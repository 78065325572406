import React, { useState, useCallback } from 'react';

import { Button } from './components';

export default function ZoomComponent() {
  const id = 'top-editor-holder';
  let previewerElement = document.getElementById(id);
  let initialSize = 100;
  let elementSize = previewerElement && previewerElement.style.fontSize;
  if (elementSize) {
    initialSize = (parseFloat(elementSize) || 1) * 100;
  }

  const [fontSize, setFontSize] = useState(initialSize);

  const zoom = useCallback((direction) => {
    const factor = direction === 'in' ? 0.1 : -0.1;
    const previewer = document.getElementById(id);
    if (!previewer) return console.log('No previewer.');
    let fontSize = previewer.style.fontSize;
    let newSize;
    if (fontSize === '' || fontSize === '1em') newSize = 1 + factor;
    else {
      fontSize = parseFloat(fontSize);
      if (isNaN(fontSize)) newSize = 1;
      else if ((direction === 'in' && fontSize > 1.5) || (direction === 'out' && fontSize < 0.6)) return;
      else newSize = fontSize + factor;
    }
    previewer.style.fontSize = newSize + 'em';

    newSize = newSize * 100;
    setFontSize(Math.round(newSize));
  }, []);

  const zoomInPreview = useCallback(() => zoom('in'), [zoom]);

  const zoomOutPreview = useCallback(() => zoom('out'), [zoom]);

  return (
    <div className="zoom-component">
      <Button onMouseDown={zoomInPreview}>
        <i className="mdi mdi-magnify-plus-outline"></i>
      </Button>
      <span className="fontsize-show">{fontSize} %</span>
      <Button onMouseDown={zoomOutPreview}>
        <i className="mdi mdi-magnify-minus-outline"></i>
      </Button>
    </div>
  );
}
