import * as plugins from './plugins/';

const buildEditorPlugins = (editor, ignore = []) => {
    for(let plugin in plugins) {
        if(typeof plugins[plugin] !== 'function' || ignore.includes(plugin)) continue;
        const pluginEditor = plugins[plugin](editor);
        if(pluginEditor !== editor) continue; // Invalid plugin
        editor = pluginEditor;
    }
    return editor;
}

export const withPlugins = (editorOrArray) => {
    if(Array.isArray(editorOrArray)) {
        return (editor) => buildEditorPlugins(editor, editorOrArray)
    }
    return buildEditorPlugins(editorOrArray);
    
}
