
export const repeatableEntityChange = {
  id: 'repeatableEntityChange',
  dependencies: { repeatableChange: true },
  time: -1,
  handler: function ({ state, handlerInvoked, paths, entries, api }) {
    
    return (node) => {
      if (node.variant !== 'entity') return;
      for (const entry of entries) {
        
        const { path, field, fieldName } = entry
        if (!path.endsWith('id')) continue;
        let { value } = entry

        const repeatableUid = api.interfaces.InputPaths.getCardUid(this.contract, path)
        const entityChange = node.data && node.data.repeatable_uid === repeatableUid && field && field.type === 'party';

        if (!entityChange) continue;

        if (node.data.fieldName !== fieldName) continue;

        const entityValue = api.interfaces.Entity.getByKeyObject(state, value, node.data.key) || '[**]'
        this.log('Rep Entity Change ', {entry, entityValue})
        node.data.entity_id = value
        node.data.value = entityValue;
        api.utils.engine.setFirstChild(node, entityValue);
        this.markNodeUpdate(node);
      }
    };
  },
};
