import React, { useState, useEffect, createRef } from 'react';
import { Editor, Transforms, Text, Range } from 'slate';
import { useSlate } from 'slate-react';
import { Tooltip, notification, Button, Input } from 'antd';
import uuid from 'uuid-random';
import { useSelector } from 'react-redux';
import { setContract, useContract } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import ClauseLibrary from './ClauseLibrary/';

const { TextArea } = Input;

export default function SidePanelButtons({ props }) {
  return (
    <div id="sted" className="studio-edit-quick-actions">
      <AddComment />
      <ClauseLibrary />
    </div>
  );
}

export function AddComment({ onAdd }) {
  const editor = useSlate();
  const { selection } = editor;
  const isExpanded = selection && Range.isExpanded(selection);
  const { openComment, component } = useAddComment({
    placement: 'right',
    top: '40px',
    onAdd,
  });
  const tooltip =
    selection && isExpanded ? (
      <IntlMessages id="studio.comments.addComment" />
    ) : (
      <IntlMessages id="studio.comments.selectToComment" />
    );
  return (
    <>
      <Tooltip title={tooltip} placement="left">
        <Button
          disabled={!selection || !isExpanded}
          size="large"
          // type="primary"
          shape="circle"
          onMouseDown={openComment}
          className="guide-comment-btn sted-btn"
        >
          <i className="mdi mdi-comment-plus-outline" />
        </Button>
      </Tooltip>
      {component}
    </>
  );
}

const useAddComment = (options = {}) => {
  const { placement = 'left', top, bottom, onAdd } = options;
  const editor = useSlate();
  const contract = useContract();
  const { user } = useSelector((state) => state.auth);

  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const inputRef = createRef();

  const openComment = () => {
    if (open === false && !editor.selection)
      return notification.warn({
        message: 'Select contract text to comment',
      });
    setOpen(!open);
  };
  const onChange = (e) => setText(e.target.value);

  const saveComment = () => {
    if (!text)
      return notification.warn({
        message: 'Add a comment text',
      });
    if (!editor.selection)
      return notification.warn({
        message: 'Select contract text to comment',
      });
    if (!contract.data.create) contract.data.create = {};
    if (!contract.data.create.comments) contract.data.create.comments = {};
    const id = uuid();
    const newComment = {
      posts: [{ user, text, time: new Date(Date.now()).toISOString() }],
    };
    if (typeof onAdd === 'function') {
      onAdd(id, newComment);
    }
    contract.data.create.comments[id] = newComment;

    setContract({ ...contract });

    const existingMarks = Editor.marks(editor);
    editor.withAllowedEditing(() => {
      if (existingMarks?.comments) {
        Transforms.setNodes(
          editor,
          (node) => {
            return {
              comments: (node.comments || []).concat(id),
            };
          },
          { match: Text.isText, split: true }
        );
      } else {
        Editor.addMark(editor, 'comments', [id]);
      }
    });

    setText('');
    setOpen(false);
  };

  const closeComment = () => {
    setText('');
    setOpen(false);
  };

  useEffect(() => {
    if (!open || !inputRef || !inputRef.current) return;
    setTimeout(() => {
      if (!inputRef || !inputRef.current) return;
      inputRef.current.focus();
    }, 10);
  }, [open, inputRef]);

  let component;

  if (!open) component = null;
  else {
    const style = {};
    if (placement === 'right') style.right = 0;
    else if (placement === 'left') style.left = 0;
    if (top !== undefined) style.top = top;
    if (bottom !== undefined) style.bottom = bottom;
    component = (
      <div className="editor-comment-box" style={style}>
        <TextArea rows={4} value={text} allowClear onChange={onChange} ref={inputRef} />
        <Button.Group className="action-area">
          <Button onMouseDown={closeComment} className="delete-comment">
            <IntlMessages id="desc.Close" />
          </Button>
          <Button type="primary" onMouseDown={saveComment} className="save-comment">
            <IntlMessages id="desc.Save" />
          </Button>
        </Button.Group>
      </div>
    );
  }
  return {
    open,
    openComment,
    closeComment,
    component,
  };
};
