import { getIsTemplateStudio, getContractValues } from "hooks"
import { updateReferences } from "core/engine/utils";

export const cmd_updateReferences = editor => ((draft) => {
    const isTemplate = getIsTemplateStudio();

    // If editing template
    if (isTemplate) {
        const contract = getContractValues();
        const newContent = updateReferences(contract)
        editor.handleChange(newContent)
        return;
    }

    // If drafting
    draft.withDraftMethod('updateReferences')
})
