export const getSmartReplacement = function (section, name, customNum) {
  if (!name) name = section;
  if (customNum) {
    if (!this.getContractCreate().build || !this.getContractCreate().build.smartReplacements) return '[**]';
    const sr = this.getContractCreate().build.smartReplacements[section];
    if (
      !sr ||
      !sr.content ||
      !sr.content[name] ||
      !sr.content[name].content ||
      !sr.content[name].content[this.language]
    )
      return '[**]';
    if (!sr.content[name].content[this.language][customNum]) return '[**]';
    return sr.content[name].content[this.language][customNum];
  } else {
    return this.getSmartReplacementText(name) || '[**]';
  }
};
export const getSmartReplacementText = function (key) {
  const draftInfo = this.getDraftInfo();
  if (!draftInfo.smartReplacements || !draftInfo.smartReplacements[key]) return false;
  return draftInfo.smartReplacements[key];
};
