import React, { useState, useCallback, useMemo } from 'react';
import PDF from 'core/utils/generators/makePDF';
import { BlobProvider } from '@react-pdf/renderer';
import { Contract } from 'core/interfaces';

export function useDownloadPDF(contract) {
  const [loadingPDF, setLoading] = useState(false);
  const [file, setFile] = useState(0);

  const initDownloadPDF = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      setFile(file + 1);
    }, 0);
  }, [file]);

  const downloadResultPDF = useMemo(() => {
    if (file) {
      return (
        <BlobProvider
          document={<PDF content={Contract.getContent(contract)} settings={Contract.getSettings(contract)} />}
        >
          {({ blob, url, loading, error }) => {
            if (!blob || !url) return null;
            setTimeout(() => {
              // window.open(url, '_blank')
              const link = document.createElement('a');
              link.href = url;
              link.target = '_blank';
              link.setAttribute('download', Contract.getName(contract) + '.pdf');
              document.body.appendChild(link);
              link.click();
              setLoading(false);
            }, 50);
            return null;
          }}
        </BlobProvider>
      );
    }
    return null;
  }, [file, contract]);

  if (!contract) return [null, null, null];

  return { initDownloadPDF, downloadResultPDF, loadingPDF };
}
