import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import AppLocale from 'lngProvider';
import { useDispatch } from 'react-redux';
import { setUser } from 'appRedux/actions';
import { IntlProvider } from 'react-intl';
import { ConfigProvider, Modal, Card, Spin, notification, Form, Input, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import { createEditor } from 'slate';
import { applyOperationSets } from 'components/editor/utils/applyOperations';
import URLSearchParams from 'url-search-params';
import ExternalContext from './core/ExternalContext';
import ViewContainer from './ViewContainer';
import { PasswordCredential, EmailCredential } from './components/Credentials';
import { setAuthToken } from 'utils/auth';
import api from './utils/externalApi';
import { IS_EXTERNAL } from 'config';

// http://localhost:8081/hello?documentid=1ed43e61-ad99-4acb-89ae-560cd9a9bf27&eid=95a35f3f-7e62-432c-9bb6-63af35187e3f&expiration=1615284694140&hash=bbbf014b14d4603284e1f2f94e7dbce268aebd8c3a0e571fec406ad05764b084

const PARAM_FIELDS = ['documentid', 'eid', 'expiration', 'hash', 'cr'];
const LOCALES = {
  en: { languageId: 'english', locale: 'en', name: 'English', icon: 'gb' },
  sv: { languageId: 'swedish', locale: 'sv', name: 'Swedish', icon: 'se' },
};
const DEFAULT_EXTERNAL_USER = {
  id: '_external_',
  firstName: '',
  lastName: '',
};

export default function ExternalView() {
  const [locale, setLocale] = useState('en');
  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  let validParams = IS_EXTERNAL ? PARAM_FIELDS.every((field) => urlParams.has(field)) : false;
  const userId = useRef({ id: urlParams.get('userid'), email: null });
  const urlRequiredCredential = urlParams.get('cr'); // 'p' (password), 'e' (email), 'n' (none)

  const params = useMemo(() => {
    if (!validParams) return null;
    return {
      documentId: urlParams.get('documentid'),
      eid: urlParams.get('eid'),
      expiration: urlParams.get('expiration'),
      hash: urlParams.get('hash'),
      cr: urlParams.get('cr')
    };
  }, [validParams, urlParams]);

  const [sharedData, setSharedData] = useState(null);
  const [sharedDataError, setSharedDataError] = useState(null);

  const [requiredCredentialType, setRequiredCredentialType] = useState(urlRequiredCredential || null);
  const [requiredCredentialValue, setRequiredCredentialValue] = useState(null);
  const [isCredentialAuthenticating, setIsCredentialAuthenticating] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [hasAuth, setHasAuth] = useState(false);

  let storedValues = useMemo(() => {
    if (!params.eid) return null;
    const stringedValues = localStorage.getItem('ext_save_' + params.eid);
    if (!stringedValues) return null;
    try {
      return JSON.parse(stringedValues);
    } catch (err) {
      console.log('Cannot parse stored values ', err);
    }
    return null;
  }, [params.eid]);

  const authWithPassword = () => {
    const tmp = { modal: null };
    const onSubmit = (values) => {
      setRequiredCredentialValue(values.password);
      tmp.modal.destroy();
      delete tmp.modal;
    };
    tmp.modal = Modal.confirm({
      title: 'Password required',
      content: <PasswordCredential onSubmit={onSubmit} />,
      className: 'pwd-modal',
      onOk: null,
      okButtonProps: { className: 'd-none' },
      cancelButtonProps: { className: 'd-none' },
      onCancel: null,
    });
  };
  const authWithEmail = () => {
    const tmp = { modal: null };
    const onSubmit = (values) => {
      setRequiredCredentialValue(values.email);
      tmp.modal.destroy();
      delete tmp.modal;
    };
    tmp.modal = Modal.confirm({
      title: 'Email required',
      content: <EmailCredential onSubmit={onSubmit} />,
      className: 'pwd-modal',
      onOk: null,
      okButtonProps: { className: 'd-none' },
      cancelButtonProps: { className: 'd-none' },
      onCancel: null,
    });
  };

  useEffect(() => {
    if (hasAuth || isCredentialAuthenticating) return;
    if (!requiredCredentialType) return
    if (requiredCredentialType === 'p') {
      setIsCredentialAuthenticating(true);
      authWithPassword();
    }
    if (requiredCredentialType === 'e') {
      setIsCredentialAuthenticating(true);
      authWithEmail();
    }
  }, [
    requiredCredentialValue,
    requiredCredentialType,
    setIsCredentialAuthenticating,
    isCredentialAuthenticating,
    hasAuth,
  ]);

  const doAuth = useCallback(
    (credentialType, credentialValue) => {
      if (!validParams) return;
      const authParams = {
        ...params,
        credentialType,
        credentialValue,
      };
      if (credentialType !== 'n' && !credentialValue) return
      
      api
        .post('/authenticate', authParams)
        .then((res) => {
          if (!res || !res.data || !res.data.access_token) {
            return setAuthError('Could not gain access to document');
          }
          const token = 'Bearer ' + res.data.access_token;
          localStorage.setItem('extAccessToken', token);
          if (credentialType === 'e') userId.current.email = credentialValue;
          setAuthToken(token);
          setHasAuth(true);
          setRequiredCredentialValue(null) // clear from memory.
          setIsCredentialAuthenticating(false);
        })
        .catch((err) => {
          // console.log('auth err ', { err, response: err.response });
          if (err.message === 'Network Error') return setAuthError('Network error - Please try again later');
          if (err.response && err.response.data && err.response.data.status === 'credential_password') {
            setIsCredentialAuthenticating(false);
            setRequiredCredentialType('p');
            return;
          }
          if (err.response && err.response.data && err.response.data.status === 'credential_email') {
            setIsCredentialAuthenticating(false);
            setRequiredCredentialType('e');
            return;
          }
          return setAuthError('Invalid link');
        });
    },
    [validParams, params]
  );

  useEffect(() => {
    if (!validParams) return console.log('No valid params');
    if (requiredCredentialType === 'p' && !requiredCredentialValue)
      return
    if (requiredCredentialType === 'e' && !requiredCredentialValue)
      return

    doAuth(requiredCredentialType, requiredCredentialValue);
  }, [requiredCredentialType, requiredCredentialValue, validParams, doAuth]);

  useEffect(() => {
    if (!validParams || !hasAuth) return;
    api
      .get('/document')
      .then((res) => {
        if (!res || !res.data || !res.data.data) return setSharedDataError('Could not fetch shared document.');
        const tmpSharedData = res.data.data;
        // console.log('Stored values ', storedValues);
        if (storedValues && storedValues.operationSets) {
          try {
            const editor = createEditor();
            /* 
            console.log('Orig ops are ', storedValues.operationSets)
            const origApply = editor.apply
            editor.apply = (...args) => {
              console.log('appl ', args)
              origApply(...args)
            }
            window.meditor = editor
            editor.onChange = (val) => {
              console.log('change val ', val)
            } 
            */
            editor.children = tmpSharedData.data.data.content;

            const appliedOps = applyOperationSets(editor, storedValues.operationSets);
            if (appliedOps) {
              tmpSharedData.data.data.content = editor.children;
            }
          } catch (err) {
            console.error(err);
          }
        } if (storedValues && storedValues.inlineComments) {
          tmpSharedData.data.data.create.comments = storedValues.inlineComments
        }

        setSharedData(tmpSharedData);

        let externalUser = DEFAULT_EXTERNAL_USER;
        if (Array.isArray(tmpSharedData.info?.recipients)) {
          let userAmongstRecipients;
          if (userId.current.email)
            userAmongstRecipients = tmpSharedData.info.recipients.find(
              (u) => u.email === userId.current.email
            );
          else if (userId.current.id)
            userAmongstRecipients = tmpSharedData.info.recipients.find((u) => u.id === userId.current.id);
          if (userAmongstRecipients) {
            externalUser = userAmongstRecipients;
          } else if (tmpSharedData.info.recipients.length === 1) {
            externalUser = tmpSharedData.info.recipients[0];
          }
        }
        dispatch(setUser(externalUser));
      })
      .catch((err) => {
        console.log('Err is ? ', err);
        setSharedDataError(err);
      });
  }, [hasAuth, validParams, dispatch, userId, storedValues]);

  useEffect(() => {
    if (!sharedData || !sharedData.data) return;
    const language = sharedData.data?.info?.language || 'en';

    if (LOCALES[language]) setLocale(language);
  }, [sharedData]);

  if (!validParams) {
    return (
      <Card title="" className="card m-3 d-flex justify-content-center align-items-center">
        <p>Invalid link</p>
      </Card>
    );
  }

  if (isCredentialAuthenticating) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        <div className="mb-4">Awating authentication . . .</div>
      </div>
    );
  }

  if (authError) {
    return (
      <Card title="" className="card m-3 d-flex align-items-center justify-content-center">
        <p>{/* <IntlMessages id="studio.errors.load" /> */}</p>
        <p>{authError}</p>
      </Card>
    );
  }

  if (sharedDataError) {
    return (
      <Card title="" className="card m-3">
        <p>{/* <IntlMessages id="studio.errors.load" /> */}</p>
        <p>
          Could not retrieve document data. Please try again later or contact the person sharing the link and
          have a new link sent.
        </p>
      </Card>
    );
  }

  if (sharedData === null) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        <div className="mb-4">Loading . . .</div>
        <div>
          <Spin size="large" />
        </div>
      </div>
    );
  }

  if (typeof sharedData !== 'object' || !sharedData.data) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        <div className="mb-4">Corrupted shared data</div>
      </div>
    );
  }

  const currentAppLocale = AppLocale[locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <ExternalContext.Provider value={params}>
          <ViewContainer sharedData={sharedData} externalId={params.eid} storedValues={storedValues} />
        </ExternalContext.Provider>
      </IntlProvider>
    </ConfigProvider>
  );
}
