import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Spin, Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Widget from 'components/Widget/index';
import WidgetHeader from 'components/WidgetHeader/index';
import Auxiliary from '../../util/Auxiliary';
import TaskHeader from '../../components/Headers/TaskHeader/index';
import api from 'utils/api';
import { fixDate } from 'components/ui';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';

const ButtonGroup = Button.Group;

const Tasks = (props) => {
  const [tasks, setTasks] = useState(null);
  const [taskError, setTaskError] = useState('');
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    api
      .get('/tasks/my')
      .then((result) => setTasks(result.data))
      .catch((err) => setTaskError(err.response));
  }, [auth.user.id]);

  const updateTask = (newTask) => {
    setTasks(
      tasks.map((task) => {
        if (task.id === newTask.id) return newTask;
        return task;
      })
    );
  };

  if (taskError) {
    return (
      <Card title="" className="card">
        <div>Cannot retrieve tasks at this time.</div>
      </Card>
    );
  }

  if (!tasks) {
    return (
      <Card title="" className="card">
        <Spin className="loader-container" />
      </Card>
    );
  }

  const pending = tasks.filter((task) => task.status === 'pending');
  const completed = tasks.filter((task) => task.status === 'completed');

  return (
    <Auxiliary>
      <TaskHeader tasks={tasks} pending={pending} completed={completed} />
      <div className="mt-4">
        <Row className="mb-4">
          <Col sm={24}>
            <WidgetHeader title={<IntlMessages id="app.general.Pending" />} />
            <div className="m-3">
              {pending.length === 0 ? (
                <div>
                  <IntlMessages id="app.general.tasks.noPendingTasks" />
                </div>
              ) : (
                <Row>
                  {pending.map((task) => (
                    <Task task={task} key={task.id} updateTask={updateTask} />
                  ))}
                </Row>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={24}>
            <WidgetHeader title={<IntlMessages id="app.general.Completed" />} />
            <div className="m-3">
              {completed.length === 0 ? (
                <div>
                  <IntlMessages id="app.general.tasks.noCompletedTasks" />
                </div>
              ) : (
                <Row>
                  {completed.map((task) => (
                    <Task task={task} key={task.id} updateTask={updateTask} />
                  ))}
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Auxiliary>
  );
};

const Task = ({ task, updateTask }) => {
  const {
    id,
    status,
    // data,
    info,
  } = task;

  const approveTask = () => {
    api
      .put('/tasks/' + id + '/ruling', { ruling: 'approved' })
      .then((res) => updateTask(res.data))
      .catch((err) => console.log('err', err.response));
  };

  const rejectTask = () => {
    api
      .put('/tasks/' + id + '/ruling', { ruling: 'rejected' })
      .then((res) => updateTask(res.data))
      .catch((err) => console.log('err', err.response));
  };

  const undeterminedTask = () => {
    api
      .put('/tasks/' + id + '/ruling', { ruling: 'undetermined' })
      .then((res) => updateTask(res.data))
      .catch((err) => console.log('err', err.response));
  };

  let textClass, iconClass;
  if (info.ruling === 'approved') {
    textClass = 'text-success';
    iconClass = 'mdi-check-circle';
  } else if (info.ruling === 'rejected') {
    textClass = 'text-danger';
    iconClass = 'mdi-close-circle';
  } else {
    textClass = 'text-muted';
    iconClass = 'mdi-help-circle';
  }

  return (
    <Col xl={8} lg={12} md={12} sm={12} xs={24}>
      <Widget>
        <div>
          <div className="d-flex justify-content-end">
            {/* <Tag className="rounded-xs text-uppercase" color="#06BB8A">
              Completed Task
            </Tag> */}
          </div>
          <h2 className="mb-3 d-block">{task.name}</h2>
          <p>{task.description}</p>
          <table className="mb-4">
            <tbody>
              <tr>
                <td className="text-grey fs-sm mb-0">Task created:</td>
                <td className="text-grey fs-sm mb-0">{fixDate(task.createdAt)}</td>
              </tr>
              <tr>
                <td className="text-grey fs-sm mb-0">Task updated:</td>
                <td className="text-grey fs-sm mb-0">{fixDate(task.updatedAt)}</td>
              </tr>
            </tbody>
          </table>

          {/* <h4 className="text-primary mb-3 mb-sm-4">Are You ready to join?</h4> */}
          {status === 'pending' && (
            <>
              <div className="mb-2">Task Action:</div>
              <div className="d-flex justify-content-center">
                <ButtonGroup className="mb-1">
                  <Button className="" type="primary" onClick={approveTask}>
                    Approve
                  </Button>
                  <Button className="btn-warning" onClick={rejectTask}>
                    Reject
                  </Button>
                </ButtonGroup>
              </div>
            </>
          )}
          {status === 'completed' && (
            <div>
              <div className="mb-2">Task Outcome:</div>
              <span>
                <b className={'ml-1 text-uppercase ' + textClass}>
                  <i className={'mr-1 mdi ' + iconClass} />
                  {info.ruling}
                </b>
              </span>
              <span>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={approveTask} disabled={info.ruling === 'approved'}>
                        Accept
                      </Menu.Item>
                      <Menu.Item onClick={undeterminedTask} disabled={info.ruling === 'undetermined'}>
                        Undetermined
                      </Menu.Item>
                      <Menu.Item onClick={rejectTask} disabled={info.ruling === 'rejected'}>
                        Reject
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <small className="link ant-dropdown-link float-right">
                    Change
                    {/* <Icon type="down" /> */}
                    <DownOutlined />
                  </small>
                </Dropdown>
              </span>
            </div>
          )}
        </div>
      </Widget>
    </Col>
  );
};

export default Tasks;
