import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Row, Col, Button, Modal } from 'antd';
import api from 'utils/api';
import NewProjectItem from './NewProjectItem';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

export default function NewProjectButton(props) {
  const { children, className } = props;

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templatesFetchedMessage, setTemplatesFetchedMessage] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const formatMessage = useIntlMessage();

  const close = () => setOpen(false);

  const buttonClassName = className || 'm-0 ml-2';

  const getProjectTemplates = useCallback(() => {
    api
      .get('/projecttemplates')
      // .get("/templates/projects?overview=true") // Setting `overview` strips out data.template - saves network traffic // apiv1
      .then(function (res) {
        if (res.data && Array.isArray(res.data)) {
          if (res.data.length > 0) {
            setTemplates(res.data);
            setTemplatesFetchedMessage(null);
          } else setTemplatesFetchedMessage(formatMessage('app.projects.NoTemplatesAvailable'));
        } else {
          setTemplatesFetchedMessage(formatMessage('app.projects.NoTemplatesFound'));
          console.log('Error in fetching templates, ', res);
        }
      })
      .catch((err) => {
        console.log('get err', err, err.response);
        setTemplatesFetchedMessage(formatMessage('app.projects.NoTemplatesFound'));
      });
  }, [formatMessage]);

  useEffect(() => {
    if (templates.length === 0) getProjectTemplates();
  }, [getProjectTemplates, templates.length]);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
    setSelectedTemplate(null);
  }, [open]);

  return (
    <>
      {children ? (
        <div className="clickable" onClick={toggleOpen}>
          {children}
        </div>
      ) : (
        <Button
          type="primary"
          icon={<i className="mdi mdi-cube-outline mr-2" />}
          className={buttonClassName}
          onClick={toggleOpen}
        >
          <IntlMessages id="app.projects.NewProject" />
        </Button>
      )}
      <Modal
        title={
          <div>
            {selectedTemplate !== null && (
              <Button
                type="secondary"
                size="small"
                className="creation-modal-back float-left ml-2"
                onClick={() => {
                  setSelectedTemplate(null);
                }}
              >
                <i className="mdi mdi-arrow-left mr-2" />
                <IntlMessages id="desc.Back" />
              </Button>
            )}
            <div>
              <IntlMessages id="app.projects.NewProject" />
            </div>
          </div>
        }
        visible={open}
        backdrop={'static'}
        onOk={toggleOpen}
        onCancel={toggleOpen}
        footer={[
          <Button key="back" onClick={toggleOpen}>
            <IntlMessages id="desc.Cancel" />
          </Button>,
        ]}
      >
        {open && selectedTemplate === null && (
          <div>
            <div className="text-center">
              <div className="card-title mb-4">
                <h6>
                  <IntlMessages id="app.projects.ChooseTemplate" />
                </h6>
              </div>
            </div>
            <Row>
              {templates &&
                templates.length > 0 &&
                templates.map((template, index) => (
                  <Col span={8} key={'doc_tmpl2_' + index}>
                    <Card
                      className="mb-1 pb-1 clickable liftable"
                      onClick={() => {
                        setSelectedTemplate(index);
                      }}
                    >
                      <div className="flex-column justify-content-center align-items-center">
                        <i className={'mdi mdi-cube-outline'} style={{ fontSize: 48 }} />
                        <h5 align={'center'}>{template.name}</h5>
                        <small align={'center'} className="text-center">
                          {template.description}
                        </small>
                      </div>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
        )}
        {templatesFetchedMessage && (
          <div className="card ml-3 mr-3">
            <div className="card-body">
              <p>{templatesFetchedMessage}</p>
              <p>
                <IntlMessages id="app.projects.ContactAdmin" />.
              </p>
            </div>
          </div>
        )}
        {open && selectedTemplate !== null && (
          <div>
            <NewProjectItem
              projectTemplate={templates[selectedTemplate]}
              projectWasCreated={props.projectWasCreated}
              history={history}
              close={close}
            />
          </div>
        )}
      </Modal>
    </>
  );
}
