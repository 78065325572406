import { IS_EXTERNAL } from './env'

const backendServer = process.env.REACT_APP_API_URL;
if (!backendServer) throw new Error('Must specify REACT_APP_API_URL');

const apiVersion = '1.0';
const apiAddress = IS_EXTERNAL ? backendServer : `${backendServer}/api/v${apiVersion}/x`;

export {
    apiAddress,
    backendServer,
}