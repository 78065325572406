import uuid from 'uuid-random'
import findIndex from 'find-index/findIndex';
import { v4 as isUuidV4} from '../../import/is-uuid'

const LegalPerson = {}

LegalPerson.construct = function(basePerson, parentPerson = {}) {
  return {
    id: isUuidV4(basePerson.id) ? basePerson.id : uuid(),
    parentId: basePerson.parentId || parentPerson.id || null,
    form: basePerson.form || parentPerson.form || null,
    name: basePerson.name,
    jurisdiction: basePerson.jurisdiction || basePerson.country || 'se',
    identificationNumber: basePerson.identificationNumber || basePerson.regno || '',
    underTransfer: null,
    street: basePerson.street || '',
    city: basePerson.city || '',
    zipcode: basePerson.zipcode || '',
    emails: basePerson.emails || [],
    data: basePerson.data || {},
  };
}

LegalPerson.isLegalPerson = function(obj) {
  return (
    obj.hasOwnProperty('id')
    && obj.hasOwnProperty('parentId')
    && obj.hasOwnProperty('name')
    && obj.hasOwnProperty('identificationNumber')
  )
}

LegalPerson.findTopCo = function(legalPersons) {
  const ownerIndex = findIndex(legalPersons, (node) => node.parentId === null);
  if (ownerIndex === -1) return null
  return legalPersons.find((node) => node.parentId === legalPersons[ownerIndex].id) || null
}

LegalPerson.collectChildren = function(legalPersons, parentId, options = {}) {
  const { mode = 'id' } = options
  let childrenIds = [];
  for (let person of legalPersons) {
    if (person.parentId !== parentId) continue;
    childrenIds.push(mode === 'full' ? person : person.id);
    const subChildren = LegalPerson.collectChildren(legalPersons, person.id, options);
    childrenIds = childrenIds.concat(subChildren);
  }
  return childrenIds;
};

export { LegalPerson }