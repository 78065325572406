import React, { useState, useEffect, createRef } from 'react';
import { Drawer, Button, List, Badge, Input, Comment as AntComment, Avatar, Tooltip, Popconfirm } from 'antd';
import CustomScrollbars from 'util/CustomScrollbars';
import { useSelector } from 'react-redux';
import { useContract } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import { CloseOutlined, SearchOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

export default function ClauseLibrary({ props }) {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('right');
  const { user } = useSelector((state) => state.auth);

  const close = () => setOpen(false);

  const oppositePlacement = placement === 'left' ? 'right' : 'left';
  const togglePlacement = () => setPlacement(oppositePlacement);
  return (
    <>
      <Tooltip title={<IntlMessages id="studio.clauseLib.clauseLibrary" />} placement="left">
        <Button size="large" shape="circle" onMouseDown={() => setOpen(true)}>
          <i className="mdi mdi-format-section" />
        </Button>
      </Tooltip>
      <Drawer
        title={
          <>
            <IntlMessages id="studio.clauseLib.clauseLibrary" />
            <div className="top-btns">
              <Tooltip
                title={
                  <>
                    <IntlMessages id="studio.comments.placeDrawer" />{' '}
                    <IntlMessages id={'desc.' + oppositePlacement} />
                  </>
                }
                placement="bottom"
              >
                <Button className="border-0" onMouseDown={togglePlacement}>
                  {oppositePlacement === 'left' ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
                </Button>
              </Tooltip>
              <Tooltip title="Close" placement="bottom">
                <Button className="border-0" onMouseDown={close}>
                  <CloseOutlined />
                </Button>
              </Tooltip>
            </div>
          </>
        }
        closable={false}
        mask={false}
        maskClosable={false}
        width={400}
        className="editor-comments-container"
        placement={placement}
        onClose={close}
        visible={open}
      >
        <ClauseLibraryContainer />
      </Drawer>
    </>
  );
}

function ClauseLibraryContainer() {
  const contract = useContract();
  return (
    <div className="comment">
      <CustomScrollbars>
        <ClauseLibraryContent contract={contract} />
      </CustomScrollbars>
    </div>
  );
}

function ClauseLibraryContent({ contract }) {
  const [searchString, setSearchString] = useState('');
  const handleSearchChange = (evt) => setSearchString(evt.target.value);

  return (
    <>
      <div className="border-bottom p-3">
        <Input
          // size="large"
          placeholder="Search"
          prefix={<SearchOutlined />}
          value={searchString}
          onChange={handleSearchChange}
        />
      </div>
      <ClauseLibraryList contract={contract} search={searchString.toLocaleLowerCase()} />
    </>
  );
}

function ClauseLibraryList({ contract, search }) {
  const clauses = [
    {
      id: 'abc123',
      types: ['termination right'],
      name: 'Change of Control',
      description:
        'Provides the right to terminate the contract if the ownership of your counterparty would change',
      clause: { type: 'clause', data: {}, children: [{ text: 'hej' }] },
    },
    {
      id: 'xbc123',
      types: ['other'],
      name: 'File Delivery',
      description: 'Require a party to deliver a file',
      clause: { type: 'clause', data: {}, children: [{ text: 'hej' }] },
    },
  ];

  const filteredClauses = search
    ? clauses.filter(({ id, types, name, description }) => {
        if (id.includes(search)) return true;
        if (name.includes(search)) return true;
        if (description.includes(search)) return true;
        if (Array.isArray(types)) {
          for (const type of types) {
            if (type.includes(search)) return true;
          }
        }
        return false;
      })
    : clauses;

  return (
    <div>
      {filteredClauses.map((clause) => (
        <PresentedClause key={clause.id} {...clause} contract={contract} />
      ))}
    </div>
  );
}

function PresentedClause({ id, clause, types, name, description, index, contract }) {
  return (
    <div className="editor-comment-item border-bottom">
      <AntComment
        actions={[<span>More info</span>]}
        author={name}
        avatar={<Avatar className="ml-2" icon={<i className="mdi mdi-format-section" />} />}
        content={<p>{description}</p>}
        datetime={
          <>
            {Array.isArray(types) &&
              types.map((type) => (
                <Tooltip title={type} key={type}>
                  <Avatar
                    size="small"
                    icon={<SearchOutlined />}
                    style={{ marginTop: '-4px', backgroundColor: '#87d068' }}
                  />
                </Tooltip>
              ))}
          </>
        }
      />
    </div>
  );
}
