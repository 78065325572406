import React, { useCallback, useMemo, useEffect, memo, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Editable, withReact, Slate, ReactEditor, useSlate, useEditor } from 'slate-react';
import { Editor, createEditor, Node, Path, Range, Text, Transforms, Span, Point, Operation } from 'slate';
import { useDispatch } from 'react-redux';
import { updateInput } from 'appRedux/actions';
import { withHistory } from 'slate-history';
import { Button } from 'antd';
/* import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend"; */
import adjustInterfaces from './legal/adjustInterfaces';
import { EditorCommentContext, ContractUpdateContext } from 'contexts/contexts';
import EditorComments from './EditorComments';
import CustomScrollbars from 'util/CustomScrollbars';
import { RenderElements, withElementLogic } from './legal/elements';
import { RenderLeaf } from './legal/marks';
import { withPlugins } from './legal/plugins';
import onKeyDown from './legal/onKeyDown';
import customClass from './legal/utils/customClass';
import { formatting } from 'core/config/contractDefaults';
import manager from 'core/engine/manager';
import { useContract, useContractUpdates, useHighlightContractUpdates, useIsTemplateStudio, setEventState, useEventState } from 'hooks';
import { Contract } from 'core/interfaces';
import { inlineHasMenu, inlineMenuComponent, TextSelect } from './components/InlineFieldMenus/';
import IntlMessages from 'util/IntlMessages';

const { fontFamilies, defaultFont } = formatting;

// Make modifications to certain slate interfaces (Transforms and Node)
adjustInterfaces();
const originalToSlatePoint = ReactEditor.toSlatePoint;

function debounceUniqueLastArg(func, wait) {
  var timeout, later, lastFirstArg;
  return function () {
    var context = this,
      args = arguments;

    let immediate = false;
    if (lastFirstArg && args[0] !== lastFirstArg) {
      clearTimeout(timeout);
      timeout = null;
      later();
      later = null;
    }
    lastFirstArg = args[0];
    later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const EditorWrapper = memo(({ children, onChangeOperations, ...props }) => {

  let contract
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    contract = useContract()
  } catch(err) {}
  // const contract = useContract();
  const content = contract ? Contract.getContent(contract) : props.content;

  const [value, setValue] = useState(content);
  const dispatch = useDispatch();
  const isTemplate = useIsTemplateStudio()

  const editor = useMemo(() => withPlugins(withElementLogic(withHistory(withReact(createEditor())))), []);

  if (!editor.meta) editor.meta = {};
  if (!editor.tmp) editor.tmp = {};
  editor.commentFirstLeafs = {};

  const handleInlineOperation = useCallback(
    debounceUniqueLastArg((stringPath, entry) => {
      if (!contract) return
      const [node, path] = entry;
      const string = Node.string(node);
      let inputField;
      if (node.variant === 'vari') {
        const inputPath = 'input.' + node.data.name;
        inputField = Contract.getUiInputFieldDataByPath(contract, inputPath);
        if (!inputField) return console.log('Could not find input field for vari.');
        dispatch(updateInput(inputPath, string));
      } else if (node.variant === 'item') {
        inputField = Contract.getUiInputFieldDataByPath(contract, node.data.each_item_path);
        if (!inputField) return console.log('Could not find input field for item.');
        dispatch(updateInput(node.data.each_item_path, string));
      }
    }, 1000),
    [contract, dispatch]
  );

  const handleOperations = useCallback(
    (operations) => {
      const textOps = operations.filter((op) => op.type === 'insert_text' || op.type === 'remove_text');
      if (textOps.length === 0) return;
      const inlinePaths = textOps
        .map((op) => {
          try {
            const parentPath = Path.parent(op.path);
            const node = Node.get(editor, parentPath);
            if (node.variant === 'item' || node.variant === 'vari')
              return [[node, parentPath], JSON.stringify(parentPath)];
          } catch (err) {
            return null;
          }
          return null;
        })
        .filter((entry) => !!entry);

      inlinePaths.forEach((item) => {
        handleInlineOperation(item[1], item[0]);
      });
    },
    [editor, handleInlineOperation]
  );

  const onChange = useCallback(
    (changeValue) => {
      // If content is the same, abort.
      if (changeValue === value) return;

      if (typeof onChangeOperations === 'function') onChangeOperations(editor.operations);
      if (!isTemplate)
        handleOperations(editor.operations);

      setValue(changeValue);
      if (contract) {
        Contract.setContent(contract, changeValue);
        manager.editUpdateMade();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract, value, handleOperations, isTemplate]
  );

  editor.handleChange = onChange;

  useEffect(() => {
    if (props.view) return;
    if (!contract) return
    manager.setEditor(Contract.getId(contract), onChange, editor);
    return () => manager.setEditor(Contract.getId(contract), null, null);
  }, [contract, props.view, onChange, editor]);

  // Handle what would otherwise cause a fatal error (getting slate point from dom point)
  useEffect(() => {
    ReactEditor.toSlatePoint = (...args) => {
      try {
        return originalToSlatePoint(...args);
      } catch (err) {
        if (!editor.selection) return null;
        return JSON.parse(JSON.stringify(editor.selection.anchor));
      }
    };
    return () => {
      ReactEditor.toSlatePoint = originalToSlatePoint;
    };
  }, [editor]);

  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      <EditorCommentContext.Provider initialValue={{}}>{children}</EditorCommentContext.Provider>
    </Slate>
  );
  /* return (
    <DndProvider backend={HTML5Backend}>
      <Slate editor={editor} value={value} onChange={onChange}>
        <EditorCommentContext.Provider initialValue={{}}>{children}</EditorCommentContext.Provider>
      </Slate>
    </DndProvider>
  ); */
});

const LegalEditor = (props) => {
  let wrapped;
  try {
    const editor = useEditor();
    if (editor) wrapped = true;
  } catch (err) {
    wrapped = false;
  }


  const EditorComponent = props.simple ? TheSimpleEditor : TheEditor

  if (wrapped) {
    return <EditorComponent {...props} holderRef={props.holderRef} />;
  } else {
    return (
      <EditorWrapper {...props}>
        <EditorComponent {...props} holderRef={props.holderRef} />
      </EditorWrapper>
    );
  }
};



const TheEditor = (props) => {
  const editor = useEditor();
  const contract = useContract();
  const settings = Contract.getSettings(contract);
  const { highlight } = useHighlightContractUpdates({
    isTemplate: props.isEditingTemplate,
    view: props.view,
  });

  const onEscapeFnRef = useRef()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElement = useCallback((props) => <RenderElements {...props} editor={editor} />, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = useCallback((props) => <RenderLeaf {...props} editor={editor} />, []);

  useEffect(() => {
    if (props.view || props.isEditingTemplate) return;
    manager.setHighlight(Contract.getId(contract), highlight);
  }, [contract, props.view, highlight, props.isEditingTemplate]);

  editor.meta.action = props.action;
  editor.meta.view = props.view;
  editor.meta.external = props.external;
  editor.meta.creating = props.creating;
  editor.meta.templateEdit = props.templateEdit;
  editor.meta.isEditingTemplate = props.isEditingTemplate;
  editor.meta.markup = props.markup;
  editor.meta.preview = props.preview;
  editor.meta.interactive = !!props.interactive;
  if (!editor.tmp)
    editor.tmp = {}

  if (editor.meta.isEditingTemplate) editor.meta.allowEditing = true;

  const className = useMemo(() => {
    return customClass(editor, props, settings || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const style = {
    fontFamily:
      (settings && settings.format && fontFamilies[settings.format.fontFace].css) ||
      fontFamilies[defaultFont].css,
  };

  window.editor = editor;
  window.Transforms = Transforms;
  window.Editor = Editor;
  window.no = Node;
  window.Path = Path;
  window.Span = Span;
  window.Point = Point;
  window.ReactEditor = ReactEditor;
  window.ran = Range;
  window.tex = Text;
  window.Operation = Operation;
  window.usedContract = contract;
  window.ge = () => {
    if (!editor.selection?.anchor?.path) return;
    return Node.get(editor, editor.selection?.anchor?.path.slice(0, -1));
  };

  if (typeof props.onInlineCommentChange === 'function') {
    editor.onInlineCommentChange = props.onInlineCommentChange;
  }

  let externalScrollControlY = useMemo(
    () => ({
      fn: null,
    }),
    []
  );

  const onScrollY = useCallback(
    (...args) => {
      localStorage.setItem('contractScrollTop', args[0].target.scrollTop);
      if (typeof props.onScrollY === 'function') props.onScrollY(...args);
      if (typeof externalScrollControlY.fn === 'function') externalScrollControlY.fn(...args);
    },
    [props, externalScrollControlY]
  );

  const editorOnKeyDown = useCallback(
    (event) => {
      onKeyDown(event, editor, {onEscapeFnRef});
    },
    [editor]
  );

  const editorOnBlur = useCallback(
    /*
      Ensure that excluded targets have a tabIndex='-1'. Otherwise, they will not be
      deemed a relatedTarget and we cannot match to them/their parents to determine
      if blurred should be prevented/allowed.
    */

    (evt) => {
      if (!editor.meta.isEditingTemplate && !editor.meta.allowEditing) return;
      if (editor.meta.action === 'contract') return;

      const { relatedTarget } = evt;
      // console.log('hela eventet ', evt);
      // console.log('relatedTarget ', relatedTarget);
      if (relatedTarget) {
        if (relatedTarget.closest('.editor-toolbar.toolbar-menu')) return;
        if (relatedTarget.closest('[role="dialog"]')) return;
        if (relatedTarget.closest('.contract-structure-holder')) return;
        if (relatedTarget.closest('.prevent-editor-blur')) return;
      }
      setEventState('editorBlur', true)
    },
    [editor]
  );

  const editorOnFocus = useCallback(() => {
    if (!editor.meta.isEditingTemplate && !editor.meta.allowEditing) return;
    if (editor.meta.action === 'contract') return;
    setEventState('editorBlur', false)
  }, [editor]);

  const clearTmpSelection = () => {
    editor.tmp._nextRange = null;
  };

  editor.onKeyDown = onKeyDown;

  return (
    <div id="editing-editor" className="editing">
      {props.previewUpdates && <><PreviewUpdatesHolder contract={contract} props={props} /></>}

      <CustomScrollbars
        className="editor-scroller"
        onScroll={
          onScrollY
          /* (typeof props.onScrollY === "function" && props.onScrollY) ||
          undefined */
        }
        autoHide={editor.meta.isEditingTemplate || editor.meta.action === 'contract' ? false : true}
      >
        <div id="top-editor-holder" className="top-editor-holder" ref={props.holderRef}>
          <HoveringFieldEditorContainer
            isEditingTemplate={props.isEditingTemplate}
            externalScrollControlY={externalScrollControlY}
            onEscapeFnRef={onEscapeFnRef}
          />

          <EditorComments editor={editor} />
          {/* <BrowseChanges editor={editor} reviewIndex={reviewIndex} setReviewIndex={setReviewIndex} /> */}
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            id="editor-holder"
            className={className}
            style={style}
            placeholder="Enter some text…"
            autoFocus={!props.isEditingTemplate}
            readOnly={props.readOnly}
            spellCheck={false}
            onKeyDown={editorOnKeyDown}
            onDragOver={editor.onDragOver}
            onBlur={editorOnBlur}
            onFocus={editorOnFocus}
            onClick={clearTmpSelection}
            onMouseDown={clearTmpSelection}
            // decorate={decorate}
          />
        </div>
      </CustomScrollbars>
    </div>
  );
};

const TheSimpleEditor = (props) => {
  const editor = useEditor();

  const { settings } = props

  if (!settings || typeof settings !== 'object') {
    throw new Error('Expected `settings` prop')
  }

  const onEscapeFnRef = useRef()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElement = useCallback((props) => <RenderElements {...props} editor={editor} />, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = useCallback((props) => <RenderLeaf {...props} editor={editor} />, []);

  editor.meta.action = props.action;
  editor.meta.view = props.view;
  editor.meta.external = props.external;
  editor.meta.creating = props.creating;
  editor.meta.templateEdit = props.templateEdit;
  editor.meta.isEditingTemplate = props.isEditingTemplate;
  editor.meta.markup = props.markup;
  editor.meta.preview = props.preview;
  editor.meta.interactive = !!props.interactive;
  editor.meta.allowEditing = props.allowEditing
  if (!editor.tmp)
    editor.tmp = {}

  if (editor.meta.isEditingTemplate) editor.meta.allowEditing = true;

  const className = useMemo(() => {
    return customClass(editor, props, settings || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const style = {
    fontFamily:
      (settings && settings.format && fontFamilies[settings.format.fontFace].css) ||
      fontFamilies[defaultFont].css,
  };

  window.editor = editor;
  window.Transforms = Transforms;
  window.Editor = Editor;
  window.no = Node;
  window.Path = Path;
  window.Span = Span;
  window.Point = Point;
  window.ReactEditor = ReactEditor;
  window.ran = Range;
  window.tex = Text;
  window.Operation = Operation;
  window.ge = () => {
    if (!editor.selection?.anchor?.path) return;
    return Node.get(editor, editor.selection?.anchor?.path.slice(0, -1));
  };

  if (typeof props.onInlineCommentChange === 'function') {
    editor.onInlineCommentChange = props.onInlineCommentChange;
  }

  const editorOnKeyDown = useCallback(
    (event) => {
      onKeyDown(event, editor, {onEscapeFnRef});
    },
    [editor]
  );

  const editorOnBlur = useCallback(
    /*
      Ensure that excluded targets have a tabIndex='-1'. Otherwise, they will not be
      deemed a relatedTarget and we cannot match to them/their parents to determine
      if blurred should be prevented/allowed.
    */

    (evt) => {
      if (!editor.meta.isEditingTemplate && !editor.meta.allowEditing) return;
      if (editor.meta.action === 'contract') return;

      const { relatedTarget } = evt;
      // console.log('hela eventet ', evt);
      // console.log('relatedTarget ', relatedTarget);
      if (relatedTarget) {
        if (relatedTarget.closest('.editor-toolbar.toolbar-menu')) return;
        if (relatedTarget.closest('[role="dialog"]')) return;
        if (relatedTarget.closest('.contract-structure-holder')) return;
        if (relatedTarget.closest('.prevent-editor-blur')) return;
      }
      setEventState('editorBlur', true)
    },
    [editor]
  );

  const editorOnFocus = useCallback(() => {
    if (!editor.meta.isEditingTemplate && !editor.meta.allowEditing) return;
    if (editor.meta.action === 'contract') return;
    setEventState('editorBlur', false)
  }, [editor]);

  const clearTmpSelection = () => {
    editor.tmp._nextRange = null;
  };

  editor.onKeyDown = onKeyDown;

  console.log('Render editor simple ? ')

  return (
    <div id="editing-editor" className="editing">

      <CustomScrollbars
        className="editor-scroller"
        autoHide={editor.meta.isEditingTemplate || editor.meta.action === 'contract' ? false : true}
        autoHeight
      >
        <div id="top-editor-holder" className="top-editor-holder" ref={props.holderRef}>
          <EditorComments editor={editor} />
          {/* <BrowseChanges editor={editor} reviewIndex={reviewIndex} setReviewIndex={setReviewIndex} /> */}
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            id="editor-holder"
            className={className}
            style={style}
            placeholder="Enter some text…"
            autoFocus={!props.isEditingTemplate}
            readOnly={props.readOnly}
            spellCheck={false}
            onKeyDown={editorOnKeyDown}
            onDragOver={editor.onDragOver}
            onBlur={editorOnBlur}
            onFocus={editorOnFocus}
            onClick={clearTmpSelection}
            onMouseDown={clearTmpSelection}
            // decorate={decorate}
          />
        </div>
      </CustomScrollbars>
    </div>
  );
};

// Code for printing 'number of updates' text
// above and below the preview editor

function PreviewUpdatesHolder({ props, contract }) {
  // return <PreviewUpdates contract={contract} props={props} />
  return (
    <ContractUpdateContext.Provider initialValue={{}}>
      <PreviewUpdates contract={contract} props={props} />
    </ContractUpdateContext.Provider>
  );
}

function PreviewUpdates({ props, contract }) {
  // const editor = useSlate();
  
  const { step } = useHighlightContractUpdates();
  const previewUpdates = useContractUpdates();
  const { previewUpdatesText } = props;
  const updates = previewUpdates;
  const updatesAbove = (updates && updates.above) || 0;
  const updatesBelow = (updates && updates.below) || 0;

  const goAbove = () => step('above');
  const goBelow = () => step('below');

  return (
    <>
      <div
        onMouseDown={goAbove}
        className={'preview-updates preview-updates-above' + (updatesAbove > 0 ? ' content' : '')}
      >
        <span className={'preview-updates-counter' + (updatesAbove > 0 ? ' has-updates' : '')}>
          {updatesAbove}
        </span>
        {previewUpdatesText && (
          <span className="text">
            {updatesAbove === 1 ? <IntlMessages id="desc.change" /> : <IntlMessages id="desc.changes" />}{' '}
            <IntlMessages id="desc.above" />
          </span>
        )}
      </div>
      <div
        onMouseDown={goBelow}
        className={'preview-updates preview-updates-below' + (updatesBelow > 0 ? ' content' : '')}
      >
        <span className={'preview-updates-counter' + (updatesBelow > 0 ? ' has-updates' : '')}>
          {updatesBelow}
        </span>
        {previewUpdatesText && (
          <span className="text">
            {updatesBelow === 1 ? <IntlMessages id="desc.change" /> : <IntlMessages id="desc.changes" />}{' '}
            <IntlMessages id="desc.below" />
          </span>
        )}
      </div>
    </>
  );
}

const HoveringFieldEditorContainer = ({ isEditingTemplate, onEscapeFnRef, externalScrollControlY }) => {
  const hoveringToolbar = useEventState('hoveringToolbar', true);
  if (!hoveringToolbar || !isEditingTemplate) return null;
  return <HoveringFieldEditor externalScrollControlY={externalScrollControlY} onEscapeFnRef={onEscapeFnRef} />;
};

const HoveringFieldEditor = ({ externalScrollControlY, onEscapeFnRef }) => {
  const ref = useRef();
  const lastSelection = useRef(null);
  const entry = useRef({ node: null, path: null });
  const recentlyClosed = useRef(false);

  const [inlineMenuData, setInlineMenuData] = useState(null);
  
  let menuPlacement = 'side'
  /* if (inlineMenuData) {
    if (inlineMenuData[0].placement) menuPlacement = inlineMenuData[0].placement
    else if(inlineMenuData[0] === 'text') menuPlacement = 'top'
  } */
  const [recalculatePosition, setRecalculatePosition] = useState(false);
  const editor = useSlate();
  const componentScrollCallback = useRef({ fn: null });

  const collapseSelection = useCallback(() => {
    const newSelection = {
      anchor: editor.selection.anchor,
      focus: JSON.parse(JSON.stringify(editor.selection.anchor)),
    };
    setTimeout(() => {
      Transforms.setSelection(editor, newSelection);
    }, 10)
    
    return newSelection;
  }, [editor]);

  const close = useCallback((options = {}) => {
    const { reselect = false, collapse = false } = options;
    const el = ref.current;
    el.removeAttribute('style');
    recentlyClosed.current = !reselect;
    setInlineMenuData(null);
    ReactEditor.focus(editor);

    if (collapse) {
      lastSelection.current = collapseSelection();
    } else {
      setTimeout(() => {
        const lastSel = lastSelection.current;
        lastSelection.current = editor.selection;
        Transforms.select(editor, lastSel);
      }, 10);
    }
  }, [ref, setInlineMenuData, editor, collapseSelection]);

  onEscapeFnRef.current = close

  // Used to cause re-positioning upon scroll.
  useEffect(() => {
    externalScrollControlY.fn = (evt) => {
      setRecalculatePosition(evt.target.scrollTop);
      if (typeof componentScrollCallback.current.fn === 'function') componentScrollCallback.current.fn(evt);
    };
    return () => {
      externalScrollControlY.fn = null;
    };
  }, [externalScrollControlY]);

  useEffect(() => {
    const el = ref.current;
    if (editor.meta.action !== 'contract' && !editor.meta.isEditingTemplate) {
      setInlineMenuData(null)
      return el?.removeAttribute('style');
    }
    const { selection } = editor;
    if (!el || !selection) {
      setInlineMenuData(null)
      return el?.removeAttribute('style');
    }
    
    // Prevent re-opening a recently closed menu.
    if (recentlyClosed.current && Path.equals(selection.anchor.path, lastSelection.current.anchor.path)) {
      return 
    } else {
      recentlyClosed.current = false;
    }
    
    if (
      !lastSelection.current ||
      !selection ||
      !Path.equals(selection.anchor.path, lastSelection.current.anchor.path)
    ) {
      lastSelection.current = selection;
    }

    let rect;
    let type;
    if (Range.isCollapsed(selection)) {
      const newEntry = editor.currentInlineEntry();
      if (!newEntry[0]) {
        el.removeAttribute('style');
        setInlineMenuData(null);
        return;
      }
      const [newNode] = newEntry;
      const hasMenu = inlineHasMenu(newNode);
      if (!hasMenu) {
        el.removeAttribute('style');
        setInlineMenuData(null);
        return;
      }
      let domElem;
      try {
        domElem = ReactEditor.toDOMNode(editor, newNode);
      } catch (e) {
        return 
      }
      rect = domElem.getBoundingClientRect();
      type = 'inline-menu';

      entry.current.node = newNode;

      // Only set entry if currently empty (entry[1] does not exist), or
      // the newEntry is of a different path (ie Path.equals is false)
      if (!entry.current.path || !Path.equals(newEntry[1], entry.current.path)) {
        entry.current.path = newEntry[1];
        setInlineMenuData([inlineMenuComponent(newNode), newEntry]);
      } else if (!inlineMenuData) {
        setInlineMenuData([inlineMenuComponent(newNode), newEntry]);
      }
    } else {
      const domSelection = window.getSelection();
      let domRange
      try {
        domRange = domSelection.getRangeAt(0);
      } catch (err) {
        return
      }
      rect = domRange.getBoundingClientRect();

      const entry = Editor.leaf(editor, editor.selection);
      setInlineMenuData(['text', entry]);
      type = 'text';
    }

    if (rect.top < 103) {
      el.removeAttribute('style');
      setInlineMenuData(null);
      return;
    }
    if (menuPlacement === 'top' /* || type === 'text' */) {
      el.style.top = `${rect.top + window.pageYOffset - 14 - el.offsetHeight}px`;
      el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;

      el.classList.add('placement-top');
    } else {
      let top = rect.top + window.pageYOffset - 13;
      const windowHeight = window.innerHeight;
      if (top + 250 > windowHeight) {
        const elHeight = el.offsetHeight;
        top = top - elHeight + 10;

        // If the menu element height is very low, it is likely
        // that it has just spawned and has not yet fully loaded
        // to its full height. Then adjust the height properly after
        // a short while
        if (elHeight < 20) {
          setTimeout(() => {
            el.style.top = top + elHeight - el.offsetHeight + 'px';
          }, 10);
        }

        el.classList.add('bottom-up');
      } else {
        el.classList.remove('bottom-up');
      }

      el.style.top = `${top}px`;
      el.style.left = `${rect.left + window.pageXOffset + rect.width + 15}px`;
    }

    el.style.opacity = 1;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.selection, recalculatePosition, editor, menuPlacement]);

  let componentContent;
  
  if (inlineMenuData && inlineMenuData[0] === 'text') {
    const [, newEntry] = inlineMenuData || [];
    const [node, path] = newEntry || [];
    componentContent = (
      <TextSelect
        editor={editor}
        node={node}
        path={path}
        componentScrollCallback={componentScrollCallback}
        close={close}
      />
    );
  } else {
    const [inlineMenu, newEntry] = inlineMenuData || [];
    const [node, path] = newEntry || [];
    componentContent =
      node && inlineMenu && inlineMenu.component ? (
        <inlineMenu.component
          editor={editor}
          node={node}
          path={path}
          componentScrollCallback={componentScrollCallback}
          close={close}
        />
      ) : null;
  }

  return (
    <Portal>
      <div ref={ref} className={'inline-editor-menu ' + (menuPlacement === 'top' ? 'top' : 'full')}>
        {componentContent}
        <Button
          type="link"
          className="close"
          size="small"
          icon={<i className="mdi mdi-close" />}
          onClick={close}
        />
      </div>
    </Portal>
  );
};

const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

export default memo(LegalEditor);
