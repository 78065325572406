import React from 'react';
import { ocount, isObject } from 'core/utils/general';
import { Tooltip } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { Contract } from 'core/interfaces';

export function getSteps({ menu, location, activePaths }) {
  const flatActiveAll = menu.reduce(makeFlat(activePaths), []);
  const activeIndex = flatActiveAll.findIndex((menuItem) => menuItem.fullPath === location.pathname);
  const next = menuStepFindNext(flatActiveAll, activeIndex);
  const prev = menuStepFindPrev(flatActiveAll, activeIndex);
  return { next, prev };
}

export default function creationInputMenu({
  menu,
  location,
  inputState,
  className = '',
  menuRef,
  activePaths,
  contract,
  isSidebarMini,
}) {
  if (!menu) {
    console.error('menuBuilder received no menu');
    return [];
  }
  const flatActiveAll = menu.reduce(makeFlat(activePaths), []);

  /* console.log('menu build debug ', {
    menu, inputState,
    activePaths,
    flatActiveAll
  }) */

  const activeIndex = flatActiveAll.findIndex((menuItem) => menuItem.fullPath === location.pathname);

  const next = menuStepFindNext(flatActiveAll, activeIndex);
  const prev = menuStepFindPrev(flatActiveAll, activeIndex);

  const element = (
    <div ref={menuRef} className={'draft-input-menu ' + className}>
      <ul className="draft-input-menu-nav top">
        {menu
          .filter((child) => activePaths[child.paths])
          .map((item, index) => (
            <InputMenuItem
              key={item.fullPath}
              item={item}
              parentId={item.id}
              inputState={inputState}
              activeIndex={activeIndex}
              flatActiveAll={flatActiveAll}
              activePaths={activePaths}
              contract={contract}
              isSidebarMini={isSidebarMini}
            />
          ))}
      </ul>
    </div>
  );
  return { element, next, prev };
}

function InputMenuLink({ name, numCount, isSidebarMini }) {
  return (
    <>
      {numCount !== false && <span className="draft-input-menu-item-counter">{numCount}</span>}
      {isSidebarMini ? (
        <Tooltip title={name} placement="right">
          <div className="draft-input-menu-item-text ml-2 pt-1 ">
            <i className="mdi mdi-disk" />
            <small className="mini-text">{name}</small>
          </div>
        </Tooltip>
      ) : (
        <span className="draft-input-menu-item-text">{name}</span>
      )}
    </>
  );
}

export function getItemName(contract, route) {
  const language = Contract.getLanguage(contract);
  const ui = Contract.getUi(contract);

  let page = ui;
  const paths = route.paths.slice(1);
  for (const path of paths) {
    page = page.pages[path];
  }
  return (page && page.header && page.header[language]) || 'Unknown';
}

function InputMenuItem({
  item,
  parentId = '',
  parents = [],
  inputState,
  activeIndex,
  flatActiveAll,
  activePaths,
  contract,
  isSidebarMini,
}) {
  let numCount =
    typeof item.numCounter !== 'string'
      ? false
      : inputState && isObject(inputState[item.numCounter])
      ? ocount(inputState[item.numCounter])
      : 0;
  const myIndex = flatActiveAll.findIndex((it) => it === item);
  const isActive = myIndex === activeIndex;
  const isLast = myIndex === flatActiveAll.length - 1;

  const header = getItemName(contract, item);
  const history = useHistory()
  const idTag = parents ? parents.map((x) => x.id + '-') + item.id : item.id;

  const linkTarget = item.fullPath + '#creating-input-' + idTag;

  let LinkItem = null;
  if (false) {
    LinkItem = ({ children, to }) => (
      <span
        className="clickable"
        onClick={() => {
          console.log(' to ', to);
          history.replace(to)
        }}
      >
        {children}
      </span>
    );
  } else {
    LinkItem = ({ children, to }) => <NavLink to={item.fullPath}>{children}</NavLink>;
  }

  const menuItems = [
    <div key={item.fullPath}>
      <li
        className={
          'draft-input-menu-indicator' + (myIndex < activeIndex ? ' visited' : '') + (isLast ? ' last' : '')
        }
      ></li>
      {isActive && <div className={'active-item'}></div>}
      <li
        className={
          'draft-input-menu-item' +
          (item.collapse ? ' dropdown' : '') +
          (myIndex < activeIndex ? ' visited' : '') +
          (isActive ? ' active' : '') +
          (isLast ? ' last' : '')
        }
      >
        {item.collapse ? (
          <InputMenuLink name={header} numCount={numCount} isSidebarMini={isSidebarMini} />
        ) : (
          <LinkItem to={item.fullPath}>
            <InputMenuLink name={header} numCount={numCount} isSidebarMini={isSidebarMini} />
          </LinkItem>
        )}
      </li>
    </div>,
  ];
  // console.log('parents are ', { parents, idTag });
  if (item.children) {
    menuItems.push(
      <ul key={'sub_' + item.fullPath} className="draft-input-menu-nav">
        {item.children
          .filter((child) => activePaths[child.paths])
          .map((child, index) => {
            const key = 'subcm-' + parentId + child.id + index;
            return (
              <InputMenuItem
                key={key}
                item={child}
                parentId={item.id}
                parents={[...parents, item]}
                inputState={inputState}
                activeIndex={activeIndex}
                flatActiveAll={flatActiveAll}
                activePaths={activePaths}
                contract={contract}
                isSidebarMini={isSidebarMini}
              />
            );
          })}
      </ul>
    );
  }
  return <React.Fragment key={item.fullPath}>{menuItems}</React.Fragment>;
}

function makeFlat(activePaths) {
  return function flatten(acc, curr) {
    if (!activePaths[curr.paths]) return acc;

    if (curr.children && curr.children.length > 0) {
      acc.push(curr);
      // console.log('makeflat curr children ', curr)
      for (const item of curr.children) flatten(acc, item);
    } else acc.push(curr);
    return acc;
  };
}

function menuStepFindNext(flatActiveAll, activeIndex) {
  // Find next starting from activeIndex
  for (let i = activeIndex + 1; i < flatActiveAll.length; i++) {
    if (flatActiveAll[i].collapse) continue;
    return flatActiveAll[i];
  }
  return null;
}
function menuStepFindPrev(flatActiveAll, activeIndex) {
  // Find previos starting from activeIndex
  if (activeIndex === 0) return null;
  for (let i = activeIndex - 1; i >= 0; i--) {
    if (flatActiveAll[i].collapse) continue;
    return flatActiveAll[i];
  }
  return null;
}
