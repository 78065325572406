export const obligorsAgentModule = {
  id: 'obligorsAgentModule',
  dependencies: { state: ['borrower.party/obligorsagent', 'guarantor.party/obligorsagent'] },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    let agentEntity;
    let agentItem =
      state.input.borrower &&
      api.utils.general.ofindValues(state.input.borrower, (item) => item['party/obligorsagent'] === true);
    if (agentItem) {
      agentEntity = this.api.interfaces.Entity.getById(state, agentItem.borrowerEntity.id);
    }
    if (!agentItem) {
      agentItem =
        state.input.guarantor &&
        api.utils.general.ofindValues(state.input.guarantor, (item) => item['party/obligorsagent'] === true);
      if (agentItem) {
        agentEntity = this.api.interfaces.Entity.getById(state, agentItem.guarantorEntity.id);
      }
    }

    if (!agentItem || !agentEntity) {
      this.setVariable('obligorsAgent', undefined);
      this.setVariable('obligorsAgentRealName', undefined);
      this.setVariable('ObligorsAgent', undefined);
      this.setVariable('ObligorsAgentRealName', undefined);
      return;
    }

    let agentName;
    const agentMapped = this.mapCompanies([agentEntity.id], state);
    if (agentMapped.match && agentMapped.fullText) agentName = agentMapped.fullText;
    else agentName = agentItem['party/definition'] || agentEntity.name;

    this.setVariable('obligorsAgent', agentName);
    this.setVariable('obligorsAgentRealName', agentEntity.name);
    this.setVariable('ObligorsAgent', agentName);
    this.setVariable('ObligorsAgentRealName', agentEntity.name);
  },
};
