import React, { useState, useEffect } from 'react';
import { Form, Select, Modal, Input, Drawer, Button, Divider, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateRealPerson, insertRealPerson } from 'appRedux/actions';
import { InfoCircleOutlined } from '@ant-design/icons';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import api from 'utils/api';
import { PERSON_RELATION_CAPACITIES } from 'core/config/constants';
import { validateEmail } from '../../utils/validation';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const { Option } = Select;

export default function EditRealPerson({
  container = 'modal',
  placement = 'left',
  id,
  onCancel,
  childPersons,
  onEditChildPerson,
  legalPerson,
  onRemoveChildPerson,
  onFinishCallback,
  saveRelation,
}) {
  const [editId, setEditId] = useState(null);
  const formatMessage = useIntlMessage();

  useEffect(() => {
    setEditId(id);
  }, [id]);

  const cancel = () => {
    setEditId(null);
    if (typeof onCancel === 'function') onCancel();
  };

  if (id && !editId) return null;
  const title = id ? formatMessage('app.persons.editPerson') : formatMessage('app.persons.addPerson');

  if (container === 'drawer') {
    return (
      <DrawerContainer onCancel={cancel} placement={placement} title={title}>
        <Edit
          id={editId}
          onCancel={cancel}
          withButtons
          placement={placement}
          childPersons={childPersons}
          onEditChildPerson={onEditChildPerson}
          legalPerson={legalPerson}
          onRemoveChildPerson={onRemoveChildPerson}
          onFinishCallback={onFinishCallback}
          saveRelation={saveRelation}
        />
      </DrawerContainer>
    );
  } else {
    return (
      <ModalContainer onCancel={cancel} title={title}>
        <Edit
          id={editId}
          onCancel={cancel}
          childPersons={childPersons}
          onEditChildPerson={onEditChildPerson}
          legalPerson={legalPerson}
          onRemoveChildPerson={onRemoveChildPerson}
          onFinishCallback={onFinishCallback}
          saveRelation={saveRelation}
        />
      </ModalContainer>
    );
  }
}

function DrawerContainer({ children, onCancel, placement, title }) {
  return (
    <Drawer
      title={title}
      placement={placement}
      width={720}
      onClose={onCancel}
      visible={true}
      bodyStyle={{ paddingTop: 40, paddingBottom: 80 }}
    >
      {children}
    </Drawer>
  );
}

function ModalContainer({ children, onCancel, title }) {
  return (
    <Modal
      title={title}
      visible={true}
      onCancel={onCancel}
      cancelText={<IntlMessages id="desc.Cancel" />}
      okText={<IntlMessages id="desc.Save" />}
      okButtonProps={{
        form: 'edit-person-form',
        key: 'submit',
        htmlType: 'submit',
      }}
      backdrop={'static'}
      size={'large'}
      className="modal-medium"
    >
      {children}
    </Modal>
  );
}

function Edit({
  id,
  onCancel,
  withButtons = false,
  placement,
  childPersons,
  onEditChildPerson,
  legalPerson,
  onRemoveChildPerson,
  onFinishCallback,
  saveRelation,
}) {
  const dispatch = useDispatch();
  const realPersons = useSelector((state) => state.realPersons);
  const [connectPersonSelect, setConnectPersonSelect] = useState(null);
  const formatMessage = useIntlMessage();
  const [form] = Form.useForm();
  const legalPersonId = legalPerson && legalPerson.id;

  // The person can either be found on the state (realPersons) or the passed childPersons
  let realPerson = null;
  let isChildPerson = false;
  if (id) {
    realPerson = realPersons.find((person) => person.id === id);
    if (!realPerson) {
      realPerson = childPersons.find((person) => person.id === id);
      isChildPerson = true;
    }
  }

  // If editing, but no person object
  if (id && !realPerson) return null;

  const capacitiesChildren = PERSON_RELATION_CAPACITIES.map((capacity) => {
    return {
      id: capacity,
      value: formatMessage(`app.persons.capacities.${capacity}`),
    };
  });

  const onFinish = (values) => {
    // Need to remove form properties that should not be on the person.
    const { capacities, representative = false } = values;
    // delete values.capacities;
    console.log('save real person values ', values);
    if (legalPersonId) values.legalPersonId = legalPersonId;

    if (legalPersonId && connectPersonSelect) {
      api
        .post(`/realpersons/${connectPersonSelect}/connectlegalperson`, {
          legalPersonId,
          capacities,
          representative,
        })
        .then((resRelations) => {
          realPerson.PersonRelation = { capacities, representative };
          dispatch(insertRealPerson(realPerson));
          onRemoveChildPerson(connectPersonSelect);
          onCancel();
        });
    } else if (id) {
      const postData = values;
      if (isChildPerson) delete postData.legalPersonId;
      api
        .put(`/realpersons/${id}`, postData)
        .then((res) => {
          /* if (!isChildPerson) {
            values.PersonRelation = { capacities };
            dispatch(updateRealPerson(id, values));
          } else {
            onEditChildPerson(id, values);
          } */
          notification.success({
            message: formatMessage('app.persons.saved'),
          });
        })
        .catch((err) => {})
        .finally(() => {
          if (!isChildPerson) {
            values.PersonRelation = { capacities, representative };
            dispatch(updateRealPerson(id, values));
          } else {
            onEditChildPerson(id, values);
          }
          onCancel();
        });
    } else {
      api
        .post('/realpersons', values)
        .then((res) => {
          const relation = { legalPersonId, realPersonId: res.data.id, capacities, representative };
          values.PersonRelation = { capacities, representative };
          dispatch(insertRealPerson({ ...values, id: res.data.id }));

          notification.success({
            message: formatMessage('app.persons.saved'),
          });
          if (onFinishCallback)
            onFinishCallback({ type: 'add', values: { ...values, id: res.data.id }, relation });
          onCancel();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const onConnectPersonChange = (personId) => {
    setConnectPersonSelect(personId);
  };

  // Capacities and being representative can only be updated on the current legalPerson relation (= not on childPerson edit)
  let capacityValues = [];
  let representative = false;
  if (!isChildPerson && realPerson && realPerson.PersonRelation) {
    capacityValues = realPerson.PersonRelation.capacities;
    representative = realPerson.PersonRelation.representative;
  }

  if (connectPersonSelect) {
    realPerson = childPersons.find((p) => p.id === connectPersonSelect);
  }

  const addNewWithChildren = !id && childPersons && childPersons.length > 0;
  console.log('editing real person ', realPerson, isChildPerson, childPersons);
  return (
    <>
      {addNewWithChildren && (
        <h3>
          <IntlMessages id="app.persons.existingPersonAdd" /> {legalPerson.name}
        </h3>
      )}
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="edit-person-form"
        initialValues={{
          ...realPerson,
          emails: Array.isArray(realPerson?.emails) ? realPerson.emails : [],
          capacities: capacityValues,
          representative,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {true && (
          <>
            {addNewWithChildren && (
              <Form.Item label={<IntlMessages id="app.persons.existingPersonName" />} name="connectPerson">
                <Select
                  showSearch
                  onChange={onConnectPersonChange}
                  optionFilterProp="children"
                  value={connectPersonSelect}
                  filterOption={(input, option) => {
                    if (!option.label || typeof option.label !== 'string') return false;
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  {childPersons.map((p) => (
                    <Option key={p.id} value={p.id} label={`${p.firstName} ${p.lastName}`}>
                      {p.firstName} {p.lastName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {!connectPersonSelect && (
              <>
                {addNewWithChildren && (
                  <>
                    <Divider />
                    <h3>
                      <IntlMessages id="app.persons.createNewPerson" />
                    </h3>
                  </>
                )}

                <RealPersonEditFields />
              </>
            )}
          </>
        )}
        {legalPersonId && !isChildPerson && (
          <Form.Item
            label={<IntlMessages id="app.persons.capacities" />}
            name="capacities"
            tooltip={{
              title: <IntlMessages id="app.persons.capacitiesTooltip" />,
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: <IntlMessages id="app.persons.validation.capacities" />,
              },
            ]}
          >
            <Select mode="tags">
              {capacitiesChildren.map((cc) => (
                <Option key={cc.id}>{cc.value}</Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {legalPersonId && !isChildPerson && (
          <Form.Item
            label={<IntlMessages id="app.persons.representative" />}
            name="representative"
            valuePropName="checked"
            /* tooltip={{
              title: <IntlMessages id="app.persons.capacitiesTooltip" />,
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: <IntlMessages id="app.persons.validation.capacities" />,
              },
            ]} */
          >
            <Checkbox />
          </Form.Item>
        )}

        {withButtons && (
          <div className={'m-3 float-' + (placement === 'left' ? 'right' : 'left')}>
            <Button onClick={onCancel}>
              <IntlMessages id="desc.Cancel" />
            </Button>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="desc.Save" />
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}

export const RealPersonEditFields = ({ emailRequired, small, ...rest }) => {
  function Label({ children }) {
    if (small) return <small>{children}</small>;
    return children;
  }
  const formatMessage = useIntlMessage();
  return (
    <>
      <Form.Item
        {...rest}
        label={
          <Label>
            <IntlMessages id="app.general.FirstName" />
          </Label>
        }
        name="firstName"
        rules={[
          { required: true, whitespace: true, message: formatMessage('app.persons.validation.firstName') },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...rest}
        label={
          <Label>
            <IntlMessages id="app.general.LastName" />
          </Label>
        }
        name="lastName"
        rules={[
          { required: true, whitespace: true, message: formatMessage('app.persons.validation.lastName') },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...rest}
        label={
          <Label>
            <IntlMessages id="app.general.PersonIdNumber" />
          </Label>
        }
        name="identificationNumber"
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...rest}
        label={
          <Label>
            <IntlMessages id="app.general.Street" />
          </Label>
        }
        name="street"
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...rest}
        label={
          <Label>
            <IntlMessages id="app.general.City" />
          </Label>
        }
        name="city"
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...rest}
        label={
          <Label>
            <IntlMessages id="app.general.Zip" />
          </Label>
        }
        name="zipcode"
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...rest}
        label={
          <Label>
            <IntlMessages id="app.general.Jurisdiction" />
          </Label>
        }
        name="jurisdiction"
        rules={[
          {
            required: true,
            whitespace: true,
            message:
              formatMessage('app.general.Jurisdiction') + formatMessage('app.general.fields.fieldRequired'),
          },
        ]}
      >
        <Select>
          <Select.Option value={'se'}>
            <IntlMessages id="app.countries.se" />
          </Select.Option>
          <Select.Option value={'dk'}>
            <IntlMessages id="app.countries.dk" />
          </Select.Option>
          <Select.Option value={'no'}>
            <IntlMessages id="app.countries.no" />
          </Select.Option>
          <Select.Option value={'fi'}>
            <IntlMessages id="app.countries.fi" />
          </Select.Option>
          <Select.Option value={'uk'}>
            <IntlMessages id="app.countries.uk" />
          </Select.Option>
        </Select>
      </Form.Item>
      <CollectPersonEmails required={emailRequired} small={small} {...rest} />
    </>
  );
};

export function CollectPersonEmails({ required = true, small, ...rest }) {
  const formatMessage = useIntlMessage();
  const label = small ? (
    <small>
      <IntlMessages id="app.persons.emails" />
    </small>
  ) : (
    <IntlMessages id="app.persons.emails" />
  );

  return (
    <Form.Item
      {...rest}
      label={label}
      name="emails"
      tooltip={{
        title: <IntlMessages id="app.persons.emailsTooltip" />,
        icon: <InfoCircleOutlined />,
      }}
      rules={[
        {
          required,
          message: <IntlMessages id="app.persons.validation.emailRequired" />,
        },
        {
          validator: async (_, values) => {
            if (!required && !values) return Promise.resolve();
            if (!values) throw new Error(formatMessage('app.persons.validation.emailFormat'));
            values.forEach((email) => {
              if (!validateEmail(email)) throw new Error(formatMessage('app.persons.validation.emailFormat'));
            });
          },
        },
      ]}
    >
      <Select mode="tags" />
    </Form.Item>
  );
}
