import { Editor } from '../../../../import/slate';

export function feedReferencesData(content, bookmarks, refBindings, contractSettings) {
  // const listFormat
  for (const [node] of Editor.nodes(
    { children: content },
    {
      match: (n) => n.variant === 'ref',
      at: [],
      voids: true,
    }
  )) {
  }
}
