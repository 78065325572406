import React, { useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useContract, useIsTemplateStudio, useHighlightContractUpdates, useEventState } from 'hooks/';
import OneEditor from 'components/editor/OneEditor';
import { useEditor } from 'slate-react';
import { Contract } from 'core/interfaces';

export default memo(function StudioEditor({ action }) {
  const editor = useEditor();
  const contract = useContract();
  const location = useLocation();
  const isTemplate = useIsTemplateStudio();
  const { highlight } = useHighlightContractUpdates({ isTemplate });
  const liveUpdate = useEventState('liveUpdate', true);

  useEffect(() => {
    // editor.meta.isEditingTemplate
    editor.meta.action = action;
    const editorElement = document.getElementById('editor-holder');
    if (!editorElement) return;

    if (editor.meta.isEditingTemplate) {
      editorElement.classList.add('mode-template');
      editorElement.classList.remove('mode-input');
      editorElement.classList.remove('mode-contract');
    } else if (action === 'input') {
      editorElement.classList.remove('mode-template');
      editorElement.classList.add('mode-input');
      editorElement.classList.remove('mode-contract');
    } else if (action === 'contract') {
      editorElement.classList.remove('mode-template');
      editorElement.classList.remove('mode-input');
      editorElement.classList.add('mode-contract');
    }
  }, [action, editor]);

  if (!contract) return <div>Nothing to preview</div>;

  const settings = Contract.getSettings(contract);

  if (location.search) {
    const search = location.search.substr(1);
    const parts = search.split('=');
    console.log('parts', parts);
    if (parts.length === 2 && (parts[0] === 'iid' || parts[0] === 'tid')) {
      setTimeout(() => {
        if (parts[0] === 'iid') editor.scrollToItemId(parts[1], { highlight: true });
        if (parts[0] === 'tid') editor.scrollToTemplateId(parts[1], { highlight: true });
      }, 250);
    }
  }

  /* if (showEditorStructure) return <EditorStructureView />; */

  return (
    <div className={'studio-editor-container-wrapper live-updates-'+(liveUpdate.toString())}>
      <OneEditor
        previewUpdates={!isTemplate}
        previewUpdatesText={!isTemplate}
        onScrollY={highlight}
        noHeaderCounting={settings.format && settings.format.noHeaderCounting}
        creating
        interactive={action === 'contract'}
        action={action}
        preview={true}
        isEditingTemplate={isTemplate}
      />
    </div>
  );
});

