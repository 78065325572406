import React, { useMemo } from 'react';
import { Layout } from 'antd';
import { useContract } from 'hooks';
import { useSelector } from 'react-redux';
import { Sidebar, Topbar, NewSidebar } from './WrapperMenus';
import TemplateInputSectionsMenu from '../TemplateInputSections/';
import applyLogic from 'core/utils/logic/applyLogic';
import { Contract } from 'core/interfaces';
import { DrawerHamburger } from 'containers/App/MainApp';
import StudioContainer from '../StudioContainer/';
import config from 'config/config';

const { UI_VERSION } = config?.ui?.studio || {};
const { Content } = Layout;

export default function Wrapper({ children, subject, action }) {
  const contract = useContract();
  const state = useSelector((state) => state);
  const createRoutes = Contract.getUiRoutes(contract);

  const activePaths = useMemo(() => {
    return !createRoutes || !contract
      ? null
      : getActivePaths(createRoutes, state, subject, {
          create: { setup: Contract.getSetup(contract) },
          contract: contract,
        });
  }, [createRoutes, contract, state, subject]);

  return (
    <Layout className={`app-layout studio studio-subject-${subject} create-design-v${UI_VERSION}`}>
      {
        UI_VERSION === 2 ? <NewSidebar action={action} activePaths={activePaths} />
        : <Sidebar action={action} activePaths={activePaths} />
      }
      {/* <Sidebar action={action} activePaths={activePaths} /> */}
      {/* <NewSidebar action={action} activePaths={activePaths} /> */}
      <Layout>
        <DrawerHamburger className={'studio'} />
        <Topbar action={action} activePaths={activePaths} />
        <Content className={`layout-content`}>
          <div className="main-content-wrapper">
            <TemplateInputSectionsMenu />
            <StudioContainer action={action} activePaths={activePaths} />
            {/* children || null */}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

/**
 *
 * @param {array} routes            The routes specified in `contract.ui.routes`
 * @param {object} state            Redux state
 * @param {string} studioSubject    If we're managing a `draft` or a `template`
 * @param {object} options          Options. Passed through to the checkRouteActive function as parameters for applying logic.
 * @returns {object}                List of the active status of each ui route (comma separated keys if nested routes), such as:
 * {
 *    input,covenants: true
 *    input,covenants,activation: true
 * }
 */

function getActivePaths(routes, state, studioSubject, options = {}) {
  if (!routes || !Array.isArray(routes)) return {};
  let activeStatus = {};
  for (const route of routes) {
    activeStatus[route.paths] = studioSubject === 'template' ? true : checkRouteActive(route, state, options);

    if (route.children)
      activeStatus = {
        ...activeStatus,
        ...getActivePaths(route.children, state, studioSubject, options),
      };
  }
  return activeStatus;
}

const checkRouteActive = (route, state, options) => {
  if (!route) return false;
  if (!route.acp) return true;
  return applyLogic(route.acp, state, { _meta: options });
};
