import React from 'react';
import { Dropdown, Menu, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import Widget from 'components/Widget/index';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import { Entity } from 'core/interfaces'
import { RemoveModal, userToName } from 'components/ui';

const EntityWidget = ({ customer, customerWasRemoved }) => {
  
  const { person, type } = Entity.getRelevantPersonFromCustomer(customer)

  const removeCustomer = () => {
    api
      .delete('/customers/' + customer.id)
      .then((result) => {
        customerWasRemoved(customer.id);
      })
      .catch((err) => {
        console.log('Error removing entity ', customer.id, err.response);
      });
  };
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link
          to={{
            pathname: '/entities/' + (customer && customer.id ? customer.id + '/overview' : '_null'),
            // state: { customer },
          }}
        >
          <IntlMessages id="general.goto" /> <IntlMessages id="general.entity" cap />{' '}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <RemoveModal
          onConfirm={removeCustomer}
          confirmText={
            <>
              <div>
                <IntlMessages id="app.general.confirmRemoval" /> <IntlMessages id="general.entity" />?
              </div>
              <div className="mt-4 text-danger">
              <IntlMessages id="app.general.customer.warnRemoval" />
              </div>
            </>
          }
        >
          <span className="">
            <IntlMessages id="desc.Remove" /> <IntlMessages id="general.entity" />
          </span>
        </RemoveModal>
      </Menu.Item>
    </Menu>
  );

  return (
    <Widget
      styleName="ch-capitalize card-sm-px"
      extra={
        customer && (
          <ul className="list-inline ml-auto mb-0 text-grey">
            <li>
              <Dropdown overlay={menu} trigger={['click']}>
                <span className="link ant-dropdown-link text-grey">
                  <i className="icon icon-chevron-down" />
                </span>
              </Dropdown>
            </li>
          </ul>
        )
      }
      title="Entity"
    >
      <div className="text-center">
        <i
          className={'size-60 mb-3 icon ico-lg icon-' + (type === 'realPerson' ? 'user-o' : 'company')}
          alt="entitylogo"
        />

        <h2 className="mb-3 mb-sm-4">
          {person && (type === 'legalPerson' ? person.name : userToName(person))}
        </h2>
        <Link
          to={{
            pathname: '/entities/' + (customer && customer.id ? customer.id + '/overview' : '_null'),
          }}
        >
          <button className="btn btn-primary text-white mb-1">
            <IntlMessages id="general.goto" /> <IntlMessages id="general.entity" cap />{' '}
          </button>
        </Link>
      </div>
    </Widget>
  );
};

export default EntityWidget;
