

export const withVoid = editor => {

  const { isVoid } = editor;


  editor.isVoid = element => 
    element.type === 'tab' || (element.data && element.data._inActive) ? true 
    : isVoid(element)

  return editor
}
