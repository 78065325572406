const Create = {};

Create.routePath = (route) => "/" + route.path.join("/");
Create.getPage = (ui, route, options = {}) => {
  let page = ui;
  const paths = options.paths ? route : route.paths.slice(1);
  for (const path of paths) {
    page = page.pages[path];
  }
  return page;
};

Create.getPageByUrl = (ui, pathname) => {
  const { input, paths } = Create.getPageParts(ui, pathname);
  
  if (input !== "input") return null;

  return Create.getPage(ui, paths, { paths: true });
};

Create.getPageParts = (ui, pathname) => {
  const parts = pathname.split("/");
  if (parts[0] === "") parts.shift(); // Remove empty first entry.

  if (parts[0] !== "studio") return null;

  const [, , , input, ...paths] = parts;
  return { input, paths };
}

const findRoute = (routes, paths, idx) => {
  const routeName = paths[idx];
  const route = routes.find((r) => r.id === routeName);

  if (idx === paths.length - 1) return route;

  return findRoute(route.children, paths, idx + 1);
}

Create.getRouteByPath = (ui, pathname) => {
  const { paths } = Create.getPageParts(ui, pathname);
  return findRoute(ui.routes, paths, 0);
}

export { Create };
