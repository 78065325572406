export const activeConditionAnyUpdate = {
  id: 'activeConditionAnyUpdate',
  dependencies: {
    repeatableAdd: true,
    repeatableRemove: true,
    repeatableChange: true,
    ordinary: true,
    legalPersonAny: true,
  },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    // Do not run this if the engine is not loaded.
    const draftInfo = this.getDraftInfo();
    if (!draftInfo.status.loaded) return;

    const keys = this._tmpDraft.updatedACPkeys;
    if (!keys) {
      return this.log('No prepared activeCondition keys for acp onAnyUpdate!');
    }
    return (node, parents) => {
      if (!node.data || !node.data.acp) return;

      // localState differs from the `repeatable state` which is the state of the repeatable
      // originally being affected by the user action. However, as other repeatable items/nodes
      // may refer to data changed by the relevant user action, we need to allow such nodes
      // to conduct it's ACP check, but that needs to be done using the proper localState for
      // any such "foreign" repeatable item/node.
      const localState = api.utils.engine.getLocalState(node, parents, state, this.contract);

      if (api.logic.matchRule(node.data.acp, keys)) {
        const { changed, inActive } = this.checkNodeACPrule(node, state, localState);
        if (changed) {
          this.setInActive(node, inActive);
        }
      } else {
        this.log('didnt match rule???');
      }
      return;
    };
  },
};

export const checkNodeACPrule = function (node, state, localState) {
  // Would we ever need to set local state here?
  /* if (!localState) {
    if (
      node.data.repeatable &&
      node.data.uid &&
      state.input[node.data.repeatable]
    ) {
      localState =
        // FIXstate.input[node.data.repeatable].find(
          (rep) => rep._uid === node.data.uid
        ) || {};
    } else {
      localState = {};
    }
  } */

  const currentlyInActive = node.data._inActive;

  const passed = this.applyLogic(node.data.acp, {
    local: localState,
  });

  const shallBeInActive = !passed;

  const changed = currentlyInActive !== shallBeInActive;

  return { changed, inActive: shallBeInActive };
};
