import React from "react";
import { Modal, Button } from "antd";
import { useModalContext, setModalContext } from "hooks";
import { ModalContext } from "contexts/contexts";

import FileUpload from "./FileUpload"
import FilesConnected from "./FilesConnected";
import OutdatedStructure from "./OutdatedStructure";
import SigningStarted from "./SigningStarted";

const CUSTOM_TYPES = {
  "file-upload": {
    component: FileUpload,
    className: "modal-file-upload"
  },
  "files-connected": {
    component: FilesConnected,
    className: "modal-files-connected"
  },
  "outdatedStructure": {
    component: OutdatedStructure,
    className: "modal-oudated-structure"
  },
  "signingStarted": {
    component: SigningStarted,
  },
}

export default function CustomModal() {
  return (
    <ModalContext.Provider initialValue={null}>
      <DynamicModal />
    </ModalContext.Provider>
  );
}

const DynamicModal = () => {
  const data = useModalContext();

  const onClose = () => {
    setModalContext(null);
    if (typeof data.onClose === 'function') data.onClose();
  };
  const onOk = () => {
    if (typeof data.onOk === 'function') data.onOk()
    onClose()
  }

  const renderModalContent = (data) => {
    if (!data || !CUSTOM_TYPES[data.type] || !CUSTOM_TYPES[data.type].component) return null;
    const Component = CUSTOM_TYPES[data.type].component
    return <Component data={data} onClose={onClose} />
  }

  if (!data || !CUSTOM_TYPES[data.type] || !CUSTOM_TYPES[data.type].component) return null;
  const className = CUSTOM_TYPES[data.type].className

  const modalContent = renderModalContent(data);

  if (!modalContent) return null;

  const modalClassName = (className || "")+" "+("modal-"+(data.size ? data.size : 'medium'))

  return (
    <Modal
      title={data.title}
      visible={true}
      onOk={onOk}
      onCancel={onClose}
      maskClosable={false}
      backdrop={"static"}
      mask={false}
      centered
      // size="lg"
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      className={modalClassName}
      footer={
        <Button type="primary" onClick={onOk}>
          OK
        </Button>
      }
    >
      {modalContent}
    </Modal>
  );
}
