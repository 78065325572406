import React, { memo, useState, useRef, useEffect, useMemo } from 'react';
import { Menu, Dropdown, notification, Slider, Form, Row, Col, Input, Select, Button } from 'antd';
import { cx } from 'emotion';
import { useSlate, useFocused, useSelected } from 'slate-react';
import { Editor, Range, Transforms, Path, Node } from 'slate';
import { useContract, setContract, setEventState, useEventState } from 'hooks';
import { Contract, InputPaths } from 'core/interfaces';
import { useDispatch } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import uuid from 'uuid-random';
import RemoveModal from './RemoveModal';
import CustomScrollbars from 'util/CustomScrollbars';
import { removeInput } from '../../../../../containers/TemplateInputSections/utils';
import { ConditionActions, PrintCondition } from 'components/Rules/InputCondition';
import { defaultClause } from 'components/editor/legal/toolbar/BlocksInlines';
import { insertBlockByPath } from 'components/editor/legal/helpers/blocks';

export default memo((props) => {
  const dispatch = useDispatch();
  const contract = useContract();
  const [showRemove, setShowRemove] = useState(false);
  const [editField, setEditField] = useState(false);
  const editFieldRef = useRef(false);
  const holderRef = useRef(null);
  const { card, field, fieldName } = props;
  const cardId = card.id;
  const isInputField = field.type !== 'newline' && field.type !== 'hr' && !field.type !== 'vr';

  const language = Contract.getLanguage(contract);
  const currentCard = contract.data.ui.cards[cardId];

  const setTemplateEditCard = (type, method) => {
    setEventState('template_edit_input_sections', {
      type,
      method,
      selected: { card: cardId, input: fieldName },
    });
  };

  useEffect(() => {
    const onEvent = (evt) => {
      if (evt.detail.fieldName !== fieldName) {
        setEditField(false);
      }
    };
    window.addEventListener('select_input_edit', onEvent, false);
    return () => window.removeEventListener('select_input_edit', onEvent, false);
  }, [fieldName, setEditField]);

  const toggleEditField = () => {
    if (!isInputField) return;
    if (!editField)
      window.dispatchEvent(new CustomEvent('select_input_edit', { detail: { fieldName: fieldName } }));
    setEditField(!editField);
  };

  const closeEditField = () => setEditField(false);

  const onRemove = (removeCompletely) => {
    // Delete the input from the card, both from input_orders and the values prop

    const action = removeInput(contract, currentCard, fieldName, props.path, removeCompletely);
    dispatch(action);

    setContract({ ...contract });
    setShowRemove(false);
  };

  const inputsLength = card.inputs_order.length;
  const fieldIndex = card.inputs_order.findIndex((x) => x === fieldName);
  const isFirst = fieldIndex === 0;
  const isLast = fieldIndex + 1 === inputsLength;
  const updateCard = (prop, value) => {
    contract.data.ui.cards[card.id][prop] = value;
    setContract({
      ...contract,
    });
  };
  const moveUp = () => {
    if (isFirst) return;
    const newOrder = [...card.inputs_order];
    const otherValue = newOrder[fieldIndex - 1];
    newOrder[fieldIndex - 1] = newOrder[fieldIndex];
    newOrder[fieldIndex] = otherValue;
    updateCard('inputs_order', newOrder);
  };
  const moveDown = () => {
    if (isLast) return;
    const newOrder = [...card.inputs_order];
    const otherValue = newOrder[fieldIndex + 1];
    newOrder[fieldIndex + 1] = newOrder[fieldIndex];
    newOrder[fieldIndex] = otherValue;
    updateCard('inputs_order', newOrder);
  };

  const style = {};
  if (!isInputField) {
    style.height = '14px';
  }

  return (
    <div ref={holderRef} className="editing-input-item" style={style}>
      {showRemove && <RemoveModal type="input" onRemove={onRemove} setOpen={(open) => setShowRemove(open)} />}
      <div
        // className={'template-input-field-wrapper click-edit clickable' + (editField ? ' edit' : '')}
        className={cx(
          'template-input-field-wrapper',
          'click-edit',
          editField && 'edit',
          isInputField && 'clickable'
        )}
        onClick={toggleEditField}
      >
        &nbsp;
      </div>
      {/*
        
        */}
      <div className="actions-container">
        {!isFirst && (
          <span className="link mr-1" onClick={moveUp}>
            <i className="mdi mdi-arrow-up" />
          </span>
        )}
        {!isLast && (
          <span className="link mr-1" onClick={moveDown}>
            <i className="mdi mdi-arrow-down" />
          </span>
        )}
        <Dropdown
          className={'xx-' + fieldName}
          overlay={
            <Menu>
              <Menu.Item onClick={() => setTemplateEditCard('input', 'edit')}>
                <span>
                  <IntlMessages id={'studio.template.modal.input.edit'} />
                </span>
              </Menu.Item>
              <Menu.Item onClick={() => setShowRemove(true)}>
                <span>
                  <IntlMessages id={'studio.template.modal.input.remove'} />
                </span>
              </Menu.Item>
            </Menu>
          }
        >
          <span className="link">
            <i className="mdi mdi-settings" />
          </span>
        </Dropdown>
      </div>
      {editField && isInputField && (
        <InputEditClick
          {...props}
          language={language}
          contract={contract}
          closeEditField={closeEditField}
          editFieldRef={editFieldRef}
          holderRef={holderRef}
        />
      )}
      {isInputField && <FieldClickWrapper {...props} contract={contract} language={language} />}
    </div>
  );
});

const fieldClickActionTypes = [
  'text',
  'party',
  'date',
  'numeric',
  'numeric-steps',
  'multipleText',
  'select',
  'radio',
  'QA',
];

function FieldClickWrapper(props) {
  if (!fieldClickActionTypes.includes(props.field?.type)) return null;
  return <FieldClickAction {...props} />;
}

function FieldClickAction({ field, card, cardUid, fieldName, contract, path, language, ...rest }) {
  const editor = useSlate();
  const focused = useFocused();
  const editorBlurred = useEventState('editorBlur', true);
  let isQA = false;
  let QAtarget = null;
  let showClickAction =
    focused &&
    editor.selection &&
    Range.isExpanded(editor.selection) &&
    Path.equals(editor.selection.anchor.path, editor.selection.focus.path);
  // It's important to not check for data properties for all inputs if there is no selection
  if (showClickAction) {
    const [dataNodes] = Array.from(
      Editor.nodes(editor, { mode: 'lowest', match: (n) => n.data && n.type === 'field' })
    );
    if (dataNodes && dataNodes[0].variant !== 'opt') showClickAction = false;
  }

  if (!showClickAction && !editorBlurred) {
    isQA =
      field.type === 'QA' &&
      focused &&
      editor.selection &&
      Range.isCollapsed(editor.selection) &&
      editor.selection.anchor.offset === 0;
    if (isQA) {
      try {
        const parentNode = Node.get(editor, Path.parent(editor.selection.anchor.path));
        if (!['paragraph', 'clause'].includes(parentNode.type) || Node.string(parentNode) !== '') {
          isQA = false;
        }
        QAtarget = parentNode.type;
      } catch (err) {}
    }
    if (isQA) showClickAction = true;
  }
  if (!showClickAction) return null;

  const getRepeatableParent = () => {
    return Array.from(
      Editor.nodes(editor, {
        at: editor.selection,
        mode: 'lowest',
        match: (n) => n.data?.each_repeatable?.repeatable,
      })
    );
  };

  if (!isQA && cardUid && getRepeatableParent().length === 0) return null;

  const clickFieldAction = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!showClickAction) return;

    if (field.type === 'QA') {
      const label = field.label[language];
      const newClause = defaultClause(label, { marks: { bold: true } });
      if (cardUid) {
        newClause.data.qa = {
          fieldPathData: InputPaths.getCardAndField(contract, path),
        };
      } else {
        newClause.data.qa = {
          fieldPath: path,
        };
      }

      const insertAt = Path.parent(editor.selection.anchor.path);
      insertBlockByPath(editor, insertAt, newClause, {
        mode: 'sibling',
        direction: 'below',
      });
      return;
    }

    // The field is within a repeatable card
    if (cardUid) {
      // First, find any repeatable containers above the editor selected node.
      const [repeatableParent] = getRepeatableParent();

      if (!repeatableParent) {
        notification.warning({
          message: 'Not allowed',
          description: `You can only connect repeatable fields to text inside blocks that have been connected to the repeatable box first`,
          duration: 10,
        });
      } else {
        // Only make the input clickable if the current input card is the same type as the selected repeatable in the document
        if (repeatableParent && repeatableParent[0].data.each_repeatable.repeatable === card.id) {
          Transforms.wrapNodes(
            editor,
            {
              type: 'field',
              variant: 'item',
              data: {
                item_id: uuid(),
                template_id: 'default_item',
                each: {
                  key: fieldName,
                  level: 0,
                },
              },
            },
            { at: editor.selection, split: true }
          );
          // Transforms.forceDeselect(editor);
          Transforms.setSelection(editor, {
            anchor: editor.selection.anchor,
            focus: JSON.parse(JSON.stringify(editor.selection.anchor)),
          });
          console.log('Editor selection...1', editor.selection);
        } else {
          const contractLanguauge = contract.data.settings.language;
          const selectedCardId = repeatableParent[0].data.each_repeatable.repeatable;
          const cardHeader = contract.data.ui.cards[selectedCardId].header[contractLanguauge];
          notification.warning({
            message: 'Not allowed',
            description: `You can only connect text inside a repeatable block to a input of the same type: ${
              cardHeader || selectedCardId
            }`,
            duration: 10,
          });
        }
      }
    }
    // The field is within an ordinary card
    else {
      Transforms.wrapNodes(
        editor,
        {
          type: 'field',
          variant: 'vari',
          data: {
            item_id: uuid(),
            template_id: 'default_vari',
            name: `${card.id}.${fieldName}`,
          },
        },
        { at: editor.selection, split: true }
      );
      // Transforms.forceDeselect(editor);
      Transforms.setSelection(editor, {
        anchor: editor.selection.anchor,
        focus: JSON.parse(JSON.stringify(editor.selection.anchor)),
      });
    }
  };
  if (!showClickAction) return null;
  return <div onMouseDown={clickFieldAction} className="template-input-field-editor-action"></div>;
}

const widthStep = 8.33;
const widths = {};
for (let i = 0; i < 12; i++) {
  let val = Math.round(widthStep * (i + 1));
  widths[val] = val + '%';
}

function InputEditClick({
  fieldName,
  card,
  language,
  contract,
  closeEditField,
  editFieldRef,
  holderRef,
  field,
  ...rest
}) {
  // console.log('props ', { fieldName, field, card, rest });

  const updateField = (prop, value) => {
    contract.data.ui.inputs[fieldName][prop] = value;
    setContract({
      ...contract,
    });
  };
  /* const updateCard = (prop, value) => {
    contract.data.ui.cards[card.id][prop] = value;
    setContract({
      ...contract,
    });
  }; */

  const afterWidthChange = (val) => {
    const newWidth = Math.round(val / widthStep);
    updateField('cols', {
      sm: newWidth,
      md: newWidth,
      lg: newWidth,
    });
  };

  // Once it has opened, let it keep it position?
  // Otherwise, a resize could cause its position to change,
  // so that the mouse is no longer over it (onMouseOver calls
  // and closes it unexpectedly)
  const style = useMemo(() => {
    const fieldDOM = holderRef.current.offsetParent;
    const fieldRect = fieldDOM.getBoundingClientRect();
    return {
      top: fieldRect.top + 350 > window.innerHeight ? window.innerHeight - 360 : fieldRect.top - 2 + 'px',
      left: fieldRect.left - 398 + 'px',
    };
  }, [holderRef]);

  const width = field.cols && field.cols.md && Math.round(field.cols.md * widthStep);
  const label = field.label[language];

  const onChangeLabel = (evt) => {
    updateField('label', {
      ...field.label,
      [language]: evt.target.value,
    });
  };

  const onChangeAcp = (id) => {
    if (field.acp === id) {
      return console.log('Same.');
    }
    updateField('acp', id);
  };

  return (
    <div className="input-click-edit" style={style}>
      <div className="linebar d-flex justify-content-end fs-small">
        <i className="mdi mdi-close clickable mr-2 mt-1" onClick={closeEditField} />
      </div>
      <CustomScrollbars>
        <Form
          initialValues={{
            label: label,
            type: field.type,
          }}
          className="m-2 mt-0 px-3 pt-1"
          {...{
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
          }}
        >
          <Row>
            <Col sm={24} md={24}>
              <Form.Item name="label" label={'Label'} onChange={onChangeLabel}>
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={24}>
              <Form.Item name="type" label={'Type'}>
                <Select options={[{ key: 'text', value: 'text', label: 'Text' }]} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label={'Condition'} className="mt-0 mb-0" />
          <div className="d-flex">
            <ConditionActions item={field} onChange={onChangeAcp} className="w-50" />
            <PrintCondition item={field} />
          </div>

          <Form.Item label={'Width'} className="mt-3 mb-0" />
          {width && (
            <Slider
              marks={widths}
              step={null}
              defaultValue={width}
              min={17}
              className="border"
              onChange={afterWidthChange}
            />
          )}
        </Form>
      </CustomScrollbars>
    </div>
  );
}
