import React from 'react';
import { Popover, Steps, Button, Menu, Dropdown, Badge } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useHistory, useLocation, Link } from 'react-router-dom';

import { getSteps, getItemName } from './menuBuilder';
import { useContract, useIsTemplateStudio } from 'hooks';
import { Contract } from 'core/interfaces';
import IntlMessages from 'util/IntlMessages';

const Step = Steps.Step;

const customItem = (route, contract, activePaths, location, isActive, history, isTemplate) => {
  if (!route) return null;

  const title = getItemName(contract, route);

  if (!isTemplate && (!route.children || route.children.length === 0)) {
    return (
      <Step
        key={route.fullPath}
        title={
          <Popover placement="bottom" content={title}>
            <Link to={route.fullPath}>{title}</Link>
          </Popover>
        }
      />
    );
  }

  const activeChildren = route.children.filter((child) => activePaths[child.paths]);
  let totalActiveChildren = activeChildren.length,
    totalChildren = route.children.length;
  let currentChild = 'x';

  const childrenMenu = (
    <Menu>
      <Menu.Item disabled={!isTemplate} key={'x' + route.fullPath}>
        {isTemplate ? <Link to={route.fullPath}>{title}</Link> : title}
      </Menu.Item>
      <Menu.Divider />
      {activeChildren.map((child, index) => {
        if (location.pathname.includes(child.fullPath)) currentChild = index + 1;

        if (child.children && child.children.length > 0) {
          const activeGrandChildren = child.children.filter((grandchild) => activePaths[grandchild.paths]);
          totalActiveChildren += activeGrandChildren.length;
          totalChildren += child.children.length;
          return (
            <Menu.SubMenu
              key={child.fullPath}
              title={getItemName(contract, child)}
              onTitleClick={() => {
                if (isTemplate) history.push(child.fullPath);
              }}
            >
              {activeGrandChildren.map((grandchild, grandchildIndex) => {
                if (location.pathname.includes(grandchild.fullPath)) {
                  currentChild = index + grandchildIndex + 1;
                }
                return (
                  <Menu.Item key={grandchild.fullPath}>
                    <Link to={grandchild.fullPath}>{getItemName(contract, grandchild)}</Link>
                  </Menu.Item>
                );
              })}
            </Menu.SubMenu>
          );
        }
        return (
          <Menu.Item key={child.fullPath}>
            <Link to={child.fullPath}>{getItemName(contract, child)}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const hasInActiveChildren = totalChildren > totalActiveChildren;

  return (
    <Step
      key={route.fullPath}
      title={
        <div>
          <Dropdown overlay={childrenMenu} className="pt-2">
            <span>
              <DownOutlined className="input-steps-down ml-1 mr-3" />
              <span>{title}</span>
              <Badge
                className={'children-indicator-holder ' + (isActive ? 'active' : '')}
                count={currentChild + ' / ' + totalActiveChildren + (hasInActiveChildren ? '*' : '')}
                style={{ backgroundColor: '#438fcc' }}
              />
            </span>
          </Dropdown>
        </div>
      }
    />
  );
};

export default function ({ activePaths }) {
  const history = useHistory();
  const location = useLocation();
  const isTemplate = useIsTemplateStudio();

  const contract = useContract();
  const createRoutes = Contract.getUiRoutes(contract);

  const { next, prev } = getSteps({
    menu: createRoutes,
    location,
    activePaths,
  });
  const activeRoutes = createRoutes.filter((route) => activePaths[route.paths]);
  const activeIndex = activeRoutes.findIndex((route) => location.pathname.includes(route.fullPath));

  return (
    <div className="input-top">
      <div className="input-stepper">
        <Popover
          content={
            <div>{(prev && getItemName(contract, prev)) || <IntlMessages id="studio.noPreviousStep" />}</div>
          }
        >
          <Button
            type="link"
            disabled={!prev}
            onClick={() => {
              if (prev) history.push(prev.fullPath);
            }}
          >
            <i className="mdi mdi-arrow-left" />
          </Button>
        </Popover>
      </div>
      <div className="input-steps-container">
        <div className="input-steps">
          <Steps size="small" current={activeIndex}>
            {activeRoutes.map((route, index) => {
              return customItem(
                route,
                contract,
                activePaths,
                location,
                activeIndex === index,
                history,
                isTemplate
              );
            })}
          </Steps>
        </div>
      </div>
      <div className="input-stepper">
        <Popover
          placement="bottomRight"
          content={
            <div>{(next && getItemName(contract, next)) || <IntlMessages id="studio.noNextStep" />}</div>
          }
        >
          <Button
            type="link"
            disabled={!next}
            onClick={() => {
              if (next) history.push(next.fullPath);
            }}
          >
            <i className="mdi mdi-arrow-right" />
          </Button>
        </Popover>
      </div>
    </div>
  );
}
