import React from 'react';
import { Menu, Dropdown, Button, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { switchLanguage, userSignOut } from 'appRedux/actions';
import { supportedLanguages } from 'lngProvider/';
import { useHistory } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { useIsSidebarMini } from 'hooks';
import devGlobals from 'utils/devGlobals';

const DEV = process.env.REACT_APP_NODE_ENV === 'development';

export default function MainActions({ extra, onSignOut, dark }) {
  const isMini = useIsSidebarMini();

  let type;
  if (extra || isMini) type = 'nested';

  return (
    <div
      className={`flex-row justify-content-center ${dark ? '' : 'border-top'}`}
      style={{ paddingTop: '10px' }}
    >
      {extra || null}
      {type === 'nested' ? (
        <Dropdown
          overlay={
            <Menu>
              <LanguageButton type={type} />
              <LogoutButton type={type} onSignOut={onSignOut} />
              {DEV && <EnableGlobals type={type} />}
            </Menu>
          }
        >
          <Button type={dark && 'ghost'}>
            <i className={'mdi mdi-dots-horizontal ' + (dark ? 'text-white' : '')} />
          </Button>
        </Dropdown>
      ) : (
        <Button.Group>
          <LanguageButton type={type} />
          <LogoutButton type={type} onSignOut={onSignOut} />
          {DEV && <EnableGlobals type={type} />}
        </Button.Group>
      )}
    </div>
  );
}

function LanguageButton({ type, ...rest }) {
  const locale = useSelector(({ settings }) => settings.locale);
  const dispatch = useDispatch();

  const pickLanguage = (value) => dispatch(switchLanguage(supportedLanguages[value]));

  const languageMenu = Object.keys(supportedLanguages).map((lang) => (
    <Menu.Item key={lang}>
      <div onClick={() => pickLanguage(lang)} className="language-option">
        <i className={`flag flag-24 mr-2 flag-${supportedLanguages[lang].icon}`} />
        <span>{supportedLanguages[lang].name}</span>
      </div>
    </Menu.Item>
  ));

  if (type === 'nested') {
    return (
      <Menu.SubMenu
        {...rest}
        title={
          <span className="flag-cascader">
            <i className={`flag flag-24 mr-2 flag-${locale.icon}`} />
            <span>{locale.name}</span>
          </span>
        }
      >
        {languageMenu}
      </Menu.SubMenu>
    );
  }

  return (
    <Dropdown overlay={<Menu>{languageMenu}</Menu>} placement="topLeft">
      <Button className="language-btn">
        <i className={`flag flag-24 flag-${locale.icon}`} />
      </Button>
    </Dropdown>
  );
}

function LogoutButton({ type, onSignOut, ...rest }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const signOut = () => {
    if (typeof onSignOut === 'function') {
      onSignOut(() => dispatch(userSignOut()));
      return;
    }

    dispatch(userSignOut());
    setTimeout(() => {
      history.push('/signin');
    }, [200]);
  };
  if (type === 'nested') {
    return (
      <Menu.Item {...rest}>
        <span onClick={signOut}>
          <i className="mdi mdi-power-settings mr-2" />
          <IntlMessages id="app.userAuth.signOut" />
        </span>
      </Menu.Item>
    );
  }
  return (
    <Tooltip title={<IntlMessages id="app.userAuth.signOut" />}>
      <Button onClick={signOut}>
        <i className="mdi mdi-power-settings" />
      </Button>
    </Tooltip>
  );
}

function EnableGlobals({ type, ...rest }) {
  if (type === 'nested') {
    return (
      <Menu.Item {...rest}>
        <span onClick={devGlobals}>
          <i className="mdi mdi-console mr-2" />
          Enable Globals
        </span>
      </Menu.Item>
    );
  }
  return (
    <Button onClick={devGlobals}>
      <i className="mdi mdi-console" />
    </Button>
  );
}
