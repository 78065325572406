import React from "react";
import { connect } from "react-redux";
import Chart from "components/orgchart/orgchart";

import { updateInputs } from "appRedux/actions";

const topRightIcon = ["mdi mdi-arrow-right-drop-circle-outline", "color:orange"];
const topLeftIcon = ["mdi mdi-arrow-right-drop-circle-outline", "color:green"];
const bottomRightIcon = ["mdi mdi-arrow-down-drop-circle-outline", "color:orange"];
const bottomLeftIcon = ["mdi mdi-arrow-down-drop-circle-outline", "color:green"];

const mapStateToProps = (state) => ({
  legalPersons: state.legalPersons,
  input: state.input,
});
const mapDispatchToProps = (dispatch) => ({
  updateInputs: (...args) => dispatch(updateInputs(...args)),
});

let layout = [
  // each array item a row

  [
    // each item a column
    {
      class: "nodename",
      align: "center",
      dataField: "name",
    },
  ],
  /*
  [ 
    {
      class: '',
      align: 'center',
      metaDataField: 'classNames',
      id: 'orgchart_name'
    },
  ]
  */
];

class OrgChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analysis: [],
    };
    this.metaData = this.props.legalPersons.map((node) => ({
      ...node,
      metaData: {},
    })); // copy with empty object content
  }

  getOrgChartRef = (ref) => {
    this.orgchart = ref;
  };

  setRestricted = (restricted) => {
    const { type } = this.props.args;
    this.props.updateInputs([{
      path: "input._undertakings."+type,
      value: restricted
    }])
    // this.props.setInput("_undertakings", -1, type, restricted);
    return;
  };

  restrictSingle = (nodeId) => {
    const { type } = this.props.args;
    const restricted =
      this.props.input["_undertakings"] && this.props.input["_undertakings"][type]
        ? [...this.props.input["_undertakings"][type]]
        : [];

    if (restricted.includes(nodeId)) return console.log('already restricted')
    restricted.push(nodeId)
    this.setRestricted(restricted);
  };
  restrictAll = (nodeId) => {
    const { type } = this.props.args;
    const restricted =
      this.props.input["_undertakings"] && this.props.input["_undertakings"][type]
        ? [...this.props.input["_undertakings"][type]]
        : [];

    this.restrictChildren(restricted, nodeId);

    restricted.push(nodeId)
    
    this.setRestricted(restricted);
  };

  restrictChildren = (restricted, parentId) => {
    // Each node of orgchart.nodes has its own children array. Use that for simplicity.
    if (!this.orgchart || !this.orgchart.nodes || !this.orgchart.nodes[parentId]) return;
    for (let child of this.orgchart.nodes[parentId].children) {
      let childIndex = restricted.indexOf(child.id);
      if (childIndex === -1) restricted.push(child.id);
      if (child.children && child.children.length > 0) this.restrictChildren(restricted, child.id);
    }
  };

  unRestrictSingle = (nodeId) => {
    const { type } = this.props.args;
    if (!this.props.input["_undertakings"] || !this.props.input["_undertakings"][type]) return;
    const restricted = [...this.props.input["_undertakings"][type]];

    console.log('restricted first ', restricted)

    const nodeIndex = restricted.indexOf(nodeId);
    if (nodeIndex !== -1) restricted.splice(nodeIndex, 1);
    console.log('new restricted ', {restricted, nodeIndex})
    this.setRestricted(restricted);
  };
  unRestrictAll = (nodeId) => {
    const { type } = this.props.args;
    if (!this.props.input["_undertakings"] || !this.props.input["_undertakings"][type]) return;
    const restricted = [...this.props.input["_undertakings"][type]];
    const nodeIndex = restricted.indexOf(nodeId);
    if (nodeIndex !== -1) restricted.splice(nodeIndex, 1);

    this.unRestrictChildren(restricted, nodeId);

    this.setRestricted(restricted);
  };

  unRestrictChildren = (restricted, parentId) => {
    if (!this.orgchart || !this.orgchart.nodes || !this.orgchart.nodes[parentId]) return;
    for (let child of this.orgchart.nodes[parentId].children) {
      let childIndex = restricted.indexOf(child.id);
      if (childIndex !== -1) restricted.splice(childIndex, 1);
      if (child.children && child.children.length > 0)
        this.unRestrictChildren(restricted, child.id);
    }
  };

  renderWarnings = () => {
    const { type } = this.props.args;
    const warnings =
      this.props.input["undertaking_" + type] && this.props.input["undertaking_" + type].warnings;

    if (!warnings || !Array.isArray(warnings) || warnings.length === 0) return null;

    return warnings.map((warning, index) => <div key={"und-warn" + index}>{warning}</div>);
  };

  render() {
    const { type } = this.props.args;
    let meta;

    if (this.props.input["_undertakings"] && this.props.input["_undertakings"][type]) {
      let newMeta = this.metaData.map((node, index) => {
        if (this.props.input["_undertakings"][type].indexOf(node.id) !== -1) {
          if (Array.isArray(node.metaData.classNames)) {
            return {
              ...node,
              metaData: {
                ...node.metaData,
                classNames: node.metaData.classNames.concat(["node-restricted"]),
              },
            };
          } else {
            return { ...node, metaData: { ...node.metaData, classNames: ["node-restricted"] } };
          }
        }
        return { ...node };
      });
      meta = newMeta;
    } else {
      meta = this.metaData;
    }

    return (
      <>
        {/* <div className="undertaking-warnings">{this.renderWarnings()}</div> */}

        {this.props.legalPersons && this.props.legalPersons.length > 0 ? (
          <Chart
          legalPersons={this.props.legalPersons}
            layout={layout}
            metaData={meta}
            clickNodeEvent={this.clickNodeEvent}
            topRightAction={this.restrictSingle}
            topRightIcon={topRightIcon}
            topLeftAction={this.unRestrictSingle}
            topLeftIcon={topLeftIcon}
            bottomRightAction={this.restrictAll}
            bottomRightIcon={bottomRightIcon}
            bottomLeftAction={this.unRestrictAll}
            bottomLeftIcon={bottomLeftIcon}
            setOrgChartRef={this.getOrgChartRef}
            chartClass={"undertakings"}
          />
        ) : null}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgChart);
