import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Avatar, Button, Alert } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import api from "utils/api";

import ContainerHeader from "components/ContainerHeader/index";

function validateEmail(mail) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

function validatePassword(pwd1, pwd2) {
  if (pwd1.length < 8) {
    return "Password must be at least 8 characters";
  }
  if (pwd1 !== pwd2) {
    return "Passwords must match";
  }
  return "";
}

export default function Verify(props) {
  const history = useHistory();
  const location = useLocation();

  // console.log("verify props are ", props);

  const { search } = location;
  const queries = !search ? null : search.substr(1).split("&");
  let initCode = "",
    initEmail = "";
  if (Array.isArray(queries)) {
    const codeMatch = queries.find((item) => item.substr(0, 2) === "c=");
    initCode = codeMatch ? atob(codeMatch.substr(2)) : "";
    const emailMatch = queries.find((item) => item.substr(0, 2) === "e=");
    initEmail = emailMatch ? atob(emailMatch.substr(2)) : "";
  }

  const [code, setCode] = useState(initCode);
  const [email, setEmail] = useState(initEmail);
  const [verified, setVerified] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [createUserError, setCreateUserError] = useState(false);

  

  const verifyChange = (e) => {
    if (e.target.value === "") setVerificationError("");
    if (e.target.name === "emailx") setEmail(e.target.value);
    if (e.target.name === "code") setCode(e.target.value);
  };

  const verifyCodeAndEmail = () => {
    if (!code || !email) return;
    api
      .post("/users/verify", { email, code })
      .then((res) => {
        if (res.data && res.data === true) {
          setVerified(true);
        } else console.log("veri false");
      })
      .catch((err) => {
        console.log("verification error", err.response);
        setVerificationError("Incorrect reset password credentials");
      });
  };
  useEffect(() => {
    verifyCodeAndEmail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPassword = () => {
    api
      .post("/users/verifySetPwd", { email, code, password: pwd1 })
      .then((res) => {
        if (res && res.data && res.data.success) {
          console.log("All good - shall redirect to signin page");
          return history.replace("/signin");
        }
        setCreateUserError(res.data.error || "Could not create user");
      })
      .catch((err) => {
        console.log("set password error ", err.response);
        setCreateUserError("Could not create user");
      });
  };

  const validEmail = validateEmail(email);
  const validCode = code.length === 36;

  const validVerificationAttempt = validEmail && validCode;
  const passwordError = validatePassword(pwd1, pwd2);

  return (
    <div
      style={{
        padding: "40px",
      }}
    >
      <Row>
        <Col span={24}>
          <ContainerHeader
            title="Reset password"
          />
        </Col>
        <Col span={24}>
          <div className="timeline-section">
            <div className={`timeline-item`}>
              <div className="timeline-badge timeline-img">
                <Avatar
                  size="large"
                  className={
                    "size-60 rounded-circle bg-" +
                    (verified ? "success" : "white")
                  }
                  icon={
                    <i
                      className={`icon icon-lock-screen ml-auto text-${
                        verified ? "white" : "primary"
                      } fs-xxxl d-block mt-1`}
                    />
                  }
                />
              </div>

              <div className="timeline-panel">
                <div className="timeline-panel-header">
                  <div className="timeline-heading">
                    <h5>Step 1</h5>
                    <h3 className="timeline-title">Verification Credentials</h3>
                  </div>
                </div>
                <div className="timeline-body">
                  <>
                    <Form layout="vertical">
                      <Form.Item label="E-mail">
                        {!verified ? (
                          <Input
                            type="text"
                            name="emailx"
                            id="emailx"
                            placeholder="your@email.com"
                            value={email}
                            onChange={verifyChange}
                            required
                          />
                        ) : (
                          <small>{email}</small>
                        )}
                      </Form.Item>
                      <Form.Item label="Code">
                        {!verified ? (
                          <Input
                            spellCheck="false"
                            type="text"
                            name="code"
                            id="code"
                            placeholder="code"
                            style={{ fontSize: "12px" }}
                            value={code}
                            onChange={verifyChange}
                            required
                          />
                        ) : (
                          <small>{code}</small>
                        )}
                      </Form.Item>
                    </Form>
                    {!verified && (
                      <Row className="mb-3 mt-4">
                        <Col span={12} className="m-auto">
                          <Button
                            type={validVerificationAttempt ? "primary" : ""}
                            size="large"
                            onClick={verifyCodeAndEmail}
                            block
                            disabled={!validVerificationAttempt ? true : false}
                          >
                            Verify me
                          </Button>
                          {verificationError && email && code && (
                            <div className="text-danger mt-2">
                              {verificationError}
                            </div>
                          )}
                          {!validEmail && email && (
                            <div className="text-danger mt-2">
                              Enter valid email
                            </div>
                          )}
                          {!validCode && code && (
                            <div className="text-danger mt-2">
                              Enter valid code
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}
                  </>
                </div>
                {verified && (
                  <div>
                    <Alert
                      message="Verification successful"
                      description="Your reset credentials have been verified. Please proceed to reset password below."
                      type="success"
                      showIcon
                    />
                  </div>
                )}
              </div>
            </div>
            {verified && (
              <div className={`timeline-item`}>
                <div className="timeline-badge timeline-img">
                  <Avatar
                    size="large"
                    className={"size-60 rounded-circle bg-white"}
                    icon={
                      <i
                        className={`icon icon-user-o ml-auto text-primary fs-xxxl d-block mt-1`}
                      />
                    }
                  />
                </div>

                <div className="timeline-panel">
                  <div className="timeline-panel-header">
                    <div className="timeline-heading">
                      <h5>Step 2</h5>
                      <h3 className="timeline-title">Reset password</h3>
                    </div>
                  </div>
                  <div className="timeline-body">
                    <>
                      <Form layout="vertical">
                        <Form.Item label="Enter new password">
                          <Input
                            type="password"
                            name="password1"
                            id="password1"
                            placeholder="password"
                            value={pwd1}
                            onChange={(e) => setPwd1(e.target.value)}
                            required
                          />
                        </Form.Item>
                        <Form.Item label="Confirm new password">
                          <Input
                            type="password"
                            name="password2"
                            id="password2"
                            placeholder="password"
                            value={pwd2}
                            onChange={(e) => setPwd2(e.target.value)}
                            required
                          />
                        </Form.Item>
                      </Form>

                      <Row className="mb-3 mt-4">
                        <Col span={12} className="m-auto">
                          <Button
                            type={!passwordError ? "primary" : ""}
                            size="large"
                            block
                            disabled={passwordError ? true : false}
                            onClick={setPassword}
                          >
                            Set new password
                          </Button>
                          <div className="text-danger mt-3">
                            {(passwordError &&
                              pwd1 !== "" &&
                              passwordError) || <span>&nbsp;</span>}
                            {createUserError && createUserError}
                          </div>
                        </Col>
                      </Row>
                    </>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
