import React from 'react'
import ReactDOM from 'react-dom'
import { cx, css } from 'emotion'
import { Button as AntButton } from "antd"

export const Button = React.forwardRef(
  ({ className, active, reversed, disabled, ...props }, ref) => {
    return (
    <AntButton 
      {...props}
      ref={ref}
      disabled={disabled}
      className={(className||'')+(active?" active":"")}
    />
  )
  }
)

export const Icon = React.forwardRef(({ className, style = {}, children, ...props }, ref) => (
  <i className={'mdi '+children+(className?' '+className:'')} style={style}></i>
))

export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}
