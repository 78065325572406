import { createReducer } from '@reduxjs/toolkit';
import { getByPath, setByPath, makeRepeatableId } from 'core/utils/general';

import {
  FIX_INPUT,
  UPDATE_INPUT,
  UPDATE_INPUTS,
  SET_CONNECT_INPUT_CARDS,
  ADD_REPEATABLE,
  REMOVE_REPEATABLE,
  ADD_ORDINARY_CARD,
  REMOVE_ORDINARY_CARD,
  REMOVE_INPUT,
  ADD_QA,
  REMOVE_QA,
  UPDATE_QA,
} from 'constants/ActionTypes';

const INIT_STATE = {};

export default createReducer(INIT_STATE, {
  [ADD_QA]: (state, action) => {
    const { path, uid, values } = action.payload;
    // console.log('values are ', values);
    try {
      setByPath(state, path + '.' + uid, values, { skip: 1, force: true });
    } catch (err) {
      console.log('Set err... ', err);
    }

    // setByPath(state, path+"."+uid, values || {}, { skip: 0, force: true });
    return state;
  },
  [REMOVE_QA]: (state, action) => {
    const { path, uid } = action.payload;
    if (!path || !uid) return;
    const qaData = getByPath(state, path, { skip: 1 });
    if (!qaData) return;
    delete qaData[uid];
    return state;
  },
  [UPDATE_QA]: (state, action) => {
    const { path, value } = action.payload;
    setByPath(state, path, value, { skip: 1, force: true });
    return state;
  },
  [ADD_REPEATABLE]: (state, action) => {
    const {
      payload: { path, uid, values },
    } = action;

    setByPath(state, path + '.' + uid, values, { skip: 1, force: true });
    return state;
  },
  [REMOVE_REPEATABLE]: (state, action) => {
    const {
      payload: { path, uid },
    } = action;

    const repeatablesData = getByPath(state, path, { skip: 1 });
    delete repeatablesData[uid];

    // Find any connectedCard entry associated with this repeatable.
    if (state.__connectedCards) {
      const repeatableName = path.split('.').slice(-1)[0];
      for (let i = 0; i < state.__connectedCards.length; i++) {
        const connectedData = state.__connectedCards[i];
        if (connectedData.cards?.[repeatableName] === uid) {
          state.__connectedCards.splice(i, 1);
          i--;
        }
      }
    }

    return state;
  },
  [ADD_ORDINARY_CARD]: (state, action) => {
    const {
      payload: { path, values },
    } = action;
    setByPath(state, path, values, { skip: 1, force: true });
    return state;
  },
  [REMOVE_ORDINARY_CARD]: (state, action) => {
    const {
      payload: { path },
    } = action;
    setByPath(state, path, null, { skip: 1, deleteProperty: true });
    return state;
  },
  [UPDATE_INPUT]: (state, action) => {
    const { path, value } = action.payload;
    setByPath(state, path, value, { skip: 1, force: true });
    return state;
  },
  [UPDATE_INPUTS]: (state, action) => {
    const entries = action.payload;
    for (const { path, value } of entries) setByPath(state, path, value, { skip: 1, force: true });
    return state;
  },
  [REMOVE_INPUT]: (state, action) => {
    const {
      payload: { path },
    } = action;
    setByPath(state, path, null, { skip: 1, deleteProperty: true });
    return state;
  },
  [SET_CONNECT_INPUT_CARDS]: (state, action) => {
    // Example state entry.
    /* 
        state.input.__connectedCards = [
            {
                cards: {
                facility: "201fb670-256a-47cf-acee-1f8c399fea4b",
                borrower: "b1301600288567173" 
                },
                value: true,
                key: 'facilityAvailableToBorrower'
            }
        ]
        */
    const { sourceCardId, sourceUid, targetCardId, targetUid, key, value } = action.payload;
    if (!state.__connectedCards) state.__connectedCards = [];
    const connectedData = state.__connectedCards.find(
      (item) =>
        item.key === key &&
        item.cards?.[sourceCardId] === sourceUid &&
        item.cards?.[targetCardId] === targetUid
    );
    if (connectedData) {
      connectedData.value = value;
    } else {
      state.__connectedCards.push({
        cards: {
          [sourceCardId]: sourceUid,
          [targetCardId]: targetUid,
        },
        key,
        value,
      });
    }
    return state;
  },

  [FIX_INPUT]: (state, action) => {
    return action.payload;
  },
});
