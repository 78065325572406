import React from 'react';
import TopHeader from '../../project/Header/';
import IntlMessages from 'util/IntlMessages';
import { userToName } from 'components/ui';

const EntityHeader = (props) => {
  const { customer, person, personType } = props;

  const items = {
    overview: {
      text: <IntlMessages id="app.project.menus.Overview" />,
      path: ({ customer }) => '/entities/' + customer.id + '/overview',
      activeMatch: '/overview',
    },
  };

  if (personType === 'legalPerson') {
    items.structurechart = {
      text: <IntlMessages id="app.project.menus.StructureChart" />,
      path: ({ customer }) => '/entities/' + customer.id + '/structurechart',
      activeMatch: '/structurechart',
    };
    items.persons = {
      text: <IntlMessages id="app.project.menus.Persons" />,
      path: ({ customer }) => '/entities/' + customer.id + '/persons',
      activeMatch: '/persons',
    };
  }

  return (
    <TopHeader
      name={person ? userToName(person) : <em>Unknown person</em>}
      // description={doc && doc.description}
      icon={{
        className: 'icon icon-' + (personType === 'realPerson' ? 'user-o' : 'company'),
        bgClassName: 'bg-orange',
      }}
      back={{
        path: '/entities',
        name: (
          <>
            <IntlMessages id="desc.BackTo" />
            <span>&nbsp;</span>
            <IntlMessages id="general.entities" />
          </>
        ),
      }}
      toPathMatch={{ customer }}
      items={items}
      {...props}
    />
  );
};

export default EntityHeader;
