import { useState, useEffect, useRef } from "react";
import { message } from "antd";
import updateStudioDrafts from "utils/saving/updateStudioDrafts";
import { useContract } from "hooks";

export let setContractWasUpdated = { fn: null, reset: null };

const notify = false;

export const useStudioAutoSave = () => {
  const contract = useContract();
  const [updateCount, setUpdateCount] = useState(0);
  const [saveError, setSaveError] = useState(false);

  const hasPendingChanges = useRef(false);
  const autoSaverInitialized = useRef(false);

  setContractWasUpdated.fn = () => {
    hasPendingChanges.current = true;
    setUpdateCount(updateCount + 1);
  };
  setContractWasUpdated.reset = () => {
    hasPendingChanges.current = false;
    setUpdateCount(0);
  };

  useEffect(() => {
    return () => {
      setContractWasUpdated = { fn: null, reset: null };
    }
  }, [])

  useEffect(() => {
    if (!autoSaverInitialized.current) {
      // Ensure that we do not set `hasPendingChanges`
      // directly on load.
      autoSaverInitialized.current = true;
      return;
    }
    hasPendingChanges.current = true;
    setUpdateCount(updateCount + 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  const checkContractUpdates = () => {
    if (!hasPendingChanges.current) return;
    updateStudioDrafts()
      .then(() => {
        if (notify) message.info("Saved!", 2);
        hasPendingChanges.current = false;
        setUpdateCount(0);
      })
      .catch((err) => {
        if (notify) message.error("Save failed", err.response, 2);
        console.log("updateStudioDrafts failed ", err, err.response);
        setSaveError(true);
      });
  };

  useEffect(() => {
    let updateInterval = setInterval(checkContractUpdates, 30000);
    return () => {
      clearInterval(updateInterval);
    };
  }, []);

  let pendingChangesStatus = "success";
  if (saveError) pendingChangesStatus = "error";
  else if (hasPendingChanges.current) pendingChangesStatus = "warning";
  else pendingChangesStatus = "success";
  return pendingChangesStatus;
};
