import {DRAFT_EDITOR_ALLOW_EDITING, FIX_DRAFT, RESET_DRAFT, SET_DRAFT, SET_DRAFT_VALUES} from "../../constants/ActionTypes";

export const setEditorAllowEditing = (value) => {
  return {
    type: DRAFT_EDITOR_ALLOW_EDITING,
    payload: value
  }
};

export const fixDraft = (value) => {
  return {
    type: FIX_DRAFT,
    payload: value
  }
};

export const resetDraft = () => {
  return {
    type: RESET_DRAFT,
  }
};

export const setDraft = (key, value) => {
  if (!key || typeof value === undefined) return null
  return {
    type: SET_DRAFT,
    payload: {
      key, value
    }
  }
}

export const setDraftValues = (values) => {
  if (!Array.isArray(values)) return null
  return {
    type: SET_DRAFT_VALUES,
    payload: values
  }
}