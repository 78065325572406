import { Contract } from '../../../interfaces';

export const setupVariables = function (contract, options = {}) {
  this.setupEngineVariables(contract, options);
  this.setupContractVariables(contract, options);
};

export const setupEngineVariables = function (contract, options = {}) {
  const { language } = options;

  this.getId = () => this.id;

  this.sessionTimes = [];

  this.states = {
    previous: {
      input: {},
      nodes: [],
      legalPersons: [],
    },
    current: { input: {}, nodes: [], legalPersons: [] },
  };
  this.language =
    language ||
    (contract.data.settings && contract.data.settings.language) ||
    this.settings.defaultLanguage ||
    'en';
  this.modules = [];
  this._tmpDraft = {};

  this.importContract = options.importContract || null;

  // References for updates
  this.refs = {
    acps: {
      input: {},
      local: {},
      shortcut: {},
    },
    smartReplacements: {
      input: {},
      shortcut: {},
      count_repeatable: {},
      numof_repeatable: {},
    },
    smartDefinitionsReplacements: {},
    repeatableShortcuts: {},
    repeatableMapping: {},
  };
};

export const setupContractVariables = function (contract, options = {}) {
  this.contract = contract;
  this.id = Contract.getId(this.contract);

  const { isLoaded } = options;
  const draftInfo = this.getDraftInfo()
  if (isLoaded) {
    if (!draftInfo.data) this.api.interfaces.Contract.setDraftInfoItem(this.contract, 'data', {})
    if (!draftInfo.variables) this.api.interfaces.Contract.setDraftInfoItem(this.contract, 'variables', {})
  } else {
    this.api.interfaces.Contract.addDraftInfo(this.contract, {
      data: {},
      variables: {},
      shortcutStates: {},
      smartReplacements: {},
      smartReplacementIndices: {},
      status: (draftInfo && draftInfo.status) || {
        initialized: false,
        loaded: false,
        editorInitialized: false,
        inherited: !!options.importContract,
      },
      importContractId: (options.importContract && options.importContract.id) || null,
    })
  }
};
