import React from "react";
import { useContract, setModalContext } from "hooks";
import { disconnectFile } from "../../../../helpers";
import { Tooltip } from "antd";
import { safePath } from "utils/editor";
import IntlMessages, { useIntlMessage } from "util/IntlMessages";

export function FilesIndicator({ editor, element }) {
  const contract = useContract();

  const formatMessage = useIntlMessage();
  
  const files = element?.data?.files;
  if (!files || files.length === 0) return null;

  const onDisconnectFile = async(file) => {
    const path = safePath(editor, element);
    disconnectFile(editor, path, file);
  }

  const openFiles = () => {
    setModalContext({
      type: 'files-connected',
      title: formatMessage("app.file.connectedFiles"),
      resourceId: contract.documentId,
      files,
      onDisconnectFile,
    });
  }

  return (
    <Tooltip
      title={
        <>
          <span><IntlMessages id="app.file.viewConnectedFiles" /></span>
        </>
      }
    >
      <div className="editor-each-repeatable-btn edit" contentEditable={false} onMouseDown={openFiles}>
        <i className="mdi mdi-file-multiple" />
      </div>
    </Tooltip>
  );
}

