
export const initInitValues = {
  id: "initInitValues",
  dependencies: { onGenesis: true, },
  handler: function ({api}) {

    return (node, parents) => {

      if (node.variant !== "initvalue" || !node.data.name) return

      const value = getInitValue(node.data.name, this.language);
      api.utils.engine.setFirstChild(node, value)

    };
  },
};

function getInitValue(name, language) {
  switch (name) {
    case "date":
      const date = new Date();
      const dateFormatter = new Intl.DateTimeFormat(language, {
        month: "long",
      });
      let d = date.getDate();

      // if (d < 10) d = "0" + d;
      return d + " " + dateFormatter.format(date) + " " + date.getFullYear();
    default: return "[**]"
  }
};