import { Transforms } from "slate"

import uuid from "uuid-random";

const IMG_TYPE = "img";

function insertImage(editor, opts = {}) {
  const text = { text: "" };
  const image = {
    type: IMG_TYPE,
    children: [text],
    data: {
      template_id: "standard_inserted_image",
      item_id: opts.item_id || uuid(),
      _format: {
        textAlign: opts.alignment || "left"
      },
      ...(opts.data || {})
    },
  };
  if (opts.imgData) image.data.imgData = opts.imgData
  if (opts.id) image.data.image_id = opts.id;
  else if (opts.url) image.data.image_url = opts.url;
  else {
    return console.log("Invalid options, expected either an url or an id");
  }

  if (opts.at) {
    return Transforms.insertNodes(editor, image, { at: opts.at });
  }
  Transforms.insertNodes(editor, image);
}

const onImageLoad = (editor, reader) => () => {
  const url = reader.result;
  if (url) insertImage(editor, {url});
};

export const withImages = (editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) =>
    element.type === IMG_TYPE ? true : isVoid(element);

  editor.insertImage = (...args) => insertImage(editor, ...args);

  const { insertData } = editor;

  editor.insertData = (data) => {
    // const text = data.getData("text/plain");
    const { files } = data;
    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");
        if (mime === "image") {
          reader.addEventListener("load", onImageLoad(editor, reader));
          reader.readAsDataURL(file);
        }
      }
    } /* else if (isImageUrl(text)) {
      insertImage(editor, {url: text});
    }  */else {
      insertData(data);
    }
  };

  return editor;
};
