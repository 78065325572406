import { Tag } from 'antd';

export default function StatusTag({ status }) {
  const text = status ? status.toLocaleUpperCase() : 'Unknown status';
  let color = 'gray';

  switch (status) {
    case 'draft':
      color = 'orange';
      break;
    case 'published':
      color = 'green';
      break;
    case 'signed':
      color = 'blue';
      break;
    default:
      break;
  }

  return <Tag color={color}>{text}</Tag>;
}
