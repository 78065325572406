import React from 'react';
import TopHeader from '../Header/';
import IntlMessages from 'util/IntlMessages';

const ProjectHeader = (props) => {
  const { project } = props;
  const { isFolder } = project;
  const iconString = isFolder ? 'mdi-folder-outline' : 'mdi-cube-outline';

  const type = isFolder ? 'folder' : 'project';
  const bgClassName = isFolder ? 'bg-orange' : 'bg-purple';
  const membersIntId = isFolder ? 'FolderMembers' : 'Members';

  /* const checklists =
    (project.data.checklists &&
      Object.keys(project.data.checklists).reduce((acc, curr) => {
        const itemsId = project.data.checklists[curr].checklists
          ? Object.keys(project.data.checklists[curr].checklists).length
          : 0;
        return acc + itemsId;
      }, 0)) ||
    0; */

  const checklists = (project && project.checklists && project.checklists.length) || 0;

  return (
    <TopHeader
      name={project && project.name}
      description={project && project.description}
      icon={{ className: 'mdi ' + iconString, bgClassName }}
      back={{
        path: '/projects',
        name: (
          <>
            <IntlMessages id="desc.BackTo" />
            <span>&nbsp;</span>
            <IntlMessages id="desc.all" />
            <span>&nbsp;</span>
            <IntlMessages id={isFolder ? 'general.folders' : 'general.projects'} />
          </>
        ),
      }}
      isFolder={isFolder}
      toPathMatch={{ project }}
      items={{
        overview: {
          text: <IntlMessages id="app.project.menus.Overview" />,
          path: ({ project }) => '/' + type + '/' + project.id + '/overview',
          activeMatch: '/overview',
        },
        documents: {
          text: <IntlMessages id="app.project.menus.Documents" />,
          path: ({ project }) => '/' + type + '/' + project.id + '/documents',
          activeMatch: '/documents',
        },
        checklists: {
          text: <IntlMessages id="app.project.menus.Checklists" />,
          path: ({ project, doc }) => '/project/' + project.id + '/checklists',
          activeMatch: '/checklists',
          badge: checklists,
          badgeColor: '#d6d6d6',
          badgeTextColor: '#333333',
        },
        files: {
          text: <IntlMessages id="app.project.menus.Files" />,
          path: ({ project }) => '/' + type + '/' + project.id + '/files',
          activeMatch: '/files',
        },
        members: {
          text: <IntlMessages id={'app.project.menus.' + membersIntId} />,
          path: ({ project }) => '/' + type + '/' + project.id + '/members',
          activeMatch: '/members',
        },
        /* timeline: {
          text: <IntlMessages id="app.project.menus.Timeline" />,
          path: ({ project }) => "/project/" + project.id + "/timeline",
          activeMatch: "/timeline",
        },
        actions: {
          text: <IntlMessages id="app.project.menus.Actions" />,
          path: ({ project }) => "/project/" + project.id + "/actions",
          activeMatch: "/actions",
        },
        events: {
          text: <IntlMessages id="app.project.menus.Events" />,
          path: ({ project }) => "/project/" + project.id + "/events",
          activeMatch: "/events",
        }, */
      }}
      {...props}
    />
  );
};

export default ProjectHeader;
