import { useSelected, useFocused } from '../../import/slate-react';
import { gotoBlocks, isList, isListItem, preventMarkAsSelected } from '../types/elements';
import images from '../data/images/';

export function useAttributes(editor, element) {
  const isSelected = useSelected();
  const isFocused = useFocused();
  const selectedAndFocused = isFocused && isSelected;

  return {
    className: useClassName(editor, element),
    style: getStyle(editor, element, selectedAndFocused),
    ...getBookmarks(element),
    ...getExtraAttributes(editor, element),
  };
}

function getStyle(editor, element, selectedAndFocused) {
  let style = (element.data && element.data._format) || {};
  if (element.type === 'img') {
    return { ...style, ...getImageStyle(element, selectedAndFocused) };
  }
  return style;
}

export const getImageSrc = (element) => {
  if (!element || !element.data) return;
  if (element.data.imgData) return element.data.imgData
  if (element.data.image_id) {
    const imageItem = images.find((image) => image.id === element.data.image_id);
    if (imageItem && imageItem.image) return imageItem.image;
  } else if (element.data.image_url) {
    return element.data.image_url;
  } else if (element.url) return element.url;
};

export const getImageStyle = (element, isFocused) => {
  const style = { display: 'block' };
  // if (isFocused) style.boxShadow = "0 0 0 3px #B4D5FF";
  if (element.data && element.data._format && element.data._format.textAlign) {
    switch (element.data._format.textAlign) {
      case 'justify':
      case 'left':
        style.marginRight = 'auto';
        break;
      case 'center':
        style.marginRight = 'auto';
        style.marginLeft = 'auto';
        break;
      case 'right':
        style.marginLeft = 'auto';
        break;
      default:
        break;
    }
  }
  if (element.data.height) style.height = element.data.height
  if (element.data.width) style.width = element.data.width
  return style;
};

function useClassName(editor, element) {
  const selected = useSelected();
  let cls = 'editor-block editor-' + element.type;
  if (element.data && element.data._inActive) cls += ' block-inactive';
  if (element.type === 'list_item' && element.data && element.data.hanging) cls += ' list-item-hanging';
  if (gotoBlocks.includes(element.type)) cls += ' goto-block';

  if (element.data && element.data.fill) cls += ' filler';

  if (element.data && element.data.module) cls += ' module';

  if (isList(element)) cls += ' block-list';

  if (selected && !preventMarkAsSelected(element)) cls += ' selected_block';

  if (element.data && element.data._each_repeatable_name && element.data._each_uid) cls += ' repetable_item';

  if (element._markup_add) cls += ' markup_block_inserted';

  if (element._markup_del) cls += ' markup_block_deleted';

  if (element.data && element.data._format && element.data._format.keepLeft) cls += ' keep-left';

  if (element.type === 'list_item' && element.data && element.data.checked === true) cls += ' checked'

  return cls;
}

const getBookmarks = (element) => {
  if (!element.data || !element.data.bookmarks) return {};
  const bookmarks =
    typeof element.data.bookmarks === 'string'
      ? [element.data.bookmarks]
      : Array.isArray(element.data.bookmarks)
      ? element.data.bookmarks
      : [];
  return bookmarks.reduce(
    (acc, cur) => (acc['data-ref-bm-' + cur.toLowerCase().replace(/\s/g, '__')] = true),
    {}
  );
};

const getExtraAttributes = (editor, element) => {
  const attrs = { iid: (element.data && element.data.item_id) || '_none_' };

  if (element.data && element.data._updateTime) attrs['data-updated'] = element.data._updateTime;

  if (element.children && element.children[0].text === '') {
    attrs['data-first-empty'] = true;
  }

  if (element.data && element.data._format) attrs.style = element.data._format;

  if (editor.meta && editor.meta.markup && element.data && element.data.markup)
    attrs.markup = element.data.markup;

  if (isListItem(element) && element.data && element.data._format && element.data._format.hanging)
    attrs.hanging = true;

  return attrs;
};
