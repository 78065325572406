import {
  SET_LEGALPERSONS,
  UPDATE_LEGALPERSON,
  INSERT_LEGALPERSON,
  REMOVE_LEGALPERSON,
} from 'constants/ActionTypes';
import { findTopCo } from 'core/utils/nodes';
import uuid from 'uuid-random';

export const setLegalPersons = (data) => {
  return {
    type: SET_LEGALPERSONS,
    payload: data,
  };
};

export const updateLegalPerson = (id, data) => {
  return (dispatch, getState) => {
    const legalPersons = [...getState().legalPersons];
    const updatingIndex = legalPersons.findIndex((data) => id === data.id);
    if (updatingIndex === -1) {
      console.log('Could not find legalPerson with id ' + id, legalPersons);
      return;
    }
    const legalPerson = JSON.parse(JSON.stringify(legalPersons[updatingIndex]));

    Object.assign(legalPerson, data);

    dispatch({
      type: UPDATE_LEGALPERSON,
      payload: { index: updatingIndex, legalPerson },
    });
  };
};

export const insertLegalPerson = (data = {}) => {
  return (dispatch, getState) => {
    let { id, parentId } = data;
    if (!parentId) {
      const legalPersons = [...getState().legalPersons];
      const topCo = findTopCo(legalPersons);
      if (topCo) {
        parentId = topCo.id;
      } else {
        parentId = null;
      }
    }
    if (!id) id = uuid();

    dispatch({
      type: INSERT_LEGALPERSON,
      payload: Object.assign({ id, parentId }, data),
    });
  };
};
export const addLegalPerson = (id, pid, data) => {
  return {
    type: INSERT_LEGALPERSON,
    payload: Object.assign({ id, parentId: pid }, data),
  };
};

export const removeLegalPerson = (id) => {
  const removeIds = [id];
  return (dispatch, getState) => {
    const legalPersons = [...getState().legalPersons];
    const childrenIds = collectChildren(legalPersons, id);
    return dispatch({
      type: REMOVE_LEGALPERSON,
      payload: removeIds.concat(childrenIds),
    });
  };
};

const collectChildren = (legalPersons, parentId) => {
  let childrenIds = [];
  for (let person of legalPersons) {
    if (person.parentId !== parentId) continue;
    childrenIds.push(person.id);
    const subChildren = collectChildren(legalPersons, person.id);
    childrenIds = childrenIds.concat(subChildren);
  }
  return childrenIds;
};
