import React, { memo } from 'react';
import { useIsTemplateStudio } from 'hooks';
import { Row, Card } from 'antd';
import CardContent from './components/card/CardContent';
import CardActions from './CardActions';
import { InputPaths } from 'core/interfaces';

export default memo(function ({ card, cardName, language, path, cardWithinCard }) {
  const isTemplate = useIsTemplateStudio();
  path = InputPaths.construct(path, card.id);

  const header = card.header?.[language] || null;
  const cardClass = cardWithinCard ? 'input-fields-card' : 'border-0 input-fields-card';

  if (card.special?.type === 'groupStructure') {
    return <GroupStructure card={card} header={header} />;
  }

  return (
    <Card
      className={cardClass}
      title={header}
      extra={isTemplate ? <CardActions cardName={cardName} /> : null}
    >
      <Row>
        <CardContent card={card} language={language} isRepeatable={false} path={path} />
      </Row>
    </Card>
  );
});

/**
 * Creates an organization chart based on the input template
 *
 * @param {template} card
 */
function GroupStructure({ card, header }) {
  const TheChart = require('./components/orgchart/views/' + card.special.data.action).default;

  const structureModules = [];
  /* if (Array.isArray(card.special.data.modules) && card.special.data.modules.length > 0) {
    for (let mod of card.special.data.modules) {
      try {
        const loaded = require('../../../../../core/modules/structureChart/' + mod.section + '/' + mod.name);
        if (loaded) structureModules.push(loaded);
      } catch (err) {
        console.log('Err when loading groupStructure mod', mod);
        console.dir(err);
      }
    }
  } */

  return <div className="chart-wrapper mb-3">
    {header && <h4>{header}</h4>}
    <TheChart template={card} args={card.special.data.args} modules={structureModules} />
  </div>;
}
