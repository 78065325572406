export const repeatableNumberBindingAll = {
  id: 'repeatableNumberBindingAll',
  dependencies: { repeatableChange: true, repeatableRemove: true, repeatableAdd: true },
  acceptInheritedData: true,
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    let mappedValues = {};
    let mappedValuesData = {};

    for (const { path, cardId, inheritedCardId, fieldName, value, pathInvoked } of entries) {
      // this.log('Number Binding X. ', {path, cardId, fieldName, value, pathInvoked})

      if (pathInvoked.repeatableAdd) {
        let repeatable_path = api.interfaces.InputPaths.parentPath(path, 1);
        const repeatable_state = api.utils.general.getByPath(state, repeatable_path);
        if (!value || typeof value !== 'object') {
          this.log('Value is not an object, although we are in repeatableAdd....', {
            path,
            cardId,
            fieldName,
            value,
            pathInvoked,
          });
          continue;
        }

        for (const repeatablesFieldName of Object.keys(value)) {
          const newMap = this.mapRepeatableNumberBindings({
            item: {
              fieldName: repeatablesFieldName,
              value: value[repeatablesFieldName],
            },
            state,
            repeatable: cardId || inheritedCardId,
            repeatableState: repeatable_state,
            api,
          });
          if (!newMap) continue;
          mappedValues = { ...mappedValues, ...newMap.values };
          mappedValuesData = { ...mappedValuesData, ...newMap.data };
        }
      } else if (pathInvoked.repeatableRemove) {
        let repeatable_path = api.interfaces.InputPaths.parentPath(path, 1);
        const repeatable_state = api.utils.general.getByPath(state, repeatable_path);
        const newMap = this.mapRepeatableNumberBindings({
          item: {},
          state,
          repeatable: cardId,
          repeatableState: repeatable_state,
          api,
        });
        if (!newMap) continue;
        mappedValues = { ...mappedValues, ...newMap.values };
        mappedValuesData = { ...mappedValuesData, ...newMap.data };
      } else if (pathInvoked.repeatableChange) {
        let repeatable_path = api.interfaces.InputPaths.parentPath(path, 2);
        const repeatable_state = api.utils.general.getByPath(state, repeatable_path);
        const newMap = this.mapRepeatableNumberBindings({
          item: {
            fieldName: fieldName,
            value,
          },
          state,
          repeatable: cardId,
          repeatableState: repeatable_state,
          api,
        });
        if (!newMap) continue;
        mappedValues = { ...mappedValues, ...(newMap.values || {}) };
        mappedValuesData = { ...mappedValuesData, ...newMap.data };
      }
    }
    // this.log('Number Binding mapped values ', { mappedValues, mappedValuesData });
    if (!mappedValues) return;
    for (const [mappedKey, mappedValue] of Object.entries(mappedValues)) {
      this.setVariable(mappedKey, mappedValue);
    }
    const draftInfo = this.getDraftInfo();
    draftInfo.data.numberBindingData = {
      ...(draftInfo.data.numberBindingData || {}),
      ...mappedValuesData,
    };

    /* return (node, parent) => {
      if (node.variant === "vari" && node.data && mappedValues[node.data.name]) {
        api.utils.engine.setFirstChild(node, mappedValues[node.data.name])
        this.markNodeUpdate(node);
      }
    }; */
  },
};

export const mapRepeatableNumberBindings = function ({ item, state, repeatable, repeatableState, api }) {
  if (!this.getContractCreate().build || !this.getContractCreate().build.repeatablesNumberBindings)
    return null;
  if (!this.getContractCreate().build.repeatablesNumberBindings[repeatable]) return null;

  if (!repeatableState) {
    return null;
  }

  const mappedValues = {};
  const { fieldName } = item;

  const numberBindings = this.getContractCreate().build.repeatablesNumberBindings[repeatable].filter(
    (binding) =>
      binding.key === fieldName || binding.boundTo === fieldName || binding.multipleWith === fieldName
  );
  const result = {};
  for (const [, eachRepeatableState] of Object.entries(repeatableState)) {
    for (const numberBinding of numberBindings) {
      if (!numberBinding.key || !numberBinding.label) continue;
      if (!eachRepeatableState.hasOwnProperty(numberBinding.key)) continue;

      const { isNumber, value } = api.utils.general.getPotentialNumber(
        eachRepeatableState[numberBinding.key]
      );

      // let number = parseFloat(this.combineNumber(eachRepeatableState[numberBinding.key])) || 0;
      let number = value;

      // If we are to multiple the relevant number with something...
      if (
        isNumber &&
        numberBinding.multipleWith &&
        eachRepeatableState.hasOwnProperty(numberBinding.multipleWith)
      ) {
        const multipleNumber =
          parseFloat(this.combineNumber(eachRepeatableState[numberBinding.multipleWith])) || 1;
        if (!Number.isNaN(multipleNumber)) {
          if (numberBinding.isPercentage) number = (number / 100) * multipleNumber;
          else number = number * multipleNumber;
        }
      }
      // if (isNaN(number)) continue;
      if (!result[numberBinding.key]) result[numberBinding.key] = { values: {}, label: numberBinding.label };
      if (numberBinding.boundTo) {
        if (!eachRepeatableState[numberBinding.boundTo]) continue;
        const boundKey = eachRepeatableState[numberBinding.boundTo];
        if (!result[numberBinding.key].values[boundKey]) {
          if (isNumber) result[numberBinding.key].values[boundKey] = 0;
          else result[numberBinding.key].values[boundKey] = '';
        }
        result[numberBinding.key].values[boundKey] += number;
        if (numberBinding.includeBindValue)
          result[numberBinding.key].include = numberBinding.includeBindValue;
        continue;
      }
      if (isNumber) {
        if (!result[numberBinding.key].values._total) result[numberBinding.key].values._total = 0;
        result[numberBinding.key].values._total += number;
      }
    }
  }
  for (const key in result) {
    if (result[key].values.hasOwnProperty('_total')) {
      mappedValues[result[key].label] = this.splitNumber(result[key].values._total);
      continue;
    }
    const allBoundKeys = [];
    for (const boundKey in result[key].values) {
      if (boundKey === '_total') continue;
      let valueString = this.splitNumber(result[key].values[boundKey]);
      switch (result[key].include) {
        case 'prefix':
          valueString = boundKey + ' ' + valueString;
          break;
        case 'suffix':
          valueString += ' ' + boundKey;
          break;
        default:
          break;
      }
      allBoundKeys.push(valueString);
      mappedValues[result[key].label + '_' + boundKey] = valueString;
    }
    mappedValues[result[key].label] = this.imp_and(allBoundKeys);
  }

  return {
    values: mappedValues,
    data: Object.values(result).reduce((acc, curr) => {
      acc[curr.label] = curr.values;
      return acc;
    }, {}),
  };
};
