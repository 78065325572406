import {
  SET_REALPERSONS,
  UPDATE_REALPERSON,
  INSERT_REALPERSON,
  REMOVE_LEGALPERSON,
} from 'constants/ActionTypes';

export const INIT_STATE = [];

export default function RealPersonsReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case SET_REALPERSONS:
      return action.payload;
    case UPDATE_REALPERSON:
      const newState = [...state];
      newState[action.payload.index] = action.payload.realPerson;
      return newState;
    case INSERT_REALPERSON:
      return [...state, action.payload];
    case REMOVE_LEGALPERSON:
      const payloadType = typeof action.payload;
      let removeIds = [];
      if (payloadType === 'number') {
        console.log('Remove_realperson got number..', action.payload);
        return state;
      }
      if (payloadType === 'string') {
        removeIds = [action.payload];
      } else if (Array.isArray(action.payload)) removeIds = action.payload;
      else return state;
      return state.filter((realPerson) => !removeIds.includes(realPerson.id));
    default:
      return state;
  }
}
