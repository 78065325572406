import api from "utils/api";
import {message} from "antd";


const save = function(id, fields, opts = {}) {

    return new Promise(function(resolve, reject) {
 
        if(!id || typeof fields !== "object") return console.log('Insufficient save data for version', {id, fields})
 
        api.put("/versions/"+id, fields)
            .then(function(res) {
                if(!res.data) {
                    return reject(res);
                }
                if(opts.notify)
                  message.success('Saved successfully');
                
                return resolve(res.data);
            })
            .catch(err => reject(err));
    }); 
  }



export default save;
