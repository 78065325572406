export const QandA_add = {
  id: 'QandA_add',
  dependencies: {
    ordinaryQuestionAnswerAdd: true,
  },
  mode: 'single',
  handler: function ({ state, handlerInvoked, entry, path, api }) {
    const { cardId, fieldName } = entry;

    console.log('Is Q & A invoked ? ')

    const fieldPath = 'input.' + cardId + '.' + fieldName;
    const itemUid = this.api.interfaces.InputPaths.getLast(path);

    return (node, parents, index) => {
      if ((node.type !== 'clause' && node.type !== 'table') || !node.data || !node.data.qa || node.data.qa.fieldPath !== fieldPath)
        return;

      const { type } = node

      const newItem = this.makeParagraph(
        [
          { text: '' },
          this.makeInlineNode('field', this.emptyContractString(), {
            qa: { fieldPath: fieldPath + '.' + itemUid + '.q' },
          }),
          { text: ' ' },
          this.makeInlineNode('field', this.emptyContractString(), {
            qa: { fieldPath: fieldPath + '.' + itemUid + '.a' },
          }),
        ],
        { qa: { fieldPath: fieldPath+'.'+itemUid } }
      );

      node.children.push(newItem);
    };
  },
};

export const QandA_remove = {
  id: 'QandA_remove',
  dependencies: {
    ordinaryQuestionAnswerRemove: true,
  },
  mode: 'single',
  handler: function ({ state, handlerInvoked, entry, path, api }) {
    const { cardId, fieldName } = entry;
    const itemUid = this.api.interfaces.InputPaths.getLast(path);
    const fieldPath = 'input.' + cardId + '.' + fieldName + '.' + itemUid;
    
    return (node, parents, index) => {
      if (!node.data || !node.data.qa || node.data.qa.fieldPath !== fieldPath)
        return;

      parents[0].children.splice(index, 1)

    };
  },
};

export const QandA_change = {
  id: 'QandA_change',
  dependencies: {
    ordinaryQuestionAnswerChange: true,
  },
  mode: 'single',
  handler: function ({ state, handlerInvoked, entry, path, api }) {
    return (node, parents, index) => {
      if (node.type !== 'field' || !node.data || !node.data.qa || node.data.qa.fieldPath !== path) return;

      const value = entry.value || this.emptyContractString();

      api.utils.engine.setFirstChild(node, value);
      this.markNodeUpdate(node);
    };
  },
};

function findQAclause(parents) {
  for (const parent of parents) {
    if (!parent.data || !parent.data.qa || (parent.type !== 'clause' && parent.type !== 'table')) continue;
    return parent
  }
}

export const repeatableQandA_add = {
  id: 'repeatableQandA_add',
  dependencies: {
    repeatableQuestionAnswerAdd: true,
  },
  mode: 'single',
  handler: function ({ state, handlerInvoked, entry, path, api }) {
    const { cardId, fieldName } = entry;

    const fieldPath = this.api.interfaces.InputPaths.removeLast(path);
    const itemUid = this.api.interfaces.InputPaths.getLast(path);

    return (node, parents, index) => {
      if ((node.type !== 'clause' && node.type !== 'table') || !node.data || !node.data.qa || node.data.qa.fieldPath !== fieldPath)
        return;
      const { type } = node

      const newItem = this.makeParagraph(
        [
          { text: '' },
          this.makeInlineNode('field', this.emptyContractString(), {
            qa: { fieldPath: fieldPath + '.' + itemUid + '.q' },
          }),
          { text: ' ' },
          this.makeInlineNode('field', this.emptyContractString(), {
            qa: { fieldPath: fieldPath + '.' + itemUid + '.a' },
          }),
        ],
        { qa: { fieldPath: fieldPath+'.'+itemUid } }
      );

      node.children.push(newItem);
    };
  },
};

export const repeatableQandA_remove = {
  id: 'repeatableQandA_remove',
  dependencies: {
    repeatableQuestionAnswerRemove: true,
  },
  mode: 'single',
  handler: function ({ state, handlerInvoked, entry, path, api }) {
    
    return (node, parents, index) => {
      if (!node.data || !node.data.qa || node.data.qa.fieldPath !== path)
        return;
      parents[0].children.splice(index, 1)

    };
  },
};

export const repeatableQandA_change = {
  id: 'repeatableQandA_change',
  dependencies: {
    repeatableQuestionAnswerChange: true,
  },
  mode: 'single',
  handler: function ({ state, handlerInvoked, entry, path, api }) {
    
    return (node, parents, index) => {
      if (node.type !== 'field' || !node.data || !node.data.qa || node.data.qa.fieldPath !== path) return;

      const value = entry.value || this.emptyContractString();

      api.utils.engine.setFirstChild(node, value);
      this.markNodeUpdate(node);
    };
  },
};