import { combineReducers } from 'redux'
import Settings from "./Settings";
import Auth from "./Auth";
// import Notes from "./Notes";
// import Contact from "./Contact";
import Common from "./Common";
import Input from "./Input";
import Nodes from "./Nodes";
import Draft from "./Draft"
import LegalPersons from './LegalPersons';
import RealPersons from './RealPersons';


const createRootReducer = (history) => combineReducers({
  settings: Settings,
  auth: Auth,
  // notes: Notes,
  // contact: Contact,
  legalPersons: LegalPersons,
  realPersons: RealPersons,
  common: Common,
  input: Input,
  nodes: Nodes,
  draft: Draft
});

export default createRootReducer
