import { Contract } from '../../../interfaces';
import { splitNumber, combineNumber } from '../../../utils/general';

export const getGeneralValues = function (path, value) {
  const fieldData = Contract.getUiInputFieldDataByPath(this.contract, path);

  let convertedToNumberText = false;

  const adjustedValues = (Array.isArray(value) ? value : [value]).map((val) => {
    const { value: newValue, textNumberConverted } = this._getGeneralValueAdjustFieldValue(path, val);
    if (textNumberConverted) convertedToNumberText = true;
    return newValue;
  });

  return {
    originalValue: value,
    values: adjustedValues,
    combinator: fieldData && fieldData.combinator,
    convertedToNumberText,
  };
};

export const getValues = function (path, value, node) {
  let valueData = this.getGeneralValues(path, value);
  if (node) valueData = this.getNodeValues(node, valueData);
  return this.getFinalValue(valueData);
};

export const _getGeneralValueAdjustFieldValue = function (path, value) {
  value = Contract.getUiInputFieldValueByPath(this.contract, path, value) || value;

  const textNumberConversion = this.convertToNumberText(value, { path });

  if (textNumberConversion.converted) {
    return {
      value: textNumberConversion.value,
      textNumberConverted: true,
    };
  }
  return {
    value,
    textNumberConverted: false,
  };
};

export const getNodeValues = function (node, valueData) {
  let { values, convertedToNumberText } = valueData;

  if (!convertedToNumberText)
    values = values.map((value) => {
      const { value: newValue } = this.convertToNumberText(value, { node });
      return newValue;
    });

  if (node.data && node.data.format) {
    values = values.map((value) => {
      return this.formatNodeText(value, { node });
    });
  }

  if (node && node.data && node.data.instructions && node.data.instructions.allowConceptConversion) {
    values = values.map((value) => {
      const conversionResult = this.mapCompanies(value, this.states && this.states.current);

      if (node.data.instructions.useDefinition) {
        const definedName = this.companyDefinition(value);
        if (definedName !== value) {
          return definedName;
        }
      }

      if (conversionResult && conversionResult.perfectMatch) {
        if (
          node.data.instructions.fullText &&
          conversionResult['fullText_' + node.data.instructions.fullText]
        ) {
          return conversionResult['fullText_' + node.data.instructions.fullText];
        } else {
          return conversionResult.fullText_and;
        }
      }
      return value;
    });
  } else if (node && node.data && node.data.instructions && node.data.instructions.useDefinition) {
    values = values.map((value) => {
      const definedName = this.companyDefinition(value);
      if (definedName) {
        return definedName;
      }
      return value;
    });
  }

  // Ensure no empty values
  if (this.settings.replaceEmptyContractStrings && this.settings.emptyReplacement) {
    values = values.map((value) => value || this.settings.emptyReplacement);
  }

  // console.log('Values are ... ', { values })

  return {
    ...valueData,
    values,
  };
};

export const getFinalValue = function (valueData) {
  let { values, combinator } = valueData;
  values = values.map((value) => this.cleanValue(value));
  if (values.length > 1) {
    if (combinator && typeof this['imp_' + combinator] === 'function') {
      return this['imp_' + combinator](values);
    }
    return values.join(' / ');
  } else {
    return values[0];
  }
};

export const cleanValue = function (value) {
  const valueType = typeof value;
  if (
    value === '' &&
    this.settings &&
    this.settings.replaceEmptyContractStrings &&
    typeof this.settings.emptyReplacement === 'string'
  ) {
    return this.settings.emptyReplacement;
  }
  if (valueType === 'string') return value;
  else if (valueType === 'number') return value.toString();
  else if (valueType === 'object' && value.id) {
    const item = this.api.interfaces.Entity.getById(this.states.current, value.id);
    return (
      item.name ||
      (this.settings &&
        this.settings.replaceEmptyContractStrings &&
        typeof this.settings.emptyReplacement === 'string' &&
        this.settings.emptyReplacement) ||
      ''
    );
  }
  return '';
};

export const convertToNumberText = function (value, options = {}) {
  const { node, cardId, name, path } = options;
  let mayFormat = false;
  if (cardId && name) {
    const fieldData = Contract.getUiInputFieldData(this.contract, name);
    if (fieldData && fieldData.format === 'numbertext') mayFormat = true;
  }
  if (path) {
    const fieldData = Contract.getUiInputFieldDataByPath(this.contract, path);
    if (fieldData && fieldData.format === 'numbertext') mayFormat = true;
  }
  if (node && node.data && (node.data.numbertext || node.data.format === 'numbertext')) mayFormat = true;

  const matchingFormat =
    this.getContractSettings() &&
    this.getContractSettings().format &&
    this.getContractSettings().format.numberText[this.language] &&
    this.getContractSettings().format.numberText[this.language][parseInt(value)];

  if (mayFormat && matchingFormat) {
    return {
      value: matchingFormat + ' (' + value + ')',
      converted: true,
    };
  }
  return { value, converted: false };
};

export const formatNodeText = function (value, options = {}) {
  const { node } = options;

  if (node && node.data && node.data.format !== 'number') return value;

  const language = this.language;
  if (!language) return value;
  return splitNumber(combineNumber(value, language), language);
};
