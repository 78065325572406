import moment from 'moment';

export const dateAgreementModule = {
  id: 'dateAgreementModule',
  dependencies: { onGenesis: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    moment.locale(api.interfaces.Contract.getLanguage(this.contract));

    return (node, parents) => {
      if (!api.utils.engine.matchModule(node, 'facilityAgreement', 'dateAgreement')) return;

      const args = (node.data && node.data.module && node.data.module.arguments) || {};
      const { format, prefix, suffix } = args;

      if (!format) {
        console.log('No format for dateAgreement.');
        return;
      }
      let value = moment().format(format);

      if (prefix) {
        value = prefix + value;
      }
      if (suffix) {
        value = value + suffix;
      }

      api.utils.engine.setFirstChild(node, value);
      this.markNodeUpdate(node);
    };
  },
};
