import React, { useEffect, useState } from 'react';
import { Row, Col, Alert, Card, Button } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import manager from 'core/engine/manager';
import CardsContainer from './CardsContainer';
import TemplatePageActions from './PageActions';
import { useContract, useIsTemplateStudio, setEventState } from 'hooks';
import { Create, Contract } from 'core/interfaces';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import config from 'config/config';
import { RightOutlined, DownOutlined } from '@ant-design/icons';

const { MULTI_PAGE = false } = config?.ui?.studio || {};

function CreatePage(props) {
  const { route, parents, isActive } = props;
  const [collapsed, setCollapsed] = useState(false);
  const contract = useContract();
  const location = useLocation();
  const isTemplate = useIsTemplateStudio();
  const ui = Contract.getUi(contract);
  const language = Contract.getLanguage(contract);
  const page = Create.getPage(ui, route);
  const { collapse } = route;
  const formatMessage = useIntlMessage();
  const { pathname } = location;

  useEffect(() => {
    if (!page || !isActive) return;
    manager.navigateTo(page.scrollContractOnFocus);
  }, [page, isActive]);

  useEffect(() => {
    if (!MULTI_PAGE) return;
    if (pathname === route.fullPath) {
      console.log('match at ', route.paths);
      setCollapsed(false);
    }
  }, [pathname, route]);

  if (MULTI_PAGE && !isActive) {
    return null;
  }

  const addNewCard = (insertPosition) => {
    setEventState('template_edit_input_sections', { type: 'card', method: 'add', insertPosition });
  };

  const addNewChildPage = () => {
    setEventState('template_edit_input_sections', {
      type: 'page',
      method: 'add',
      selected: { addChildPage: true },
    });
  };

  const mainCol = 24;

  if (collapse) {
    if (!isTemplate) return null;
    return (
      <div id={`creating-input-${route.id}`} className="creating-input">
        {page.pages && Object.keys(page.pages).length > 0 && (
          <Row>
            <Col span={mainCol}>
              <div className="p-1">
                <h4>
                  <IntlMessages id="studio.template.pages.subPages" />
                </h4>
                <div className="pl-2">
                  {/* <Card className="border-0" title={<IntlMessages id="studio.template.pages.subPages" />}> */}
                  {Object.values(page.pages).map((childPage) => {
                    const childRoute = Create.getRouteByPath(contract.data.ui, `${pathname}/${childPage.id}`);
                    const childPath = childRoute && childRoute.fullPath;
                    return (
                      <div key={childPage.id}>
                        <Link to={childPath}>{childPage.header[language]}</Link>
                      </div>
                    );
                  })}
                  {/* </Card> */}
                </div>
              </div>
            </Col>
          </Row>
        )}
        {isTemplate && (
          <Row className="mt-4">
            <Col span={mainCol}>
              <div className="liftable" style={{ border: '2px dashed #dedede', marginBottom: '10px' }}>
                <div
                  className="clickable hoverable d-flex align-items-center justify-content-center"
                  style={{ height: '80px' }}
                  onClick={addNewChildPage}
                >
                  <IntlMessages id="studio.template.modal.page.addChild" />
                </div>
              </div>
              {/* <Card className="border-0" title={}>
                <Row>
                  <Button type="primary" >
                    <IntlMessages id="studio.template.pages.addButton" />
                  </Button>
                </Row>
              </Card> */}
            </Col>
          </Row>
        )}
      </div>
    );
  }

  const AddBox = ({ insertPosition }) => {
    if (!isTemplate) return null;
    return (
      <Row>
        <Col span={mainCol}>
          <div className="liftable" style={{ border: '2px dashed #dedede', marginBottom: '10px' }}>
            <div className="clickable hoverable text-center" onClick={() => addNewCard(insertPosition)}>
              <div className="ant-card-head-title">
                <IntlMessages id="studio.template.cards.addButton" />
              </div>
              {insertPosition && insertPosition === 'top' && (
                <div style={{ paddingBottom: '10px' }}>
                  <small>
                    <IntlMessages id="studio.template.cards.addButtonTop" />
                  </small>
                </div>
              )}
              {insertPosition && insertPosition === 'bottom' && (
                <div style={{ paddingBottom: '10px' }}>
                  <small>
                    <IntlMessages id="studio.template.cards.addButtonBottom" />
                  </small>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const fullIdString = parents ? parents.map((parent) => parent.id + '-') + route.id : route.id;

  return (
    <div id={`creating-input-${fullIdString}`} className="creating-input">
      {isTemplate && <TemplatePageActions />}
      <AddBox insertPosition="top" />
      <Row className="create-page-row">
        <PageHeader
          ui={ui}
          page={page}
          route={route}
          parents={parents}
          language={language}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        {collapsed ? null : (
          <Col span={mainCol} className="pb-4">
            {page ? (
              <CardsContainer page={page} parents={parents} language={language} />
            ) : (
              <Alert
                message={formatMessage('studio.template.pages.addButton')}
                description=" "
                type="warning"
                style={{ minHeight: '120px' }}
              />
            )}
          </Col>
        )}
      </Row>
      {page.cards.length > 0 && <AddBox insertPosition="bottom" />}
    </div>
  );
}

function PageHeader({ page, parents, ui, language, collapsed, setCollapsed }) {
  if (!MULTI_PAGE) return <div className="create-page-header">{page.header[language]}</div>;
  const header = [];
  if (parents) {
    for (const parent of parents) {
      const parentPage = Create.getPage(ui, parent);

      header.push(
        <React.Fragment key={parent.paths.toString()}>
          <span className="create-page-header-text parent">{parentPage.header[language]}</span>
          <RightOutlined />
        </React.Fragment>
      );
    }
  }

  header.push(
    <span key={page.id} className="create-page-header-text current-page">
      {page.header[language]}
    </span>
  );

  return (
    <div className="create-page-header clickable" onClick={() => setCollapsed(!collapsed)}>
      <span className="mr-4 fs-lg d-flex align-items-center">
        {collapsed ? <RightOutlined /> : <DownOutlined />}
      </span>
      <span className="header-text">{header}</span>
    </div>
  );
}

CreatePage.propTypes = {
  route: PropTypes.object.isRequired,
};

export default CreatePage;

/* header += parentPage.header[language] + ' > '; */
