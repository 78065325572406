import React, { useState } from 'react';
import { useContract } from 'hooks';
import { Contract } from 'core/interfaces';
import { unwrapNodes } from '../../legal/helpers';
import {ConditionActions, PrintCondition} from 'components/Rules/NodeConditions'

export default function AcpField({ editor, node, path, close }) {
  const contract = useContract();
  const [acp, setAcp] = useState(node.data.acp || null);
  const rule = Contract.getRule(contract, acp);
  const [updateTogglerCount, setUpdateTogglerCount] = useState(false)

  const onChangeRule = (id) => {
    setAcp(id);
    // Ensure this components always updates so we get updated PrintRule result
    // even if the id is the same (e.g. the user is just saving new data to the
    // same rule with the same id)
    setUpdateTogglerCount(!updateTogglerCount) 
  };

  const disconnectInput = () => {
    // TODO: Double check if this is correct?
    // unwrapNodes(editor, path.slice(0, -1), node.variant);
    // Or should it be: 
    unwrapNodes(editor, path);
  };

  return (
    <div className="flex-column">
      <div className="d-flex mb-3" style={{ justifyContent: 'space-between' }}>
        <h5>Field Condition</h5>
      </div>

      {!rule && (
        <div className="mb-3">
          <h5>Warning!</h5>
          <small className="ant-typography ant-typography-danger">
            Condition does not stipulate a rule. <br />
            Please add by clicking <em>Set Rule</em> below.
          </small>
        </div>
      )}
      {rule && (
        <div className="mb-3">
          <h5>Label</h5>
          <small>{(rule && rule.label) || 'No label'}</small>
        </div>
      )}

      {rule && rule.data && (
        <div className="mb-3">
          <h5>Summary</h5>
          <PrintCondition node={node} />
        </div>
      )}
      <div className="mb-1">
        <h5>Actions</h5>
      </div>
      <div>
        <ConditionActions node={node} stringPath={JSON.stringify(path)} onChange={onChangeRule} />
      </div>
      <div className="mt-2 mb-2">
        <span
          className="link"
          onMouseDown={() => {
            disconnectInput();
          }}
        >
          Remove condition
        </span>
      </div>
    </div>
  );
}


