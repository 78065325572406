/* eslint-disable no-param-reassign */
import uuid from 'uuid-random';
import { Contract } from '../../interfaces'

const operators = ['==', '!=', '>', '>=', '<', '<='];
const allOperators = [...operators, '===', '!==', '!', '!!'];
const conjunctions = ['and', 'or'];

function firstKey(obj) {
  return !!obj && typeof obj === 'object' && Object.keys(obj)[0];
}

export default function convertContractRules(contract) {
  const savedRules = {};

  if (contract.data.settings.modelVersion && contract.data.settings.modelVersion > 1.08) {
    return
  }

  const mainValues = {
    count: 0,
    fs: [],
    done: {},
    itemData: {},
    contract,
  };

  convertPages(contract, mainValues);
  convertRoutes(contract, mainValues);
  convertInputs(contract, mainValues);
  convertContent(contract, mainValues);
  convertSmartDefinitions(contract, mainValues);

  const ufs = [...new Set(mainValues.fs)];
  mainValues.ufs = ufs;
  convertRepeatableActivation(contract, mainValues);

  // console.log('Count ', { count: mainValues.count, ufs });

  const draftInfo = Contract.getDraftInfo(contract)

  const reversedDone = {};
  for (const [key, entry] of Object.entries(mainValues.done)) {
    reversedDone[entry] = key;
    savedRules[entry] = {
      label:
        mainValues.itemData[entry] && mainValues.itemData[entry].label
          ? mainValues.itemData[entry].label
          : null,
      description: null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      data: JSON.parse(key),
    };
    if (mainValues.itemData[entry] && mainValues.itemData[entry].code) {
      savedRules[entry].code = mainValues.itemData[entry].code;
      savedRules[entry].data = null;
      savedRules[entry].value =
        draftInfo &&
        draftInfo.shortcutStates &&
        typeof draftInfo.shortcutStates[entry] === 'boolean'
          ? draftInfo.shortcutStates[entry]
          : false;
    }
  }
  // console.log('reversedDone', { reversedDone, savedRules, itemData: mainValues.itemData });
  contract.data.create.savedRules = {
    ...(contract.data.create.savedRules || {}),
    ...savedRules,
  };
}

function convertRepeatableActivation(contract, mainValues) {
  if (contract.data.create.build.repeatableActivation) {
    for (const item of contract.data.create.build.repeatableActivation) {
      if (!mainValues.ufs.includes(item.activate)) {
        // console.log('Skip shortcut ', item.activate);
        continue;
      }
      item.rule = convertRule({ rule: item.rule, mainValues });
    }
  }
}
function convertPages(contract, mainValues) {
  doConvertPages(contract.data.ui.pages, mainValues);
}
function doConvertPages(pages, mainValues) {
  for (const pageId in pages) {
    const page = pages[pageId];
    if (page.acp) page.acp = convertRule({ rule: page.acp, mainValues });
    if (page.pages) doConvertPages(page.pages, mainValues);
  }
}
function convertRoutes(contract, mainValues) {
  doConvertRoutes(contract.data.ui.routes, mainValues);
}
function doConvertRoutes(routes, mainValues) {
  for (const route of routes) {
    if (route.acp) route.acp = convertRule({ rule: route.acp, mainValues });
    if (route.children) doConvertRoutes(route.children, mainValues);
  }
}
function convertInputs(contract, mainValues) {
  for (const id in contract.data.ui.inputs) {
    const input = contract.data.ui.inputs[id];
    if (input.acp) input.acp = convertRule({ rule: input.acp, mainValues });
  }
}
function convertContent(contract, mainValues) {
  function convCont(node) {
    if (node && node.data && node.data.acp) node.data.acp = convertRule({ rule: node.data.acp, mainValues });
    if (node && node.data && node.data.acp_descr) delete node.data.acp_descr;
    if (node.variant === 'enum' && node.data && node.data.enums) {
      for (const item of node.data.enums) {
        if (item.rule) item.rule = convertRule({ rule: item.rule, mainValues });
      }
    }
    if (node.data && node.data.filter) node.data.filter = convertRule({ rule: node.data.filter, mainValues });
  }

  onContract(contract.data.content, convCont);
}

function convertSmartDefinitions(contract, mainValues) {
  function convCont(node) {
    if (node && node.data && node.data.acp) node.data.acp = convertRule({ rule: node.data.acp, mainValues });
    if (node && node.data && node.data.acp_descr) delete node.data.acp_descr;
    if (node.variant === 'enum' && node.data && node.data.enums) {
      for (const item of node.data.enums) {
        if (item.rule) item.rule = convertRule({ rule: item.rule, mainValues });
      }
    }
    if (node.data && node.data.filter) node.data.filter = convertRule({ rule: node.data.filter, mainValues });
  }

  if (!contract.data.create || !contract.data.create.build || !Array.isArray(contract.data.create.build.smartDefinitions)) {
    console.log('No smart definitions')
  }
  for (const smartDef of contract.data.create.build.smartDefinitions) {
    onContract(smartDef.xcontent, convCont)
  }
}

function onContract(node, fn) {
  if (Array.isArray(node)) {
    for (var y of node) {
      onContract(y, fn);
    }
    return;
  }
  if (node.children) onContract(node.children, fn);
  if (typeof node === 'object') {
    fn(node);
  }
}

function convertRule({ rule, topLevel = true, lastIsTopConjunction = false, parentTmpData, mainValues }) {
  if (!rule || !mainValues || !mainValues.contract) {
    console.log('RULE AND CONTRACT EXP.', { rule, mainValues });
    return '';
  }
  if (typeof rule === 'string') return rule;
  let newRule = {};
  let tmpData = {};
  function setTmpData(val) {
    tmpData = { ...tmpData, ...val };
  }
  const key = firstKey(rule);

  if (topLevel) mainValues.count++;

  let wrapInConjunction = false;

  if (allOperators.indexOf(key) > -1) {
    if (topLevel) wrapInConjunction = true;
    const data = rule[key];
    const [first, ...rest] = data;
    const ruleType = firstKey(first);
    switch (ruleType) {
      case 'count_repeatable':
        const c = first.count_repeatable;
        newRule[key] = [
          {
            numof: { card: c[0], condition: { and: [{ '==': [{ var: c[1] }, c[2]] }] } },
          },
          rest[0],
        ];
        break;
      case 'numof_repeatable':
        newRule[key] = [{ numof: { card: first.numof_repeatable } }, rest[0]];
        break;
      case 'any_repeatable':
        const anr = first.any_repeatable;
        newRule['>'] = [
          {
            numof: {
              card: anr[0],
              condition: { and: [{ [key]: [{ var: anr[1] }, anr[2]] }] },
            },
          },
          0,
        ];
        break;
      case 'all_repeatable':
        const ar = first.all_repeatable;
        newRule['=='] = [
          {
            numof: {
              card: ar[0],
              condition: { and: [{ [key]: [{ var: ar[1] }, ar[2]] }] },
            },
          },
          { numof: { card: ar[0] } },
        ];
        break;
      case 'any_item':
        newRule[key] = [{ any_in_card: [first.any_item[0], first.any_item[1]] }, true];
        break;
      case 'all_items':
        newRule[key] = [{ all_in_card: [first.all_items[0], first.all_items[1]] }, true];
        break;
      case 'numof_nodes':
        newRule[key] = [{ numof_state: 'legalPersons' }, rest[0]];
        break;
      case 'input':
      case 'setup':
      case 'local':
        newRule = rule;
        break;
      case 'shortcut':
        // newRule = rule
        // if (rest[0] === true) console.log('ShortCut true')
        if (first.shortcut === 'GROUP_TRUE' && rest[0] === false) {
          newRule = {
            and: [
              {
                '<=': [{ numof_state: 'legalPersons' }, 2],
              },
            ],
          };
        } else {
          const repActItem = mainValues.contract.data.create.build.repeatableActivation.find(
            (x) => x.activate === first.shortcut
          );

          const shortcutData = {};
          if ((topLevel || lastIsTopConjunction) && parentTmpData) shortcutData.label = first.shortcut;
          if (!repActItem) {
            // console.log('Not find repActItem', first.shortcut, lastIsTopConjunction);
            if (first.shortcut === 'CURRENCY_EUR') {
              console.log('Currency EUR HERE.');
            }
            // console.log('No RepActItem rule ', JSON.stringify(rule));
            if ((topLevel || lastIsTopConjunction) && parentTmpData) {
              // console.log('Manual ', first.shortcut, wrapInConjunction)
              shortcutData.code = first.shortcut;
              newRule = rule;
            } else {
              console.log('Cannot update rule for shortcut item.');
              newRule = rule;
            }
          } else {
            const shortCutRuleWas = JSON.parse(JSON.stringify(repActItem.rule));
            const newRuleContent = convertRuleContent({ rule: shortCutRuleWas });
            // newRule = repActItem.rule;
            newRule = newRuleContent
          }
          if (Object.keys(shortcutData).length > 0) parentTmpData(shortcutData);
        }

        mainValues.fs.push(first.shortcut);

        break;
      default:
        // console.log('Unknown rule type ', ruleType);
        newRule = rule;
      //return rule;
    }
  } else if (conjunctions.indexOf(key) > -1) {
    newRule[key] = [];
    for (let i = 0; i < rule[key].length; i++) {
      const subRule = rule[key][i];
      // for (const subRule of rule[key]) {
      newRule[key].push(
        convertRule({
          rule: subRule,
          topLevel: false,
          lastIsTopConjunction: topLevel && i === 0,
          parentTmpData: setTmpData,
          mainValues,
        })
      );
    }
  }

  if (wrapInConjunction) {
    newRule = {
      and: [newRule],
    };
  }
  if (topLevel) {
    const stringed = JSON.stringify(newRule);
    let id;
    if (tmpData.code) {
      id = tmpData.code;
      mainValues.done[stringed] = id;
    } else if (mainValues.done[stringed]) {
      id = mainValues.done[stringed];
    } else {
      id = uuid();
      mainValues.done[stringed] = id;
    }
    if (tmpData && Object.keys(tmpData).length > 0) {
      if (mainValues.itemData[id]) {
        mainValues.itemData[id] = { ...mainValues.itemData[id], ...tmpData };
      } else {
        mainValues.itemData[id] = tmpData;
      }
    }
    return id;
  }

  return newRule;
}

function convertRuleContent({ rule, topLevel = true }) {
  if (!rule) {
    console.log('RULE AND CONTRACT EXP.', { topLevel });
    return '';
  }
  if (typeof rule === 'string') return rule;
  let newRule = {};

  const key = firstKey(rule);

  if (allOperators.indexOf(key) > -1) {
    const data = rule[key];
    const [first, ...rest] = data;
    const ruleType = firstKey(first);
    switch (ruleType) {
      case 'count_repeatable':
        const c = first.count_repeatable;
        newRule[key] = [
          {
            numof: { card: c[0], condition: { and: [{ '==': [{ var: c[1] }, c[2]] }] } },
          },
          rest[0],
        ];
        break;
      case 'numof_repeatable':
        newRule[key] = [{ numof: { card: first.numof_repeatable } }, rest[0]];
        break;
      case 'any_repeatable':
        const anr = first.any_repeatable;
        newRule['>'] = [
          {
            numof: {
              card: anr[0],
              condition: { and: [{ [key]: [{ var: anr[1] }, anr[2]] }] },
            },
          },
          0,
        ];
        break;
      case 'all_repeatable':
        const ar = first.all_repeatable;
        newRule['=='] = [
          {
            numof: {
              card: ar[0],
              condition: { and: [{ [key]: [{ var: ar[1] }, ar[2]] }] },
            },
          },
          { numof: { card: ar[0] } },
        ];
        break;
      case 'any_item':
        newRule[key] = [{ any_in_card: [first.any_item[0], first.any_item[1]] }, true];
        break;
      case 'all_items':
        newRule[key] = [{ all_in_card: [first.all_items[0], first.all_items[1]] }, true];
        break;
      case 'numof_nodes':
        newRule[key] = [{ numof_state: 'legalPersons' }, rest[0]];
        break;
      case 'input':
      case 'setup':
      case 'local':
        newRule = rule;
        break;
      case 'shortcut':
        // newRule = rule
        // if (rest[0] === true) console.log('ShortCut true')
        /*
        if (first.shortcut === 'GROUP_TRUE' && rest[0] === false) {
          newRule = {
            and: [
              {
                '<=': [{ numof_state: 'legalPersons' }, 2],
              },
            ],
          };
        } else {
          const repActItem = mainValues.contract.data.create.build.repeatableActivation.find(
            (x) => x.activate === first.shortcut
          );

          const shortcutData = {};
          if ((topLevel || lastIsTopConjunction) && parentTmpData) shortcutData.label = first.shortcut;
          if (!repActItem) {
            // console.log('Not find repActItem', first.shortcut, lastIsTopConjunction);
            if (first.shortcut === 'CURRENCY_EUR') {
              console.log('Currency EUR HERE.')
            }
            // console.log('No RepActItem rule ', JSON.stringify(rule));
            if ((topLevel || lastIsTopConjunction) && parentTmpData) {
              // console.log('Manual ', first.shortcut, wrapInConjunction)
              shortcutData.code = first.shortcut;
              newRule = rule;
            } else {
              console.log('Cannot update rule for shortcut item.');
              newRule = rule;
            }
          } else {
            if (first.shortcut === 'CURRENCY_EUR') {
              console.log('Currency EUR NOOOOOT HERE.')
              console.log('Set rule to... ', {rule: JSON.parse(JSON.stringify(repActItem.rule)), wrapInConjunction})
              shortcutData.isCEUR = true
            }
            newRule = repActItem.rule;
          }
          if (Object.keys(shortcutData).length > 0) parentTmpData(shortcutData);
        }

        mainValues.fs.push(first.shortcut);
        */
        console.log('Should not have a shortcut within a shortcut?! ');
        newRule = rule;
        break;
      default:
        // console.log('Unknown rule type ', ruleType);
        newRule = rule;
      //return rule;
    }
  } else if (conjunctions.indexOf(key) > -1) {
    newRule[key] = [];
    for (let i = 0; i < rule[key].length; i++) {
      const subRule = rule[key][i];
      // for (const subRule of rule[key]) {
      newRule[key].push(
        convertRuleContent({
          rule: subRule,
          topLevel: false,
        })
      );
    }
  }

  return newRule;
}
