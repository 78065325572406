import React from "react";
import { useSelector } from "react-redux"
import { Form, Select } from "antd";
import { getValue } from "./utils/values";

const { Option } = Select;

function InputSelect(props) {
  const {
    label,
    handleChange,
    language,
    path,
    cardUid,
    field,
    inputIndex,
  } = props;

  const nodes = useNodes(field)

  const value = getValue(props, field.value ? field.value : "");

  const id = field.name + inputIndex + path + cardUid;

  return (
    <Form.Item
      label={label || null}
      className="w-100"
      extra={
        field.info ? (
          <small className="muted d-block">{field.info[language]}</small>
        ) : null
      }
    >
      <Select
        onChange={handleChange}
        className="w-100"
        name={field.name}
        id={id}
        value={value}
        placeholder=""
      >
        {
          renderOptions(props, nodes)
        }
      </Select>
    </Form.Item>
  );
}

function renderOptions(props, nodes) {
  const {
    language,
    path,
    cardUid,
    field,
    inputIndex,
  } = props;

  if (field.content === "node-names") {
    // First, one empty...
    /* const firstEntry = (
      <Option
        key={"first_" + path + cardUid + inputIndex + field.name + "_x"}
        value=""
        disabled
      >
        -- Please select --
      </Option>
    ); */
    return [].concat(
      nodes
        .filter(node => node.parentId !== null)
        .map((node, nodeIndex) => (
          <Option
            key={
              node.id +
              path +
              cardUid +
              inputIndex +
              nodeIndex +
              field.name +
              "y"
            }
            value={node.name}
          >
            {node.name}
          </Option>
        ))
    );
  }

  if (!field.content) {
    console.log('No field content?? ', {field, props})
    return null;
  }

  return field.content.map((option, contentIndex) => (
    <Option
      key={
        option.id +
        path +
        cardUid +
        inputIndex +
        contentIndex +
        field.name +
        "x"
      }
      disabled={option.disabled}
      value={
        option.id
        /* option.values && option.values.hasOwnProperty(language)
          ? option.values[language]
          : option.id */
      }
    >
      {option.label[language]}
    </Option>
  ));
}

function useNodes(field) {
  return useSelector(state => {
    if (field.content === "node-names")
      return state.legalPersons
    return null
  })
}

export default InputSelect;
