import React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { ucfirst } from 'core/utils/general';

const InjectMassage = (props) => {
  const formater = useIntlMessage();
  let text = formater(props.id, props);
  return <span>{text}</span>;
  /* if (props.cap || props.capitalize) {
    return (
      <span style={{ textTransform: "capitalize" }}>
        <FormattedMessage {...props} />
      </span>
    );
  } else if (props.upper || props.uppercase) {
    return (
      <span style={{ textTransform: "uppercase" }}>
        <FormattedMessage {...props} />
      </span>
    );
  } else if (props.lower || props.lowercase) {
    return (
      <span style={{ textTransform: "lowercase" }}>
        <FormattedMessage {...props} />
      </span>
    );
  }
  return <FormattedMessage {...props} />; */
};
export default injectIntl(InjectMassage, {
  withRef: false,
});

export const useIntlMessage = () => {
  const intl = useIntl();
  return (id, formatting, values = {}) => {
    let text = intl.formatMessage({ id }, values);
    if (!formatting) return text;
    if (typeof formatting === 'string') {
      switch (formatting) {
        case 'cap':
        case 'capitalise':
          return ucfirst(text);
        case 'upper':
        case 'uppercase':
          return text.toLocaleUpperCase();
        case 'lower':
        case 'lowercase':
          return text.toLocaleLowerCase();
        default:
          return text;
      }
    } else if (formatting && typeof formatting === 'object') {
      if (formatting.cap) text = ucfirst(text);
      if (formatting.lower) text = text.toLocaleLowerCase();
      if (formatting.upper) text = text.toLocaleUpperCase();
      if (formatting.spaceBefore) text = ' ' + text;
      if (formatting.spaceAfter) text += ' ';
    }
    return text;
  };
};
