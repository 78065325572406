import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal } from 'antd';

import CustomScrollbars from 'util/CustomScrollbars';
import NewCustomerItem from './NewCustomerItem';
import IntlMessages from 'util/IntlMessages';

export default function NewCustomer(props) {
  const { open: propsOpen, setOpen: propsSetOpen, plusButton, className = '' } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (propsOpen !== undefined) setOpen(propsOpen);
  }, [propsOpen]);

  const toggleOpen = useCallback(() => {
    if (propsSetOpen) propsSetOpen(!open);
    else setOpen(!open);
  }, [open, propsSetOpen]);

  const buttonClassName = className || 'm-0 ml-2';

  return (
    <React.Fragment>
      {plusButton ? (
        <Button size="small" onClick={toggleOpen} className={'ml-auto '+buttonClassName} color="green">
          <i className="mdi mdi-plus" />
          {props.label || ''}
        </Button>
      )
    : (
      <Button
          type="primary"
          icon={<i className="icon icon-company mr-2" />}
          className={buttonClassName}
          onClick={toggleOpen}
        >
          <IntlMessages id="app.projects.NewCustomer" />
        </Button>
    )
    }
      <Modal
        title={
          <div>
            <div>
              <IntlMessages id="app.projects.NewCustomer" />
            </div>
          </div>
        }
        visible={open}
        backdrop={'static'}
        onOk={toggleOpen}
        onCancel={toggleOpen}
        style={{
          height: '80vh'
        }}
        footer={[
          <Button key="back" onClick={toggleOpen}>
            <IntlMessages id="desc.Cancel" />
          </Button>,
        ]}
      >
        <div className="creation-modal-body" style={{ height: 'calc(80vh - 125px)' }}>
          <CustomScrollbars className="creation-modal-scroll">
            <div style={{ height: 'calc(80vh - 125px)' }}>
              {open && (
                <div>
                  <NewCustomerItem customerWasCreated={props.customerWasCreated} close={toggleOpen} />
                </div>
              )}
            </div>
          </CustomScrollbars>
        </div>
      </Modal>
    </React.Fragment>
  );
}
