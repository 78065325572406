import { uniqueStateChanges } from "./draftSessionHelpers"

export const setPreviousState = function(state) {
  this.states.previous = { input: state.input, nodes: state.nodes, legalPersons: state.legalPersons, realPersons: state.realPersons };
}
export const getPreviousState = function() {
  return this.states.previous
}
export const setCurrentState = function(state) {
  const contractState = this.api.interfaces.State.produceContractState(state)
  this.states.current = { input: state.input, nodes: state.nodes, legalPersons: state.legalPersons, realPersons: state.realPersons };
  this.api.interfaces.Contract.setFullState(this.contract, contractState)
}
export const getCurrentState = function() {
  return this.states.current
}

export const handleNewState = function (state) {
  if (!state) return
  this.setCurrentState(state)
  const changesData = this.compareStates(this.states)
  const uniqueChanges = uniqueStateChanges(changesData, this.contract)
  return {changesData, uniqueChanges}
};

export const compareStates = function ({ previous, current }) {
  function filePaths(obj, prefix = "", store = {}, first = true) {
    for (let key in obj) {
      if (!obj.hasOwnProperty(key)) continue;
      const curPath = first ? key : `${prefix}.${key}`;
      if (typeof obj[key] === "object") {
        store[curPath] = obj[key];
        filePaths(obj[key], curPath, store, false);
      } else {
        store[curPath] = obj[key];
      }
    }
    return store;
  }

  if (!current) return {};

  // No previous means no comparison.
  if (!previous) previous = {};

  const currentCollection = filePaths(current);
  const currentKeys = Object.keys(currentCollection);
  const previousCollection = filePaths(previous);
  const previousKeys = Object.keys(previousCollection);
  const allKeys = [...new Set(currentKeys.concat(previousKeys))];

  // If states (a and b) do not originate from same source (such as update on backend)
  // then they share no javascript object references and
  // a shallow compare will not work (i.e., all `!==` comparisons
  // between identical objects will be true). In such case,
  // we need to make a deep compare (not JSON.stringify as that
  // will give false positives if objects have same content but
  // in different order).
  const touched = allKeys.filter((k) => currentCollection[k] !== previousCollection[k]);

  const added = currentKeys.filter((k) => !previousKeys.includes(k));
  const removed = previousKeys.filter((k) => !currentKeys.includes(k));
  const addedOrRemoved = added.concat(removed);
  const modified = touched.filter((k) => !addedOrRemoved.includes(k));

  const info = {};
  const values = {},
    previousValues = {};
  for (const path of touched) {
    values[path] = currentCollection[path];
    previousValues[path] = previousCollection[path];
    if (added.includes(path)) info[path] = "added";
    else if (removed.includes(path)) info[path] = "removed";
    else info[path] = "modified";
  }

  return { added, removed, modified, touched, info, values, previousValues };
};