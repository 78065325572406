import React from "react";
import TopToolbar from "components/editor/legal/toolbar/TopToolbar";
import ZoomComponent from "components/editor/legal/toolbar/ZoomComponent";
import { useSelector } from "react-redux";
import { useIsTemplateStudio } from "hooks";
import IntlMessages from "util/IntlMessages";

export default function TopbarContent() {
  const editor_allowEditing = useSelector(({ draft }) => draft.editor_allowEditing);
  const isTemplate = useIsTemplateStudio();

  if (editor_allowEditing || isTemplate)
    return (
        <TopToolbar isTemplate />
    );

  return (
    <>
      <div>
        <IntlMessages id="studio.contractPreview" />
      </div>
      <ZoomComponent />
    </>
  );
}
