import React, { useState, useCallback, useEffect } from 'react';
import { Button, Tooltip, Dropdown, Menu, Alert, Spin } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addRepeatable } from 'appRedux/actions';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { useContract } from 'hooks';
import { Contract } from 'core/interfaces';
import api from 'utils/api';

export default function (props) {
  const { cardName } = props;
  const dispatch = useDispatch();

  const contract = useContract();
  const concepts = Contract.getConcepts(contract);
  const partyConcept = concepts.find((concept) => concept.stateId === cardName && concept.contractParty);

  const addBlock = (data) => {
    dispatch(addRepeatable(props.path, data));
  };

  return (
    <>
      <Tooltip title={<IntlMessages id="studio.inputs.addParty" />}>
        <AddPartyButton addBlock={addBlock} cardName={cardName} concept={partyConcept} />
      </Tooltip>
    </>
  );
}

function AddPartyButton({ addBlock, cardName, concept }) {
  const [client, setClient] = useState(null);
  const { auth, legalPersons } = useSelector(({ auth, legalPersons }) => ({ auth, legalPersons }));
  const { user } = auth;
  useEffect(() => {
    api
      .get('/clients/' + user.clientId)
      .then((res) => {
        res && res.data && setClient(res.data);
      })
      .catch((err) => {
        console.log('could not find my client', err);
      });
  }, [user]);

  let menu = null;
  let menuItems = [];

  // FIX!
  if (false && client) {
    menuItems.push(
      <Menu.Item key="client">
        <span
          onClick={() => {
            addBlock({ 'party/name': client.name });
          }}
        >
          {client && client.name}
        </span>
      </Menu.Item>
    );
    menuItems.push(<Menu.Divider key="clientdivider" />);
  }

  if (legalPersons && legalPersons.length > 0) {
    const { id: ownerId } = legalPersons.find((legalPerson) => !legalPerson.parentId);
    const topLegalPerson = legalPersons.find((legalPerson) => legalPerson.parentId === ownerId);
    const legalPersonItems = [
      <Menu.Item key={'legalPerson' + topLegalPerson.id}>
        <span
          onClick={() => {
            addBlock({
              [cardName + 'Entity']: {
                type: 'legalPerson',
                id: topLegalPerson.id,
              },
            });
          }}
        >
          {topLegalPerson.name}
        </span>
      </Menu.Item>,
    ];
    if (legalPersons.length > 2) {
      const subsidiaries = [];
      for (const legalPerson of legalPersons) {
        if (legalPerson === topLegalPerson || legalPerson.id === ownerId) continue;
        subsidiaries.push(
          <Menu.Item key={'legalPerson' + legalPerson.id}>
            <span
              onClick={() => {
                addBlock({
                  [cardName + 'Entity']: {
                    type: 'legalPerson',
                    id: legalPerson.id,
                  },
                });
              }}
            >
              {legalPerson.name}
            </span>
          </Menu.Item>
        );
      }
      legalPersonItems.push(
        <Menu.SubMenu title="subsidiaries" key={'children'}>
          {subsidiaries}
        </Menu.SubMenu>
      );
    }

    menuItems = menuItems.concat(legalPersonItems);
  }

  if (menuItems.length > 0) menu = <Menu>{menuItems}</Menu>;

  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <Button shape="circle" icon={<UserAddOutlined />} size="small" style={{ fontSize: '14px' }} />
    </Dropdown>
  );
}
