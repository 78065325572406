
export const buildRepaymentClauseSFA = function (node, state) {
  if (!state.input || !state.input.facility) return;

  const termFacilities = this.api.utils.general.ofilter(state.input.facility, (facility) => facility.type === "term");
  const revolvingFacilities = this.api.utils.general.ofilter(state.input.facility, (facility) => facility.type === "rcf");

  const termLength = this.api.utils.general.ocount(termFacilities)
  const rcfLength = this.api.utils.general.ocount(revolvingFacilities)

  if (termLength === 0) {
    if(node.data.module.type === "inline") {
      return [this.makeTextNode("[**]")]
    }
    return [this.makeParagraph("[**]")];
  }

  const nums = {
    rcf: rcfLength,
    term: termLength,
    total: rcfLength + termLength,
  };

  if(node.data.module.type === "inline") {
    let repaymentText = this.buildRepaymentTextSFA(termFacilities[Object.keys(termFacilities)[0]], nums);
    const inlineText = this.describeConcept("the_termFacilityOrFacility").text + repaymentText
    return [this.makeTextNode(inlineText)]

  }

  let repaymentTexts = [];
  for (const [, facility] of Object.entries(termFacilities)) {
    var repaymentText = this.buildRepaymentTextSFA(facility, nums);
    if (nums.total > 1)
      repaymentText = facility.name + " " + repaymentText;

    if (!repaymentText || repaymentText === "") continue;

    repaymentTexts.push(repaymentText);
  }

  if (repaymentTexts.length > 1) {
    return [
      this.makeList(
        repaymentTexts.map((repayment) => this.makeListItemOneText(repayment)),
        { item_join: "and" }
      ),
    ];
  } else if (repaymentTexts.length === 1) {
    return [this.makeListItem(repaymentTexts[0])];
  }

  return [
    this.makeList(
      repaymentTexts.map((repayment) => this.makeListItemOneText(repayment)),
      { item_join: "and" }
    ),
  ];
};

export const buildRepaymentTextSFA = function (facility) {
  var returnText = "";
  var datetext = "[**]";
  var divider, mytenor, adjust;
  var deferredPaymentText;
  if (!facility) {
    this.log('Build repayment text got no facility? ', {facility})
    return "[**]";
  }
  const fullname = facility.name;
  if (!facility.repayment || facility.repayment === "" || facility.type !== "term") return false;

  switch (facility.deferredrepayment) {
    case "none":
      if (facility.compensaterepaymentdeferral) adjust = 0;
      deferredPaymentText = "";
      break;
    case "6m":
      if (facility.compensaterepaymentdeferral) adjust = 0.5;
      deferredPaymentText =
        ", " +
        this.translateText("providedThat") +
        " " +
        this.translateText("repayment") +
        " " +
        this.translateText("repaymentDeferral") +
        " " +
        this.num2strnum(6) +
        " " +
        this.translateText("months") +
        " " +
        this.translateText("from") +
        " " +
        this.translateText("dateOfThisAgreement");
      break;
    case "9m":
      if (facility.compensaterepaymentdeferral) adjust = 0.75;
      deferredPaymentText =
        ", " +
        this.translateText("providedThat") +
        " " +
        this.translateText("repayment") +
        " " +
        this.translateText("repaymentDeferral") +
        " " +
        this.num2strnum(9) +
        " " +
        this.translateText("months") +
        " " +
        this.translateText("from") +
        " " +
        this.translateText("dateOfThisAgreement");
      break;
    case "12m":
      if (facility.compensaterepaymentdeferral) adjust = 1;
      deferredPaymentText =
        ", " +
        this.translateText("providedThat") +
        " " +
        this.translateText("repayment") +
        " " +
        this.translateText("repaymentDeferral") +
        " " +
        this.num2strnum(12) +
        " " +
        this.translateText("months") +
        " " +
        this.translateText("from") +
        " " +
        this.translateText("dateOfThisAgreement");
      break;
    default:
      adjust = 0;
      deferredPaymentText = "";
  }
  switch (facility.repaymentinterval) {
    case "interestperiod":
    case "quarterly":
      divider = 4;
      datetext =
        this.translateText("quarterly") +
        " " +
        this.translateText("on") +
        " " +
        this.translateText("each") +
        " " +
        this.translateText("interestPaymentDate");
      break;
    case "monthly":
      divider = 12;
      datetext = this.translateText("monthly");
      break;
    case "semiannually":
      divider = 2;
      datetext = this.translateText("semiAnnually");
      break;
    case "annually":
      divider = 1;
      datetext = this.translateText("annually");
      break;
    default:
      divider = 1;
      datetext = "[**]";
  }

  if (facility.repayment === "bullet") {
    returnText +=
      " " +
      this.translateText("inItsEntirety") +
      " " +
      this.translateText("on") +
      " " +
      this.translateText("theTerminationDate") +
      (this.numFacilities > 1 ? " " + this.translateText("inRelationTo") + " " + fullname : "");
  } else if (facility.repayment === "linear") {
    if (facility.tenor === "other") {
      returnText += '[-- REPAYMENT TYPE "other" NOT YET IMPLEMENTED --]';
    } else if(facility.offer_multiple_tenors) {
      returnText +=
          " " +
          this.translateText("byLinearRepayment") +
          " " +
          datetext +
          " " +
          this.translateText("soItIsRepaid") +
          " " +
          this.translateText("inItsEntirety") +
          " " +
          this.translateText("on") +
          " " +
          this.translateText("theTerminationDate") +
          (this.numFacilities > 1
            ? " " + this.translateText("inRelationTo") + " " + fullname
            : "") +
          deferredPaymentText;
    } else {
      if (facility.tenor === "[**]") {
        returnText +=
          " " +
          this.translateText("byRepayingAnAmountOf") +
          " " +
          facility.currency +
          " [**] " +
          datetext +
          " (" +
          this.translateText("and") +
          " " +
          this.translateText("potentialResidualAmount") +
          " " +
          this.translateText("inItsEntirety") +
          " " +
          this.translateText("on") +
          " " +
          this.translateText("theTerminationDate") +
          (this.numFacilities > 1
            ? " " + this.translateText("inRelationTo") + " " + fullname
            : "") +
          ")" +
          deferredPaymentText;
      } else {
        if (facility.tenor === "date") {
          let tenorDate = new Date(facility.termdate);
          if (isNaN(tenorDate.valueOf())) {
            return "[enter repayment provision manually]";
          }
          let nowDate = new Date();
          var Difference_In_Time = tenorDate.getTime() - nowDate.getTime();

          // To calculate the no. of years between two dates
          mytenor = Difference_In_Time / (1000 * 3600 * 24 * 365.25);
          // this.log('mytenor', mytenor);
        } else {
          mytenor = parseFloat(facility.tenor);
          if (facility.tenorformat === "months") mytenor = parseFloat(mytenor / 12);
          if (adjust > 0) mytenor = parseFloat(mytenor - adjust);
        }
        let repaymentAmount;
        if (mytenor === 0) repaymentAmount = "[**]";
        else {
          if (isNaN(mytenor)) repaymentAmount = "[**]";
          else if (facility.commitment !== "" && facility.commitment !== 0) {
            repaymentAmount = this.splitNumber(
              Math.round(
                Math.ceil(
                  parseFloat(
                    parseInt(this.combineNumber(facility.commitment)) /
                      parseFloat(mytenor) /
                      divider
                  )
                ) / 1000
              ) * 1000
            );
          } else repaymentAmount = "[**]";
        }

        returnText +=
          " " +
          this.translateText("byRepayingAnAmountOf") +
          " " +
          facility.currency +
          " " +
          repaymentAmount +
          " " +
          datetext +
          " (" +
          this.translateText("and") +
          " " +
          this.translateText("potentialResidualAmount") +
          " " +
          this.translateText("inItsEntirety") +
          " " +
          this.translateText("on") +
          " " +
          this.translateText("theTerminationDate") +
          (this.numFacilities > 1
            ? " " + this.translateText("inRelationTo") + " " + fullname
            : "") +
          ")" +
          deferredPaymentText;
      }
    }

    return returnText;
  } else if (facility.repayment === "amount") {
    switch (facility.repaymentinterval) {
      case "interestperiod":
      case "querterly":
        datetext =
          this.translateText("quarterly") +
          " " +
          this.translateText("on") +
          " " +
          this.translateText("each") +
          " " +
          this.translateText("interestPaymentDate");
        break;
      case "monthly":
        datetext = this.translateText("monthly");
        break;
      case "semiannually":
        datetext = this.translateText("semiAnnually");
        break;
      case "annually":
        datetext = this.translateText("annually");
        break;
      default:
        datetext = "";
    }

    returnText +=
      " " +
      this.translateText("byRepayingAnAmountOf") +
      " " +
      facility.currency +
      " " +
      (facility.repaymentamount || "[**]") +
      " " +
      datetext +
      " (" +
      this.translateText("and") +
      " " +
      this.translateText("potentialResidualAmount") +
      " " +
      this.translateText("inItsEntirety") +
      " " +
      this.translateText("on") +
      " " +
      this.translateText("theTerminationDate") +
      (this.numFacilities > 1 ? " " + (this.translateText("inRelationTo") + " " + fullname) : "") +
      ")" +
      deferredPaymentText;
  }
  return returnText;
};
