import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { Input } from "antd";
import { useIntlMessage } from "util/IntlMessages";
import { SearchOutlined } from "@ant-design/icons";

function searchFilter(item, fields, searchValue, startsWith) {
    for (const field of fields) {
      if (!item[field]) continue;
      if (startsWith) {
        if (item[field].toLocaleLowerCase().indexOf(searchValue) === 0)
          return true;
      } else {
        if (item[field].toLocaleLowerCase().indexOf(searchValue) !== -1)
          return true;
      }
    }
  }
  
  /**
   * Use Seach
   * Option for returning either [ (updated collection matching search), (element with search bar) ]
   * OR [ (updated collection matching search), search, onSearch, resetSearch ]
   * @param {array} collection
   * @param {obj} opts
   */
  
  export function useSearch(collection, opts = {}) {
    const {
      element = false,
      mapper,
      fields = ["name"],
      startsWith = false
    } = opts;
    const searchRef = useRef(null)
    const [search, setSearch] = useState("");
    const onSearch = useCallback(evt => setSearch(evt.target.value), []);
    useEffect(() => {
      if (!searchRef.current) return
      searchRef.current.focus()
    }, [search])
    const resetSearch = useCallback(() => {
      console.log("reset");
      setSearch("");
    }, []);
    const searchValue = search.toLocaleLowerCase();
    const displayCollection = useMemo(() => {
      if (!collection) return;
      return (searchValue === ""
        ? collection
        : collection.filter(doc =>
            searchFilter(doc, fields, searchValue, startsWith)
          )
      ).map(item => (mapper ? mapper(item) : item));
    }, [collection, searchValue, startsWith, mapper, fields]);
  
    const formatMessage = useIntlMessage();

    if (!displayCollection) return [[], search, onSearch, resetSearch];
  
    if (!element) return [displayCollection, search, onSearch, resetSearch];
  
    const reactElement = (
      <div className="position-relative">
        <Input
          ref={searchRef}
          prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder={formatMessage('desc.Search')+"..."}
          value={searchValue}
          onChange={onSearch}
          allowClear
        />
        {/* searchValue !== "" && (
          <div
            className="clickable float-right position-absolute"
            style={{ right: "8px", top: "11px" }}
            onClick={resetSearch}
          >
            <i className="mdi mdi-close" />
          </div>
        ) */}
      </div>
    );
  
    return [displayCollection, reactElement];
  }