import { Editor, Transforms, Node, Range, Text, Path, Point } from 'slate';
import { ReactEditor } from 'slate-react';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

Text.stdEquals = Text.equals;
const NodeGetLeaf = Node.leaf;
const originalPointEquals = Point.equals;
const originalToDOMPoint = ReactEditor.toDOMPoint
const originalToDOMRange = ReactEditor.toDOMRange

export default function adjustInterfaces() {
  if (!Transforms.forceDeselect) Transforms.forceDeselect = Transforms.deselect;
  Transforms.deselect = () => {};
  Transforms.select = (editor, target) => {
    if (target === null || target.anchor === null || target.focus === null) return;
    const { selection } = editor;
    target = Editor.range(editor, target);
    if (selection) {
      Transforms.setSelection(editor, target);
      return;
    }

    if (!Range.isRange(target)) {
      throw new Error(
        `When setting the selection and the current selection is \`null\` you must provide at least an \`anchor\` and \`focus\`, but you passed: ${JSON.stringify(
          target
        )}`
      );
    }

    editor.apply({
      type: 'set_selection',
      properties: selection,
      newProperties: target,
    });
  };

  // Make setNodes accept a function as `props` argument.
  Transforms.setNodes = (editor, props, options = {}) => {
    Editor.withoutNormalizing(editor, () => {
      let { match, at = editor.selection } = options;
      const { hanging = false, mode = 'lowest', split = false, voids = false } = options;

      if (!at) {
        return;
      }

      if (match == null) {
        match = Path.isPath(at) ? matchPath(editor, at) : (n) => Editor.isBlock(editor, n);
      }

      if (!hanging && Range.isRange(at)) {
        at = Editor.unhangRange(editor, at);
      }

      if (split && Range.isRange(at)) {
        const rangeRef = Editor.rangeRef(editor, at, { affinity: 'inward' });
        const [start, end] = Range.edges(at);
        const splitMode = mode === 'lowest' ? 'lowest' : 'highest';
        Transforms.splitNodes(editor, {
          at: end,
          match,
          mode: splitMode,
          voids,
        });
        Transforms.splitNodes(editor, {
          at: start,
          match,
          mode: splitMode,
          voids,
        });
        at = rangeRef.unref();

        if (options.at == null) {
          Transforms.select(editor, at);
        }
      }

      for (const [node, path] of Editor.nodes(editor, {
        at,
        match,
        mode,
        voids,
      })) {
        const properties = {};
        const newProperties = {};

        // You can't set properties on the editor node.
        if (path.length === 0) {
          continue;
        }

        // Make `setNodes` alternatively accept a function as
        // second argument. Function receives node and path, and
        // returns an object with the updated node properties.
        let propsToSet;
        if (typeof props === 'function') {
          propsToSet = props(node, path);
          if (!propsToSet || typeof propsToSet !== 'object') continue;
        } else if (typeof props === 'object') {
          propsToSet = props;
        } else {
          throw new Error('Second argument to setNodes shall be function or object of properties');
        }

        for (const k in propsToSet) {
          if (k === 'children' || k === 'text') {
            continue;
          }

          if (propsToSet[k] !== node[k]) {
            properties[k] = node[k];
            newProperties[k] = propsToSet[k];
          }
        }

        if (Object.keys(newProperties).length !== 0) {
          editor.apply({
            type: 'set_node',
            path,
            properties,
            newProperties,
          });
        }
      }
    });
  };

  Node.leaf = (...args) => {
    try {
      const leaf = NodeGetLeaf(...args);
      return leaf;
    } catch (err) {
      console.log('Err getting node leaf', { err });
    }
  };

  Node.isTextInline = (editor, node) => editor.isInline(node) && node.children.every(Text.isText);

  Text.equals = (text, another, options = {}) => {
    const { loose = false } = options;

    return isEqual(loose ? omit(text, 'text') : text, loose ? omit(another, 'text') : another);
  };

  Point.equals = (point, another) => {
    if (!point || !another) {
      console.log('Invalid arguments to Points.equals');
      return false;
    }
    return point && another && originalPointEquals(point, another);
  };

  ReactEditor.toDOMPoint = (...args) => {
    try {
      const result = originalToDOMPoint(...args)
      return result
    } catch (err) {
      console.log('Cannot resolve DOM point')
      return null
    }
  }

  ReactEditor.toDOMRange = (...args) => {
    try {
      const result = originalToDOMRange(...args)
      return result
    } catch (err) {
      console.log('Cannot resolve DOM range')
      return null
    }
  }

}

// Helpers
const matchPath = (editor, path) => {
  const [node] = Editor.node(editor, path);
  return (n) => n === node;
};
