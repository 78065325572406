import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Badge, Spin, Dropdown, Menu } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { fixDate } from 'components/ui';
import api from 'utils/api';
import StatusBox from 'components/Metrics/StatusBox';
import IntlMessages from 'util/IntlMessages';
import {
  MainActionButton,
  GotoDraftSpan,
  GotoDocumentViewSpan,
  GotoVersionViewSpan,
} from 'components/DocumentsList/';

const { Column, ColumnGroup } = Table;

const config = {
  bordered: false,
  loading: false,
  pagination: false,
  size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  scroll: undefined,
};

function analyseProjects(projects, alternativeProjects, setProjectData, setDocumentData) {
  const months = [];
  let docs = [];
  if ((!projects || projects.length === 0) && alternativeProjects && alternativeProjects.length > 0)
    projects = alternativeProjects;
  for (const project of projects) {
    if (project.documents && project.documents.length > 0) docs = docs.concat(project.documents);

    const month = new Date(project.createdAt).getMonth();

    const monthName = new Date(project.createdAt).toLocaleString('default', {
      month: 'long',
    });
    if (months[month]) months[month].number++;
    else
      months[month] = {
        name: monthName,
        number: 1,
      };
  }
  const firstKey = Object.keys(months)[0];
  const lastKey = Object.keys(months).slice(-1)[0];
  const final = { ...months };
  if (firstKey) {
    for (let i = firstKey; i < lastKey; i++) {
      if (!final[i]) final[i] = 0;
    }
  }

  setProjectData(Object.values(final));
  analyseDocuments(docs, setDocumentData);
}
function analyseDocuments(documents, setDocumentData) {
  const months = {};
  for (const document of documents) {
    const month = new Date(document.createdAt).getMonth();
    const monthName = new Date(document.createdAt).toLocaleString('default', {
      month: 'long',
    });
    if (months[month]) months[month].number++;
    else
      months[month] = {
        name: monthName,
        number: 1,
      };
    /* if (months[month]) months[month]++;
      else months[month] = 1; */
  }
  const firstKey = Object.keys(months)[0];
  const lastKey = Object.keys(months).slice(-1)[0];
  const final = { ...months };
  if (firstKey) {
    for (let i = firstKey; i < lastKey; i++) {
      if (!final[i]) final[i] = 0;
    }
  }
  setDocumentData(Object.values(final));
}

export default function Main(props) {
  const [projects, setProjects] = useState(null);
  const [projectsError, setProjectsError] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [documentsError, setDocumentsError] = useState(null);
  const [clientUsers, setClientUsers] = useState(null);
  const [clientUsersError, setClientUsersError] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    // if (project) return; // Only fetch if we haven't received the project in location state
    api
      .get(
        '/projects?limit=5&order=desc&fields=id,name,description,info,isPersonal,isFolder,createdAt,updatedAt'
      )
      .then((result) => {
        result && result.data && setProjects(result.data.filter((project) => !project.isPersonal));
      })
      .catch((err) => setProjectsError(JSON.stringify(err.response)));

    api
      .get('/documents?limit=5&order=desc&fields=id,name,description,projectId,status,createdAt,updatedAt')
      .then((result) => result && result.data && setDocuments(result.data))
      .catch((err) => setDocumentsError(JSON.stringify(err.response)));

    api
      .get('/users')
      .then((result) => result && result.data && setClientUsers(result.data))
      .catch((err) => setClientUsersError(JSON.stringify(err.response)));
  }, []);

  /* useEffect(() => {
    if (!projects) return;
    api
      .get(
        "/projects/documents?fields=id,name,description,projectId,isPersonal,createdAt,updatedAt&order=ASC|updatedAt"
      )
      .then((res) => {
        analyseProjects(
          res.data.filter((project) => !project.isPersonal),
          projects,
          setProjectData,
          setDocumentData
        );
      })
      .catch((err) => {
        if (err.response) {
          console.log("err...", err.response);
        }
      });
  }, [projects]); */

  const projectProjects = Array.isArray(projects) && projects.filter((p) => !p.isFolder);
  const folderProjects = Array.isArray(projects) && projects.filter((p) => p.isFolder);

  return (
    <>
      <Row>
        <UserStats data={clientUsers} clientUsersError={clientUsersError} />
        <Col span={5}>
          <Card className={`card-full py-4 px-2`}>
            <div className="flex-row justify-content-center mb-3 mb-sm-4 clickable">
              <a href="/file_resources/Fastigheter.xlsx">
                <span
                  className={`size-80 border border-primary text-primary flex-row justify-content-center align-items-center rounded-circle position-relative`}
                >
                  <i className={`mdi mdi-home-modern fs-xlxl`} />
                  <i
                    className="mdi mdi-file-excel fs-xl position-absolute top-0"
                    style={{ right: '-20px' }}
                  />
                </span>
              </a>
            </div>
            <div className="text-center">
              <small className={`mb-0 mb-sm-3`}>
                <a href="/file_resources/Fastigheter.xlsx">
                  <IntlMessages id="app.main.downloadPropertyExcel" />
                </a>
              </small>
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        
        <DocumentStats data={documentData} />
        <ProjectStats data={projectData} />
      </Row> */}
      <Row>
        <Col s={24} lg={12}>
          <ListProjects projects={projectProjects} projectsError={projectsError} type="project" />
        </Col>
        <Col s={24} lg={12}>
          <ListProjects projects={folderProjects} projectsError={projectsError} type="folder" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ListDocuments documents={documents} documentsError={documentsError} />
        </Col>
      </Row>
    </>
  );
}

function UserStats({ data, clientUsersError }) {
  if (!data || clientUsersError) return null;
  const users = data && data.length;
  return (
    <Col xl={4} lg={8} md={8} sm={12} xs={24}>
      <StatusBox
        icon="user-o"
        title={users}
        colorTitle="primary"
        subTitle={<IntlMessages id="app.main.organisationUsers" />}
        subSubTitle={data.name}
        colorSubTitle="grey"
      />
    </Col>
  );
}

function ListProjects({ projects, projectsError, type = 'project' }) {
  const history = useHistory();

  const intTypeCap = type === 'folder' ? 'Folder' : 'Project';

  if (projectsError) {
    return (
      <Card title={<IntlMessages id={'app.main.latest' + intTypeCap + 's'} />}>
        <IntlMessages id="app.main.notRetrieveLatest" /> <IntlMessages id={'general.' + type + 's'} cap />
      </Card>
    );
  }

  return (
    <Card title={<IntlMessages id={'app.main.latest' + intTypeCap + 's'} />} className="no-body-padding">
      {!projects && <Spin className="loader-container" />}
      {projects && (
        <Table
          rowKey="id"
          className="table-responsive no-documents-table"
          locale={{
            emptyText: (
              <span>
                <IntlMessages id={'app.main.no' + intTypeCap + 'sCreated'} />.{' '}
                <NavLink to="/projects">
                  <IntlMessages id={'app.main.createA' + type} />
                </NavLink>
              </span>
            ),
          }}
          {...config}
          rowClassName="clickable"
          onRow={(r) => ({
            onClick: () => history.push('/project/' + r.id),
          })}
          dataSource={projects}
        >
          <ColumnGroup title={null}>
            <Column title={<IntlMessages id="app.general.Name" />} dataIndex="name" key="name" />
            <Column
              title={<IntlMessages id="app.general.Description" />}
              dataIndex="description"
              key="description"
            />
            <Column
              title={<IntlMessages id="app.general.LastModified" />}
              dataIndex="updatedAt"
              key="updatedAt"
              render={(text) => fixDate(text)}
            />
            {/* <Column
              title={<IntlMessages id="app.general.Action" />}
              key="actions"
              render={(text, record) => (
                <span className="margin-0">
                  <NavLink to={"/project/" + record.id}>
                    <IntlMessages id={"app.main.goTo"+intTypeCap} />
                  </NavLink>
                </span>
              )}
            /> */}
          </ColumnGroup>
        </Table>
      )}
    </Card>
  );
}

function ListDocuments({ documents, documentsError }) {
  if (documentsError) {
    return (
      <Card title={<IntlMessages id="app.main.latestDocuments" />}>
        <IntlMessages id="app.main.notRetrieveLatest" /> <IntlMessages id="app.documents" />
      </Card>
    );
  }

  return (
    <Card title={<IntlMessages id="app.main.latestDocuments" />} className="no-body-padding w-100">
      {!documents && <Spin className="loader-container" />}
      {documents && (
        <Table
          rowKey="id"
          locale={{
            emptyText: (
              <span>
                <IntlMessages id="app.main.noDocumentsCreated" />. <IntlMessages id="app.main.StartBy" />{' '}
                <NavLink to="/projects">
                  <IntlMessages id="app.main.creatingAproject" />
                </NavLink>
              </span>
            ),
          }}
          className="table-responsive no-documents-table"
          {...config}
          dataSource={documents}
        >
          <ColumnGroup title={null}>
            <Column title={<IntlMessages id="app.general.Name" />} dataIndex="name" key="name" />
            <Column
              title={<IntlMessages id="app.general.Description" />}
              dataIndex="description"
              key="description"
            />
            <Column
              title={<IntlMessages id="app.general.LastModified" />}
              dataIndex="updatedAt"
              key="updatedAt"
              render={(text) => fixDate(text)}
            />
            <Column
              title={<IntlMessages id="app.general.Status" />}
              dataIndex="status"
              key="status"
              render={(text) => (
                <Badge text={text.toLocaleUpperCase()} color={text === 'draft' ? 'orange' : 'green'} />
              )}
            />
            <Column
              title={<IntlMessages id="app.general.Action" />}
              key="actions"
              render={(text, record) => <MainActionButton document={record} />}
            />
            <Column
              title={<IntlMessages id="app.general.More" />}
              key="extraActions"
              render={(text, record) => (
                <Dropdown
                  overlay={
                    <Menu>
                      {record.status === 'draft' && (
                        <Menu.Item>
                          <GotoDocumentViewSpan document={record} />
                        </Menu.Item>
                      )}
                      {record.status === 'draft' && (
                        <Menu.Item>
                          <GotoVersionViewSpan
                            document={record}
                            title={
                              <>
                                <IntlMessages id="app.general.GoTo" /> <IntlMessages id="desc.latest" />{' '}
                                <IntlMessages id="app.resources.Version" />
                              </>
                            }
                          />
                        </Menu.Item>
                      )}
                      {record.status === 'published' && (
                        <Menu.Item>
                          <GotoDraftSpan
                            document={record}
                            title={
                              <>
                                <IntlMessages id="app.general.Edit" /> <IntlMessages id="desc.latest" />{' '}
                                <IntlMessages id="app.resources.Version" />
                              </>
                            }
                          />
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <span className="link ant-dropdown-link">
                    <i className="icon-btn icon icon-ellipse-v" />
                  </span>
                </Dropdown>
              )}
            />
          </ColumnGroup>
        </Table>
      )}
    </Card>
  );
}

/* 
import { Area, AreaChart, XAxis, ResponsiveContainer, Tooltip } from "recharts";
import ChartCard from "components/Metrics/ChartCard";

function ProjectStats({ data }) {
  if (!data) return null;

  return (
    <Col xl={6} lg={8} md={8} sm={8} xs={24}>
      <ChartCard
        title={<IntlMessages id="general.Projects" />}
        titleClassName="text-primary"
        noPercentage
        noUp
        spaceTop
        children={
          data.length > 0 ? (
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <Tooltip />
                <defs>
                  <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="5%" stopColor="#163469" stopOpacity={0.9} />
                    <stop offset="95%" stopColor="#FE9E15" stopOpacity={0.9} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <Area
                  name="Projects"
                  dataKey="number"
                  type="monotone"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#4D95F3"
                  fill="url(#color3)"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          ) : (
            <p className="p-4">
              <IntlMessages id="app.main.noProjectsCreated" />
            </p>
          )
        }
      />
    </Col>
  );
}
function DocumentStats({ data }) {
  if (!data) return null;

  return (
    <Col xl={6} lg={8} md={8} sm={8} xs={24}>
      <ChartCard
        title={<IntlMessages id="general.Documents" />}
        titleClassName="text-primary"
        noPercentage
        noUp
        spaceTop
        children={
          data.length > 0 ? (
            <ResponsiveContainer width="100%" height={75}>
              <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <Tooltip />
                <defs>
                  <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="5%" stopColor="#4ECDE4" stopOpacity={0.9} />
                    <stop offset="95%" stopColor="#06BB8A" stopOpacity={0.9} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <Area
                  name="Documents"
                  dataKey="number"
                  type="monotone"
                  strokeWidth={0}
                  stackId="2"
                  stroke="#4D95F3"
                  fill="url(#color4)"
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          ) : (
            <p className="p-4">
              <IntlMessages id="app.main.noDocumentsCreated" />
            </p>
          )
        }
      />
    </Col>
  );
}
 */
