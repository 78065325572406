import React, { useCallback } from "react";
import { Transforms } from "slate";
import { ReactEditor } from "slate-react";

const TYPES = [
  "paragraph",
  "clause",
  "numbered_list",
  "list_item"
]

export const ActiveClause = ({ element, editor }) => {
  const inactivateNode = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    try {
      const path = ReactEditor.findPath(editor, element);
      const data = { ...element.data, _inActive: true };
      Transforms.setNodes(editor, { data }, { at: path });
      console.log("activate for path ", path);
    } catch (err) {
      console.log("Err finding inactive node path");
    }
  }, [editor, element]);

  const isInActiveRepresentation = element._isInActiveRepresentation;
  if (isInActiveRepresentation || !TYPES.includes(element.type)) return null

  return (
      <div
        className="active-block-inactivate-btn"
        onMouseDown={inactivateNode}
        style={{ zIndex: "1000" }}
        contentEditable={false}
      >
        InActivate
      </div>
  );
};

