function advanceFindNodes(node, opts = {}, path = []) {
  if (!node) return [];
  let matches = { all: [] };
  for (const col in opts.collect) {
    matches[col] = [];
  }
  if (!Array.isArray(node)) {
    matches.all.push([node, path]);
    if (opts.collect) {
      for (const col in opts.collect) {
        if (opts.collect[col](node)) {
          matches[col].push([node, path]);
        }
      }
    }
  }
  const iteratable = Array.isArray(node) ? node : Array.isArray(node.children) ? node.children : [];
  if (iteratable) {
    for (let i = 0; i < iteratable.length; i++) {
      const nextPath = path.length === 0 ? path.concat(i) : path.concat('children').concat(i);
      const potential = advanceFindNodes(iteratable[i], opts, nextPath);
      for (const section in potential) {
        if (!matches[section]) matches[section] = [];
        matches[section] = matches[section].concat(potential[section]);
      }
    }
  }

  return matches;
}

function pathFinderNew(node, predicate, path = [], opts = {}, parent) {
  if (!node) return [];
  if (typeof predicate !== 'function') {
    console.warn('Provide predicate function to pathFinder');
    return [];
  }
  let matches = [];
  let match = [];
  if (typeof node !== 'object') return [];
  if (!Array.isArray(node) && path.length > 0) {
    // Don't match on the top level
    let passed = predicate(node);

    if (passed) {
      match = [node, path];
      if (opts.parent && parent) match.push(parent);
      matches.push(match);
    }
  }

  if (opts.routes) {
    opts.routes = opts.routes.filter((it) => !isNaN(it));
    let routeIndex = opts.routeIndex ? opts.routeIndex : 0;
    let pathPart = opts.routes[routeIndex];
    const iteratable = Array.isArray(node) ? node : Array.isArray(node.children) ? node.children : [];

    if (iteratable.length === 0) {
      return matches;
    }

    opts.routeIndex = routeIndex + 1;

    const nextPath = path.length === 0 ? path.concat(pathPart) : path.concat('children').concat(pathPart);

    const potential = pathFinderNew(iteratable[pathPart], predicate, nextPath, opts, [node, path]);
    if (potential.length > 0) matches = matches.concat(potential);
  } else {
    const iteratable = Array.isArray(node) ? node : Array.isArray(node.children) ? node.children : [];
    if (iteratable) {
      for (let i = 0; i < iteratable.length; i++) {
        const nextPath = path.length === 0 ? path.concat(i) : path.concat('children').concat(i);

        const potential = pathFinderNew(iteratable[i], predicate, nextPath, opts, [node, path]);
        if (potential.length > 0) matches = matches.concat(potential);
      }
    }
  }
  return matches;
}

function find(node, predicate, options = {}) {
  const result = [];
  const { startPath = [], tuple = false, onlyActive = false, mode = 'all' } = options;
  function finder(item, path = []) {
    if (onlyActive && item.data && item.data._inActive) return;
    if (predicate(item)) {
      if (tuple) {
        if (mode === 'one') return [item, path];
        result.push([item, path]);
      } else {
        if (mode === 'one') return item;
        result.push(item);
      }
    }
    if (item.children) {
      for (let i = 0; i < item.children.length; i++) {
        const foundItem = finder(item.children[i], [...path, i]);
        if (mode === 'one' && foundItem) return foundItem
      }
    }
  }

  if (Array.isArray(node)) {
    for (let i = 0; i < node.length; i++) {
      const foundItem = finder(node[i], [...startPath, i]);
      if (mode === 'one' && foundItem) return foundItem
    }
  } else if (typeof node === 'object') {
    const foundItem = finder(node, startPath);
    if (mode === 'one' && foundItem) return foundItem
  }
  return result;
}

export { find, pathFinderNew, advanceFindNodes };
