import { getNodeAllText, advanceFindNodes } from '../index';
import { format_number } from '../../../utils/general';
import { Path } from '../../../../import/slate';
import { Contract } from '../../../interfaces';

export function organiseBookmarks(content, opts = {}, contract) {
  // let { bookmarkEntries, clause = [], list = [], bookmarks = {}, match, refBindings, headings = {} } = iterationInfo

  let clause = [];
  let currentClausePath = null;
  let preceedingClauseBookmark = null;
  let preceedingHeaderBookmark = null;
  let schedule = [];
  let list = [];
  let refBindings = new Map();
  let refMap = {};
  let headings = {};
  let bookmarks = {};

  const doBookmarks = opts.bookmarks || opts.updateContent;
  const doRefs = opts.refs || opts.updateContent;

  const collect = {};
  if (doBookmarks) collect.bookmarks = (n) => n.data && n.data.bookmarks && n.data.bookmarks.length > 0;
  if (doRefs) collect.refs = (n) => n.variant === 'ref' && n.data && n.data.name;
  // if(opts.toc) collect.toc = (n) => n.data && n.data.fill && n.data.fill.type === 'TOC'

  const nodes = advanceFindNodes(content, {
    collect,
  });
  const bookmarkEntries = nodes.bookmarks;
  let tmpBookmarks;

  let lastInactivePath = null;
  // let lastPathLength = 0
  let pathLengthListLength = [];

  if (Array.isArray(nodes.all)) {
    for (const entry of nodes.all) {
      const [node, path] = entry;

      if (lastInactivePath && path.join('.').includes(lastInactivePath)) {
        continue;
      }
      if (node.data && node.data._inActive) {
        lastInactivePath = path.join('.');
        continue;
      } else lastInactivePath = null;

      let hIndex, sIndex;
      let thisPathLength = path.length;
      let thisListLength = list.length;

      if (node.type === 'clause' && Array.isArray(node.data && node.data.bookmarks))
        preceedingClauseBookmark = node.data.bookmarks[0];

      if (node.type === 'heading_one') hIndex = 0;
      if (node.type === 'heading_two') hIndex = 1;
      if (node.type === 'heading_three') hIndex = 2;
      if (hIndex !== undefined) {
        // console.log('clause before hindex ', clause, hIndex)
        if (clause[hIndex]) clause[hIndex]++;
        else clause.push(1);
        // Remove all coming after this index
        while (clause[hIndex + 1]) clause.splice(hIndex + 1, 1);
        // console.log('clause ', clause)
        // while(list[0]) list.splice(0,1)

        list = [];
        pathLengthListLength = [];

        currentClausePath = Path.parent(path.filter((x) => typeof x === 'number'));

        preceedingHeaderBookmark = preceedingClauseBookmark || (node.data && node.data.item_id) || null;
        preceedingClauseBookmark = null;
      }
      if (node.type === 'schedule_one') sIndex = 0;
      if (node.type === 'schedule_two') sIndex = 1;
      if (sIndex !== undefined) {
        if (schedule[sIndex]) schedule[sIndex]++;
        else schedule.push(1);
        // Remove all coming after this index
        while (schedule[sIndex + 1]) schedule.splice(sIndex + 1, 1);
        // console.log('schedule ', schedule)
        // while(list[0]) list.splice(0,1)
        list = [];
        pathLengthListLength = [];

        // Reset header & clause counters and data
        hIndex = undefined;
        clause = [];
      } /* else {
                schedule = []
            } */
      /* if(node.type === 'schedule_one' || node.type === 'schedule_two') {
                list = []
                pathLengthListLength = []
            } */
      if (node.type === 'clause' && node.data.bookmarks && node.data.bookmarks.length > 0) {
        tmpBookmarks = node.data.bookmarks;
      }
      if (node.type === 'numbered_list') {
        list.push(0);
      }
      if (node.type === 'list_item') {
        if (thisListLength > 0) {
          if (pathLengthListLength[thisPathLength] === thisListLength) {
            // We may just continue, list length is correct as per previous path
          } else if (pathLengthListLength[thisPathLength] < thisListLength) {
            // The list length is too long (a child of a previous/above num_list increased the list, so shorten it)
            list = list.slice(0, pathLengthListLength[thisPathLength]);
          } else if (pathLengthListLength[thisPathLength] > thisListLength) {
            // The list length is too short - can this ever happen?
            /* console.log(
              'List length too short ',
              pathLengthListLength[thisPathLength],
              thisListLength,
              JSON.stringify(list),
              node
            ); */
          }
        }
        list[list.length - 1]++;
        pathLengthListLength[thisPathLength] = list.length;
      }

      // lastPathLength = thisPathLength
      const shortPath = path.filter((x) => typeof x === 'number');

      if (doBookmarks && bookmarkEntries && Array.isArray(bookmarkEntries)) {
        const bookmarkNode = bookmarkEntries.find(([bookmarkNode]) => bookmarkNode === node);
        // if(bookmarkNode) console.log('Found ', bookmarkNode)

        if (node.type === 'list_item') {
          if (bookmarkNode) {
            for (const bookmark of node.data.bookmarks)
              bookmarks[bookmark] = {
                clause: JSON.parse(JSON.stringify(clause)),
                clausePath: currentClausePath,
                clause_bookmark_name: preceedingHeaderBookmark,
                schedule: JSON.parse(JSON.stringify(schedule)),
                list: JSON.parse(JSON.stringify(list)),
                type: node.type,
                path: shortPath,
                hIndex: hIndex && hIndex + 1,
                sIndex: sIndex && sIndex + 1,
              };
          }
        } else if (node.type === 'schedule_one' || node.type === 'schedule_two') {
          if (bookmarkNode) {
            for (const bookmark of node.data.bookmarks)
              bookmarks[bookmark] = {
                clause: JSON.parse(JSON.stringify(clause)),
                clausePath: currentClausePath,
                schedule: JSON.parse(JSON.stringify(schedule)),
                list: JSON.parse(JSON.stringify(list)),
                type: node.type,
                headingText: getNodeAllText(node),
                path: shortPath,
                hIndex: hIndex && hIndex + 1,
                sIndex: sIndex && sIndex + 1,
              };
          }
        } else if (hIndex !== undefined && tmpBookmarks) {
          for (const bookmark of tmpBookmarks)
            bookmarks[bookmark] = {
              clause: JSON.parse(JSON.stringify(clause)),
              clausePath: currentClausePath,
              schedule: JSON.parse(JSON.stringify(schedule)),
              list: JSON.parse(JSON.stringify(list)),
              type: 'clause',
              headingText: getNodeAllText(node),
              path: shortPath,
              hIndex: hIndex && hIndex + 1,
              sIndex: sIndex && sIndex + 1,
            };
          tmpBookmarks = null;
        }
      }

      if (opts.headings && hIndex !== undefined) {
        headings[node.data.item_id] = {
          clause: JSON.parse(JSON.stringify(clause)),
          clausePath: currentClausePath,
          schedule: JSON.parse(JSON.stringify(schedule)),
          list: JSON.parse(JSON.stringify(list)),
          type: node.type,
          headingText: getNodeAllText(node),
          path: shortPath,
          hIndex: hIndex && hIndex + 1,
          sIndex: sIndex && sIndex + 1,
        };
      }

      if (doRefs && node.variant === 'ref') {
        refBindings.set(node, {
          x_clause: JSON.parse(JSON.stringify(clause)),
          x_list: JSON.parse(JSON.stringify(list)),
        });
        if (node.data && node.data.item_id) {
          refMap[node.data.item_id] = {
            x_clause: JSON.parse(JSON.stringify(clause)),
            x_list: JSON.parse(JSON.stringify(list)),
            name: node.data.name,
            path: shortPath,
          };
        }
      }

      // preceedingHeaderBookmark = null;
    }
  } else {
    // console.log('No Nodes all in organise');
    return {};
  }
  const result = {};

  if (doBookmarks) result.bookmarks = bookmarks;
  if (doRefs) {
    result.refs = nodes.refs;
    result.refBindings = refBindings;
    result.refMap = updateRefMaps(refMap, bookmarks, contract);
  }
  if (opts.toc) result.toc = nodes.toc;
  if (opts.headings) result.headings = headings;
  return result;
}

function updateRefMaps(refMap, bookmarks, contract) {
  const format = Contract.getFormat(contract || {});
  for (const refId in refMap) {
    const ref = refMap[refId];
    const bookmark = bookmarks[ref.name];
    if (!bookmark) continue
    if (Path.isAncestor(bookmark.clausePath, ref.path)) {
      ref.local = true;
    } else {
      const bookmarkList = bookmark.list;
      if (contract && bookmarkList.length > 0 && format.listFormat) {
        const clauseText = bookmark.clause.join('.');
        const listDiff = remove_first_commons(bookmarkList, ref.x_list);
        if (listDiff.length === 0) {
          ref.textParts = [clauseText];
        } else {
          let listText = '';
          for (let i = 0; i < bookmarkList.length; i++)
            listText += format_number(bookmarkList[i], i, format.listFormat);
          ref.textParts = [clauseText, listText];
        }
      }
    }
  }

  return refMap;
}

// Remove all the common values until non-match is found
// [1,4,6,8] and [1,4,7,8] -> [6,8] from the first arr
function remove_first_commons(a1, a2) {
  for (let i = 0; i < a1.length; i++) if (a1[i] !== a2[i]) return a1.slice(i);
  return a1;
}
