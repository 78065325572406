import { Contract } from './Contract';
import { draftConfig } from '../config/draft';

const InputPaths = {};

/**
 * Construct a path. Accepts a base path as first argument,
 * and any number of additional path segments.
 *
 * construct("input.facility.uuid123", "name") into `input.facility.uuid123.name`
 * construct(null, "facility", 1, "name") into `input.facility.uuid123.name`
 *
 * @param {string} path
 * @param  {...any} rest
 */
InputPaths.construct = (...parts) => {
  parts = parts.filter((part) => !!part || (typeof part === 'number' && part !== -1));
  let path = InputPaths.pathAdd(...parts);
  if (path.substr(0, 6) !== 'input.') path = 'input.' + path;
  return path;
};

/**
 * Add path peices to eachother
 *
 * @param {string} path
 * @param {...any} parts
 */
InputPaths.pathAdd = (path, ...parts) => {
  if (!path) path = '';
  if (!path) return parts;
  if (!parts) return path;

  parts = parts.filter((part) => !!part || (typeof part === 'number' && part !== -1));
  for (const item of parts) {
    if (path !== '') path += '.';
    path += item;
  }
  return path;
};

/**
 * Extract the different state paths contained in a path
 *
 * Turn `input.facility.uuid123.name` into
 *
 * ["input.facility.uuid123", "input.facility.uuid123.name"]
 *
 * @param {string} path
 * @returns {array} [paths]
 */
InputPaths.extractPathParts = (contract, path) => {
  const parts = path.split('.');
  const paths = [];
  let newPath = parts[0];

  for (let i = 1; i < parts.length; i++) {
    const part = parts[i];
    newPath += '.' + part;
    if (Contract.getUiCard(contract, part)) {
      if (Contract.getUiIsCardRepeatable(contract, part) && parts[i + 1]) {
        paths.push(newPath + '.' + parts[i + 1]);
      } else paths.push(newPath);
      /* else if(parts[i+1]) {
        paths.push(newPath)
      } */
    }
  }
  return paths;
};

/**
 * Extract the different state paths referring to a repeatable
 *
 * Turn `input.facility.uuid123.name` into
 * ["input.facility.uuid123"]
 *
 * Or `input.facility.uuid123.multiple_tenors.uuid789.type` into
 * ["input.facility.uuid123", "input.facility.uuid123.multiple_tenors.uuid789"]
 *
 * @param {string} path
 * @returns {array} [paths]
 */
InputPaths.extractRepeatablePathParts = (contract, path) => {
  const parts = path.split('.');
  const paths = [];
  let newPath = '';
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    newPath += part;
    if (Contract.getUiIsCardRepeatable(contract, part) && parts[i + 1])
      paths.push(newPath + '.' + parts[i + 1]);
    newPath += '.';
  }
  return paths;
};

/**
 * Extract all repeatable card names from a path
 * @param {object} contract
 * @param {string} path
 */
InputPaths.extractRepeatables = (contract, path) => {
  const parts = path.split('.');
  const paths = [];
  for (const part of parts) {
    if (Contract.getUiIsCardRepeatable(contract, part)) paths.push(part);
  }
  return paths;
};

/**
 * Retrive the name of the card holding the fieldName.
 *
 * Turns `input.facility.uuid123.name` into "facility"
 * Turns `input.facility.uuid123.multiple_tenors.uuid789.type` into "multiple_tenors"
 *
 * @param {string} path
 * @returns {string} card name (or undefined if no card found)
 */
/*
"input.pricing.price"                              =>  pricing
"input.facility.uuid123"                           =>  facility
"input.facility.uuid123.type"                      =>  facility
"input.facility.uuid123.multiple_tenor"            =>  multiple_tenor
"input.facility.uuid123.multiple_tenor.uuid789"    =>  multiple_tenor
*/
InputPaths.getCardId = (contract, path) => {
  const parts = path.split('.');
  if (!parts[0] === 'input') return console.log('Not input');
  parts.shift(); // Remove first item (i.e. "input")

  if (InputPaths.isStorageCard(path)) return InputPaths.getSecondLast(path);

  for (const part of parts.reverse()) {
    if (Contract.getUiCard(contract, part)) return part;
  }
};

/**
 * Retrieve the index of the card holding the fieldName.
 *
 * Turns `input.facility.uuid123.name` into uuid123
 * Turns `input.facility.uuid123.multiple_tenors.uuid789.type` into uuid789
 *
 *
 * @param {string} path
 * @returns {number} card uid(or undefined if no card found)
 */
InputPaths.getCardUid = (contract, path) => {
  const parts = path.split('.');
  if (!parts[0] === 'input') return console.log('Not input');
  parts.shift();

  for (var i = parts.length - 1; i >= 0; i--) {
    const part = parts[i];
    if (Contract.getUiCard(contract, part) && Contract.getUiIsCardRepeatable(contract, part)) {
      if (parts[i + 1]) return parts[i + 1];
      return;
    }
  }
};

/**
 * Retrive the name of the input field
 *
 * Turns `input.facility.uuid123.name` into "name"
 * Turns `input.facility.uuid123.multiple_tenors.uuid789.type` into "type"
 *
 * @param {string} path
 * @returns {string} field name (or undefined if no fieldname found)
 */
InputPaths.getFieldName = (contract, path) => {
  const parts = path.split('.');
  if (!parts[0] === 'input') return console.log('Not input');
  parts.shift();

  if (InputPaths.isStorageCard(path)) return InputPaths.getLast(path);

  for (var i = parts.length - 1; i >= 0; i--) {
    const part = parts[i];
    if (Contract.getUiCard(contract, part)) {
      if (Contract.getUiIsCardRepeatable(contract, part)) {
        if (parts[i + 2]) return parts[i + 2];
      } else {
        if (parts[i + 1]) return parts[i + 1];
      }
      return;
    }
  }
};

InputPaths.isStorageCard = function (path) {
  // Check if the path belongs to a special state storage card.

  if (!Array.isArray(draftConfig.isStateStorageCard)) return false;

  for (const checkFn of draftConfig.isStateStorageCard) {
    if (checkFn(path)) return true;
  }
};

/**
 * Retrive both the card name and field name.
 *
 * @param {string} path
 * @returns {object} {cardId, fieldName}
 */
InputPaths.getCardAndField = (contract, path) => {
  return {
    cardId: InputPaths.getCardId(contract, path),
    fieldName: InputPaths.getFieldName(contract, path),
  };
};

InputPaths.getRepeatable = (contract, path) => {
  const parts = path.split('.');
  if (!parts[0] === 'input') return console.log('Not input');
  parts.shift();
  for (const part of parts.reverse()) {
    if (Contract.getUiCard(contract, part) && Contract.getUiIsCardRepeatable(contract, part)) return part;
  }
};

InputPaths.getRepeatablePath = (contract, path) => {
  const paths = InputPaths.extractRepeatablePathParts(contract, path);
  if (!paths || paths.length === 0) return;
  return paths.slice(-1)[0];
};

InputPaths.newGetRepeatable = (path) => {
  const parts = path.split('.');
  for (let i = parts.length - 1; i > 0; i--) {
    const part = parts[i];
    if (isPartRepeatable(part)) {
      return parts[i - 1];
    }
  }
};
InputPaths.newGetRepeatablePath = (path) => {
  const parts = path.split('.');
  for (const part of parts.slice().reverse()) {
    console.log('Check part... ', part);
    if (isPartRepeatable(part)) return parts.join('.');
    parts.pop();
  }
};

function isPartRepeatable(part) {
  if (typeof part !== 'string') return false;
  return part.startsWith('_/re/');
}

InputPaths.getLast = (path) => path.split('.').slice(-1)[0];
InputPaths.getSecondLast = (path) => path.split('.').slice(-2)[0];
InputPaths.removeLast = (path) => path.split('.').slice(0, -1).join('.');

/**
 *
 * Returns the parent path of a path.
 * Optionally skip more levels (skipLevels 2 gives grandparent etc.)
 *
 * @param {string} path
 * @param {number} skipLevels
 * @returns {string} path
 */
InputPaths.parentPath = (path, skipLevels = 1) => {
  if (!skipLevels) return path;
  return path.split('.').slice(0, -skipLevels).join('.');
};

/**
 * Replace the last point of a path with another.
 *
 * Turns `input.facility.uuid123.name`, `type` into `input.facility.uuid123.type`
 * @param {string} path
 * @param {string} end
 */
InputPaths.replaceLast = (path, end) => path.split('.').slice(0, -1).join('.') + '.' + end;

/**
 * Construct a trigger path for input modules, based on a path.
 *
 * Turns `input.facility.uuid123.name` into `facility/name`
 *
 * Turns `input.facility.uuid123` into `facility/*`
 *
 * @param {string} path
 * @returns {string} trigger path
 */
InputPaths.toTriggerPath = (contract, path) => {
  // Ordinary building of trigger path
  const { cardId, fieldName } = InputPaths.getCardAndField(contract, path);

  if (isNaN(fieldName)) {
    return cardId + '/' + fieldName;
  } else {
    return cardId + '/*';
  }
};

const INPUT_LOCATION_SEPARATOR = '->';
/**
 * Construct an `inputLocation`.
 *
 * Input location has the signature: cardId->fieldName
 * E.g: 'cars.amount'
 */
InputPaths.toInputLocation = (contract, path) => {
  // Ordinary building of trigger path
  const { cardId, fieldName } = InputPaths.getCardAndField(contract, path);

  if (fieldName && isNaN(fieldName)) {
    return cardId + INPUT_LOCATION_SEPARATOR + fieldName;
  } else {
    return cardId + INPUT_LOCATION_SEPARATOR + '*';
  }
};
InputPaths.fromInputLocation = (inputLocation) => {
  if (typeof inputLocation !== 'string') return {};
  const [cardId, fieldName] = inputLocation.split(INPUT_LOCATION_SEPARATOR);
  return { cardId, fieldName };
};
InputPaths.inputLocationToPath = (contract, inputLocation, siblingPath) => {
  const { cardId, fieldName } = InputPaths.fromInputLocation(inputLocation);
  if (!cardId || !fieldName) return null;

  const isRepeatable = Contract.getUiIsCardRepeatable(contract, cardId);
  if (isRepeatable) {
    if (!siblingPath) {
      console.log('inputLocationToPath: Expects argument siblingPath for repeatable.');
      return null;
    }
    const inputRepeatableCardId = InputPaths.getRepeatable(contract, siblingPath);
    if (inputRepeatableCardId !== cardId) {
      console.log('One repeatable referring to another without us knowing which instance.');
      return null;
    }
    const repeatablePath = InputPaths.getRepeatablePath(contract, siblingPath);
    return `${repeatablePath}.${fieldName}`;
  }

  return `input.${cardId}.${fieldName}`;
};

export { InputPaths };
