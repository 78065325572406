import React, { useState, useEffect } from 'react';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Button, Form, Input, Select, Checkbox } from 'antd';
import InputSelect from '../../routes/studio/containers/TemplateInputSections/Inputs/InputTypes/Select';
import InputCheckbox from '../../routes/studio/containers/TemplateInputSections/Inputs/InputTypes/Checkbox';
import InputTextbox from '../../routes/studio/containers/TemplateInputSections/Inputs/InputTypes/Text';
import InputNumeric from '../../routes/studio/containers/TemplateInputSections/Inputs/InputTypes/Numeric';
import { generateUID } from 'core/utils/general';
const { Option } = Select;

export default function AddEditInput({ selected, contract, onFinish, setupState, setSetupState }) {
  const formatMessage = useIntlMessage();

  const currentInput = selected.input ? setupState.find((si) => si.id === selected.input) : null;
  const currentInputId = currentInput && selected.input;
  const contractLanguauge = contract.data.settings.language;

  const [inputType, setInputType] = useState(currentInput ? currentInput.type : '-1'); // Ugly to use -1, what else? 'choose' ?
  const [inputId, setInputId] = useState(currentInput ? currentInputId : '');
  const [inputIdError, setInputIdError] = useState(null);
  const [inputLabel, setInputLabel] = useState(currentInput ? currentInput.label[contractLanguauge] : '');
  const [inputLabelError, setInputLabelError] = useState(null);
  const [childInvalidError, setChildInvalidError] = useState(null);
  const [childData, setChildData] = useState(null);
  const [useCustomId, setUseCustomId] = useState(false);

  const handleInputIdChange = (value) => {
    setInputId(value);
    if (setupState.find((s) => s.id === value)) {
      setInputIdError(formatMessage('studio.template.inputs.errors.idExists') + value);
    } else if (inputIdError) {
      setInputIdError('');
    }
  };

  const generateInputId = (label) => {
    while (true) {
      const id = `${label
        .replace(' ', '_')
        .replace(/[^a-zA-Z_]/g, '')
        .toLowerCase()
        .slice(0, 6)}_${generateUID(4)}`;
      if (!setupState.find((s) => s.id === id)) {
        return id;
      }
    }
  };

  const handleInputLabelChange = (evt) => {
    const { value } = evt.target;
    setInputLabel(value);
    if (!currentInput && !useCustomId) {
      handleInputIdChange(generateInputId(value));
    }
  };

  useEffect(() => {
    if (!currentInput && !useCustomId) {
      handleInputIdChange(generateInputId(inputLabel || 'input'));
    }
  }, []); // Medvetet utelämnat dependencies?

  const handleTypeChange = (value) => {
    setInputType(value);
    setChildInvalidError(null);
  };

  const onChildUpdate = (data) => {
    setChildData(data);
    setChildInvalidError(null);
  };

  // We go through the data from the child and validate it and set extra properties
  const getChildData = () => {
    if (['select', 'radio'].includes(inputType)) {
      if (!childData) return setChildInvalidError('Unexpected error');
      const { options, defaultValue } = childData;

      if (Object.keys(options).length === 0)
        return setChildInvalidError(formatMessage('studio.template.inputs.errors.selectOptionMissing'));

      const obj = {
        options: Object.entries(options).map(([id, { label, value }]) => {
          const tmp = {
            id,
            label: {
              [contractLanguauge]: label,
            },
          };
          if (value) {
            tmp.values = { [contractLanguauge]: value };
          }
          return tmp;
        }),
      };
      if (defaultValue) obj.value = defaultValue;

      return obj;
    } else if (['text', 'checkbox', 'numeric', 'numeric-steps'].includes(inputType)) {
      return {
        value: childData && childData.defaultValue,
      };
    }
    return {};
  };

  const addInput = () => {
    if (!inputId) return setInputIdError(formatMessage('studio.template.inputs.errors.idMissing'));
    if (!inputLabel) return setInputLabelError(formatMessage('studio.template.inputs.errors.labelMissing'));

    // Treat the data depending on the child
    const extraProps = getChildData();

    const name = inputId;

    // Two different width systems. Have to divide with 2 for the contract data.
    const newInput = {
      type: inputType,
      label: {
        [contractLanguauge]: inputLabel,
      },
      id: name,
      // required: ????
      ...extraProps,
    };

    let newSetup;
    if (currentInput) {
      newSetup = setupState.map((s) => {
        if (s.id === currentInputId) {
          return newInput;
        }
        return s;
      });
    } else {
      newSetup = [...setupState, newInput];
    }
    setSetupState(newSetup);

    onFinish();
  };

  const inputTypes = ['text', 'select', 'checkbox', 'radio', 'numeric', 'numeric-steps', 'date'];

  return (
    <div>
      <Form layout="vertical">
        <Form.Item
          label={<IntlMessages id="studio.template.inputs.label.label" />}
          extra={
            <div>
              <small>
                <IntlMessages id="studio.template.inputs.label.info" />
              </small>
              {inputLabelError && <div className="text-warning">{inputLabelError}</div>}
            </div>
          }
        >
          <Input
            type="text"
            value={inputLabel}
            onChange={handleInputLabelChange}
            placeholder={formatMessage('studio.template.inputs.label.placeholder')}
          />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="studio.template.inputs.type.label" />}
          extra={
            <div>
              <small>
                <IntlMessages id="studio.template.inputs.type.info" />
              </small>
            </div>
          }
        >
          <Select onChange={handleTypeChange} className="w-100" value={inputType}>
            <Option value="-1">{`-- ${formatMessage('studio.template.inputs.types.pickType')} --`}</Option>
            {inputTypes.map((inputType) => (
              <Option value={inputType}>
                <IntlMessages id={`studio.template.inputs.types.${inputType}`} />
              </Option>
            ))}
          </Select>
        </Form.Item>

        {inputType !== '-1' && (
          <>
            {['select', 'radio'].includes(inputType) && (
              <InputSelect
                onChildUpdate={onChildUpdate}
                currentInput={currentInput}
                contractLanguauge={contractLanguauge}
                propertyName="options"
              />
            )}
            {inputType === 'checkbox' && (
              <InputCheckbox onChildUpdate={onChildUpdate} currentInput={currentInput} />
            )}
            {['text'].includes(inputType) && (
              <InputTextbox onChildUpdate={onChildUpdate} currentInput={currentInput} />
            )}
            {['numeric', 'numeric-steps'].includes(inputType) && (
              <InputNumeric onChildUpdate={onChildUpdate} currentInput={currentInput} />
            )}
          </>
        )}

        <Input.Group compact>
          {!currentInput && (
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.customId.label" />}
              style={{ width: '40%' }}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.customId.info" />
                  </small>
                </div>
              }
            >
              <Checkbox checked={useCustomId} onChange={() => setUseCustomId(!useCustomId)} />
            </Form.Item>
          )}
          <Form.Item
            label={<IntlMessages id="studio.template.inputs.id.label" />}
            style={{ width: '60%' }}
            className="ml-2"
            extra={
              <div>
                <small>
                  <IntlMessages id="studio.template.inputs.id.info" />
                </small>
                {inputIdError && <div className="text-warning">{inputIdError}</div>}
              </div>
            }
          >
            <Input
              type="text"
              value={inputId}
              onChange={(evt) => handleInputIdChange(evt.target.value)}
              disabled={!!currentInput || !useCustomId}
              placeholder={formatMessage('studio.template.inputs.id.placeholder')}
            />
          </Form.Item>
        </Input.Group>
      </Form>

      <Button
        type="primary"
        block
        onClick={addInput}
        disabled={
          inputType === '-1' || inputIdError || childInvalidError || inputId === '' || inputLabel === ''
        }
      >
        {currentInput
          ? formatMessage('studio.template.inputs.updateButton')
          : formatMessage('studio.template.inputs.addButton')}
      </Button>
      {childInvalidError && <div className="text-warning">{childInvalidError}</div>}
    </div>
  );
}
