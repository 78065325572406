export default function AfterResponse() {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      {/* <div>Invalid location.</div> */}
      <div
        className="text-muted clickable liftable p-3"
        style={{ position: 'absolute', marginTop: '70px', borderRadius: '7px' }}
      >
        © Maintract 2021.
      </div>
    </div>
  );
}
