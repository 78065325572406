import React, { useState } from 'react';
import { useContract, setContract } from 'hooks';
// import IntlMessages from 'util/IntlMessages';
import { Contract, Create } from 'core/interfaces';
import CustomScrollbars from 'util/CustomScrollbars';
import { getByPath, array_move } from 'core/utils/general';
import { Alert, Button } from 'antd';

export default function ArrangePages() {
  const contract = useContract();
  const language = Contract.getLanguage(contract);
  const ui = Contract.getUi(contract);
  const { routes } = ui;

  return (
    <div className="h-100">
      <CustomScrollbars autoHeight autoHeightMin={600}>
        <div className="h-100">
          <ListPages
            contract={contract}
            ui={ui}
            routes={routes}
            language={language}
            routePath={'data.ui.routes'}
            pagePath={'data.ui'}
          />
        </div>
      </CustomScrollbars>
    </div>
  );
}

function ListPages({ contract, ui, routes, language, routePath, pagePath, level = 0 }) {
  return (
    <div className={level !== 0 ? 'ml-3' : ''}>
      {routes.map((route, index) => {
        return (
          <RouteEntry
            key={'route_' + route.fullPath}
            contract={contract}
            ui={ui}
            route={route}
            language={language}
            routePath={routePath}
            pagePath={pagePath}
            index={index}
            level={level}
          />
        );
      })}
    </div>
  );
}

function RouteEntry({ contract, ui, route, language, routePath, pagePath, index, level }) {
  routePath += level > 0 ? '.children.' + index : '.' + index;
  pagePath += '.pages.' + route.id;

  const page = Create.getPage(ui, route);
  if (!page) {
    console.log('Could not find page for route ', { route });
    return null;
  }

  const moveUp = () => {
    if (index === 0) return;
    const parts = routePath.split('.');
    const parentPath = parts.slice(0, -1).join('.');
    const parent = getByPath(contract, parentPath);
    array_move(parent, index, index - 1);
    setContract({ ...contract });
  };
  const moveDown = () => {
    const parts = routePath.split('.');
    const parentPath = parts.slice(0, -1).join('.');
    const parent = getByPath(contract, parentPath);
    if (index + 1 === parent.length) {
      return console.log('Not last');
    }
    array_move(parent, index, index + 1);
    setContract({ ...contract });
  };

  return (
    <>
      <Alert
        message={
          <div className="flex-row" style={{ justifyContent: 'space-between' }}>
            <div>{page.header[language]}</div>
            <div className="mr-1">
              <Button type="link" className="m-0" size="small" onClick={moveUp}>
                <i className="mdi mdi-arrow-up" />
              </Button>
              <Button type="link" className="m-0" size="small" onClick={moveDown}>
                <i className="mdi mdi-arrow-down" />
              </Button>
            </div>
          </div>
        }
        type="info"
      />
      {route.children && (
        <ListPages
          key={'children_' + route.fullPath}
          contract={contract}
          ui={ui}
          routes={route.children}
          language={language}
          routePath={routePath}
          pagePath={pagePath}
          level={level + 1}
        />
      )}
    </>
  );
}
