
// Grammar genders:
/*
m: Maskulinum, ein Tisch – der Tisch, tyska
f: Femininum, una mesa – la mesa, spanska
r: Reale, realgenus, utrum, en bok – boken, en stol – stolen, svenska
n: Neutrum, ett bord – bordet, svenska
*/
// For use by UI builders

export const availableGenders = {
  en: ["r", "n"], // Not really applicable, but for determining "a"/"an". r = a, n = an
  sv: ["r", "n"],
  de: ["m", "f", "n"],
};

export const standardConceptGrammar = {
  borrower: {
    values: {
      sv: {
        base: "Låntagar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Borrower",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  originalBorrower: {
    stateId: 'borrower',
    values: {
      sv: {
        base: "Ursprunglig",
        gender: "r",
        singular: {
          suffix: "Låntagare",
          suffixSpace: true,
        },
        singularFixed: {
          prefix: "den",
          prefixSpace: true,
          suffix: "a Låntagaren",
        },
        plural: {
          suffix: "a Låntagare",
          suffixSpace: false
        },
        pluralFixed: {
          prefix: "de",
          prefixSpace: true,
          suffix: "a Låntagarna",
          suffixSpace: false
        },
      },
      en: {
        base: "Original Borrower",
        gender: "n",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  guarantor: {
    values: {
      sv: {
        base: "Borgens",
        gender: "r",
        singular: {
          suffix: "man",
        },
        singularFixed: {
          suffix: "mannen",
        },
        plural: {
          suffix: "män",
        },
        pluralFixed: {
          suffix: "männen",
        },
      },
      en: {
        base: "Guarantor",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  originalGuarantor: {
    stateId: 'guarantor',
    values: {
      sv: {
        base: "Ursprunglig",
        gender: "r",
        singular: {
          suffix: "Borgensman",
          suffixSpace: true,
        },
        singularFixed: {
          prefix: "den",
          prefixSpace: true,
          suffix: "a Borgensmannen",
        },
        plural: {
          suffix: "a Borgensmän",
          suffixSpace: false
        },
        pluralFixed: {
          prefix: "de",
          prefixSpace: true,
          suffix: "a Borgensmännen",
          suffixSpace: false
        },
      },
      en: {
        base: "Original Guarantor",
        gender: "n",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  purchaser: {
    values: {
      sv: {
        base: "Köpar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Purchaser",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  seller: {
    values: {
      sv: {
        base: "Säljar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Seller",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  buyer: {
    values: {
      sv: {
        base: "Köpar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Buyer",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  customer: {
    values: {
      sv: {
        base: "Kund",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "er",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Borrower",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  grantor: {
    values: {
      sv: {
        base: "Givar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Grantor",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  grantee: {
    values: {
      sv: {
        base: "Tagar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Grantee",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  lessor: {
    values: {
      sv: {
        base: "Licensgivar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Lessor",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  lessee: {
    values: {
      sv: {
        base: "Licenstagar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Lessee",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  owner: {
    values: {
      sv: {
        base: "Ägar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Owner",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  property: {
    values: {
      sv: {
        base: "Fastighet",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "er",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Propert",
        gender: "r",
        singular: {
          suffix: "y",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "y",
        },
        plural: {
          suffix: "ies",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "ies",
        },
      },
    },
  },
  propCo: {
    values: {
      sv: {
        base: "Relevant",
        gender: "n",
        singular: {
          suffix: "Koncernbolag",
          suffixSpace: true
        },
        singularFixed: {
          prefix: "det",
          prefixSpace: true,
          suffix: "a Koncernbolaget",
        },
        plural: {
          suffix: "Koncernbolag",
          suffixSpace: true
        },
        pluralFixed: {
          prefix: "de",
          prefixSpace: true,
          suffix: "a Koncernbolagen",
        },
      },
      en: {
        base: "Propco",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  propCoOrig: {
    values: {
      sv: {
        base: "Fastighetsbolag",
        gender: "n",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "et",
        },
        plural: {
          suffix: "",
        },
        pluralFixed: {
          suffix: "en",
        },
      },
      en: {
        base: "Propco",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  party: {
    values: {
      sv: {
        base: "Part",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "er",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Part",
        gender: "r",
        singular: {
          suffix: "y",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "y",
        },
        plural: {
          suffix: "ies",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "ies",
        },
      },
    },
  },
  lender: {
    values: {
      sv: {
        base: "Långivar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Lender",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  obligor: {
    values: {
      sv: {
        base: "Gäldenär",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "er",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Obligor",
        gender: "n",
        singular: {
          onePrefix: "an",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  originalObligor: {
    values: {
      sv: {
        base: "Ursprunglig",
        gender: "r",
        singular: {
          suffix: "Gäldenär",
          suffixSpace: true,
        },
        singularFixed: {
          suffix: "a Gäldenären",
        },
        plural: {
          suffix: "a Gäldenärer",
          suffixSpace: false
        },
        pluralFixed: {
          suffix: "a Gäldenärerna",
          suffixSpace: false
        },
      },
      en: {
        base: "Original Obligor",
        gender: "n",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  obligorOrBorrower: {
    transforms: [
      {
        // Only relevant if there's a borrower and a guarantor
        rule: {
          and: [
            {
              ">": [
                {
                  numof: {
                    card: 'borrower',
                  },
                },
                0,
              ],
            },
            {
              ">": [
                {
                  numof: {
                    card: 'guarantor',
                  },
                },
                0,
              ],
            },
          ],
        },
        concept: "obligor",
      },
      {
        // default
        concept: "borrower",
      },
    ],
  },
  obligorOrGuarantor: {
    transforms: [
      {
        // Only relevant if there's a borrower and a guarantor
        rule: {
          and: [
            {
              ">": [
                {
                  numof: {
                    card: 'borrower',
                  },
                },
                0,
              ],
            },
            {
              ">": [
                {
                  numof: {
                    card: 'guarantor',
                  },
                },
                0,
              ],
            },
          ],
        },
        concept: "obligor",
      },
      {
        // default
        concept: "guarantor",
      },
    ],
  },
  investor: {
    values: {
      sv: {
        base: "Investerar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Investor",
        gender: "r",
        singular: {
          onePrefix: "an ",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  shareholder: {
    values: {
      sv: {
        base: "Aktieägar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Shareholder",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  intragrouploan: {
    values: {
      sv: {
        base: "Internlån",
        gender: "n",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "et",
        },
        plural: {
          suffix: "",
        },
        pluralFixed: {
          suffix: "en",
        },
      },
      en: {
        base: "Intra-Group Loan",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  intraGroupLoanCreditor: {
    values: {
      sv: {
        base: "Koncernborgenär",
        gender: "n",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "er",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Intra-Group Loan Creditor",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  intraGroupLoanDebtor: {
    values: {
      sv: {
        base: "Koncerngäldenär",
        gender: "n",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "er",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Intra-Group Loan Debtor",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  promissorynotes: {
    values: {
      sv: {
        base: "Internrevers",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "er",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Promissory Note",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  floatingChargePledgor: {
    values: {
      sv: {
        base: "Företagsinteckningspantsättar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Floating Charge Pledgor",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },

  sharesecurity: {
    values: {
      sv: {
        base: "Aktiepant",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Share Pledge",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  shareSecurityPledgor: {
    values: {
      sv: {
        base: "Aktiepantsättar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Share Pledge Pledgor",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  shareSecurityPledgedCompany: {
    values: {
      sv: {
        base: "Pantsatt",
        gender: "n",
        singular: {
          suffixSpace: true,
          suffix: "Aktiebolag",
        },
        singularFixed: {
          prefix: "det",
          prefixSpace: true,
          suffix: "a Aktiebolaget",
        },
        plural: {
          suffixSpace: true,
          suffix: "Aktiebolag",
        },
        pluralFixed: {
          prefix: 'de',
          prefixSpace: true,
          suffix: "a Aktiebolagen",
        },
      },
      en: {
        base: "Share Pledged Compan",
        gender: "r",
        singular: {
          suffix: "y",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "y",
        },
        plural: {
          suffix: "ies",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "ies",
        },
      },
    },
  },
  loan: {
    values: {
      sv: {
        base: "Lån",
        gender: "n",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "et",
        },
        plural: {
          suffix: "",
        },
        pluralFixed: {
          suffix: "en",
        },
      },
      en: {
        base: "Loan",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  pledgor: {
    values: {
      sv: {
        base: "Pantsättar",
        gender: "r",
        singular: {
          suffix: "e",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "e",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Pledgor",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  facility: {
    values: {
      sv: {
        base: "Facilitet",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "er",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Facilit",
        gender: "r",
        singular: {
          suffix: "y",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "y",
        },
        plural: {
          suffix: "ies",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "ies",
        },
      },
    },
  },

  termFacility: {
    countRule: {
      numof: {
        card: 'facility',
        condition: { and: [{ '==': [{ var: 'facility/type' }, 'term'] }] },
      },
    },
    stateId: "facility",
    values: {
      sv: {
        base: "Periodfacilitet",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "en",
        },
        plural: {
          suffix: "er",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Term Facilit",
        gender: "r",
        singular: {
          suffix: "y",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "y",
        },
        plural: {
          suffix: "ies",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "ies",
        },
      },
    },
  },
  revolvingFacility: {
    countRule: {
      numof: {
        card: 'facility',
        condition: { and: [{ '==': [{ var: 'facility/type' }, 'rcf'] }] },
      },
    },
    stateId: "facility",
    values: {
      sv: {
        base: "Revolverande Facilitet",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          prefix: "den",
          prefixSpace: true,
          suffix: "en",
        },
        plural: {
          suffix: "er",
        },
        pluralFixed: {
          suffix: "erna",
        },
      },
      en: {
        base: "Revolving Facilit",
        gender: "r",
        singular: {
          suffix: "y",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "y",
        },
        plural: {
          suffix: "ies",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "ies",
        },
      },
    },
  },

  termLoan: {
    values: {
      sv: {
        base: "Periodlån",
        gender: "n",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "et",
        },
        plural: {
          suffix: "",
        },
        pluralFixed: {
          suffix: "en",
        },
      },
      en: {
        base: "Term Loan",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  revolvingLoan: {
    values: {
      sv: {
        base: "Revolverande Lån",
        gender: "n",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "et",
        },
        plural: {
          suffix: "",
        },
        pluralFixed: {
          suffix: "en",
        },
      },
      en: {
        base: "Revolving Loan",
        gender: "r",
        singular: {},
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },

  termLoanOrLoan: {
    transforms: [
      {
        // Only relevant if there's also an RCF
        rule: {
          and: [
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'term'] }] },
                  },
                },
                0,
              ],
            },
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'rcf'] }] },
                  },
                },
                0,
              ],
            },
          ],
        },
        concept: "termLoan",
      },
      {
        // default
        concept: "loan",
      },
    ],
  },
  revolvingLoanOrLoan: {
    transforms: [
      {
        // Only relevant if there's also an RCF
        rule: {
          and: [
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'term'] }] },
                  },
                },
                0,
              ],
            },
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'rcf'] }] },
                  },
                },
                0,
              ],
            },
          ],
        },
        concept: "revolvingLoan",
      },
      {
        // default
        concept: "loan",
      },
    ],
  },

  revolvingFacilityOrFacility: {
    transforms: [
      {
        // Only relevant if there's also a term facility
        rule: {
          and: [
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'rcf'] }] },
                  },
                },
                0,
              ],
            },
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'term'] }] },
                  },
                },
                0,
              ],
            },
          ],
        },
        concept: "revolvingFacility",
      },
      {
        // default
        concept: "facility",
      },
    ],
  },
  termFacilityOrFacility: {
    transforms: [
      {
        // Only relevant if there's also a term facility
        rule: {
          and: [
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'rcf'] }] },
                  },
                },
                0,
              ],
            },
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'term'] }] },
                  },
                },
                0,
              ],
            },
          ],
        },
        concept: "termFacility",
      },
      {
        // default
        concept: "facility",
      },
    ],
  },

  commitment: {
    stateId: "facility",
    values: {
      sv: {
        base: "Åtagande",
        gender: "n",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "t",
        },
        plural: {
          suffix: "n",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Commitment",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  termCommitment: {
    countRule: {
      numof: {
        card: 'facility',
        condition: { and: [{ '==': [{ var: 'facility/type' }, 'term'] }] },
      },
    },
    stateId: "facility",
    values: {
      sv: {
        base: "Periodåtagande",
        gender: "n",
        singular: {
          suffix: "",
        },
        singularFixed: {
          suffix: "t",
        },
        plural: {
          suffix: "en",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Term Commitment",
        gender: "r",
        singular: {
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  revolvingCommitment: {
    countRule: {
      numof: {
        card: 'facility',
        condition: { and: [{ '==': [{ var: 'facility/type' }, 'rcf'] }] },
      },
    },
    stateId: "facility",
    values: {
      sv: {
        base: "Revolverande Åtagande",
        gender: "n",
        singular: {
          suffix: "",
        },
        singularFixed: {
          prefix: "det",
          suffix: "t",
        },
        plural: {
          suffix: "en",
        },
        pluralFixed: {
          suffix: "na",
        },
      },
      en: {
        base: "Revolving Commitment",
        gender: "r",
        singular: {
          suffix: "",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
        },
        plural: {
          suffix: "s",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "s",
        },
      },
    },
  },
  termCommitmentOrCommitment: {
    transforms: [
      {
        // Only relevant if there's also an RCF
        rule: {
          and: [
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'term'] }] },
                  },
                },
                0,
              ],
            },
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'rcf'] }] },
                  },
                },
                0,
              ],
            },
          ],
        },
        concept: "termCommitment",
      },
      {
        // default
        concept: "commitment",
      },
    ],
  },
  rcfCommitmentOrCommitment: {
    transforms: [
      {
        // Only relevant if there's also a term facility
        rule: {
          and: [
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'rcf'] }] },
                  },
                },
                0,
              ],
            },
            {
              ">": [
                {
                  numof: {
                    card: 'facility',
                    condition: { and: [{ '==': [{ var: 'facility/type' }, 'term'] }] },
                  },
                },
                0,
              ],
            },
          ],
        },
        concept: "rcfCommitment",
      },
      {
        // default
        concept: "commitment",
      },
    ],
  },
};

export const standardConceptVariations = {
  a: {
    label: "A",
    key: "a",
    description: "Refer to an item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ n: "ett", r: "en" }, true]],
          prefixSpace: true,
          suffixes: [],
          targetMode: "singular",
        },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ n: "an", r: "a" }, true]],
          prefixSpace: true,
          suffixes: [],
          targetMode: "singular",
        },
      },
    },
  },
  the: {
    label: "The",
    key: "the",
    description: "Refer to a specific item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [], suffixes: [], targetMode: "pluralFixed" },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [], prefixSpace: true, suffixes: [], targetMode: "pluralFixed" },
      },
    },
  },
  "the-one": {
    label: "The one",
    key: "the-one",
    description: "Refer to a specific item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ n: "det", r: "den" }, true]],
          suffixes: [],
          targetMode: "singularFixed",
        },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
      },
    },
  },
  all: {
    label: "All",
    key: "all",
    description: "Refer to all items (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ n: "samtliga", r: "samtliga" }, true]],
          suffixes: [],
          targetMode: "plural",
        },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "all" }, true]], suffixes: [], targetMode: "singular" },
      },
    },
  },
  any: {
    label: "Any",
    key: "any",
    description: "Refer to any item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ n: "något", r: "någon" }, true]],
          suffixes: [],
          targetMode: "singular",
        },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "any" }, true]], suffixes: [], targetMode: "singular" },
      },
    },
  },
  such: {
    label: "Such",
    key: "such",
    description: "Refer to such item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ n: "sådant", r: "sådan" }, true]],
          suffixes: [],
          targetMode: "singular",
        },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "such" }, true]], suffixes: [], targetMode: "singular" },
      },
    },
  },
  each: {
    label: "Each",
    key: "each",
    description: "Refer to each item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "varje" }, true]], suffixes: [], targetMode: "singular" },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "each" }, true]], suffixes: [], targetMode: "singular" },
      },
    },
  },
  "each-other": {
    label: "Each other",
    key: "each-other",
    description: "Refer to each item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "varje annan" }, true]], suffixes: [], targetMode: "singular" },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "each other" }, true]], suffixes: [], targetMode: "singular" },
      },
    },
  },
  that: {
    label: "That",
    key: "that",
    description: "Refer to that item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ n: "det", r: "den" }, true]],
          suffixes: [],
          targetMode: "singular",
        },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "that" }, true]], suffixes: [], targetMode: "singular" },
      },
    },
  },
  relevant: {
    label: "Relevant",
    key: "relevant",
    description: "Refer to the relevant item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "relevant" }, true]], suffixes: [], targetMode: "singular" },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "relevant" }, true]], suffixes: [], targetMode: "singular" },
      },
    },
  },
  "the-relevant": {
    label: "The Relevant",
    key: "the-relevant",
    description: "Refer to the relevant item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [
            [{ n: "det", r: "den" }, true],
            [{ n: "relevanta", r: "relevanta" }, true],
          ],
          suffixes: [],
          targetMode: "singularFixed",
        },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ text: "the relevant" }, true]],
          suffixes: [],
          targetMode: "singular",
        },
      },
    },
  },
  respective: {
    label: "Respective",
    key: "respective",
    description: "Refer to the respective item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ text: "respektive" }, true]],
          suffixes: [],
          targetMode: "singular",
        },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ text: "respective" }, true]],
          suffixes: [],
          targetMode: "singular",
        },
      },
    },
  },
  "the-respective": {
    label: "The respective",
    key: "the-respective",
    description: "Refer to the respective item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [
            [{ n: "det", r: "den" }, true],
            [{ text: "respektive" }, true],
          ],
          suffixes: [],
          targetMode: "singularFixed",
        },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [
            [{ text: "the" }, true],
            [{ text: "respective" }, true],
          ],
          suffixes: [],
          targetMode: "singular",
        },
      },
    },
  },
  certain: {
    label: "Certain",
    key: "certain",
    description: "Refer to certain items (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: {
          prefixes: [[{ text: "vissa" }, true]],
          suffixes: [],
          targetMode: "plural",
        },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "certain" }, true]], suffixes: [], targetMode: "plural" },
      },
    },
  },
  no: {
    label: "No",
    key: "no",
    description: "Refer to no item (if several exists) or the only one",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ n: "inget", r: "ingen" }, true]], suffixes: [], targetMode: "singular" },
      },
      en: {
        singular: { prefixes: [], suffixes: [], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "no" }, true]], suffixes: [], targetMode: "singular" },
      },
    },
  },
  shallNot: {
    label: "No X shall",
    key: "shallNot",
    description: "Refer to that no item shall (if several exists) or the only one shall not",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [[{ text: "ska inte" }, true]], targetMode: "singularFixed" },
        plural: { prefixes: [[{ n: "inget", r: "ingen" }, true]], suffixes: [[{ text: "ska" }, true]], targetMode: "singular" },
      },
      en: {
        singular: { prefixes: [], suffixes: [[{ text: "shall not" }, true]], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "no" }, true]], suffixes: [[{ text: "shall" }, true]], targetMode: "singular" },
      },
    },
  },
  mayNot: {
    label: "No X may",
    key: "mayNot",
    description: "Refer to that no item may (if several exists) or the only one may not",
    rules: {
      sv: {
        singular: { prefixes: [], suffixes: [[{ text: "får inte" }, true]], targetMode: "singularFixed" },
        plural: { prefixes: [[{ n: "inget", r: "ingen" }, true]], suffixes: [[{ text: "får" }, true]], targetMode: "singular" },
      },
      en: {
        singular: { prefixes: [], suffixes: [[{ text: "may not" }, true]], targetMode: "singularFixed" },
        plural: { prefixes: [[{ text: "no" }, true]], suffixes: [[{ text: "may" }, true]], targetMode: "singular" },
      },
    },
  },
};

export const genitiveRules = {
  en(str, isPlural) {
    if (str.charAt(str.length - 1) === "s") return str + "'";
    if (isPlural) return "s'";
    return str + "'s";
  },
  sv(str, isPlural) {
    if (str.charAt(str.length - 1) === "s") return str;
    return str + "s";
  },
};


/* 
export const defaultGrammars = {
  en(str) {
    str = ucfirst(str);
    let lastChar = str.charAt(str.length - 1);
    if (lastChar === "y") {
      str = str.slice(0, str.length - 1);
      return {
        base: str,
        singular: {
          suffix: "y",
        },
        singularFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "y",
        },
        plural: {
          suffix: "ies",
        },
        pluralFixed: {
          prefix: "the",
          prefixSpace: true,
          suffix: "ies",
        },
      };
    }
    return {
      base: str,
      singular: {},
      singularFixed: {
        prefix: "the",
        prefixSpace: true,
      },
      plural: {
        suffix: "s",
      },
      pluralFixed: {
        prefix: "the",
        prefixSpace: true,
        suffix: "s",
      },
    };
  },
  sv(str) {
    str = ucfirst(str);
    let lastChar = str.charAt(str.length - 1);
    if (lastChar === "a") {
      str = str.slice(0, str.length - 1);
      return {
        base: str,
        singular: {
          suffix: "a",
        },
        singularFixed: {
          suffix: "an",
        },
        plural: {
          suffix: "or",
        },
        pluralFixed: {
          suffix: "orna",
        },
      };
    }
    return {
      base: str,
      singular: {
        suffix: "",
      },
      singularFixed: {
        suffix: "en",
      },
      plural: {
        suffix: "er",
      },
      pluralFixed: {
        suffix: "erna",
      },
    };
  },
};
 */
export const languages = ["en", "sv"];
