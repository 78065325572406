export const entityChange = {
  id: 'entityChange',
  dependencies: { legalPersonAny: true },
  handler: function ({ state, handlerInvoked, paths, entries, api }) {
    this.log('Legal Person change ', entries);

    return (node) => {
      if (node.variant !== 'entity' || !node.data) return;
      // console.log('Got an ENtity change! ', entries)
      for (const { path, value } of entries) {
        const [entityType, entityIndex, key] = path.split('.');

        const entity = state[entityType][entityIndex];
        if (!entity || node.data.entity_id !== entity.id || node.data.key !== key) {
          // console.log('Entity change no match', {entity, node: JSON.parse(JSON.stringify(node))})
          continue;
        }

        node.data.value = value;
        api.utils.engine.setFirstChild(node, value);
        this.markNodeUpdate(node);
      }
    };
  },
};
