const hooksExamples = {
  core: {
    id: '7c0949a6-5f6f-42b9-b1e8-d0814dd9cd35',
    action: 'user-added',
    data: {
      user: {
        firstName: 'John',
        lastName: 'Doe',
        id: 'e3a949a6-5f2f-42b9-b1e8-d0814cc9ce97',
      },
    },
  },
  task: {
    id: '7c0949a6-5f6f-42b9-b1e8-d0814dd9cd35',
    action: 'completed',
    data: {
      user: {
        firstName: 'John',
        lastName: 'Doe',
        id: 'e3a949a6-5f2f-42b9-b1e8-d0814cc9ce97',
      },
      taskResponse: 'approve',
      outcome: 'success',
    },
  },
  events: {
    id: '7c0949a6-5f6f-42b9-b1e8-d0814dd9cd35',
    eventCategory: 'core',
    eventType: 'signing',
    resourceType: 'Document',
    resourceId: 'adc949e9-cf9b-42b9-b1e8-d0814cc9dea2',
    data: {
      success: true,
      signatories: [
        {
          firstName: 'John',
          lastName: 'Doe',
          id: 'e3a949a6-5f2f-42b9-b1e8-d0814cc9ce97',
          ssn: 'xxxxxxxx-xxxx',
          maintractUser: true,
        },
        {
          firstName: 'Jane',
          lastName: 'Doe',
          ssn: 'xxxxxxxx-xxxx',
          maintractUser: false,
        },
      ],
    },
  },
  contractClauseStatus: {
    id: '7c0949a6-5f6f-42b9-b1e8-d0814dd9cd35',
    item_id: 'e24d1feb-c7bd-4fd7-9a40-ad461141cc77',
    statusType: 'fulfilled',
    resourceType: 'Document',
    resourceId: 'adc949e9-cf9b-42b9-b1e8-d0814cc9dea2',
  },
  contractTask: {
    id: '7c0949a6-5f6f-42b9-b1e8-d0814dd9cd35',
    taskType: 'payment',
    performanceStatus: 'fulfilled',
    allProcessedAt: '2021-05-02T14:18:21.927Z',
    assignedEntityType: 'LegalPerson',
    assignedEntityId: 'a3c7644f-63a2-43c7-aa8f-023d641101b8',
    data: {
      clause: {},
    },
  },
  contractTaskSchedule: {
    id: '7c0949a6-5f6f-42b9-b1e8-d0814dd9cd35',
    taskId: '081f7deb-f8d6-4268-9f73-4389fee18ecf',
    scheduleType: 'reminder',
    processedAt: '2021-05-02T14:18:21.927Z',
  },
};

export default hooksExamples;
