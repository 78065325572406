import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useEditor } from 'slate-react';
import { setEditorAllowEditing, setDraft } from 'appRedux/actions';
import { useIsTemplateStudio, useEventState, useDraft } from 'hooks';

/* import SidebarLayout from '../Sidebar'; */
import SidebarLayout from 'containers/Sidebar/index';
import SidebarContainer from './SidebarContainer';

import useInputSidebarTabs from '../../Views/Input/SidebarTabs';
import useEditSidebarTabs from '../../Views/Edit/SidebarTabs';

import SidebarLogo from 'containers/Sidebar/SidebarLogo';
// import SidebarLogo from '../Sidebar/SidebarLogo';
import ContractInfo from '../Sidebar/ContractInfo';
import ModeSwitcher from '../Sidebar/ModeSwitcher';
import ActionButtons from '../../components/ActionButtons';

import TopbarLayout from '../Topbar';
import InputTopbarContent from '../../Views/Input/TopbarContent';
import EditTopbarContent from '../../Views/Edit/TopbarContent';

import { TemplateStudioSectionMenu } from '../../components/TemplateStudio/';

import TableOfContents from '../../components/SidebarComponents/TableOfContents';
import { ChangesShowType } from '../../components/SidebarComponents/General';

import { Layout, Menu, Divider } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  SaveOutlined,
  SettingOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  InteractionOutlined,
  FormOutlined,
  EditOutlined,
  ArrowsAltOutlined,
  VerticalRightOutlined,
  VerticalLeftOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import ContractInfo2 from '../Sidebar/ContractInfo2';

import { InputMenu } from '../../Views/Input/SidebarTabs';

export function Sidebar({ action, activePaths }) {
  const isTemplate = useIsTemplateStudio();
  return (
    <SidebarLayout action={action}>
      <SidebarLogo />
      <div className="sidebar-content">
        <ContractInfo />
        {!isTemplate && <ModeSwitcher />}
        {action === 'input' ? <InputSidebar activePaths={activePaths} /> : <EditSidebar />}
        <ActionButtons />
      </div>
    </SidebarLayout>
  );
}
function InputSidebar({ activePaths }) {
  return <SidebarContainer tabFunction={useInputSidebarTabs} tabArguments={{ activePaths }} />;
}
function EditSidebar({ activePaths }) {
  return <SidebarContainer tabFunction={useEditSidebarTabs} tabArguments={{ activePaths }} />;
}

export function Topbar({ action, activePaths }) {
  const isTemplate = useIsTemplateStudio();

  if (isTemplate) {
    return <TemplateEditingTopBar action={action} activePaths={activePaths} />;
  }

  return <DraftTopBar action={action} activePaths={activePaths} />;
}

function DraftTopBar({ action, activePaths }) {
  const editor_allowEditing = useSelector(({ draft }) => draft.editor_allowEditing);
  const editor_draft_mode = useSelector(({ draft }) => draft.draft_mode);
  const editorBlurred = useEventState('editorBlur', true);
  const contractFocus = useEventState('contractFocus');

  const showEditorToolbarShowable = !editorBlurred || contractFocus;

  const showToolbar =
    (showEditorToolbarShowable && !!editor_allowEditing) || editor_draft_mode === 'contract';

  return (
    <TopbarLayout>
      {showToolbar ? <EditTopbarContent /> : <InputTopbarContent activePaths={activePaths} />}
    </TopbarLayout>
  );
}

function TemplateEditingTopBar({ action, activePaths }) {
  const editorBlurred = useEventState('editorBlur', true);
  const contractFocus = useEventState('contractFocus');

  const showEditorToolbarShowable = !editorBlurred || contractFocus;

  return (
    <TopbarLayout>
      {showEditorToolbarShowable || action === 'contract' ? (
        <EditTopbarContent />
      ) : (
        <>
          <InputTopbarContent activePaths={activePaths} />
          <TemplateStudioSectionMenu />
        </>
      )}
    </TopbarLayout>
  );
}

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export function NewSidebar({ action, activePaths }) {
  const isTemplate = useIsTemplateStudio();
  const dispatch = useDispatch();

  const editor = useEditor();
  const draft = useDraft();

  const updateReferences = () => {
    editor.cmd_updateReferences(draft);
    if (draft && draft.instance) {
      draft.withDraftMethod('itemJoinContent');
    }
  };

  const editor_allowEditing = useSelector(({ draft }) => draft.editor_allowEditing);

  const toggleAllowEditing = useCallback(() => {
    dispatch(setEditorAllowEditing(!editor_allowEditing));
    editor.meta.allowEditing = !editor_allowEditing;
  }, [dispatch, editor_allowEditing, editor]);

  const direction = useSelector(({ draft }) => draft.input_preview_direction);
  const oppositeDirection = direction === 'left' ? 'right' : 'left';
  const toggleDirection = () => {
    localStorage.setItem('input-direction', oppositeDirection);
    dispatch(setDraft('input_preview_direction', oppositeDirection));
  };
  useEffect(() => {
    const element = document.getElementById('draft-input-container');
    if (element) {
      if (direction === 'right') element.classList.add('right');
      else element.classList.remove('right');
    }
  }, [direction]);

  return (
    <Sider
      theme="dark"
      collapsible
      collapsedWidth={80}
      width={240}
      collapsed={true}
      className="studio-sider"
      trigger={null}
    >
      <div>
        <ContractInfo2 />
        <Divider className="m-0" />
        {!isTemplate && (
          <>
            <ModeSwitcher newSidebar />
            <Divider className="m-0" />
          </>
        )}

        <Menu theme="dark" defaultSelectedKeys={[]} selectedKeys={[]} mode="inline">
          {/* <Divider plain>Version</Divider> */}
          <SubMenu key="sub1" icon={<FileOutlined />} title="Version">
            <Menu.Item key="3">Rename version</Menu.Item>
            <Menu.Item key="4">Duplicate version</Menu.Item>
            <Menu.Item key="5">Remove version</Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<SaveOutlined />} title="Save">
            <Menu.Item key="6">Save version</Menu.Item>
            <Menu.Item key="8">Save as new version</Menu.Item>
          </SubMenu>
          <SubMenu key="sub2x" icon={<SettingOutlined />} title="Settings">
            <Menu.Item key="6xz">
              <div style={{ width: '100%', height: '100%' }}>apa</div>
            </Menu.Item>
            <Menu.Item key="6dxz">
              <div style={{ width: '100%', height: '100%' }}>apa</div>
            </Menu.Item>
          </SubMenu>

          <Divider className="m-0" />
          <Menu.Item
            key="1fdsdsf"
            icon={<ArrowsAltOutlined />}
            // className="bg-sepia"
            onClick={updateReferences}
          >
            Update contract cross references
          </Menu.Item>
          <Divider className="m-0" />
          <SubMenu
            key="subd2x"
            icon={<FormOutlined />}
            title="Input Sections"
            // className="bg-primary"
          >
            <Menu.Item key="6f" className="container-item">
              <div>
                <InputMenu activePaths={activePaths} />
              </div>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="subd2xop"
            icon={<OrderedListOutlined />}
            title="Table of Contents"
            // className="bg-primary"
          >
            <Menu.Item key="6fda" className="container-item">
              <div>
                <TableOfContents isSidebarMini={false} noHeader={true} />
              </div>
            </Menu.Item>
          </SubMenu>
          {/* <Divider plain>Actions</Divider> */}
          <Divider className="m-0" />
          <Menu.Item
            key="1fdsf"
            icon={
              <EditOutlined
                style={{
                  color: editor_allowEditing && 'green',
                }}
              />
            }
            // className="bg-dark-grey"
            onClick={toggleAllowEditing}
          >
            <div>
              <div>Contract free text editing is {editor_allowEditing ? 'activated' : 'inactivated'}</div>
              <div>Click to {editor_allowEditing ? 'inactivate' : 'activate'}</div>
            </div>
          </Menu.Item>
          <SubMenu
            key="subd2ds"
            icon={<ExclamationCircleOutlined />}
            title="Changes"
            // className="bg-dark-grey"
          >
            <Menu.Item key="6fdsd" className="container-item">
              <ChangesShowType />
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="1fdsdassf"
            icon={direction === 'right' ? <VerticalRightOutlined /> : <VerticalLeftOutlined />}
            // className="bg-dark-grey"
            onClick={toggleDirection}
          >
            Switch contract placement to {oppositeDirection}
          </Menu.Item>
        </Menu>
      </div>
      <div className="studio-sider-bottom">
        <ActionButtons isMini dark />
      </div>
    </Sider>
  );

  /* return (
    <SidebarLayout action={action}>
      <SidebarLogo />
      <div className="sidebar-content">
        <ContractInfo />
        {!isTemplate && <ModeSwitcher />}
        {action === 'input' ? <InputSidebar activePaths={activePaths} /> : <EditSidebar />}
        <ActionButtons />
      </div>
    </SidebarLayout>
  ); */
}
