import React from "react";
import { Route, Switch } from "react-router-dom";


import Main from "./main/index"
import Search from "./search/index"
import Projects from "./projects/index";
import Project from "./project/index";
import Folders from "./folders/index";
import Entities from "./entities/index";
import Entity from "./entity/index";
// import Persons from "./persons/index";
import Tasks from "./tasks/index";
import Help from "./help/index"
import Templates from "./templates/index"
import ClientSettings from "./clientSettings/index"

import DocumentRedirect from "./redirectors/documents"
import VersionsRedirect from "./redirectors/versions"

const App = ({ match }) => (
  <div className="main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} component={Main} />
      <Route path={`${match.url}search`} component={Search} />
      <Route path={`${match.url}projects`} component={Projects} />
      <Route path={`${match.url}documents/:id`} component={DocumentRedirect} />
      <Route path={`${match.url}versions/:id`} component={VersionsRedirect} />
      <Route path={`${match.url}folders`} component={Folders} />
      <Route path={`${match.url}project/:id`} component={Project} />
      <Route path={`${match.url}folder/:id`} component={Project} />
      <Route path={`${match.url}entities`} exact component={Entities} />
      <Route path={`${match.url}entities/:id`} component={Entity} />
      {/* <Route path={`${match.url}persons/:id`} component={Persons} /> */}
      <Route path={`${match.url}tasks`} component={Tasks} />
      <Route path={`${match.url}templates`} component={Templates} />
      <Route path={`${match.url}clientSettings`} component={ClientSettings} />
      <Route path={`${match.url}help`} component={Help} />
    </Switch>
  </div>
);

export default App;
