import { Node } from '../../../../import/slate';
import uuid from 'uuid-random';
import { find } from '../index';
import { isElement, isBlock, isInline, isText } from '../../../types/elements';

export function isTextFirst(node, parent) {
  if (!node || !parent || !Array.isArray(parent.children)) return false;
  let previousString,
    previousStrings = '';
  for (let i = 0; i < parent.children.length; i++) {
    const child = parent.children[i];

    if (child === node) {
      if (typeof previousString === 'string' && previousString.trim().endsWith('.')) return true;
      if (i === 0) {
        return true;
      }
      if (previousStrings.trim() === '') {
        return true;
      }
    }

    previousString = Node.string(child);
    previousStrings += previousString;
  }
}

export function getNodeAllText(node) {
  if (Array.isArray(node) && node.length > 0) node = node[0];
  if (node.data && node.data._inActive) return '';
  if (node.hasOwnProperty('text')) return node['text'] || '';
  let text = '';
  if (!node || !node.children || node.children.length === 0) return '';
  for (const child of node.children) {
    if (isBlock(child) || isInline(child)) text += getNodeAllText(child);
    if (isText(child)) text += child.text;
  }
  return text;
}

export function setFirstChild(node, text) {
  if (typeof text !== 'string') {
    if (text === undefined) {
      console.trace('setFirstChild: text is undefined!', { node: JSON.parse(JSON.stringify(node)) });
      node.children = [{ text: '[**]' }];
      return;
    }
    text = text.toString();
  }
  if (node.children.length === 1) return (node.children[0].text = text);
  node.children = [{ text }];
}

export function uniqueItemIds(nodeOrArray) {
  function ensure(node) {
    if (!isElement(node)) return;
    if (!node.data) {
      node.data = {
        item_id: uuid(),
        template_id: 'std_' + (node.type || 'item'),
      };
    } else {
      node.data.item_id = uuid();
      if (!node.data.template_id) node.data.template_id = 'std_' + (node.type || 'item');
    }
    for (const child of node.children) ensure(child);
  }

  if (Array.isArray(nodeOrArray)) {
    for (const node of nodeOrArray) ensure(node);
  } else if (isElement(nodeOrArray)) {
    ensure(nodeOrArray);
  }
  return nodeOrArray;
}

export function initEachItems(containerNode, uid) {
  function ensure(node) {
    if (!isElement(node)) return;
    if (node.variant === 'item' && node.data.each.key) {
      node.data.each_item_id = uid + '.' + node.data.each.key;
    }
    for (const child of node.children) ensure(child);
  }

  if (isElement(containerNode)) {
    ensure(containerNode);
  }

  const referencesToPopulate = find(
    containerNode,
    (n) => n.variant === 'ref' && n.data && n.data.populateReference && n.data.bookmarkPrefix
  );
  for (const reference of referencesToPopulate) {
    reference.data.name = reference.data.bookmarkPrefix + uid;
  }

  return containerNode;
}
