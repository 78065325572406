import {
  SET_LEGALPERSONS,
  UPDATE_LEGALPERSON,
  INSERT_LEGALPERSON,
  REMOVE_LEGALPERSON,
} from 'constants/ActionTypes';

export const INIT_STATE = [
  /* {
    id: '8888aaaa-6538-4eda-8767-4c334d1e32da',
    parentId: null,
    form: null,
    name: 'Owner',
    jurisdiction: 'se',
    identificationNumber: '',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
  {
    id: 'f89895d1-6fca-48bb-8d77-52a6ebf63f61',
    parentId: '8888aaaa-6538-4eda-8767-4c334d1e32da',
    form: 'AB',
    name: 'Company Name',
    jurisdiction: 'se',
    identificationNumber: '5566771122',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  }, */
];

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case SET_LEGALPERSONS:
      return action.payload;
    case UPDATE_LEGALPERSON:
      const newState = [...state];
      newState[action.payload.index] = action.payload.legalPerson;
      return newState;
    case INSERT_LEGALPERSON:
      return [...state, action.payload];
    case REMOVE_LEGALPERSON:
      const payloadType = typeof action.payload;
      let removeIds = [];
      if (payloadType === 'number') {
        console.log('Remove_legalperson got number..', action.payload);
        return state;
      }
      if (payloadType === 'string') {
        removeIds = [action.payload];
      } else if (Array.isArray(action.payload)) removeIds = action.payload;
      else return state;
      return state.filter((legalPerson) => !removeIds.includes(legalPerson.id));
    default:
      return state;
  }
}
