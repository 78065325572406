import React from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import { isHeading } from 'core/types/elements';
import { getNodeAllText } from 'core/engine/utils';
import { Tooltip } from 'antd';
import IntlMessages from 'util/IntlMessages';

export default function TableOfContents({ noHeader, hideIfEmpty, hideIfOne, isSidebarMini }) {
  const editor = useSlate();
  const [...headings] = Editor.nodes(editor, {
    match: (n) =>
      (n.type === 'heading_one' ||
        n.type === 'heading_two' ||
        n.type === 'schedule_one' ||
        n.type === 'section') &&
      (!n.data || !n.data._inActive),
    at: [],
  });

  const numbers = {
    heading_one: 0,
    heading_two: 0,
    schedule_one: 0,
  };

  if (headings.length === 0 && hideIfEmpty) return null;
  if (headings.length === 1 && hideIfOne) return null;

  return (
    <div className="">
      {!noHeader && (
        <h5>
          <IntlMessages id="studio.sidebar.contents.contents" />
        </h5>
      )}
      <div className="btns-section" style={{ overflow: 'hidden' }}>
        {headings.map(([node, path]) => {
          const nodeIsHeading = isHeading(node);
          let number = '';
          let name;
          if (nodeIsHeading) {
            name = getNodeAllText(node);
            if (name.length > 30) {
              name = name.substr(0, 30) + '...';
            }
            if (node.type === 'heading_one') numbers.heading_two = 0;
            numbers[node.type]++;
            for (const type in numbers) {
              if (type !== node.type) number += numbers[type] + '.';
              else {
                number += numbers[type] + ' ';
                break;
              }
            }
          } else if (node.type === 'schedule_one') {
            name = getNodeAllText(node);
            if (name.length > 30) {
              name = name.substr(0, 30) + '...';
            }
            numbers[node.type]++;
            for (const type in numbers) {
              if (type !== node.type) continue;
              else {
                number += numbers[type] + ' ';
                break;
              }
            }
          } else {
            name = node.data.name;
          }
          if (isSidebarMini) {
            return (
              <span
                key={path.join('.')}
                className={'d-block clickable hover-strong side-toc-' + node.type}
                onMouseDown={() => {
                  editor.scrollToNode(node);
                }}
              >
                <Tooltip title={name} placement="right" className="d-flex ml-2">
                  <div className="draft-input-menu-item-text mr-2 ">
                    <i className="mdi mdi-disk" />
                    <small className="mini-text">{number || name || ''}</small>
                  </div>
                </Tooltip>
              </span>
            );
          }
          return (
            <span
              key={path.join('.')}
              className={'d-block clickable hover-strong side-toc-' + node.type}
              onMouseDown={() => {
                editor.scrollToNode(node);
              }}
            >
              {number}
              {name}
            </span>
          );
        })}
      </div>
    </div>
  );
}
