
export const initLists = {
  id: "initLists",
  dependencies: { onGenesis: true },
  handler: function ({api}) {

    api.utils.engine.itemJoiner(this, this.getContent());
    
    /* return (node, parents) => {
      
      

    }; */
  },
};
