import React from "react";
import TopHeader from "../../project/Header/"
import IntlMessages from "util/IntlMessages";

const TaskHeader = () => {
  return (
    <TopHeader
      name={<IntlMessages id="app.general.tasks.Tasks" />}
      description={<IntlMessages id="app.general.tasks.TasksDesc" />}
      icon={{ className: "mdi mdi-blur-linear", bgClassName: "bg-orange" }}
    />
  );
};

export default TaskHeader;