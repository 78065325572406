import React from 'react';
import { useContract, setContract, useStudioBaseRoute } from 'hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Create } from 'core/interfaces';
import RemoveModal from '../../../Views/Input/renderDraftUI/components/templateEdit/RemoveModal';
import { removeCard } from '../utils';

export default function RemovePage({ onFinish }) {
  const contract = useContract();
  const location = useLocation();
  const baseRoute = useStudioBaseRoute();
  const history = useHistory();
  const dispatch = useDispatch();

  const removePage = (pages, paths, level) => {
    const currentPath = paths[level];

    if (level === paths.length - 1) {
      return delete pages[currentPath];
      // return console.log('Deleting from pages, with path: ', pages, currentPath);
    } else {
      return removePage(pages[currentPath].pages, paths, level + 1);
    }
  };

  const removePageFromRoute = (children, paths, level) => {
    const currentPath = paths[level];

    const node = children.find((c) => c.id === currentPath);
    if (level === paths.length - 1) {
      const index = children.indexOf(node);
      return children.splice(index, 1);
      // return console.log('Deleting from routes, with path and index: ', children, currentPath, index);
    } else {
      if (!node.children || node.children.length === 0) return;
      return removePageFromRoute(node.children, paths, level + 1);
    }
  };

  const onRemove = (removeCompletely) => {
    const { paths } = Create.getPageParts(contract.data.ui, location.pathname);
    const currentPage = Create.getPageByUrl(contract.data.ui, location.pathname);

    if (removeCompletely) {
      currentPage.cards.forEach((cardName) => {
        dispatch(removeCard(contract, currentPage, cardName, removeCompletely));
      });
    }

    // Since there can be sub pages, we recursively go through child pages/routes and delete the last on in "paths" = the current page we're on
    removePage(contract.data.ui.pages, paths, 0);
    removePageFromRoute(contract.data.ui.routes, paths, 0);

    // If we remove the top level, also remove from pages_order
    if (paths.length === 1) {
      const index = contract.data.ui.pages_order[paths[0]];
      contract.data.ui.pages_order.splice(index, 1);
    }

    setContract({ ...contract });

    onFinish();

    const fullRoutePath = `${baseRoute}/input`;
    history.push(fullRoutePath);
  };

  return <RemoveModal type="page" onRemove={onRemove} />;
}
