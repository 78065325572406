import * as handlers from '../../handlers/';
import { Contract } from '../../../interfaces';

export const setupHandlers = function (Engine) {
  this.handlers = [];
  const contractModules = Contract.getModules(this.contract);
  const engineModules = (contractModules && contractModules.engine) || null;
  const engineModulesList = engineModules ? engineModules.map((m) => m.folder) : null;

  for (const handlerName in handlers) {
    // Deal with modules. Ensure that only modules specified by
    // the contract (contract.data.create.contractModules.engine)
    // are added.
    if (handlerName === '_moduleHandlers') {
      if (!engineModules) continue;
      for (const moduleSetName in handlers._moduleHandlers) {
        const moduleSet = handlers._moduleHandlers[moduleSetName];
        if (engineModulesList.indexOf(moduleSetName) === -1) {
          continue;
        }
        for (const moduleName in moduleSet) {
          setupHandler(Engine, this, moduleSet[moduleName], moduleName);
        }
      }
      continue;
    }

    // Deal with ordinary handlers
    setupHandler(Engine, this, handlers[handlerName], handlerName);
  }
  this.handlers.sort(sortHandlers);
};

function setupHandler(Engine, engine, handler, handlerName) {
  const type = getHandlerType(handler);
  if (!type) return;

  if (type === 'handler') {
    // Add handler
    if (typeof handler.handler === 'function') engine.handlers.push(handler);
  } else {
    // Add function/variable to engine prototype
    Engine.prototype[handlerName] = handler;
    engine.api.core[handlerName] = handler.bind(engine);
  }
}

// Helpers
function getHandlerType(handler) {
  const type = typeof handler;
  if (type === 'object' && handler.dependencies && handler.handler) return 'handler';
  return type;
}

function sortHandlers(a, b) {
  const aTime = !isNaN(a.time) ? a.time : 0;
  const bTime = !isNaN(b.time) ? b.time : 0;

  if (aTime < bTime) return -1;
  if (bTime > aTime) return 1;
  return 0;
}
