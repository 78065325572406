import {Node, Path, Transforms, Editor, Range} from 'slate';

export const remove_text = (editor, app) => {
    /* if (Range.isCollapsed(editor.selection) && editor.selection.anchor.offset === 0) {
      try {
        if (Node.get(editor, Path.parent(editor.selection.anchor.path)).type === 'tab') {
          console.log('In tab.')
          Transforms.removeNodes(editor, { at: Path.parent(editor.selection.anchor.path)})
          return false
        }
      } catch (err) {

      }
    } */
    return true; // Return false to stop the default apply logic
}

