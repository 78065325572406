export const mapLegalPersons = (legalPersons) => {
  return legalPersons.map((legalPerson) => (
    {
      id: legalPerson.id,
      parentId: legalPerson.parentId,
      form: legalPerson.form,
      name: legalPerson.name,
      jurisdiction: legalPerson.jurisdiction,
      identificationNumber: legalPerson.identificationNumber,
      street: legalPerson.street,
      city: legalPerson.city,
      zipcode: legalPerson.zipcode,
      emails: legalPerson.emails,
      data: legalPerson.data,
      isTopCo: legalPerson.isTopCo,
      underTransfer: legalPerson.underTransfer,
      Owners: legalPerson.Owners,
      RealPersons: legalPerson.RealPersons,
      // Owned: legalPerson.Owned,
    }
  ));
};
