import React, { useState, useEffect } from "react";
import { message, Modal } from "antd";
import IframeResizer from 'iframe-resizer-react'
import api from "utils/api";

export default function ({ fileId, resourceId, resourceType, onClose }) {
  const [viewFileCurrent, setViewFileCurrent] = useState(null); 

  const fileQuery = (fileId, resourceId, resourceType, extraPath = null, extraParams = null) => {
    return `/superfiles${fileId ? `/${fileId}` : ''}${extraPath ? extraPath : ''}?resourceType=${resourceType}&resourceId=${resourceId}${extraParams ? extraParams : ''}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(fileQuery(fileId, resourceId, resourceType, '/info', '&fetchType=view'));
        setViewFileCurrent(response.data);
      } catch (err) {
        console.log(err);
        message.error('Could not view file'); 
      }
    };
    fetchData();
  }, [fileId, resourceId, resourceType]);

  const closeViewFile = () => {
    setViewFileCurrent(null);
    onClose();
  }

  const renderViewFile = (file) => {
    const { contentType, downloadUrl, fileName } = file;
    if (contentType.startsWith('image'))
      return <img src={downloadUrl} alt={fileName} />
    if (contentType === 'application/pdf')
      return (
        <IframeResizer
          log
          style={{ width: '1px', minWidth: '100%', minHeight: '80vh'}}  
          heightCalculationMethod="bodyScroll"
          src={downloadUrl}
        />
      );
    return null;
  }

  if (!viewFileCurrent) return null;
  return (
    <Modal
      size="lg"
      className="qb-modal"
      title={
        <div>
          <div>{viewFileCurrent.fileName}</div>
        </div>
      }
      visible={true}
      backdrop={"static"}
      onOk={closeViewFile}
      onCancel={closeViewFile}
      centered
      style={{ textAlign: 'center' }}
      footer={null}
    >
      {(
        <div>
          {renderViewFile(viewFileCurrent)}
        </div>
      )}
    </Modal>
  );
}

