import React, { useState } from "react";
import { Row, Col, Form, Input, Avatar, Button, Alert } from "antd";
import api from "utils/api";

import ContainerHeader from "components/ContainerHeader/index";

function validateEmail(mail) {
  // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [linkSent, setLinkSent] = useState(false);
  const [verificationError, setVerificationError] = useState("");

  const verifyChange = (e) => {
    if (e.target.value === "") setVerificationError("");
    if (e.target.name === "emailx") setEmail(e.target.value);
  };

  const validEmail = validateEmail(email);

  const sendLink = () => {
    if (!email || !validEmail) return;
    api
      .post("/users/forgotpwd", { email })
      .then((res) => {
        if (res.data && res.data === true) {
          console.log('Forgot pwd send link ', res.data)
          setLinkSent(true);
        } else console.log("veri false");
      })
      .catch((err) => {
        console.log("verification error", err.response);
        setVerificationError("Invalid user email");
      });
  };

  

  return (
    <div
      style={{
        padding: "40px",
      }}
    >
      <Row>
        <Col span={24}>
          <ContainerHeader
            title="Forgot password"
            // match={match}
          />
        </Col>
        <Col span={24}>
          <div className="timeline-section">
            <div className={`timeline-item`}>
              <div className="timeline-badge timeline-img">
                <Avatar
                  size="large"
                  className={
                    "size-60 rounded-circle bg-" +
                    (linkSent ? "success" : "white")
                  }
                  icon={
                    <i
                      className={`icon icon-email ml-auto text-${
                        linkSent ? "white" : "primary"
                      } fs-xxxl d-block mt-1`}
                    />
                  }
                />
              </div>

              <div className="timeline-panel">
                <div className="timeline-panel-header">
                  <div className="timeline-heading">
                    <h3 className="timeline-title">Credential</h3>
                  </div>
                </div>
                <div className="timeline-body">
                  <>
                    <Form layout="vertical">
                      <Form.Item label="E-mail">
                        {!linkSent ? (
                          <Input
                            type="text"
                            name="emailx"
                            id="emailx"
                            placeholder="your@email.com"
                            value={email}
                            onChange={verifyChange}
                            required
                          />
                        ) : (
                          <small>{email}</small>
                        )}
                      </Form.Item>
                    </Form>
                    {!linkSent && (
                      <Row className="mb-3 mt-4">
                        <Col span={12} className="m-auto">
                          <Button
                            type={validEmail ? "primary" : ""}
                            size="large"
                            onClick={sendLink}
                            block
                            disabled={!validEmail ? true : false}
                          >
                            Reset password
                          </Button>
                          {verificationError && email && (
                            <div className="text-danger mt-2">
                              {verificationError}
                            </div>
                          )}
                          {!validEmail && email && (
                            <div className="text-danger mt-2">
                              Enter valid email
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}
                  </>
                </div>
                {linkSent && (
                  <div>
                    <Alert
                      message="Reset password successful"
                      description="Please check your email inbox with instructions on how to reset your password"
                      type="success"
                      showIcon
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
