import React, { useState, useCallback, useMemo } from 'react';
import { Button, Spin, notification, Form, Radio, Input, Select, Checkbox } from 'antd';
import api from 'utils/api';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { RealPersonEditFields, CollectPersonEmails } from 'components/RealPerson/EditRealPerson';
import { userToName } from 'components/ui';

const { useForm } = Form;

const structureEligibleJurisdictions = ['se'];

function validRegno(jurisdiction, str) {
  if (jurisdiction === 'se') {
    str = str.replace(/-/g, '');
    if (str.length !== 10) return 'invalid';
    if (isNaN(parseInt(str))) return 'invalid';
    return 'valid';
  }
  return 'unknown';
}

export default function NewCustomerItem(props) {
  const [isSettingUp, setIsSettingUp] = useState(false);
  const [customerType, setCustomerType] = useState('legalPerson');
  const formatMessage = useIntlMessage();

  const storeNewCustomer = useCallback(
    (data) => {
      api
        .post('/customers', data)
        .then(function (res) {
          if (res.data) {
            // console.log('Stored new customer.', res.data);
            props.customerWasCreated(res.data);
            props.close();
          } else {
            console.log('Error in posting customer, ', res);
            notification.error({
              description: formatMessage('app.projects.couldNotCreateCustomer'),
            });
          }
          // setIsSettingUp(false);
        })
        .catch((err) => {
          console.log('post err', err, err.response);
          notification.error({
            description: formatMessage('app.projects.couldNotCreateCustomer'),
          });
          setIsSettingUp(false);
        });
    },
    [props, formatMessage]
  );

  const onFinish = (values) => {
    console.log('finish ', values);

    const name =
      customerType === 'legalPerson'
        ? values.name
        : userToName({ firstName: values.firstName, lastName: values.lastName });

    const { identificationNumber, firstName, lastName, jurisdiction, fetchGroup } = values;
    if (!name || !jurisdiction) {
      return notification.error({
        message: formatMessage('app.projects.fillOutAllRequired'),
      });
    }

    if (fetchGroup && !structureEligibleJurisdictions.includes(jurisdiction)) {
      return notification.error({
        message: formatMessage('app.general.customer.fetchNotAvailableJurisdiction'),
      });
    }
    if (fetchGroup && !identificationNumber) {
      return notification.error({
        message: formatMessage('app.general.customer.incorrectIdentificationNumberFormat'),
      });
    }

    const instructions = {};
    if (structureEligibleJurisdictions.includes(jurisdiction) && fetchGroup) {
      instructions.fetchGroup = true;
      instructions.fetchGroupJurisdiction = jurisdiction;
      instructions.fetchGroupRegNo = identificationNumber;
    }

    const customer = {
      ...values,
      type: customerType,
      fetchGroup,
      name: name,
      firstName,
      lastName,
      jurisdiction: jurisdiction,
      identificationNumber,
    };

    setIsSettingUp(true);
    storeNewCustomer(customer);
  };
  const onFinishFailed = (err) => {
    return notification.error({
      message: formatMessage('app.projects.fillOutAllRequired'),
    });
  };

  if (isSettingUp) return <Spin className="loader-container" />;

  return (
    <div>
      <div align={'center'}>
        <i className={'mdi mdi-account-plus'} style={{ fontSize: 48 }} />
      </div>
      <div className="new-project-text">
        <IntlMessages id="app.projects.CreateNewCustomer" />
      </div>
      <Radio.Group
        className="mb-4 w-100 text-center"
        value={customerType}
        onChange={(e) => setCustomerType(e.target.value)}
      >
        <Radio value={'legalPerson'}>
          <IntlMessages id="app.persons.legalPerson" />
        </Radio>
        <Radio value={'realPerson'}>
          <IntlMessages id="app.persons.realPerson" />
        </Radio>
      </Radio.Group>
      {customerType === 'legalPerson' && (
        <NewLegalPersonCustomer onFinish={onFinish} onFinishFailed={onFinishFailed} />
      )}
      {customerType === 'realPerson' && (
        <NewRealPersonCustomer onFinish={onFinish} onFinishFailed={onFinishFailed} />
      )}
    </div>
  );
}

function NewLegalPersonCustomer({ onFinish, onFinishFailed }) {
  const formatMessage = useIntlMessage();
  const [form] = useForm();

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="new-customer"
      initialValues={{
        name: '',
        identificationNumber: '',
        jurisdiction: 'se',
        fetchGroup: false,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label={
          <small>
            <IntlMessages id="app.general.customer.Name" />
          </small>
        }
        name="name"
        rules={[{ required: true, whitespace: true, message: formatMessage('app.persons.validation.name') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={
          <small>
            <IntlMessages id="app.general.Jurisdiction" />
          </small>
        }
        name="jurisdiction"
        rules={[
          {
            required: true,
            whitespace: true,
            message:
              formatMessage('app.general.Jurisdiction') + formatMessage('app.general.fields.fieldRequired'),
          },
        ]}
      >
        <Select>
          <Select.Option value={'se'}>
            <IntlMessages id="app.countries.se" />
          </Select.Option>
          <Select.Option value={'dk'}>
            <IntlMessages id="app.countries.dk" />
          </Select.Option>
          <Select.Option value={'no'}>
            <IntlMessages id="app.countries.no" />
          </Select.Option>
          <Select.Option value={'fi'}>
            <IntlMessages id="app.countries.fi" />
          </Select.Option>
          <Select.Option value={'uk'}>
            <IntlMessages id="app.countries.uk" />
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <small>
            <IntlMessages id="app.general.IdentificationNumber" />
          </small>
        }
        name="identificationNumber"
        rules={[
          {
            validator: (rule, value) => {
              const jurisdiction = form.getFieldValue('jurisdiction');
              // console.log('valid ? ', {rule, value, form})
              if (value && validRegno(jurisdiction, value) === 'invalid') return Promise.reject(new Error());

              return Promise.resolve();
            },
            message: formatMessage('app.general.customer.incorrectIdentificationNumberFormat'),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={
          <small>
            <IntlMessages id="app.general.Street" />
          </small>
        }
        name="street"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={
          <small>
            <IntlMessages id="app.general.City" />
          </small>
        }
        name="city"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={
          <small>
            <IntlMessages id="app.general.Zip" />
          </small>
        }
        name="zipcode"
      >
        <Input />
      </Form.Item>
      <CollectPersonEmails required={false} small />

      <Form.Item
        className="border-top mt-2 pt-2"
        dependencies={['jurisdiction', 'identificationNumber']}
        label={
          <small>
            <IntlMessages id="app.general.customer.fetchGroup" />
          </small>
        }
        extra={
          <small>
            <IntlMessages id="app.general.customer.fetchGroupInfo" />
          </small>
        }
        name="fetchGroup"
        rules={[
          {
            validator: (rule, value) => {
              const jurisdiction = form.getFieldValue('jurisdiction');
              const identificationNumber = form.getFieldValue('identificationNumber');
              if (value && !structureEligibleJurisdictions.includes(jurisdiction))
                return Promise.reject(
                  new Error(formatMessage('app.general.customer.fetchNotAvailableJurisdiction'))
                );
              if (value && validRegno(jurisdiction, identificationNumber) === 'invalid')
                return Promise.reject(
                  new Error(formatMessage('app.general.customer.fetchRequireIdentificationNumber'))
                );
              return Promise.resolve();
            },
          },
        ]}
        valuePropName={'checked'}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 0, span: 24 }} className="text-center">
        <Button type="primary" htmlType="submit" className="btn mt-4">
          <IntlMessages id="app.projects.CreateCustomer" />
        </Button>
      </Form.Item>
    </Form>
  );
}

function NewRealPersonCustomer({ onFinish, onFinishFailed }) {
  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="new-customer"
      initialValues={{
        city: '',
        emails: [],
        firstName: '',
        identificationNumber: '',
        lastName: 'tes',
        street: '',
        zipcode: '',
        jurisdiction: 'se',
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <RealPersonEditFields emailRequired={false} small />
      <Form.Item wrapperCol={{ offset: 0, span: 24 }} className="text-center">
        <Button type="primary" htmlType="submit" className="btn mt-4">
          <IntlMessages id="app.projects.CreateCustomer" />
        </Button>
      </Form.Item>
    </Form>
  );
}
