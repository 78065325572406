// import { Editor, Node, Path, Range } from 'slate';
// import { autoSelectableInline } from 'core/types/elements';

let currentPath = [];
// let previousValidInteractiveSelection = null;

// const isCollapsed = (selection) => selection.anchor && selection.focus && Range.isCollapsed(selection);

export const set_selection = (editor, app, applyFn) => {
  // Set by onKeyDown handler. Would potentially
  // be useful here to know if a keyCode (left, right etc)
  // caused the selection change. We need to reset it
  // here for it to be correct (potentially should be null) the next time.
  editor.tmp._lastKeyCode = null;

  /*   const allowEdit = editor.editingAllowed();
  const isInput = editor.meta.action === "input";
  const isEditingTemplate = editor.meta.isEditingTemplate

  if (isInput || isEditingTemplate) return;

  if (!app.newProperties || !app.newProperties.anchor) {
    return
  }

  const newSelection = {
    anchor: app.newProperties.anchor || (editor.selection && editor.selection.anchor),
    focus: app.newProperties.focus || (editor.selection && editor.selection.focus),
  };

  const collapsedSelection = isCollapsed(newSelection);

  if (collapsedSelection && !allowEdit) {
    // Auto highlight field
    if (Path.equals(newSelection.anchor.path, newSelection.focus.path)) {
      // previousValidInteractiveSelection = newSelection;
      const currentInline = editor.currentInline(newSelection.anchor.path);
      if (!currentInline || !Node.isTextInline(editor, currentInline) || !autoSelectableInline(currentInline)) {
        currentPath = newSelection.anchor.path;
        return;
      }
      // If user goes to new inline which only contains text,
      // select all of it
      if (!Path.equals(newSelection.anchor.path, currentPath)) {
        app.newProperties = {
          anchor: Editor.start(editor, newSelection.anchor.path),
          focus: Editor.end(editor, newSelection.anchor.path),
        };
        currentPath = newSelection.anchor.path
      }
    }

    return;
  }
 */
};
