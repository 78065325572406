import React from "react";
import { Transforms } from "slate"
import { useSelected, useEditor } from "slate-react";
import { useContract, setStudioEditorInputContent } from "hooks";
import { Contract, InputPaths } from "core/interfaces";
import { Tooltip } from "antd";

export function RepeatableButton({ element }) {
  const selected = useSelected();
  const contract = useContract();
  const editor = useEditor()
  
  if (!selected) return null;
  
  const ui = Contract.getUi(contract);
  const language = Contract.getLanguage(contract);

  const { _path } = element.data

  if (!_path) return null;

  const cardId = InputPaths.getCardId(contract, _path)

  const card = ui.cards[cardId];

  const openCard = () => {
    Transforms.forceDeselect(editor)
    setStudioEditorInputContent({
      card: card,
      language,
      path: _path,
      isRepeatable: true,
    });
  };

  return (
    <Tooltip
      title={
        <>
          <span>Edit</span> <strong>{cardId}</strong> <span>input</span>
        </>
      }
    >
      <div className="editor-each-repeatable-btn edit" contentEditable={false} onMouseDown={openCard}>
        <i className="mdi mdi-creation" />
      </div>
    </Tooltip>
  );
}
