import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { createDraft, finishDraft } from 'immer';
import { Select, Checkbox, Input, Form, Collapse, Row, Col, Card } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useContract } from 'hooks';
import { Contract } from 'core/interfaces';
import Concepts from './Concepts';
import Setup from './Setup';
import { formatting } from 'core/config/contractDefaults';
import { LIST_STYLE_TYPES } from 'core/config/constants';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

const { fontFamilies, defaultFont, fontSizes } = formatting;
const { Option } = Select;
const { Panel } = Collapse;

const listFormats = [
  {
    value: '(a)',
    prefix: '(',
    suffix: ')',
  },
  {
    value: 'a)',
    prefix: '',
    suffix: ')',
  },
  {
    value: 'a.',
    prefix: '',
    suffix: '.',
  },
];

const ITEMS = {
  name: {
    get: (contract) => Contract.getName(contract),
    set: (contract, value, allValues) => (contract.name = value),
  },
  description: {
    get: (contract) => Contract.getDescription(contract),
    set: (contract, value, allValues) => (contract.description = value),
  },
  shortName: {
    get: (contract) => Contract.getShortName(contract),
    set: (contract, value) => (contract.data.outline.shortName = value),
  },
  idName: {
    get: (contract) => Contract.getOutline(contract).id_name,
    set: (contract, value) => (contract.data.outline.id_name = value),
  },
  contract_labels: {
    get: (contract) => Contract.getLabels(contract),
    set: (contract, value, allValues) => (contract.data.outline.labels = value),
  },
  headerLevelStartsAsText: {
    get: (contract) => Contract.getHeaderLevelStartsAsText(contract) || null,
    set: (contract, value, allValues) => {
      if (!contract.data.settings.format.headers) contract.data.settings.format.headers = {}
      contract.data.settings.format.headers.headerLevelStartsAsText = value
    }
  },
  fontFace: {
    get: (contract) => Contract.getFormat(contract).fontFace || defaultFont,
    set: (contract, value, allValues) => (contract.data.settings.format.fontFace = value),
  },
  fontSize: {
    get: (contract) => Contract.getFormat(contract).fontSize || 12,
    set: (contract, value, allValues) => (contract.data.settings.format.fontSize = value),
  },
  pageNumbers: {
    get: (contract) => Contract.getFormat(contract).pageNumbers || true,
    set: (contract, value, allValues) => (contract.data.settings.format.pageNumbers = value),
  },
  listFormat: {
    get: (contract) => {
      const format = Contract.getFormat(contract).listFormat;
      const lf = listFormats.find((lf) => lf.prefix === format.prefix && lf.suffix === format.suffix);
      if (lf) return lf.value;
      return 'custom';
    },
  },
  listFormatStyles: {
    get: (contract) => {
      const styles = Contract.getFormat(contract).listFormat.formats;
      if (styles.length < 5) {
        for (let i = 0; i < 5 - styles.length; i += 1) {
          styles.push({ format: false });
        }
      }
      return styles.map((style, idx) => (
        {
          format: style.format || 'decimal',
        }
      ));
    },
  },
  listFormatPrefix: {
    get: (contract) => Contract.getFormat(contract).listFormat.prefix || '',
    set: (contract, value, allValues) => (contract.data.settings.format.listFormat.prefix = value),
  },
  listFormatSuffix: {
    get: (contract) => Contract.getFormat(contract).listFormat.suffix || '',
    set: (contract, value, allValues) => (contract.data.settings.format.listFormat.suffix = value),
  },

  noHeaderCounting: {
    get: (contract) => Contract.getFormat(contract).noHeaderCounting || false,
    set: (contract, value, allValues) => (contract.data.settings.format.noHeaderCounting = value),
  },
};

export default function EditTemplateSettings({ getContractRef }) {
  const contract = useContract();
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const formatMessage = useIntlMessage();
  const listTypeExamples = {
    decimal: '1',
    'lower-alpha': 'a',
    'upper-alpha': 'A',
    'lower-roman': 'i',
    'upper-roman': 'I',
  };

  const [setupState, setSetupState] = useState(Contract.getCreate(contract).setup);
  const [conceptStates, setConceptStates] = useState({
    conceptGrammar: contract.data.create.build.conceptGrammar
      ? JSON.parse(JSON.stringify(contract.data.create.build.conceptGrammar))
      : {},
    concepts: JSON.parse(JSON.stringify(Contract.getConcepts(contract))),
  });

  const initialValues = useMemo(() => {
    const values = {};
    for (const item in ITEMS) {
      if (!ITEMS[item].get) return;
      values[item] = ITEMS[item].get(contract);
    }
    return values;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [listFormatType, setListFormatType] = useState(initialValues.listFormat);
  const [listFormatStyles, setListFormatStyles] = useState(initialValues.listFormatStyles);
  const [generalAdvanced, setGeneralAdvanced] = useState(false);
  const [prefix, setPrefix] = useState(initialValues.listFormatPrefix);
  const [suffix, setSuffix] = useState(initialValues.listFormatSuffix);

  // Function to collect all form values into
  // a new contract/template. Using immer for immutability.
  const getUpdatedContract = useCallback(() => {
    const draftContract = createDraft(contract);
    const formValues = formRef.current.getFieldsValue();
    for (const [key, value] of Object.entries(formValues)) {
      if (ITEMS[key].set) {
        ITEMS[key].set(draftContract, value, formValues);
      }
    }

    // Set custom properties not received through form values
    draftContract.data.settings.format.listFormat.formats = listFormatStyles;

    draftContract.data.create.build.concepts = conceptStates.concepts;
    draftContract.data.create.build.conceptGrammar = conceptStates.conceptGrammar;
    draftContract.data.create.setup = setupState;

    return finishDraft(draftContract);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef.current, contract, setupState, conceptStates]);

  useEffect(() => {
    // Set our get updated contract function to the ref received
    // from the component above.
    getContractRef.current = getUpdatedContract;
  }, [getContractRef, getUpdatedContract]);

  const onListFormatChange = (value) => {
    setListFormatType(value);

    if (value !== 'custom') {
      const lf = listFormats.find((lf) => lf.value === value);
      form.setFieldsValue({
        listFormatPrefix: lf.prefix,
        listFormatSuffix: lf.suffix,
      });
      setPrefix(lf.prefix);
      setSuffix(lf.suffix);
    }
  };

  const onListFormatStyleChange = (idx, value) => {
    listFormatStyles[idx].format = value;
    setListFormatStyles([...listFormatStyles]);
  };

  const listLevelLabel = (idx) => {
    return `${formatMessage('studio.sidebar.settings.generalSettings.listItemStyle.level')} ${idx + 1}`;
  };

  const renderList = (idx) => {
    const style = listFormatStyles[idx];

    let innerList = null;
    if (idx < listFormatStyles.length - 1) {
      innerList = renderList(idx + 1);
    }

    const listValue = `${prefix}${listTypeExamples[style.format]}${suffix} ${listLevelLabel(idx)}`;

    const ul = (
      <ol style={{ listStyleType: "none" }}>
        <li>{listValue}</li>
        {innerList}
      </ol>
    );

    return ul;
  };

  // Hide custom prefix/suffix if pre-defined format
  const listFormatStyle = listFormatType === 'custom' ? {} : { display: 'none' };

  return (
    <>
      <div className="settings mt-2 p-1">
        <Form
          form={form}
          initialValues={initialValues}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 20 }}
          ref={formRef}
        >
          <Collapse
            defaultActiveKey={['1']}
            className="w-100 no-end-border-radius"
            style={{
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0',
            }}
          >
            <Panel header={<IntlMessages id="studio.sidebar.settings.generalSettings.general" />} key="1">
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.name" />}
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.description" />}
                name="description"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.tags" />}
                name="contract_labels"
              >
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder={<IntlMessages id="studio.sidebar.settings.generalSettings.tags.placeHolder" />}
                ></Select>
              </Form.Item>
              <Collapse className="w-100">
                <Panel header="Advanced options">
                  <Form.Item label="Short name" name="shortName" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Id name" name="idName" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Panel>
              </Collapse>
              {generalAdvanced && (
                <Form.Item
                  label={<IntlMessages id="studio.sidebar.settings.generalSettings.description" />}
                  name="description"
                >
                  <Input />
                </Form.Item>
              )}
            </Panel>
            <Panel header={<IntlMessages id="studio.sidebar.settings.generalSettings.headings" />} key="2">
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.headerLevel" />}
                name="headerLevelStartsAsText"
                tooltip={{
                  title: formatMessage('studio.sidebar.settings.generalSettings.headerLevel.tooltip'),
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Select>
                  <Option value={null}>
                    <IntlMessages id="studio.sidebar.settings.generalSettings.headerLevel.no" />
                  </Option>
                  <Option value={1}>
                    <IntlMessages id="studio.sidebar.settings.generalSettings.headerLevel.level" /> 1
                  </Option>
                  <Option value={2}>
                    <IntlMessages id="studio.sidebar.settings.generalSettings.headerLevel.level" /> 2
                  </Option>
                  <Option value={3}>
                    <IntlMessages id="studio.sidebar.settings.generalSettings.headerLevel.level" /> 3
                  </Option>
                  <Option value={4}>
                    <IntlMessages id="studio.sidebar.settings.generalSettings.headerLevel.level" /> 4
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.ignoreHeadingCount" />}
                name="noHeaderCounting"
                tooltip={{
                  title: formatMessage('studio.sidebar.settings.generalSettings.ignoreHeadingCount'),
                  icon: <InfoCircleOutlined />,
                }}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Panel>
            <Panel header={<IntlMessages id="studio.sidebar.settings.generalSettings.formats" />} key="3">
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.fontFace" />}
                name="fontFace"
              >
                <Select>
                  {Object.keys(fontFamilies).map((ff, index) => (
                    <Option key={'ff' + index} value={ff}>
                      {ff}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.fontSize" />}
                name="fontSize"
              >
                <Select>
                  {fontSizes.map((size, index) => (
                    <Option key={'fs' + index} value={size}>
                      {size}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.pageNumbers" />}
                name="pageNumbers"
                valuePropName="checked"
                tooltip={{
                  title: formatMessage('studio.sidebar.settings.generalSettings.pageNumbers.tooltip'),
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.listItemFormat" />}
                name="listFormat"
              >
                <Select onChange={onListFormatChange}>
                  {listFormats.map((format) => (
                    <Option key={`lif_${format.value}`} value={format.value}>
                      {format.value}
                    </Option>
                  ))}
                  <Option value="custom" key="lif_custom">
                    <IntlMessages id="studio.sidebar.settings.generalSettings.listItemFormat.custom" />
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.listItemPrefix" />}
                name="listFormatPrefix"
                rules={[{ max: 1 }]}
                style={listFormatStyle}
              >
                <Input style={{ width: '40px' }} onChange={(e) => setPrefix(e.target.value)} />
              </Form.Item>
              <Form.Item
                label={<IntlMessages id="studio.sidebar.settings.generalSettings.listItemSuffix" />}
                name="listFormatSuffix"
                rules={[{ max: 1 }]}
                style={listFormatStyle}
              >
                <Input style={{ width: '40px' }} onChange={(e) => setSuffix(e.target.value)} />
              </Form.Item>
              <br />

              <Row>
                <label>
                  <span>
                    <IntlMessages id="studio.sidebar.settings.generalSettings.listItemStyle.header" />
                  </span>
                </label>
              </Row>
              <Row>
                <Col span={6}>
                  {listFormatStyles.map((style, idx) => {
                    return (
                      <Form.Item
                        initialValue={style.format}
                        label={listLevelLabel(idx)}
                        wrapperCol={{ span: 36 }}
                        labelCol={{ span: 8 }}
                        key={`listFormatStyle_${idx}`}
                      >
                        <Select
                          defaultValue={style.format.toString()}
                          style={{ width: '170px' }}
                          onChange={(value) => onListFormatStyleChange(idx, value)}
                        >
                          {LIST_STYLE_TYPES.map((style, styleIdx) => (
                            <Option value={style} key={`lf_${styleIdx}`}>
                              {style}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  })}
                </Col>
                <Col>
                  <Card>
                    <div style={{ paddingRight: '30px' }}>
                      {renderList(0)}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Form>
        <div
          style={{
            marginTop: '-1px',
            marginLeft: '-8px',
            marginRight: '-8px',
          }}
        >
          <Collapse
            defaultActiveKey={[]}
            className="w-100"
            style={{
              borderTopLeftRadius: '0',
              borderTopRightRadius: '0',
            }}
          >
            <Panel header={<IntlMessages id="studio.sidebar.settings.generalSettings.concepts" />} key="1">
              <Concepts
                contract={contract}
                conceptStates={conceptStates}
                setConceptStates={setConceptStates}
              />
            </Panel>
            <Panel header={<IntlMessages id="studio.sidebar.settings.generalSettings.setupFields" />} key="2">
              <Setup contract={contract} setupState={setupState} setSetupState={setSetupState} />
            </Panel>
          </Collapse>
        </div>
      </div>
    </>
  );
}
