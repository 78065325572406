import { ReactEditor } from 'slate-react';
import { Transforms, Node, Range } from 'slate';
import { find } from 'core/engine/utils';

// Overriding default editor.insertText which distorts when on the edge of inlines
export const insertText = (editor) => (text) => {
  const { marks } = editor;

  // onKeyDown function sets a tmp._nextRange if it wants to force the next
  // text to be at a particular point in relation to an inline. Since slate
  // at the `beforeInput` event sometimes sets a range based on the DOM,
  // force the selection back to where it is supposed to be.
  if (!!editor.tmp._nextRange) {
    if (!Range.equals(editor.selection, editor.tmp._nextRange)) {
      console.log('yes adjust');
      const nr = JSON.parse(JSON.stringify(editor.tmp._nextRange));
      const sel = JSON.parse(JSON.stringify(editor.selection));
      console.log('Insert at ', {
        nr,
        sel,
      });
      Transforms.setSelection(editor, editor.tmp._nextRange);
    }
    editor.tmp._nextRange = null;
  }

  if (marks) {
    const node = { text, ...marks };
    Transforms.insertNodes(editor, node);
  } else {
    Transforms.insertText(editor, text);
  }

  editor.marks = null;
};

const editorElement = function () {
  const editorElem = document.getElementById('editor-holder');
  if (!editorElem) return null;
  return editorElem.parentElement;
};

export const scrollToNodeOrDomNode = (editor) => (node, opts = {}) => {
  let editorHolderDom = editorElement(),
    domNode,
    time = 2500;
  if (!editorHolderDom) return console.log('Could not find domNode for editor');

  if (!opts.isDomNode) {
    try {
      domNode = ReactEditor.toDOMNode(editor, node);
    } catch (err) {
      return console.log('Could not find domNode for node');
    }
    if (!domNode) return;
  } else {
    domNode = node;
  }

  let offset = -200;
  let parentNode = domNode;

  while (parentNode !== editorHolderDom && parentNode.offsetParent) {
    offset += parentNode.offsetTop;
    parentNode = parentNode.offsetParent;
  }

  editorHolderDom.parentElement.scrollTop = offset - 50;

  if (!domNode.classList.contains('highlighted-node')) {
    domNode.classList.add('highlight-node');
    setTimeout(() => {
      domNode.classList.remove('highlight-node');
      domNode.classList.add('highlight-node-out');
    }, time);
    setTimeout(() => {
      domNode.classList.remove('highlight-node-out');
    }, time * 2);
  } else {
    /* 
		domNode.classList.add('highlight-highlighted-node')
		setTimeout(() => {
			domNode.classList.remove('highlight-highlighted-node')
		}, 2000)
 		*/
  }

  return true;
};

export const scrollToPath = (editor) => (path) => {
  try {
    const node = Node.get(editor, path);
    if (!node) return console.log('No node found.');
    editor.scrollToNode(node);
  } catch (err) {
    return console.log('Could find node at ', path);
  }
};

export const scrollToNode = (editor) => (node, opts = {}) => {
  let editorHolderDom, domNode;
  try {
    editorHolderDom = ReactEditor.toDOMNode(editor, editor);
    if (!editorHolderDom) return;
    editorHolderDom = editorHolderDom.parentElement;
  } catch (err) {
    return console.log('Could not find domNode for editor');
  }

  if (!opts.isDomNode) {
    try {
      domNode = ReactEditor.toDOMNode(editor, node);
    } catch (err) {
      return console.log('Could not find domNode for node');
    }
    if (!domNode) return;
  }

  let offset = -100;
  let parentNode = domNode;
  /* console.log('editorHolderDom is ', editorHolderDom)
  console.log('parent Node is ', parentNode) */
  while (parentNode && parentNode !== editorHolderDom && parentNode.parentElement) {
    offset += parentNode.offsetTop;
    parentNode = parentNode.parentElement;
  }
  /* 
  if(parentNode.classList.contains("editor-container") || parentNode.classList.contains("top-editor-holder"))
    parentNode = parentNode.parentElement.parentElement
 */

  parentNode = window.document.getElementsByClassName('top-editor-holder')[0].parentElement;

  // console.log("Parent elem is ", parentNode);
  parentNode.scrollTop = offset - 50;

  if (!opts.highlight) return true;

  const time = opts.time || 2500;

  if (!domNode.classList.contains('highlighted-node')) {
    domNode.classList.add('highlight-node');
    setTimeout(() => {
      domNode.classList.remove('highlight-node');
      domNode.classList.add('highlight-node-out');
    }, time);
    setTimeout(() => {
      domNode.classList.remove('highlight-node-out');
    }, time * 2);
  } else {
    domNode.classList.add('highlight-highlighted-node');
    setTimeout(() => {
      domNode.classList.remove('highlight-highlighted-node');
    }, 2000);
  }

  return true;
};

export const scrollToItemId = (editor) => (id, opts = {}) => {
  const node = find(editor.children, (n) => n?.data?.item_id === id);
  if (node.length === 1) return editor.scrollToNode(node[0], opts);
  console.log('No node found for item id ', id, node);
};
export const scrollToTemplateId = (editor) => (id, opts = {}) => {
  const node = find(editor.children, (n) => n?.data?.template_id === id);
  if (node.length === 1) return editor.scrollToNode(node[0], opts);
  console.log('No node found for template item id ', id);
};
export const scrollToDataMatch = (editor) => (key, value, opts = {}) => {
  if (!key || value === undefined) return;
  const node = find(editor.children, (n) => n?.data && n.data[key] === value);
  if (node.length === 1) return editor.scrollToNode(node[0], opts);
  console.log('No node found for data match ', { key, value });
};

export const clearHighlights = (editor) => () => {
  Array.prototype.filter.call(document.getElementsByClassName('highlight-node'), function (elem) {
    elem.classList.remove('highlight-node');
  });
};

export const highlightPath = (editor) => (path) => {
  try {
    const node = Node.get(editor, path);
    if (!node) return console.log('No node found.');
    editor.highlightNode(node);
  } catch (err) {
    return console.log('Could find node at ', path);
  }
};

export const highlightNode = (editor) => (node, auto = false, preventInitialClear = false) => {
  if (!preventInitialClear) clearHighlights();

  const time = 2500;
  let editorHolderDom;
  try {
    editorHolderDom = ReactEditor.toDOMNode(editor, editor);
  } catch (err) {}
  if (!editorHolderDom) return console.log('Cannot locate editor dom node');

  let domNode;
  try {
    domNode = ReactEditor.toDOMNode(editor, node);
  } catch (err) {
    console.log('Could not find domNode for node');
  }
  if (!domNode) return;

  let offset = -200;
  let parentNode = domNode;

  while (!parentNode.classList.value.includes('scrollbar-container') && parentNode.offsetParent) {
    offset += parentNode.offsetTop;
    parentNode = parentNode.offsetParent;
  }

  editorHolderDom.scrollTop = offset - 50;

  domNode.classList.add('highlight-node');

  if (auto) {
    setTimeout(() => {
      domNode.classList.remove('highlight-node');
      domNode.classList.add('highlight-node-out');
    }, time);
    setTimeout(() => {
      domNode.classList.remove('highlight-node-out');
    }, time * 2);
  }
};
