import Engine from './draft/engine';
// import TemplateEngine from "./draft/templateEngine";

import updateStudioDrafts from 'utils/saving/updateStudioDrafts';
// import { default as newVersionApi } from "utils/saving/newVersion";

import { Contract } from '../interfaces';
import { Transforms } from '../../import/slate';
import { setContractWasUpdated } from 'hooks';

const isFrontend = true;

function Manager(options) {
  this.config = {
    showUpdateType: 'all',
  };
  this.drafts = [];
  this.activeDraft = 0;
  this.onChanges = {};

  this.addDraft = function (contract, options = {}) {
    if (typeof contract !== 'object') return false;
    const { isLoaded = false, language = 'en' } = options;

    const id = Contract.getId(contract);

    const instance = new Engine(contract, options);
    // window.engine = instance
    const draft = {
      id,
      data: {},
      contract,
      instance,
      loaded: isLoaded,
      language,
      isDrafting: false,
      preventUpdate: false,
      withDraftMethod: (...args) => this.forwardDraftMethod(instance, ...args),
    };
    draft.save = () => updateStudioDrafts(draft);
    this.drafts.push(draft);
  };
  this.addDrafts = function (contracts) {
    if (!Array.isArray(contracts)) return false;
    for (const contract of contracts) this.addDraft(contract);
    return this.drafts;
  };
  this.setDrafts = function (contracts) {
    this.drafts = [];
    this.addDrafts(contracts);
  };
  this.loadDrafts = function (contracts) {
    if (!Array.isArray(contracts)) return false;
    for (const contract of contracts) this.addDraft(contract, { isLoaded: true });
    this.loadedChangesMade = false;
    return this.drafts;
  };

  this.addTemplateDraft = function (template, options = {}) {
    /*
    if (typeof template !== "object") return false;
    const { isLoaded = false, language = "en" } = options;

    console.log("Manager add template: ", template, isLoaded);
    const id = Contract.getId(template);
    this.drafts.push({
      id,
      data: {},
      instance: new TemplateEngine(template, this, options),
      loaded: isLoaded,
      isTemplate: true,
    }); */
    if (typeof template !== 'object') return false;
    const { isLoaded = false, language = 'en' } = options;

    const id = Contract.getId(template);

    const instance = new Engine(template, options);
    const draft = {
      id,
      data: {},
      contract: template,
      instance,
      loaded: isLoaded,
      language,
      isDrafting: false,
      isTemplate: true,
      preventUpdate: false,
      withDraftMethod: (...args) => this.forwardDraftMethod(instance, ...args),
    };
    draft.save = async () => {
      await updateStudioDrafts(draft);
      return true
    }
    this.drafts.push(draft);
  };
  this.addTemplateDrafts = function (templates) {
    if (!Array.isArray(templates)) return false;
    for (const template of templates) this.addTemplateDraft(template);
    return this.drafts;
  };
  this.setTemplateDrafts = function (templates) {
    this.drafts = [];
    this.addTemplateDrafts(templates);
  };
  this.loadTemplateDrafts = function (templates) {
    if (!Array.isArray(templates)) return false;
    for (const template of templates) this.addTemplateDraft(template, { isLoaded: true });
    this.loadedChangesMade = false;
    return this.drafts;
  };

  this.findDraftByInstance = function (instance) {
    return this.drafts.find((draft) => draft.instance === instance);
  };
  this.findDraftById = function (id) {
    return this.drafts.find((draft) => draft.id === id);
  };

  this.getDraftByNameId = function (id) {
    return this.drafts.find((c) => c.data && c.data.outline && c.data.outline.id_name === id);
  };

  this.databaseStoreAllDrafts = function () {
    for (const draft of this.drafts) draft.save();
  };

  this.clearDrafts = function () {
    this.drafts = [];
    this.template = null;
  };

  this.editUpdateMade = function () {
    this.checkFirstLoadedChange();
  };

  this.checkFirstLoadedChange = function () {
    if (!manager.drafts || manager.drafts.length === 0) return;
    if (typeof setContractWasUpdated.fn !== 'function')
      return console.warn('setContractWasUpdated.fn is not a function');
    setContractWasUpdated.fn();
    // Determine if loaded, and first change is made
    if (this.hasOwnProperty('loadedChangesMade') && this.loadedChangesMade === false) {
      console.log('first change made.');
      this.loadedChangesMade = true;
    }
  };

  this.invokeEngineMethod = function (draft, method, ...args) {
    return Promise.resolve(draft.instance.invokeEngineMethod(method, draft.contract, ...args));
  };

  this.updateAction = function (action, state, isSagaAction) {
    if (!action || !state) return;
    for (const draft of this.drafts) {
      if (typeof draft.instance.updateAction === 'function') {
        draft.instance.updateAction(action, state, isSagaAction);
      }
    }
  };

  this.draftSession = function (state) {
    this.beginDraft(state);
    this.finishDraft(state);
  };

  this.preventUpdate = function (contractId, value) {
    const draft = this.findDraftById(contractId);
    if (!draft) return;
    draft.preventUpdate = value;
    console.log('Now preventing draft update: ', draft.preventUpdate);
  };

  this.beginDraft = function (options = {}) {
    for (const draft of this.drafts) {
      if (draft.isDrafting) continue;
      if (options.origin === 'redux' && draft.preventUpdate) {
        continue;
      }
      if (draft.editor) {
        // Ensure the selection is removed on the relevant editor,
        // to prevent any slate selection errors after update.
        Transforms.forceDeselect(draft.editor);
      }
      draft.isDrafting = true;
      if (typeof draft.instance.beginDraft === 'function')
        draft.instance.beginDraft({ contract: draft.contract });
    }
  };

  this.forwardDraftMethod = function (instance, method, ...args) {
    const draft = this.findDraftByInstance(instance);

    const result = draft.instance.forwardDraftMethod(method, draft.contract, ...args);

    if (result && result.content) {
      this.draftWasUpdated(draft, result);
    }
  };

  this.finishDraft = function (state, options = {}) {
    for (const draft of this.drafts) {
      if (options.origin === 'redux' && draft.preventUpdate) {
        continue;
      }
      if (typeof draft.instance.finishDraft === 'function') {
        const result = draft.instance.finishDraft(state);

        if (result && result.content) {
          draft.isDrafting = false;
          this.draftWasUpdated(draft, result);
        }
      }
    }
  };

  this.draftWasUpdated = function (draft, result) {
    const { content, draftTime, draftInfo } = result;
    Contract.setContent(draft.contract, content);
    draft._draftTime = draftTime;
    Contract.setDraftInfo(draft.contract, draftInfo)

    if (isFrontend) {
      if (typeof draft.editorOnChange === 'function') draft.editorOnChange(content);
      if (typeof draft.highlight === 'function') draft.highlight(content);
    }
  };

  this.setEditor = (id, onChange, editor) => {
    const draft = this.findDraftById(id);
    if (!draft) return;
    draft.editorOnChange = onChange;
    draft.editor = editor;
  };
  this.setHighlight = (id, highlight) => {
    const draft = this.findDraftById(id);
    if (!draft) return;
    draft.highlight = highlight;
  };

  this.loadEngines = async function (state, opts = {}) {
    for (const draft of this.drafts) {
      if (typeof draft.instance.load === 'function') {
        const loadResult = await draft.instance.load(state, opts);
        this.draftWasUpdated(draft, loadResult);
      }
    }
    return true;
  };

  this.genesisEngines = async function (state, opts = {}) {
    for (const draft of this.drafts) {
      if (draft.loaded) continue; // If already loaded, do not have the draft load values from state

      if (typeof draft.instance.genesis === 'function') {
        const genesisResult = await draft.instance.genesis(state, opts);
        console.log('genesisResult', genesisResult)
        this.draftWasUpdated(draft, genesisResult);
      }

      // console.log('Updating api with draft...', draft.instance.contract.data.content)
      await updateStudioDrafts(draft, {
        notify: false,
        isDraft: true,
        isTemplate: draft.isTemplate,
      });
    }
    return true;
  };

  /*******/

  this.navigateTo = function (opts) {
    if (!opts || this.drafts.length === 0) return;

    const {
      // contractNameId,
      template_id,
      each_label_id,
    } = opts;

    /* const draft = contractNameId
      ? this.getDraftByNameId(contractNameId)
      : this.getActiveDraft(); */
    const draft = this.getActiveDraft();

    if (!draft || !draft.editor) return;

    if (template_id)
      draft.editor.scrollToTemplateId(template_id, {
        highlight: true,
      });
    else if (each_label_id)
      draft.editor.scrollToDataMatch('_each_label_id', each_label_id, {
        highlight: true,
      });
  };

  this.getActiveDraft = function () {
    return this.drafts[this.activeDraft];
  };

  /********/
}

const manager = new Manager();
export default manager;
