import { DocumentTemplate } from './DocumentTemplate';
import { Concept } from './Concept';
import { InputPaths } from './InputPaths';
import contractDefaults from '../config/contractDefaults';
import { ofilter, omap, getByPath } from '../utils/general';
// import manager from '../engine/manager';
import { VALUE_INPUTS } from '../config/constants';

const Contract = {};

Contract.isContract = (contract) =>
  contract.id && contract.data && contract.data._draftInfo && contract.documentTemplateId;

Contract.setName = (contract, name) => (contract.name = name);
Contract.setDescription = (contract, description) => (contract.description = description);

Contract.getId = (contract) => contract && contract.id;

Contract.getUi = (contract) => contract && contract.data && contract.data.ui;
Contract.getUiRoutes = (contract) => contract && contract.data && contract.data.ui && contract.data.ui.routes;
Contract.getUiPages = (contract) => Contract.getUi(contract) && Contract.getUi(contract).pages;
Contract.getUiCards = (contract) => Contract.getUi(contract) && Contract.getUi(contract).cards;
Contract.getUiCard = (contract, cardId) =>
  Contract.getUiCards(contract) && Contract.getUiCards(contract)[cardId];
Contract.getUiInputs = (contract) => (Contract.getUi(contract) && Contract.getUi(contract).inputs) || {};
Contract.getUiInput = (contract, fieldName) => Contract.getUiInputs(contract)[fieldName];

Contract.getUiInputFieldDataByPath = (contract, path) => {
  const { cardId, fieldName } = InputPaths.getCardAndField(contract, path);
  if (!cardId || !fieldName) return;
  return Contract.getUiInputFieldData(contract, fieldName);
};
Contract.getUiInputFieldValueByPath = (contract, path, value, options = {}) => {
  const { cardId, fieldName } = InputPaths.getCardAndField(contract, path);
  if (!cardId || !fieldName) return value;
  return Contract.getUiInputFieldValue(contract, fieldName, value, options);
};

Contract.getUiInputFieldData = (contract, fieldName) => {
  let uiInput = Contract.getUiInput(contract, fieldName);
  if (uiInput) return uiInput;
};

Contract.getUiInputFieldValue = (contract, fieldName, value, options = {}) => {
  if (Array.isArray(value)) return value;
  const inputFieldData = Contract.getUiInputFieldData(contract, fieldName);

  const language = Contract.getLanguage(contract);
  if (!inputFieldData || !Array.isArray(inputFieldData.content)) return value;

  const { strict = false } = options;

  const contentItem = inputFieldData.content.find((c) => c.id === value);
  if (!contentItem || !contentItem.values) return strict ? undefined : value;
  if (contentItem.values.hasOwnProperty(language)) {
    return contentItem.values[language];
  }
  return strict ? undefined : value;
};

Contract.getUiIsCardRepeatable = (contract, cardId) => {
  const draftInfo = Contract.getDraftInfo(contract);
  if (Array.isArray(draftInfo.repeatableCardNames) && draftInfo.repeatableCardNames.includes(cardId)) {
    return true;
  }
  return Contract.getUiCard(contract, cardId) && Contract.getUiCard(contract, cardId).isRepeatable;
};
Contract.getUiRepeatableCards = (contract) => ofilter(Contract.getUiCards(contract), (c) => c.isRepeatable);
Contract.getUiRepeatableCardNames = (contract) => Object.keys(Contract.getUiRepeatableCards(contract));
Contract.getRepeatableInfo = (contract, cardId) => {
  return Contract.getUiCard(contract, cardId) && Contract.getUiCard(contract, cardId).repeatableInfo;
};

Contract.getRepeatableValuesByPath = (contract, path) => {
  const cards = Contract.getUiCards(contract);
  if (!cards) return { repeatable: null, values: null, path: null };

  const pathParts = path.split('.');
  const repeatablePath = [];

  if (pathParts[0] === 'input') {
    pathParts.shift();
    repeatablePath.push('input');
  }
  let match;
  for (const pathPart of pathParts) {
    repeatablePath.push(pathPart);
    if (cards[pathPart] && Contract.getUiIsCardRepeatable(contract, pathPart) && cards[pathPart].values) {
      // console.log('Got match for ', {pathPart})
      match = {
        repeatable: pathPart,
        values: cards[pathPart].values,
        path: repeatablePath.join('.'),
      };
    }
  }

  if (match) {
    // console.log('Match is ', match)
    return match;
  }
  return { repeatable: null, values: null, path: null };
};

Contract.getPagesForCard = (contractOrPages, cardId, parentPages = [], nested = false) => {
  const pages = nested ? contractOrPages : Contract.getUiPages(contractOrPages);
  for (const pageName in pages) {
    const page = pages[pageName];
    if (page.cards.includes(cardId)) return [...parentPages, page];
    if (page.pages) {
      const match = Contract.getPagesForCard(page.pages, cardId, [...parentPages, page], true);
      if (match) return match;
    }
  }
};

Contract.getCardsWithState = (contract) => {
  return omap(
    ofilter(Contract.getUiCards(contract), (card) => {
      if (card.special) return false;
      if (!card.inputs_order || card.inputs_order.length === 0) return false;
      const inputs = card.inputs_order;
      return inputs.some((fieldName) => VALUE_INPUTS.includes(Contract.getUiInput(contract, fieldName).type));
    }),
    (card) => {
      return { ...card, _pages: Contract.getPagesForCard(contract, card.id) };
    }
  );
};

Contract.getDraftInfo = (contract) => (contract && contract.data && contract.data._draftInfo) || {};
Contract.setDraftInfo = (contract, values = {}) => {
  contract.data._draftInfo = values;
};
Contract.addDraftInfo = (contract, values = {}) => {
  contract.data._draftInfo = { ...Contract.getDraftInfo(contract), ...values };
};
Contract.setDraftInfoItem = (contract, key, value) => {
  contract.data._draftInfo[key] = value;
};
Contract.getSetup = (contract) => Contract.getDraftInfo(contract) && Contract.getDraftInfo(contract).setup;
Contract.setSetup = (contract, value) => {
  contract.data._draftInfo.setup = value;
};

Contract.getVariable = (contract, key) =>
  Contract.getDraftInfo(contract).variables && Contract.getDraftInfo(contract).variables.hasOwnProperty(key)
    ? Contract.getDraftInfo(contract).variables[key]
    : null;

// getLanguage differs from DocumentTemplate.getLanguage
Contract.getLanguage = (contract) => {
  let language = Contract.getDraftInfo(contract) && Contract.getDraftInfo(contract).language;
  if (!language) {
    language = Contract.getInfo(contract).language;
  }
  return language || DocumentTemplate.getLanguage(contract);
};

Contract.getFullState = (contract) => contract && contract.data && contract.data._state;
Contract.setFullState = (contract, state) => {
  contract.data._state = state;
};
Contract.getInputState = (contract) =>
  contract && contract.data && contract.data._state && contract.data._state.input;

Contract.getInput = (contract, path) => {
  const inputState = Contract.getInputState(contract)
  if (!inputState) return null
  return getByPath(inputState, path)
}

Contract.getRules = (contract) => DocumentTemplate.getCreate(contract).savedRules || {};
Contract.getRule = (contract, id) => {
  const rules = DocumentTemplate.getCreate(contract).savedRules;
  return rules ? rules[id] : null;
};
Contract.getRuleData = (contract, id) => {
  const rule = Contract.getRule(contract, id);
  return rule ? rule.data : null;
};

// getSetupp differs from DocumentTemplate.getSetup.
// this is the actual, populated setup
Contract.getSetup = (contract) =>
  (Contract.getDraftInfo(contract) && Contract.getDraftInfo(contract).setup) || {};

Contract.getComments = (contract) =>
  (Contract.getCreate(contract) && Contract.getCreate(contract).comments) || {};
Contract.getComment = (contract, id) => Contract.getComments(contract)[id];

Contract.getNumberText = (contract) => {
  const format = DocumentTemplate.getFormat(contract);
  const language = Contract.getLanguage(contract);
  if (!format || !language) return;
  return format.numberText && format.numberText[language];
};
Contract.getHeaderLevelStartsAsText = (contract) => {
  const format = DocumentTemplate.getFormat(contract);
  if (!format) return;
  if (!format.headers || typeof format.headers.headerLevelStartsAsText !== 'number') return;
  return format.headers.headerLevelStartsAsText;
};
Contract.setHeaderLevelStartsAsText = (contract, value) => {
  let format = DocumentTemplate.getFormat(contract);
  if (!format) {
    contract.data.settings.format = {};
    format = contract.data.settings.format;
  }
  let headers = format.headers;
  if (!headers) {
    contract.data.settings.format.headers = {
      sizes: { 1: 130, 2: 100, 3: 100, 4: 100 },
      headerLevelStartsAsText: null,
    };
    headers = contract.data.settings.format.headers;
  }
  contract.data.settings.format.headers.headerLevelStartsAsText = value;
};

Contract.getContractParties = (contract, suppliedState) => {
  const concepts = DocumentTemplate.getConcepts(contract);
  const contractState = suppliedState ? suppliedState : Contract.getFullState(contract);

  if (!concepts || !contractState) return [];

  const contractPartyConcepts = concepts.filter((concept) => concept.contractParty);

  return contractPartyConcepts
    .map((concept) => {
      return Concept.getConceptEntities(concept, contract, contractState);
    })
    .flat();
};

// Specific contract data points
Contract.getSeals = (contract) => {
  const settings = Contract.getSettings(contract);
  return settings.seals || {};
};
Contract.getApprovalAccessLevel = (contract) => {
  const seals = Contract.getSeals(contract);
  return (
    (seals.approval && seals.approval.resourceLevel) ||
    (contractDefaults.seals && contractDefaults.seals.resourceLevel)
  );
};
Contract.getApprovalRequiredNumber = (contract) => {
  const seals = Contract.getSeals(contract);
  return (seals.approval && seals.approval.requiredNumber) || contractDefaults.requiredNumber;
};

Contract._standardLive = () => ({
  ...Contract.standardApplyFields(),
  ...Contract.standardRelationsFields(),
  ...Contract.standardStateFields(),
});
Contract.standardApplyFields = () => ({
  applied: {
    dates: {
      base: {},
      values: {},
    },
    events: {},
    provisions: {},
    _meta: { updated: 0 },
  },
});

Contract.standardRelationsFields = () => ({
  relations: {
    dates: {},
  },
});

Contract.standardStateFields = () => ({
  state: {},
});

Contract.dbStoreData = (contract, state) => {
  return {
    ...contract.data,
    _state: state,
  };
};

// Inherit from DocumentTemplate
[
  'getName',
  'getDescription',
  'getInfo',
  'getData',
  'getContent',
  'getCreate',
  'getOutline',
  'getSettings',
  'setSetting',
  'getType',
  'getIdName',
  'getLabels',
  'getShortName',
  'getFormat',
  'setFormat',
  'getBuild',
  'getModules',
  // "getSetup",
  'getConcepts',
  'getConcept',
  'getConceptGrammar',
  'getConceptVariations',
  'getMapRepeatablesByConcepts',
  'getRepeatableActivation',
  'getRepeatablesNumberBindings',
  'getInputBindings',
  'getSmartDefinitions',
  'getSmartReplacements',
  'getAnalysisInstructions',
  'getAnalysisModules',
  'setContent',
].forEach((method) => (Contract[method] = DocumentTemplate[method]));

export { Contract };
