import jsonLogic from 'json-logic-js';
import { array_homogenous } from '../general';
import { Contract, Concept } from '../../interfaces';

function getJsonLogicData(path, data, fullState) {
  if (fullState && fullState._meta && fullState._meta.contract) {
    const { contract } = fullState._meta;
    const concept = Contract.getConcept(contract, path);
    if (concept) {
      const conceptState = Concept.getConceptState(concept, contract, fullState);
      return { match: conceptState || {} };
    }
  }

  var not_found = { err: null }; // Removed the possibility to return default value b
  if (typeof data !== 'object' || typeof path === 'undefined' || path === '' || path === null) {
    return { err: data };
  }
  var sub_props = String(path).split('.');
  for (var i = 0; i < sub_props.length; i++) {
    if (data === null) {
      return not_found;
    }
    // Descending into data
    data = data[sub_props[i]];
    if (data === undefined) {
      return not_found;
    }
  }
  if (Array.isArray(data)) return { match: data };
  else if (typeof data === 'object') {
    return {
      match: Object.keys(data)
        .filter((item) => item.substr(0, 7) !== '__data_')
        .reduce((acc, curr) => {
          acc[curr] = data[curr];
          return acc;
        }, {}),
    };
  }
  return { match: data };
}

function getInputConsiderConcept(base, path, options = {}) {
  // const { contract } = base._meta;
  const { _meta, ...state } = base;
  const contract = _meta;

  if (!contract) {
    console.warn('No contract provided to jsonLogicOperations');
    return null;
  }

  const conceptState = Concept.getConceptState(Contract.getConcept(contract, path), contract, state, options);
  if (conceptState) return conceptState;
  else {
    /* if(window?.debug === "full")
      console.trace("NOTE: No conceptState found for: ", { base, path, options }); */
  }

  const { match, err } = getJsonLogicData(path, state.input);

  if (err || !match) {
    /* if(window?.debug)
      console.log("getInputConsiderConcept jsonLogicData err: ", { err, base, path, options }); */
    return null;
  }

  return match;
}

function getObjectDataAsArray(a, data) {
  var not_found = null;
  if (typeof a === 'undefined' || a === '' || a === null) {
    return data;
  }
  var sub_props = String(a).split('.');
  for (var i = 0; i < sub_props.length; i++) {
    if (data === null) {
      return not_found;
    }
    // Descending into data
    data = data[sub_props[i]];
    if (data === undefined) {
      return not_found;
    }
  }
  if (!!data && !Array.isArray(data) && typeof data === 'object') return Object.values(data);
  return data;
}

export function varValues(a, b) {
  var not_found = b === undefined ? null : b;
  var data = this;
  if (typeof a === 'undefined' || a === '' || a === null) {
    return data;
  }
  var sub_props = String(a).split('.');
  for (var i = 0; i < sub_props.length; i++) {
    if (data === null) {
      return not_found;
    }
    // Descending into data
    data = data[sub_props[i]];
    if (data === undefined) {
      return not_found;
    }
  }
  if (!!data && !Array.isArray(data) && typeof data === 'object') return Object.values(data);
  return data;
}

export function firstSet(a, b) {
  const arrayData = getObjectDataAsArray(a, this);
  if (!Array.isArray(arrayData)) return null;
  return arrayData[0];
}
export function firstSetItem(a, b) {
  const arrayData = getObjectDataAsArray(a, this);
  if (!Array.isArray(arrayData)) return null;
  return (arrayData[0] && arrayData[0][b]) || null;
}

/**
 * Custom, additional json logic operations
 *
 */

/* Repatables' state operations */

// {"==":[{"any_repeatable":["facility", "name", "AFacility"]},true]}
export function any_repeatable(path, key, valueOrOperator, extraValue) {
  let operator, value;
  if (!this.input) return null;

  const data = getInputConsiderConcept(
    this,
    path,
    { ignoreInheritanceFilter: true } // Do not filter - we're searching everything.
  );
  if (!data) return null;

  if (extraValue !== undefined) {
    value = extraValue;
    operator = valueOrOperator;
  } else {
    value = valueOrOperator;
    operator = '==';
  }
  // Data is an object.
  const uids = Object.keys(data);

  return uids.some((uid) => {
    const item = data[uid];
    switch (operator) {
      case '==':
        return item[key] == value;
      case '!=':
        return item[key] != value;
      case '>':
        return item[key] > value;
      case '<':
        return item[key] < value;
      case '>=':
        return item[key] >= value;
      case '<=':
        return item[key] <= value;
      default:
        return false;
    }
  });
}

// {"==":[{"all_repeatable":["facility", "name", "AFacility"]},true]}
export function all_repeatable(path, key, valueOrOperator, extraValue) {
  let operator, value;
  if (!this.input) return null;

  const data = getInputConsiderConcept(
    this,
    path,
    { ignoreInheritanceFilter: true } // Do not filter - we're searching everything.
  );
  if (!data) return null;

  if (extraValue !== undefined) {
    value = extraValue;
    operator = valueOrOperator;
  } else {
    value = valueOrOperator;
    operator = '==';
  }

  // Data is an object.
  const uids = Object.keys(data);

  return uids.every((uid) => {
    const item = data[uid];
    switch (operator) {
      case '==':
        return item[key] == value;
      case '!=':
        return item[key] != value;
      case '>':
        return item[key] > value;
      case '<':
        return item[key] < value;
      case '>=':
        return item[key] >= value;
      case '<=':
        return item[key] <= value;
      default:
        return false;
    }
  });
}

// { "==" : [{numof_repeatable: 'facility'},2] }
export function numof_repeatable(path) {
  if (!this.input) return null;

  const data = getInputConsiderConcept(
    this,
    path,
    { ignoreInheritanceFilter: false } // Do filter - we're only concerned with unique number of.
  );
  if (!data) return null;

  return Object.keys(data).length;
}

// { "==" : [{count_repeatable: ['facility', 'type', 'rcf']},2] }
export function count_repeatable(path, key, value) {
  if (!path || !key || typeof value === 'undefined') return null;
  if (!this.input) return null;

  const data = getInputConsiderConcept(
    this,
    path,
    { ignoreInheritanceFilter: true } // Do not filter - we're searching everything.
  );
  if (!data) return null;

  // Data is an object.
  const uids = Object.keys(data);

  return uids.filter((uid) => {
    const item = data[uid];
    return item[key] === value;
  }).length;
  // return data.filter((item) => item[key] === value).length;
}

// { "==" : [{all_repeatables_same:['facility', 'currency']}, true] }, {state: state}
// returns true if all facilities' currencies are 'EUR' etc
export function all_repeatables_same(path, key) {
  if (!path || typeof key === 'undefined') return null;
  if (!this.input) return null;

  const data = getInputConsiderConcept(
    this,
    path,
    { ignoreInheritanceFilter: true } // Do not filter - we're searching everything.
  );
  if (!data) return null;

  // Data is an object.
  const uids = Object.keys(data);

  const values = uids.map((uid) => {
    return data[uid][key];
  });
  // const values = data.map((item) => item[key]);
  return array_homogenous(values);
}

/* Ordinary state item operations */

export function numof_nodes(path) {
  if (!Array.isArray(this.nodes)) return null;
  return this.nodes.length;
}

export function numof_legalPersons() {
  if (!Array.isArray(this.legalPersons)) return 0;
  return this.legalPersons.length;
}

export function numof_realPersons() {
  if (!Array.isArray(this.realPersons)) return 0;
  return this.realPersons.length;
}

// { "==" : [{any_item:['activeCovenants', true]}] }
export function any_item(path, value) {
  if (!this.input) return null;
  var data = getJsonLogicData(path, this.input);
  if (data.err || !data.match || typeof data.match !== 'object' || Array.isArray(data.match)) return null;
  return Object.keys(data.match).some((item) => data.match[item] === value);
}

// { "==" : [{all_items:['activeCovenants', true]}] }
export function all_items(path, value) {
  if (!this.input) return null;
  var data = getJsonLogicData(path, this.input);
  if (data.err || !data.match || typeof data.match !== 'object' || Array.isArray(data.match)) return null;
  /* 
    console.log('All items are: ', data.match)
    console.log('Are all items ', value, '?')
    Object.keys(data.match).forEach(item => console.log(data.match[item]))
    console.log('Res: ', Object.keys(data.match).every(item => data.match[item] === value))
    */
  return Object.keys(data.match).every((item) => data.match[item] === value);
}

// {"==":[{"input":"pricing.upfrontPaymentOn"},"signing"]}
export function input(path) {
  if (!this.input) return null;
  var data = getJsonLogicData(path, this.input);
  if (data.err || !data.hasOwnProperty('match')) return null;
  return data.match;
}

// {"==":[{"setup":"financingType"},"propertyFinancing"]}
export function setup(path) {
  const setup = Contract.getSetup(this._meta.contract);
  if (!setup) return null;
  var data = getJsonLogicData(path, setup);
  if (data.err || !data.hasOwnProperty('match')) return null;
  return data.match;
}

// {"==":[{"local":"type"},"rcf"]}
export function local(path) {
  if (!this._meta || !this._meta.data || !this._meta.data.local) return null;
  var data = getJsonLogicData(path, this._meta.data.local);
  if (data.err || !data.hasOwnProperty('match')) return null;
  // console.log('Local logic is ', data.match)
  return data.match;
}

// {"==":[{"shortcut":"x"},true]}
export function shortcut(path) {
  if (!this._meta || !this._meta.data || !this._meta.data.shortcuts) return null;
  var data = getJsonLogicData(path, this._meta.data.shortcuts);
  if (data.err || !data.hasOwnProperty('match')) return null;
  // console.log('Shortcut logic is ', data.match)
  return data.match;
}

export function objectValues(x) {
  console.log('Obj Values...', this, x);
  if (!this[x]) return this;
  const target = this[x];
  if (target && typeof target === 'object' && !Array.isArray(target)) {
    console.log('Yes array is ', Object.values(target));
  }
  if (target && typeof target === 'object' && !Array.isArray(target)) return Object.values(target);
  return target;
}

/* const numof_with_cond = {
  '==': [{ numof: { card: 'facility', condition: { and: [{ '==': [{ var: 'name' }, 'hej'] }] } } }, 1],
};

const numof_wo_cond = {
  '==': [{ numof: { card: 'facility' } }, 1],
};

const any_rep = {
  '>': [{ numof: { card: 'facility', condition: { and: [{ '==': [{ var: 'name' }, 'hej'] }] } } }, 0],
}

const all_rep = {
  '==': [{ numof: { card: 'facility', condition: { and: [{ '==': [{ var: 'name' }, 'hej'] }] } } }, {numof: {card: 'facility'}} ],
} */

export function card(card) {
  // console.log('Get card ', card)
  var data = getJsonLogicData(card, this.input, this);
  if (!data || data.err || !data.match || typeof data.match !== 'object' || Array.isArray(data.match)) {
    return null;
  }

  return data.match;
}

export function numof(path) {
  /* if (JSON.stringify(path).includes('obligor')) {
    console.log('Path with obl', path)
  } */
  if (!this.input || !path) return null;

  let cardValues, condition;

  if (path.hasOwnProperty('card') && typeof path.card === 'string') {
    cardValues = jsonLogic.apply({ card: path.card }, this);
    condition = path.condition;
  } else {
    cardValues = path;
    // console.log('path ? ', path)
  }
  
  if (!cardValues || typeof cardValues !== 'object') return false;

  let count = 0;
  if (condition) {
    for (const rep_item of Object.values(cardValues)) {
      if (jsonLogic.apply(condition, rep_item)) {
        count++;
      }
    }
  } else {
    count = Object.keys(cardValues).length;
  }

  return count;
}

export function numof_state(path) {
  const not_found = 0;
  let data = this;
  var sub_props = String(path).split('.');
  for (var i = 0; i < sub_props.length; i++) {
    if (data === null) {
      return not_found;
    }
    // Descending into data
    data = data[sub_props[i]];
    if (data === undefined) {
      return not_found;
    }
  }

  if (!data) return 0;
  if (Array.isArray(data)) return data.length;
  if (typeof data === 'object') return Object.keys(data).length;
  return 0;
}

// { '==': [{ any_in_card: ['activeCovenants', true] }, true] },

export function any_in_card(path, value) {
  if (!this.input || !path) return null;

  var data = getJsonLogicData(path, this.input);
  if (data.err || !data.match || typeof data.match !== 'object' || Array.isArray(data.match)) return null;

  return Object.values(data.match).some((item) => item === value);
}

// { '==': [{ all_in_card: ['activeCovenants', true] }, true] },
export function all_in_card(path, value) {
  if (!this.input || !path) return null;

  var data = getJsonLogicData(path, this.input);
  if (data.err || !data.match || typeof data.match !== 'object' || Array.isArray(data.match)) return null;

  return Object.values(data.match).every((item) => item === value);
}

// Testing functions
export function like(search, matches) {
  if (typeof search !== 'string' || matches === null) {
    return false;
  }
  // Remove special chars
  search = search.replace(
    new RegExp('([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])', 'g'),
    '\\$1'
  );
  // Replace % and _ with equivalent regex
  search = search.replace(/%/g, '.*').replace(/_/g, '.');
  // Check matches
  return RegExp('^' + search + '$', 'gi').test(matches);
}


export function qAndA(...args) {
  console.log('qAndA args ', {args, thisX: this })
  return null
}