const fieldNamesConcerned = [
  'party/definition',
];

const entityPropertiesConcerned = ['name', 'identificationNumber'];

export const listPartiesModule = {
  id: 'listPartiesModule',
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true, legalPersonAny: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    let shallReGenerate = false;
    const concepts = api.interfaces.Contract.getConcepts(this.contract);

    // entriesLoop:
    for (const entry of entries) {
      const { cardId, fieldName, path, pathInvoked } = entry;
      // this.log('entry..', entry);

      if (pathInvoked.repeatableChange) {
        if (path.endsWith('id') || fieldNamesConcerned.includes(fieldName)) {
          shallReGenerate = true;
          break;
        }
      } 
      else if (pathInvoked.legalPersonAny) {
        const propertyConcerned = api.interfaces.InputPaths.getLast(path)
        if (entityPropertiesConcerned.includes(propertyConcerned)) {
          this.log('node prop relevant')
          shallReGenerate = true;
          break;
        }
      } else {
        const matchingConcept = concepts.find((c) => c.stateId === cardId);
        if (matchingConcept && matchingConcept.contractParty) {
          shallReGenerate = true;
          break;
        }
      }
    }
    // this.log('Shall regenerate list of parties: ', shallReGenerate);
    if (!shallReGenerate) return;

    /* // Reset for usage below.
    shallReGenerate = false

    if (!handlerInvoked.repeatableAdd && !handlerInvoked.repeatableRemove) {
      // If just a repeatableChange, then figure out whether or not a relevant
      // fieldName has been updated. If not, abort this handler.
      for (const {fieldName} of entries) {
        if(fieldNamesConcerned.includes(fieldName)) {
          shallReGenerate = true
          break;
        }
      }
      if (!shallReGenerate) return
    }  */
    return (node, parents) => {
      if (!api.utils.engine.matchModule(node, 'facilityAgreement', 'listParties')) return;
      const listedParties = this.listTheParties(node, state);

      if (!Array.isArray(listedParties)) return;

      api.utils.engine.itemJoiner(this, listedParties);

      node.children = listedParties;

      this.markNodeUpdate(node);
    };
  },
};
