
export const load = function (state) {

  this.log("LOADING ENGINE....", state);
  this.setPreviousState(state)
  this.setCurrentState(state)
  /* Invoke init flows */
  
  const api = this.api;

  const runLoadFlows = () => {
    const isInitialized = this.isInitialized();

    this.handlers
    .filter((handler) => handler.dependencies && handler.dependencies.onLoad)
      .forEach((handler) => {
        if (typeof handler.handler !== "function") return;
        const fn = handler.handler.call(this, {
          state,
          isInitialized,
          invoked: { onLoad: true },
          api
        });

        this.runFlow(fn, this.getContent(), handler)
      });
  };

  runLoadFlows();
  
  this.api.interfaces.Contract.setDraftInfoItem(this.contract, 'initialized', true)

  return {
    content: this.getContent(),
    draftInfo: this.getDraftInfo(),
    draftTime: Date.now(),
  };

};
