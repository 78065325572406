import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Table, Button, Dropdown, Menu, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import WidgetHeader from 'components/WidgetHeader/index';
import EntityWidget from 'components/Widgets/EntityWidget';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import NewCustomerButton from 'components/customer/NewCustomer/NewCustomer.js';
import { useSearch } from 'hooks';
import { fixDate, RemoveModal } from 'components/ui';
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';

const { Column, ColumnGroup } = Table;

export default function Customers() {
  const [customers, setCustomers] = useState(null);
  const [customersError, setCustomersError] = useState('');
  const [viewMode, setViewMode] = useState('list');

  const customerWasCreated = (customer) => {
    setCustomers([...customers, customer]);
  };
  const customerWasRemoved = (id) => {
    setCustomers([...customers.filter((customer) => customer.id !== id)]);
  };

  useEffect(() => {
    const include = [
      { model: 'LegalPerson', as: 'LegalPersons' },
      { model: 'RealPerson', as: 'RealPersons' },
    ];
    const includeQuery = encodeURI(JSON.stringify(include));
    api
      .get(`/customers?fields=id,name,createdAt&include=${includeQuery}`)
      .then(function (res) {
        console.log('fetch entity res ', res);
        if (res.data && res.data.length > 0) setCustomers(res.data);
        else {
          setCustomersError('No customers available');
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setCustomersError('Sign in to see your customers');
        } else {
          setCustomersError('No customers available at this time');
        }
      });
  }, []);

  const showList = () => setViewMode('list');
  const showGrid = () => setViewMode('grid');

  const [filteredCustomers, searchElement] = useSearch(customers, {
    element: true,
    fields: ['name'],
  });

  return (
    <>
      <WidgetHeader
        title={<IntlMessages id="general.customers" cap />}
        styleName="mb-3"
        extra={
          <>
            <span className="mr-3">
              <Tooltip title={'Show customers as list'} placement="bottom">
                <Button
                  size="middle"
                  className={'m-0 ' + (viewMode === 'list' ? 'active' : '')}
                  type="ghost"
                  onClick={showList}
                >
                  <UnorderedListOutlined />
                </Button>
              </Tooltip>
              <Tooltip title={'Show customers as grid'} placement="bottom">
                <Button
                  size="middle"
                  className={'m-0 ml-3 ' + (viewMode === 'grid' ? 'active' : '')}
                  type="ghost"
                  onClick={showGrid}
                >
                  <AppstoreOutlined />
                </Button>
              </Tooltip>
            </span>
            {searchElement}
            <NewCustomerButton customerWasCreated={customerWasCreated} />
          </>
        }
      />
      {!customers && !customersError && (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Spin size="large" />
        </div>
      )}

      {customersError && (
        <div className="d-flex align-items-center justify-content-center h-100">{customersError}</div>
      )}

      {customers && !customersError && viewMode === 'list' && (
        <div className="full-table-view transparent-th">
          <CustomersTable
            customers={filteredCustomers}
            customerWasRemoved={customerWasRemoved}
            type="project"
          />
        </div>
      )}
      {customers && !customersError && viewMode === 'grid' && (
        <Row>
          {filteredCustomers.map((customer) => (
            <Col xs={12} lg={6} key={customer.id}>
              <EntityWidget key={customer.id} customer={customer} customerWasRemoved={customerWasRemoved} />
            </Col>
          ))}
        </Row>
      )}

      {/* {customers && (
        <Row>
          {customers.map((customer) => (
            <Col key={customer.id} xl={6} lg={12} md={12} sm={12} xs={24}>
              <EntityWidget customer={customer} customerWasRemoved={customerWasRemoved} />
            </Col>
          ))}
        </Row>
      )} */}
    </>
  );
}

const config = {
  bordered: false,
  loading: false,
  pagination: { position: 'bottom' },
  size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  scroll: undefined,
};

export function CustomersTable({ customers, customerWasRemoved }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selection) => {
    console.log('selection changed: ', selection);
    setSelectedRowKeys(selection);
  };

  const rowSelection = null; /* {
    selectedRowKeys,
    onChange: onSelectChange,
  }; */

  const removeCustomer = (id) => {
    api
      .delete('/customers/' + id)
      .then((result) => {
        customerWasRemoved(id);
      })
      .catch((err) => {
        console.log('Error removing project ', id, err.response);
      });
  };

  return (
    <Table
      rowKey="id"
      className="table-responsive ml-3"
      {...config}
      // columns={columns}
      rowSelection={rowSelection}
      dataSource={customers}
    >
      <ColumnGroup title={null}>
        <Column
          title={<IntlMessages id="app.general.Name" />}
          dataIndex="name"
          key="name"
          render={(text, record) => (
            <Link
              to={{
                pathname: '/entities/' + (record && record.id ? record.id : '_null'),
              }}
            >
              {text}
            </Link>
          )}
        />
        {/* <Column
          title={<IntlMessages id="app.general.Description" />}
          dataIndex="description"
          key="description"
        /> */}
        <Column
          title={<IntlMessages id="app.general.Created" />}
          dataIndex="createdAt"
          key="createdAt"
          render={(text) => fixDate(text)}
        />
        <Column
          title={<IntlMessages id="app.general.Action" />}
          key="actions"
          render={(text, record) => (
            <Button className="margin-0">
              <Link
                to={{
                  pathname: '/entities/' + (record && record.id ? record.id : '_null'),
                }}
              >
                <IntlMessages id={'app.main.goToCustomer'} />
              </Link>
            </Button>
          )}
        />
        <Column
          title={<IntlMessages id="app.general.More" />}
          dataIndex={null}
          key="menu"
          render={(text, record) => (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <RemoveModal
                      onConfirm={() => removeCustomer(record.id)}
                      confirmText={
                        <>
                          <IntlMessages id="app.general.confirmRemoval" />{' '}
                          <IntlMessages id={'general.customer'} />?
                        </>
                      }
                    >
                      <span className="">
                        <IntlMessages id="desc.Remove" /> <IntlMessages id={'general.customer'} />
                      </span>
                    </RemoveModal>
                  </Menu.Item>
                </Menu>
              }
            >
              <span className="link ant-dropdown-link">
                <i className="icon-btn icon icon-ellipse-v" />
              </span>
            </Dropdown>
          )}
        />
      </ColumnGroup>
    </Table>
  );
}
