import { useState, useCallback, useEffect } from 'react';
import { useEditor } from 'slate-react';
import { useDraft, setContractUpdates } from 'hooks';
import { Contract } from 'core/interfaces'

/**
 * hook for updating the highlighting on contract preview
 */

let globalType = 'all';
let force = {
  highlight: () => {},
};
const next = { current: { above: null, below: null } };

export function useHighlightContractUpdates(opts = {}) {
  const { isTemplate, view } = opts;
  const editor = useEditor();
  const draft = useDraft();

  const [type, setShowType] = useState(globalType);

  const setType = (value) => {
    setShowType(value);
    globalType = value;
  };

  const draftInfo = Contract.getDraftInfo(draft?.contract)
  const _firstTimeDraft = draftInfo._firstTimeDraft || '';
  const draftId = draft?.id;

  const highlight = useCallback(() => {
    if (isTemplate || view) return {};

    if (globalType === 'none') {
      let elems = document.querySelectorAll('.highlighted-node');
      [].forEach.call(elems, function (el) {
        el.classList.remove('highlighted-node');
      });
      setContractUpdates({ updates: 0, above: 0, below: 0 });
      return { updates: 0, above: 0, below: 0 };
    }

    const firstDraftTime = _firstTimeDraft.toString();

    let above = 0,
      below = 0,
      updates = 0;

    next.current = { above: null, below: null };

    const all = globalType === 'all';

    const selector = all ? '[data-updated]' : '[data-updated="' + draft?._draftTime + '"]';

    const updatedDomNodes = window.document.querySelectorAll(selector);
    const actualUpdateDomNodes = [];

    for (const domNode of updatedDomNodes) {
      if (domNode.getAttribute('data-updated') === firstDraftTime || domNode.offsetParent === null) {
        // Skip node if updated at first load, or if not visible
        continue;
      }
      const parentUpdated = domNode.parentElement.closest(selector);
      // Skip node if a parent has also changed
      if (parentUpdated && parentUpdated.getAttribute('data-updated') !== firstDraftTime) {
        continue;
      }

      const nodeInView = isInView(domNode);

      if (nodeInView === -1) {
        above++;
        next.current.above = domNode;
      } else if (nodeInView === 1) {
        below++;
        if (!next.current.below) next.current.below = domNode;
      }
      updates++;
      actualUpdateDomNodes.push(domNode);
      if (!domNode.classList.contains('highlighted-node')) domNode.classList.add('highlighted-node');
    }

    // Remove highlight for previous non relevant nodes
    let elems = document.querySelectorAll('.highlighted-node');
    [].forEach.call(elems, function (el) {
      if (!actualUpdateDomNodes.includes(el)) {
        el.classList.remove('highlighted-node');
      }
    });
    // console.log('Highlight data ', { updates, above, below })
    setContractUpdates({ updates, above, below });
    force.highlight = highlight;
    return { updates, above, below };
  }, [draft, _firstTimeDraft, isTemplate, view]);

  useEffect(() => {
    highlight();
  }, [type, highlight]);

  useEffect(() => {
    return () => (force.highlight = null);
  }, []);

  const step = (direction) => {
    // console.log('next ', {next, direction})
    if (!next.current[direction]) return;
    // console.log('step ', next.current[direction])
    editor.scrollToNodeOrDomNode(next.current[direction], { isDomNode: true });
    highlight();
  };

  return { highlight, type, setType, step, next };
}

const editorElement = function () {
  const editorElem = document.getElementById('editor-holder');
  if (!editorElem) return null;
  return editorElem.parentElement;
};

const isInView = function (elem) {
  const editorHolderDom = editorElement();
  if (!editorHolderDom) {
    return console.log('No editorHolderDom');
  }
  let domNode = elem;

  if (!domNode) return false;

  let offset = 0;
  let parentNode = domNode;

  while (parentNode !== editorHolderDom && parentNode.offsetParent) {
    offset += parentNode.offsetTop;
    parentNode = parentNode.offsetParent;
    // console.log('parntNode is ', parentNode)
    if (parentNode.classList.contains('editor-scroller')) {
      break;
    }
  }

  let editorScrollTop = editorHolderDom.parentElement.scrollTop;
  let style = window.getComputedStyle(domNode);

  let offsetMeasureTop = offset + domNode.offsetHeight;
  offsetMeasureTop -= parseInt(style.getPropertyValue('margin-bottom'));
  const offsetMeasureBottom = offset + parseInt(style.getPropertyValue('margin-top'));

  const passedTop = offsetMeasureTop > editorScrollTop + 5;

  const passedBottom =
    offsetMeasureBottom < editorScrollTop + editorHolderDom.parentElement.parentElement.offsetHeight - 5;

  if (!passedTop) return -1;
  if (!passedBottom) return 1;
  return 0;
};
