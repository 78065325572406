import { Contract } from 'core/interfaces';
import applyJsonLogic from 'core/utils/logic/applyLogic';
import { mergeObj, setByPath } from 'core/utils/general';

export const handleContractImport = (contract, contractInputState, importContract) => {
  const importState = Contract.getFullState(importContract);
  let inputState, nodesState, legalPersonsState;

  const mergedSetup = mergeObj(Contract.getSetup(importContract), Contract.getSetup(contract));

  function testLogic(rule, testInputState) {
    return applyJsonLogic(
      rule,
      {
        input: testInputState,
      },
      {
        _meta: {
          create: { setup: mergedSetup },
          contract: contract,
        },
        data: {
          shortcuts: importContract ? Contract.getDraftInfo(importContract).shortcutStates || {} : {},
        },
        debug: true,
      }
    );
  }

  if (importState && importState.input) {
    const contractInput = contractInputState;
    const importInput = importState.input;

    // First, clear any repeatables which are present
    // in both the contractInputState and the importState,
    // so that we do not use any defaults originating from
    // from the new contract, but rather only the ones from the
    // imported state.
    for (const cardId in contractInputState) {
      if (!Contract.getUiIsCardRepeatable(contract, cardId)) continue;
      if (importInput[cardId]) {
        contractInputState[cardId] = {};
      }
    }

    console.log('Merge ', { contractInput, importInput, mergeObj });

    inputState = mergeObj(contractInput, importInput);
    nodesState = importState.nodes;
    legalPersonsState = importState.legalPersons || [];
  } else {
    inputState = contractInputState;
  }

  if (contract.data.create?.build?.logicValues) {
    for (const logicValue of contract.data.create.build.logicValues) {
      const { rule, path, value } = logicValue;
      if (testLogic(rule, inputState)) {
        setByPath(inputState, path, value);
      }
    }
  }

  return { inputState, nodesState, legalPersonsState, mergedSetup };
};
