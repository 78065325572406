import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Col, Row, Card, Spin } from 'antd';

import asyncComponent from 'util/asyncComponent';
import Auxiliary from '../../util/Auxiliary';
import ProjectHeader from '../../components/project/ProjectHeader/index';

import { setCustomer, useProject, usePermissionsTable } from 'hooks';
import { useSelector } from 'react-redux';
import { updateRules } from 'permissions/permissions';

const routesWithoutHeader = [
  ['/version/', '/view'], // includes both /version/ and /view
  ['/version/', '/apply'], // includes both /version/ and /apply
  ['/version/'],
  ['/document/'],
];

const Project = (props) => {
  const { match, location } = props;
  const { id } = match.params;
  const project = useProject(id, { force: true });
  const type = "Project"

  const auth = useSelector((state) => state.auth);

  const permissionsTable = usePermissionsTable()

  useEffect(() => {
    if (!project || project.id !== id) return; // Effect only upon loading new project
    updateRules(project, 'Project', auth, permissionsTable);
    if (!project.isFolder) setCustomer(project.customerId);
  }, [project, project.id, auth, id, permissionsTable]);

  if (!project || project.id !== id) {
    return (
      <Card title="" className="card">
        <Spin className="loader-container" />
      </Card>
    );
  }

  // Test against routesWithoutHeader to determine if ProjectHeader shall be shown
  // Include the header if there is NO match in routesWithoutHeader
  let includeHeader =
    routesWithoutHeader.findIndex((condition) =>
      condition.every((part) => location.pathname.includes(part))
    ) === -1;

  return (
    <Auxiliary>
      {includeHeader && <ProjectHeader project={project} location={location} />}

      <Row className="project-main-holder">
        <Col sm={24}>
          <Switch>
            <Route
              path={`${match.url}/documents`}
              component={asyncComponent(() => import('./Documents/'), { project })}
            />
            <Route
              path={`${match.url}/document/:documentId/version/:versionId`}
              component={asyncComponent(() => import('./Version/'), { project })}
            />
            <Route
              path={`${match.url}/document/:documentId`}
              component={asyncComponent(() => import('./Document/'), { project })}
            />
            <Route
              path={`${match.url}/members`}
              component={asyncComponent(() => import('./Members/'), { project })}
            />
            <Route
              path={`${match.url}/files`}
              component={asyncComponent(() => import('./Files/'), { project })}
            />
            <Route
              path={`${match.url}/checklists`}
              component={asyncComponent(() => import('./Checklists/'), { project, id, type })}
            />
            <Route
              path={`${match.url}/settings`}
              component={asyncComponent(() => import('./Settings/'), { project })}
            />
            {/* <Route
                path={`${match.url}/timeline`}
                component={asyncComponent(() => import("./Timeline/"), {project})}
              /> */}
            <Route
              path={`${match.url}/overview`}
              component={asyncComponent(() => import('./Overview/'), { project, id, type })}
            />
            <Route path={`${match.url}`} exact={true}>
              <Redirect to={`${match.url}/overview`} />
            </Route>
            {/* <Route
                path={`${match.url}`}
                exact={true}
                component={asyncComponent(() => import("./Overview/"), {project})}
              /> */}
          </Switch>
        </Col>
      </Row>
    </Auxiliary>
  );
};
export default Project;
