import React, { memo } from "react";
import {
  ProvisionIndicator,
  RepeatableButton,
  AddRepeatableButton,
  InActiveClause,
  ActiveClause,
  ElementsIndicator,
  // ClauseIndicator,
  FilesIndicator,
} from "./indicators/";
import { useSelected, ReactEditor } from "slate-react";
import { useSelector } from "react-redux";
export * from "./indicators/BlockMenu";

export const Indicator = memo(({ editor, element }) => {
  return (
    <>
      <StaticIndicators editor={editor} element={element} />
      <OnSelectedIndicators editor={editor} element={element} />
    </>
  );
});

const StaticIndicators = memo(({ editor, element }) => {
  const isInactive = element.data && element.data._inActive;

  if (editor.meta.view && element.provision && !isInactive)
    return <ProvisionIndicator element={element} editor={editor} />;

  let stringPath
  try {
    stringPath = element.data?.acp ? JSON.stringify(ReactEditor.findPath(editor, element)) : ""
  } catch (err) {
    console.log('Could not get path for element.', element)
  }
  

  return (
    <>
      {!editor.meta.isEditingTemplate && <ActivesWrapper editor={editor} element={element} />}
      {!isInactive && <ElementsIndicator editor={editor} elementData={element.data} stringPath={stringPath} />}
      <FilesIndicator editor={editor} element={element} />
    </>
  );
});
const OnSelectedIndicators = memo(({ editor, element }) => {
  const isInactive = element.data && element.data._inActive;
  const isInActiveRepresentation = element._isInActiveRepresentation;
  const selected = useSelected();

  return (
    <>
      {!editor.meta.isEditingTemplate && selected && element.data?._each_repeatable_name && (
        <RepeatableButton contentEditable={false} editor={editor} element={element} />
      )}
      {!editor.meta.isEditingTemplate && selected && element.data?.each_repeatable?.repeatable && (
        <AddRepeatableButton contentEditable={false} editor={editor} element={element} />
      )}

      {/* selected && element.type === "clause" && !isInactive && !isInActiveRepresentation && (
        <ClauseIndicator element={element} editor={editor} />
      ) */}
    </>
  );
});

function ActivesWrapper({ editor, element }) {
  const showInActive = useSelector(({ draft }) => draft.editor_showInActive);
  if (!showInActive) return null;
  const isInactive = element.data && element.data._inActive;
  if (isInactive)
    return <InActiveClause contentEditable={false} element={element} editor={editor} />;
  else return <ActiveClause contentEditable={false} element={element} editor={editor} />;
}
