import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { Col, Row, Card, Spin } from 'antd';

import asyncComponent from 'util/asyncComponent';
import Auxiliary from '../../util/Auxiliary';
import EntityHeader from '../../components/Headers/EntityHeader/index';

import { useCustomer, setCustomer } from 'hooks';
import EntityOrgChart from './OrgChart';
import Persons from './Persons';

const routesWithoutHeader = [
  // ["/version/", "/view"], // includes both /version/ and /view
  // ["/version/"]
];

export default function Entity(props) {

  const { match, location } = props;
  const { id } = match.params;
  const type = "Customer"

  const { customer, person, personType } = useCustomer(id, { force: false });
  useEffect(() => {
    if (!customer || customer.id !== id) setCustomer(id);
  }, [id, customer]);

  if (!customer || customer.id !== id) {
    return (
      <Card title="" className="card">
        <Spin className="loader-container" />
      </Card>
    );
  }

  // Test against routesWithoutHeader to determine if EntityHeader shall be shown
  // Include the header if there is NO match in routesWithoutHeader
  let includeHeader =
    routesWithoutHeader.findIndex((condition) =>
      condition.every((part) => location.pathname.includes(part))
    ) === -1;

  return (
    <Auxiliary>
      {includeHeader && (
        <EntityHeader customer={customer} person={person} personType={personType} location={location} />
      )}

      <Row>
        <Col sm={24}>
          <Switch>
            <Route
              path={`${match.url}/overview`}
              exact={true}
              component={asyncComponent(() => import('./Overview/'), { id, type, customer, person, personType })}
            />
            {personType === 'legalPerson' && (
              <Route
                path={`${match.url}/structurechart`}
                exact={true}
                // component={asyncComponent(() => import("./OrgChart/"), {customer})}
                render={(props) => <EntityOrgChart {...props} customer={customer} />}
              />
            )}
            {personType === 'legalPerson' && (
              <Route
                path={`${match.url}/persons`}
                exact={true}
                render={(props) => <Persons customer={customer} person={person} />}
              />
            )}
            <Route path={`${match.url}`} exact={true}>
              <Redirect to={`${match.url}/overview`} />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Auxiliary>
  );
}
