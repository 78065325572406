import React from 'react';

import { Form, Input } from 'antd';
import { getValue } from './utils/values';
import { isNumeric, combineNumber } from 'core/utils/general';

function InputNumeric(props) {
  const { label, handleChange, cardId, language, field, inputIndex, cardUid } = props;

  let value = getValue(props, '');

  const handleNumericChange = (event) => {
    if (!isNumeric(combineNumber(event.target.value, language))) return;
    handleChange(event.target.value, { format: field.format });
  };

  return (
    <Form.Item
      label={label}
      extra={field.info ? <small className="muted">{field.info[language]}</small> : null}
    >
      {field.introText && field.introText[language] && <div>{field.introText[language]}</div>}
      <Input
        type="text"
        name={field.name}
        autoComplete="off"
        onChange={handleNumericChange}
        id={field.name + inputIndex + cardId + cardUid}
        value={value}
        placeholder=""
      />
    </Form.Item>
  );
}

export default InputNumeric;

/*
<FormGroup>
        <Label htmlFor={field.name + inputIndex}>{label}</Label>
        {field.introText && field.introText[language] && <div>{field.introText[language]}</div>}
        <Input
          type="text"
          name={field.name}
          onChange={event => handleChange(event.target.value, {format: field.format})}
          id={field.name + inputIndex + cardId + cardUid}
          value={value}
          placeholder=""
          autoComplete="off"
        />
        {field.info ? (
          <FormText className="muted">{field.info[language]}</FormText>
        ) : null}
      </FormGroup>
*/
