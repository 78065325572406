import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSlate } from "slate-react";
import { Transforms, Node } from "slate";
import { addRepeatable, removeRepeatable, setDraft } from 'appRedux/actions';
import { Row, Card, Collapse, Button, Tooltip, Alert, Spin } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import CardContent from './components/card/CardContent';
import { useContract, useIsTemplateStudio } from 'hooks';
import { Contract, InputPaths } from 'core/interfaces';
import { getByPath, makeRepeatableId } from 'core/utils/general';
import CardActions from "./CardActions";
import { setAlertContext } from "hooks";

const Panel = Collapse.Panel;

export default function RepeatableCard(props) {
  const { card, cardName, language, cardWithinCard } = props;
  const mainPath = InputPaths.construct(props.path, card.id);
  const { header } = card;
  const cardHeader = header[language] || 'Unknown';
  const isTemplate = useIsTemplateStudio();
  const templateConnectRepeatable = useSelector(({ draft }) => draft.template_connect_repeatable);
  const editor = useSlate();

  const contract = useContract();

  const repeatableInfo = Contract.getRepeatableInfo(contract, cardName);
  const repeatableState = useSelector((state) => getByPath(state, mainPath));

  const [openCard, setOpenCardState] = useState([
    repeatableState ? cardName + Object.keys(repeatableState)[0] : null,
  ]);
  const [loadingNewCard, setLoadingNewCard] = useState(false);

  const dispatch = useDispatch();
  const addBlock = (data) => {
    const uid = makeRepeatableId(mainPath.split('.').slice(-1)[0]);
    setLoadingNewCard(true);
    setOpenCard(['new']);
    setTimeout(() => {
      dispatch(addRepeatable(mainPath, data, uid));
      setOpenCard([cardName + uid]);
      setLoadingNewCard(false);
    }, 1);
  };

  // const removeBlock = (...args) => dispatch(removeInputBlock(...args));
  const removeBlock = (path) => dispatch(removeRepeatable(path));

  const setOpenCard = useCallback((keys) => {
    setOpenCardState(keys);
  }, []);

  const onRepeatableAction = () => {
    const node = Node.get(editor, templateConnectRepeatable.path);

    const newData = { ...node.data, each_repeatable: { repeatable: card.id, inlineone: true }};
    delete newData.type;

    Transforms.setNodes(
      editor,
      {
        data: {
          ...newData
        },
      },
      {
        at: templateConnectRepeatable.path,
      }
    );

    dispatch(setDraft('template_connect_repeatable', null));
    setAlertContext(null);
  }

  let Buttons = [];

  if (isTemplate) {
    Buttons = (
      <CardActions cardName={cardName} />
    );
  } else {
    if (card.action_module?.component) {
      let ActionModuleComponent;
      try {
        ActionModuleComponent = require('./components/action_modules/' +
          card.action_module?.component +
          '/index.js').default;
        Buttons.push(
          <React.Fragment key="action_module_component">
            <ActionModuleComponent {...props} path={mainPath} />
          </React.Fragment>
        );
      } catch (err) {
        console.log('Cannot import action module componenent', card.action_module);
      }
    }
    Buttons.push(
      <AddButton
        key="addbtn"
        addBlock={addBlock}
        cardName={cardName}
        language={language}
        repeatableInfo={repeatableInfo}
      />
    );
  }

  // const cardClass = cardWithinCard ? "" : "border-0"
  const cardClass = 'border-0 repeatable-card input-fields-card';

  const uids = repeatableState ? Object.keys(repeatableState) : [];

  uids.sort((a, b) => {
    if (repeatableState[a]._meta.added < repeatableState[b]._meta.added) {
      return -1;
    }
    if (repeatableState[a]._meta.added > repeatableState[b]._meta.added) {
      return 1;
    }
    return 0;
  });

  if (!repeatableState || uids.length === 0) {
    return (
      <Card className={cardClass} title={cardHeader} extra={Buttons}>
        <div>
          <IntlMessages id="studio.inputs.noRepeatableCards" />
          
        </div>
      </Card>
    );
  }

  if (!card.id) card.id = cardName;
  return (
    <Card className={cardClass} title={cardHeader} extra={Buttons} actions={cardWithinCard ? null : Buttons}>
      {templateConnectRepeatable && (
        <div onMouseDown={onRepeatableAction} className="template-repeatable-action"></div>
      )}
      <Collapse activeKey={openCard} onChange={setOpenCard}>
        {uids.map((uid, index) => {
          const repData = repeatableState[uid];
          const { header, btn } = getHeader(
            card,
            uid,
            index,
            removeBlock,
            language,
            repData,
            repeatableInfo,
            mainPath,
            uids.length,
            contract,
            isTemplate
          );
          const itemPath = InputPaths.construct(props.path, card.id, uid);

          return (
            <Panel className="repeatable-panel" header={header} key={cardName + uid} extra={btn || null}>
              <Row>
                <CardContent
                  card={card}
                  language={language}
                  isRepeatable={true}
                  path={itemPath}
                  cardWithinCard={cardWithinCard}
                />
              </Row>
            </Panel>
          );
        })}
        {loadingNewCard && (
          <Panel className="repeatable-panel" header={'...'} key={'new'}>
            <Spin tip="Loading...">
              <Alert message=" " description=" " type="info" style={{ minHeight: '100px' }} />
            </Spin>
          </Panel>
        )}
      </Collapse>
    </Card>
  );
}

function HeaderTitle({ contract, card, uid, index, data, language }) {
  let text;
  const { eachHeader } = card;
  if (typeof eachHeader === 'string') text = eachHeader;
  else if (typeof eachHeader === 'object') {
    if (eachHeader.type === 'ref' && eachHeader.target) {
      if (
        Contract.getUiInputFieldData(contract, eachHeader.target) &&
        Contract.getUiInputFieldData(contract, eachHeader.target).type === 'party'
      ) {
        return (
          <h2 className="h5 mb-0">
            <PersonName data={data} target={eachHeader.target} />
          </h2>
        );
      }
      if (data && data[eachHeader.target]) text = data[eachHeader.target];
      else if (typeof eachHeader.default === 'object' && eachHeader.default[language])
        text = eachHeader.default[language];
      else if (typeof eachHeader.default === 'string') text = eachHeader.default;
    } else if (eachHeader.hasOwnProperty(language) && typeof eachHeader[language] === 'string') {
      let title = eachHeader[language];
      if (eachHeader.includeIndexStartOne) title += ' ' + (index + 1);
      text = title;
    }
  }
  if (!text || typeof text !== 'string') return <h2 className="h5 mb-0">&nbsp;</h2>;
  return <h2 className="h5 mb-0">{text}</h2>;
}

function PersonName({ data, target }) {
  const legalPersons = useSelector((state) => state.legalPersons);
  const targetField = data[target]
  if (!targetField || !targetField.id) return <>&nbsp;</>;
  const legalPerson = legalPersons.find((person) => person.id === targetField.id);
  if (!legalPerson || !legalPerson.name) return <>&nbsp;</>;
  return legalPerson.name;
}

function getHeader(
  card,
  uid,
  index,
  removeInputBlock,
  language,
  data,
  repeatableInfo,
  mainPath,
  totalNumberRepeatableInstances,
  contract,
  isTemplate
) {
  // let header = null;
  let btn = null;

  const { defaultNumber = 0 } = repeatableInfo;

  const header = (
    <HeaderTitle contract={contract} card={card} uid={uid} index={index} data={data} language={language} />
  );
  const showRemoveRepeatableButton = totalNumberRepeatableInstances > defaultNumber;

  // const shallDisplay = headerText || headerText === "" || showRemoveRepeatableButton;

  // if (!shallDisplay) return { header, btn };

  if (isTemplate) return { header };

  if (showRemoveRepeatableButton) {
    btn = (
      <Tooltip title={'Remove'} placement="top">
        <Button
          type="danger"
          shape="circle"
          icon={<CloseOutlined className="fs-xs" />}
          className="remove-repeatable-btn ant-btn-mini"
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            removeInputBlock(mainPath + '.' + uid);
          }}
        />
      </Tooltip>
    );
  }
  return { header, btn };
}

function AddButton({ addBlock, cardName, language }) {
  const contract = useContract();
  const repeatableInfo = Contract.getRepeatableInfo(contract, cardName);

  return (
    <>
      <Tooltip title={(repeatableInfo && repeatableInfo.label && repeatableInfo.label[language]) || 'Add'}>
        <Button shape="circle" icon={<PlusOutlined />} onClick={() => addBlock()} />
      </Tooltip>
    </>
  );
}
