import React from 'react';
import { Modal, Button, Row } from 'antd';
import { useStudioEditorInputContent, setStudioEditorInputContent } from 'hooks';
import { StudioEditorInputContent } from 'contexts/contexts';
import CardContent from 'routes/studio/Views/Input/renderDraftUI/components/card/CardContent';

export default function ContractInputEdit() {
  return (
    <StudioEditorInputContent.Provider initialValue={null}>
      <InputEditCard />
    </StudioEditorInputContent.Provider>
  );
}

function InputEditCard() {
  const data = useStudioEditorInputContent();

  const onClose = () => {
    setStudioEditorInputContent(null);
  };

  if (!data) return null;

  const { card, path, language } = data;

  if (!card || !language) return null;

  return (
    <Modal
      title={'Edit data'}
      visible={true}
      onOk={onClose}
      onCancel={onClose}
      backdrop={'static'}
      size="lg"
      className="qb-modal"
      footer={
        <Button type="primary" onClick={onClose}>
          OK
        </Button>
      }
    >
      <div className="mb-3">
        <Row>
          <CardContent
            card={card}
            language={language}
            isRepeatable={true}
            path={path}
            cardWithinCard={false}
          />
        </Row>
      </div>
    </Modal>
  );
}
