import React from 'react';
import { Badge } from 'antd';
import Widget from 'components/Widget/index';

const IconCard = ({ className, color, icon, mdi, badge, bottomText, children = null, onClick }) => {
  return (
    <Widget styleName={`card-full p-2 text-center text-primary ${className || ''} ${color}`}>
      {badge && (
        <Badge
          status={badge}
          style={{ position: 'absolute', right: '0', marginRight: '0', top: '4px' }}
        ></Badge>
      )}
      <div>
        {
          icon && (
            <i className={`${mdi ? 'mdi mdi-' : 'icon icon-'}${icon} fs-iconcard`} />  
          )
        }
        {children}
      </div>
      <div>{bottomText ? bottomText : ''}</div>
    </Widget>
  );
};

export default IconCard;
