import React, { useState } from "react";
import { Avatar, Dropdown, Menu, Popconfirm, Tooltip, Button, Input, notification } from "antd";
import { Link } from "react-router-dom";
import Widget from "components/Widget/index";
import api from "utils/api";
import { useModalContent } from "components/ui/modals";

function DuplicateTemplate({ template, templateWasCreated, toggleModal }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const duplicate = () => {
    if (!name || !description) {
      notification.warning({
        message: "Provide name and description",
      });
      return;
    }

    api
      .get("/documenttemplates/" + template.id)
      .then((res) => {
        api
          .post("/documenttemplates", {
            ...res.data,
            id: undefined,
            name,
            description,
          })
          .then((res) => {
            console.log("Successfully created...", res.data);
            templateWasCreated(res.data);
            toggleModal();
          })
          .catch((err) => {
            console.log("Failed creating ", err.response);
          });
      })
      .catch((err) => {
        console.log("Cannot fetch full template prior to creating new.");
        return;
      });
  };

  return (
    <div>
      <div>
        Name:
        <Input value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="mt-4">
        Document description:
        <Input value={description} onChange={(e) => setDescription(e.target.value)} />
      </div>
      <Button className="mt-4" block type="primary" onClick={duplicate}>
        Create Document
      </Button>
    </div>
  );
}

const DocumentTemplateWidget = ({ template, templateWasRemoved, templateWasCreated }) => {
  const removeTemplate = () => {
    api
      .delete("/documenttemplates/" + template.id)
      .then((result) => {
        templateWasRemoved(template.id);
      })
      .catch((err) => {
        console.log("Error removing template ", template.id, err.response);
      });
  };

  const [Modal, toggleModal,, open] = useModalContent("Duplicate Template", {
    defaultOpen: false,
  });

  if (!template || !template.id) return null

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link
          to={{
            pathname:
              "/studio/template/" + template.id + "/input",
          }}
        >
          Open Template
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <Popconfirm
          title="Are you sure delete this template?"
          onConfirm={removeTemplate}
          okText="Yes"
          cancelText="No"
        >
          <span className="">Remove Template</span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Modal size="lg" className="x-modal">
        {open && (
          <DuplicateTemplate
            template={template}
            templateWasCreated={templateWasCreated}
            toggleModal={toggleModal}
          />
        )}
      </Modal>
      <Widget
        styleName="ch-capitalize card-sm-px"
        extra={
          template && (
            <ul className="list-inline ml-auto mb-0 text-grey">
              <li>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <span className="link ant-dropdown-link text-grey">
                    <i className="icon icon-chevron-down" />
                  </span>
                </Dropdown>
              </li>
            </ul>
          )
        }
        title="Doc Template"
      >
        <div className="text-center">
          <i className="size-60 mb-3 mdi mdi-file-outline ico-lg" alt="projectlogo" />

          <h2 className="mb-3 mb-sm-4">{template && template.name}</h2>
          <h5 className="mb-3 mb-sm-4">{template && template.description}</h5>

          <ul className="list-inline mb-3 mb-lg-4 tags">
            <li>
              <Tooltip title="Document Template">
                <Avatar className="bg-secondary text-white">DT</Avatar>
              </Tooltip>
            </li>
          </ul>
          <Link
            to={{
              pathname:
                "/studio/template/" + template.id + "/input",
            }}
          >
            <button className="btn btn-primary text-white mb-1">Edit template</button>
          </Link>
          <Button className="mb-1" onClick={toggleModal}>
            Duplicate template
          </Button>
        </div>
      </Widget>
    </>
  );
};

export default DocumentTemplateWidget;
