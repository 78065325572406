export const facAgrCustomShortcuts = {
  id: 'facAgrCustomShortcuts',
  dependencies: { state: ['*'] },

  time: -11,

  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    const cardIds = entries.map((entry) => entry.cardId);
    const fieldNames = entries.map((entry) => entry.fieldName);

    // Handle shortcut "HAS_PROPCO"
    if (cardIds.includes('property') || cardIds.includes('borrower')) {
      const propertyLength = api.utils.general.ocount(state.input && state.input.property);
      if (propertyLength === 0) {
        this.setShortcut('HAS_PROPCO', false);
        this.setShortcut('NO_PROPCOS', true);
      } else {
        const propCoProperties = api.utils.general.ofilter(state.input.property, (property) => {
          if (!state.input.borrower) return true;
          return !api.utils.general.ofindKey(
            state.input.borrower,
            (borrower) =>
              borrower.borrowerEntity &&
              borrower.borrowerEntity.id === (property.propertyOwner && property.propertyOwner.id)
          );
        });
        this.setShortcut('HAS_PROPCO', api.utils.general.ocount(propCoProperties) > 0);
        this.setShortcut('NO_PROPCOS', false);
      }
    }

    // Handle Fees
    if (cardIds.includes('facility')) {
      let COMMITMENT_FEE_TRUE, COMMITMENT_FEE_TL, COMMITMENT_FEE_RCF;
      let HAS_FEES = false;

      if (
        fieldNames.includes('pricing/comfee_percentage') ||
        fieldNames.includes('facility/type') ||
        fieldNames.includes('facility/name')
      ) {
        COMMITMENT_FEE_TL = !!(
          state.input.facility &&
          api.utils.general.ofindKey(
            state.input.facility,
            (f) => f['facility/type'] === 'term' && f['pricing/comfee_percentage']
          )
        );
        COMMITMENT_FEE_RCF = !!(
          state.input.facility &&
          api.utils.general.ofindKey(
            state.input.facility,
            (f) => f['facility/type'] === 'rcf' && f['pricing/comfee_percentage']
          )
        );
        COMMITMENT_FEE_TRUE = COMMITMENT_FEE_TL || COMMITMENT_FEE_RCF;
        this.setShortcut('COMMITMENT_FEE_TL', COMMITMENT_FEE_TL);
        this.setShortcut('COMMITMENT_FEE_RCF', COMMITMENT_FEE_RCF);
        this.setShortcut('COMMITMENT_FEE_TRUE', COMMITMENT_FEE_TRUE);

        if (COMMITMENT_FEE_TRUE) {
          const rcfFacilities = api.utils.general.ofilter(
            state.input.facility,
            (f) => f['facility/type'] === 'rcf'
          );
          const termFacilities = api.utils.general.ofilter(
            state.input.facility,
            (f) => f['facility/type'] === 'term'
          );

          const rcfLength = api.utils.general.ocount(rcfFacilities);
          const termLength = api.utils.general.ocount(termFacilities);

          if (rcfLength > 0) {
            const withCommitmentFee = api.utils.general.ofilter(
              rcfFacilities,
              (f) => f['pricing/comfee_percentage']
            );
            let relevantNames;
            if (rcfLength === api.utils.general.ocount(withCommitmentFee)) {
              relevantNames = this.describeConcept('relevant_revolvingFacilityOrFacility');
              relevantNames = relevantNames.text || '[**]';
            } else {
              relevantNames = this.imp_and(
                api.utils.general.omap(withCommitmentFee, (f) => f['facility/name'])
              );
            }

            const allSameFeeLevel =
              api.utils.general.array_homogenous(
                api.utils.general.omap(withCommitmentFee, (f) => f['pricing/comfee_percentage'])
              ) &&
              api.utils.general.array_homogenous(
                api.utils.general.omap(withCommitmentFee, (f) => f['pricing/comfee_calc'])
              );

            const firstWithCommitmentFee = withCommitmentFee[Object.keys(withCommitmentFee)[0]];

            if (allSameFeeLevel && firstWithCommitmentFee) {
              let comFeeText = '';
              const comFeeCalcText = api.interfaces.Contract.getUiInputFieldValue(
                this.contract,
                'pricing/comfee_calc',
                firstWithCommitmentFee['pricing/comfee_calc']
              );
              comFeeText =
                firstWithCommitmentFee['pricing/comfee_percentage'] +
                ' ' +
                this.translateText('percent') +
                ' ' +
                comFeeCalcText;
              if (withCommitmentFee.length > 1) {
                comFeeText +=
                  ' ' +
                  this.translateText('for') +
                  ' ' +
                  (this.describeConcept('relevant_revolvingFacilityOrFacility').text || '[**]');
              }

              this.setVariable('CommitmentFeeRCFPercentage', comFeeText);
            } else {
              let comFeeText = [];
              for (const [, facility] of Object.entries(withCommitmentFee)) {
                const comFeeCalcText = api.interfaces.Contract.getUiInputFieldValue(
                  this.contract,
                  'pricing/comfee_calc',
                  facility['pricing/comfee_calc']
                );
                comFeeText.push(
                  facility['pricing/comfee_percentage'] +
                    ' ' +
                    this.translateText('percent') +
                    ' ' +
                    comFeeCalcText +
                    ' ' +
                    this.translateText('for') +
                    ' ' +
                    facility['facility/name']
                );
              }
              this.setVariable('CommitmentFeeRCFPercentage', this.imp_and(comFeeText));
            }

            this.setVariable('RCFcomFac', relevantNames);
          } else {
          }

          if (termLength > 0) {
            const withCommitmentFee = api.utils.general.ofilter(
              termFacilities,
              (f) => f['pricing/comfee_percentage']
            );
            let relevantNames;
            if (termLength === api.utils.general.ocount(withCommitmentFee)) {
              relevantNames = this.describeConcept('relevant_termFacilityOrFacility');
              relevantNames = relevantNames.text || '[**]';
            } else {
              relevantNames = this.imp_and(
                api.utils.general.omap(withCommitmentFee, (f) => f['facility/name'])
              );
            }

            const allSameFeeLevel =
              api.utils.general.array_homogenous(
                api.utils.general.omap(withCommitmentFee, (f) => f['pricing/comfee_percentage'])
              ) &&
              api.utils.general.array_homogenous(
                api.utils.general.omap(withCommitmentFee, (f) => f['pricing/comfee_calc'])
              );

            const firstWithCommitmentFee = withCommitmentFee[Object.keys(withCommitmentFee)[0]];

            if (allSameFeeLevel && firstWithCommitmentFee) {
              let comFeeText = '';
              const comFeeCalcText = api.interfaces.Contract.getUiInputFieldValue(
                this.contract,
                'pricing/comfee_calc',
                firstWithCommitmentFee['pricing/comfee_calc']
              );
              comFeeText =
                firstWithCommitmentFee['pricing/comfee_percentage'] +
                ' ' +
                this.translateText('percent') +
                ' ' +
                comFeeCalcText;
              if (withCommitmentFee.length > 1) {
                comFeeText +=
                  ' ' +
                  this.translateText('for') +
                  ' ' +
                  (this.describeConcept('relevant_termFacilityOrFacility').text || '[**]');
              }

              this.setVariable('CommitmentFeeTLPercentage', comFeeText);
            } else {
              let comFeeText = [];
              for (const [, facility] of Object.entries(withCommitmentFee)) {
                const comFeeCalcText = api.interfaces.Contract.getUiInputFieldValue(
                  this.contract,
                  'pricing/comfee_calc',
                  facility['pricing/comfee_calc']
                );
                comFeeText.push(
                  facility['pricing/comfee_percentage'] +
                    ' ' +
                    this.translateText('percent') +
                    ' ' +
                    comFeeCalcText +
                    ' ' +
                    this.translateText('for') +
                    ' ' +
                    facility['facility/name']
                );
              }
              this.setVariable('CommitmentFeeTLPercentage', this.imp_and(comFeeText));
            }

            this.setVariable('TLcomFac', relevantNames);
          } else {
          }
        }
      }

      if (
        fieldNames.includes('pricing/comfee_percentage') ||
        fieldNames.includes('facility/type') ||
        fieldNames.includes('facility/name') ||
        fieldNames.includes('pricing/upfront')
      ) {
        const HAS_COMMITMENT_FEE =
          !!(
            state.input.facility &&
            api.utils.general.ofindKey(
              state.input.facility,
              (f) => f['facility/type'] === 'term' && f['pricing/comfee_percentage']
            )
          ) ||
          !!(
            state.input.facility &&
            api.utils.general.ofindKey(
              state.input.facility,
              (f) => f['facility/type'] === 'rcf' && f['pricing/comfee_percentage']
            )
          );
        HAS_FEES = HAS_COMMITMENT_FEE;
        if (!HAS_FEES) {
          // this.log("Check for upfront...");
          const UPFRONT = !!(
            state.input.facility &&
            api.utils.general.ofindKey(state.input.facility, (f) => f['pricing/upfront'] !== '')
          );
          if (UPFRONT) HAS_FEES = true;
        }

        // this.log("HAS FEES", HAS_FEES);
        this.setShortcut('HAS_FEES', HAS_FEES);
      }
    }
  },
};
