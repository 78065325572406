import React, { useMemo, useCallback, useEffect, useState } from 'react';
// import CustomScrollbars from 'util/CustomScrollbars';
import { useSlate } from 'slate-react';
import { Editor, Element } from 'slate';
import { useContract, setContract, useEventState, setEventState, useRerender } from 'hooks/';
import { Contract } from 'core/interfaces';
import { Transforms } from 'slate';
import { blockMenuIcons } from 'core/types/elements';
import { Avatar, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { unwrapNodes, removeBlockByPath } from 'components/editor/legal/helpers';
import { RuleModal, ContractRulesEditor } from 'components/RuleEditor/';
import { ConditionActions, PrintCondition } from 'components/Rules/NodeConditions';
import { ElementsIndicator } from 'components/editor/legal/elements/render/blocks/indicators/ElementsIndicator.js';
import { Tablify } from 'components/ui'

function EditorStructureView({}) {
  const editor = useSlate();
  const elements = Array.from(Editor.nodes(editor)).filter(([element]) => Element.isElement(element));

  const [openElements, setOpenElements] = useState([]);

  const closeStructureView = () => {
    setEventState('ContractStructureModeSwitch', false);
  };

  useEffect(() => {
    setOpenElements([]);
  }, [editor.selection]);

  const openElement = (stringPath) => {
    const isOpen = openElements.find((x) => x === stringPath);
    if (isOpen) {
      setOpenElements(openElements.filter((x) => x !== stringPath));
    } else {
      setOpenElements([...openElements, stringPath]);
    }
  };

  const allElements = [[{ type: 'contract' }, []], ...elements];

  return (
    <div className="contract-structure-holder" tabIndex="-1">
      <div className="header">
        <h5>Document Structure</h5>
        {/* <Button onClick={closeStructureView} icon={<CloseCircleOutlined />} type="link" size="small" /> */}
        <CloseCircleOutlined className="clickable hoverable" onClick={closeStructureView} />
      </div>

      {allElements.map(([element, path], index) => {
        const stringPath = JSON.stringify(path);

        const isOpen = openElements.find((x) => x === stringPath);
        const icon = index === 0 ? 'mdi-file-document' : blockMenuIcons[element.type];
        const avatarStyle = {};
        if (isOpen) avatarStyle.backgroundColor = '#87d068';
        return (
          <div
            className={'contract-structure-item' + (isOpen ? ' open' : '')}
            style={{
              marginLeft: index * 10 + 'px',
            }}
            key={stringPath}
            onMouseEnter={() => index !== 0 && editor.highlightNode(element)}
            onMouseLeave={editor.clearHighlights}
          >
            <div className="flex-row">
              <div
                className="hoverable clickable"
                onClick={() => {
                  openElement(stringPath);
                }}
              >
                <span>
                  {icon && (
                    <Avatar
                      className={'contract-structure-avatar mr-2 ' + (index === 0 ? 'document-avatar' : '')}
                      style={avatarStyle}
                      icon={<i className={'mdi ' + icon} />}
                      size="small"
                    />
                  )}
                </span>
                <span>
                  <span className={'item-type' + (isOpen ? ' open' : '')}>{element.type}</span>{' '}
                  {element.variant && <>({element.variant})</>}
                </span>
              </div>
              <div className="contract-structure-item-indicators">
                <ElementsIndicator editor={editor} elementData={element.data} tooltipPlacement={'left'} />
              </div>
            </div>
            {isOpen && index === 0 && (
              <div className="actions">
                <ContractOpen editor={editor} />
              </div>
            )}
            {isOpen && index !== 0 && (
              <div className="actions">
                <ElementItems editor={editor} element={element} stringPath={stringPath} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

function ContractOpen({ editor }) {
  const contract = useContract();
  // const state = useSelector(state => state)
  const [isEditingRules, setIsEditingRules] = useState(false);
  const toggleEdit = () => {
    setIsEditingRules(!isEditingRules);
  };

  const contractSavedRules = contract.data.create.savedRules;

  return (
    <>
      <div>
        <span>{Object.keys(contractSavedRules).length} saved contract rules</span>
        <small className="ml-2 link" onClick={toggleEdit}>
          Edit rules
        </small>
        {isEditingRules && <ContractRulesEditor contract={contract} onClose={toggleEdit} />}
      </div>
    </>
  );
}

function ElementItems({ editor, element, stringPath }) {
  const items = [];

  // Custom content for certain data properties.
  if (element.data && element.data.acp) {
    items.push(<ElementACP key={'acp'} editor={editor} element={element} stringPath={stringPath} />);
  }
  if (element.data && element.data.each_repeatable) {
    items.push(<ElementRepeatable key={'rep'} editor={editor} element={element} />);
  }

  // Content for variants
  if (element.type === 'field') {
    switch (element.variant) {
      case 'vari':
        items.push(<VariantVari key={'vari'} editor={editor} element={element} stringPath={stringPath} />);
        break;
      case 'item':
        items.push(<VariantItem key={'item'} editor={editor} element={element} stringPath={stringPath} />);
        break;
      case 'group':
        items.push(<VariantGroup key={'group'} editor={editor} element={element} stringPath={stringPath} />);
        break;
      case 'enum':
        items.push(<VariantEnum key={'enum'} editor={editor} element={element} stringPath={stringPath} />);
        break;
      case 'concept':
        items.push(
          <VariantConcept key={'concept'} editor={editor} element={element} stringPath={stringPath} />
        );
        break;
      case 'entity':
        items.push(
          <VariantEntity key={'entity'} editor={editor} element={element} stringPath={stringPath} />
        );
        break;
      case 'ref':
        items.push(<VariantRef key={'ref'} editor={editor} element={element} stringPath={stringPath} />);
        break;
      default:
        break;
    }
  } else {
    switch (element.type) {
      case 'section':
        items.push(<TypeSection key={'section'} editor={editor} element={element} stringPath={stringPath} />);
        break;
      case 'clause':
        items.push(<TypeClause key={'clause'} editor={editor} element={element} stringPath={stringPath} />);
        break;
      case 'heading_one':
        items.push(
          <TypeHeading level={1} key={'heading'} editor={editor} element={element} stringPath={stringPath} />
        );
        break;
      case 'heading_two':
        items.push(
          <TypeHeading level={2} key={'heading'} editor={editor} element={element} stringPath={stringPath} />
        );
        break;
      case 'heading_three':
        items.push(
          <TypeHeading level={3} key={'heading'} editor={editor} element={element} stringPath={stringPath} />
        );
        break;
      case 'heading_four':
        items.push(
          <TypeHeading level={4} key={'heading'} editor={editor} element={element} stringPath={stringPath} />
        );
        break;
      case 'definitions':
        items.push(
          <TypeDefinitions key={'definitions'} editor={editor} element={element} stringPath={stringPath} />
        );
        break;
      case 'numbered_list':
      case 'bulleted_list':
        items.push(<TypeList key={'list'} editor={editor} element={element} stringPath={stringPath} />);
        break;
      case 'list_item':
        items.push(
          <TypeListItem key={'listitem'} editor={editor} element={element} stringPath={stringPath} />
        );
        break;
      default:
        break;
    }
  }

  return items;
}

const unwrapField = (editor, stringPath) => {
  const path = JSON.parse(stringPath);
  unwrapNodes(editor, path);
};
const removeField = (editor, stringPath) => {
  const path = JSON.parse(stringPath);
  removeBlockByPath(editor, path);
};

function CommonFieldActions({ editor, stringPath }) {
  return (
    <>
      <div className="mt-2">
        <span className="link" onMouseDown={() => unwrapField(editor, stringPath)}>
          Remove Field Code
        </span>
        <small> (make ordinary text)</small>
      </div>
      <div className="mt-2">
        <span className="link" onMouseDown={() => removeField(editor, stringPath)}>
          Remove Field
        </span>
        <small> (remove completely, including contents)</small>
      </div>
    </>
  );
}

function VariantVari({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.variant}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">
          <Tablify data={[['Key', element.data.name]]} />
        </div>
        <CommonFieldActions editor={editor} stringPath={stringPath} />
      </div>
    </div>
  );
}
function VariantItem({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.variant}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">
          <Tablify data={[['Key', element.data.each?.key || <em>Unknown</em>]]} />
        </div>
        <CommonFieldActions editor={editor} stringPath={stringPath} />
      </div>
    </div>
  );
}
function VariantGroup({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.variant}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for group.</div>
      </div>
    </div>
  );
}
function VariantEnum({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.variant}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for enum.</div>
      </div>
    </div>
  );
}
function VariantConcept({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.variant}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for concept.</div>
      </div>
    </div>
  );
}
function VariantEntity({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.variant}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for entity.</div>
      </div>
    </div>
  );
}
function VariantRef({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.variant}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for ref.</div>
      </div>
    </div>
  );
}

// Types
function TypeSection({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.type}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for section.</div>
      </div>
    </div>
  );
}
function TypeClause({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.type}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for clause.</div>
      </div>
    </div>
  );
}
function TypeHeading({ editor, element, stringPath, level }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.type}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for heading {level}.</div>
      </div>
    </div>
  );
}
function TypeDefinitions({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.type}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for definitions.</div>
      </div>
    </div>
  );
}
function TypeList({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.type}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for list.</div>
      </div>
    </div>
  );
}
function TypeListItem({ editor, element, stringPath }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.type}</span>.
      </div>
      <div className="ml-2">
        <div className="mt-2">Functions for list item.</div>
      </div>
    </div>
  );
}

function ElementACP({ editor, element, stringPath }) {
  const contract = useContract();
  const [acp, setAcp] = useState(element.data.acp || null);
  const rerender = useRerender();
  const currentRule = Contract.getRule(contract, acp);

  const onChangeRule = (id) => {
    setAcp(id);
    rerender();
  };

  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.type}</span> is conditioned.
      </div>
      <div className="ml-2">
        <div className="mt-2">
          <Tablify
            data={[
              ['Rule Name', currentRule.label ? <strong>{currentRule.label}</strong> : <em>No label</em>],
              [
                'Rule Description',
                currentRule.description ? (
                  <strong>{currentRule.description}</strong>
                ) : (
                  <em>No description</em>
                ),
              ],
            ]}
          />
        </div>
        <div className="mt-2">
          <small>Rule Content Summary</small>
          <small className="d-block">
            <PrintCondition node={element} />
          </small>
        </div>
        <div className="mt-2">
          <ConditionActions node={element} stringPath={stringPath} onChange={onChangeRule} />
        </div>
        <div className="mt-2">
          <span className="link" onMouseDown={() => unwrapField(editor, stringPath)}>
            Remove condition
          </span>
          <small> (make contents ordinary text)</small>
        </div>
        <div className="mt-2">
          <span className="link" onMouseDown={() => removeField(editor, stringPath)}>
            Remove Field
          </span>
          <small> (remove completely, including contents)</small>
        </div>
      </div>
    </div>
  );
}

function ElementACP_old({ editor, element, stringPath }) {
  const contract = useContract();
  // const state = useSelector(state => state)
  const [isEditingRule, setIsEditingRule] = useState(false);
  const [isSwitchingRule, setIsSwitchingRule] = useState(false);
  const currentRule = Contract.getRule(contract, element.data.acp);

  console.log('Current Rule ', currentRule);

  const toggleEdit = () => {
    if (!isEditingRule) setIsSwitchingRule(false);
    setIsEditingRule(!isEditingRule);
  };
  const toggleSwitch = () => {
    if (!isSwitchingRule) setIsEditingRule(false);
    setIsSwitchingRule(!isSwitchingRule);
  };

  const onSave = (rule, ruleId) => {
    // console.log('On save got ', {rule, ruleId});
    return;
  };

  const onSaveAs = (rule, ruleId) => {
    // console.log('Saved AS new rule...', {rule, ruleId})
    const path = JSON.parse(stringPath);
    Transforms.setNodes(
      editor,
      (node) => {
        return {
          data: {
            ...node.data,
            acp: ruleId,
          },
        };
      },
      { at: path }
    );
  };

  const onOk = (rule) => {
    // console.log('OK.', rule)
    setIsEditingRule(false);
  };

  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.type}</span> is conditioned.{' '}
      </div>
      <div className="ml-2">
        <small>
          Rule Name: {currentRule.label ? <strong>{currentRule.label}</strong> : <em>No label</em>}
        </small>
      </div>
      <div>
        {currentRule && !currentRule.code && currentRule.data && (
          <small className="ml-2 link" onClick={toggleEdit}>
            Edit rule
          </small>
        )}
        <small className="ml-2 link" onClick={toggleSwitch}>
          Switch rule
        </small>
        {isEditingRule && (
          <RuleModal
            contract={contract}
            id={element.data.acp}
            onSave={onSave}
            onSaveAs={onSaveAs}
            onOk={onOk}
            onCancel={toggleEdit}
          />
        )}
        {isSwitchingRule && (
          <ContractRulesEditor
            contract={contract}
            onSelect={(id) => console.log('Selected id', id)}
            onClose={toggleSwitch}
          />
        )}
      </div>
    </div>
  );
  // return <RuleModal contract={contract} id={'abc4fad23'} />;

  // return <ContractRulesEditor contract={contract} state={state} onSelect={(id) => console.log('Selected id', id)} />
}

function ElementRepeatable({ editor, element }) {
  return (
    <div className="mb-3">
      <div>
        <span className="text-capitalize">{element.type}</span> content repeats for each{' '}
        <strong>{element.data.each_repeatable.repeatable}</strong>
      </div>
      <div>
        <small className="ml-2 link">Edit repeating settings</small>
      </div>
    </div>
  );
}

export default EditorStructureView;
