export const nodeACPwasUpdated = function (elementData, rule, state, repeatable_state = null) {
  this.log("FIX NODE ACP WAS UPDATED: ", { elementData, rule, state, repeatable_state });

  const itemId = elementData.item_id;
  let currentlyInActive

  const newRulePassed = this.applyLogic(rule, { local: repeatable_state });
  const shallBeInActive = !newRulePassed;

  this.withDraft(() => {
    const elementItems = this.contentSetters((n) => n.data && n.data.item_id === itemId, {
      outsideDraft: false,
    });
    for (const elementItem of elementItems) {
      const updateNode = elementItem.get();
      currentlyInActive = updateNode.data._inActive;
      // this.log('Update Node 1 is ', updateNode)
      elementItem.set({
        ...updateNode,
        data: {
          ...updateNode.data,
          _inActive: !newRulePassed,
          acp: rule,
        },
      });
    }
  });
  /* this.log("acp result ", {
    newRulePassed,
    changed: currentlyInActive !== shallBeInActive,
    currentlyInActive,
    shallBeInActive
  }); */
  return {
    isActive: newRulePassed,
    changed: currentlyInActive !== shallBeInActive,
  };
};
