import {
    SET_NODES,
    UPDATE_NODE,
    INSERT_NODE,
    REMOVE_NODE
} from "constants/ActionTypes";

export const setNodes = (nodes) => {
    return {
        type: SET_NODES,
        payload: nodes
    }
}

export const updateNode = (id, data) => {
    return (dispatch, getState) => {
        const nodes = [...getState().nodes];
        // console.log('updating node', id, data);
        const updatingNodeIndex = nodes.findIndex(data => id === data.id);
        if(updatingNodeIndex === -1) {
            console.log('Could not find node with id '+id, nodes);
            return dispatch({
                type: '__ignore__'
            });
        }
        const node = JSON.parse(JSON.stringify(nodes[updatingNodeIndex]));

        Object.assign(node, data);

        dispatch({
            type: UPDATE_NODE,
            payload: {index: updatingNodeIndex, node}
        });
    }
}

export const _old_updateNode = (orgChart, id, data, pid = undefined) => {
    return (dispatch, getState) => {
        const nodes = [...getState().nodes];
        if(typeof orgChart !== 'object' || !id || typeof data !== 'object') {
            console.log('updateNode requires orgChart obj, id and data');
            return dispatch({
                type: '__ignore__'
            });
        }
        const updatingNodeIndex = nodes.findIndex(data => id === data.id);
        if(updatingNodeIndex === -1) {
            return dispatch({
                type: '__ignore__'
            });
        }
        const node = nodes[updatingNodeIndex];

        if(!pid && pid !== null) { // Top parent has pid == null
            pid = node.parentId;
            if(!pid && pid !== null) {
                pid = orgChart.nodes[id].pid;
                if(!pid) {
                    console.log('Cannot locate pid for node!');
                    return dispatch({
                        type: '__ignore__'
                    });
                }
            }
        }
        if(!pid) {
            console.log('Unknown get pid err');
            return dispatch({
                type: '__ignore__'
            });
        }
        Object.assign(node, data, {parentId: pid});

        // Tmp inactive
        // orgChart.updateNode(id, pid, node, false); // Last arg false -> Don't send event

        dispatch({
            type: UPDATE_NODE,
            payload: {index: updatingNodeIndex, node}
        });
    }
}
export const nodeWasUpdated = (id, pid, data) => {
    return (dispatch, getState) => {
        const nodes = [...getState().nodes];
        
        const updatingNodeIndex = nodes.findIndex(data => id === data.id);
        if(updatingNodeIndex === -1) {
            return dispatch({
                type: '__ignore__'
            });
        }
        const node = JSON.parse(JSON.stringify(nodes[updatingNodeIndex]));
        if(!pid)
            pid = node.parentId;
            
        Object.assign(node, data, {parentId: pid});

        dispatch({
            type: UPDATE_NODE,
            payload: {index: updatingNodeIndex, node}
        });
    }
}

export const insertNode = (orgChart, pid, data) => {
    return (dispatch, getState) => {
        console.log('pid for insert is ', pid, typeof pid);
        console.log('action insertNode not active');
        // Tmp inactive
        // const newNode = orgChart.insertNode(pid, data, undefined, false);
/* 
        const node = Object.assign({}, {id: newNode.id, parentId: pid}, {...data});
        dispatch({
            type: INSERT_NODE,
            payload: node
        }); */
    }
}
export const nodeWasInserted = (id, pid, data) => {
    return {
        type: INSERT_NODE,
        payload: Object.assign({id, parentId: pid}, data)
    }
}

export const removeNode = (orgChart, id) => {
    // Tmp inactive
        // orgChart.removeNode(id, false);
    return {
        type: REMOVE_NODE,
        payload: id,
    }
}

export const nodeWasRemoved = (id) => {
    console.log('removing ', id);
    const removeIds = [id];
    return (dispatch, getState) => {
        const nodes = [...getState().nodes];
        const childrenIds = collectNodeChildren(nodes, id);
        return dispatch({
            type: REMOVE_NODE,
            payload: removeIds.concat(childrenIds)
        });
    }

    /* return {
        type: REMOVE_NODE,
        payload: id
    } */
}

const collectNodeChildren = (nodes, parentId) => {
    let childrenIds = [];
    for(let node of nodes) {
        if(node.parentId !== parentId) continue;
        childrenIds.push(node.id);
        const subChildren = collectNodeChildren(nodes, node.id);
        childrenIds = childrenIds.concat(subChildren);
    }
    return childrenIds;
}