import React from 'react';
import { useSelector } from 'react-redux';
import { getContractValues } from 'hooks';
import { getByPath } from 'core/utils/general';
import { Contract, InputPaths } from 'core/interfaces';

function headerData({ format }) {
  if (!format) return { Tag: 'h4', style: { textDecoration: 'underline' } };
  const { size, bold, underline = true } = format;
  let Tag,
    style = {};
  if (size === 2) Tag = 'h5';
  else Tag = 'h4';

  if (bold) style.fontWeight = '500';
  if (underline) style.textDecoration = 'underline';

  return { Tag, style };
}

function InputHeader(props) {
  const { Tag, style } = headerData(props.field);

  if (props.field?.label?.type === 'values') {
    return (
      <div className="m-10">
        <Tag style={style}>
          <HeaderValues {...props} />
        </Tag>
      </div>
    );
  }

  if (props.field?.label?.type === 'text' && props.field?.label?.values?.[props.language]) {
    return (
      <div className="m-10">
        <Tag style={style}>{props.field.label.values[props.language]}</Tag>
      </div>
    );
  }

  if (props.label || props.label === '') {
    return (
      <div className="m-10">
        <Tag style={style}>{props.label}</Tag>
      </div>
    );
  }

  return (
    <div className="m-10">
      <Tag style={style}>{'--'}</Tag>
    </div>
  );
}

export default InputHeader;

function HeaderValues(props) {
  const contract = getContractValues();

  const { field, path, language } = props;
  const { label } = field;

  const repeatablePaths = InputPaths.extractPathParts(contract, path);

  const localState = useSelector((state) => {
    return repeatablePaths.reduce((acc, curr) => {
      acc = { ...acc, ...getByPath(state, curr) };
      return acc;
    }, {});
  });

  let headerValue = '';

  for (const valueItem of label.values) {
    if (valueItem.type === 'ref') {
      let val = localState[valueItem.target];
      if (valueItem.readable) {
        const readableValue = Contract.getUiInputFieldValue(contract, valueItem.target, val);
        headerValue += readableValue;
      } else headerValue += val || valueItem.default || '[**]';
    } else if (valueItem[language]) {
      headerValue += valueItem[language];
    } else if (valueItem.type === 'text' && valueItem.value) {
      headerValue += valueItem.value;
    }
  }
  return headerValue;
}
