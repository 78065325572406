import React from "react";
import { Form, Checkbox, Tooltip } from "antd";
import { getValue } from "./utils/values";

function InputCheck(props) {
  const { label, handleChange, language, cardId, field, path, cardUid } = props;

  const value = getValue(props, false);

  const checkLabel =
    field.checkLabel && field.checkLabel[language] ? field.checkLabel[language] : "";
  const id = cardId  + cardUid + path;

  const showLabel = (
    <>
      <span>{label}</span>
      {field.helper && field.helper[language] && (
        <Tooltip title={field.helper[language]}>
          <i className="ml-1 mdi mdi-help-circle" />
        </Tooltip>
      )}
    </>
  );

  return (
    <Form.Item
      label={showLabel}
      extra={field.info && <small className="muted">{field.info[language]}</small>}
    >
      <Checkbox
        onChange={(event) => {
          handleChange(event.target.checked, {
            formType: "checkbox",
          });
        }}
        id={id}
        name={id}
        checked={value}
      >
        {checkLabel}
      </Checkbox>
    </Form.Item>
  );
}

export default InputCheck;
