import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useContract, setContract, setEventState } from 'hooks';
import { useLocation } from 'react-router-dom';
import { addRepeatable, addOrdinaryCard, removeOrdinaryCard } from 'appRedux/actions';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Button, Form, Input, Select, Checkbox, Divider } from 'antd';
import { Create } from 'core/interfaces';
import { generateUID } from 'core/utils/general';
import RemoveModal from '../common/RemoveModal';
import { VALUE_INPUTS } from 'core/config/constants';
import { removeCard } from '../utils';
import EditAddConcepts from 'components/TemplateEdit/EditAddConcepts';
const { Option } = Select;

export default function AddEditCard({ selected, onFinish, insertPosition }) {
  const contract = useContract();
  const location = useLocation();
  const dispatch = useDispatch();
  const formatMessage = useIntlMessage();

  // Get all inputs within this card (if its an existing one)
  const currentCard = selected && selected.card ? contract.data.ui.cards[selected.card] : null;
  const contractLanguauge = contract.data.settings.language;

  // Check if there is a corresponding concept for the card in create/build
  const currentConcept =
    currentCard && contract.data.create.build.concepts.find((c) => c.id === currentCard.id);

  // Get all inputs available for repeatable, header type ref
  const inputs = [];
  if (currentCard && currentCard.inputs_order) {
    currentCard.inputs_order.forEach((inputId) => {
      const input = contract.data.ui.inputs[inputId];
      if (input && VALUE_INPUTS.includes(input.type)) {
        inputs.push({ id: inputId, label: input.label[contractLanguauge] });
      }
    });
  }

  let defaultHeaderTarget = null;
  if (currentCard && currentCard.eachHeader && currentCard.eachHeader.target) {
    const input = inputs.find((i) => i.id === currentCard.eachHeader.target);
    if (input) defaultHeaderTarget = currentCard.eachHeader.target;
  }

  const [cardId, setCardId] = useState(currentCard ? currentCard.id : '');
  const [cardIdError, setCardIdError] = useState('');
  const [cardLabel, setCardLabel] = useState(currentCard ? currentCard.header[contractLanguauge] : '');
  const [isConcept, setIsConcept] = useState(currentConcept);
  const [conceptStateKey, setConceptStateKey] = useState(currentConcept && currentConcept.stateKey);
  const [conceptContractParty, setConceptContractParty] = useState(
    currentConcept && currentConcept.contractParty
  );
  const [isRepeatable, setIsRepeatable] = useState(currentCard ? currentCard.isRepeatable : false);
  const [itemHeaderType, setItemHeaderType] = useState(
    (currentCard && currentCard.eachHeader && currentCard.eachHeader.type) || 'fixed'
  );
  const [itemHeader, setItemHeader] = useState(
    (currentCard &&
      currentCard.eachHeader &&
      currentCard.eachHeader.type === 'fixed' &&
      currentCard.label[contractLanguauge]) ||
      ''
  ); // Set from current card
  const [itemHeaderTarget, setItemHeaderTarget] = useState(defaultHeaderTarget);
  const [itemHeaderError, setItemHeaderError] = useState(null);
  const [useCustomId, setUseCustomId] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  const handleCardIdChange = (value) => {
    setCardId(value);
    if (contract.data.ui.cards[value]) {
      setCardIdError(formatMessage('studio.template.cards.errors.idExists') + value);
    } else if (cardIdError) {
      setCardIdError('');
    }
  };

  const handleCardLabelChange = (evt) => {
    const { value } = evt.target;
    setCardLabel(value);
    if (!currentCard && !useCustomId) {
      handleCardIdChange(generateCardId(value));
    }
  };

  // underscores in id doesn't work with concepts.
  const generateCardId = (label) => {
    while (true) {
      const id = `${label
        .replace(' ', '-')
        .replace('_', '-')
        .replace(/[^a-zA-Z_]/g, '')
        .toLowerCase()
        .slice(0, 6)}-${generateUID(4)}`;
      if (!contract.data.ui.cards[id]) {
        return id;
      }
    }
  };

  useEffect(() => {
    if (!currentCard && !useCustomId) {
      handleCardIdChange(generateCardId(cardLabel || 'box'));
    }
  }, []);

  const onRemove = (removeCompletely) => {
    if (!currentCard) return;
    const currentPage = Create.getPageByUrl(contract.data.ui, location.pathname);
    const removeCardAction = removeCard(contract, currentPage, selected.card, removeCompletely);

    dispatch(removeCardAction);
    setContract({ ...contract });
    setShowRemove(false);
    setEventState('template_edit_input_sections', null);
  };

  const addCard = () => {
    if (!cardId || !cardLabel) {
      return console.log('Missing');
    }

    if (currentCard && isRepeatable && itemHeaderType === 'ref' && !itemHeaderTarget)
      return setItemHeaderError(formatMessage('studio.template.cards.errors.itemHeaderTarget'));
    if (isRepeatable && itemHeaderType === 'fixed' && itemHeader.length === 0)
      return setItemHeaderError(formatMessage('studio.template.cards.errors.itemHeaderMissing'));

    // Use existing card or create a new one (important to not override inputs and other props)
    const newCard = currentCard || {
      id: cardId,
      inputs_order: [],
      values: {},
    };

    const wasRepeatable = currentCard && currentCard.isRepeatable;

    newCard.header = {
      en: cardLabel,
      [contractLanguauge]: cardLabel,
    };
    newCard.isRepeatable = isRepeatable;

    if (isRepeatable) {
      newCard.repeatableInfo = {
        parents: [],
        title: {},
        card: cardId,
        defaultNumber: 0, // Sätt från input när den är fixad
      };

      newCard.eachHeader = {
        type: itemHeaderType,
      };

      if (itemHeaderType === 'fixed') {
        // .info ??
        newCard.label = {
          en: itemHeader,
          [contractLanguauge]: itemHeader,
        };
      } else if (itemHeaderType === 'ref') {
        newCard.eachHeader.target = itemHeaderTarget;
        if (contract.data.ui.inputs[itemHeaderTarget].label?.en) {
          newCard.eachHeader.default = `${formatMessage('studio.template.cards.eachHeaderMissing', null, {
            name: contract.data.ui.inputs[itemHeaderTarget].label.en,
          })}`;
        }
      }
    } else {
      delete newCard.repeatableInfo;
      delete newCard.eachHeader;
      delete newCard.label;
    }

    const currentPage = Create.getPageByUrl(contract.data.ui, location.pathname);
    // Only push to page if its a new input
    if (currentPage && !currentCard) {
      if (insertPosition && insertPosition === 'top') currentPage.cards.unshift(cardId);
      else currentPage.cards.push(cardId);
    }

    contract.data.ui.cards[cardId] = newCard;

    // Add/edit/delete concept data if needed
    if (!currentConcept && isConcept) {
      // Add
      contract.data.create.build.concepts.push({
        id: cardId,
        stateId: cardId,
        stateKey: conceptStateKey,
        contractParty: conceptContractParty,
      });
    } else if (currentConcept && !isConcept) {
      // Remove
      const conceptIndex = contract.data.create.build.concepts.indexOf(currentConcept);
      if (conceptIndex >= 0) contract.data.create.build.concepts.splice(conceptIndex, 1);
    } else if (currentConcept && isConcept) {
      // Update
      currentConcept.stateKey = conceptStateKey;
      currentConcept.contractParty = conceptContractParty;
    }

    setContract({
      ...contract,
    });
    setCardId('');
    setCardIdError('');
    setCardLabel('');
    onFinish();

    // Add/update card in the state
    const inputStatePath = `input.${cardId}`;
    if (!currentCard) {
      if (isRepeatable) dispatch(addRepeatable(inputStatePath, {}));
      else dispatch(addOrdinaryCard(inputStatePath, {}));
    } else {
      // If it's an existing card, we just need to do something with the state if it has changed from/to repeatable
      if (wasRepeatable !== isRepeatable) {
        // Save state data first to be used later
        let oldState = contract.data.input[cardId];

        // We remove both ordinary and repeater card with same method (since we want to remove everything from the card level)
        dispatch(removeOrdinaryCard(inputStatePath));
        if (isRepeatable) {
          // Add repeatable with saved state
          dispatch(addRepeatable(inputStatePath, oldState));
        } else {
          const keys = Object.keys(oldState);
          if (keys.length > 0 && keys[0].startsWith('_/re')) {
            oldState = oldState[keys[0]];

            delete oldState._meta;
            delete oldState._uid;

            dispatch(addOrdinaryCard(inputStatePath, oldState));
          }
        }
      }
    }
  };

  const shallSetCustomId = !currentCard && useCustomId;

  return (
    <div>
      {showRemove && <RemoveModal type="card" onRemove={onRemove} />}
      <Form layout="vertical">
        <Form.Item
          label={<IntlMessages id="studio.template.cards.label.label" />}
          extra={
            <div>
              <small>
                <IntlMessages id="studio.template.cards.label.info" />
              </small>
            </div>
          }
        >
          <Input
            type="text"
            value={cardLabel}
            onChange={handleCardLabelChange}
            placeholder={formatMessage('studio.template.cards.label.placeholder')}
          />
        </Form.Item>
        <Input.Group compact>
          {!currentCard && (
            <Form.Item
              label={<>&nbsp;</>}
              style={{ width: '35%' }}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.cards.customId.info" />
                  </small>
                </div>
              }
            >
              <Checkbox
                className="boxed-checkbox"
                checked={useCustomId}
                onChange={() => setUseCustomId(!useCustomId)}
              >
                <IntlMessages id="studio.template.cards.customId.label" />
              </Checkbox>
            </Form.Item>
          )}
          {shallSetCustomId ? (
            <Form.Item
              label={<IntlMessages id="studio.template.cards.id.label" />}
              style={{ width: '55%' }}
              className="ml-2"
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.cards.id.info" />
                  </small>
                  {cardIdError && <div className="text-warning">{cardIdError}</div>}
                </div>
              }
            >
              <Input
                type="text"
                value={cardId}
                onChange={(evt) => handleCardIdChange(evt.target.value)}
                disabled={!!currentCard || !useCustomId}
                placeholder={formatMessage('studio.template.cards.id.placeholder')}
              />
            </Form.Item>
          ) : (
            <Form.Item
              style={{ width: '55%' }}
              className="ml-2"
              label={<IntlMessages id="studio.template.pages.id.label" />}
            >
              <em className="pl-2" style={{ fontWeight: '600' }}>
                {cardId}
              </em>
            </Form.Item>
          )}
        </Input.Group>
        <Divider orientation="left" className="mb-0">
          Repeatable Settings
        </Divider>
        <Form.Item
          className="mt-2"
          extra={
            <div>
              <small>
                <IntlMessages id="studio.template.cards.isRepeatable.info" />
              </small>
            </div>
          }
        >
          <Checkbox
            checked={isRepeatable}
            className="boxed-checkbox"
            onChange={() => {
              if (isRepeatable) setIsConcept(false); // If it's not a repeatable anymore, it cannot be a concept
              setIsRepeatable(!isRepeatable);
            }}
          >
            <IntlMessages id="studio.template.cards.isRepeatable.label" />
          </Checkbox>
        </Form.Item>
        {isRepeatable && (
          <div className="ml-2">
            <Form.Item
              label={<IntlMessages id="studio.template.cards.itemHeaderType.label" />}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.cards.itemHeaderType.info" />
                    {!currentCard && (
                      <>
                        <br />
                        <IntlMessages id="studio.template.cards.itemHeaderType.infoExtra" />
                      </>
                    )}
                  </small>
                </div>
              }
            >
              <Select onChange={(value) => setItemHeaderType(value)} className="w-100" value={itemHeaderType}>
                <Option value="fixed">
                  <IntlMessages id="studio.template.cards.itemHeaderType.options.fixed" />
                </Option>
                {currentCard && (
                  <Option value="ref">
                    <IntlMessages id="studio.template.cards.itemHeaderType.options.ref" />
                  </Option>
                )}
              </Select>
            </Form.Item>
            {itemHeaderType === 'fixed' && (
              <Form.Item
                label={<IntlMessages id="studio.template.cards.headerFixed.label" />}
                extra={
                  <div>
                    <small>
                      <IntlMessages id="studio.template.cards.headerFixed.info" />
                    </small>
                    {itemHeaderError && <div className="text-warning">{itemHeaderError}</div>}
                  </div>
                }
              >
                <Input
                  type="text"
                  value={itemHeader}
                  onChange={(evt) => setItemHeader(evt.target.value)}
                  placeholder={formatMessage('studio.template.cards.headerFixed.placeholder')}
                />
              </Form.Item>
            )}
            {itemHeaderType === 'ref' && (
              <Form.Item
                label={<IntlMessages id="studio.template.cards.headerRef.label" />}
                extra={
                  <div>
                    <small>
                      <IntlMessages id="studio.template.cards.headerRef.info" />
                    </small>
                    {itemHeaderError && <div className="text-warning">{itemHeaderError}</div>}
                  </div>
                }
              >
                <Select
                  onChange={(value) => setItemHeaderTarget(value)}
                  className="w-100"
                  value={itemHeaderTarget}
                >
                  {inputs.map(({ id, label }) => (
                    <Option value={id} key={`option_${id}`}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </div>
        )}
        <Divider orientation="left" className="mb-0">
          Concept Settings
        </Divider>
        <Form.Item
          className="mt-2"
          extra={
            <div>
              <small>
                <IntlMessages id="studio.template.cards.isConcept.info" />
              </small>
            </div>
          }
        >
          <Checkbox className="boxed-checkbox" checked={isConcept} onChange={() => setIsConcept(!isConcept)}>
            <IntlMessages id="studio.template.cards.isConcept.label" />
          </Checkbox>
        </Form.Item>
        {isConcept && (
          <div className="ml-2">
            <EditAddConcepts contract={contract} id={cardId} mode="edit" contentType="button" onNewBox>
              Edit Concept
            </EditAddConcepts>
          </div>
        )}
      </Form>
      <Divider />
      <Button
        type="primary"
        block
        onClick={addCard}
        disabled={cardIdError || cardId === '' || cardLabel === ''}
      >
        {currentCard
          ? formatMessage('studio.template.cards.updateButton')
          : formatMessage('studio.template.cards.addButton')}
      </Button>
      {currentCard && (
        <Button
          type="danger"
          block
          onClick={() => setShowRemove(true)}
          className="mt-3"
          disabled={cardIdError || cardId === '' || cardLabel === ''}
        >
          {formatMessage('studio.template.cards.removeButton')}
        </Button>
      )}
    </div>
  );
}
