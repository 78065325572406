import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, Row, Tag } from 'antd';
import { useCustomer } from 'hooks';
import { Contract } from 'core/interfaces';
import AboutItem from 'components/project/Info/InfoItem';
import { ucfirst, uuidColor, splitNumber } from 'core/utils/general';
import { supportedCurrencies, supportedCurrenciesColors } from 'core/config/constants';
import { fixDate, userToName } from 'components/ui';
import IntlMessages from 'util/IntlMessages';
import api from 'utils/api';

export default function Summary({ project, contract, options = {} }) {
  const initCustomer = useCustomer();
  const language = Contract.getLanguage(contract);
  const [customer, setCustomer] = useState(initCustomer ? initCustomer.customer : false);
  useEffect(() => {
    if (!contract.customerId) return setCustomer(null);
    if (initCustomer && initCustomer.customer && initCustomer.customer.id === contract.customerId) return;
    (async function fetchCustomer() {
      try {
        const custFetch = await api.get('/customers/' + contract.customerId);
        if (custFetch && custFetch.data) setCustomer(custFetch.data);
      } catch (err) {
        console.log('cannot fetch customer ', err);
      }
    })();
  }, [initCustomer, contract.customerId]);

  if (!contract.info || !contract.info._summary) return <div>No summary available</div>;
  const { _summary: summary } = contract.info;
  const {
    values,
    valuesData,
    contractParties,
    clauseStatuses = 0,
    contractTasks = 0,
    contentCounts,
  } = summary;
  const { contractValue, terminationDate, jurisdiction } = values;
  const { sections, headings, clauses } = contentCounts;

  console.log('summary ? ', summary);
  let contractValueContent = contractValue || <em>Unknown</em>;
  if (valuesData && valuesData.contractValue) {
    contractValueContent = (
      <div>
        {' '}
        <div>
          {Object.entries(valuesData.contractValue).map(([currency, amount]) => (
            <Tag key={currency} color={supportedCurrenciesColors[currency]}>{`${currency} ${splitNumber(
              amount,
              language
            )}`}</Tag>
          ))}
        </div>
        {valuesData.contractValueTotal && (
          <>
            {/* <Divider orientation="left" className="m-0">
              Total
            </Divider> */}
            <small className="d-block ml-2">
              <strong>Total</strong>
            </small>
            <Tag color={supportedCurrenciesColors[valuesData.contractValueTotal.currency]}>{`${
              valuesData.contractValueTotal.currency
            } ${splitNumber(valuesData.contractValueTotal.amount, language)}`}</Tag>
          </>
        )}
      </div>
    );
  }

  let contractPartyTags = null;
  if (contractParties && contractParties.length > 0) {
    contractPartyTags = contractParties.map((party) => (
      <Tag key={party.id} color={'orange'}>
        {userToName(party)}
      </Tag>
    ));
  } else {
    contractPartyTags = <em>No parties</em>;
  }

  const { withHeader = true } = options;

  return (
    <Card
      bodyStyle={{ paddingTop: 0 }}
      title={
        withHeader && (
          <>
            <IntlMessages id="app.general.Summary" />
          </>
        )
      }
    >
      <Row>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <AboutItem
            data={{
              title: <IntlMessages id="apply.parties" />,
              icon: 'user',
              desc: contractPartyTags,
              color: 'primary',
            }}
          />
        </Col>
        {customer && (
          <>
            <Col xl={12} lg={24} md={24} sm={24} xs={24}>
              <AboutItem
                data={{
                  title: 'Customer',
                  icon: 'user-o',
                  desc: customer.name,
                  color: 'primary',
                }}
              />
            </Col>
          </>
        )}
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <AboutItem
            data={{
              title: 'Clauses to track',
              icon: 'affix',
              desc: clauseStatuses || <em>None</em>,
              color: 'primary',
            }}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <AboutItem
            data={{
              title: 'Contract Tasks',
              icon: 'callout',
              desc: contractTasks || <em>None</em>,
              color: 'primary',
            }}
          />
        </Col>
        <Divider />
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <AboutItem
            data={{
              title: 'Contract Value',
              icon: 'diamond',
              desc: contractValueContent,
              color: 'green',
            }}
          />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <AboutItem
            data={{
              title: 'Termination Date',
              icon: 'chart-composed',
              desc: terminationDate ? fixDate(terminationDate, { onlyDate: true }) : <em>Unknown</em>,
              color: 'green',
            }}
          />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <AboutItem
            data={{
              title: 'Disputes',
              icon: 'map-km-layer',
              desc: jurisdiction ? <Tag>{jurisdiction}</Tag> : <em>Unknown</em>,
              color: 'green',
            }}
          />
        </Col>
        <Divider />

        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <AboutItem
            data={{
              title: 'Sections',
              icon: 'anchor',
              desc: sections || 0,
            }}
          />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <AboutItem
            data={{
              title: 'Main Headings',
              icon: 'tab',
              desc: headings || 0,
            }}
          />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <AboutItem
            data={{
              title: 'Clauses total',
              icon: 'social',
              desc: clauses || 0,
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}
