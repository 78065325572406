import { Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { uuidColor } from 'core/utils/general';
import userToName from './userToName';

export default function UserAvatar({
  user,
  className,
  style = {},
  withTooltip = false,
  tooltipExtra = null,
}) {
  const { color } = user && user.id ? uuidColor(user.id, { mode: 'dark' }) : '#ccc';
  const avatar = (
    <Avatar className={className} style={{ ...style, backgroundColor: color }} icon={<UserOutlined />} />
  );
  if (withTooltip) {
    const tooltip = (
      <>
        <div>{userToName(user)}</div>
        {tooltipExtra && <div>{tooltipExtra}</div>}
      </>
    );
    return (
      <Tooltip title={tooltip} placement="top">
        {avatar}
      </Tooltip>
    );
  }
  return avatar;
}
