import React, { useState } from 'react';
import { Row, Col, Spin, Button, Tooltip } from 'antd';
import NewFolderButton from 'components/project/NewProject/NewFolderButton';
import { useMountFetch, useSearch } from 'hooks';
import WidgetHeader from 'components/WidgetHeader/index';
import IntlMessages from 'util/IntlMessages';
import { ProjectsTable } from '../projects/index';
import ProjectWidget from 'components/Widgets/ProjectWidget';
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';

export default function Projects() {
  const [projects, setProjects] = useState(null);
  const [projectsError, setProjectsError] = useState('');
  const [viewMode, setViewMode] = useState('list');

  const setFetchedProjects = (fetchedProjects) => {
    if (!Array.isArray(fetchedProjects)) return;
    if (projects) return;
    setProjects(fetchedProjects);
  };

  const projectWasCreated = (project) => {
    setProjects([project, ...projects]);
  };
  const projectWasRemoved = (id) => {
    setProjects([...projects.filter((project) => project.id !== id)]);
  };

  useMountFetch(
    '/projects?includePersonal&fields=id,name,description,isSecret,isPersonal,isFolder,createdAt&order=desc&where=isFolder_->_true',
    setFetchedProjects,
    setProjectsError
  );

  const showList = () => setViewMode('list');
  const showGrid = () => setViewMode('grid');

  const [filteredProjects, searchElement] = useSearch(projects, {
    element: true,
    fields: ['name', 'description'],
  });

  return (
    <>
      <WidgetHeader
        title={<IntlMessages id="app.projects.Folders" />}
        styleName="mb-3"
        extra={
          <>
            <span className="mr-3">
              <Tooltip title={'Show folders as list'} placement="bottom">
                <Button
                  size="middle"
                  className={'m-0 ' + (viewMode === 'list' ? 'active' : '')}
                  type="ghost"
                  onClick={showList}
                >
                  <UnorderedListOutlined />
                </Button>
              </Tooltip>
              <Tooltip title={'Show folders as grid'} placement="bottom">
                <Button
                  size="middle"
                  className={'m-0 ml-3 ' + (viewMode === 'grid' ? 'active' : '')}
                  type="ghost"
                  onClick={showGrid}
                >
                  <AppstoreOutlined />
                </Button>
              </Tooltip>
            </span>
            {searchElement}
            <NewFolderButton projectWasCreated={projectWasCreated} className="" />
          </>
        }
      />

      {!projects && (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Spin size="large" />
        </div>
      )}

      {projectsError && (
        <div className="d-flex align-items-center justify-content-center h-100">{projectsError}</div>
      )}

      {projects && !projectsError && viewMode === 'list' && (
        <div className="full-table-view transparent-th">
          <ProjectsTable
            projects={filteredProjects}
            projectWasRemoved={projectWasRemoved}
            type="folder"
          />
        </div>
      )}
      {projects && !projectsError && viewMode === 'grid' && (
        <Row>
          {filteredProjects.map((pr) => (
            <Col xs={12} lg={6} key={pr.id}>
              <ProjectWidget key={pr.id} project={pr} projectWasRemoved={projectWasRemoved} type="folder" />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}
