
export const eachesOnRepeatableRemove = {
  id: "eachesOnRepeatableRemove",
  dependencies: { repeatableRemove: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    // Parents are all the nodes parents all to the top.
    // Closest parent first in parents array.
    return (node, parents, index) => {
      if (!node.data || !node.data._path || !paths.includes(node.data._path)) return;
      const parent = parents[0];
      /* this.log("Shall remove repeatable at index: ", index, {
        parent: JSON.parse(JSON.stringify(parent)),
        node: JSON.parse(JSON.stringify(node)),
        removeNode: JSON.parse(JSON.stringify(parent.children[index])),
      }); */
      parent.children.splice(index, 1);

      // Insert a placeholder if otherwise empty
      if (index === 0 && parent.children.length === 0) {
        parent.children = this.generateEachPlaceholderChild(parent);
      }

      api.utils.engine.itemJoiner(this, parent);
    };
  },
};
