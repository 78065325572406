import React, { useState } from "react";
import {
  Form,
  Input,
} from "antd";

export default function InputTypeNumeric ({ onChildUpdate, currentInput }) {
  const [defaultValue, setDefaultValue] = useState(currentInput ? currentInput.value : '');

  const onChangeDefaultValue = (evt) => {
    const { value } = evt.target;
    if (!isNaN(value)) {
      setDefaultValue(value);
      onChildUpdate({ defaultValue: value });
    }
  };
  
  return (
    <>
      <Form layout="vertical">
        <Form.Item
          label={"Default value"}
          // Should have rules. But rules doesn't work. Why?
          // rules={[{ required: true, type: 'number', message: 'Must be a number' }]}
        >
          <Input
            type="text"
            value={defaultValue}
            onChange={onChangeDefaultValue}
            placeholder="Enter default value (if any)"
          />
        </Form.Item>
      </Form>
    </>
  );
}
