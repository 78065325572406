
/**
 * startFinaancingPackage - Main function for module set-up of project
 * 
 * @params   {object}   states, actions, apis, status
 *                      (states comprises 'project' with a 'setup' property of user settings)
 * @returns  n/a         
 */

async function startFinancingPackage({ states, actions, apis, status }) {

    if(status.importedState) return
    if(states.customer && states.customer.data && states.customer.data.nodes && actions.setNodes) {
      actions.setNodes(states.customer.data.nodes)
    }

    // Fetch the group structure for the registration number set by the user (if any)
    /* 
    const regno = apis('setup', 'regno');
    if(!regno) return
    apis('getOrgStructure', regno)
    .then(result => {
        if(result.data && result.data.status === 'ok' && result.data.data && result.data.data.nodes) {
            // Use the actions API to set nodes (companies) using the fetched data
            actions.setNodes(result.data.data.nodes);
        }
    })
    .catch(error => {
        // There was an error in fetching the code. 
    });
     */
}
// Module data
export default [
    {
        id: 'financingPackageCreationStart',
        function: startFinancingPackage,
        info: {
            name: 'Sets up initial values for financing package document creation',
            category: 'advanced',
        }
    }
];




