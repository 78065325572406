import React, { useState, createContext, useContext, useEffect } from 'react';

let _globals = {};

// https://codesandbox.io/s/wrap-usecontext-in-react-hooks-drhu2?from-embed
const superContext = (id) => {
  const Context = createContext();
  let setSuperState, addSuperState;
  if (id) _globals[id] = {};

  const clearContext = () => {
    setSuperState = null;
    addSuperState = null;
    if (id) delete _globals[id];
  };

  const Provider = ({ initialValue, value, children }) => {
    // const [state, setState] = useState(initialValue || (id && _globals[id]) || value);

    const initialState = initialValue || value || (id && _globals[id]) || {};

    // const state = initialState
    const [state, setState] = useState(initialState);

    // console.log('INIt .. ', {id, initialValue, gid: (id && _globals[id]) })

    // const state = initialValue || (id && _globals[id]) || value
    // const state = initialValue || value

    if (value && value !== state) {
      console.log('Update context master state by forced.');
      // setState(value)
    }

    if (id) {
      _globals[id] = state;
    }
    setSuperState = (newValue) => {
      // console.trace('set super state ', id, id ? 'same: ' + (newValue === _globals[id]) : '-x-', newValue);
      if (id) {
        if (newValue === null) delete _globals[id];
        else _globals[id] = newValue;
      }
      // if (id === 'contractUpdateContext') console.log('set super state');
      setState(newValue);
    };
    addSuperState = (newValue) => {
      if (id) {
        _globals[id] = { ..._globals[id], newValue };
      }
      // if (id === 'contractUpdateContext') console.log('Add super state');
      setState({ ...state, ...newValue });
    };

    // console.log('super state rerender ', id)

    useEffect(() => {
      return () => {
        // setState(null)
        console.log('Unmount. Clear Context ', id);
        clearContext();
      };
    }, []);

    return <Context.Provider value={state}>{children}</Context.Provider>;
    // return <Context.Provider value={initialValue || value}>{children}</Context.Provider>;
  };

  return {
    Provider,
    useContext: () => useContext(Context),
    setContext: (value) => {
      if (typeof setSuperState === 'function') setSuperState(value);
      else if (_globals[id]) _globals[id] = value;
    },
    addContext: (value) => {
      if (typeof setSuperState === 'function') addSuperState(value);
      else if (_globals[id]) _globals[id] = { ..._globals[id], ...value };
    },
    getGlobal: () => (id ? _globals[id] : undefined),
    get: (arg) => (id ? _globals[id] && _globals[id][arg] : undefined),
    clearContext,
  };
};

export const ModalContext = superContext();

export const AlertContext = superContext();

export const CustomerContext = superContext('customer');

export const ProjectContext = superContext('project');

export const ContractContext = superContext('contract');

export const DraftContext = superContext('draft');

export const EditorCommentContext = superContext('editor_comment');

export const ContractUpdateContext = superContext('contractUpdateContext');

export const StudioEditorInputContent = superContext('studioEditorInputContent');

export const WarningsContext = superContext('warnings');

export const PermissionsTableContext = React.createContext(null);
