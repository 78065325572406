import React, { useState, useEffect, useRef } from 'react';
import { Form, Select, Modal, Input, Drawer, Button, AutoComplete } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateLegalPerson } from 'appRedux/actions';
import { InfoCircleOutlined, PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import api from 'utils/api';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { PERSON_RELATION_CAPACITIES } from 'core/config/constants';
import countries from 'routes/studio/Views/Input/renderDraftUI/components/orgchart/views/countries.json';
import updateStudioDrafts from 'utils/saving/updateStudioDrafts';
import EditRealPerson from 'components/RealPerson/EditRealPerson';

const { Option } = Select;
const { TextArea } = Input;

export default function EditLegalPerson({
  container = 'modal',
  placement = 'left',
  id,
  onCancel,
  autoSelectName = false,
  onEditCallback,
}) {
  const [editId, setEditId] = useState(null);
  useEffect(() => {
    setEditId(id);
  }, [id]);

  const cancel = () => {
    setEditId(null);
    if (typeof onCancel === 'function') onCancel();
  };

  if (!editId) return null;

  if (container === 'drawer') {
    return (
      <DrawerContainer onCancel={cancel} placement={placement}>
        <Edit
          id={editId}
          onCancel={cancel}
          withButtons
          autoSelectName={autoSelectName}
          placement={placement}
          onEditCallback={onEditCallback}
        />
      </DrawerContainer>
    );
  } else {
    return (
      <ModalContainer onCancel={cancel}>
        <Edit id={editId} onCancel={cancel} autoSelectName={autoSelectName} />
      </ModalContainer>
    );
  }
}

function DrawerContainer({ children, onCancel, placement }) {
  return (
    <Drawer
      title={<IntlMessages id="app.legalPersons.editEntity" />}
      placement={placement}
      width={720}
      onClose={onCancel}
      visible={true}
      bodyStyle={{ paddingTop: 40, paddingBottom: 80 }}
    >
      {children}
    </Drawer>
  );
}

function ModalContainer({ children, onCancel }) {
  return (
    <Modal
      title={<IntlMessages id="app.legalPersons.editEntity" />}
      visible={true}
      onCancel={onCancel}
      cancelText={<IntlMessages id="desc.Cancel" />}
      okText={<IntlMessages id="desc.Save" />}
      okButtonProps={{
        form: 'edit-entity-form',
        key: 'submit',
        htmlType: 'submit',
      }}
      backdrop={'static'}
      size={'large'}
      className="modal-medium"
    >
      {children}
    </Modal>
  );
}

function Edit({ id, onCancel, withButtons = false, placement, autoSelectName = false, onEditCallback }) {
  const legalPersons = useSelector((state) => state.legalPersons);
  const legalPerson = legalPersons.find((person) => person.id === id);
  const [realPersons, setRealPersons] = useState([]);
  const [personRelations, setPersonRelations] = useState([]);
  const [form] = Form.useForm();

  async function fetchData() {
    const realPersonsResponse = await api.get(`/realpersons`);

    setRealPersons(realPersonsResponse.data);
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  const nameRef = useRef();
  const dispatch = useDispatch();

  const updatePersonRelation = (realPersonId, data) => {
    const personIndex = personRelations.findIndex((item) => item.realPersonId === realPersonId);
    setPersonRelations([
      ...personRelations.slice(0, personIndex),
      data,
      ...personRelations.slice(personIndex + 1),
    ]);
  };
  const removePersonRelation = (realPersonId) => {
    setPersonRelations(personRelations.filter((relation) => relation.realPersonId !== realPersonId));
  };
  const addPersonRelation = (data) => {
    setPersonRelations([...personRelations, data]);
  };

  const addRealPerson = (person) => {
    setRealPersons([...realPersons, person]);
  }

  // FIX!!
  useEffect(() => {
    // Should fetch relations through API. Now dummy data.
    if (!legalPerson.RealPersons) return;

    setPersonRelations(legalPerson.RealPersons.map((realPerson) => realPerson.PersonRelation || []).flat());
  }, [legalPerson.RealPersons, id]);

  const onFinish = (values) => {
    if (values.notes) {
      values.data = { notes: values.notes };
    }

    // Add new relations to the legal person
    const newRealPersonList = personRelations.map((pr) => {
      const person = realPersons.find((rp) => rp.id === pr.realPersonId);
      return {
        ...person,
        PersonRelation: pr,
      };
    });
    values.RealPersons = newRealPersonList;

    dispatch(updateLegalPerson(id, values));
    
    // TBD.
    // updateStudioDrafts();
    // If running updateStudioDrafts, this will cause the
    // engine to run (through `forwardDraftMethod` of the manager),
    // not considering the updated redux state. This will cause the
    // engine to not use the latest state. TBD work-around.

    onCancel();
    if (onEditCallback) onEditCallback();
  };

  const onFinishFailed = (errorInfo) => {
  };

  useEffect(() => {
    if (autoSelectName && nameRef.current) {
      setTimeout(() => {
        nameRef.current.focus();
        nameRef.current.select();
      }, 10);
    }
  }, [autoSelectName, nameRef]);

  if (!id || !legalPerson) {
    return null;
  }

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="edit-entity-form"
        initialValues={{
          ...legalPerson,
          emails: Array.isArray(legalPerson.emails) ? legalPerson.emails : [],
          notes: (legalPerson.data && legalPerson.data.notes) || '',
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={<IntlMessages id="app.general.Name" />}
          name="name"
          rules={[{ required: true, message: <IntlMessages id="app.legalPersons.validation.name" /> }]}
        >
          <Input name="name" ref={nameRef} />
        </Form.Item>

          <Form.Item label={<IntlMessages id="app.legalPersons.parentCompany" /> } name="parentId">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {legalPersons.map((lp) => (
              <Option key={lp.id} value={lp.id}>
                {lp.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={<IntlMessages id="app.general.IdentificationNumber" />}
          name="identificationNumber"
          // rules={[{ required: true, message: 'Please input Identification Number!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={<IntlMessages id="app.general.Jurisdiction" />} name="jurisdiction">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {countries.map((country) => (
              <Option key={country.code} value={country.code}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={<IntlMessages id="app.general.Street" />} name="street">
          <Input />
        </Form.Item>
        <Form.Item label={<IntlMessages id="app.general.City" />} name="city">
          <Input />
        </Form.Item>
        <Form.Item label={<IntlMessages id="app.general.Zip" />} name="zipcode">
          <Input />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="app.legalPersons.contacts" />}
          name="personRelations"
          tooltip={{
            title: <IntlMessages id="app.legalPersons.contactsTooltip" />,
            icon: <InfoCircleOutlined />,
          }}
        >
          <ListPersonRelations
            addPersonRelation={addPersonRelation}
            removePersonRelation={removePersonRelation}
            updatePersonRelation={updatePersonRelation}
            addRealPerson={addRealPerson}
            personRelations={personRelations}
            legalPerson={legalPerson}
            realPersons={realPersons}
          />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="app.persons.emails" />}
          name="emails"
          tooltip={{
            title: <IntlMessages id="app.persons.emailsTooltip" />,
            icon: <InfoCircleOutlined />,
          }}
          // extra="Separate emails by space or comma"
        >
          <Select mode="tags" />
        </Form.Item>

        <Form.Item label={<IntlMessages id="app.legalPersons.notes" />} name="notes">
          <TextArea rows={4} />
        </Form.Item>

        {withButtons && (
          <div className={'m-3 float-' + (placement === 'left' ? 'right' : 'left')}>
            <Button onClick={onCancel}>
              <IntlMessages id="desc.Cancel" />
            </Button>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="desc.Save" />
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}



function ListPersonRelations({
  addPersonRelation,
  removePersonRelation,
  updatePersonRelation,
  addRealPerson,
  personRelations,
  legalPerson,
  realPersons,
}) {
  // const [capacities, setCapacities] = useState(personRelations.capacities)

  const [addingNewPerson, setAddingNewPerson] = useState(null);
  const [creatingNewPerson, setCreatingNewPerson] = useState(false);
  const formatMessage = useIntlMessage();

  const handleCapacityChange = (realPersonId, value) => {
    updatePersonRelation(realPersonId, {
      realPersonId,
      legalPersonId: legalPerson.id,
      capacities: value,
    });
  };
  const handleSelectNewPerson = (value, option) => {
    if (option.key === '__add_new') {
      setCreatingNewPerson(true);
      
    } else {
      addPersonRelation({
        legalPersonId: legalPerson.id,
        realPersonId: value,
        capacities: [],
      });
      setAddingNewPerson(false);
    }
  };

  const onCreatePersonCallback = (data) => {
    if (data.type === 'add') {
      addPersonRelation(data.relation);

      //addPerson()
      const newPerson = { ...data.values, PersonRelation: data.relation };
      addRealPerson(newPerson);

      setAddingNewPerson(false);
      setCreatingNewPerson(false);
    }
  };

  const capacitiesChildren = PERSON_RELATION_CAPACITIES.map((capacity) => {
    return {
      id: capacity,
      value: formatMessage(`app.persons.capacities.${capacity}`),
    }
  });

  const nonRelatedRealPersons = realPersons.filter(
    (realPerson) => !personRelations.find((relation) => relation.realPersonId === realPerson.id)
  );

  return (
    <div className="w-100">
      <div>
        {personRelations.map((relation) => {
          const realPerson = realPersons.find((person) => person.id === relation.realPersonId);

          if (!realPerson) return null;
          const capacities = relation.capacities;
          return (
            <div key={relation.realPersonId} className="flex-column m-2" style={{ fontSize: '12px' }}>
              <div>
                <strong>{realPerson.firstName} {realPerson.lastName}</strong>
                <span className="ml-3 link" onClick={() => removePersonRelation(relation.realPersonId)}>
                  <CloseCircleOutlined /> Remove
                </span>
              </div>

              <div className="ml-2">
                <h6>Capacities</h6>
                <Select
                  mode="tags"
                  size="small"
                  value={capacities}
                  style={{ width: '100%' }}
                  placeholder="Capacities"
                  onChange={(value) => handleCapacityChange(relation.realPersonId, value)}
                >
                  {capacitiesChildren.map((cap) => (
                    <Select.Option key={cap.id} value={cap.value}>
                      {cap.value}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ fontSize: '13px' }} className="link">
        {addingNewPerson ? (
          <div>
            <AutoComplete
              style={{ width: 200 }}
              placeholder="Search person"
              filterOption={(inputValue, option) => {
                return option.name && option.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
              }}
              onChange={handleSelectNewPerson}
            >
              <Option key={'__add_new'} value="" className="border-bottom">
                <span>
                  <PlusCircleOutlined /> Add new
                </span>
              </Option>
              {nonRelatedRealPersons.map((realPerson) => (
                <Option
                  key={realPerson.id}
                  value={realPerson.id}
                  name={realPerson.firstName + ' ' + realPerson.lastName}
                >
                  {realPerson.firstName} {realPerson.lastName}
                </Option>
              ))}
            </AutoComplete>
            <span className="link" onClick={() => setAddingNewPerson(false)}>
              <CloseCircleOutlined /> Abort
            </span>
          </div>
        ) : (
          <div onClick={() => setAddingNewPerson(true)}>
            <PlusCircleOutlined /> Add person to {legalPerson.name}
          </div>
        )}
        {creatingNewPerson && (
          <EditRealPerson
            onCancel={() => setCreatingNewPerson(false)}
            legalPerson={legalPerson}
            onFinishCallback={onCreatePersonCallback}
            saveRelation={false}
          />
        )}
      </div>
    </div>
  );
}

/*
  capacities: ["legal"]
  legalPersonId: "94eb0c9e-832b-4e07-8522-32efca1a391a"
  realPersonId: "e82b5f51-0964-4839-944e-fd479da743d6"
  */
