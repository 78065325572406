import 'regenerator-runtime/runtime';
import { all, takeEvery, select } from 'redux-saga/effects';

import {
  UPDATE_NODE,
  INSERT_NODE,
  REMOVE_NODE,
  SET_LEGALPERSONS,
  UPDATE_LEGALPERSON,
  INSERT_LEGALPERSON,
  REMOVE_LEGALPERSON,
  UPDATE_REALPERSON,
  INSERT_REALPERSON,
  REMOVE_REALPERSON,
  UPDATE_INPUT,
  UPDATE_INPUTS,
  SET_CONNECT_INPUT_CARDS,
  ADD_REPEATABLE,
  REMOVE_REPEATABLE,
  ADD_QA,
  REMOVE_QA,
  UPDATE_QA,
} from 'constants/ActionTypes';
import { reduxEngine } from 'config/config';
import manager from 'core/engine/manager';

const { REPEATABLE_ADDED_TIMEOUT, MODULES_ADDED_TIMEOUT, MODULES_AWAIT_TIME } = reduxEngine;

/**
 *
 * Root saga. Set up.
 *
 */

function* rootSaga() {
  yield all([
    takeEvery(
      [
        UPDATE_INPUT,
        UPDATE_INPUTS,
        SET_CONNECT_INPUT_CARDS,
        ADD_REPEATABLE,
        REMOVE_REPEATABLE,
        ADD_QA,
        REMOVE_QA,
        UPDATE_QA,
      ],
      inputCatch
    ),
    takeEvery([INSERT_NODE, REMOVE_NODE, /* SET_NODES, */ UPDATE_NODE], nodesCatch),
    takeEvery([UPDATE_LEGALPERSON, INSERT_LEGALPERSON, REMOVE_LEGALPERSON], legalPersonCatch),
    takeEvery([UPDATE_REALPERSON, INSERT_REALPERSON, REMOVE_REALPERSON], realPersonCatch),
  ]);
}

const debouncedAction = actionDebouncer(300);

/**
 * Input catcher.
 *
 * @param {object} action State actions
 */
function* inputCatch(action) {
  if (window.location.pathname.startsWith('/studio/template/')) return;

  const state = yield select((state) => state);
  manager_beginDraft();

  // The inputModule middleware may dispatch additional actions
  // (if required by the input modules), and if so we want to
  // await such having been dispatched before we start an engine
  // drafting session. Therefore, return now and the drafting
  // session to be initiated below will be run in the next
  // dispatch.
  if (action.meta?.actionsToFollow) {
    if (window?.debug) console.log('More actions will follow...');
    return;
  }
  // console.log('Action ', { t: Date.now(), action });
  switch (action.type) {
    case ADD_REPEATABLE:
    case REMOVE_REPEATABLE:
      setTimeout(() => {
        manager_finishDraft(state);
      }, REPEATABLE_ADDED_TIMEOUT);
      break;
    default:
      // console.log('Debouncable ', action.debounce);
      if (action.debounce) {
        return debouncedAction(state, action);
      }
      return setTimeout(() => {
        manager_finishDraft(state);
      }, 500);
  }
}

/**
 * Nodes catcher.
 *
 * @param {object} action State actions
 */
function* nodesCatch(action) {
  const state = yield select((state) => state);
  manager_beginDraft(state);
  manager_finishDraft(state);
}

/**
 * Legal Person catcher.
 *
 * @param {object} action State actions
 */
function* legalPersonCatch(action) {
  const state = yield select((state) => state);
  manager_beginDraft(state);
  manager_finishDraft(state);
}

/**
 * Real Person catcher.
 *
 * @param {object} action State actions
 */
function* realPersonCatch(action) {
  const state = yield select((state) => state);
  manager_beginDraft(state);
  manager_finishDraft(state);
}

/****************
 *
 *   HANDLERS
 *
 *****************/

function manager_beginDraft(state) {
  if (typeof manager.beginDraft !== 'function') return;
  manager.beginDraft({ origin: 'redux' });
}
function manager_finishDraft(state) {
  if (typeof manager.finishDraft !== 'function') return;
  setTimeout(() => {
    manager.finishDraft(state, { origin: 'redux' });
  }, 0);
}

// Custom debouncer considering whether or not
// an action's payload's path is the same as last
// time (or not).
//
// Usage:
// const db = debouncer(time)
// db(state, action)
function actionDebouncer(wait) {
  let timeout;
  let lastFullPath;
  return function executedFunction(state, action) {
    if (window?.debug) console.log('Debouncing.');
    const later = () => {
      lastFullPath = null;
      clearTimeout(timeout);
      manager_finishDraft(state);
    };

    let allPaths;

    if (action?.payload) {
      if (Array.isArray(action.payload)) allPaths = action.payload.map((payload) => payload.path).join('/');
      else if (action.payload.path) allPaths = action.payload.path;

      if (lastFullPath && allPaths !== lastFullPath) {
        // console.log("Isnt same path as prior.", { lastFullPath, allPaths });
        later();
      }
      // Is new action path
      lastFullPath = allPaths;
    }

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default rootSaga;
