import { Transforms, Node } from "slate";
import { jsx } from "slate-hyperscript";
import { uniqueItemIds } from "core/engine/utils";

const ELEMENT_TAGS = {
  // A: (el) => ({ type: "link", url: el.getAttribute("href") }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H1: () => ({ type: "heading_one" }),
  H2: () => ({ type: "heading_two" }),
  H3: () => ({ type: "heading_three" }),
  H4: () => ({ type: "heading_four" }),
  H5: () => ({ type: "heading_five" }),
  H6: () => ({ type: "heading_six" }),
  IMG: (el) => ({ type: "image", url: el.getAttribute("src") }),
  LI: () => ({ type: "list_item" }),
  OL: () => ({ type: "numbered_list" }),
  P: () => ({ type: "paragraph" }),
  // PRE: () => ({ type: "code" }),
  UL: () => ({ type: "bulleted_list" }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  SPAN: () => ({}),
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

const normalizePastedFragment = (children, parents = []) => {
  const newFragment = [];
  for (const node of children) {
    if (node.hasOwnProperty('text')) {
      newFragment.push(node);
      continue;
    }
    if (node.children) node.children = normalizePastedFragment(node.children, [node, ...parents]);
    let liftedNodesBefore = [],
      liftedNodesAfter = [];
    for (const transfomer of TRANSFORM_ELEMENTS) {
      console.log('Test if.... ndoe ', node)
      if (transfomer.if(node)) {
        const transformLifting = transfomer.transform(node, parents);
        if (transformLifting && transformLifting.length === 2) {
          liftedNodesBefore = liftedNodesBefore.concat(transformLifting[0]);
          liftedNodesAfter = liftedNodesAfter.concat(transformLifting[1]);
        }
      }
    }

    liftedNodesBefore
      .filter((item) => !!item)
      .forEach((liftedNode) => newFragment.push(liftedNode));
    newFragment.push(node);

    liftedNodesAfter.filter((item) => !!item).forEach((liftedNode) => newFragment.push(liftedNode));
  }
  uniqueItemIds(newFragment)
  return newFragment;
  // return children
};

const TRANSFORM_ELEMENTS = [
  {
    if: (node) => node._matches.meta && node._matches.meta.type === "ol" && node._matches.css.listStyleType === "decimal",
    transform: (node, parents) => {
      if (parents[0] && parents[0].type === "numbered_list") {
        node.type = "heading_two";
        let text = ""
        const liftAfter = [];
        for (const child of node.children) {
          if (child.type === "list_item") {
            text += Node.string(child);
          }
          if (child.type === "heading_three") liftAfter.push(child);
        }
        node.children = [
          {
            text: text,
          },
        ];
        return [null, liftAfter];
      }
      if (parents.length === 0) {
        node.type = "heading_one";
        console.log("head one children", node.children);
        let text = "";
        const liftAfter = [];
        for (const child of node.children) {
          if (child.type === "list_item") {
            text += Node.string(child);
          }
          if (child.type === "heading_two") liftAfter.push(child);
        }
        node.children = [
          {
            text: text,
          },
        ];
        return [null, liftAfter];
      }
    },
  },
  {
    if: (node) => node._matches.meta && node._matches.meta.type === "ol" && node._matches.css.listStyleType === "lowerAlpha",
    transform: (node, parents) => {

    }
  }
];

const NEWLINES = ["↵", "\n", "\r", "\r\n"];

export const deserialize = (el, styles, parents = []) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }

  const { nodeName } = el;
  const lowerNodeName = nodeName.toLowerCase();
  let parent = el;

  if (nodeName === "PRE" && el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
    parent = el.childNodes[0];
  }
  const children = Array.from(parent.childNodes)
    .map((child) => deserialize(child, styles, [...parents, lowerNodeName]))
    .flat()
    .filter((child) => {
      if (typeof child !== "string") return true;
      for (var i = 0; i < child.length; i++) {
        if (!NEWLINES.includes(child.charAt(i))) return true;
      }
      return false;
    });
  if (children.length === 0) {
    console.log('Add children for parent ', {parent, nodeName})
    children.push({text: ''})
  }

  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = { ...ELEMENT_TAGS[nodeName](el), _matches: getMatchingStyles(el, styles) };
    // console.log('attrs: ', attrs)
    const elementContent = jsx("element", attrs, children);
    return elementContent;
  }

  else if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => jsx("text", attrs, child));
  }
  else {
    console.log('Unkown node Name ', nodeName)
  }

  return children;
};

function getMatchingStyles(el, styles) {
  const lowerNodeName = el.nodeName.toLowerCase();
  let matches = {};
  if (styles[lowerNodeName]) matches = { ...matches, ...styles[lowerNodeName] };

  for (const className of el.classList) {
    let fullPath = lowerNodeName + "." + className;
    if (styles[fullPath]) matches = { ...matches, ...styles[fullPath] };
  }

  return matches;
}

function cssToObj(css) {
  const obj = {}
  if (!css || typeof css !== 'string') {
    console.log('No css.')
    return obj
  }
  const s = css
      .toLowerCase()
      .replace(/-(.)/g, function (m, g) {
        return g.toUpperCase();
      })
      .replace(/;\s?$/g, "")
      .split(/:|;/g);
  for (var i = 0; i < s.length; i += 2)
    obj[s[i].replace(/\s/g, "")] = s[i + 1].replace(/^\s+|\s+$/g, "");
  return obj;
}

const getTagFromSelector = (selector) => {
  const parts = selector.split(" ");
  if (parts[0].startsWith(".")) return null;
  const firstParts = parts[0].split(".");
  if (firstParts[0] === "") return null;
  return firstParts[0];
};

const getStyleMeta = (selector, css) => {
  return {
    type: getTagFromSelector(selector),
  };
};

const getStyles = (head) => {
  // const { nodeName } = head;

  const styles = Array.from(head.childNodes)
    .filter((child) => child.nodeName === "STYLE")
    .map((child) => {
      const rawText = child.textContent;
      let text = "";
      for (let i in rawText) {
        if (!NEWLINES.includes(rawText[i])) text += rawText[i];
      }
      return text
        .split("{")
        .map((y) => y.split("}"))
        .flat();
    })
    .flat()
    .filter((css) => !!css)
    .reduce((acc, curr, i, array) => {
      if (i % 2 === 0) {
        const css = cssToObj(array[i + 1]);
        const tag = curr.trim().toLowerCase();
        acc[tag] = {
          meta: getStyleMeta(curr, css),
          css,
        };
      }
      return acc;
    }, {});
  // console.log("styles", styles);
  return styles;
};

export const pasteHtml = (editor) => {
  const { insertData } = editor;

  editor.insertHtml = (html) => {
    const parsed = new DOMParser().parseFromString(html, "text/html");
    // console.log("new html ", { parsed, html });
    const styles = getStyles(parsed.head);
    const fragment = normalizePastedFragment(deserialize(parsed.body, styles));
    Transforms.insertNodes(editor, fragment);
    console.log("Fragment: ", fragment);
    return;
  };

  editor.insertData = (data) => {
    const html = data.getData("text/html");
    const isSlateData = html.includes('data-slate-fragment="')
    
    // If slate data is pasted, use default function.
    if (isSlateData) {
      return insertData(data)
    }

    // If other html, parse using custom functionality.
    if (html) {
      return editor.insertHtml(html);
    }

    // Default.
    insertData(data);
  };

  return editor;
};
