import {
  FIX_INPUT,
  ADD_REPEATABLE,
  REMOVE_REPEATABLE,
  UPDATE_INPUT,
  UPDATE_INPUTS,
  SET_CONNECT_INPUT_CARDS,
  ADD_ORDINARY_CARD,
  REMOVE_ORDINARY_CARD,
  REMOVE_INPUT,
  ADD_QA,
  REMOVE_QA,
  UPDATE_QA,
  // UPDATE_QA_A
} from 'constants/ActionTypes';

import { getContractValues } from 'hooks';
import { Contract, InputPaths } from 'core/interfaces';
import { colors } from 'config/ui';
import { getByPath, makeRepeatableId } from 'core/utils/general';

export const addQA = (path, uid, values = { q: '', a: '' }) => {
  return {
    type: ADD_QA,
    payload: { path, uid, values },
  };
};

export const removeQA = (path, uid) => {
  return {
    type: REMOVE_QA,
    payload: { path, uid },
  };
};

export const updateQA = (path, value) => {
  return {
    type: UPDATE_QA,
    payload: { path, value },
  };
};

export const updateInput = (path, value, options = {}) => {
  return {
    type: UPDATE_INPUT,
    payload: { path, value },
  };
};
export const updateInputs = (entries, options = {}) => {
  const { debounce = false } = options;
  return {
    type: UPDATE_INPUTS,
    payload: entries,
    debounce,
  };
};

export const setConnectedInputCards = (payload) => {
  return {
    type: SET_CONNECT_INPUT_CARDS,
    payload,
  };
};

export const addRepeatable = (path, data, _uid, actionsToFollow) => {
  return (dispatch, getState) => {
    const prevState = getState();
    const contract = getContractValues();

    const { repeatable, values, path: repeatablePath } = Contract.getRepeatableValuesByPath(contract, path);

    const newValues = JSON.parse(JSON.stringify(values));

    const existingRepeatables = getByPath(prevState, repeatablePath);

    if (!repeatable || !values) {
      return console.log('Cannot create repeatable for path: ', path);
    }

    let cardCount = existingRepeatables ? Object.keys(existingRepeatables).length : 0;

    const uid = _uid || makeRepeatableId(repeatable);
    newValues._uid = uid;

    const added = Date.now();
    const color = cardCount > colors.length - 1 ? colors[cardCount - colors.length] : colors[cardCount];
    if (!newValues._meta) newValues._meta = { color, added };
    else {
      newValues._meta.added = added;
      newValues._meta.color = color;
    }

    if (data) Object.assign(newValues, data);

    dispatch({
      type: ADD_REPEATABLE,
      payload: {
        path: repeatablePath,
        uid,
        values: newValues,
      },
      meta: {
        uid,
        actionsToFollow,
      },
    });
  };
};

export const removeRepeatable = (path, opts = {}) => {
  const uid = InputPaths.getLast(path);
  path = InputPaths.parentPath(path);
  return {
    type: REMOVE_REPEATABLE,
    payload: {
      path,
      uid,
    },
  };
};

export const addOrdinaryCard = (path, values) => {
  return {
    type: ADD_ORDINARY_CARD,
    payload: {
      path,
      values,
    },
  };
};

export const removeOrdinaryCard = (path) => {
  return {
    type: REMOVE_ORDINARY_CARD,
    payload: {
      path,
    },
  };
};

export const fixInput = (payload) => {
  return {
    type: FIX_INPUT,
    payload,
  };
};

export const removeInput = (path) => {
  return {
    type: REMOVE_INPUT,
    payload: { path },
  };
};
