import React from "react";
import PDF from "core/utils/generators/makePDF";
import { PDFViewer } from "@react-pdf/renderer";
import { useContract } from "hooks";
import { Contract } from "core/interfaces";
import ErrorBoundary from "containers/Error/index.js";

/* const standardHeight = 730
const standardWidth = 500 */

export default function ViewPDF({ size = 100 }) {
  const contract = useContract();

  const content = Contract.getContent(contract);
  const settings = Contract.getSettings(contract);

  // const docWidth = standardWidth * (size/100)
  // const docHeight = standardHeight * (size/100)
  const docWidth = "100%";
  const docHeight = "100%";

  // const containerWidth = docWidth * 1.5

  return (
    <ErrorBoundary>
      <PDFViewer width={docWidth} height={docHeight} /* style={{width: containerWidth}} */>
        <PDF content={content} settings={settings} />
      </PDFViewer>
    </ErrorBoundary>
  );
}
