import React, { useEffect, useState, createRef } from 'react';
import { useSelector } from 'react-redux';
import { Transforms, Editor, Text } from 'slate';
import { useEditor } from 'slate-react';
import CustomScrollbars from 'util/CustomScrollbars';
import { useEditorComments, setEditorComments, useContract, setContract } from 'hooks';
import { Drawer, Button, List, Badge, Input, Comment as AntComment, Avatar, Tooltip, Popconfirm } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import { Contract } from 'core/interfaces';
import { dayAndMonth, textAbstract, uuidColor } from 'core/utils/general';
import { fixDate } from 'components/ui';
import IntlMessages from 'util/IntlMessages';

const ButtonGroup = Button.Group;
const { TextArea } = Input;

export default function EditorCommentsContainer() {
  const editorComments = useEditorComments();
  const { open, id } = editorComments;
  const [placement, setPlacement] = useState('left');
  const { user } = useSelector((state) => state.auth);

  const showAllComments = () => setEditorComments({ open: true });
  const close = () => setEditorComments({});

  if (!open && !id) return null;
  const oppositePlacement = placement === 'left' ? 'right' : 'left';
  const togglePlacement = () => setPlacement(oppositePlacement);

  return (
    <Drawer
      title={
        <>
          {!id ? (
            <IntlMessages id="studio.comments.contractComments" />
          ) : (
            <div>
              <div>
                <IntlMessages id="studio.comments.contractComment" />
              </div>
              <div className="link" onMouseDown={showAllComments}>
                <small>
                  <IntlMessages id="studio.comments.backToAll" />
                </small>
              </div>
            </div>
          )}
          <div className="top-btns">
            <Tooltip
              title={
                <>
                  <IntlMessages id="studio.comments.placeDrawer" />{' '}
                  <IntlMessages id={'desc.' + oppositePlacement} />
                </>
              }
              placement="bottom"
            >
              <Button className="border-0 switch-drawer-position" onMouseDown={togglePlacement}>
                {/* <Icon type={"arrow-" + oppositePlacement} /> */}
                {oppositePlacement === 'left' ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
              </Button>
            </Tooltip>
            <Tooltip title="Close" placement="bottom">
              <Button className="border-0" onMouseDown={close}>
                <CloseOutlined />
              </Button>
            </Tooltip>
          </div>
        </>
      }
      closable={false}
      mask={false}
      maskClosable={false}
      width={400}
      className="editor-comments-container"
      placement={placement}
      onClose={close}
      visible={!!id || !!open}
    >
      {id && <Comment id={id} user={user} />}
      {!id && open && <Comments user={user} />}
    </Drawer>
  );
}

function Comments({ user }) {
  const contract = useContract();
  const editor = useEditor();
  const comments = Contract.getComments(contract);
  if (!comments) {
    return null;
  }

  // Make a new comments object from the leaf nodes
  // containing `property`, in order to get the comments
  // ordered the same way as the contract content.
  const orderedComments = {};
  for (const [node] of Editor.nodes(editor, {
    match: (n) => Text.isText && Array.isArray(n.comments),
    at: [],
  })) {
    for (const id of node.comments) {
      if (!orderedComments[id]) {
        orderedComments[id] = {
          ...comments[id],
          leafs: [node],
        };
      } else {
        orderedComments[id].leafs.push(node);
      }
    }
  }

  return (
    <div className="comments">
      <CustomScrollbars>
        <List
          className=""
          loading={false}
          locale={{ emptyText: 'No messages made' }}
          itemLayout="horizontal"
          dataSource={Object.keys(orderedComments)}
          renderItem={(id) => {
            const comment = orderedComments[id];
            const { leafs } = comment;
            const text = textAbstract(leafs.map((leaf) => leaf.text).join(''), 20);
            const uniquePosters = [...new Set(comment.posts.map((p) => p.user.id))];

            const sortedDates = comment.posts.map((p) => p.time).sort();
            const [firstPostTime] = sortedDates;
            const [lastPostTime] = sortedDates.slice(-1);
            let timeText = '',
              timeTextFull = '';
            if (sortedDates.length === 1) {
              timeText = dayAndMonth(firstPostTime);
              timeTextFull = fixDate(firstPostTime);
            } else if (sortedDates.length > 1) {
              const ddFirst = dayAndMonth(firstPostTime);
              const ddLast = dayAndMonth(lastPostTime);
              if (ddFirst !== ddLast) timeText = ddFirst + ' - ' + ddLast;
              else timeText = ddFirst;
              timeTextFull = (
                <>
                  <div>First: {fixDate(firstPostTime)}</div>
                  <div>Last: {fixDate(lastPostTime)}</div>
                </>
              );
            }

            const view = () => setEditorComments({ id });
            const scroll = () => {
              const firstDomLeaf = document.getElementsByClassName('cmnt_' + id)[0];
              editor.scrollToNodeOrDomNode(firstDomLeaf, { isDomNode: true });
            };

            return (
              <div className="editor-comment-item border-bottom">
                <AntComment
                  actions={[
                    <span className="link" key="view" onMouseDown={view}>
                      <IntlMessages id="studio.comments.viewComment" />
                    </span>,
                    <span className="link" key="scroll" onMouseDown={scroll}>
                      <IntlMessages id="studio.comments.scrollTo" />
                    </span>,
                  ]}
                  author={
                    <>
                      {comment.posts.length} <IntlMessages id="desc.posts" className="pr-1" />{' '}
                      <IntlMessages id="desc.by" /> {uniquePosters.length} <IntlMessages id="desc.authors" />
                    </>
                  }
                  avatar={
                    <Badge count={comment.posts.length} showZero onMouseDown={view}>
                      <div className="standalone-comment ml-2">
                        <i className="mdi mdi-comment-processing-outline" />
                      </div>
                    </Badge>
                  }
                  content={
                    <p>
                      <i className="mdi mdi-format-quote" />
                      {text}
                      <i className="mdi mdi-format-quote" />
                    </p>
                  }
                  datetime={
                    <Tooltip title={timeTextFull}>
                      <span>{timeText}</span>
                    </Tooltip>
                  }
                />
              </div>
            );
          }}
        />
      </CustomScrollbars>
    </div>
  );
}

function Comment({ id, user }) {
  return (
    <div className="comment">
      <CustomScrollbars>
        <Posts id={id} user={user} />
        <NewPost id={id} user={user} />
      </CustomScrollbars>
    </div>
  );
}

function NewPost({ id, user }) {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const contract = useContract();
  const editor = useEditor();
  const inputRef = createRef();

  const onChange = (e) => setText(e.target.value);
  const closeNewPostArea = () => {
    setText('');
    setOpen(false);
  };
  const addPost = () => {
    const newPostItem = {
      user,
      text,
      time: new Date(Date.now()).toISOString(),
    };
    contract.data.create.comments[id].posts.push(newPostItem);

    if (typeof editor.onInlineCommentChange === 'function') {
      editor.onInlineCommentChange(id, 'newPost', newPostItem);
    }

    setContract({ ...contract });
    closeNewPostArea();
  };

  useEffect(() => {
    if (!open || !inputRef || !inputRef.current) return;
    inputRef.current.focus();
  }, [open, inputRef]);

  return (
    <div className="p-2">
      {!open && (
        <Button onMouseDown={() => setOpen(true)} block>
          <IntlMessages id="studio.comments.newPost" />
        </Button>
      )}
      {open && (
        <div className="editor-comment-box">
          <TextArea rows={4} value={text} onChange={onChange} ref={inputRef} />
          <ButtonGroup className="d-flex action-area">
            <Button
              onMouseDown={closeNewPostArea}
              style={{
                flexGrow: 1,
              }}
            >
              <IntlMessages id="desc.Abort" />
            </Button>
            <Button
              type="primary"
              onMouseDown={addPost}
              style={{
                flexGrow: 1,
              }}
            >
              <IntlMessages id="studio.comments.addComment" />
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
}

function Posts({ id, user }) {
  const contract = useContract();
  const editor = useEditor();
  const { posts } = Contract.getComment(contract, id);

  if (!posts || posts.length === 0) {
    return (
      <div className="no-comment-items">
        <div className="mb-3">
          <IntlMessages id="studio.comments.noPosts" />.
        </div>
        <div>
          <IntlMessages id="studio.comments.howToAdd" />.
        </div>
      </div>
    );
  }

  const scroll = () => {
    const firstDomLeaf = document.getElementsByClassName('cmnt_' + id)[0];
    editor.scrollToNodeOrDomNode(firstDomLeaf, { isDomNode: true });
  };

  return (
    <>
      <div className="comment-top border-bottom p-3">
        <span className="link" onMouseDown={scroll}>
          <IntlMessages id="studio.comments.scrollTo" />
        </span>
        <DeleteEntireComment id={id} user={user} />
      </div>
      {posts.map((post, index) => (
        <Post key={post.text + post.time} id={id} contract={contract} post={post} index={index} user={user} />
      ))}
    </>
  );
}

function Post({ post, index, user, id, contract }) {
  const editor = useEditor();

  const deletePost = () => {
    contract.data.create.comments[id].posts.splice(index, 1);
    setContract({ ...contract });

    if (typeof editor.onInlineCommentChange === 'function') {
      editor.onInlineCommentChange(id, 'deletePost', index);
    }
  };

  return (
    <div className="editor-comment-item border-bottom">
      <AntComment
        actions={[
          (post.user.id === user.id && (
            <span key="delete-post" onMouseDown={deletePost}>
              <IntlMessages id="desc.Delete" />
            </span>
          )) ||
            null,
        ]}
        author={post.user.firstName + ' ' + post.user.lastName}
        avatar={
          <Avatar
            className="ml-2"
            style={{ backgroundColor: uuidColor(post.user.id) }}
            icon={<UserOutlined />}
          />
        }
        content={<p>{post.text}</p>}
        datetime={
          <Tooltip title={fixDate(post.time)}>
            <span>{fixDate(post.time, { onlyDate: true })}</span>
          </Tooltip>
        }
      />
    </div>
  );
}

function DeleteEntireComment({ id, user }) {
  const contract = useContract();
  const editor = useEditor();

  const deletePost = () => {
    // Remove comment data container from the contract
    delete contract.data.create.comments[id];

    // Remove reference to this comment in any leaf node's
    // comment array.
    Transforms.setNodes(
      editor,
      (node) => {
        const newComments = node.comments.filter((comment_id) => comment_id !== id);
        return {
          comments: newComments,
        };
      },
      {
        match: (n) => Text.isText(n) && Array.isArray(n.comments) && n.comments.includes(id),
        at: [],
        // split: true
      }
    );

    if (typeof editor.onInlineCommentChange === 'function') {
      editor.onInlineCommentChange(id, 'deleteComment');
    }

    // Update the newly modified contract
    setContract({ ...contract });

    // Go back to main comments view
    setEditorComments({ open: true });
  };

  return (
    <Popconfirm
      placement="bottom"
      title="Are you sure delete this comment and all its posts?"
      onConfirm={deletePost}
      okText="Yes"
      cancelText="No"
    >
      <span className="link">
        <IntlMessages id="studio.comments.deleteEntire" />
      </span>
    </Popconfirm>
  );
}
