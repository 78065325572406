import React from 'react';
import PropTypes from 'prop-types';

const WidgetHeader = ({ title, extra, titleExtra, styleName, noEntryTitle, size = 'default' }) => {
  const Header = ({ children, ...props }) => {
    if (size === 'small') {
      return (
        <h5 {...props} style={{ fontSize: '13px', paddingBottom: '8px' }}>
          {children}
        </h5>
      );
    }
    return <h2 {...props}>{children}</h2>;
  };

  return (
    <Header className={(!noEntryTitle ? 'entry-title' : '') + ` ant-card-head-wrapper ${styleName}`}>
      {title}
      {titleExtra || null}
      <span className="text-primary fs-md ml-auto pl-4 d-flex">{extra}</span>
    </Header>
  );
};

WidgetHeader.defaultProps = {
  styleName: '',
};

WidgetHeader.propTypes = {
  title: PropTypes.node,
  extra: PropTypes.node,
};

export default WidgetHeader;
