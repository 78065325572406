
export const getSmartReplacementValues = function (
  refSection,
  key_name,
  state
) {
  // Inputs can affect smartReplacement rules either by:
  // `input` rule             - ordinary input update functions called with this as refSection
  // `count_repeatable` rule  - repeatable input update functions called with this as refSection
  // `numof_repeatables` rule  - repeatable input update functions called with this as refSection
  if (
    !this.refs.smartReplacements[refSection] ||
    !this.refs.smartReplacements[refSection][key_name]
  )
    return;

  const mappedValues = {}
  const draftInfo = this.getDraftInfo();

  for (const sectionName of this.refs.smartReplacements[refSection][key_name]) {
    if (!this.getContractCreate().build.smartReplacements[sectionName]) {
      this.warn("Non existing smartReplacement section ", sectionName);
      continue;
    }
    const sectionRules = this.getContractCreate().build.smartReplacements[
      sectionName
    ].rules;
    if (!sectionRules) {
      this.warn("No section rules for ", sectionName);
      // Consider to replace all values with [**]
      continue;
    }
    let validRuleIndex;
    for (let i = 0; i < sectionRules.length; i++) {
      // OK use for applyLogic
      if (
        this.applyLogic(sectionRules[i], {
          state,
          shortcuts: draftInfo.shortcutStates,
        })
      ) {
        validRuleIndex = i;
        break;
      }
    }
    if (validRuleIndex === undefined) {
      this.warn(
        "No section rule valid for ",
        sectionName,
        sectionRules,
        state
      );
      continue;
    }

    draftInfo.smartReplacementIndices[sectionName] = validRuleIndex;

    if(!mappedValues[sectionName]) mappedValues[sectionName] = {}

    const sectionContent = this.getContractCreate().build.smartReplacements[
      sectionName
    ].content;
    if (!sectionContent) {
      this.warn("No section content for ", sectionName);
      continue;
    }

    for (const repName in sectionContent) {
      if (
        !sectionContent[repName].content[this.language] ||
        !sectionContent[repName].content[this.language][validRuleIndex]
      ) {
        this.log(
          "No section content...",
          repName,
          validRuleIndex,
          this.language,
          sectionContent[repName]
        );
        continue;
      }
      let value =
        sectionContent[repName].content[this.language][validRuleIndex];

      // Update the local current storage
      draftInfo.smartReplacements[repName] = value;
      mappedValues[sectionName][repName] = value

    }
  }

  return mappedValues

};
