import { useState, useRef, useEffect, memo, useMemo } from 'react';
import uuid from 'uuid-random';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import {
  Button,
  Dropdown,
  Menu,
  Select,
  Tooltip,
  Divider,
  Alert,
  Space,
  Modal,
  Checkbox,
  notification,
} from 'antd';
import {
  PlusCircleOutlined,
  GroupOutlined,
  PlusOutlined,
  CloseOutlined,
  DownOutlined,
  QuestionOutlined,
  SaveOutlined,
  EditOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { Contract, Concept } from 'core/interfaces';
import { Editor, Node } from 'slate';
import { useEditor } from 'slate-react';

import { GrayCheckbox, Helper } from './components/ui';

import InputSetupLocal from './components/logics/InputSetupLocal';
import AnyAllInCardItem from './components/logics/AnyAllInCard';
import NumOf from './components/logics/Numof';
import NumOfState from './components/logics/NumOfState';
import {
  conjunctions,
  ruleToType,
  typeToText,
  rulesArray,
  firstKey,
  getType,
  getRuleCard,
  generateEmptyRule,
} from './core/common';
import ruleValidation from './core/validation';
import Help from './components/Help';
import SaveAsForm from './components/SaveAsForm';
// import { MenuHeader } from 'components/ui'

const { OptGroup } = Select;

function allRulesValid(valids) {
  for (const valid in valids) {
    if (!valids[valid]) return false;
  }
  return true;
}


function getDefaultLocalCard(slateEditor, ruleId) {
  /* 
  const [acpFilter] = Editor.nodes(slateEditor, {
    match: (node) => node.data && node.data.group_repeatable && node.data.filter === ruleId,
    at: [],
  });
  if (acpFilter && acpFilter[0]) {
    return acpFilter[0].data.group_repeatable;
  } else {
    const [acpNode] = Editor.nodes(slateEditor, {
      match: (node) => node.data && node.data.acp === ruleId,
      at: [],
    });
    if (acpNode) {
      for (const [ancestor] of Node.ancestors(slateEditor, acpNode[1], { reverse: true })) {
        if (
          ancestor &&
          ancestor.data &&
          ancestor.data.each_repeatable &&
          ancestor.data.each_repeatable.repeatable
        ) {
          return ancestor.data.each_repeatable.repeatable;
        }
      }
    }
  } */
  return null;
}

const LogicsEditor = memo(function ({
  contract,
  language: suppliedLanguage,
  rule: initRule,
  setters: parentSetters,
  nested,
  ruleId,
  onChange,
  localCard: givenLocalCard,
  onLocalCardChange,
  state,
  onSave,
  onSaveAs,
  ignoreLocalCard,
  setRulesSetter,
}) {
  let language;

  if (!contract) {
    throw new Error('LogicsEditor expects a `contract` property.');
  } else if (!contract.data || !contract.data.ui) {
    throw new Error('LogicsEditor: invalid contract data');
  }

  const ui = contract.data.ui;
  const [allCards, ordinaryCards, repeatableCards] = useMemo(() => {
    const ordinaryCards = [];
    const repeatableCards = [];
    const allCards = Object.keys(ui.cards);
    allCards.forEach((cardId) => {
      if (Contract.getUiIsCardRepeatable(contract, cardId)) repeatableCards.push(cardId);
      else ordinaryCards.push(cardId);
    });
    return [allCards, ordinaryCards, repeatableCards];
  }, [contract, ui]);

  const contractSetup =
    (contract.data.create && Array.isArray(contract.data.create.setup) && contract.data.create.setup) || [];

  if (suppliedLanguage) language = suppliedLanguage;
  else if (contract && contract.data && contract.data.settings && contract.data.settings.language)
    language = contract.data.settings.language;
  else {
    console.log('contract ? ', contract);
    throw new Error('LogicsEditor expects a `language` or `contract` property.');
  }

  const [startRules, startConjunction] = useMemo(() => {
    let initialRules = {};
    let initialConjunction = 'and';

    if (!initRule) return [initialRules, initialConjunction];

    if (typeof initRule !== 'object' || Object.keys(initRule).length !== 1) {
      throw new Error('Errornous `rule` to LogicsEditor: ' + JSON.stringify(initRule));
    }
    if (conjunctions.includes(Object.keys(initRule)[0])) {
      initialConjunction = Object.keys(initRule)[0];
      initialRules = initRule[initialConjunction].reduce((acc, curr) => {
        acc[uuid()] = curr;
        return acc;
      }, {});
    } else {
      // Start rule was not wrapped in a conjunction.
      // Keep 'and' as default mainConjuntion and
      // wrap the initRule in an array as sole child.
      initialRules = { [uuid()]: initRule };
    }
    return [initialRules, initialConjunction];
  }, [initRule]);

  const startValids = Object.keys(startRules).reduce((acc, curr) => {
    acc[curr] = false;
    return acc;
  }, {});

  const startRuleTypes = useMemo(() => {
    const types = {};
    for (const [id, rule] of Object.entries(startRules)) {
      types[id] = getType(rule);
    }
    return types;
  }, [startRules]);

  const setters = useRef({});
  const [rules, setRules] = useState(startRules);
  const [ruleTypes, setRuleTypes] = useState(startRuleTypes);
  const validRefs = useRef(startValids);
  const [valids, setValids] = useState(startValids);
  const [mainConjunction, setMainConjunction] = useState(startConjunction);

  // const slateEditor = useEditor();
  // const defaultLocalCard = useMemo(() => givenLocalCard || getDefaultLocalCard(slateEditor, ruleId), [slateEditor, ruleId, givenLocalCard]);
  const defaultLocalCard = useMemo(() => givenLocalCard, [givenLocalCard]);
  const [localCard, setLocalCard] = useState(defaultLocalCard);

  const [isManuallyEditing, setIsManuallyEditing] = useState(false);
  const toggleManualEdit = () => setIsManuallyEditing(!isManuallyEditing);

  const [showHelp, setShowHelp] = useState(false);

  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };

  const getRules = () => rules;
  const getValids = () => valids;

  if (!window.one) window.one = { getRules, getValids };
  else window.two = { getRules, getValids };

  if (typeof setRulesSetter === 'function') {
    setRulesSetter(setRules);
  }

  const setRuleType = (id, type) => {
    setRuleTypes({ ...ruleTypes, [id]: type });
  };

  const setRule = (id, rule, assignNewId = false) => {
    // console.log('Rules were ', rules, valids);
    const newRules = { ...rules };
    let newId;

    if (assignNewId) {
      newId = uuid();
      delete newRules[id];
      newRules[newId] = rule;

      setRules(newRules);
    } else {
      newRules[id] = rule;
      setRules(newRules);
    }

    // console.trace('Set rule to ', id, rule)

    if (nested) {
      const thisJointRule = {
        [mainConjunction]: rulesArray(newRules),
      };
      // console.log('Set new joint rule for this... ', thisJointRule);

      if (assignNewId) {
        parentSetters.current.setRule(ruleId, thisJointRule);
        parentSetters.current.setValid(ruleId, allRulesValid(valids));
      } else {
        parentSetters.current.setRule(ruleId, thisJointRule);
        parentSetters.current.setValid(ruleId, allRulesValid(valids));
      }
    }
  };
  const setValid = (id, value) => {
    validRefs.current[id] = value;
    setValids({ ...validRefs.current });
    // console.log('Set valid ', id, value, {nested})
    if (nested) {
      // console.log('nested set valid.... ', allRulesValid(validRefs.current))
      parentSetters.current.setValid(ruleId, allRulesValid(validRefs.current));
    } else {
      // console.log('Top got valids... ', validRefs)
    }
  };

  const newGroup = () => {
    const newId = uuid();
    setRules({
      ...rules,
      [newId]: { and: [] },
    });
    setValids({
      ...rules,
      [newId]: false,
    });
    setRuleTypes({
      ...ruleTypes,
      [newId]: 'conjunction',
    });
  };

  const addRule = (type) => {
    let newRule = generateEmptyRule();
    const newId = uuid();
    setRules({
      ...rules,
      [newId]: newRule,
    });
    setRuleTypes({
      ...ruleTypes,
      [newId]: null,
    });
    setValids({
      ...rules,
      [newId]: false,
    });
  };

  const updateRules = (newRules) => {
    const newObjectRules = {};
    const newObjectValids = {};
    for (const newRule of newRules) {
      const newId = uuid();
      newObjectRules[newId] = newRule;
      newObjectValids[newId] = false;
    }
    setRules(newObjectRules);
    setValids(newObjectValids);
  };

  const removeRule = (id) => {
    const rulesCopy = { ...rules };
    delete rulesCopy[id];
    setRules(rulesCopy);

    const validsCopy = { ...valids };
    delete validsCopy[id];
    setValids(validsCopy);
  };

  const onChangeConjunction = (value) => {
    setMainConjunction(value);
    if (nested) {
      parentSetters.current.setRule(ruleId, {
        [value]: rulesArray(rules),
      });
      parentSetters.current.setValid(ruleId, allRulesValid(valids));
    }
  };

  const makeFinalRule = () => ({ [mainConjunction]: rulesArray(rules) });

  useEffect(() => {
    if (typeof onChange === 'function') {
      const finalRule = {
        [mainConjunction]: rulesArray(rules),
      };

      const valid = mainConjunction && Object.keys(rules).length > 0 && allRulesValid(valids);
      onChange([finalRule, valid]);
    }
  }, [onChange, rules, mainConjunction, valids]);

  setters.current.setRule = setRule;
  setters.current.setRuleType = setRuleType;
  setters.current.setValid = setValid;
  setters.current.removeRule = removeRule;

  const rulesLength = Object.keys(rules).length;

  if (isManuallyEditing) {
    return (
      <div>
        <EditAllManually
          rule={makeFinalRule()}
          updateRules={updateRules}
          setMainConjunction={setMainConjunction}
          ruleId={ruleId}
          ui={ui}
          toggle={toggleManualEdit}
        />
      </div>
    );
  }

  const newMenu = (
    <Button.Group>
      <Button icon={<PlusOutlined />} tooltip="Add a rule" onClick={addRule}>
        Add rule
      </Button>
      <Button icon={<GroupOutlined />} tooltip="Add a Group of rules" onClick={newGroup}>
        Add group
      </Button>
    </Button.Group>
  );

  const checkIsSaveable = (saveFunction) => {
    if (typeof saveFunction !== 'function') {
      notification.error({
        message: 'Save failed',
        description: 'No Save Function was provided by the component which opened the Rule Editor.',
      });
      return false;
    }

    if (!allRulesValid(valids)) {
      notification.error({
        message: 'Save failed',
        description: 'All rules do not seem to be correct',
      });

      return false;
    }

    if (Object.keys(rules).length === 0) {
      notification.error({
        message: 'Save failed',
        description: 'No rules are added',
      });
    }

    return true;
  };

  const handleSave = () => {
    if (!checkIsSaveable(onSave) || typeof onSave !== 'function') return;
    onSave({ ruleData: makeFinalRule(), ruleId, localCard });
  };
  const handleSaveAs = () => {
    const tmp = { modal: null };
    const onCancel = () => {
      tmp.modal.destroy();
      delete tmp.modal;
    };
    const onSubmit = (values) => {
      onSaveAs({ ruleData: makeFinalRule(), localCard, ...values });
      onCancel();
    };

    tmp.modal = Modal.confirm({
      title: 'Save As',
      icon: <SaveOutlined />,
      content: <SaveAsForm onSubmit={onSubmit} onCancel={onCancel} />,
      className: 'pwd-modal',
      onOk: null,
      okButtonProps: { className: 'd-none' },
      cancelButtonProps: { className: 'd-none' },
      onCancel: null,
    });
  };

  const actionBtns = (
    <>
      <Button.Group>
        {typeof onSave === 'function' && (
          <Tooltip title={'Save Rule'}>
            <Button onClick={handleSave} className="pr-4" icon={<i className="mdi mdi-content-save" />} />
          </Tooltip>
        )}
        {typeof onSaveAs === 'function' && (
          <Tooltip title={'Save Rule As'}>
            <Button
              onClick={handleSaveAs}
              className="pr-4"
              icon={<i className="mdi mdi-content-save-all" />}
            />
          </Tooltip>
        )}
        <Tooltip title={'Edit rule manually'}>
          <Button onClick={toggleManualEdit} className="pr-4" icon={<EditOutlined />} />
        </Tooltip>
        <Tooltip title={'Help'}>
          <Button onClick={toggleHelp} className="pr-4" icon={<QuestionOutlined />} />
        </Tooltip>
      </Button.Group>
    </>
  );

  return (
    <>
      {!nested && <Help showHelp={showHelp} toggleHelp={toggleHelp} />}
      <div className="main-logics-editor w-100">
        <div className="flex-row">
          <Select value={mainConjunction} onChange={onChangeConjunction} className="main-conjunction-select">
            {conjunctions.map((conjunction) => {
              return (
                <Select.Option key={conjunction} value={conjunction}>
                  {conjunction.toUpperCase()}
                </Select.Option>
              );
            })}
          </Select>

          <Divider type="vertical" style={{ height: 'unset' }} />
          {newMenu}
          {!nested && actionBtns}
          <div
            style={{
              flexGrow: 1,
            }}
          ></div>
        </div>
        {rulesLength === 0 ? (
          <div className="no-rules-container">
            <div>No rules</div>
            <div>
              <small>
                Add a rule by hitting <u>Add Rule</u> above
              </small>
            </div>
          </div>
        ) : (
          <div className="main-rule-container">
            {Object.keys(rules).map((id, index) => {
              const rule = rules[id];
              return (
                <div key={id} className="rule-container">
                  <Tooltip title={'Remove item'}>
                    <Button
                      className="remove-rule-button bg-transparent"
                      icon={<CloseOutlined />}
                      size="small"
                      onClick={() => removeRule(id)}
                      type="text"
                    />
                  </Tooltip>
                  <RuleContainer
                    contract={contract}
                    ui={ui}
                    language={language}
                    ruleId={id}
                    setters={setters}
                    rule={rule}
                    ruleType={ruleTypes[id]}
                    isValid={valids[id]}
                    localCard={localCard}
                    contractSetup={contractSetup}
                    isLast={index + 1 === rulesLength}
                    nested={nested}
                    state={state}
                    allCards={allCards}
                    ordinaryCards={ordinaryCards}
                    repeatableCards={repeatableCards}
                    ignoreLocalCard={ignoreLocalCard}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
});

function EditAllManually({ rule, updateRules, setMainConjunction, ruleId, ui, toggle }) {
  const [manualJsonError, setManualJsonError] = useState(null);
  const [manualValidationError, setManualValidationError] = useState(null);
  const manualRule = useRef(rule);

  const onEditChange = (update) => {
    const { error, jsObject } = update;

    if (error) return setManualJsonError(error);

    const validated = ruleValidation(jsObject, ui, 'conjunction');
    // console.log('update X', { update, validated });
    if (validated.error) {
      setManualValidationError(validated.error);
    } else if (manualValidationError) setManualValidationError(null);

    setManualJsonError(null);

    manualRule.current = jsObject;
    // console.log('Updated current rule.');
  };
  const onEditSave = () => {
    const validated = ruleValidation(manualRule.current, ui, 'conjunction');

    // console.log('on save ', { validated, rule: manualRule.current });

    if (manualJsonError) {
      notification.error({
        message: 'Invalid JSON',
        description: 'There is an error in the JSON structure. Please fix it before saving.',
      });
      return;
    }

    if (validated.error) {
      notification.error({
        message: 'Invalid rule',
        description:
          'The rule currently being manually edited does not validate correctly. Fix it before saving.',
      });
      return;
    }

    setTimeout(() => {
      setManualValidationError(null);
      doSaveManual();
    }, [1000]);
  };

  const doSaveManual = () => {
    const conjunction = firstKey(manualRule.current);
    const newRules = manualRule.current[conjunction];
    // console.log('Shall save ', manualRule.current);
    setMainConjunction(conjunction);
    updateRules(newRules);
    toggle();
  };

  return (
    <div className="rules-all-manual rules-manual">
      <div className="actions">
        <Button onClick={onEditSave} type="primary" disabled={!!manualValidationError && !!manualJsonError}>
          <SaveOutlined /> Save
        </Button>
        <Button onClick={toggle}>
          <CloseOutlined /> Close
        </Button>
      </div>
      <div className="position-relative">
        {manualValidationError && <ManualValidationErrorTop manualValidationError={manualValidationError} />}
      </div>
      <div>
        <JSONInput
          id={'edit-rule-' + ruleId}
          onChange={onEditChange}
          confirmGood={false}
          placeholder={rule}
          theme="light_mitsuketa_tribute"
          locale={locale}
          error={{
            line:
              (manualJsonError && manualJsonError.line && manualJsonError.line + 2) ||
              (manualValidationError && manualValidationError.line),
          }}
          colors={{
            error: '#ff0000',
          }}
          style={{
            outerBox: {
              width: 'calc(100% - 185px)',
              height: 'unset',
            },
            container: {
              width: '100%',
              height: 'unset',
              borderRadius: '6px',
            },
            body: {
              fontSize: '1em',
            },
            warningBox: {
              display: 'none',
            },
          }}
        />
      </div>
      <div>
        {manualJsonError && <ManualJsonError manualJsonError={manualJsonError} />}
        {manualValidationError && manualValidationError.highlight && (
          <ManualValidationErrorHighlight manualValidationError={manualValidationError} />
        )}
      </div>
    </div>
  );
}

function RuleContainer({
  ui,
  contract,
  language,
  ruleId,
  setters,
  rule,
  ruleType,
  isLast,
  nested,
  isValid,
  localCard,
  contractSetup,
  state,
  allCards,
  ordinaryCards,
  repeatableCards,
  ignoreLocalCard
}) {
  const [isViewingRule, setIsViewingRule] = useState(false);
  const toggleViewRule = () => setIsViewingRule(!isViewingRule);

  const [isManuallyEditing, setIsManuallyEditing] = useState(false);
  const [manualJsonError, setManualJsonError] = useState(null);
  const [manualValidationError, setManualValidationError] = useState(null);
  const manualRule = useRef(rule);
  const toggleEdit = () => {
    if (manualValidationError) setManualValidationError(null);
    setIsManuallyEditing(!isManuallyEditing);
  };
  const onEditChange = (update) => {
    const { error, jsObject } = update;

    if (error) return setManualJsonError(error);

    const validated = ruleValidation(jsObject, ui, ruleType);
    console.log('update X', { update, validated });
    if (validated.error) {
      setManualValidationError(validated.error);
    } else if (manualValidationError) setManualValidationError(null);

    setManualJsonError(null);

    manualRule.current = jsObject;
    console.log('Updated current rule.');
  };
  const onEditSave = () => {
    const validated = ruleValidation(manualRule.current, ui, ruleType);

    console.log('on save ', { validated, rule: manualRule.current });

    if (manualJsonError) {
      notification.error({
        message: 'Invalid JSON',
        description: 'There is an error in the JSON structure. Please fix it before saving.',
      });
      return;
    }

    if (validated.error) {
      notification.error({
        message: 'Invalid rule',
        description:
          'The rule currently being manually edited does not validate correctly. Fix it before saving.',
      });
      return;
    }

    setTimeout(() => {
      setIsManuallyEditing(false);
      setManualValidationError(null);
      // setters.current.setRule(ruleId, manualRule.current);
      doSaveManual();
    }, [100]);
  };

  const doSaveManual = () => {
    console.log('Shall save ', manualRule.current);
    setters.current.setRule(ruleId, manualRule.current, true);
  };

  const ruleText = typeToText(ruleType) || <em>Unknown</em>;

  const infoContent = (
    <>
      <div className="rule-top">
        {/* <div className={'rule-type-name ' + (!isValid ? 'invalid' : 'valid')}>{ruleText}</div> */}
        <ValidOrFailed isValid={isValid} />
        <div onClick={toggleEdit} className="link mr-3">
          {isManuallyEditing ? 'Close Edit' : 'Edit Rule'}
        </div>
        <div onClick={toggleViewRule} className="link">
          {isViewingRule ? 'Close View' : 'View Rule'}
        </div>
      </div>
    </>
  );

  return (
    <>
      {isLast && <div className="rule-last-block" style={nested ? { background: '#f0f2f3' } : {}}></div>}
      <div className="rule-joiner" />
      <div className="rule-item rule-group">
        {isViewingRule && (
          <div className="view-rule">
            <pre>{JSON.stringify(rule, null, 2)}</pre>
          </div>
        )}
        {isManuallyEditing ? (
          <div className="rules-all-manual rules-manual w-100">
            {manualValidationError && (
              <ManualValidationErrorTop manualValidationError={manualValidationError} />
            )}
            <JSONInput
              id={'edit-rule-' + ruleId}
              onChange={onEditChange}
              confirmGood={false}
              placeholder={rule}
              theme="light_mitsuketa_tribute"
              locale={locale}
              error={{
                line:
                  (manualJsonError && manualJsonError.line) ||
                  (manualValidationError && manualValidationError.line),
              }}
              colors={{
                error: '#ff0000',
              }}
              style={{
                outerBox: {
                  width: 'calc(100% - 185px)',
                  height: 'unset',
                },
                container: {
                  width: '100%',
                  height: 'unset',
                  borderRadius: '6px',
                },
                body: {
                  fontSize: '1em',
                },
                warningBox: {
                  display: 'none',
                },
              }}
            />
            <div>
              <Button
                onClick={onEditSave}
                type="primary"
                disabled={!!manualValidationError && !!manualJsonError}
              >
                <SaveOutlined /> Save
              </Button>
              <Button onClick={toggleEdit}>
                <CloseOutlined /> Close
              </Button>
            </div>
            {manualJsonError && <ManualJsonError manualJsonError={manualJsonError} />}
            {manualValidationError && manualValidationError.highlight && (
              <ManualValidationErrorHighlight manualValidationError={manualValidationError} />
            )}
          </div>
        ) : ruleType === 'conjunction' ? (
          <LogicsEditor
            contract={contract}
            language={language}
            rule={rule}
            nested
            ruleId={ruleId}
            setters={setters}
            state={state}
          />
        ) : (
          <Rule
            allCards={allCards}
            ordinaryCards={ordinaryCards}
            repeatableCards={repeatableCards}
            contract={contract}
            ui={ui}
            language={language}
            ruleId={ruleId}
            setters={setters}
            rule={rule}
            ruleType={ruleType}
            isValid={isValid}
            localCard={localCard}
            contractSetup={contractSetup}
            isLast={isLast}
            nested={nested}
            state={state}
            ignoreLocalCard={ignoreLocalCard}
          />
        )}
      </div>
      {infoContent}
    </>
  );
}

function getNewRuleType({ repeatableCards, isLocal, ordinaryType, currentCard }) {
  const currentCardIsRepeatable = repeatableCards.includes(currentCard);
  const currentCardIsSetup = currentCard === '__setup';
  const currentCardIsNumOfState = currentCard === 'legalPersons' || currentCard === 'realPersons';
  const currentCardIsOrdinary =
    !currentCardIsRepeatable && !currentCardIsSetup && !currentCardIsNumOfState && currentCard;

  if (currentCardIsRepeatable && isLocal) {
    return 'local';
  } else if (currentCardIsRepeatable) {
    return 'numof';
  } else if (currentCardIsSetup) {
    return 'setup';
  } else if (currentCardIsNumOfState) {
    return 'numof_state';
  } else if (currentCardIsOrdinary && ordinaryType === 'any_in_card') {
    return 'any_in_card';
  } else if (currentCardIsOrdinary && ordinaryType === 'all_in_card') {
    return 'all_in_card';
  } else if (currentCardIsOrdinary) {
    return 'input';
  }
  return null;
}

function isSameCategory(type1, type2) {
  if (
    (type1 === 'all_in_card' && type2 === 'any_in_card') ||
    (type2 === 'all_in_card' && type1 === 'any_in_card')
  ) {
    return true;
  }
  return type1 === type2;
}

function Rule({
  allCards,
  ordinaryCards,
  repeatableCards,
  ui,
  contract,
  language,
  ruleId,
  setters,
  rule,
  ruleType,
  isLast,
  nested,
  isValid,
  localCard,
  contractSetup,
  state,
  selectedCard,
  ignoreLocalCard
}) {
  const ruleCard = getRuleCard(rule);

  const [currentCard, setCurrentCard] = useState(ruleCard || null);
  const [isLocal, setIsLocal] = useState(ruleType === 'local');
  const [ordinaryType, setOrdinaryType] = useState(
    ruleType === 'any_in_card' ? 'any_in_card' : ruleType === 'all_in_card' ? 'all_in_card' : null
  );

  const myRuleType = getNewRuleType({
    repeatableCards,
    isLocal,
    ordinaryType,
    currentCard,
  });

  const currentCardIsRepeatableOrLocal = myRuleType === 'numof' || myRuleType === 'local';
  const showLocalSwitch = (!ignoreLocalCard && myRuleType === 'numof') || myRuleType === 'local'
  const currentCardIsInputAnyOrAll =
    myRuleType === 'input' || myRuleType === 'all_in_card' || myRuleType === 'any_in_card';

  const onCardChange = (value) => {
    // generateEmptyRule
    const newRuleType = getNewRuleType({
      repeatableCards,
      isLocal,
      ordinaryType,
      currentCard: value,
    });
    if (!isSameCategory(myRuleType, newRuleType)) {
      const newRule = generateEmptyRule(newRuleType);
      setters.current.setRule(ruleId, newRule);
      setters.current.setRuleType(ruleId, newRuleType);
      setters.current.setValid(ruleId, false);
    }

    setCurrentCard(value);
    setOrdinaryType(null);
    setIsLocal(false);
  };
  const onOrdinaryTypeChange = (value) => {
    const newRuleType = getNewRuleType({
      repeatableCards,
      isLocal,
      ordinaryType: value,
      currentCard,
    });
    if (!isSameCategory(myRuleType, newRuleType)) {
      const newRule = generateEmptyRule(newRuleType);
      setters.current.setRule(ruleId, newRule);
      setters.current.setRuleType(ruleId, newRuleType);
      setters.current.setValid(ruleId, false);
    }
    setIsLocal(null);

    setOrdinaryType(value);
  };

  const onLocalChange = (value) => {
    const newRuleType = getNewRuleType({
      repeatableCards,
      isLocal: value,
      ordinaryType,
      currentCard,
    });
    if (!isSameCategory(myRuleType, newRuleType)) {
      const newRule = generateEmptyRule(newRuleType);
      setters.current.setRule(ruleId, newRule);
      setters.current.setRuleType(ruleId, newRuleType);
      setters.current.setValid(ruleId, false);
    }
    setIsLocal(value);
    setOrdinaryType(null);
  };

  /* console.log('card is rep ? ', {
    currentCardIsRepeatableOrLocal,
    currentCard,
    repeatableCards,
    rule,
    ruleType,
    myRuleType,
  }); */

  let content = null;

  if (myRuleType === 'input') {
    content = (
      <InputSetupLocal
        contract={contract}
        ui={ui}
        language={language}
        ruleId={ruleId}
        setters={setters}
        rule={rule}
        mode={'input'}
        currentCard={currentCard}
      />
    );
  }
  if (myRuleType === 'setup') {
    content = (
      <InputSetupLocal
        contract={contract}
        ui={ui}
        language={language}
        ruleId={ruleId}
        setters={setters}
        rule={rule}
        contractSetup={contractSetup}
        mode={'setup'}
      />
    );
  }
  if (myRuleType === 'local') {
    content = (
      <InputSetupLocal
        contract={contract}
        ui={ui}
        language={language}
        ruleId={ruleId}
        setters={setters}
        rule={rule}
        currentCard={currentCard}
        mode={'local'}
      />
    );
  }

  if (myRuleType === 'numof') {
    content = (
      <NumOf
        contract={contract}
        ui={ui}
        language={language}
        ruleId={ruleId}
        setters={setters}
        rule={rule}
        currentCard={currentCard}
      />
    );
  }

  if (myRuleType === 'numof_state') {
    content = (
      <NumOfState
        contract={contract}
        ui={ui}
        language={language}
        ruleId={ruleId}
        setters={setters}
        rule={rule}
        currentCard={currentCard}
      />
    );
  }

  if (myRuleType === 'any_in_card') {
    content = (
      <AnyAllInCardItem
        contract={contract}
        ui={ui}
        language={language}
        ruleId={ruleId}
        setters={setters}
        rule={rule}
        mode="any"
        currentCard={currentCard}
      />
    );
  }
  if (myRuleType === 'all_in_card') {
    content = (
      <AnyAllInCardItem
        contract={contract}
        ui={ui}
        language={language}
        ruleId={ruleId}
        setters={setters}
        rule={rule}
        mode="all"
        currentCard={currentCard}
      />
    );
  }

  if (ruleType === 'conjunction') {
    content = (
      <LogicsEditor
        contract={contract}
        language={language}
        rule={rule}
        nested
        ruleId={ruleId}
        setters={setters}
        state={state}
      />
    );
  }

  return (
    <>
      <div className="d-flex">
        <Select
          onChange={onCardChange}
          value={currentCard || undefined}
          placeholder={<span>Select input category . . .</span>}
          style={{ width: 220 }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            if (option.options) return false; // if optGroup - don't match
            if (typeof option.children === 'string')
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            if (Array.isArray(option.children)) {
              const string = option.children.join('');
              return string.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            console.log('Search ', { input, option });
            return false;
            // return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          <OptGroup label="Repeatables">
            {repeatableCards.map((card) => {
              const label =
                (ui.cards && ui.cards[card] && ui.cards[card].header && ui.cards[card].header[language]) ||
                card;
              return (
                <Select.Option key={card} value={card}>
                  {label} ({card})
                </Select.Option>
              );
            })}
          </OptGroup>
          <OptGroup label="Ordinary Input Boxes">
            {ordinaryCards.map((card) => {
              const label =
                (ui.cards && ui.cards[card] && ui.cards[card].header && ui.cards[card].header[language]) ||
                card;
              return (
                <Select.Option key={card} value={card}>
                  {label} ({card})
                </Select.Option>
              );
            })}
          </OptGroup>

          <OptGroup label="Setup">
            <Select.Option value={'__setup'}>Setup Values</Select.Option>
          </OptGroup>

          <OptGroup label="Number of">
            <Select.Option value={'legalPersons'}>Legal Persons</Select.Option>
            <Select.Option value={'realPersons'}>Real Persons</Select.Option>
          </OptGroup>
        </Select>
        {currentCardIsInputAnyOrAll && (
          <>
            <small style={{ margin: '0 4px' }}>WHERE</small>
            <Select value={ordinaryType} onChange={onOrdinaryTypeChange}>
              <Select.Option value={null}>Specific Input</Select.Option>
              <Select.Option value={'any_in_card'}>Any Input is . . .</Select.Option>
              <Select.Option value={'all_in_card'}>All Inputs are . . .</Select.Option>
            </Select>
          </>
        )}
        {showLocalSwitch && (
          <GrayCheckbox
            checked={isLocal}
            onChange={(evt) => onLocalChange(evt.target.checked)}
            label={'Local'}
            help={
              <div>
                <div>This makes the rule target an individual instance of a card.</div>
                <div>
                  To be used for rules which are applied to a section of a contract which prints each instance
                  of a repeatable.
                </div>
              </div>
            }
            snug
          ></GrayCheckbox>
        )}
      </div>
      {content}
    </>
  );
}

function ValidOrFailed({ isValid }) {
  return isValid ? (
    <Tooltip title={'Rule looks correct'}>
      <div className="valid-indicator valid">
        Rule is valid <CheckOutlined />
      </div>
    </Tooltip>
  ) : (
    <Tooltip title={'Rule looks invalid - please fill in all fields'}>
      <div className="valid-indicator invalid">
        Rule is invalid<ExclamationCircleOutlined className="ml-1" />
      </div>
    </Tooltip>
  );
}

function ManualJsonError({ manualJsonError }) {
  if (!manualJsonError) return null;
  return (
    <div className="manual-json-error-info">
      <div>{manualJsonError.reason}</div>
      <div>
        Line {manualJsonError.line}, token {manualJsonError.token}
      </div>
    </div>
  );
}

function ManualValidationErrorTop({ manualValidationError }) {
  if (!manualValidationError) return null;
  return (
    <div className="manual-json-error">
      <span className="mr-3">
        <strong>{manualValidationError.message}</strong>
      </span>
      {manualValidationError.info && <FailInfo info={manualValidationError.info} noDiv />}
    </div>
  );
}

function ManualValidationErrorHighlight({ manualValidationError }) {
  if (!manualValidationError) return null;
  return (
    <div className="manual-error-highlight">
      <h4>Expected rule to match the example below as follows</h4>
      <pre dangerouslySetInnerHTML={{ __html: manualValidationError.highlight }} />
    </div>
  );
}

function arrayToText(array) {
  const length = array.length;
  const result = [];
  for (let i = 0; i < length; i++) {
    result.push(
      <strong key={array[i]} className="mr-1">
        {array[i]}{' '}
      </strong>
    );
    if (i === length - 2)
      result.push(
        <span key="orx" className="mr-1">
          {' '}
          or{' '}
        </span>
      );
  }
  return result;
}

function FailInfo({ info, noDiv }) {
  if (noDiv) {
    return (
      <small>
        {info.map((item) => {
          if (Array.isArray(item)) return arrayToText(item);
          return item;
        })}
      </small>
    );
  }
  return (
    <div>
      <small>
        {info.map((item) => {
          if (Array.isArray(item)) return arrayToText(item);
          return item;
        })}
      </small>
    </div>
  );
}

function UnsupportedRule({ rule }) {
  const [view, setView] = useState(false);
  const action = view ? 'Hide rule' : 'Show rule';

  const toggle = () => setView(!view);

  return (
    <div className="w-100">
      <div>
        Unsupported rule{' '}
        <small className="link" onClick={toggle}>
          {action}
        </small>
      </div>
      {view && (
        <div style={{ fontSize: '0.8em', padding: '10px', border: '1px dashed #d9d9d9', width: '100%' }}>
          <pre>{JSON.stringify(rule, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default LogicsEditor;
