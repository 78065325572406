import { AbilityBuilder } from '@casl/ability';
import { Ability } from '@casl/ability';

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  if (item.__type) return item.__type;
  return 'Project';
}

export const ability = new Ability(null, { subjectName });

/*
Example data: 
{
  can_add: 'Y' / 'N' / 'O' / NULL
}
*/
export function updateRules(data, type, auth, permissionsTable) {
  const rules = defineRulesFor(data, type, auth, permissionsTable);
  ability.update(rules);
}

export function defineRulesFor(data, type, { user }, permissionsTable) {
  const { can, cannot, rules } = AbilityBuilder.extract();

  if (!Array.isArray(data.PermUsersRoles) || !permissionsTable) {
    return [];
  }

  // Ensure that we have the property permission resource (if the one we go has `useAccessFrom` another)
  let permissionResource = permissionsTable[type];
  let preventForeverLoop = 0;
  while (permissionsTable[type].useAccessFrom && permissionsTable[type].useAccessFrom[0]) {
    if (preventForeverLoop > 20) break;
    permissionResource = permissionsTable[type].useAccessFrom[0];
  }
  const accesses = permissionResource.access;
  if (!accesses) return [];

  // Since we may have multiple roles, let's store the most permissive permissions.
  const mostAllowance = {};

  for (const { role_name } of data.PermUsersRoles) {
    const roleAccesses = accesses[role_name];
    if (!roleAccesses) {
      continue;
    }
    for (const [permissionActionNameFull, permissionActionValue] of Object.entries(roleAccesses)) {
      const permissionActionName = permissionActionNameFull.substr(4);

      // If yes now or already yes.
      if (mostAllowance[permissionActionName] === 'Y') {
        continue; // Already allowed.
      }
      if (permissionActionValue === 'Y') {
        mostAllowance[permissionActionName] = 'Y';
        continue;
      }

      // If own resource now or already own resource.
      if (mostAllowance[permissionActionName] === 'O') {
        continue; // Already allowed.
      }
      if (permissionActionName === 'O') {
        mostAllowance[permissionActionName] = 'O';
        continue;
      }

      // Else, we may not.
      mostAllowance[permissionActionName] = 'N';
    }
  }

  // Update the ability permissions with the most permissive data.
  for (const [permissionActionName, permissionActionValue] of Object.entries(mostAllowance)) {
    if (permissionActionValue === 'Y') can(permissionActionName, type);
    if (permissionActionValue === 'N' || !permissionActionValue) cannot(permissionActionName, type);
    if (permissionActionValue === 'O') can(permissionActionName, type, { userId: user.id });
  }

  return rules;
}
