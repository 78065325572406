import { Button } from 'antd';
import { Editor, Transforms, Point } from 'slate';
import { conditionalizeField } from '../../legal/helpers';

export const TextSelect = ({ editor, node, path, close }) => {
  const { selection } = editor;

  const onClickMakeConditional = (evt) => {
    conditionalizeField(editor);
    evt.preventDefault();
    evt.stopPropagation();
    close({ reselect: true, collapse: true });
  };

  const isAtStart = Point.equals(selection.anchor, Editor.start(editor, selection.anchor.path));
  const isAtEnd = Point.equals(selection.focus, Editor.end(editor, selection.focus.path));
  let allOfFieldParent = false;
  if (isAtStart && isAtEnd) {
    const parent = Editor.parent(editor, path);
    if (parent && parent[0]) {
      if (parent[0].type === 'field') {
        allOfFieldParent = true;
/*         setTimeout(() => {
          close()
        }, 1) */
      }
    }
  }

  return (
    <>
      <h5>Text Selection</h5>
      {!allOfFieldParent && (
        <Button type="link" className="m-0 border-none" onMouseDown={onClickMakeConditional}>
          Make Conditional
        </Button>
      )}
    </>
  );
};
