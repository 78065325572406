import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbars = (props) => (
  <Scrollbars
    {...props}
    autoHide={props.hasOwnProperty('autoHide') ? props.autoHide : true}
    autoHeight={props.hasOwnProperty('autoHeight') && props.autoHeight}
    renderTrackHorizontal={() => <div style={{ display: 'none' }} className="track-horizontal" />}
    renderTrackVertical={() => <div className="track-vertical" />}
  />
);

export default CustomScrollbars;
