export const smartDefinitionOnRepeatableChange = {
  id: 'smartDefinitionOnRepeatableChange',
  dependencies: { repeatableChange: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    let shallSort = false;

    // Check if any each-`item` is affected by a particular repeatableChange.
    // If so, sort definitions.
    for (const { path, fieldName } of entries) {
      const repeatable_path = api.interfaces.InputPaths.parentPath(path);
      const repeatable_state = api.utils.general.getByPath(state, repeatable_path);

      const uid = repeatable_state && repeatable_state._uid;
      if (!uid) return;

      const key = uid + '.' + fieldName;

      // Find out if there are any 'smartDefinitions'
      // (paragraph with `isDefKey` data property), which has
      // a definition item matching the relevant field (name) being updated
      const matches = api.utils.engine.find(
        this.draftValue,
        (n) =>
          n.data && n.data.isDefKey && n.children && n.children[1] && n.children[1].data && n.children[1].data.each_item_id === key
          // !!n?.data?.isDefKey && n?.children && n.children[1] && n.children[1]?.data?.each_item_id === key
      );

      if (matches.length > 0) shallSort = true;
    }

    if (shallSort) this.sortDefinitions();
  },
};
