import React from "react";
import {Layout} from "antd";


const {Header} = Layout;

const Topbar = ({children, right}) => {


  return (
    <Header className="studio-header">
      {children}
      {Array.isArray(right) && 
      <ul className="header-notifications ml-auto">
        {right}
      </ul>
    }
    </Header>
  );
};

export default Topbar;
