import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useContract, setEditorComments, setWarnings, useIsSidebarMini } from 'hooks';
import { useLocation } from 'react-router-dom';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
// import { sidebarShowFields } from 'core/types/elements';
import creationInputMenu from './menuBuilder';
import { Contract } from 'core/interfaces';
import GeneralSettings from '../../components/SidebarComponents/General';
import TableOfContents from '../../components/SidebarComponents/TableOfContents';
// import Settings from "../../components/SidebarComponents/Settings";
import Warnings from '../../components/SidebarComponents/Warnings';
// import Fields from '../../components/SidebarComponents/Fields';
import { Tooltip, Badge } from 'antd';
import IntlMessages from 'util/IntlMessages';

export default function useInputSidebarTabs({ activePaths, activeKey }) {
  const tooltipPlacement = 'right';
  const warnings = useSelector(({ draft }) => draft.warnings);
  const isSidebarMini = useIsSidebarMini();

  // const editor = useSlate();
  /* 
  const [...selectedFields] = Editor.nodes(editor, {
    match: sidebarShowFields,
  });
  let hasFields = selectedFields.length > 0;
   */

  const paths = useMemo(() => {
    const tooltips = [
      [
        <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.general" />}>
          <i className="mdi mdi-eye fs-lg" />
        </Tooltip>,
        <GeneralSettings action={'input'} />,
      ],
      [
        <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.contents" />}>
          <i className="mdi mdi-sort-descending fs-lg" />
        </Tooltip>,
        <TableOfContents isSidebarMini={isSidebarMini} noHeader={isSidebarMini} />,
      ],
      [
        <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.menu" />}>
          <i className="mdi mdi-file-tree fs-lg" />
        </Tooltip>,
        <InputMenu activePaths={activePaths} isSidebarMini={isSidebarMini} />,
      ],
      /* [
      <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.comments" />}>
          <i className="mdi mdi-comment-outline fs-lg" />
        </Tooltip>,
        null,
        {
          onClick: () => setEditorComments({open:true})
        }
      ], */
      /* [
        <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.settings" />}>
          <i className="mdi mdi-settings fs-lg" />
        </Tooltip>,
        <Settings />,
      ], */
      /* [
        <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.fields" />}>
          <i
            className={
              "mdi mdi-developer-board fs-lg" + (hasFields && activeKey !== "3" ? " highlight" : "")
            }
          />
        </Tooltip>,
        <Fields selectedFields={selectedFields} />,
      ], */
    ];
    if (warnings && warnings.length > 0) {
      tooltips.push([
        <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.warnings" />}>
          <Badge count={1} size="small">
            <i className="mdi mdi-alert-outline fs-lg" />
          </Badge>
        </Tooltip>,
        <Warnings warnings={warnings} />,
      ]);
    }
    return tooltips;
  }, [activePaths, warnings, isSidebarMini /* , selectedFields, hasFields, activeKey */]);
  return paths;
}

export function InputMenu({ activePaths, isSidebarMini }) {
  const location = useLocation();

  const contract = useContract();
  const createRoutes = Contract.getUiRoutes(contract);

  const state = useSelector((state) => state);
  const { element } = creationInputMenu({
    menu: createRoutes,
    location: location,
    inputState: state.input,
    menuRef: null,
    activePaths,
    contract,
    isSidebarMini,
  });

  return element || 'Could not load input menu';
}
