import React from 'react';
import { connect } from 'react-redux';

import ihdStructure from 'external/chart2';
import { addMetaDataToNodes } from './utils';
import { LegalPerson } from 'core/interfaces';
import {
  setLegalPersons,
  updateLegalPerson,
  addLegalPerson,
  removeLegalPerson,
} from 'appRedux/actions';

const mapDispatchToProps = (dispatch) => ({
  setLegalPersons: (legalPersons) => dispatch(setLegalPersons(legalPersons)),

  updateLegalPerson: (id, data) => dispatch(updateLegalPerson(id, data)),

  // Events from chart
  addLegalPerson: (id, pid, data) => dispatch(addLegalPerson(id, pid, data)),
  removeLegalPerson: (id) => dispatch(removeLegalPerson(id)),
});

class AChart extends React.PureComponent {
  // Events coming from the chart
  updateLegalPersonEvent = (sender, args) => {
    // console.log('EVENT');
    let id = args.node.id;
    if (this.props.editable) {
      this.event = {
        action: 'update',
        id,
        parentId: args.pid,
        data: args.data,
      };
      this.props.updateLegalPerson(id, {
        ...args.data,
        parentId: args.pid,
      });
    }
  };
  insertLegalPersonEvent = (sender, args) => {
    const parentId = args.pid;
    console.log('new LP !');
    const parent = this.props.legalPersons.find((x) => x.id === parentId);
    const data = LegalPerson.construct({ parentId, ...args.data }, parent);
    // console.log('EVENT', args);
    if (this.props.editable) {
      this.event = {
        action: 'insert',
        id: data.id,
        parentId,
        data: data,
      };
      this.props.addLegalPerson(data.id, parentId, data);
      if (typeof this.props.onLegalPersonInsert === 'function') this.props.onLegalPersonInsert(data);
    }
  };
  removeLegalPersonEvent = (sender, args) => {
    // console.log('EVENT', args);
    let id = args.id;
    if (this.props.editable) {
      this.event = {
        action: 'remove',
        id,
        parentId: args.pid,
        data: args.data,
      };
      this.props.removeLegalPerson(id);
    }
  };

  moveLegalPersonEvent = (movedId, newParentId, oldParentId) => {
    /* console.log(
      'move ' + movedId + ' (' + typeof movedId + ') to ' + newParentId + ' (prev ' + oldParentId + ')'
    ); */

    // Cannot move `Owner`
    if (oldParentId === null) return;

    const legalPerson = this.props.legalPersons.find((x) => x.id === movedId);

    if (!legalPerson) return console.log('Did not find legalPerson with id ', movedId);

    this.event = {
      action: 'update',
      id: movedId,
      parentId: newParentId,
      data: legalPerson,
    };
    this.props.updateLegalPerson(movedId, {
      ...legalPerson,
      parentId: newParentId,
    });
  };

  clickLegalPersonEvent = (sender, args) => {
    // console.log("click", sender, args);
  };

  render() {
    // console.log('A Chart shall render.', this.props.legalPersons);
    let tmpLegalPersons = addMetaDataToNodes(
      JSON.parse(JSON.stringify(this.props.legalPersons)),
      this.props.metaData,
      this.props.layout
    );
    // console.log('loc ', { tmpLegalPersons });
    const updateConditionCheck =
      typeof this.props.updateChartCondition !== 'function' ? true : this.props.updateChartCondition();

    if (this.event || (this.chart && updateConditionCheck)) {
      // console.log('Force. tmpLegalPersons', tmpLegalPersons, this.props.layout);

      if (this.justForcedChart) {
        // console.log('Naah - just forced..');
        this.justForcedChart = false;
      } else {
        this.chart.updateChart(tmpLegalPersons);
        if (this.event && this.chart && typeof this.props.editCallback === 'function') {
          this.props.editCallback(this.chart.orgchart, this.event);
        }
        this.justForcedChart = true;
        setTimeout(() => {
          this.justForcedChart = false;
        }, 300);
      }

      this.event = undefined;
    }

    // console.log('Chart is re-rendering... ', tmpLegalPersons)

    return (
      <React.Fragment>
        <ChartUnderlying
          ref={(ref) => (this.chart = ref)}
          legalPersonsData={tmpLegalPersons}
          insertLegalPersonEvent={this.insertLegalPersonEvent}
          updateLegalPersonEvent={this.updateLegalPersonEvent}
          removeLegalPersonEvent={this.removeLegalPersonEvent}
          clickLegalPersonEvent={this.props.clickLegalPersonEvent}
          moveLegalPersonEvent={this.moveLegalPersonEvent}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}
export default connect(null, mapDispatchToProps)(AChart);

class ChartUnderlying extends React.Component {
  componentDidMount() {
    if (1) {
      // const {intl} = this.props;

      let searchPlaceholder = 'Search';
      /* try {
        searchPlaceholder = intl.formatMessage({id:'desc.Search'});
      } catch(err) {

      } */

      this.orgchart = new ihdStructure(this.refs.oc, {
        updateInternal: false,

        // createNodeEvent: this.props.createLegalPersonEvent,
        insertNodeEvent: this.props.insertLegalPersonEvent,
        updateNodeEvent: this.props.updateLegalPersonEvent,
        clickNodeEvent: this.props.clickLegalPersonEvent,
        removeNodeEvent: this.props.removeLegalPersonEvent,
        // updatedEvent: this.props.updatedEvent,
        moveNodeEvent: this.props.moveLegalPersonEvent,
        mouseOverEvent: this.props.mouseOverEvent,
        mouseLeaveEvent: this.props.mouseLeaveEvent,
        nodeDropZone: this.props.legalPersonDropZone,
        onDrawCallback: this.props.onDrawCallback,
        searchPlaceholder,
        boxSizeInPercentage: {
          minBoxSize: {
            width: 5,
            height: 5,
          },
          boxSize: {
            width: 20,
            height: 20,
          },
          maxBoxSize: {
            width: 100,
            height: 100,
          },
        },
        color: 'lightblue',
        actions: {
          topLeft: this.props.topLeftAction || 'default', // function, 'default' or 'none'
          topRight: this.props.topRightAction || 'default',
          bottomLeft: this.props.bottomLeftAction || 'default',
          bottomRight: this.props.bottomRightAction || 'default',
          topLeftPredicate: this.props.topLeftPredicate,
          topRightPredicate: this.props.topRightPredicate,
          bottomLeftPredicate: this.props.bottomLeftPredicate,
          bottomRightPredicate: this.props.bottomRightPredicate,
        },
        buttonIcons: {
          topLeft: this.props.topLeftIcon || 'default',
          topRight: this.props.topRightIcon || 'default',
          bottomLeft: this.props.bottomLeftIcon || 'default',
          bottomRight: this.props.bottomRightIcon || 'default',
        },
        primaryFields: ['jurisdiction', 'name', 'identificationNumber'],
        primaryFieldsDefaultEmpty: ['jurisdiction', 'identificationNumber'],
        photoFields: ['image'],
        // theme: "ihd",
        scale: 'auto',
        enableMove: true,
        // enableDragDrop: true,
        enableZoom: true,
        enablePrint: true,
        enableExpand: true,

        enableExportToImage: true,
        // enableGridView: true,

        linkType: 'M',
        enableDragDrop: true,
        enableEdit: true,
        idField: 'id',
        parentIdField: 'parentId',
        secondParentIdField: 'secondParentId',
        thirdParentIdField: 'creditors',
        levelSeparation: 200,
        enableDetailsView: true,
        parentXadjust: -35,
        chartClass: this.props.chartClass ? this.props.chartClass : 'std-chart',
        dataSource: this.props.legalPersonsData || [],
      });
      if (typeof this.props.setOrgChartRef === 'function') this.props.setOrgChartRef(this.orgchart);

      const topCo = LegalPerson.findTopCo(this.props.legalPersonsData);
      const orgchart = this.orgchart;
      if (topCo && topCo.id) orgchart.highlightNode(topCo.id);

      // window.orc = this.orgchart;
    }
  }
  shouldComponentUpdate() {
    return false;
  }

  updateChart = (legalPersons) => {
    if (legalPersons) {
      // console.log('Chart update.');
      this.orgchart.loadFromJSON(legalPersons, false, undefined, true);
    } else console.log('Cannot reload without data');
  };

  render() {
    return (
      <React.Fragment>
        <div className="orgchart_holder">
          <div id="ihd_orgchart" className="ihdchart-org-chart" ref="oc" style={{}}></div>
        </div>
      </React.Fragment>
    );
  }
}

// const ChartUnderlying = injectIntl(ChartUnderlyingX);
