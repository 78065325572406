import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  useIsTemplateStudio,
  useHighlightContractUpdates,
  useDraft,
  useContract,
  setContract,
  useEventState,
  setEventState,
} from 'hooks';
import { useEditor } from 'slate-react';
import { useSelector, useDispatch } from 'react-redux';
import { getStore } from 'appRedux/store';
import { setEditorAllowEditing, setDraft } from 'appRedux/actions';
import { Switch, Tooltip, Radio, Modal, Spin, Tabs } from 'antd';
import { display } from 'core/config/contractDefaults';
import EditTemplateSettings from 'components/TemplateEdit/GeneralSettings';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import api from 'utils/api';
import manager from 'core/engine/manager'

const { TabPane } = Tabs;
const { classes: defaultClasses } = display;

export default function General({ action }) {
  const isTemplate = useIsTemplateStudio();
  const isInput = action === 'input';
  const editorElem =
    document.getElementById('editor-holder') || document.getElementById('preview-editor-holder');

  return (
    <>
      {isTemplate && (
        <>
          <h5 className="mt-2 mb-3">
            <IntlMessages id="studio.sidebar.settings" />
          </h5>
          <TemplateSettings />
        </>
      )}

      {isTemplate && (
        <>
          <h5 className="mt-2 mb-3">API values</h5>
          <TemplateAPIvalues />
        </>
      )}

      <h5 className={'mt-2 mb-3' + (isTemplate ? ' pt-4' : '')}>
        <IntlMessages id="studio.sidebar.general.actions" />
      </h5>
      {/* !isInput &&  */ !isTemplate && <SettingAllowEdit isTemplate={isTemplate} />}
      <UpdateReferences />

      {!isTemplate && <ToggleLiveUpdate />}

      <h5 className="mt-2 mb-4 pt-4">
        <IntlMessages id="studio.sidebar.general.viewSettings" />
      </h5>

      {isTemplate && <TemplateContractHoveringToolbar />}

      {isTemplate && <TemplateContractFocusMode />}

      {!isTemplate && <ChangesShowType />}

      {(!isInput || isTemplate) && <SettingFields editorElem={editorElem} />}

      {!isInput && <SettingShowInactive editorElem={editorElem} />}

      {isInput && <SwitchDirection />}
    </>
  );
}

function Setting({ label, content, borderBottom, className = '' }) {
  className = 'settings mt-2 p-1 ' + (borderBottom ? borderBottom : '') + ' ' + className;

  return (
    <div className={className}>
      <div className="label mini-sidebar-hide">{label}</div>
      {content}
    </div>
  );
}

function TemplateSettings() {
  const [open, setOpen] = useState(false);
  const getContractRef = useRef(null);

  const toggleOpen = () => {
    if (open) getContractRef.current = null; // Clear function.
    setOpen(!open);
  };

  const onCancel = () => {
    setOpen(false);
  };
  const onOk = () => {
    const newContract = getContractRef.current();
    setContract(newContract);
    setOpen(false);
  };

  return (
    <div className={'settings mt-2 p-1'}>
      <div className="label mini-sidebar-hide link" onClick={toggleOpen}>
        {<IntlMessages id="studio.sidebar.settings.editGeneralSettings" />}
      </div>
      <div className="label full-sidebar-hide">
        <Tooltip
          placement="right"
          title={
            <>
              <IntlMessages id="studio.sidebar.settings.editGeneralSettings" />
            </>
          }
        >
          <span className="link" onClick={toggleOpen}>
            <i className="mdi mdi-settings" />
          </span>
        </Tooltip>
      </div>
      {open && (
        <Modal
          title={<IntlMessages id="studio.sidebar.settings.editGeneralSettings" />}
          visible={open}
          onCancel={onCancel}
          onOk={onOk}
          okText={<IntlMessages id="desc.Save" />}
          width={'85vw'}
          maskClosable={false}
        >
          <EditTemplateSettings getContractRef={getContractRef} />
        </Modal>
      )}
    </div>
  );
}

function TemplateAPIvalues() {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onCancel = () => {
    setOpen(false);
  };
  const onOk = () => {
    setOpen(false);
  };

  return (
    <div className={'settings mt-2 p-1'}>
      <div className="label mini-sidebar-hide link" onClick={toggleOpen}>
        {'API Values'}
      </div>
      <div className="label full-sidebar-hide">
        <Tooltip placement="right" title={<>'API Values'</>}>
          <span className="link" onClick={toggleOpen}>
            <i className="mdi mdi-settings" />
          </span>
        </Tooltip>
      </div>
      {open && (
        <Modal
          title={'API Values'}
          visible={open}
          onCancel={onCancel}
          onOk={onOk}
          // okText={<IntlMessages id="desc.Save" />}
          width={'85vw'}
          maskClosable={false}
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab="State Example" key="1">
              <TemplateAPIstateExample />
            </TabPane>
            <TabPane tab="Full API Draft Example" key="2">
              <TemplateAPIfullDraftExample />
            </TabPane>
          </Tabs>
        </Modal>
      )}
    </div>
  );
}

function TemplateAPIstateExample() {
  const [apiState, setApiState] = useState(null);
  const contract = useContract();
  useEffect(() => {
    api
      .post('/draft/inputValues?state=true', contract)
      .then((res) => setApiState(res.data))
      .catch((err) => console.log('err', err.response));
  }, [contract]);

  if (!apiState) return <Spin size="large" />;

  return (
    <div
      style={{
        fontSize: '9px',
        height: '85vh',
      }}
    >
      <CustomScrollbars>
        <div
          style={{
            fontSize: '9px',
            // height: '90vh'
          }}
        >
          <pre>{JSON.stringify(apiState, null, 2)}</pre>
        </div>
      </CustomScrollbars>
    </div>
  );
}

function TemplateAPIfullDraftExample() {
  const [apiState, setApiState] = useState(null);
  const contract = useContract();
  useEffect(() => {
    api
      .post('/draft/inputValues?fullDraft=true', contract)
      .then((res) => setApiState(res.data))
      .catch((err) => console.log('err', err.response));
  }, [contract]);

  if (!apiState) return <Spin size="large" />;

  return (
    <div
      style={{
        fontSize: '9px',
        height: '85vh',
      }}
    >
      <div className="mb-4 p-2 border" style={{ fontSize: '14px' }}>
        <div>
          POST request may be sent to:{' '}
          <span className="p-1 px-2" style={{ background: 'rgb(222 222 222)' }}>
            https://<b>customer</b>-api.com/external/api/v1.0/x/documents/draft
          </span>
        </div>
      </div>
      <CustomScrollbars>
        <div
          style={{
            fontSize: '9px',
            // height: '90vh'
          }}
        >
          <pre>{JSON.stringify(apiState, null, 2)}</pre>
        </div>
      </CustomScrollbars>
    </div>
  );
}

export function SettingAllowEdit({ isTemplate }) {
  const editor = useEditor();

  const editor_allowEditing = useSelector(({ draft }) => draft.editor_allowEditing);
  const dispatch = useDispatch();

  const toggleAllowEditing = useCallback(() => {
    dispatch(setEditorAllowEditing(!editor_allowEditing));
    editor.meta.allowEditing = !editor_allowEditing;
  }, [dispatch, editor_allowEditing, editor]);

  return (
    <Setting
      label={<IntlMessages id="studio.sidebar.general.allowEditing" />}
      content={
        <Tooltip
          placement="right"
          title={
            <>
              <IntlMessages id="studio.sidebar.general.allowEditing" />
            </>
          }
        >
          <Switch
            size="small"
            onChange={toggleAllowEditing}
            checked={editor_allowEditing}
            className="switch-edit-allow-editing"
            id="switch-edit-allow-editing"
          />
        </Tooltip>
      }
    />
  );
}

function UpdateReferences() {
  const editor = useEditor();
  const draft = useDraft();

  const updateReferences = () => {
    editor.cmd_updateReferences(draft);
    if (draft && draft.instance) {
      draft.withDraftMethod('itemJoinContent')
    } 
  }

  return (
    <div className={'settings mt-2 p-1'}>
      <div className="label mini-sidebar-hide link" onClick={updateReferences}>
        {<IntlMessages id="studio.sidebar.general.updateCrossReferences" />}
      </div>
      <div className="label full-sidebar-hide">
        <Tooltip
          placement="right"
          title={
            <>
              <IntlMessages id="studio.sidebar.general.updateCrossReferences" />
            </>
          }
        >
          <span className="link" onClick={updateReferences}>
            <i className="mdi mdi-bookmark-plus-outline" />
          </span>
        </Tooltip>
      </div>
    </div>
  );

  /* return (
    <Setting
      label={<IntlMessages id="studio.sidebar.general.updateCrossReferences" />}
      content={
        <Tooltip
          placement="right"
          title={
            <>
              <IntlMessages id="studio.sidebar.general.updateCrossReferences" />
            </>
          }
        >
          <span className="link" onClick={updateReferences}>Update</span>
        </Tooltip>
      }
    />
  ); */
  /* return (
    <div className="settings p-1">
      <Tooltip placement="right" title={<IntlMessages id="studio.sidebar.general.clickCrossReferences" />}>
        <span className="link" onClick={updateReferences}>
          <i className="mdi mdi-bookmark-plus-outline fs-lg" />
          <small className="hide-menu-small ml-2">
            <IntlMessages id="studio.sidebar.general.updateCrossReferences" />
          </small>
        </span>
      </Tooltip>
    </div>
  ); */
}

function ToggleLiveUpdate() {
  const {id} = useContract()
  const liveUpdate = useEventState('liveUpdate', true);
  const setLiveUpdate = (value) => setEventState('liveUpdate', value);

  const toggleLiveUpdate = () => {
    manager.preventUpdate(id, liveUpdate)
    setLiveUpdate(!liveUpdate);
  };

  const manualUpdate = () => {
    const state = getStore().getState();
    manager.draftSession(state)
  }

  return (
    <>
      <Setting
        label={'Toggle Live Update'}
        content={
          <Tooltip placement="right" title={<>Toggle Live Update</>}>
            <Switch
              size="small"
              onChange={toggleLiveUpdate}
              checked={liveUpdate}
              className="switch-edit-toggle-live-update"
              id="switch-edit-toggle-live-update"
            />
          </Tooltip>
        }
      />
      {!liveUpdate && <div className="ml-2">
        <small className="label link" onClick={manualUpdate}>Update contract manually</small>
      </div>}
    </>
  );
}

function TemplateContractHoveringToolbar() {
  const hoveringToolbar = useEventState('hoveringToolbar', true);
  const setHoveringToolbar = (value) => setEventState('hoveringToolbar', value);

  const toggleHoveringToolbar = () => {
    setHoveringToolbar(!hoveringToolbar);
  };

  return (
    <Setting
      label={<IntlMessages id="studio.sidebar.general.templateHoveringToolbar" />}
      content={
        <Tooltip
          placement="right"
          title={
            <>
              <IntlMessages id="studio.sidebar.general.templateHoveringToolbar" />
            </>
          }
        >
          <Switch
            size="small"
            onChange={toggleHoveringToolbar}
            checked={hoveringToolbar}
            className="switch-edit-show-current-fields"
          />
        </Tooltip>
      }
    />
  );
}

function TemplateContractFocusMode() {
  const contractFocus = useEventState('contractFocus', false);
  const setContractFocus = (value) => setEventState('contractFocus', value);

  const toggleFocus = () => {
    setContractFocus(!contractFocus);
  };

  useEffect(() => {
    const elem = document.getElementById('draft-input-container');
    if (contractFocus) elem.classList.add('contract-focus');
    else elem.classList.remove('contract-focus');
  }, [contractFocus]);

  return (
    <Setting
      label={<IntlMessages id="studio.sidebar.general.templateContractFocus" />}
      content={
        <Tooltip
          placement="right"
          title={
            <>
              <IntlMessages id="studio.sidebar.general.templateContractFocus" />
            </>
          }
        >
          <Switch
            size="small"
            onChange={toggleFocus}
            checked={contractFocus}
            className="switch-edit-show-current-fields"
          />
        </Tooltip>
      }
    />
  );
}

function SettingFields({ editorElem }) {
  const showDocumentStructure = useSelector(({ draft }) => draft.editor_showDocumentStructure);
  const dispatch = useDispatch();
  if (!editorElem)
    editorElem = document.getElementById('editor-holder') || document.getElementById('preview-editor-holder');
  const [showCurrentFields, setShowCurrentFields] = useState(
    (editorElem && editorElem.classList.contains('show-current-fields')) ||
      defaultClasses['show-current-fields']
  );

  const toggleAllFields = useCallback(() => {
    dispatch(setDraft('editor_showDocumentStructure', !showDocumentStructure));
  }, [showDocumentStructure, dispatch]);

  useEffect(() => {
    if (!editorElem) return;
    if (showDocumentStructure) editorElem.classList.add('template-edit');
    else editorElem.classList.remove('template-edit');
  }, [showDocumentStructure, editorElem]);

  const toggleCurrentFields = useCallback(() => {
    setShowCurrentFields(!showCurrentFields);
  }, [showCurrentFields, setShowCurrentFields]);

  useEffect(() => {
    if (!editorElem) return;
    if (showCurrentFields) editorElem.classList.add('show-current-fields');
    else editorElem.classList.remove('show-current-fields');
  }, [showCurrentFields, editorElem]);

  return (
    <>
      <Setting
        label={<IntlMessages id="studio.sidebar.general.showCurrentFields" />}
        content={
          <Tooltip
            placement="right"
            title={
              <>
                <IntlMessages id="studio.sidebar.general.showCurrentFields" />
              </>
            }
          >
            <Switch
              size="small"
              onChange={toggleCurrentFields}
              checked={showDocumentStructure || showCurrentFields}
              disabled={showDocumentStructure}
              className="switch-edit-show-current-fields"
            />
          </Tooltip>
        }
      />

      <Setting
        label={<IntlMessages id="studio.sidebar.general.showDocumentStructure" />}
        content={
          <Tooltip
            placement="right"
            title={
              <>
                <IntlMessages id="studio.sidebar.general.showDocumentStructure" />
              </>
            }
          >
            <Switch
              size="small"
              onChange={toggleAllFields}
              checked={showDocumentStructure}
              className="switch-edit-show-all-fields"
              id="switch-edit-show-all-fields"
            />
          </Tooltip>
        }
      />
    </>
  );
}

function SettingShowInactive({ editorElem }) {
  const showInActive = useSelector(({ draft }) => draft.editor_showInActive);
  const dispatch = useDispatch();

  const toggleInActive = useCallback(() => {
    dispatch(setDraft('editor_showInActive', !showInActive));
  }, [showInActive, dispatch]);

  useEffect(() => {
    if (!editorElem) return;
    if (showInActive) editorElem.classList.add('show-inactive');
    else editorElem.classList.remove('show-inactive');
  }, [showInActive, editorElem]);

  return (
    <Setting
      label={<IntlMessages id="studio.sidebar.general.showInactiveItems" />}
      content={
        <Tooltip
          placement="right"
          title={
            <>
              <IntlMessages id="studio.sidebar.general.showInactiveItems" />
            </>
          }
        >
          <Switch
            size="small"
            onChange={toggleInActive}
            checked={showInActive}
            className="switch-edit-show-fields"
            id="switch-edit-show-inactive"
          />
        </Tooltip>
      }
    />
  );
}

export function ChangesShowType() {
  const { type, setType } = useHighlightContractUpdates({ isTemplate: false });

  const onChange = (evt) => setType(evt.target.value);

  return (
    <Setting
      label={<IntlMessages id="studio.sidebar.general.showChanges" />}
      content={
        <Radio.Group
          value={type}
          size="small"
          className="show-changes-ratios"
          // className="d-flex justify-content-center"
          style={{ flexBasis: '100%', marginTop: '10px' }}
          onChange={onChange}
          // direction='vertical'
        >
          <Radio value="all" className="block-on-mini">
            <IntlMessages id="studio.sidebar.general.changes.all" />
          </Radio>
          <Radio value="recent" className="block-on-mini">
            <IntlMessages id="studio.sidebar.general.changes.recent" />
          </Radio>
          <Radio value="none" className="block-on-mini">
            <IntlMessages id="studio.sidebar.general.changes.none" />
          </Radio>
        </Radio.Group>
      }
    />
  );
}

/* <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onMouseDown={() => {
                  setType("all");
                }}
                disabled={type === "all"}
                className={"preview-show-changes-" + (type === "all" ? "active" : "")}
              >
                <IntlMessages id="studio.sidebar.general.changes.all" />
              </Menu.Item>
              <Menu.Item
                onMouseDown={() => {
                  setType("recent");
                }}
                disabled={type === "recent"}
                className={"preview-show-changes-" + (type === "recent" ? "active" : "")}
              >
                <IntlMessages id="studio.sidebar.general.changes.recent" />
              </Menu.Item>
              <Menu.Item
                onMouseDown={() => {
                  setType("none");
                }}
                disabled={type === "none"}
                className={"preview-show-changes-" + (type === "none" ? "active" : "")}
              >
                <IntlMessages id="studio.sidebar.general.changes.none" />
              </Menu.Item>
            </Menu>
          }
        >
          <small className="link ant-dropdown-link">
            <IntlMessages id={"studio.sidebar.general.changes." + type} />
            <DownOutlined />
          </small>
        </Dropdown> */

function SwitchDirection() {
  const direction = useSelector(({ draft }) => draft.input_preview_direction);
  const dispatch = useDispatch();

  // const [direction, setDirection] = useState(localStorage.getItem("input-direction") || "left")

  const oppositeDirection = direction === 'left' ? 'right' : 'left';

  const toggleDirection = () => {
    localStorage.setItem('input-direction', oppositeDirection);
    dispatch(setDraft('input_preview_direction', oppositeDirection));
  };

  useEffect(() => {
    const element = document.getElementById('draft-input-container');
    if (element) {
      if (direction === 'right') element.classList.add('right');
      else element.classList.remove('right');
    }
  }, [direction]);

  return (
    <Setting
      label={<IntlMessages id="studio.sidebar.general.switchDirection" />}
      content={
        <Tooltip
          placement="right"
          title={
            <>
              <IntlMessages id="studio.sidebar.general.ChangeTo" />{' '}
              <IntlMessages id={'desc.' + oppositeDirection} />
            </>
          }
        >
          <Switch
            size="small"
            onChange={toggleDirection}
            checked={direction === 'right'}
            unCheckedChildren={<i className="mdi mdi-format-horizontal-align-right" />}
            checkedChildren={<i className="mdi mdi-format-horizontal-align-left" />}
            className="switch-edit-show-fields-direction"
            id="switch-edit-direction"
          />
        </Tooltip>
      }
    />
  );
}
