import { useState, useEffect, useCallback, useRef, memo } from 'react';
import { Row, Col, Table, Card, Button, notification, Form, Input, Select, Checkbox, Divider } from 'antd';
import { NavLink } from 'react-router-dom';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import WidgetHeader from 'components/WidgetHeader/index';
import { fixDate } from 'components/ui';
import api from 'utils/api';
import { UnorderedListOutlined, AppstoreOutlined, SearchOutlined } from '@ant-design/icons';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { ContractRulesEditor } from 'components/RuleEditor/index';
import LogicsEditor from 'components/RuleEditor/LogicsEditor';

import Widget from 'components/Widget/index';
import DocItem from './DocItem';

const { Column, ColumnGroup } = Table;

export default function Search() {
  const searchData = useRef({});
  const searchClearers = useRef({});

  const [searchResult, setSearchResult] = useState(null);
  const [masterContract, setMasterContract] = useState(null);
  const [searchWithLogic, setSearchWithLogic] = useState(false);

  useEffect(() => {
    // Fetch all documentTemplates combined into one contract
    // for the purpose of supporting all logic rules to be build
    (async function fetch() {
      try {
        const res = await api.get('/documenttemplates/allAsOne');
        if (!res || !res.data) return;
        setMasterContract(res.data);
      } catch (err) {
        console.log('Err fetching all', err);
      }
    })();
  }, []);

  const clearSearch = () => {
    searchData.current = {};
    if (searchClearers.current.projects) {
      searchClearers.current.projects();
    }
    if (searchClearers.current.documentTemplates) {
      searchClearers.current.documentTemplates();
    }
    if (searchClearers.current.string) {
      searchClearers.current.string();
    }
    if (searchClearers.current.rule) {
      searchClearers.current.rule();
    }
  };

  const clearSearchResults = () => setSearchResult(null);

  const onSearch = useCallback(() => {
    async function searchFn() {
      try {
        let query = '';
        const { string, withRule, ruleTuple, projects = [], documentTemplates = [] } = searchData.current;
        const [rule, ruleValid] = ruleTuple || [];
        console.log('rule typle ', ruleTuple);
        if (string) {
          query += `string=${string}`;
        }
        if (withRule) {
          if (!ruleValid) return console.log('rule is not valid');
          if (string) query += '&';
          query += `rule=${JSON.stringify(rule)}`;
        }

        if (!query) {
          return notification.error({
            message: 'Invalid search parameters',
            description: 'Please specify the search parameters in more detail',
          });
        }

        if (projects.length > 0) {
          query += `&projectIds=${JSON.stringify(projects)}`;
        }
        if (documentTemplates.length > 0) {
          query += `&documentTemplateIds=${JSON.stringify(documentTemplates)}`;
        }

        query += '&get=documents&documentTemplateName=true';
        console.log('query is ', query);

        const res = await api.get('/search?' + query);
        if (!res || !res.data) return;
        setSearchResult(res.data);
      } catch (err) {
        console.log('Err fetching all', err);
        return notification.error({
          message: 'Search failed',
          description: 'Search could not be performed at this time. Please try again later.',
        });
      }
    }
    searchFn();
  }, [searchData]);

  // console.log('master contr ? ', { masterContract });

  return (
    <>
      <WidgetHeader title={'Search'} styleName="mb-3" />
      <div className="mt-4 mb-4">
        <small>Here you may search for documents, contracts and/or meta data</small>
      </div>
      <Row>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Form layout={'vertical'}>
            <NameAndDescription searchData={searchData} searchClearers={searchClearers} />
          </Form>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form layout={'vertical'}>
            <WithinProjects searchData={searchData} searchClearers={searchClearers} />
          </Form>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form layout={'vertical'}>
            <WithinDocumentTemplates searchData={searchData} searchClearers={searchClearers} />
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {masterContract && (
            <Form.Item className="m-2">
              <Checkbox
                className=""
                checked={searchWithLogic}
                onChange={() => {
                  setSearchWithLogic(!searchWithLogic);
                  searchData.current.withRule = !searchWithLogic;
                }}
              >
                Search with logic
              </Checkbox>
            </Form.Item>
          )}
        </Col>
      </Row>

      {masterContract && searchWithLogic && (
        <WithLogics searchData={searchData} searchClearers={searchClearers} masterContract={masterContract} />
      )}
      <Row className="mb-3">
        <Col sm={24} md={12} lg={12}>
          <Button type="primary" className="m-0" onClick={onSearch} block>
            Search
          </Button>
        </Col>
        <Col sm={24} md={12} lg={12} className="d-flex align-items-center">
          <span className="link" onClick={clearSearch}>
            Clear search parameters
          </span>
        </Col>
      </Row>
      <Row></Row>
      <SearchResult results={searchResult} clearSearchResults={clearSearchResults} />
    </>
  );
}

const NameAndDescription = memo(function NameAndDescription({ searchData, searchClearers, ...rest }) {
  const formatMessage = useIntlMessage();

  // Text - name and description
  const [plainText, setPlainText] = useState('');
  const onPlainTextChange = useCallback(
    (evt) => {
      const { value } = evt.target;
      setPlainText(value);
      searchData.current.string = value;
    },
    [searchData]
  );

  useEffect(() => {
    searchClearers.current.string = () => setPlainText('');
  }, [searchClearers]);

  const plainTextValue = plainText.toLocaleLowerCase();

  return (
    <>
      <Form.Item label={'Name / Description'} {...rest}>
        <Input
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={formatMessage('desc.Search') + '...'}
          value={plainTextValue}
          onChange={onPlainTextChange}
          allowClear
        />
      </Form.Item>
    </>
  );
});

const WithinProjects = memo(function WithinProjects({ searchData, searchClearers, ...rest }) {
  const [values, setValues] = useState([]);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    // Fetch projects
    (async function fetch() {
      try {
        const res = await api.get('/projects?fields=id,name');
        if (!res || !res.data) return;
        setProjects(res.data);
      } catch (err) {
        console.log('Err fetching all', err);
      }
    })();
  }, []);

  const handleChange = (val) => {
    setValues(val);
    searchData.current.projects = val;
  };
  useEffect(() => {
    searchClearers.current.projects = () => setValues([]);
  }, [searchClearers]);
  return (
    <>
      <Form.Item label={'Within project(s)'} {...rest}>
        <Select
          mode="multiple"
          placeholder="Limit search to contracts within these projects"
          value={values}
          onChange={handleChange}
          style={{ width: '100%' }}
          filterOption={(inputValue, option) => {
            const { children } = option;
            const lower = children.toLocaleLowerCase();
            return lower.includes(inputValue.toLocaleLowerCase());
          }}
          allowClear
        >
          {projects &&
            projects.map((p) => (
              <Select.Option key={p.id} value={p.id} label={p.name}>
                {p.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </>
  );
});

const WithinDocumentTemplates = memo(function WithinDocumentTemplates({
  searchData,
  searchClearers,
  ...rest
}) {
  const [values, setValues] = useState([]);
  const [documentTemplates, setDocumentTemplates] = useState(null);

  useEffect(() => {
    // Fetch documentTemplates
    (async function fetch() {
      try {
        const res = await api.get('/documenttemplates?fields=id,name');
        if (!res || !res.data) return;
        setDocumentTemplates(res.data);
      } catch (err) {
        console.log('Err fetching all', err);
      }
    })();
  }, []);

  const handleChange = (val) => {
    setValues(val);
    searchData.current.documentTemplates = val;
  };
  useEffect(() => {
    searchClearers.current.documentTemplates = () => setValues([]);
  }, [searchClearers]);
  return (
    <>
      <Form.Item label={'Of type(s)'} {...rest}>
        <Select
          mode="multiple"
          placeholder="Limit search to contracts within these types"
          value={values}
          onChange={handleChange}
          style={{ width: '100%' }}
          filterOption={(inputValue, option) => {
            const { children } = option;
            const lower = children.toLocaleLowerCase();
            return lower.includes(inputValue.toLocaleLowerCase());
          }}
        >
          {documentTemplates &&
            documentTemplates.map((p) => (
              <Select.Option key={p.id} value={p.id}>
                {p.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </>
  );
});

const WithLogics = memo(function WithLogics({ searchData, searchClearers, masterContract }) {
  const clearRulesRef = useRef(null);

  const onRuleChange = (tuple) => {
    searchData.current.ruleTuple = tuple;
  };

  const setRulesSetter = useCallback(
    (setRules) => {
      searchClearers.current.rule = () => setRules({});
      clearRulesRef.current = () => setRules({});
    },
    [searchClearers]
  );

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      searchClearers.current.rule = null;
    };
  }, [searchClearers]);

  if (!masterContract) return null;

  return (
    <div className="ml-1 p-2 border bg-white mb-2">
      <div className="wrap-rule-modal">
        <div className="rule-modal">
          <LogicsEditor
            contract={masterContract}
            onChange={onRuleChange}
            language="en"
            ignoreLocalCard
            setRulesSetter={setRulesSetter}
          />

          {/* <Button className="mt-2" onClick={clearRulesRef.current}>Clear rule</Button> */}
        </div>
      </div>
    </div>
  );
});

const DocumentsDisplay = ({ results }) => {
  if (!results) return null;

  // return results.map((data, index) => <DocItem key={index} data={data} widget />);

  return (
    <Widget styleName="card-profile mt-4">
      <div className="ant-card-head">
        {/* <span className="ant-card-head-title mb-1">Documents</span> */}
        {/* <p className="text-grey fs-sm mb-0">Description</p> */}
      </div>
      <div className="pt-md-3">
        {results.map((data, index) => (
          <DocItem key={index} data={data} />
        ))}
      </div>
    </Widget>
  );
};

const SearchResult = memo(({ results, clearSearchResults }) => {
  // console.log('results ', results);

  const [orderBy, setOrderBy] = useState(null);

  const onChangeOrderBy = (val) => {
    setOrderBy(val);
  };

  const resultsLength = (results && results.length) || 0;
  return (
    <>
      <Divider orientation="left" className="mt-3 mb-2">
        <span className="mr-4">Search Results</span>
      </Divider>
      {results && resultsLength > 0 ? (
        <>
          <div className="d-flex justify-content-space-between">
            <div>
              <span className="ml-2 mr-3">There are {resultsLength} search results.</span>
              <small className="link" onClick={clearSearchResults}>
                Clear results
              </small>
            </div>
            <div>
              <Form layout="vertical">
                <Form.Item label="Order by" className="m-0" style={{ minWidth: '180px' }}>
                  <Select value={orderBy} onChange={onChangeOrderBy} placeholder="Order by">
                    <Select.Option value={null}>
                      <em>None</em>
                    </Select.Option>
                    <Select.Option value={'terminationDate'}>Termination Date</Select.Option>
                    <Select.Option value={'contractValue'}>Contract Value</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </div>
          <DocumentsDisplay results={results} />
        </>
      ) : (
        <div>No documents or contracts matched your search parameters</div>
      )}

      {/* <DocumentResults results={results} /> */}
    </>
  );
});

function DocumentResults({ results }) {
  return (
    <Table
      // columns={columns}
      rowKey={(record) => record.id}
      expandable={{
        expandedRowRender: (record) => (
          <VersionsList
            results={record.versions}
            projectId={record.projectId}
            documentId={record.documentId}
          />
        ),
      }}
      dataSource={results}
    >
      <Column title={<IntlMessages id="app.general.Name" />} dataIndex="name" key="name" />
      {/* <Column
        title={<IntlMessages id="app.general.Description" />}
        dataIndex="description"
        key="description"
      /> */}
      {/* <Column
        title={<IntlMessages id="app.general.LastModified" />}
        dataIndex="updatedAt"
        key="updatedAt"
        render={(text) => fixDate(text)}
      /> */}
      <Column
        title={
          <>
            # <IntlMessages id="general.Versions" />
          </>
        }
        dataIndex="versions"
        key="versions"
        render={(text, record) => {
          return record.versions ? record.versions.length : 0;
        }}
      />
      <Column
        title={<IntlMessages id="app.general.Action" />}
        key="actions"
        render={(text, record) => (
          <span className="margin-0">
            <NavLink to={`/project/${record.projectId}/document/${record.id}`}>
              <IntlMessages id={'app.main.goToDocument'} />
            </NavLink>
          </span>
        )}
      />
    </Table>
  );
}

function VersionsList({ results, projectId, documentId }) {
  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={results}
      pagination={false}
      bordered={false}
      size="middle"
      className="versions-list"
      style={{ margin: '5px 0 5px 0px' }}
    >
      <Column title={<IntlMessages id="app.general.Name" />} dataIndex="name" key="name" />
      <Column
        title={<IntlMessages id="app.general.LastModified" />}
        dataIndex="updatedAt"
        key="updatedAt"
        render={(text) => fixDate(text)}
      />
      <Column
        title={<IntlMessages id="app.general.Action" />}
        key="actions"
        render={(text, record) => (
          <span className="margin-0">
            <NavLink to={`/project/${projectId}/document/${documentId}/version/${record.id}`}>
              <IntlMessages id={'app.main.goToVersion'} />
            </NavLink>
          </span>
        )}
      />
    </Table>
  );
}
