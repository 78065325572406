/* eslint-disable import/prefer-default-export */
import moment from 'moment-business-days';
import { find } from '../../engine/utils'
import { getByPath } from '../../utils/general';

const Dates = {}

function getValue(node, type, value) {
  const { type: valueType } = value;
  // console.log('ValueType ', {node, valueType, value})
  switch (valueType) {
    case 'in-clause':
      return getByPath(node, (value.location || 'data.value'));
    case 'fixed':
      return value.value;
    default:
      return null;
  }
}

function adjustDate(date, format, value, direction) {
  const intValue = parseInt(value, 10);
  let newDate = moment(date);
  // const newDate = new Date(date);
  switch (format) {
    case 'day':
    case 'days':
      if (direction === 'before') {
        newDate.subtract(value, 'days');
      } else if (direction === 'after') {
        newDate.add(value, 'days');
      }
      break;
    case 'businessdays':
      if (direction === 'before') {
        console.log('del business Days: ', { value, newDate: newDate.toISOString() });
        newDate = newDate.businessSubtract(intValue);
        console.log('deled business Days: ', { value, newDate: newDate.toISOString() });
      } else if (direction === 'after') {
        console.log('add business Days: ', { value, newDate: newDate.toISOString() });
        newDate = newDate.businessAdd(intValue);
        console.log('added business Days: ', { value, newDate: newDate.toISOString() });
      }
      break;
    case 'month':
    case 'months':
      // newDate.setMonth(newDate.getMonth() + intValue);
      newDate.add(intValue, 'months');
      break;
    case 'year':
    case 'years':
      // newDate.setFullYear(newDate.getFullYear() + intValue);
      newDate.add(intValue, 'years');
      break;
    default:
      return newDate;
  }
  return newDate;
}

function applyModifier(modifierObj, baseDates) {
  if (!modifierObj) return null;
  const { value, format, direction, base } = modifierObj;
  // console.log('modifierObj type', type);

  if (!base || !baseDates[base]) return null;
  if (!value || Number.isNaN(parseInt(value, 10))) return null;

  if (direction && format) {
    return adjustDate(baseDates[base], format, value, direction);
  }
  return null;
}

function handleDate(nodes, baseDates) {
  const result = {};
  // const modifier = {}
  for (const node of nodes) {
    const { type, value } = node.date;
    if (!type || !value) {
      console.warn('Invalid date node ', { node });
      continue;
    }
    result[type] = getValue(node, type, value);
    // modifier[type] = value
  }
  console.log('results so far ', result);
  const datesApplied = applyModifier(result, baseDates);
  return {
    result,
    applied: datesApplied,
    date: datesApplied.toISOString(),
  };
}

function applyDate({ baseDates = {}, content, locale = 'en' }) {
  moment.updateLocale(locale, {
    workingWeekdays: [1, 2, 3, 4, 5],
    holidays: ['24/12'],
    holidayFormat: 'DD/MM',
  });

  const dateItems = find(content, (node) => !!node.date);

  const dateResult = handleDate(dateItems, baseDates);

  return dateResult;
  /*
  const task = {
    taskType: provision.data.provision.type,
    data: {
      provision: provision,
    },
    resourceType: 'Document',
    resourceId: 'xxx',
    assignedOrgId: 'xxx',
    assignedOrgType: 'Customer',
    source: 'auto',
  };
  const schedules = [
    {
      scheduleType: 'reminder',
      processAt: new Date(
        new Date(dateResult.date).setDate(new Date(dateResult.date).getDate() - 2)
      ).toISOString(),
    },
    {
      scheduleType: 'deadline',
      processAt: dateResult.date,
    },
  ];

  console.log('Date items', { dateItems, dateResult, task, schedules }); */
}


Dates.applyDate = applyDate
Dates.applyModifier = applyModifier

export { Dates }