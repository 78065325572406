import React, { useEffect, memo } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
import AppsNavigation from './AppsNavigation';
import IntlMessages from '../../util/IntlMessages';
import { useSelector, useDispatch } from 'react-redux';
import { setNumbers } from 'appRedux/actions';
import api from 'utils/api';

import MainActions from 'containers/App/MainActions';

let menuEntries = [
  {
    key: 'search',
    path: '/search',
    nameId: 'search',
    icon: 'mdi mdi-magnify',
    activePathCatch: ['/search'],
  },
  {
    key: 'main',
    path: '/main',
    nameId: 'main',
    icon: 'mdi mdi-home',
    activePathCatch: ['/main'],
  },
  {
    path: '/projects',
    nameId: 'projects',
    icon: 'mdi mdi-cube-outline',
    activePathCatch: ['/project'],
    numberKey: 'projects',
  },
  {
    path: '/folders',
    nameId: 'folders',
    icon: 'mdi mdi-folder-outline',
    activePathCatch: ['/folder'],
    numberKey: 'folders',
  },
  {
    path: '/entities',
    nameId: 'entities',
    icon: 'icon-company',
    activePathCatch: ['/entities', '/entity'],
    numberKey: 'customers',
  },
  // {
  //   path: "/persons",
  //   nameId: "persons",
  //   icon: "icon-company",
  //   activePathCatch: ["/persons"],
  // },
  {
    path: '/tasks',
    nameId: 'tasks',
    icon: 'icon-tasks',
    activePathCatch: ['/tasks'],
  },
  /*   {
    path: "/applies",
    nameId: "apply",
    icon: "mdi mdi-access-point-network",
    activePathCatch: ["/applies", "/apply"],
  }, */
  {
    path: '/help',
    nameId: 'help',
    icon: 'mdi mdi-help-circle-outline',
    activePathCatch: ['/help'],
    space: true,
  },
];

if (process.env.REACT_APP_DEV_DEPLOYMENT || process.env.REACT_APP_NODE_ENV === 'development') {
  menuEntries.splice(menuEntries.length - 1, 0, {
    path: '/templates',
    nameId: 'templates',
    icon: 'mdi mdi-settings-box',
    activePathCatch: ['/templates'],
  });
}

const SidebarContent = () => {
  const numbers = useSelector(({ common }) => common.numbers);
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();

  useEffect(() => {
    async function getNumbers() {
      try {
        const projectProjects = await api.get('/projects/count?where=isFolder_->_NULL-or-FALSE');
        const folderProjects = await api.get('/projects/count?where=isFolder_->_true');
        const customersFetch = await api.get('/customers/count');

        const folders = folderProjects.data.count;
        const projects = projectProjects.data.count;
        const customers = customersFetch.data.count;

        dispatch(setNumbers({ folders, projects, customers }));
      } catch (err) {
        console.log('Fetch count err', { err, response: err.response });
      }
    }
    getNumbers();
  }, [dispatch]);

  const currentMainPath = '/' + pathname.split('/')[1];
  const selectedKey = menuEntries.find((entry) => {
    if (entry.path && entry.path === currentMainPath) return true;
    if (entry.activePathCatch && entry.activePathCatch.includes(currentMainPath)) return true;
    return false;
  });

  return (
    <>
      <SidebarLogo />
      <div className="sidebar-content">
        <div className={`sidebar-notifications`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={['main']}
            selectedKeys={[selectedKey ? selectedKey.nameId : '']}
            theme={'lite'}
            mode="inline"
          >
            {menuEntries.map((entry) => (
              <Menu.Item key={entry.nameId} className={entry.space ? 'border-top mt-3 pt-3' : ''}>
                <Link to={entry.path}>
                  <i className={'icon ' + entry.icon} />
                  <span>
                    <IntlMessages id={'sidebar.' + entry.nameId} />
                  </span>
                  {numbers[entry.numberKey] !== undefined && (
                    <span className="sidebar-number">{numbers[entry.numberKey]}</span>
                  )}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </CustomScrollbars>
        <MainActions />
      </div>
    </>
  );
};

export default memo(SidebarContent);
