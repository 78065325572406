
export function getPath(baseRoute, path) {
  if (path.substr(0, 8) === "/create/") path = path.substr(7);
  return baseRoute + path;
}

export function firstPath(routes) {
  if(!Array.isArray(routes) || !routes[0]) {
      console.log('Invalid routes')
      return null
  }
  for(const route of routes) {
      if(!route.collapse) return route.fullPath
      else if(route.children) {
          const childPath = firstPath(route.children)
          if(childPath) return childPath
      }
  }
  return null
}