import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Row, Col, Typography, Tooltip, Button, Modal, Form, Input, Radio, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { useHistory } from 'react-router-dom';

const { Paragraph } = Typography;

export const TYPES = {
  reject: {
    messageOptions: ['oneChangedMind', 'weChangedMind'],
    icon: 'mdi-close-outline',
    btnClassName: 'btn-dangerous',
    btnType: 'danger',
  },
  approve: {
    messageOptions: ['looksGood', 'lookingForward'],
    icon: 'mdi-check-circle',
    btnClassName: 'btn-success',
    btnType: 'success',
  },
  feedback: {
    messageOptions: ['minorComments', 'proposedChangesHappy'],
    icon: 'mdi-send',
    btnClassName: 'btn-primary',
    btnType: 'primary',
  },
};

function useResponseMessageOptions(action) {
  const formatMessage = useIntlMessage();
  const messageOptions = [];
  for (const option of TYPES[action].messageOptions) {
    const msg = formatMessage('app.external.initResponse.messages.' + option);
    messageOptions.push({
      label: (
        <span className="message">
          <i className="mdi mdi-format-quote" />
          {msg}
          <i className="mdi mdi-format-quote" />
        </span>
      ),
      value: msg,
    });
  }
  messageOptions.push({
    label: <em><span className="message">{formatMessage('app.external.initResponse.messages._custom_')}</span></em>,
    value: '_custom_',
  });
  return messageOptions;
}

export default function Response({ action, children, dispatchResponse, ...rest }) {
  const [formRef, setFormRef] = useState(null);
  const [visible, setVisible] = useState(false);
  const [customMessage, setCustomMessage] = useState(false);
  const customMessageRef = useRef();
  const toggleVisible = () => setVisible(!visible);

  const messageOptions = useResponseMessageOptions(action);

  if (!action) {
    throw new Error('No action provided to Response');
  }

  const setting = TYPES[action];

  const onFinish = (values) => {
    console.log('Received values of form: ', values);

    const feedbackData = {
      status: action,
      message: values.message === '_custom_' ? values.custom_message : values.message,
    };

    dispatchResponse(action, feedbackData);

    formRef.resetFields();
    setVisible(false);
  };

  const handleFormValuesChange = (changedValues) => {
    if (changedValues.message) {
      setCustomMessage(changedValues.message === '_custom_');
    }
  };

  useEffect(() => {
    if (!customMessage || !customMessageRef.current) return;
    customMessageRef.current.focus();
  }, [customMessage]);

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const title = <IntlMessages id={'app.external.action.' + action} cap />;

  const modal = (
    <Modal
      visible={visible}
      title={
        <>
          <i className={'mdi ' + setting.icon + ' ant-typography ant-typography-info mr-2'} />
          {title}
        </>
      }
      okText={
        <>
          <IntlMessages id={'app.messages.submit'} cap /> {title}
        </>
      }
      okButtonProps={{
        className: 'btn-primary',
      }}
      onCancel={() => setVisible(false)}
      onOk={formRef && formRef.submit}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        ref={saveFormRef}
        onFinish={onFinish}
        initialValues={{ send_notes: 'false' }}
        onValuesChange={handleFormValuesChange}
      >
        <Form.Item
          label={<IntlMessages id={'app.external.initResponse.message'} />}
          name="message"
          className="feedback-message-block"
        >
          <Radio.Group options={messageOptions} />
        </Form.Item>
        {customMessage && (
          <Form.Item
            label={<IntlMessages id={'app.external.initResponse.customMessage'} />}
            name="custom_message"
          >
            <Input.TextArea ref={customMessageRef} autoSize={{ minRows: 2, maxRows: 12 }} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );

  if (!children) {
    return (
      <>
        {modal}
        <div>
          <Tooltip
            title={
              <>
                {title} <IntlMessages id={'general.document'} cap />
              </>
            }
            placement="left"
          >
            <Button
              // disabled={!selection || !isExpanded}
              size="large"
              type={setting.btnType}
              shape="circle"
              onMouseDown={toggleVisible}
              className={'sted-btn guide-ext-' + action + '-contract'}
            >
              <i className={'mdi ' + setting.icon} />
            </Button>
          </Tooltip>
        </div>
      </>
    );
  }

  return (
    <>
      {modal}
      <Button onMouseDown={toggleVisible} {...rest} type={setting.btnType} block>
        {children}
      </Button>
    </>
  );
}

export const useResponsePopup = () => {
  const formatMessage = useIntlMessage();
  const history = useHistory();

  return (action, response) => {
    const { icon, btnType } = TYPES[action];
    notification.success({
      message: formatMessage('app.external.response.title', 'cap')
    });
    Modal.success({
      title: formatMessage('app.external.response.title', 'cap'),
      icon: <CheckCircleOutlined />,
      content: (
        <>
          <p>{formatMessage('app.external.response.header', 'cap')}</p>
          <div className="mb-2">
            <Row className="pt-4 mt-4 border-top">
              <Col span={9}>
                <strong>{formatMessage('app.external.response.response', 'cap')}:</strong>
              </Col>
              <Col span={15} className={'text-' + btnType}>
                <i className={'mdi ' + icon} />
                <span className="ml-2">{formatMessage('app.external.response.' + action, 'cap')}</span>
              </Col>
            </Row>
            <Row className="mt-3 mb-4 pb-4 border-bottom">
              <Col span={9}>
                <strong>{formatMessage('app.external.response.message', 'cap')}:</strong>
              </Col>
              <Col span={15}>
                {response.message ? (
                  <span>{response.message}</span>
                ) : (
                  <em>{formatMessage('app.external.response.noMessage', 'cap')}</em>
                )}
              </Col>
            </Row>
          </div>

          <p className="mt-4">
            <u>{formatMessage('app.external.response.note', 'cap')}</u>
          </p>
          <p>{formatMessage('app.external.response.linkText', 'cap')}:</p>
          <Paragraph copyable>{window.location.href}</Paragraph>
        </>
      ),
      onOk() {
        history.push('/sc');
      },
      cancelButtonProps: { className: 'd-none' },
      onCancel: null,
    });
  };
};
