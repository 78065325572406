import React, { memo, useMemo } from 'react';
import { Table as EditorTable } from '../../plugins/slate-table/elements/table';
import { BLOCK_RENDER_OPTIONS } from 'core/types/elements';
import { useIsTemplateStudio } from 'hooks';
import { Indicator, BlockMenu } from './blocks/';
import { useAttributes, getImageSrc, getImageStyle } from 'core/utils/formatting';
import { useSelector } from 'react-redux';
// import Selectable from "./dnd/Selectable";
import { cx, css } from 'emotion';

export const Img = memo((props) => {
  if (props.element && props.element.data && props.element.data._inActive) return null;

  const { attributes, element, children } = props;
  const src = getImageSrc(element);
  const style = getImageStyle(element);

  if (!src) {
    return (
      <div {...attributes} style={style} className="editor-image-not-found">
        {children}
      </div>
    );
  }

  return (
    <div {...attributes}>
      <img className="editor-image" alt="Contract Image" src={src} style={style} contentEditable={false} />
      {children}
    </div>
  );
});

// For general comments submitted by an external party.
export const notes_list_item = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content notes_list_item">{children}</div>;
});
// For general comments submitted by an external party.


export const Section = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});
export const Clause = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});
export const Container = memo(({ attributes, element, children, editor }) => {
  return (
    <div {...attributes} {...useAttributes(editor, element)}>
      <div className="block-content">{children}</div>
    </div>
  );
});
export const Schedule = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});
export const Definitions = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});

export const Paragraph = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});

export const Textbox = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});

export const Numbered_list = memo(({ attributes, element, children, editor }) => {
  let className = 'block-content';
  if (element.data && element.data._format && element.data._format.listType) {
    const { listType, indicatorPrefix = '', indicatorSuffix = '' } = element.data._format;
    let listTypeClass = `
     > div > div > div:not(.block-inactive):not(.list-item-hanging) > li:before {
      content: "${indicatorPrefix}" counter(list-level-1, ${listType}) "${indicatorSuffix}" !important;
    }
    `;
    className = cx([
      className,
      css`
        ${listTypeClass}
      `,
    ]);
  }
  return <ol className={className}>{children}</ol>;
});

export const Bulleted_list = memo(({ attributes, element, children, editor }) => {
  return <ul className="block-content">{children}</ul>;
});

export const Heading_one = memo(({ attributes, element, children, editor }) => {
  return <h1 className="block-content">{children}</h1>;
});

export const Heading_two = memo(({ attributes, element, children, editor }) => {
  return <h2 className="block-content">{children}</h2>;
});

export const Heading_three = memo(({ attributes, element, children, editor }) => {
  return <h3 className="block-content">{children}</h3>;
});

export const Schedule_one = memo(({ attributes, element, children, editor }) => {
  return <div>{children}</div>;
});

export const Schedule_two = memo(({ attributes, element, children, editor }) => {
  return <div>{children}</div>;
});

export const List_item = memo(({ attributes, element, children, editor }) => {
  return <li className={"block-content"}>{children}</li>;
});

export const BlockWrapper = memo(({ attributes, element, children, editor }) => {
  const styleAttributes = useAttributes(editor, element);
  const draft_mode = useSelector(({ draft }) => draft.draft_mode);
  const isTemplate = useIsTemplateStudio();

  if (BLOCK_RENDER_OPTIONS.customRender.includes(element.type)) {
    return children;
  }

  if (draft_mode !== 'contract' && !isTemplate) {
    return (
      <div className={'block-container type-' + element.type}>
        <div className={'block-middle type-' + element.type}>
          <div {...attributes} {...styleAttributes}>
            {children}
          </div>
        </div>
      </div>
    );
  }
  return <BlockWrapperX attributes={attributes} children={children} editor={editor} element={element} />;
});

export const BlockWrapperX = memo(({ attributes, element, children, editor }) => {
  const styleAttributes = useAttributes(editor, element);

  if (BLOCK_RENDER_OPTIONS.customRender.includes(element.type)) {
    return children;
  }

  let content = (
    <>
      {!BLOCK_RENDER_OPTIONS.withoutIndicator.includes(element.type) && (
        <Indicator element={element} editor={editor} />
      )}
      {children}
    </>
  );

  if (BLOCK_RENDER_OPTIONS.withBlockMenu.includes(element.type) && !element._isInActiveRepresentation) {
    content = (
      <BlockMenu editor={editor} element={element}>
        {content}
      </BlockMenu>
    );
  }

  /* if (BLOCK_RENDER_OPTIONS.draggable.includes(element.type)) {
    if (element.data && element.data.item_id) {
      return (
        <Selectable element={element}>
          <div {...attributes} {...styleAttributes}>
            {content}
          </div>
        </Selectable>
      );
    } else {
      console.log("Element missing item id...", element);
    }
  } */

  return (
    <div className={'block-container type-' + element.type}>
      <div className={'block-middle type-' + element.type}>
        <div {...attributes} {...styleAttributes}>
          {content}
        </div>
      </div>
    </div>
  );
});

export const Table = memo((props) => {
  if (props.element && props.element.data && props.element.data._inActive) return null;
  return <EditorTable {...props} />;
});
export const Table_row = memo((props) => {
  if (props.element && props.element.data && props.element.data._inActive) return null;
  return <EditorTable {...props} />;
});
export const Table_cell = memo((props) => {
  if (props.element && props.element.data && props.element.data._inActive) return null;
  return <EditorTable {...props} />;
});
export const Table_content = memo((props) => {
  if (props.element && props.element.data && props.element.data._inActive) return null;
  return <EditorTable {...props} />;
});

export const block_quote = memo(({ attributes, element, children, editor }) => {
  return <blockquote {...attributes}>{children}</blockquote>;
});
