import React from "react";
import { Row } from "antd";
import IntlMessages from "util/IntlMessages";

export default function SigningStarted ({ data, onClose }) {
  const { signUrl } = data;

  const openSignWindow = (signUrl) => {
    window.open(signUrl, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=768,height=1024");
  }

  return (
    <div>
      <Row className="flex-row m-0 mt-4">
        <h5>
          <IntlMessages id="app.signing.started.header" />
        </h5>
      </Row>
      {signUrl && (
        <>
          <Row className="flex-row m-0 mt-4">
            <span><IntlMessages id="app.signing.started.signingParty" /></span>
          </Row>
          <Row className="flex-row m-0 mt-4">
            <div onClick={() => openSignWindow(signUrl)}>
              <span className="link">
                <i className="mdi mdi-lead-pencil mr-1" />
                <IntlMessages id="app.signing.started.signContract" />
              </span>
              <div>
                <small>
                  <IntlMessages id="app.signing.started.openWindow" />
                </small>
              </div>
            </div>
          </Row>
          <Row className="flex-row m-0 mt-4">
            <span>
              <IntlMessages id="app.signing.started.checkStatus" />
            </span>
          </Row>
        </>
      )}
    </div>
  );
}