import React from 'react';
import { Tooltip } from 'antd';

const OverlayActions = ({ direction = 'horizontalx', topAction, bottomAction, children }) => {
  return (
    <div>
      {children}
      <div className={'widget-item-overlay overlay-' + direction}>
        <div className="action" onClick={topAction.onClick || null}>
          <div className="action-content">
            {topAction.tooltip ? (
              <Tooltip title={topAction.tooltip} placement={topAction.tooltipPlacement || 'top'}>
                {topAction.text}
              </Tooltip>
            ) : (
              topAction.text
            )}
          </div>
        </div>
        <div className="action" onClick={bottomAction.onClick || null}>
          <div className="action-content">
            {bottomAction.tooltip ? (
              <Tooltip title={bottomAction.tooltip} placement={bottomAction.tooltipPlacement || 'top'}>
                {bottomAction.text}
              </Tooltip>
            ) : (
              bottomAction.text
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayActions;
