import {
  imp_and as impAndUtil,
  imp_or as impOrUtil,
  combineNumber as combineNumberUtil,
  splitNumber as splitNumberUtil,
} from '../../utils/general';
import uuid from 'uuid-random';
import { Contract } from '../../interfaces';

/**
 * makeUniqueId: Generates a unique id.
 *
 * @return {string}                     Returns a (somewhat )unique id.
 */
export function makeUniqueId() {
  return uuid();
}

/**
 * combineNumber: Combine number string into parseInt'able string.
 * Outcome depends on contract language
 *
 * @param {string}       value      Number string, e.g. 78,000,000 (en) or 78 000 000 (sv)
 * @return {string}                 Clean string, e.g. 78000000
 */
/* export const combineNumber = function (value) {
  return combineNumberUtil(value, this.language);
}; */

/**
 * splitNumber: Split number into human readable string.
 * Outcome depends on contract language.
 *
 * @param {string/number} value     Number to parse, e.g. 78000000
 * @return {string}                 Human readable string, e.g. 78,000,000 (en) or 78 000 000 (sv)
 */
/* export const splitNumber = function (value) {
  return splitNumberUtil(value, this.language);
}; */

/**
 * imp_and: Implode and join with word 'and'
 *
 * @param {array}       collection      Array of items to be 'imploded' and joined with an 'and'.
 * @return {string}                     Returns a string such as 'item1, item2 and item3'.
 */
/* export const imp_and = function (collection) {
  const and = this.translateText('and');
  if (and) return impAndUtil(collection, and);
  return impAndUtil(collection);
}; */

/**
 * imp_or: Implode and join with word 'or'
 *
 * @param {array}       collection      Array of items to be 'imploded' and joined with an 'or'.
 * @return {string}                     Returns a string such as 'item1, item2 or item3'.
 */
/* export const imp_or = function (collection) {
  const or = this.translateText('or');
  if (or) return impOrUtil(collection, or);
  return impOrUtil(collection);
}; */

/**
 * num2str: Converts a number to string using the 'numberText' array.
 *
 * @param {number}      num             Number to be transformed, e.g. '5'.
 * @return {string}                     Returns a string e.g. 'five'.
 */
export const num2str = function (num) {
  const { numberText } = Contract.getFormat(this.contract);
  if (!numberText || !numberText[this.language] || !numberText[this.language][num]) return num;
  return numberText[this.language][num];
};

/**
 * num2strnum: Converts a number to string and append the number using the 'numberText' array.
 *
 * @param {number}      num             Number to be transformed, e.g. '5'.
 * @return {string}                     Returns a string e.g. 'five (5)'.
 */
export const num2strnum = function (num) {
  const { numberText } = Contract.getFormat(this.contract);
  if (!numberText || !numberText[this.language] || !numberText[this.language][num]) return num;
  return numberText[this.language][num] + ' (' + num + ')';
};

/**
 * getRepeatableByUid: Find the block item for a specific uid. Regarding uids, see system state.
 *
 * @param {string}      uid             Unique uid for a block.
 * @return {object}                     Returns the object containing the item info.
 */
export const getRepeatableByUid = function (uid) {
  if (!this.repeatables || !this.repeatables.data) return false;
  for (const repeatable in this.repeatables.data) {
    if (!this.repeatables.data[repeatable].data) continue;
    for (const repeatableItem of this.repeatables.data[repeatable].data) {
      if (repeatableItem._uid === uid) return repeatableItem;
    }
  }
  return false;
};
