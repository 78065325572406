import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEditor } from 'slate-react';
import { useContract, useStudioBaseRoute } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { setDraft } from 'appRedux/actions';
import { Switch, Tooltip } from 'antd';
import { Contract } from 'core/interfaces';
import { firstPath } from 'utils/draftPath';
import IntlMessages from 'util/IntlMessages';

const ModeSwitcher = ({ newSidebar }) => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const baseRoute = useStudioBaseRoute();
  const editor = useEditor();
  const contract = useContract();
  const dispatch = useDispatch();
  const action = useSelector(({ draft }) => draft.draft_mode);
  const [switchLoading, setSwitchLoading] = useState(false);

  const routes = Contract.getUiRoutes(contract);

  const isEditing = params.action === 'contract';

  // Ensure that the state is align with the route path.
  useEffect(() => {
    if (params.action !== action) {
      dispatch(setDraft('draft_mode', params.action));
      setSwitchLoading(false);
    }
  }, [params.action, action, dispatch]);
  const inputLocation = useRef(!isEditing ? location.pathname : firstPath(routes));
  inputLocation.current = isEditing ? inputLocation.current : location.pathname;

  const onSwitchMainMode = useCallback(() => {
    setSwitchLoading(true);
    setTimeout(() => {
      if (isEditing) {
        // Go to input mode
        const newAction = 'input';
        editor.meta.interactive = false;
        editor.meta.preview = true;
        editor.meta.action = newAction;
        const editorElem = document.getElementById('editor-holder');
        if (editorElem) {
          editorElem.classList.remove('template-edit');
          // editorElem.classList.remove("show-current-fields");
          editorElem.classList.remove('show-inactive');
        }
        history.replace(inputLocation.current);
      } else {
        // Go to contract mode
        const newAction = 'contract';
        editor.meta.action = newAction;
        editor.meta.interactive = true;
        editor.meta.preview = false;
        history.replace(baseRoute + '/contract/view');
      }
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, history]);

  if (newSidebar) {
    return (
      <div className="flex-column align-items-center justify-content-center mt-1 mb-3">
        <small className="text-white fs-xs">Draft Mode</small>
        <Tooltip title={<IntlMessages id="studio.toggleModes" />} placement="right">
          <div className="flex-row w-100 justify-content-center align-items-center">
            <Switch
              loading={switchLoading}
              onChange={onSwitchMainMode}
              checked={isEditing}
              className="draft-mode-switch"
            />
          </div>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className="flex-column align-items-center justify-content-center border-bottom pb-3 mb-0">
      <Tooltip title={<IntlMessages id="studio.toggleModes" />}>
        <div className="flex-row w-100 justify-content-center align-items-center">
          <div className="mode-option-left">
            <small
              className={'mini-sidebar-hide draft-mode-switcher ' + (!isEditing ? 'active' : 'inactive')}
            >
              <IntlMessages id="studio.modeInput" />
            </small>
          </div>
          <Switch
            loading={switchLoading}
            onChange={onSwitchMainMode}
            checked={isEditing}
            className="draft-mode-switch"
          />
          <div className="mode-option-right clickable">
            <small className={'mini-sidebar-hide draft-mode-switcher ' + (isEditing ? 'active' : 'inactive')}>
              <IntlMessages id="studio.modeContract" />
            </small>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default ModeSwitcher;
