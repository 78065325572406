import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, Card, Popconfirm } from 'antd';
import { setRealPersons } from 'appRedux/actions';
import api from 'utils/api';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import EditRealPerson from 'components/RealPerson/EditRealPerson';

const { Column, ColumnGroup } = Table;

export default memo(({ customer, person }) => {
  const customerId = customer.id;
  const dispatch = useDispatch();
  const realPersons = useSelector((state) => state.realPersons);
  const [editingRealPerson, setEditingRealPerson] = useState(null);
  const [addingNewPerson, setAddingNewPerson] = useState(false);
  const [childPersons, setChildPersons] = useState([]);
  const formatMessage = useIntlMessage();

  useEffect(() => {
    async function fetchData() {
      const realPersonsResponse = await api.get(`/customers/${customerId}/realpersons`);
      // Only set persons that are related directly to the current legal person
      const currentPersons = realPersonsResponse.data
        .filter((rp) => {
          const ids = rp.LegalPersons.map((lp) => lp.id);
          if (ids.includes(person.id)) return true;
        })
        .map((p) => {
          const newObj = { ...p };
          const lp = newObj.LegalPersons.find((lp) => lp.id === person.id);
          newObj.PersonRelation = lp.PersonRelation;
          delete newObj.LegalPersons;
          return newObj;
        });

      const otherPersons = realPersonsResponse.data.filter(
        (rp) => !currentPersons.find((cp) => cp.id === rp.id)
      );

      dispatch(setRealPersons(currentPersons));
      setChildPersons(otherPersons);
    }

    fetchData();
  }, [person.id, customerId, dispatch]);

  const editChildPerson = (id, values) => {
    const updatingIndex = childPersons.findIndex((data) => id === data.id);
    const newArray = [...childPersons]; //making a new array
    Object.assign(newArray[updatingIndex], values);

    setChildPersons(newArray);
  };

  const onRemoveChildPerson = (id) => {
    setChildPersons(childPersons.filter((p) => p.id !== id));
  };

  const disconnectPerson = (id) => {
    api
      .delete(`/realpersons/${id}/disconnectlegalperson?legalPersonId=${person.id}`)
      .then((resRelations) => {
        // Once disconnected server-side, remove from real person list and add to children
        dispatch(setRealPersons(realPersons.filter((rp) => rp.id !== id)));
        setChildPersons([...childPersons, realPersons.find((rp) => rp.id === id)]);
      });
  };

  const config = {
    bordered: false,
    loading: !realPersons,
    pagination: { position: 'bottom' },
    size: 'default',
    expandedRowRender: false,
    showHeader: true,
    footer: false,
    scroll: undefined,
  };

  const renderTable = (dataSource, title, disconnectButton, hideCompanyColumn, noDataText, extra) => {
    // config.title = () => <h3>{title}</h3>;
    return (
      <Card title={title} extra={extra} className="no-body-padding">
        <Table
          rowKey="id"
          className="table-responsive documents-table"
          {...config}
          dataSource={dataSource}
          locale={{ emptyText: noDataText || 'No data' }}
        >
          <ColumnGroup title={null}>
            <Column
              title={<IntlMessages id="app.general.FirstName" />}
              dataIndex="firstName"
              key="firstName"
              sorter={(a, b) => a.firstName.localeCompare(b.firstName)}
            />
            <Column
              title={<IntlMessages id="app.general.LastName" />}
              dataIndex="lastName"
              key="lastName"
              sorter={(a, b) => a.lastName.localeCompare(b.lastName)}
            />
            {!hideCompanyColumn && (
              <Column
                title={<IntlMessages id="app.general.Company" />}
                dataIndex="LegalPersons"
                key="LegalPersons"
                render={(value) => (Array.isArray(value) ? value.map((c) => c.name).join(', ') : null)}
              />
            )}
            <Column
              title={<IntlMessages id="app.general.Email" />}
              dataIndex="emails"
              key="emails"
              render={(value) => (Array.isArray(value) ? value.join(', ') : value)}
            />
            <Column
              title={<IntlMessages id="app.general.Action" />}
              key="actions"
              render={(text, record) => (
                <>
                  <Button onClick={() => setEditingRealPerson(record.id)}>
                    <IntlMessages id="app.persons.editPerson" />
                  </Button>
                  {disconnectButton && (
                    <Popconfirm
                      placement="top"
                      title={<IntlMessages id="app.persons.disconnectConfirm" />}
                      onConfirm={() => {
                        disconnectPerson(record.id);
                      }}
                      okText={<IntlMessages id="desc.Yes" />}
                      cancelText={<IntlMessages id="desc.No" />}
                    >
                      <Button type="danger">
                        <IntlMessages id="app.persons.disconnect" />
                      </Button>
                    </Popconfirm>
                  )}
                </>
              )}
            />
          </ColumnGroup>
        </Table>
      </Card>
    );
  };

  return (
    <>
      {renderTable(
        realPersons,
        `${formatMessage('app.persons.personsInCurrent')} ${person.name}`,
        true,
        true,
        formatMessage('app.persons.noRealPersonsAssociated'),
        <Button type="primary" className="m-0" onClick={() => setAddingNewPerson(true)}>
          <IntlMessages id="app.persons.addPerson" />
        </Button>
      )}
      {renderTable(
        childPersons,
        formatMessage('app.persons.personsInChildren'),
        false,
        false,
        formatMessage('app.persons.noRealPersonsAssociated')
      )}
      {editingRealPerson && (
        <EditRealPerson
          id={editingRealPerson}
          childPersons={childPersons}
          onCancel={() => setEditingRealPerson(null)}
          onEditChildPerson={editChildPerson}
          legalPerson={person}
          saveRelation={true}
        />
      )}
      {addingNewPerson && (
        <EditRealPerson
          onCancel={() => setAddingNewPerson(false)}
          legalPerson={person}
          childPersons={childPersons}
          onRemoveChildPerson={onRemoveChildPerson}
          saveRelation={true}
        />
      )}
    </>
  );
  /* return (
    <Card
      title={
        <div>
          <div style={{ display: 'inline-block' }} className="float-right">
            <Button type="primary" onClick={() => setAddingNewPerson(true)}><IntlMessages id="app.persons.addPerson" /></Button>
          </div>
        </div>
      }
      className="project-documents full-table-view white-th"
    >
      {renderTable(realPersons, `${formatMessage('app.persons.personsInCurrent')} ${person.name}`, true, true)}
      {renderTable(childPersons, formatMessage('app.persons.personsInChildren'), false, false)}
      {editingRealPerson && (
        <EditRealPerson id={editingRealPerson} childPersons={childPersons} onCancel={() => setEditingRealPerson(null)} onEditChildPerson={editChildPerson} legalPerson={person} saveRelation={true} />
      )}
      {addingNewPerson && (
        <EditRealPerson onCancel={() => setAddingNewPerson(false)} legalPerson={person} childPersons={childPersons} onRemoveChildPerson={onRemoveChildPerson} saveRelation={true} />
      )}
    </Card>
  ); */
});
