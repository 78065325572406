import React, { useCallback } from 'react';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addRepeatable } from 'appRedux/actions';
import { useContract } from 'hooks';
import { Contract, Entity } from 'core/interfaces';
import { combineNumber, splitNumber, ocount, omap } from 'core/utils/general';
import IntlMessages from 'util/IntlMessages';

export default function AddIntraGroupLoans(props) {
  const contract = useContract();
  const language = Contract.getLanguage(contract);
  const state = useSelector((state) => state);
  const { borrowers, properties } = state.input;
  const hasALA = useSelector(
    (state) => state.input.property_settings && state.input.property_settings['property_settings/ala']
  );
  const dispatch = useDispatch();

  const addLoans = useCallback(
    (creditorEntity) => {
      const values = {};
      for (const uid in properties) {
        const propertyItem = properties[uid];
        if (!propertyItem.propertyOwner || !propertyItem.propertyOwner.id) continue;
        let amount = 0;
        if (hasALA) {
          amount += parseInt(combineNumber(propertyItem['property/ALA'], language)) || 0;
        } else {
          amount += parseInt(combineNumber(propertyItem['property/existingMN'], language)) || 0;
          amount += parseInt(combineNumber(propertyItem['property/newMN'], language)) || 0;
        }
        if (values[propertyItem.propertyOwner.id]) values[propertyItem.propertyOwner.id] += amount;
        else values[propertyItem.propertyOwner.id] = amount;
      }
      console.log('Values are ', { values });
      for (const debtorId in values) {
        const debtorEntity = Entity.getById(state, debtorId);
        dispatch(
          addRepeatable('input.intragrouploan', {
            'intragrouploan/intragrouploanDebtor': debtorEntity,
            'intragrouploan/intragrouploanCreditor': creditorEntity,
            'intragrouploan/amount': splitNumber(values[debtorId], language),
          })
        );
      }
    },
    [properties, hasALA, language, dispatch]
  );

  const hasBorrowers = borrowers && ocount(borrowers) > 0;
  const hasProperties = properties && ocount(properties) > 0;
  if (!hasBorrowers && !hasProperties) {
    return (
      <Tooltip title={<IntlMessages id="studio.inputs.addBorrowersAndPropertiesFirst" />}>
        <Button disabled className="mr-2">
          <i className="mdi mdi-database-plus" />
        </Button>
      </Tooltip>
    );
  }
  if (!hasBorrowers) {
    return (
      <Tooltip title={<IntlMessages id="studio.inputs.addBorrowersFirst" />}>
        <Button disabled className="mr-2">
          <i className="mdi mdi-database-plus" />
        </Button>
      </Tooltip>
    );
  }
  if (!hasProperties) {
    return (
      <Tooltip title={<IntlMessages id="studio.inputs.addPropertiesFirst" />}>
        <Button disabled className="mr-2">
          <i className="mdi mdi-database-plus" />
        </Button>
      </Tooltip>
    );
  }

  const menu = (
    <Menu>
      <Menu.ItemGroup
        key="g1"
        title={
          <strong>
            <IntlMessages id="studio.inputs.addIGLselectCreditor" />
          </strong>
        }
      >
        {omap(borrowers, (borrower) => (
          <Menu.Item key={'borrower' + borrower._uid}>
            <span
              onClick={() => {
                addLoans(borrower.borrowerEntity);
              }}
            >
              {(borrower && borrower.borrowerEntity && borrower.borrowerEntity.name) || 'No name'}
            </span>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Tooltip title={<IntlMessages id="studio.inputs.addIGLby" />}>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Button>
          <i className="mdi mdi-database-plus" />
        </Button>
      </Dropdown>
    </Tooltip>
  );
}
