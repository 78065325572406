import applyJsonLogic from '../../../utils/logic/applyLogic';
import { Contract } from '../../../interfaces';

export const applyLogic = function (rule, data) {
  const draftInfo = this.getDraftInfo();
  const newData = {
    shortcuts: (draftInfo && draftInfo.shortcutStates) || {},
    ...(data || {}),
  };

  return applyJsonLogic(rule, this.states.current, {
    _meta: {
      create: { setup: Contract.getSetup(this.contract) },
      contract: this.contract,
    },
    data: newData,
    debug: false,
  });
};
