import React from "react";
import {Card} from "antd"

const StatusBox = ({icon, title, subTitle, subSubTitle, color, colorTitle, colorSubTitle}) => {
  return (
    <Card className={`card-full py-4 px-2 bg-${color}`}>
      <div className="flex-row justify-content-center mb-3 mb-sm-4">
          <span
            className={`size-80 border border-${colorTitle} text-${colorTitle} flex-row justify-content-center align-items-center rounded-circle`}>
            <i className={`icon icon-${icon} fs-xlxl`}/></span>
      </div>
      <div className="text-center">
        <h2 className={`fs-xxxl font-weight-medium text-${colorTitle}`}>{title}</h2>
        <p className={`mb-0 mb-sm-0 text-${colorSubTitle}`}>{subTitle}</p>
        {
          subSubTitle && <small className={`mb-0 mt-0 pt-0 mb-sm-3 text-${colorSubTitle}`}>{subSubTitle}</small>
        }
      </div>
    </Card>
  );
};

export default StatusBox;
