import contractDefaults from '../config/contractDefaults';
import { Contract } from './Contract';

const DocumentTemplate = {};
// Main
DocumentTemplate.getInfo = (template) => (template && template.info) || {};
DocumentTemplate.getName = (template) => template && template.name;
DocumentTemplate.getDescription = (template) => template && template.description;
DocumentTemplate.getData = (template) => template.data || {};

// Main data
DocumentTemplate.getContent = (template) => DocumentTemplate.getData(template).content || [];
DocumentTemplate.getCreate = (template) => DocumentTemplate.getData(template).create || {};
DocumentTemplate.getInputs = (template) => DocumentTemplate.getData(template).input || {};
DocumentTemplate.getOutline = (template) => DocumentTemplate.getData(template).outline || {};
DocumentTemplate.getSettings = (template) => DocumentTemplate.getData(template).settings || {};
DocumentTemplate.setSetting = (template, key, value) => {
  if (!template.data.settings) template.data.settings = {};
  template.data.settings[key] = value;
};
DocumentTemplate.getUi = (template) => DocumentTemplate.getData(template).ui || {};

// Outline Data
DocumentTemplate.getType = (template) => DocumentTemplate.getOutline(template).type;
DocumentTemplate.getIdName = (template) => DocumentTemplate.getOutline(template).id_name;
DocumentTemplate.getLabels = (template) => DocumentTemplate.getOutline(template).labels;
DocumentTemplate.getShortName = (template) => DocumentTemplate.getOutline(template).shortName;

// Settings data
DocumentTemplate.getFormat = (template) => DocumentTemplate.getSettings(template).format || {};
DocumentTemplate.setFormat = (template, key, value) => {
  if (!template.data.settings) template.data.settings = {};
  if (!template.data.settings.format) template.data.settings.format = {};
  template.data.settings.format[key] = value;
};
DocumentTemplate.getLanguage = (template) =>
  DocumentTemplate.getSettings(template).language || DocumentTemplate.getDefaultLanguage(template);
DocumentTemplate.getDefaultLanguage = (template) =>
  DocumentTemplate.getSettings(template).defaultLanguage || contractDefaults.language;

// Create data
DocumentTemplate.getBuild = (template) => DocumentTemplate.getCreate(template).build || {};
DocumentTemplate.getOldModules = (template) => DocumentTemplate.getCreate(template).modules || {};
DocumentTemplate.getModules = (template) => DocumentTemplate.getCreate(template).contractModules || {};

DocumentTemplate.getSetup = (template) => DocumentTemplate.getCreate(template).setup || {};
// Build data
DocumentTemplate.getConcepts = (template) => DocumentTemplate.getBuild(template).concepts || [];
DocumentTemplate.getConcept = (template, conceptId) => {
  const concepts = DocumentTemplate.getConcepts(template);
  if (concepts.length === 0) return null;
  return concepts.find((item) => item.id === conceptId) || null;
};
DocumentTemplate.getConceptGrammar = (template) => DocumentTemplate.getBuild(template).conceptGrammar || {};
DocumentTemplate.getConceptVariations = (template) =>
  DocumentTemplate.getBuild(template).conceptVariations || {};
DocumentTemplate.getMapRepeatablesByConcepts = (template) =>
  DocumentTemplate.getBuild(template).mapRepeatablesByConcepts || [];
DocumentTemplate.getRepeatableActivation = (template) =>
  DocumentTemplate.getBuild(template).repeatableActivation || [];
DocumentTemplate.getRepeatablesNumberBindings = (template) =>
  DocumentTemplate.getBuild(template).repeatablesNumberBindings || {};
DocumentTemplate.getInputBindings = (template) =>
  DocumentTemplate.getBuild(template).inputBindings || [];
DocumentTemplate.getSmartDefinitions = (template) =>
  DocumentTemplate.getBuild(template).smartDefinitions || [];
DocumentTemplate.getSmartReplacements = (template) =>
  DocumentTemplate.getBuild(template).smartReplacements || {};

DocumentTemplate.getAnalysisInstructions = (template) =>
  DocumentTemplate.getBuild(template).analysis || {};
  
DocumentTemplate.getAnalysisModules = (template) =>
  DocumentTemplate.getModules(template).analysis || [];
  
/* Actions on document template */
// Set content
DocumentTemplate.setContent = (template, value) => (template.data.content = value);
// Generate a document from document template. Also takes setupValues and the relevant project object
DocumentTemplate.generateEmptyDocument = (
  template,
  setupValues = {},
  project = { id: '0', projectTemplateId: '0' },
  customer
) => ({
  name: setupValues._doc_name || template.name || 'Untitled',
  description: setupValues._doc_description || template.description || 'No description',
  projectTemplateId: project.projectTemplateId || '0',
  documentTemplateId: template.id,
  data: {
    setup: setupValues,
  },
  info: {
    ...(template.info || {}),
    language: DocumentTemplate.getLanguage(template),
    entities: !customer
      ? {}
      : {
          customerId: customer.id,
        },
    stats: {}, // Currently not in use
  },
  isSecret: typeof setupValues._doc_isSecret === 'boolean' ? setupValues._doc_isSecret : false,
  projectId: project.id || '0',
  customerId: (customer && customer.id) || null,
});

// Used to create a `contract` of a template,
// e.g. for input phase (input->draft contract)
function getStandardContractDraftInfo(values = {}) {
  return {
    data: {},
    status: {
      editorInitialized: false,
      inherited: false,
      initialized: false,
      loaded: false,
    },
    importContractId: null,
    smartReplacementIndices: {},
    smartReplacements: {},
    shortcutStates: {},
    variables: {},
    _firstTimeDraft: 0,
    ...values,
  };
}
DocumentTemplate.toContract = (template, version, language = contractDefaults.language, setup = {}) => {
  const { input, ui, ...templateData } = template.data;
  return {
    ...version,
    data: {
      ...templateData,
      ui: {
        ...ui,
        routes: moderniseRoutes(makeDraftBaseRoute(version.id), ui.routes),
      },
      _live: Contract._standardLive(),
      _state: {},
      _draftInfo: getStandardContractDraftInfo({ setup, language }),
    },
  };
};

DocumentTemplate.emptyVersionFromDocument = (document, newValues = {}) => {
  return {
    ...document,
    ...newValues,
    // id: '',
    info: {
      ...(document.info || {}),
      versionNumber: 1,
      comment: 'Draft',
    },
    documentId: document.id,
    seals: {},
    data: {},
  };
};

DocumentTemplate.ensureRoutes = (template) => {
  const { ui, ...templateData } = template.data;
  return {
    ...template,
    data: {
      ...templateData,
      ui: {
        ...ui,
        routes: moderniseRoutes(makeTemplateBaseRoute(template.id), ui.routes),
      },
    },
  };
};

function makeDraftBaseRoute(versionId) {
  return '/studio/draft/' + versionId;
}
function makeTemplateBaseRoute(versionId) {
  return '/studio/template/' + versionId;
}
function moderniseRoutes(baseRoute, routes) {
  if (!routes) return [];
  return routes.map((route) => ({
    ...route,
    fullPath: baseRoute + '/' + route.paths.join('/'),
    children: moderniseRoutes(baseRoute, route.children),
  }));
}

DocumentTemplate.toTemplateContract = (template, language = contractDefaults.language) => {
  const { input, ui, ...templateData } = template.data;
  return {
    contract: {
      data: { ...templateData, _live: Contract._standardLive() },
      versionInherited: {
        ...template,
        data: null,
      },
    },
  };
};

export { DocumentTemplate };
