
import { ReactEditor } from "slate-react"

export function safePath(editor, element) {
  let path;
  try {
    path = ReactEditor.findPath(editor, element);
  } catch (err) {
    return console.log("Cannot find path for ", { element });
  }
  if (!path) return console.log("Cannot find path for element (2) ", { element });
  return path;
}