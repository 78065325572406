import manager from 'core/engine/manager';
import api from './api';
import { Editor, Node, Path, Range, Text, Transforms, Span, Point } from 'slate';
import jsonLogic from 'json-logic-js';
import { Contract, Concept, InputPaths, State } from 'core/interfaces';

export default function SetDevGlobals() {
  window.manager = manager;
  window.engine = manager && manager.drafts && manager.drafts[0] && manager.drafts[0].instance;
  window.con = manager && manager.drafts && manager.drafts[0];
  window.api = api;
  window.apig = (path) => api.get(path).then((res) => console.log(res.data));

  // Slate
  window.Transforms = Transforms;
  window.Editor = Editor;
  window.no = Node;
  window.Path = Path;
  window.Point = Point;
  window.Span = Span;
  window.ran = Range;
  window.tex = Text;

  window.jsonLogic = jsonLogic;

  window.Contract = Contract;
  window.Concept = Concept;
  window.InputPaths = InputPaths;
  window.State = State;
}
window.globb = SetDevGlobals