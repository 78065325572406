import React from "react";
import { useDispatch } from "react-redux";
import { addRepeatable } from "appRedux/actions";
import { useSelected } from "slate-react";
import { Tooltip } from "antd";

export function AddRepeatableButton({ element }) {
  const selected = useSelected();
  const dispatch = useDispatch();
  if (!selected) return null;
  const {
    each_repeatable: { repeatable },
  } = element.data;

  const newRepeatable = () => dispatch(addRepeatable("input."+repeatable));

  return (
    <Tooltip
      title={
        <>
          <span>Add</span> <strong>{repeatable}</strong> <span>entry</span>
        </>
      }
    >
      <div className="editor-each-repeatable-btn add" contentEditable={false} onMouseDown={newRepeatable}>
        <i className="mdi mdi-plus-circle-multiple-outline" />
      </div>
    </Tooltip>
  );
}
