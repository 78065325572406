import createSagaMiddleware from "redux-saga";
import { createLogger } from 'redux-logger';
import {applyMiddleware, compose, createStore} from 'redux'
import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers'

import { inputModule } from "../inputModule/"

const createBrowserHistory = require('history').createBrowserHistory;


export const history = createBrowserHistory();
// const routeMiddleware = routerMiddleware(history);

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();

const middlewares = [thunk,sagaMiddleware, logger, inputModule];

let sagaRuns = 0

let store

export default function configureStore(preloadedState) {
  const composeEnhancers = (process.env.REACT_APP_NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        ...middlewares
      ),
    ),
  );

  if(sagaRuns === 0)
    sagaMiddleware.run(rootSaga);
  sagaRuns++
  return store;
}

export function getStore() { return store }