export const FILE_UPLOAD_ALLOWED_TYPES = [
  'image/*',
  '.pdf',
  '.doc',
  '.doc',
  '.docx',
  '.xml',
  '.xls',
  '.xlsx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const PERSON_RELATION_CAPACITIES = ['hr', 'legal', 'signatory'];

export const VALUE_INPUTS = [
  'select',
  'radio',
  'text',
  'numeric',
  'numeric-steps',
  'checkbox',
  'date',
  'party',
];

export const LIST_STYLE_TYPES = ['decimal', 'lower-alpha', 'upper-alpha', 'lower-roman', 'upper-roman'];

export const supportedCurrencies = ['SEK', 'NOK', 'EUR', 'USD', 'DKK', 'GBP'];
export const supportedCurrenciesColors = {
  SEK: 'gold',
  NOK: 'red',
  USD: 'green',
  EUR: 'blue',
  GBP: 'purple',
  DKK: 'magenta',
};
