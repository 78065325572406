import React, { useState, useMemo, useCallback, useEffect, memo } from 'react';
import { Slate, Editable, withReact, useEditor, useReadOnly, ReactEditor } from 'slate-react';
import { Transforms, Path, Editor, Node, createEditor, Range } from 'slate';
import { Button, Checkbox, Tooltip, message } from 'antd';
import { DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import CustomScrollbars from 'util/CustomScrollbars';
import IntlMessages from '../../../util/IntlMessages';

const defaultNoteItem = {
  type: 'notes_list_item',
  data: {
    item_id: 'abc123-note',
  },
  children: [
    {
      text: '',
    },
  ],
};
export const defaultNotesValue = [defaultNoteItem];

const defaultCommentItem = {
  type: 'notes_list_item',
  data: {
    item_id: 'abc123-note',
  },
  children: [
    {
      text: '',
    },
  ],
};
export const defaultCommentsValue = [defaultCommentItem];

function withChecklist(editor) {
  const { apply } = editor;
  editor.apply = (operation) => {
    if (operation.type === 'split_node' && operation.properties?.type === 'notes_list_item')
      operation.properties.checked = false;

    apply(operation);
  };
  return editor;
}

export default memo(
  ({
    contract,
    type = 'notes',
    content,
    onChange: parentOnChange,
    addGeneralComment,
    editors,
    setEditors,
    close,
  }) => {
    const initValue = content || defaultNotesValue;
    const [value, setValue] = useState(initValue);

    const editor = useMemo(() => withChecklist(withReact(createEditor())), []);
    editor.tmp = {
      type,
    };
    if (type === 'notes') {
      editor.tmp.addGeneralComment = addGeneralComment;
    }
    // window.ce = editor;

    useEffect(() => {
      if (setEditors) editors.current[type] = editor;
      return () => (editors.current[type] = null);
    }, [editors, type, editor, setEditors]);

    useEffect(() => {
      setTimeout(() => {
        ReactEditor.focus(editor);

        if (editor.children.length === 1 && Node.string(editor.children[0]) === '') {
          // Completely empty.
          Transforms.select(editor, [0]);
        } else {
          const firstEnd = Editor.end(editor, []);
          if (!firstEnd) return;
          if (firstEnd.offset === 0) {
            Transforms.select(editor, firstEnd);
            return;
          }
          const nextPath = Path.next([firstEnd.path[0]]);
          const newNode = JSON.parse(JSON.stringify(type === 'notes' ? defaultNoteItem : defaultCommentItem));
          newNode.checked = false;
          Transforms.insertNodes(editor, newNode, { at: nextPath });
          setTimeout(() => {
            Transforms.select(editor, nextPath);
          }, 20);
        }
      }, 30);
    }, [editor, type]);

    const onChange = useCallback(
      (changeValue) => {
        if (changeValue === value) return;
        setValue(changeValue);
        if (typeof parentOnChange === 'function') parentOnChange(changeValue);
      },
      [value, parentOnChange]
    );
    const renderElement = useCallback((props) => <Element {...props} />, []);

    const resetValues = () => {
      Transforms.forceDeselect(editor);
      onChange(JSON.parse(JSON.stringify(type === 'notes' ? defaultNotesValue : defaultCommentsValue)));
      setTimeout(() => {
        ReactEditor.focus(editor);
        Transforms.select(editor, [0, 0]);
      }, 100);
    };

    return (
      <>
        <div className="notes-header">
          <h5 className="">
            <i className="mdi mdi-cursor-move notes-dragger"></i>
            <span className="notes-header-title">
              <IntlMessages id={'app.external.' + type + '.label'} cap />
            </span>
          </h5>
          <div>
            <Tooltip title={<IntlMessages id="desc.clearAll" />}>
              <Button
                shape="circle"
                icon={<DeleteOutlined className="" />}
                size="small"
                className="m-0 mr-2"
                onClick={resetValues}
              ></Button>
            </Tooltip>
            <Tooltip title={<IntlMessages id="desc.Close" />}>
              <Button
                shape="circle"
                icon={<CloseOutlined className="" />}
                size="small"
                className="m-0"
                onClick={close}
              ></Button>
            </Tooltip>
          </div>
        </div>
        <div className="notes-body">
          <Slate editor={editor} value={value} onChange={onChange}>
            <div className="external-comments-notes-editor">
              <CustomScrollbars>
                <Editable
                  renderElement={renderElement}
                  id="user-editor-holder"
                  className={'user-editor ' + type}
                  autoFocus={true}
                  placeholder={<IntlMessages id={'app.external.' + type + '.placeholder'} />}
                  spellCheck={false}
                />
              </CustomScrollbars>
            </div>
          </Slate>
        </div>
      </>
    );
  }
);

const Element = (props) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'notes_list_item':
      return <CheckListItemElement {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const CheckListItemElement = ({ attributes, children, element }) => {
  const editor = useEditor();
  const readOnly = useReadOnly();
  const { checked } = element;
  let pathRef;
  try {
    pathRef = Editor.pathRef(editor, ReactEditor.findPath(editor, element));
  } catch (err) {}

  const removeItem = (evt) => {
    const path = pathRef.current;
    if (!path) return console.log('No path for element');

    evt.preventDefault();
    evt.stopPropagation();

    Transforms.removeNodes(editor, { at: path });
    return;
  };

  const addToGeneral = () => {
    editor.tmp.addGeneralComment(JSON.parse(JSON.stringify(element)));
    Transforms.setNodes(editor, { checked: true }, { at: pathRef.current });
    message.info('Added as general comment');
  };

  const isFirst = pathRef.current && pathRef.current.length === 1 && pathRef.current[0] === 0;
  const { type } = editor.tmp;

  return (
    <div
      {...attributes}
      className="user-comment-note-item"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <span
        contentEditable={false}
        className="user-editor-actions flex-row justify-content-end"
      >
        {!isFirst && (
          <Tooltip
            title={
              <>
                <IntlMessages id={'app.external.editor.removeThis'} cap />
                <IntlMessages id={'app.general.' + (type === 'notes' ? 'note' : 'comment')} />
              </>
            }
          >
            <i className="mdi mdi-close remove-item" onMouseDown={removeItem} />
          </Tooltip>
        )}
        {type === 'notes' && (
          <Tooltip title={<IntlMessages id={'app.external.editor.addToGeneralComments'} cap />}>
            <i className="mdi mdi-comment-account-outline remove-item" onMouseDown={addToGeneral} />
          </Tooltip>
        )}

        {editor.tmp && type === 'notes' && (
          <Checkbox
            checked={checked}
            onChange={(event) => {
              const path = ReactEditor.findPath(editor, element);
              const newProperties = {
                checked: event.target.checked,
              };
              Transforms.setNodes(editor, newProperties, { at: path });
            }}
          />
        )}
      </span>
      <span
        contentEditable={!readOnly}
        suppressContentEditableWarning
        style={{
          textDecoration: !checked ? 'none' : 'line-through',
        }}
      >
        {children}
      </span>
    </div>
  );
};
