

import nameFacilities from './inputs/nameFacilities';
import calcUpfront from './inputs/calcUpfront';
import financialCovenantSuggestions from './inputs/financalCovenantsSuggestion';

const inputs = [
    {
        trigger: 'facility/facility/type',
        function: nameFacilities,
        info: {
            name: 'Automatically name facilities depending on types',
            category: 'legal',
        }
    },
    {
        trigger: 'facility/pricing/upfront',
        function: calcUpfront,
        info: {
            name: 'Automatically name facilities depending on types',
            category: 'legal',
        }
    },
    {
        trigger: 'facility/facility/commitment',
        function: calcUpfront,
        info: {
            name: 'Automatically name facilities depending on types',
            category: 'legal',
        }
    },
    {
        trigger: 'facility/facility/currency',
        function: calcUpfront,
        info: {
            name: 'Automatically name facilities depending on types',
            category: 'legal',
        }
    },
    {
        trigger: 'measurements/interval',
        function: financialCovenantSuggestions,
        info: {
            name: 'Automatically make suggestions for financial covenants',
            category: ['legal', 'finance']
        }
    },
    {
        trigger: 'measurements/referencedates',
        function: financialCovenantSuggestions,
        info: {
            name: 'Automatically make suggestions for financial covenants',
            category: ['legal', 'finance']
        }
    }
];

export default inputs;
