const steps = [
  {
    selector: '#editor-holder',
    content: <div className="external-tour-item">The contract you are asked to review and/or approve</div>,
  },
  {
    selector: '.guide-ext-view-options',
    content: <div className="external-tour-item">Display the contract in standard mode or PDF mode on the page</div>,
  },
  {
    onlyIfSetting: ['allowFeedbackOnly'],
    selector: '.guide-ext-toggle-topbar',
    content: <div className="external-tour-item">Toogle between view mode and contract editing mode</div>,
  },
  /* {
    selector: '.guide-ext-start-tour',
    content: <div className="external-tour-item">This guide tour currently being active</div>,
  }, */
  {
    selector: '.guide-ext-actions-menu',
    content: <div className="external-tour-item">Menu with additional actions</div>,
  },
  {
    onlyIfSetting: ['allowGeneralComments'],
    selector: '.guide-ext-user-editor-comments',
    content: <div className="external-tour-item">Add a general comment</div>,
  },
  {
    onlyIfSetting: ['allowInlineComments'],
    selector: '.guide-comment-btn',
    content: <div className="external-tour-item">Add a comment on a specific part of the contract content</div>,
  },
  {
    selector: '.guide-ext-user-editor-notes',
    content: <div className="external-tour-item">Your personal review notes. Not shared with anyone.</div>,
  },
  {
    selector: '.guide-ext-reject-contract',
    content: <div className="external-tour-item">Reject this contract proposal</div>,
  },
  {
    onlyIfSetting: ['allowFeedbackOnly'],
    selector: '.guide-ext-feedback-contract',
    content: <div className="external-tour-item">Submit feedback</div>,
  },
  {
    selector: '.guide-ext-approve-contract',
    content: <div className="external-tour-item">Approve the contract</div>,
  },
  /* {
    selector: '.',
    content: <div className="external-tour-item"></div>,
  }, */
];
export default steps;
