import { removeOrdinaryCard, removeInput as removeInputAction } from "appRedux/actions";

export const removeInput = (contract, card, inputName, inputPath, removeCompletely) => {
  const inputIndex = card.inputs_order.indexOf(inputName);
  card.inputs_order.splice(inputIndex, 1);
  delete card.values[inputName];

  if (removeCompletely) {
    console.log('Removing input: ', inputName);
    delete contract.data.ui.inputs[inputName];
  }
  // Return action to remove from state
  return removeInputAction(inputPath);
}

export const removeCard = (contract, page, cardName, removeCompletely) => {
  const cardIndex = page.cards.indexOf(cardName);
  page.cards.splice(cardIndex, 1);

  // Remove from state
  const inputStatePath = `input.${cardName}`;

  if (removeCompletely) {
    contract.data.ui.cards[cardName].inputs_order.map((inputName) => {
      removeInput(contract, contract.data.ui.cards[cardName], inputName, `input.${cardName}.${inputName}`, removeCompletely);
    });

    delete contract.data.ui.cards[cardName];
  }

  return removeOrdinaryCard(inputStatePath);
}
