import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Select, Button, Checkbox } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

const { Option } = Select;

export default function InputTypeSelect({ onChildUpdate, currentInput, contractLanguauge, propertyName = 'content' }) {
  // Translate the content of the input data to an object with options
  let defaultOptions = {};
  if (currentInput && currentInput[propertyName]) {
    defaultOptions = Object.assign(
      {},
      ...currentInput[propertyName].filter((c) => !c.disabled).map((s) => {
        return {
          [s.id]: {
            label: s.label[contractLanguauge],
            value: s.values && s.values[contractLanguauge],
          }
        }
      }),
    );
  }
  const formatMessage = useIntlMessage();

  const [optionId, setOptionId] = useState('');
  const [optionLabel, setOptionLabel] = useState('');
  const [optionIdError, setOptionIdError] = useState(null);
  const [optionLabelError, setOptionLabelError] = useState(null);
  const [options, setOptions] = useState(defaultOptions);
  const [defaultValue, setDefaultValue] = useState(currentInput ? currentInput.value : '-1');
  const [hasCustomValue, setHasCustomValue] = useState(false);
  const [customValue, setCustomValue] = useState('');

  const handleOptionIdChange = (evt) => {
    const { value } = evt.target;
    setOptionId(value);
    setOptionIdError('');
  };

  const handleOptionLabelChange = (evt) => {
    const { value } = evt.target;
    setOptionLabel(value);
    setOptionLabelError('');
  };

  const handleCustomValueChange = (value) => {
    setCustomValue(value);
  };

  const addOption = () => {
    if (!optionId) return setOptionIdError('Option id must be specified.');
    if (!optionLabel) return setOptionIdError('Option label must be specified.');

    if (options[optionId])
      return setOptionIdError('Option with the same id already exists, choose another one.');

    const newOptions = { ...options, [optionId]: { label: optionLabel, value: customValue || null } };
    setOptions(newOptions);
    setOptionId('');
    setOptionLabel('');
    setCustomValue('');
    setHasCustomValue(false);
    updateParent(newOptions, defaultValue);
  };

  const updateParent = (options, value) => {
    onChildUpdate({ options, defaultValue: value === '-1' ? null : value });
  };

  const removeOption = (id) => {
    const newOptions = { ...options };
    delete newOptions[id];
    setOptions(newOptions);
    if (id === defaultValue) setDefaultValue(null); // If default value was removed
    updateParent(newOptions, null);
  };

  const onChangeDefaultValue = (value) => {
    setDefaultValue(value);
    updateParent(options, value); // We want null in parent if no default value is chosen
  };

  const rows = Object.entries(options).map(([id, data]) => ({ id, ...data }));

  const renderOptions = () => {
    const rowsLength = rows.length;
    return (
      <>
        {rowsLength > 0 && (
          <>
            <Row className="flex-row mt-4">
              <h5><IntlMessages id="studio.template.inputs.select.options.added" /></h5>
            </Row>
            <Row className={'flex-row mt-3 mb-2 pb-2'} style={{ borderBottom: '1px dashed #d9d9d9' }}>
              <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
                <strong><IntlMessages id="studio.template.inputs.select.options.id" /></strong>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
                <strong><IntlMessages id="studio.template.inputs.select.options.label" /></strong>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
                <strong><IntlMessages id="studio.template.inputs.select.options.value" /></strong>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
                <strong><IntlMessages id="studio.template.inputs.select.options.actions" /></strong>
              </Col>
            </Row>
          </>
        )}
        {rows.map((row, index) => (
          <Row
            key={row.id}
            className={'flex-row mb-2 pb-2 ' + (index + 1 !== rowsLength ? 'border-bottom' : '')}
          >
            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
              {row.id}
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
              {row.label}
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
              {row.value || row.id}
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
              <Button type="secondary" className="m-0" onClick={() => removeOption(row.id)}>
                <IntlMessages id="studio.template.inputs.select.options.remove" />
              </Button>
            </Col>
          </Row>
        ))}
        {rowsLength > 0 && <div className="mb-4"> </div>}
      </>
    );
  };

  const renderDefaultOption = () => {
    return (
      <Form layout="vertical">
        <Form.Item
          label={<IntlMessages id="studio.template.inputs.select.option.defaultValue.label" />}
          extra={
            <div>
              <small><IntlMessages id="studio.template.inputs.select.option.defaultValue.info" /></small>
            </div>
          }
        >
          <Select onChange={onChangeDefaultValue} value={defaultValue} placeholder="">
            <Option value="-1" key="-1">
              <IntlMessages id="studio.template.inputs.select.option.noDefault" />
            </Option>
            {rows.map((row) => (
              <Option value={row.id} key={row.id}>
                {row.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    );
  };

  useEffect(() => {
    if (currentInput && Object.keys(defaultOptions).length > 0) {
      updateParent(options, defaultValue);
    }
  }, []);

  return (
    <>
      <Form.Item label={<IntlMessages id="studio.template.inputs.select.options" />}>
        <div className="additional-input-field-choices">
          <Form layout="vertical">
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.select.option.id.label" />}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.select.option.id.info" />
                  </small>
                  {optionIdError && <div className="text-warning">{optionIdError}</div>}
                </div>
              }
            >
              <Input
                type="text"
                value={optionId}
                onChange={handleOptionIdChange}
                placeholder={formatMessage('studio.template.inputs.select.option.id.placeholder')}
              />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.select.option.label.label" />}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.select.option.label.info" />
                  </small>
                  {optionLabelError && <div className="text-warning">{optionLabelError}</div>}
                </div>
              }
            >
              <Input
                type="text"
                value={optionLabel}
                onChange={handleOptionLabelChange}
                placeholder={formatMessage('studio.template.inputs.select.option.label.placeholder')}
              />
            </Form.Item>
            <Input.Group compact>
              <Form.Item
                label={<IntlMessages id="studio.template.inputs.select.option.hasCustomValue.label" />}
                style={{ width: '35%' }}
                extra={
                  <div>
                    <small>
                      <IntlMessages id="studio.template.inputs.select.option.hasCustomValue.info" />
                    </small>
                  </div>
                }
              >
                <Checkbox checked={hasCustomValue} onChange={() => setHasCustomValue(!hasCustomValue)} />
              </Form.Item>
              <Form.Item
                label={<IntlMessages id="studio.template.inputs.select.option.customValue.label" />}
                style={{ width: '55%' }}
                className="ml-2"
                extra={
                  <div>
                    <small>
                      <IntlMessages id="studio.template.inputs.select.option.customValue.info" />
                    </small>
                  </div>
                }
              >
                <Input
                  type="text"
                  value={customValue}
                  onChange={(evt) => handleCustomValueChange(evt.target.value)}
                  disabled={!hasCustomValue}
                  placeholder={formatMessage('studio.template.inputs.select.option.customValue.placeholder')}
                />
              </Form.Item>
            </Input.Group>
            <Button
              type="primary"
              block
              onClick={addOption}
              disabled={optionIdError || optionLabelError || optionId === '' || optionLabel === ''}
            >
              <IntlMessages id="studio.template.inputs.select.option.addButton" />
            </Button>
          </Form>
          {renderOptions()}
        </div>
      </Form.Item>

      {renderDefaultOption()}
    </>
  );
}
