import React, { useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { hideMessage, showAuthLoader, userSignIn } from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, user } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  }, [dispatch, showMessage]);

  useEffect(() => {
    if (!user || !user.email) return;
    form.setFieldsValue({
      email: user.email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, form]);

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
    /* e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(showAuthLoader());
        dispatch(
          userSignIn({
            email: values.email,
            password: values.password,
          })
        );
      }
    }); */
  };

  return (
    <>
      <div style={{ padding: "30px" }}>
        <Form onFinish={onFinish} form={form} className="signin-form form-row0">
          <FormItem>
            {user && (
              <div>
                {user.firstName} {user.lastName}
              </div>
            )}
            <div>
              <p>You need to re-authenticate</p>
              <p>Please provide your credentials</p>
            </div>
          </FormItem>
          <FormItem
            label="Email"
            name="email"
            style={{
              display: user && user.email ? "none" : "flex",
            }}
            rules={[{ required: true, type: "email", message: "The input is not valid E-mail!" }]}
          >
            {/* getFieldDecorator("email", {
              rules: [{ required: true, type: "email", message: "The input is not valid E-mail!" }],
            })(<Input placeholder="Email" />)} */}
            <Input placeholder="Email" />
          </FormItem>
          <FormItem name="password" label="Password" rules={[{ required: true, message: "Please input your Password!" }]}>
            {/* getFieldDecorator("password", {
              rules: [{ required: true, message: "Please input your Password!" }],
            })(<Input type="password" placeholder="Password" />) */}
            <Input type="password" placeholder="Password" />
          </FormItem>
          <FormItem>
            <Button type="primary" className="mb-0" htmlType="submit">
              <IntlMessages id="app.userAuth.signIn" />
            </Button>
          </FormItem>
        </Form>
      </div>

      {loader ? (
        <div className="loader-view">
          <CircularProgress />
        </div>
      ) : null}
      {showMessage ? message.error(alertMessage.toString()) : null}
    </>
  );
};

// const WrappedNormalLoginForm = Form.create()(SignIn);

export default SignIn;
