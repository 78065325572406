// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Common Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SET_NUMBER = 'COMMON_SET_NUMBER';
export const SET_NUMBERS = 'COMMON_SET_NUMBERS';

export const FIX_DRAFT = 'FIX_DRAFT'
export const RESET_DRAFT = 'RESET_DRAFT'
export const SET_DRAFT = 'SET_DRAFT'
export const SET_DRAFT_VALUES = 'SET_DRAFT_VALUES'
export const DRAFT_EDITOR_ALLOW_EDITING = 'DRAFT_EDITOR_ALLOW_EDITING'

//Auth const
export const AUTH_OVERLAY = 'AUTH_OVERLAY';

export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const SET_USER = 'SET_USER';
export const INIT_URL = 'INIT_URL';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';


// NODES CONSTANTS
export const UPDATE_NODE = 'NODE_UPDATE_NODE';
export const INSERT_NODE = 'NODE_INSERT_NODE';
export const REMOVE_NODE = 'NODE_REMOVE_NODE';
export const SET_NODES = 'NODE_SET_NODES';

// LEGALPERSONS CONSTANTS
export const UPDATE_LEGALPERSON = 'LEGALPERSON_UPDATE_LEGALPERSON';
export const INSERT_LEGALPERSON = 'LEGALPERSON_INSERT_LEGALPERSON';
export const REMOVE_LEGALPERSON = 'LEGALPERSON_REMOVE_LEGALPERSON';
export const SET_LEGALPERSONS = 'LEGALPERSON_SET_LEGALPERSONS';

// REALPERSONS CONSTANTS
export const UPDATE_REALPERSON = 'REALPERSON_UPDATE_REALPERSON';
export const INSERT_REALPERSON = 'REALPERSON_INSERT_REALPERSON';
export const REMOVE_REALPERSON = 'REALPERSON_REMOVE_REALPERSON';
export const SET_REALPERSONS = 'REALPERSON_SET_REALPERSONS';

// INPUT CONSTANTS
export const ADD_REPEATABLE = 'INPUT_ADD_REPEATABLE'
export const REMOVE_REPEATABLE = 'INPUT_REMOVE_REPEATABLE'
export const ADD_ORDINARY_CARD = 'INPUT_ADD_ORDINARY_CARD'
export const REMOVE_ORDINARY_CARD = 'INPUT_REMOVE_ORDINARY_CARD'
export const UPDATE_INPUT = 'INPUT_UPDATE_INPUT';
export const UPDATE_INPUTS = 'INPUT_UPDATE_INPUTS';
export const SET_CONNECT_INPUT_CARDS = 'INPUT_SET_CONNECT_INPUT_CARDS';
export const REMOVE_INPUT = 'INPUT_REMOVE_INPUT';

export const FIX_INPUT = 'INPUT_FIX_INPUT';
export const SET_INPUT = 'INPUT_SET_INPUT';
export const INSERT_NESTED_ITEM = 'INPUT_INSERT_NESTED_ITEM';
export const REMOVE_NESTED_ITEM = 'INPUT_REMOVE_NESTED_ITEM';
export const REMOVE_INPUT_REPEATABLE = 'REMOVE_INPUT_REPEATABLE';
export const MERGE_INPUTS = 'INPUT_MERGE_INPUTS';
export const NEW_CARD = 'INPUT_NEW_CARD';
export const SET_INPUT_DATA = 'INPUT_SET_INPUT_DATA';
export const SET_INPUT_ITEM_DATA = 'INPUT_SET_INPUT_ITEM_DATA';
export const ADD_MULTIPLEBLOCK_ITEM = 'INPUT_ADD_MULTIPLEBLOCK_ITEM'
export const REMOVE_MULTIPLEBLOCK_ITEM = 'INPUT_REMOVE_MULTIPLEBLOCK_ITEM'
export const ADD_QA = 'INPUT_ADD_QA'
export const REMOVE_QA = 'INPUT_REMOVE_QA'
export const UPDATE_QA = 'INPUT_UPDATE_QA'