
export const initActiveConditions = {
  id: "initActiveConditions",
  dependencies: { onGenesis: true },
  handler: function ({ state, isInitialized, api }) {
    // Run through all shortcuts and update the state.
    this.applyShortcutRules(null, state, { force: true });

    return (node, parents) => {
      if (!node.data || !node.data.acp) return;

      // By default, before first initialization, mark each 'acp' node as inActive
      if (!isInitialized) node.data._inActive = true;

      // localState differs from the `repeatable state` which is the state of the repeatable
      // originally being affected by the user action. However, as other repeatable items/nodes
      // may refer to data changed by the relevant user action, we need to allow such nodes
      // to conduct it's ACP check, but that needs to be done using the proper localState for
      // any such "foreign" repeatable item/node.
      const localState = api.utils.engine.getLocalState(node, parents, state, this.contract);

      const { changed, inActive } = this.checkNodeACPrule(
        node,
        state,
        localState
      );
      if (changed) {
        node.data._inActive = inActive;
        this.markNodeUpdate(node);
      }
    };
  },
};
