import uuid from 'uuid-random';
import { Contract } from '../../interfaces';
import { makeRepeatableId } from '../general';
import colors from '../../config/colors';

export const generateStateValues = (contract, defaultState, givenState) => {
  if (!contract || !defaultState) {
    throw new Error('generateStateValues requires a contract and defaultState');
  }
  if (!givenState) {
    if (!defaultState) {
      return { input: {}, legalPersons: [], realPersons: [] };
    }
    return defaultState;
  }

  const handledCards = [];

  const {
    input: defaultInput = {},
    legalPersons: defaultLegalPersons = [],
    realPersons: defaultRealPersons = [],
  } = defaultState;
  const {
    input: givenInput = {},
    legalPersons: givenLegalPersons = [],
    realPersons: givenRealPersons = [],
  } = givenState;

  const newInput = {};

  // First, handle input state starting from the defaultInput state
  // originating from the template
  for (const cardName in defaultInput) {
    const defaultCard = defaultInput[cardName];
    const givenCard = givenInput[cardName];
    if (!givenCard) {
      newInput[cardName] = defaultCard || {};
      continue;
    }
    handleCard(newInput, givenCard, defaultCard, cardName, contract);
    handledCards.push(cardName);
  }

  // If there are additional cards in the specified (user given) input state,
  // ensure to add these as well.
  for (const cardName in givenInput) {
    if (handledCards.includes(cardName)) continue;
    const givenCard = givenInput[cardName];
    if (!givenCard) {
      newInput[cardName] = {};
      continue;
    }
    handleCard(newInput, givenCard, null, cardName, contract);
  }
  const newState = {
    input: newInput,
    legalPersons: defaultLegalPersons,
    realPersons: defaultRealPersons,
  };
  return newState;
};

if (typeof window !== 'undefined') {
  window.generateStateValues = generateStateValues;
}

function handleCard(newInput, givenCard, defaultCard, cardName, contract) {
  const repeatableInfo = Contract.getRepeatableInfo(contract, cardName);

  // Card is repeatable
  if (repeatableInfo) {
    // Repeatable cards should be objects.
    // If card is an array, it is most likely provided as an
    // array by an api call. Hence, convert it to proper object format.
    newInput[cardName] = handleRepeatable(givenCard, defaultCard, cardName, contract);
  } else {
    newInput[cardName] = handleInputFields(givenCard, defaultCard, cardName, null, contract);
  }
}

function handleRepeatable(givenCard, defaultCard, cardName, contract) {
  let hasAddedGivenCard = false;
  const newCard = {};

  // We allow for simplified specification of repeatable states.
  // If specified as an array, convert it into object repeatable state,
  // with the addition of a new id and _uid and _meta
  if (Array.isArray(givenCard)) {
    let i = 0;
    for (const givenEntry of givenCard) {
      const newId = givenEntry.id || givenEntry._uid || makeRepeatableId(cardName);
      newCard[newId] = {
        ...handleInputFields(givenEntry, {}, cardName, newId, contract),
        _uid: newId,
        _meta: givenEntry._meta || {
          color: colors[i],
          added: i + 1,
        },
      };
      hasAddedGivenCard = true;
      i++;
    }
  }
  // If object, just ensure it has the proper _uid and _meta
  else if (givenCard && typeof givenCard === 'object') {
    let i = 0;
    for (const cardId in givenCard) {
      const givenEntry = givenCard[cardId];
      newCard[cardId] = {
        ...handleInputFields(givenEntry, {}, cardName, cardId, contract),
        _uid: cardId,
        _meta: givenEntry._meta || {
          color: colors[i],
          added: i + 1,
        },
      };
      hasAddedGivenCard = true;
      i++;
    }
  }
  if (!hasAddedGivenCard) {
    if (defaultCard) return defaultCard;
    else return {};
  }
  return newCard;
}

function handleInputFields(givenCard = {}, defaultCard = {}, cardName, repeatableId = null, contract) {
  if (!defaultCard && !givenCard) {
    return {};
  }
  const cardState = { ...defaultCard, ...givenCard };
  const newCardState = {};
  inputFieldsLoop: for (const inputFieldName in cardState) {
    const inputFieldState = cardState[inputFieldName];
    const data = Contract.getUiInput(contract, inputFieldName);

    // If the inputFieldName does not correspond to an UI Input.
    if (!data) {
      // This could be a nested repeatable. Check if so.
      const card = Contract.getUiCard(contract, cardName);
      const fieldCard = Contract.getUiCard(contract, inputFieldName);

      if (card && Array.isArray(card.inputs_order) && fieldCard) {
        for (const anInputFieldName of card.inputs_order) {
          const anInputData = Contract.getUiInput(contract, anInputFieldName);
          if (anInputData.type === 'card' && anInputData.cardName === inputFieldName) {
            newCardState[inputFieldName] = handleRepeatable(
              inputFieldState,
              defaultCard[inputFieldName] || {},
              inputFieldName,
              contract
            );

            continue inputFieldsLoop; // Continue 2. No code exec after here.
          }
        }
      }

      // If not a nested repeatable, just assign the value and move on.
      newCardState[inputFieldName] = inputFieldState;
      continue;
    }

    switch (data.type) {
      case 'QA': // Questions and Answers
        // Should be object (but we've may receive array from api call)
        if (Array.isArray(inputFieldState)) {
          const newQAState = {};
          for (const entry of inputFieldState) {
            const newId = entry.id || entry._uid || uuid();
            newQAState[newId] = {
              ...entry,
              id: newId,
            };
          }
          newCardState[inputFieldName] = newQAState;
        } else if (inputFieldState && typeof inputFieldState === 'object') {
          const newQAState = {};
          for (const qaId in inputFieldState) {
            newQAState[qaId] = {
              ...inputFieldState[qaId],
              id: qaId,
            };
          }
          newCardState[inputFieldName] = newQAState;
        } else {
          console.log('Invalid QA state');
        }
        break;

      default:
        newCardState[inputFieldName] = inputFieldState;
        break;
    }
  }
  return newCardState;
}
