import React, { useState, memo, useCallback, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Menu, Tooltip, Modal, Button, notification, Divider } from 'antd';
import { Editor, Path, Node, Transforms } from 'slate';
import { useSelected, ReactEditor, useEditor } from 'slate-react';
import {
  removeBlockByPath,
  duplicateBlockByPath,
  insertBlockByPath,
  liftClauseByPath,
  setItemJoin,
  connectFile,
  removeRepeatable,
} from '../../../../helpers';
import { getContractValues, useIsTemplateStudio, setModalContext, setAlertContext, useContract } from 'hooks';
import { Contract } from 'core/interfaces';
import { headingToNumber, BLOCK_RENDER_OPTIONS, isList, blockMenuIcons } from 'core/types/elements';
import IntlMessages from 'util/IntlMessages';
import { setDraft } from 'appRedux/actions';
import MenuHeader from 'components/ui/MenuHeader';
import { ConditionActions, PrintCondition } from 'components/Rules/NodeConditions';
import EditContractBlock from './BlockMenuModals/EditContractBlock';
import api from 'utils/api';

const MODALS = {
  editContractBlock: {
    component: EditContractBlock,
    className: 'edit-contract-block',
  },
};

const useShowMenu = (element, editor) => {
  const contract = getContractValues();
  const selected = useSelected();

  if (editor?.meta?.action !== 'contract' && !editor.meta.isEditingTemplate) return false;
  if (element._isInActiveRepresentation || (element.data && element.data._inActive)) return false;

  // Prova flytta upp denna t.ex.. returnera children direkt.. prova lite olika.
  // Ludvig har provat undersöka varför den renderar ytterligare en gång.

  if (!selected) return false;

  if (BLOCK_RENDER_OPTIONS.withBlockMenu.includes(element.type)) return true;
  const headerLevelStartsAsText = Contract.getHeaderLevelStartsAsText(contract);
  if (!headerLevelStartsAsText) return;
  const headingNumber = headingToNumber[element.type];
  if (headingNumber >= headerLevelStartsAsText) return true;
};

export const BlockMenu = memo(({ children, editor, element }) => {
  // anropas 800 ggr
  const showBlockMenu = useShowMenu(element, editor);
  const stringPath = showBlockMenu ? ReactEditor.findPath(editor, element).join('.') : '';
  const { type } = element;
  // console.log('String path...', {stringPath})
  const path = useMemo(() => {
    return stringPath.split('.').map((item) => parseInt(item));
  }, [stringPath]);

  // console.log('showblockmenu: ', element, showBlockMenu)

  if (!showBlockMenu) return children;
  return (
    <>
      <BlockMenuButton path={path} type={type} />
      {children}
    </>
  );
});

const onMenuClose = () => {};

const BlockMenuButton = memo((props) => {
  const editor = useEditor();
  const { type } = props;

  const [modalData, setModalData] = useState(null);

  const handleMouseEnter = useCallback(() => {
    const element = Node.get(editor, props.path);
    editor.highlightNode(element);
  }, [editor, props.path]);
  const handleMouseLeave = useCallback(() => {
    editor.clearHighlights();
  }, [editor]);
  const [isHidingMenu, setIsHidingMenu] = useState(false);

  // Force hiding of the menu. Can be used for example if a component should open because of a click, and must hide the context menu
  if (isHidingMenu) return null;

  const propsToPass = { ...props, setIsHidingMenu, setModalData };

  return (
    <>
      <Tooltip
        title={
          <>
            <IntlMessages id={'contract.content.types.' + type} />{' '}
            <span>
              <IntlMessages id="general.xMeny" />
            </span>
          </>
        }
        placement="left"
      >
        <div
          className="block-menu"
          contentEditable={false}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Dropdown
            overlay={<BlockMenuContent {...propsToPass} />}
            trigger={['click']}
            onClose={onMenuClose}
            overlayClassName="editor-clause-menu-overlay"
          >
            <i className={'mdi ' + blockMenuIcons[type]} />
          </Dropdown>
        </div>
      </Tooltip>
      <BlockMenuModal data={modalData} setModalData={setModalData} />
    </>
  );
});

function BlockMenuModal({ data, setModalData }) {
  if (!data) return null;
  const onClose = () => {
    if (typeof data.onClose === 'function') data.onClose();
    setModalData(null);
  };
  const onOk = () => {
    if (typeof data.onOk === 'function') data.onOk();
    onClose();
  };
  const renderModalContent = (data) => {
    if (!data || !MODALS[data.type] || !MODALS[data.type].component) return null;
    const Component = MODALS[data.type].component;
    return <Component data={data} onClose={onClose} />;
  };

  if (!data || !MODALS[data.type] || !MODALS[data.type].component) return null;
  const className = MODALS[data.type].className;

  const modalContent = renderModalContent(data);

  if (!modalContent) return null;

  const modalClassName = (className || '') + ' ' + ('modal-' + (data.size ? data.size : 'medium'));
  return (
    <Modal
      title={data.title}
      visible={true}
      onOk={onOk}
      okText={<IntlMessages id="desc.Save" />}
      onCancel={onClose}
      cancelText={<IntlMessages id="desc.Cancel" />}
      maskClosable={false}
      backdrop={'static'}
      mask={false}
      centered
      // size="lg"
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      className={modalClassName}
      /* footer={
        <Button type="primary" onClick={onOk}>
          OK
        </Button>
      } */
    >
      {modalContent}
    </Modal>
  );
}

const itemProduces = {
  section: [
    { type: 'section', above: true, below: true },
    { type: 'clause', top: true, bottom: true },
    { type: 'paragraph', top: true, bottom: true },
    { type: 'numbered_list', top: true, bottom: true },
    { type: 'bulleted_list', top: true, bottom: true },
  ],
  paragraph: [{ type: 'paragraph', above: true, below: true }],
  clause: [
    { type: 'clause', above: true, below: true, top: true, bottom: true },
    { type: 'paragraph', above: true, below: true, top: true, bottom: true },
    { type: 'numbered_list', above: true, below: true, top: true, bottom: true },
    { type: 'bulleted_list', above: true, below: true, top: true, bottom: true },
  ],
  heading_one: [
    { type: 'heading_two', above: false, below: true },
    { type: 'paragraph', above: true, below: true },
    { type: 'numbered_list', above: false, below: true },
    { type: 'bulleted_list', above: false, below: true },
  ],
  heading_two: [
    { type: 'heading_three', above: false, below: true },
    { type: 'paragraph', above: true, below: true },
    { type: 'numbered_list', above: false, below: true },
    { type: 'bulleted_list', above: false, below: true },
  ],
  heading_three: [
    { type: 'paragraph', above: true, below: true },
    { type: 'numbered_list', above: false, below: true },
    { type: 'bulleted_list', above: false, below: true },
  ],
  list_item: [
    { type: 'list_item', above: true, below: true },
    { type: 'paragraph', above: true, below: true },
  ],
  img: [
    { type: 'paragraph', above: true, below: true },
    { type: 'numbered_list', above: false, below: true },
    { type: 'bulleted_list', above: false, below: true },
  ],
  table: [{ type: 'paragraph', above: true, below: true }],
  numbered_list: [
    { type: 'list_item', top: true, bottom: true },
    { type: 'paragraph', above: true, below: true },
  ],
  bulleted_list: [
    { type: 'list_item', top: true, bottom: true },
    { type: 'paragraph', above: true, below: true },
  ],
};

function BlockMenuContent(props) {
  const { type, path, setIsHidingMenu } = props;
  const isTemplate = useIsTemplateStudio();

  let itemName = <IntlMessages id={'contract.content.types.' + type} /> || 'item';
  return (
    <Menu>
      <MenuHeader menu intl="studio.blockMenu.groups.actions" />
      <Insertions path={path} type={type} itemName={itemName} />
      <Duplicate path={path} itemName={itemName} />
      {type === 'list_item' && <ListItemMerge path={path} />}
      <Menu.Divider />
      <MenuHeader menu intl="studio.blockMenu.groups.marks" />
      <BlockMarks path={path} />
      <Menu.Divider />
      <MenuHeader menu intl="studio.blockMenu.groups.other" />
      {type === 'clause' && <LiftClause path={path} itemName={itemName} />}
      <EditBlock {...props} path={path} itemName={itemName} setIsHidingMenu={setIsHidingMenu} />
      <RemoveBlock path={path} itemName={itemName} />
      <ItemJoin path={path} itemName={itemName} />
      <NewFile path={path} itemName={itemName} setIsHidingMenu={setIsHidingMenu} />
      {isTemplate && <Menu.Divider />}
      {isTemplate && <MenuHeader menu intl="studio.blockMenu.groups.template" />}
      {isTemplate && (
        <ConnectRepeatable path={path} type={type} itemName={itemName} setIsHidingMenu={setIsHidingMenu} />
      )}
      {isTemplate && <BlockACP path={path} itemName={itemName} />}
    </Menu>
  );
}

function LiftClause(props) {
  const editor = useEditor();
  const { itemName, path, ...rest } = props;
  const parentElement = Node.get(editor, Path.parent(path));
  if (parentElement.type === 'section') return null;
  return (
    <Menu.Item
      key="liftclause"
      {...rest}
      onClick={() => {
        liftClauseByPath(editor, path);
      }}
    >
      <i className="mdi mdi-arrow-up-bold mr-2" /> Move up one Level
    </Menu.Item>
  );
}

function ItemJoin(props) {
  const editor = useEditor();
  const { itemName, path, ...rest } = props;
  const node = Node.get(editor, path);

  if (!isList(node)) return null;

  const _setItemJoin = (value) => setItemJoin(editor, path, value);

  if (!node.data) {
    console.log('List Node has no data...');
    return null;
  }

  const currentValue = node.data?.item_join || false;

  return (
    <Menu.SubMenu
      title={
        <span>
          <i className="mdi mdi-plus mr-2" />
          Item Join
        </span>
      }
      key={'item join'}
      {...rest}
    >
      <Menu.Item onMouseDown={() => _setItemJoin(false)}>
        {currentValue === false && <i className="mdi mdi-check mr-2" />} None
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onMouseDown={() => _setItemJoin('and')}>
        {currentValue === 'and' && <i className="mdi mdi-check mr-2" />} and
      </Menu.Item>
      <Menu.Item onMouseDown={() => _setItemJoin('or')}>
        {currentValue === 'or' && <i className="mdi mdi-check mr-2" />} or
      </Menu.Item>
    </Menu.SubMenu>
  );
}

function BlockACP(props) {
  const editor = useEditor();
  const { itemName, path, ...rest } = props;
  const node = Node.get(editor, path);
  const stringPath = JSON.stringify(path);
  const hasAcp = !!node.data?.acp;
  const actionDescription = hasAcp ? 'unconditioned' : 'conditioned';

  const yesNo = hasAcp ? 'yes' : 'no';
  /* const rerender = useRerender()
  const onChangeRule = (id) => {
    console.log('change')
    rerender()
  }; */

  const removeCondition = () => {
    Transforms.setNodes(
      editor,
      {
        data: {
          ...node.data,
          acp: null,
        },
      },
      { at: path }
    );
  };

  return (
    <Menu.SubMenu
      title={
        <span>
          <i className={'mdi mdi-crosshairs' + (hasAcp ? '-gps' : '') + ' mr-2'} />
          Conditioned ({yesNo})
        </span>
      }
      key={'blockACP'}
      {...rest}
    >
      <Menu.Item>
        <PrintCondition node={node} />
      </Menu.Item>
      <Menu.Item>
        <ConditionActions node={node} stringPath={stringPath} linksInline />
      </Menu.Item>
      {hasAcp && (
        <Menu.Item>
          <span onClick={removeCondition}>Remove Condition</span>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  );
}

function EditBlock(props) {
  const editor = useEditor();
  const contract = getContractValues();
  const { itemName, path, setIsHidingMenu, setModalData, ...rest } = props;
  const node = Node.get(editor, path);

  const tmpData = useRef(JSON.parse(JSON.stringify(node.data || {})));

  const onOk = () => {
    console.log('Update node data with ', tmpData.current);
    Transforms.setNodes(
      editor,
      {
        data: tmpData.current,
      },
      {
        at: path,
      }
    );
  };

  return (
    <Menu.Item
      key="editBlock"
      onMouseDown={() => {
        // setIsHidingMenu(true);
        setModalData({
          type: 'editContractBlock',
          node,
          path,
          contract,
          onOk,
          tmpData,
          size: 'lg',
          onClose: () => setIsHidingMenu(false),
        });
      }}
      {...rest}
    >
      <span>
        <i className="mdi mdi-book-open-page-variant mr-2" />
        <IntlMessages id="general.edit" cap /> {itemName}
      </span>
    </Menu.Item>
  );
}

function NewFile(props) {
  const editor = useEditor();
  const contract = getContractValues();

  const { itemName, path, setIsHidingMenu, ...rest } = props;
  const node = Node.get(editor, path);

  const onConnectFiles = async (files) => {
    files.forEach((file) => {
      connectFile(editor, path, file);
    });
  };

  return (
    <Menu.Item
      key="newFile"
      onMouseDown={() => {
        setIsHidingMenu(true);
        setModalContext({
          type: 'file-upload',
          projectId: contract.projectId,
          documentId: contract.documentId,
          nodeType: node.type,
          connectedFiles: node.data.files || [],
          onConnectFiles,
          onClose: () => setIsHidingMenu(false),
        });
      }}
      {...rest}
    >
      <span>
        <i className="mdi mdi-file-multiple mr-2" />
        <IntlMessages id="app.file.connectFile" />
      </span>
    </Menu.Item>
  );
}

function ConnectRepeatable(props) {
  const dispatch = useDispatch();
  const editor = useEditor();

  const { itemName, path, setIsHidingMenu, type, ...rest } = props;
  const node = Node.get(editor, path);

  if (!['clause', 'paragraph', 'numbered_list', 'bulleted_list'].includes(type)) return null;

  if (!node.data.each_repeatable) {
    return (
      <Menu.Item
        key="connectRepeatable"
        onMouseDown={() => {
          setIsHidingMenu(true);
          setAlertContext({
            type: 'info',
            message: <IntlMessages id="studio.blockMenu.repeatable.connect.message" />,
            description: <IntlMessages id="studio.blockMenu.repeatable.connect.description" />,
            onClose: () => {
              setIsHidingMenu(false);
              dispatch(setDraft('template_connect_repeatable', null));
            },
          });
          dispatch(setDraft('template_connect_repeatable', { path }));
        }}
        {...rest}
      >
        <span>
          <i className="mdi mdi-file-multiple mr-2" />
          <IntlMessages id="studio.blockMenu.repeatable.connect.button" />
        </span>
      </Menu.Item>
    );
  } else {
    return (
      <Menu.Item
        key="disconnectRepeatable"
        onMouseDown={() => {
          removeRepeatable(editor, path);
          Transforms.forceDeselect(editor);
        }}
        {...rest}
      >
        <span>
          <i className="mdi mdi-file-multiple mr-2" />
          <IntlMessages id="studio.blockMenu.repeatable.disconnect.button" />
        </span>
      </Menu.Item>
    );
  }
}

function RemoveBlock(props) {
  const editor = useEditor();
  const { itemName, path, ...rest } = props;
  return (
    <Menu.Item
      key="removeBlock"
      onMouseDown={() => {
        removeBlockByPath(editor, path);
      }}
      {...rest}
    >
      <span>
        <i className="mdi mdi-close-box-outline mr-2" />
        <IntlMessages id="desc.Remove" /> {itemName}
      </span>
    </Menu.Item>
  );
}

function Insertions(props) {
  const { itemName, path, type, ...rest } = props;
  const inserts = itemProduces[type];
  if (!inserts) return null;
  const types = Object.keys(inserts);
  if (!types.length) return null;

  const capItemName = itemName;

  return (
    <Menu.SubMenu
      title={
        <span>
          <i className="mdi mdi-plus mr-2" />
          <IntlMessages id="desc.Insert" />
        </span>
      }
      key={'inserts'}
      {...rest}
    >
      {inserts.map((insert) => (
        <InsertChoice
          key={insert.type}
          path={path}
          capItemName={capItemName}
          type={insert.type}
          data={insert}
        />
      ))}
    </Menu.SubMenu>
  );
}

function InsertChoice({ path, type, data, capItemName, ...rest }) {
  const editor = useEditor();
  const { above, below, top, bottom } = data;
  const showDivider = (above || below) && (top || bottom);

  return (
    <Menu.SubMenu
      title={<IntlMessages id={'contract.content.types.' + data.type} />}
      key={'insert_' + type}
      {...rest}
    >
      {top && (
        <Menu.Item
          key={type + '_within_top'}
          onClick={() => {
            insertBlockByPath(editor, path, type, {
              mode: 'within',
              direction: 'above',
            });
          }}
        >
          <span>
            <i className="mdi mdi-arrow-top-right mr-2" />
            <IntlMessages id="studio.blockMenu.Within" /> {capItemName} (
            <IntlMessages id="studio.blockMenu.atTop" />)
          </span>
        </Menu.Item>
      )}
      {bottom && (
        <Menu.Item
          key={type + '_within_bottom'}
          onClick={() => {
            insertBlockByPath(editor, path, type, {
              mode: 'within',
              direction: 'below',
            });
          }}
        >
          <span>
            <i className="mdi mdi-arrow-bottom-right mr-2" />
            <IntlMessages id="studio.blockMenu.Within" /> {capItemName} (
            <IntlMessages id="studio.blockMenu.atBottom" />)
          </span>
        </Menu.Item>
      )}
      {showDivider && <Menu.Divider />}
      {above && (
        <Menu.Item
          key={type + '_sibling_top'}
          onClick={() => {
            insertBlockByPath(editor, path, type, {
              mode: 'sibling',
              direction: 'above',
            });
          }}
        >
          <span>
            <i className="mdi mdi-arrow-up mr-2" />
            <IntlMessages id="studio.blockMenu.BeforeThis" /> {capItemName}
          </span>
        </Menu.Item>
      )}
      {below && (
        <Menu.Item
          key={type + '_sibling_bottom'}
          onClick={() => {
            insertBlockByPath(editor, path, type, {
              mode: 'sibling',
              direction: 'below',
            });
          }}
        >
          <span>
            <i className="mdi mdi-arrow-down mr-2" />
            <IntlMessages id="studio.blockMenu.AfterThis" /> {capItemName}
          </span>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  );
}

function Duplicate({ path, itemName, ...rest }) {
  const editor = useEditor();
  return (
    <Menu.Item key="1" {...rest} onMouseDown={() => duplicateBlockByPath(editor, path)}>
      <span>
        <i className="mdi mdi-content-duplicate mr-2" />
        <IntlMessages id="studio.blockMenu.Duplicate" /> {itemName}
      </span>
    </Menu.Item>
  );
}

function ListItemMerge({ path, ...rest }) {
  const editor = useEditor();

  if (path.slice(-1)[0] !== 0) {
    return null;
  }

  const parentPath = Path.parent(path);
  const parentNode = Node.get(editor, parentPath);

  // if(parentNode.children.length > 1) { return null }

  const previousActiveEntry = Editor.previous(editor, {
    at: parentPath,
    match: (n) => {
      const [parent] = Editor.parent(editor, parentPath);
      return parent.children.includes(n) && !n.data?._inActive;
    },
    mode: 'highest',
  });

  if (!previousActiveEntry) return null;
  const [previousActiveNode, previousActivePath] = previousActiveEntry;

  if (!isList(previousActiveNode)) return null;

  return (
    <>
      <Menu.Divider {...rest} />
      <Menu.Item
        {...rest}
        key="moveIntoAboveList"
        onClick={() => {
          Editor.withoutNormalizing(editor, () => {
            const parentLength = parentNode.children.length;
            const to = [...previousActivePath, previousActiveNode.children.length];
            // Move into above list
            Transforms.moveNodes(editor, {
              at: path,
              to,
            });
            Transforms.setSelection(editor, {
              anchor: Editor.start(editor, to),
              focus: Editor.end(editor, to),
            });

            if (parentLength === 1) {
              console.log('Delete curren list');
              Transforms.delete(editor, { at: parentPath });
            }
          });
        }}
      >
        <span>
          <i className="mdi mdi-call-missed mr-2 flipped" />
          <IntlMessages id="studio.blockMenu.MoveIntoListAbove" />
        </span>
      </Menu.Item>
    </>
  );
}

function BlockMarks({ path, ...rest }) {
  const editor = useEditor();
  const contract = useContract();
  const element = Node.get(editor, path);

  const [marks, setMarks] = useState((element.data && element.data.marks) || {});

  const toggleMark = (key) => {
    const currentlyHasKey = marks[key];

    if (key === 'update-template') {
      if (currentlyHasKey) {
        api
          .delete('/clauselibs/' + currentlyHasKey)
          .then((res) => {
            console.log('Removed from clause lib ', res);
          })
          .catch((err) => {
            console.log('Error remove from clause lib ', err);
          })
          .finally(() => {
            const newMarks = {
              ...marks,
              [key]: null,
            };
            Transforms.setNodes(
              editor,
              {
                data: {
                  ...(element.data || {}),
                  marks: newMarks,
                },
              },
              {
                at: path,
              }
            );
            setMarks(newMarks);
          });

        console.log('Remove key... update-template');
        return;
      }
      //

      const { id: versionId, documentId, documentTemplateId } = contract;
      const { item_id, template_id } = element.data || {};

      if (versionId && documentId && documentTemplateId && item_id && template_id) {
        api
          .post('/clauselibs', {
            data: {},
            itemId: item_id,
            templateId: template_id,
            versionId,
            documentId,
            documentTemplateId,
          })
          .then((res) => {
            if (!res.data) {
              notification.error({
                message: 'Cannot create template update',
                description: 'Please try again later',
              });
            }
            const { id } = res.data;
            const newMarks = {
              ...marks,
              [key]: id,
            };
            Transforms.setNodes(
              editor,
              {
                data: {
                  ...(element.data || {}),
                  marks: newMarks,
                },
              },
              {
                at: path,
              }
            );
            setMarks(newMarks);
            notification.success({
              message: 'Template update',
              description: 'Marked as proposal for update of template',
            });
          })
          .catch((err) => {
            notification.error({
              message: 'Cannot create template update',
              description: 'Please try again later',
            });
          });
      } else {
        console.log('Missing data to add new ClauseLib item');
      }
    } else {
      const newMarks = {
        ...marks,
        [key]: !marks[key],
      };
      Transforms.setNodes(
        editor,
        {
          data: {
            ...(element.data || {}),
            marks: newMarks,
          },
        },
        {
          at: path,
        }
      );
      setMarks(newMarks);
    }
  };

  return (
    <Menu.SubMenu
      title={
        <>
          <i className="mdi mdi-tag-outline mr-2" />
          <IntlMessages id="studio.blockMenu.MarkItem" />
        </>
      }
      key={'mark_block'}
      {...rest}
    >
      <MarkItem
        tag={'new'}
        label={<IntlMessages id="studio.blockMenu.NewItem" />}
        toggleMark={toggleMark}
        marks={marks}
      />
      <MarkItem
        tag={'required'}
        label={<IntlMessages id="studio.blockMenu.Required" />}
        toggleMark={toggleMark}
        marks={marks}
      />
      <Divider />
      <MarkItem
        tag={'update-template'}
        label={<IntlMessages id="studio.blockMenu.UpdateTemplate" />}
        toggleMark={toggleMark}
        marks={marks}
      />
    </Menu.SubMenu>
  );
}

function MarkItem({ tag, label, toggleMark, marks, ...rest }) {
  const active = !!marks[tag];
  return (
    <Menu.Item
      key={tag + '_mark'}
      {...rest}
      onClick={() => {
        toggleMark(tag);
      }}
    >
      <span>
        {active ? (
          <i className="mdi mdi-check mr-2" />
        ) : (
          <span style={{ display: 'inline-block', width: '20px' }}></span>
        )}
        {label}
      </span>
    </Menu.Item>
  );
}
