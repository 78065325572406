import React, { useState, useEffect, useMemo, useRef } from 'react';
import { getAvailableOperatorText, operators } from '../../core/common';
import { Select, InputNumber } from 'antd';

const supportedStates = [
  {
    id: 'legalPersons',
    label: 'Legal Persons',
  },
  {
    id: 'realPersons',
    label: 'Real Persons',
  },
];

export default function InputRule({ ui, language, setters, ruleId, rule, currentCard: currentInputField }) {
  let startOperator = '==',
    startCurrentInputField = null,
    startValue = '';

  if (rule) {
    startOperator = Object.keys(rule)[0];
    const [tmpVars, tmpValue] = rule[startOperator];
    startCurrentInputField = tmpVars.numof_state;
    startValue = tmpValue;
  }

  const hasMounted = useRef(false);
  const [operator, setOperator] = useState(startOperator);
  // const [currentInputField, setCurrentInputField] = useState(startCurrentInputField);
  const [value, setValue] = useState(startValue);

  /* const cards = useMemo(() => {
    return Object.keys(ui.cards);
  }, [ui]); */

  /* const onInputFieldChange = (newInputName) => {
    setCurrentInputField(newInputName);
  }; */
  const onOperatorChange = (value) => {
    setOperator(value);
  };
  const onValueChange = (evt) => {
    if (evt === undefined || evt === null) return;
    setValue(evt);
  };

  const validRule = (operator && currentInputField && typeof value === 'number') || false;

  useEffect(() => {
    if (!hasMounted.current) return;
    if (!validRule) return;
    const rule = { [operator]: [{ numof_state: currentInputField }, value] };
    setters.current.setRule(ruleId, rule);
  }, [validRule, setters, ruleId, operator, currentInputField, value, hasMounted]);

  useEffect(() => {
    console.log('valid rule ', validRule)
    setters.current.setValid(ruleId, validRule);
  }, [ruleId, validRule, setters]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return (
    <>
      {/* <InputField
        currentInputField={currentInputField}
        onInputFieldChange={onInputFieldChange}
        language={language}
      /> */}
      <Operators operator={operator} onOperatorChange={onOperatorChange} language={language} />
      <Value operator={operator} value={value} onValueChange={onValueChange} language={language} />
    </>
  );
}

function InputField({ currentInputField, onInputFieldChange }) {
  return (
    <div>
      <Select
        onChange={onInputFieldChange}
        value={currentInputField || undefined}
        placeholder={<span>Select state entries</span>}
      >
        {supportedStates.map(({ id, label }) => {
          return (
            <Select.Option value={id} key={id}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

function Operators({ operator, onOperatorChange }) {
  return (
    <div>
      <Select onChange={onOperatorChange} value={operator}>
        {operators.map((availableOperator) => {
          const label = getAvailableOperatorText(availableOperator);
          return (
            <Select.Option value={availableOperator} key={availableOperator}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

function Value({ value, onValueChange }) {
  return (
    <div>
      <InputNumber value={value} onChange={onValueChange} />
    </div>
  );
}
