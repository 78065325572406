
/**
 * ENUM.
 * An inline field container, the value of which depends on
 * underlying rules. 
 * The enums are processed in the order they appear in the enums-array.
 * If an enum's rule passes the logic test, then that item's content is
 * set as the node's children, and the `data.currentEnumIndex` is set to
 * that enum's index in the array.
 * 
 * Node example:
 		{
			 type: "field",
			 variant: "enum",
			 data: {
				 ignoreChange: false, // Default 'false'. If true, any manual edits will not be saved when enum changes
				 enums: [
					 {
						rule: {...json-logic rule...},
						content: [{
							text: "this is the first alternative node"
						}]
					 },
					 {
						rule: {...json-logic rule...},
						content: [{
							type: "field",
							variant: "vari",
							data: {...},
							children: [{text: "this is the second alternative node which itself is an inline"}]
						}]
					 },
					 {
						default: true, // default content if none above match
						content: [{
							text: "this is the first alternative node"
						}]
					 },
				 ],
				 currentEnumIndex: 0 // The current value
			 }
 		}
 */

export const enumAnyUpdate = {
  id: "enumAnyUpdate",
  dependencies: {
    repeatableAdd: true,
    repeatableRemove: true,
    repeatableChange: true,
    ordinary: true,
    legalPersonAny: true,
  },
  time: -5,
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    // This handler runs after the `activeConditionPreparation` handler,
    // as we have set the `time` property to -5 (compared with -10).
    // Upon any input change, the activeCondition handler calculates
    // relevant keys, which we can re-use here.
    const keys = this._tmpDraft.updatedACPkeys;

    // If no keys are set, that means that we are at the stage where
    // a contract is first being created ('genesis' phase). The reason
    // being that the activeCondition handler does not update ACP's
    // prior to the contract having been full created and loaded again.
    // Hence, at this stage, we want to check all rules.
    const draftInfo = this.getDraftInfo();
    const checkAllRules = !keys && !draftInfo.status.loaded

    return (node, parents) => {
      // Only for fields of variant 'enum'
      if (node.type !== "field" || node.variant !== "enum") return;
      if (!Array.isArray(node.data.enums)) return;

      const localState = api.utils.engine.getLocalState(node, parents, state, this.contract);

      const currentEnumIndex = node.data.currentEnumIndex;
      

      const supportMultiple = !!node.data.multiple

      if (supportMultiple) {
        node.data.currentEnumIndices = []
        let content = []
        for (let i = 0; i < node.data.enums.length; i++) {
          const item = node.data.enums[i];

          if (item.default && content.length === 0) {
            content = item.content
            node.data.currentEnumIndices = [i]
            break;
          }

          if(this.applyLogic(item.rule, {
            local: localState,
          })) {
            node.data.currentEnumIndices.push(i)
            content = content.concat(item.content)
          }
        }
        
        if (node.data.join && content.length > 1) {
          let joinedContent = []
          for (let i=0;i<content.length;i++) {

            if (i+1 === content.length) {
              joinedContent.push(content[i])
              continue;
            }

            joinedContent.push(content[i])

            if (i+2 === content.length) {
              joinedContent.push(this.makeTextNode(" "+this.translateText(node.data.join)+" ", null, content[i]))
            } else {
              joinedContent.push(this.makeTextNode(", "))
            }
          }
          content = joinedContent
        }
        
        node.children = content;
        this.markNodeUpdate(node);
        return;
      }


      for (let i = 0; i < node.data.enums.length; i++) {
        const item = node.data.enums[i];

        // We want to proceed with the item in the following cases:
        // 1) We need to check all rules (see above)
        // 2) It is the default enum item
        // 3) The current rule has been affected by the relevant update
        const proceed =
          checkAllRules || item.default || api.logic.matchRule(item.rule, keys);        
        
        if (!proceed) {
          if (i === currentEnumIndex) {
            // If this item is the current item, and it is unaffected by the
            // relevant update (payload), then no change to the relevant enum node
            // shall be made at all. I.e. we have already a valid enum item being set.
            break;
          }
          continue;
        }

        if (
          item.default ||
          this.applyLogic(item.rule, {
            local: localState,
          })
        ) {
          // If the same index already applies - no change needed
          // and we may return early.
          if (node.data.currentEnumIndex === i) {
            return;
          }
          if (
            currentEnumIndex !== null &&
            currentEnumIndex !== undefined &&
            node.data.enums[currentEnumIndex] &&
            !node.data.ignoreChange
          ) {
            // Save current content to current item
            node.data.enums[currentEnumIndex].content = JSON.parse(
              JSON.stringify(node.children)
            );
          }
          node.data.currentEnumIndex = i;
          // node.data.currentEnumIndices = [i];
          node.children = item.content;
          this.markNodeUpdate(node);
          return;
        }
      }
    };
  },
};
