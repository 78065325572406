export const smartDefinitionOnRepeatableAdd = {
  id: 'smartDefinitionOnRepeatableAdd',
  mode: 'single',
  time: -3,
  dependencies: { repeatableAdd: true },
  handler: function ({ state, handlerInvoked, entry, path, api }) {
    if (!this.getContractCreate().build.smartDefinitions) return;

    const { value, cardId: repeatable } = entry;

    if (!repeatable) return;

    const { _uid } = value;

    const definitions = this.getContractCreate().build.smartDefinitions.filter(
      (item) =>
        item.xcontent &&
        item.xcontent.data &&
        item.xcontent.data.each_repeatable &&
        item.xcontent.data.each_repeatable.repeatable === repeatable
    );

    let definitionWasInserted = false;

    return (node, parents) => {
      if (node.type !== 'definitions' || !node.data || node.data.definitions_id !== 'mainDefinitions') return;

      for (const definition of definitions) {
        const content = api.utils.engine.uniqueItemIds(
          api.utils.engine.initEachItems(JSON.parse(JSON.stringify(definition.xcontent)), _uid)
        );

        if (content && content.data && content.data.acp) {
          if (!this.applyLogic(definition.rule, { state })) content.data._inActive = true;
        }

        const definitionKey = getDefinitionKey(content, api.utils.engine.find);

        if (typeof window !== 'undefined' && window.debug) this.log('On repeatable add def ', { path });

        content.data = {
          ...content.data,
          _each_uid: _uid,
          _each_repeatable_name: repeatable,
          _each_label_id: 'el_smartdef_' + definitionKey + '_' + _uid,
          _path: path,
          key: definitionKey,
          isSmartDefinition: true,
          isDefKey: true,
        };

        this.populateEachContent(repeatable, content, value, state, entry);
        // this.log('Added smart definition.')
        delete content.data.each_repeatable;

        definitionWasInserted = true;
        node.children.push(content);
        continue;
      }
      if (definitionWasInserted) this.sortDefinitions();
    };
  },
};

function getDefinitionKey(content, find) {
  const items = find(content, (n) => n.variant === 'item' && n.data && n.data.each && n.data.each.key);
  if (items.length === 0) return '__unknown__';
  return items[0].data.each.key;
}
