import React, { useState, useRef, useEffect } from 'react';
import { Form, Select, Modal, Input, Button, Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { insertLegalPerson, insertRealPerson } from 'appRedux/actions';
import { getValue } from './utils/values';
import { PlusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import OverlayActions from 'components/Widgets/OverlayActions';
import countries from 'routes/studio/Views/Input/renderDraftUI/components/orgchart/views/countries.json';
import { LegalPerson, RealPerson } from 'core/interfaces';

import EditLegalPerson from 'components/entity/EditLegalPerson';
import EditRealPerson from 'components/RealPerson/EditRealPerson';
import { userToName } from 'components/ui';

const { Option } = Select;
const { TextArea } = Input;

function Party(props) {
  const { label, handleChange, language, field, path, isTemplate } = props;
  const value = getValue(props, {});

  const { legalPersons, realPersons } = useSelector(({ legalPersons, realPersons }) => ({
    legalPersons,
    realPersons,
  }));
  /* const legalPerson = legalPersons.find((person) => person.id === value.id);
  const realPerson = realPersons.find((person) => person.id === value.id); */

  let person, name;
  const { type } = value;
  if (type === 'legalPerson') {
    person = legalPersons.find((person) => person.id === value.id);
    name = (person && person.name) || null;
  } else if (type === 'realPerson') {
    person = realPersons.find((person) => person.id === value.id);
    name = (person && userToName(person)) || null;
  }

  // console.log('leg pers ', { person, type });

  const [changingParty, setChangingParty] = useState(!person ? true : false);
  const [makingNewEntity, setMakingNewEntity] = useState(false);
  const [editingPerson, setEditingPerson] = useState(false);

  if (isTemplate) {
    return (
      <Form.Item
        label={label}
        extra={field.info ? <small className="muted">{field.info[language]}</small> : null}
      >
        <div className="input-party">
          (<IntlMessages id="studio.template.inputs.types.entity" />)
        </div>
      </Form.Item>
    );
  }

  const onClickName = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setChangingParty(true);
  };
  const onClickEdit = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setEditingPerson(true);
  };
  const saveNewParty = (id, type) => {
    // if (!changingParty) return;
    if (id === '__add_new') {
      setMakingNewEntity(true);
      setChangingParty(false);
      return;
    }
    if (id === value.id) {
      setChangingParty(false);
      return;
    }
    handleChange({
      ...value,
      id,
      type,
    });
    setChangingParty(false);
  };
  const makeNewEntity = (id) => {
    setMakingNewEntity(false);
    handleChange({
      ...value,
      id,
    });
  };

  const cancelMakeNewEntity = () => setMakingNewEntity(false);
  const cancelEditingEntity = () => setEditingPerson(false);

  return (
    <>
      <Form.Item
        label={label}
        extra={field.info ? <small className="muted">{field.info[language]}</small> : null}
      >
        <div className="input-party">
          {changingParty || !person ? (
            <div>
              <ChangeParty value={value} saveNewParty={saveNewParty} />
            </div>
          ) : (
            <OverlayActions
              topAction={{
                onClick: onClickName,
                text: 'Change Entity',
                tooltip: 'Select new entity instead of current',
              }}
              bottomAction={{
                onClick: onClickEdit,
                text: 'Edit Entity',
                tooltip: 'Edit details of currently selected entity',
                tooltipPlacement: 'bottom',
              }}
            >
              <div className="input-party-content hoverable clickable" onClick={onClickName}>
                <div>
                  <div className="input-party-icon">
                    <i className="icon icon-company" />
                  </div>
                </div>
                <div className="input-party-name">
                  <div>{name}</div>
                  <small className="text-grey">{person.identificationNumber}</small>
                </div>
              </div>
            </OverlayActions>
          )}
          {makingNewEntity && <AddEntity save={makeNewEntity} cancel={cancelMakeNewEntity} />}
        </div>
      </Form.Item>
      {editingPerson && type === 'legalPerson' && (
        <EditLegalPerson id={person && person.id} onCancel={cancelEditingEntity} />
      )}
      {editingPerson && type === 'realPerson' && (
        <EditRealPerson id={person && person.id} onCancel={cancelEditingEntity} />
      )}
    </>
  );
}

function AddEntity({ save, cancel }) {
  const { legalPersons, realPersons } = useSelector(({ legalPersons, realPersons }) => ({
    legalPersons,
    realPersons,
  }));

  const [addType, setAddType] = useState('legalPerson');

  const dispatch = useDispatch();
  // console.log('Adding new entity...')
  const onFinish = (values) => {
    if (values.notes) {
      values.data = { notes: values.notes };
    }
    let newPerson;
    if (addType === 'legalPerson') {
      newPerson = LegalPerson.construct(values);
      dispatch(insertLegalPerson(newPerson));
    } else if (addType === 'realPerson') {
      console.log('values are ', values);
      newPerson = RealPerson.construct(values);
      console.log('New person became ', newPerson);
      dispatch(insertRealPerson(newPerson));
    } else {
      return;
    }
    save(newPerson.id, addType);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title={'Add New Entity'}
      visible={true}
      onCancel={cancel}
      cancelText={<IntlMessages id="desc.Cancel" />}
      okText={<IntlMessages id="desc.Save" />}
      okButtonProps={{
        form: 'new-entity-form',
        key: 'submit',
        htmlType: 'submit',
      }}
      backdrop={'static'}
      size={'large'}
      className="modal-medium"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="new-entity-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Radio.Group
          className="mb-4 w-100 text-center"
          value={addType}
          onChange={(e) => setAddType(e.target.value)}
        >
          <Radio value={'legalPerson'}>
            <IntlMessages id="app.persons.legalPerson" />
          </Radio>
          <Radio value={'realPerson'}>
            <IntlMessages id="app.persons.realPerson" />
          </Radio>
        </Radio.Group>

        {addType === 'legalPerson' && (
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input company name!' }]}
          >
            <Input />
          </Form.Item>
        )}
        {addType === 'realPerson' && (
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please input first name!' }]}
          >
            <Input />
          </Form.Item>
        )}
        {addType === 'realPerson' && (
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please input last name!' }]}
          >
            <Input />
          </Form.Item>
        )}

        {addType === 'legalPerson' && (
          <Form.Item label="Parent Company" name="parentId">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {legalPersons.map((lp) => (
                <Option key={lp.id} value={lp.id}>
                  {lp.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Identification Number"
          name="identificationNumber"
          // rules={[{ required: true, message: 'Please input Identification Number!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Jurisdiction" name="jurisdiction">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {countries.map((country) => (
              <Option key={country.code} value={country.code}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Street Adress" name="street">
          <Input />
        </Form.Item>
        <Form.Item label="City" name="city">
          <Input />
        </Form.Item>
        <Form.Item label="Zip Code" name="zipcode">
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="emails"
          tooltip={{
            title: 'Provide one or more email. Press <Enter> after each email.',
            icon: <InfoCircleOutlined />,
          }}
          // extra="Separate emails by space or comma"
        >
          <Select mode="tags" />
        </Form.Item>

        <Form.Item label="Notes" name="notes">
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function ChangeParty(props) {
  const { value, saveNewParty } = props;
  const { legalPersons, realPersons } = useSelector(({ legalPersons, realPersons }) => ({
    legalPersons,
    realPersons,
  }));
  const [selected, setSelected] = useState(value.id || null);

  const persons = [...legalPersons, ...realPersons];

  const ref = useRef();
  useEffect(() => {
    ref.current.focus();
  }, [ref]);
  const onChange = (newVal) => {
    if (newVal === '__add_new') {
      return saveNewParty(newVal);
    }
    setSelected(newVal);
  };

  function onBlur() {
    const changeType = legalPersons.find((lp) => lp.id === selected) ? 'legalPerson' : 'realPerson';
    saveNewParty(selected, changeType);
  }

  return (
    <div>
      <Select
        ref={ref}
        placeholder="Select a person"
        value={selected}
        onChange={onChange}
        onBlur={onBlur}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          if (!option.label || typeof option.label !== 'string') return false;
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        <Option value={'__add_new'} className="border-bottom">
          <span>
            <PlusCircleOutlined /> Add new
          </span>
        </Option>
        {persons.map((person) => {
          if (person.parentId === null) return null;
          return (
            <Option key={person.id} value={person.id} label={userToName(person)}>
              {userToName(person)}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}

export default Party;
