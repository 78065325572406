import React, { useState, useEffect } from 'react';
import {
  message,
  Card,
  Col,
  Table,
  Spin,
  Button,
  Badge,
  Dropdown,
  Menu,
  Popconfirm,
  notification,
} from 'antd';
import IconCardActions from 'components/Widgets/IconCardActions';
import { fixDate } from 'components/ui';
import api from 'utils/api';
import NewFileUpload from 'components/File/NewFileUpload';
import ViewFile from './ViewFile';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { DownloadOutlined, DownOutlined, LinkOutlined, EyeOutlined, SettingFilled } from '@ant-design/icons';

const { Column, ColumnGroup } = Table;

const defaultTableConfig = {
  bordered: false,
  loading: false,
  pagination: { position: 'bottom' },
  size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  rowSelection: null,
  scroll: undefined,
};

const FILE_TYPE_ICONS = {
  png: 'mdi mdi-file-image',
  jpg: 'mdi mdi-file-image',
  gif: 'mdi mdi-file-image',
  pdf: 'mdi mdi-file-pdf-box',
  doc: 'mdi mdi-file-word',
  docx: 'mdi mdi-file-word',
  xlsx: 'mdi mdi-file-excel',
  xls: 'mdi mdi-file-excel',
  __default: 'mdi mdi-file',
};

export const fileQuery = (fileId, resourceId, resourceType, extraPath = null, extraParams = null) => {
  return `/superfiles${fileId ? `/${fileId}` : ''}${
    extraPath ? extraPath : ''
  }?resourceType=${resourceType}&resourceId=${resourceId}${extraParams ? extraParams : ''}`;
};

export default function FileList({
  resourceType,
  resourceId,
  hideCard = false,
  widgets = false,
  cardClassName = '',
  hideNewUpload = false,
  fetchType = 'resource',
  fetchLimit = 0,
  tableType = 'normal',
  connectedFiles,
  onConnectFiles,
  nodeType,
  onDisconnectFile,
  keepModalAfterUpload = false,
}) {
  const [files, setFiles] = useState(null);
  const [filesError, setFilesError] = useState(null);
  const [viewFileCurrent, setViewFileCurrent] = useState(null);

  const formatMessage = useIntlMessage();

  useEffect(() => {
    if (tableType === 'listConnected') {
      const fetchData = async () => {
        // We make one API call per connected file and wait for all responses and then treat them accordingly to the http status
        const fileResponses = await Promise.all(
          connectedFiles.map(async (connectedFile) => {
            try {
              const response = await api.get(
                fileQuery(
                  connectedFile.fileId,
                  connectedFile.resourceId,
                  connectedFile.resourceType,
                  '/info',
                  null
                )
              );
              return response.data;
            } catch (err) {
              const errStatus = err.response?.status;
              if (errStatus && [404, 403].includes(errStatus)) {
                return {
                  ...connectedFile,
                  id: connectedFile.fileId,
                  status: 'missing',
                  fileName:
                    errStatus === 404 ? (
                      <IntlMessages id={'app.file.errors.referencedFileDeleted'} />
                    ) : (
                      <IntlMessages id={'app.file.errors.referencedFilePermission'} />
                    ),
                };
              } else {
                return (
                  <>
                    <IntlMessages id={'app.file.errors.connectedFileMissing'} /> {connectedFile.fileId}
                  </>
                );
              }
            }
          })
        );
        const filesToSet = [];
        const errorsToSet = [];
        fileResponses.forEach((file) => {
          if (file.id) filesToSet.push(file);
          else errorsToSet.push(file);
        });

        setFiles(filesToSet);
        setFilesError(errorsToSet);
      };
      fetchData();
    } else {
      api
        .get(
          fileQuery(null, resourceId, resourceType, null, `&fetchType=${fetchType}&fetchLimit=${fetchLimit}`)
        )
        .then((result) => result && result.data && setFiles(result.data))
        .catch((err) => setFilesError(JSON.stringify(err.response)));
    }
  }, [resourceId, resourceType, fetchType, fetchLimit, connectedFiles, tableType]);

  if (!files) {
    return (
      <Card title="" className="card">
        <Spin className="loader-container" />
      </Card>
    );
  }

  const onFileUpload = (newFiles) => {
    setFiles([...files, ...newFiles]);
    if (onConnectFiles) {
      connectFiles(newFiles, 'new');
    }
  };

  const minified = tableType === 'latestFiles';

  const columnSettings = {
    actionDisconnect: !!onDisconnectFile,
    actionConnect: !!onConnectFiles,
    actionDelete: tableType === 'normal',
    buttonType: tableType === 'connectTable' ? 'icon' : 'button',
    buttonSize: tableType === 'connectTable' || minified ? 'small' : 'default',
    lessColumns: tableType === 'latestFiles',
    buttonViewType: tableType !== 'connectTable' ? 'default' : 'default',
    buttonDownloadType: tableType !== 'connectTable' ? 'default' : 'default',
    hideNewUpload: hideNewUpload,
    tableEmptyText:
      tableType === 'listConnected'
        ? formatMessage('app.file.connectedFilesMissing')
        : formatMessage('app.file.filesMissing'),
  };
  if (tableType === 'connectTable' && resourceType === 'Document') {
    columnSettings.showUploadFromStart = true;
  }
  if (!hideNewUpload && tableType !== 'connectTable' && files.length === 0) {
    columnSettings.showUploadFromStart = true;
    columnSettings.hideTable = true;
  }

  const tableConfig = minified
    ? {
        ...defaultTableConfig,
        pagination: { position: 'none' },
        size: 'small',
        showHeader: false,
      }
    : defaultTableConfig;

  // Since the filelist can list both files on a resource, and files on a children (ie project/document), we need to get the correct resourceid/type
  const getResource = (record) => {
    if (record.SuperfileRelation) {
      return [record.SuperfileRelation.resourceId, record.SuperfileRelation.resourceType];
    }
    return [resourceId, resourceType];
  };

  const downloadFile = async (record) => {
    const fileId = record.id;
    const [downloadResourceId, downloadResourceType] = getResource(record);
    try {
      const response = await api.get(
        fileQuery(fileId, downloadResourceId, downloadResourceType, '/info', '&fetchType=download')
      );
      window.open(response.data.downloadUrl, '_blank');
    } catch (err) {
      console.log(err);
      message.error(formatMessage('app.file.errors.download'));
    }
  };

  const viewFile = async (record) => {
    const fileId = record.id;
    const [viewResourceId, viewResourceType] = getResource(record);
    setViewFileCurrent({ fileId, resourceId: viewResourceId, resourceType: viewResourceType });
  };

  const deleteFile = async (record) => {
    const fileId = record.id;
    const [deleteResourceId, deleteResourceType] = getResource(record);

    try {
      await api.delete(fileQuery(fileId, deleteResourceId, deleteResourceType));
      setFiles([...files.filter((file) => file.id !== fileId)]);
      // TODO: If we are on a version, remove any block references. Look at src/routes/project/Version/Files/index.js
    } catch (err) {
      console.log(err);
      message.error(formatMessage('app.file.errors.delete'));
    }
  };

  const connectInfo = (record) => {
    if (record.status === 'missing') return record; // If status is missing, it means that we're trying to disconnect a "free roaming" file, and the information needed is in the record itself.
    const [connectResourceId, connectResourceType] = getResource(record);
    return {
      fileId: record.id,
      resourceId: connectResourceId,
      resourceType: connectResourceType,
    };
  };

  const connectFiles = (records, connectType) => {
    const connectFileInfoes = records.map(connectInfo);
    onConnectFiles(connectFileInfoes);

    // Remove connected files from files list
    setFiles([
      ...files.filter((file) => {
        const [resId, resType] = getResource(file);

        return !connectFileInfoes.find(
          (cFile) => cFile.fileId === file.id && cFile.resourceId === resId && cFile.resourceType === resType
        );
      }),
    ]);
    const message = {
      existing: `${formatMessage('app.file.connectedExisting')} ${formatMessage(
        `contract.content.types.${nodeType}`
      )}`,
      new: `${formatMessage('app.file.connectedNew')} ${formatMessage(`contract.content.types.${nodeType}`)}`,
    };
    notification.success({
      description: message[connectType],
    });
  };

  let docs = files;
  if (tableType === 'connectTable') {
    // Filter away already connected files
    docs = docs.filter((doc) => {
      const fileId = doc.id;
      const [resId, resType] = getResource(doc);

      return !connectedFiles.find(
        (file) => file.fileId === fileId && file.resourceId === resId && file.resourceType === resType
      );
    });
  }

  const disconnectFile = (record) => {
    onDisconnectFile(connectInfo(record));
    setFiles([...files.filter((file) => file.id !== record.id)]);
  };

  const columnDownload = () => {
    return (
      <Column
        title={<IntlMessages id={'app.general.Download'} />}
        key="download"
        render={(_text, record) => {
          if (record.status === 'missing') return null;
          return (
            <Button
              onClick={() => {
                downloadFile(record);
              }}
              className={columnSettings.buttonType === 'icon' ? 'm-0 p-0' : 'mt-0 m-0'}
              type={columnSettings.buttonDownloadType}
              size={columnSettings.buttonSize}
              icon={columnSettings.buttonType === 'icon' ? <DownloadOutlined /> : null}
            >
              {columnSettings.buttonType === 'button' && <IntlMessages id={'app.general.Download'} />}
            </Button>
          );
        }}
      />
    );
  };

  const columnView = () => {
    return (
      <Column
        title={<IntlMessages id={'app.general.View'} />}
        key="view"
        render={(_text, record) => {
          if (record.status === 'missing') return null;
          return (
            <Button
              onClick={() => {
                viewFile(record);
              }}
              className={columnSettings.buttonType === 'icon' ? 'm-0 p-0' : 'mt-0 m-0'}
              type={columnSettings.buttonViewType}
              size={columnSettings.buttonSize}
              icon={columnSettings.buttonType === 'icon' ? <EyeOutlined /> : null}
            >
              {columnSettings.buttonType === 'button' && <IntlMessages id={'app.general.View'} />}
            </Button>
          );
        }}
      />
    );
  };

  const columnConnect = () => {
    if (!columnSettings.actionConnect) return null;
    return (
      <Column
        title={<IntlMessages id="app.file.connect" />}
        key="connect"
        render={(_text, record) => {
          return (
            <Button
              onClick={() => {
                connectFiles([record], 'existing');
              }}
              className={columnSettings.buttonType === 'icon' ? 'm-0 p-0' : 'mt-4'}
              type="primary"
              size={columnSettings.buttonSize}
              icon={columnSettings.buttonType === 'icon' ? <LinkOutlined /> : null}
            >
              {columnSettings.buttonType === 'button' && <IntlMessages id="app.file.connect" />}
            </Button>
          );
        }}
      />
    );
  };

  const renderViewFileModal = () => {
    if (!viewFileCurrent) return null;
    return (
      <ViewFile
        fileId={viewFileCurrent.fileId}
        resourceId={viewFileCurrent.resourceId}
        resourceType={viewFileCurrent.resourceType}
        onClose={() => setViewFileCurrent(null)}
      />
    );
  };

  const tableContent = () => {
    return (
      <>
        {renderViewFileModal()}
        {!columnSettings.hideNewUpload && (
          <NewFileUpload
            resourceType={resourceType}
            resourceId={resourceId}
            onFileUpload={onFileUpload}
            showFromStart={columnSettings.showUploadFromStart}
            keepModalAfterUpload={keepModalAfterUpload}
          />
        )}
        {!columnSettings.hideTable && (
          <>
            <Table
              rowKey="id"
              locale={{ emptyText: columnSettings.tableEmptyText }}
              className="table-responsive documents-table"
              {...tableConfig}
              dataSource={docs}
            >
              <ColumnGroup title={null}>
                <Column
                  title={<IntlMessages id="app.general.FileName" />}
                  dataIndex="fileName"
                  key="fileName"
                />
                {!columnSettings.lessColumns && (
                  <Column
                    title={<IntlMessages id="app.general.Created" />}
                    dataIndex="createdAt"
                    key="createdAt"
                    render={(text) => {
                      if (!text) return null;
                      return <small>{fixDate(text)}</small>;
                    }}
                  />
                )}
                {!columnSettings.lessColumns && (
                  <Column
                    title={<IntlMessages id={'app.general.Status'} />}
                    dataIndex="status"
                    key="status"
                    render={(text) => {
                      if (!text) return null;
                      const color = (() => {
                        switch (text) {
                          case 'confirmed':
                            return 'green';
                          case 'pending':
                            return 'orange';
                          case 'missing':
                            return 'red';
                          default:
                            return 'red';
                        }
                      })();
                      return (
                        <Badge text={text && <IntlMessages id={`app.file.status.${text}`} />} color={color} />
                      );
                    }}
                  />
                )}
                {columnDownload()}
                {columnView()}
                {columnConnect()}
                {!columnSettings.lessColumns &&
                  (columnSettings.actionDelete || columnSettings.actionDisconnect) && (
                    <Column
                      title={<IntlMessages id={'app.general.Actions'} />}
                      key="actions"
                      render={(text, record) => (
                        <span>
                          <Dropdown
                            overlay={
                              <Menu>
                                {columnSettings.actionDelete && (
                                  <Menu.Item>
                                    <Popconfirm
                                      placement="top"
                                      title={<IntlMessages id={'app.file.removeConfirm'} />}
                                      onConfirm={() => {
                                        deleteFile(record);
                                      }}
                                      okText={<IntlMessages id="desc.Yes" />}
                                      cancelText={<IntlMessages id="desc.No" />}
                                    >
                                      <span className="">
                                        <IntlMessages id={'app.file.removeFile'} />
                                      </span>
                                    </Popconfirm>
                                  </Menu.Item>
                                )}
                                {columnSettings.actionDisconnect && (
                                  <Menu.Item>
                                    <Popconfirm
                                      placement="top"
                                      title={<IntlMessages id={'app.file.disconnectConfirm'} />}
                                      onConfirm={() => {
                                        disconnectFile(record);
                                      }}
                                      okText={<IntlMessages id="desc.Yes" />}
                                      cancelText={<IntlMessages id="desc.No" />}
                                    >
                                      <span className="">
                                        <IntlMessages id={'app.file.disconnectFile'} />
                                      </span>
                                    </Popconfirm>
                                  </Menu.Item>
                                )}
                              </Menu>
                            }
                          >
                            <span className="link ant-dropdown-link">
                              <SettingFilled />
                              {/* <IntlMessages id={'app.general.Actions'} /> */} <DownOutlined />
                            </span>
                          </Dropdown>
                        </span>
                      )}
                    />
                  )}
              </ColumnGroup>
            </Table>
          </>
        )}
      </>
    );
  };

  const widgetContent = () => {
    return (
      <>
        {renderViewFileModal()}
        {!columnSettings.hideNewUpload && (
          <NewFileUpload
            resourceType={resourceType}
            resourceId={resourceId}
            onFileUpload={onFileUpload}
            showFromStart={columnSettings.showUploadFromStart}
          />
        )}
        {docs &&
          docs.map((doc) => (
            <Col xl={8} lg={8} md={8} sm={8} xs={8} key={doc.id}>
              <div className="a-recent-document mb-4">
                <IconCardActions
                  mdi
                  // icon={"file-image"}
                  icon={FILE_TYPE_ICONS[doc.extension] || FILE_TYPE_ICONS.__default}
                  // bottomText={<small>{""}</small>}
                  topAction={{
                    onClick: () => downloadFile(doc),
                    text: <DownloadOutlined />,
                    tooltip: 'Download File',
                  }}
                  bottomAction={{
                    onClick: () => viewFile(doc),
                    text: <i className="icon icon-eye" />,
                    tooltip: 'View File',
                    tooltipPlacement: 'bottom',
                  }}
                  afterText={doc.fileName}
                />
              </div>
            </Col>
          ))}
        {(!docs || docs.length === 0) && (
          <div className="p-4">
            <IntlMessages id="app.file.filesMissing" cap />
          </div>
        )}
      </>
    );
  };

  const renderErrors = () => {
    return (
      <>
        {filesError && filesError.length > 0 && (
          <Card title="" className="card">
            {filesError.map((err) => (
              <div>{err}</div>
            ))}
          </Card>
        )}
      </>
    );
  };

  const content = (
    <>
      {widgets ? widgetContent() : tableContent()}
      {renderErrors()}
    </>
  );

  return !hideCard ? (
    <Card
      className={cardClassName || ''}
      title={
        <div style={{ display: 'inline-block' }}>
          <IntlMessages id="app.file.files" />
        </div>
      }
    >
      {content}
    </Card>
  ) : (
    content
  );
}
