

export const updateLoadedContract = (contract) => {
  contract.data.ui.routes = moderniseRoutes(makeDraftBaseRoute(contract.id), contract.data.ui.routes);

  return contract;
  
};
export const updateNewContract = (contract) => {
  contract.data.ui.routes = moderniseRoutes(makeDraftBaseRoute(contract.id), contract.data.ui.routes);
  return contract
}


function makeDraftBaseRoute(versionId) {
  return "/studio/draft/" + versionId;
}
function moderniseRoutes(baseRoute, routes) {
  if (!routes) return [];
  return routes.map((route) => ({
    ...route,
    fullPath: baseRoute + "/" + route.paths.join("/"),
    children: moderniseRoutes(baseRoute, route.children),
  }));
}

