import React from 'react';
import { Button, Tooltip } from 'antd';
import { setEventState } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import { PlusSquareOutlined, EditOutlined } from '@ant-design/icons';

export default function TemplatePageActions() {

  const openInputSectionMenu = (type, method, selected = null, disableModal = false) => {
    setEventState('template_edit_input_sections', { type, method, selected, disableModal });
  };

  return (
    <div className="template-topbar-actions">
      <Tooltip title={<IntlMessages id="studio.template.modal.card.add" />} placement="left">
        <Button
          shape="circle"
          icon={<PlusSquareOutlined />}
          onClick={() => openInputSectionMenu('card', 'add')}
          className="m-0 mb-1"
        />
      </Tooltip>
      <Tooltip title={<IntlMessages id="studio.template.modal.page.edit" />} placement="left">
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => openInputSectionMenu('page', 'edit', { editPage: true })}
          className="m-0 mb-1"
        />
      </Tooltip>
    </div>
  );
}
