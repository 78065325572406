import { useDispatch, useSelector } from 'react-redux';
import { fixInput, setNodes, setLegalPersons, setRealPersons } from 'appRedux/actions';
import { diffString, diff } from 'json-diff';
import manager from 'core/engine/manager';
import { firstPath } from 'utils/draftPath';
import api from 'utils/api';
import { DocumentTemplate, Contract } from 'core/interfaces';
import { updateLoadedContract } from 'core/utils/initDraft/updateContract';
import { generateTemplateInputState } from './helpers/';
import { mapLegalPersons } from 'core/utils/initDraft/mapLegalPersons';
import { setDraft } from 'appRedux/actions';
import uuid from 'uuid-random';
import { useIntlMessage } from 'util/IntlMessages';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

import { generateStateValues } from 'core/utils/initDraft/generateStateValues';

export default function useStudioLoad(subject) {
  const dispatch = useDispatch();
  const currentWarnings = useSelector(({ draft }) => draft.warnings);
  const formatMessage = useIntlMessage();
  const history = useHistory();

  return async function loadResource(id) {
    console.log('=== BEGIN LOAD ===');

    let resource;
    if (subject === 'template') {
      try {
        resource = await api.get('/documenttemplates/' + id);
        resource = DocumentTemplate.ensureRoutes(resource.data);
      } catch (err) {
        console.log('Could not find id ', id, err, err.response);
        return null;
      }
    } else {
      try {
        resource = await api.get('/versions/' + id + '?withExternal=true');
        resource = resource.data;
        resource = await updateLoadedContract(resource);

        if (resource.status === 'signed') {
          notification.error({
            message: formatMessage('studio.warnings.editSignedVersion'),
            duration: 10,
          });
          history.push(
            '/project/' +
              resource.projectId +
              '/document/' +
              resource.documentId +
              '/version/' +
              resource.id +
              '/overview'
          );
          return;
        }
      } catch (err) {
        console.log('Could not find id ', id, err, err.response);
        return null;
      }
    }

    if (!validateResource(resource)) return console.log('Cannot load version');

    let state = Contract.getFullState(resource);

    if (subject !== 'template' && !state) {
      // Something is seriously wrong with this contract.
      return console.log('resource is invalid', resource);
    }

    if (subject === 'draft') {
      // Get the company structure based on the customer id
      const customerId = resource.info?.entities?.customerId;

      // Lets compare the structure on the contract, with the latest structure for the legal person
      // What if we don't have topLegalPersonId ? Get customer first so we get legalperson?
      if (customerId) {
        const structureResponse = await api.get(`/customers/${customerId}/structure`);
        if (structureResponse && structureResponse.data && state.legalPersons) {
          const latestStructure = mapLegalPersons(structureResponse.data);
          const currentStructure = mapLegalPersons(state.legalPersons);

          // console.log('currentStructure', currentStructure);
          // console.log('latestStructure', latestStructure);

          const diffList = [];
          // Loop through current structure and check the diff against the latest
          const idsChecked = [];
          currentStructure.forEach((currentNode) => {
            idsChecked.push(currentNode.id);
            const latestNode = latestStructure.find((n) => n.id === currentNode.id);
            if (!latestNode) diffList.push({ type: 'removed', currentNode });
            else {
              const difference = diff(currentNode, latestNode);
              if (difference) {
                diffList.push({ type: 'changed', currentNode, latestNode, difference });
              }
            }
          });

          latestStructure
            .filter((n) => !idsChecked.includes(n.id))
            .forEach((latestNode) => {
              diffList.push({ type: 'added', latestNode });
            });

          // Other warnings can be added here too.
          if (diffList.length > 0) {
            const newWarningList = currentWarnings ? currentWarnings : [];
            newWarningList.push({
              id: uuid(), // We need a unique id to be able to clear warnings in the state later on
              type: 'outdatedStructure',
              data: {
                diffList,
                latestStructure,
              },
              important: true,
              handled: false,
              text: formatMessage('studio.warnings.structure.header'),
            });
            dispatch(setDraft('warnings', newWarningList));
            if (newWarningList.filter((warning) => warning.important && !warning.handled).length > 0) {
              notification.warn({
                message: formatMessage('studio.warnings.notification.header'),
                description: formatMessage('studio.warnings.notification.description'),
                duration: 10,
              });
            }
          }
        }
      }
    }

    manager.clearDrafts();

    if (subject === 'template') {
      const templateInputState = generateTemplateInputState(resource);
      dispatch(fixInput(templateInputState));
    } else {
      if (state.input) dispatch(fixInput(state.input));
      if (state.legalPersons) dispatch(setLegalPersons(state.legalPersons));
      if (state.realPersons) dispatch(setRealPersons(state.realPersons));
    }

    if (subject !== 'template') setupManager(resource, subject);
    if (subject !== 'template') await manager.loadEngines(state);

    console.log('=== FINISHED LOAD ===');

    return {
      firstPath: firstPath(Contract.getUiRoutes(resource)),
      contract: resource,
    };
  };
}

function setupManager(resource, subject) {
  const drafts = [resource];

  if (subject === 'template') {
    manager.addTemplateDrafts(drafts);
  } else {
    manager.loadDrafts(drafts);
  }
}

function validateResource(resource) {
  if (!resource || !resource.data) {
    return console.warn('Invalid loadresource args', resource);
  }
  return true;
}
