const fieldNamesConcerned = ['party/definition'];
const entityPropertiesConcerned = ['name'];
export const signingPagePartiesModule = {
  id: 'signingPagePartiesModule',
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true, legalPersonAny: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    let shallReGenerate = false;
    const concepts = api.interfaces.Contract.getConcepts(this.contract);

    // entriesLoop:
    for (const entry of entries) {
      const { cardId, fieldName, path, pathInvoked } = entry;
      // this.log('entry..', entry);

      if (pathInvoked.repeatableChange) {
        if (path.endsWith('id') || fieldNamesConcerned.includes(fieldName)) {
          shallReGenerate = true;
          break;
        }
      } else if (pathInvoked.legalPersonAny) {
        const propertyConcerned = api.interfaces.InputPaths.getLast(path);
        if (entityPropertiesConcerned.includes(propertyConcerned)) {
          this.log('node prop relevant');
          shallReGenerate = true;
          break;
        }
      } else {
        const matchingConcepts = concepts.filter((c) => c.stateId === cardId);
        if (matchingConcepts.length > 0 && matchingConcepts.some((mc) => mc.contractParty)) {
          shallReGenerate = true;
          break;
        }
      }
    }

    if (!shallReGenerate) return;

    const nameDescription = api.utils.general.ucfirst(this.translateText('name')) + ':';

    // this.log('Shall regenerate signing page');

    return (node, parents) => {
      if (!api.utils.engine.matchModule(node, 'facilityAgreement', 'signingPageParties')) return;

      if (!this.getContractCreate().build || !this.getContractCreate().build.concepts) return;
      const contractPartyConcepts = this.getContractCreate().build.concepts.filter(
        (concept) => concept.contractParty
      );

      const content = [];
      let i = 0;
      for (const contractPartyConcept of contractPartyConcepts) {
        const { id, stateKey } = contractPartyConcept;

        const conceptState = api.interfaces.Concept.getConceptState(
          contractPartyConcept,
          this.contract,
          state
        );
        if (api.utils.general.ocount(conceptState) === 0) continue;

        const { text: conceptDescription } =
          api.interfaces.Concept.describe('the_' + id, state.input, this.language, {
            contract: this.contract,
          }) || '[**]';

        if (i !== 0) content.push(this.makeParagraph(''));
        content.push(
          this.makeParagraph([this.makeTextNode(api.utils.general.ucfirst(conceptDescription), 'bold')])
        );

        for (const [, party] of Object.entries(conceptState)) {
          if (typeof party !== 'object' || party === null) continue;
          // const partyName = party[stateKey] || this.emptyContractString();

          const stateKeyData = party[stateKey];
          let entity, partyName;

          if (!stateKeyData) partyName = this.emptyContractString();
          else if (typeof stateKeyData === 'string') partyName = stateKeyData;
          else if (typeof stateKeyData === 'object') {
            entity = api.interfaces.Entity.getById(state, stateKeyData.id, stateKeyData.type);
            if (!entity) {
              this.log('Cannot locate party entity', { party });
              continue;
            }
            // partyName = entity.name
            partyName = this.api.utils.general.userToName(entity);
          }

          content.push(this.makeParagraph([this.makeTextNode(partyName, 'bold')]));

          // makeTable([['hej', 'happ']])
          const tabl = this.makeTable(
            [
              ['_________________', '_________________'],
              [nameDescription, nameDescription],
            ],
            {
              _format: { border: 0 },
            }
          );
          content.push(tabl);
          // content.push(this.makeParagraph([this.makeTextNode('___________________')]));
          i++;
        }
      }

      node.children = content;

      this.markNodeUpdate(node);
    };
  },
};
