import { Concept, Contract, Entity } from '../../../interfaces';

export const describeConcept = function (name) {
  if (!this.states || !this.states.current || !this.states.current.input) return {};
  return Concept.describe(name, this.states.current.input, this.language, {
    contract: this.contract,
  });
};

export const companyDefinition = function (nameOrId) {
  const contract = this.contract;
  const concepts = Contract.getConcepts(contract);
  const state = this.getCurrentState();

  if (!nameOrId || !state || !concepts) {
    return {};
  }
  let companyName;

  for (const concept of concepts) {
    const { id, definitionKey } = concept;
    const conceptEntries = Concept.getConceptEntryNames(concept, contract, state);
    const conceptOne = conceptEntries.length === 1;
    for (const entry of conceptEntries) {
      const { id, name, state: entryState } = entry;
      if (id !== nameOrId && name !== nameOrId) continue;
      if (definitionKey && entryState[definitionKey]) {
        return entryState[definitionKey];
      } else if (conceptOne) {
        const described = this.describeConcept('the_' + id);
        if (described.status.status === '_success_') {
          return described.value;
        }
      } else {
        companyName = name;
      }
    }
  }

  if (!companyName) {
    const entity = Entity.getById(state, nameOrId)
    if (entity && entity.name) companyName = entity.name
    else companyName = nameOrId
  }

  return companyName;
};
