const Entity = {};
const baseStateNames = ['legalPersons', 'realPersons'];

Entity.getBase = function (state, type) {
  let base;
  if (type) {
    base = state[type] || state[type + 's'];
  }

  if (!base) {
    base = Object.keys(state).reduce((acc, curr) => {
      if (baseStateNames.indexOf(curr) > -1) {
        acc = acc.concat(state[curr]);
      }
      return acc;
    }, []);
  }
  return base || [];
};

// A customer may have multiple LegalPersons pointing to it
// (if the customer is a legal person or a group of legal persons),
// or a single RealPerson pointing at it.
Entity.getRelevantPersonFromCustomer = function (customer) {
  if (Array.isArray(customer.RealPersons) && customer.RealPersons.length === 1) {
    return {
      person: customer.RealPersons[0],
      type: 'realPerson',
    };
  } else if (Array.isArray(customer.LegalPersons) && customer.LegalPersons.length > 0) {
    return {
      person: customer.LegalPersons.find((lp) => lp.isTopCo) || null,
      type: 'legalPerson',
    };
  }
  return {
    person: null,
    type: null
  }
};

Entity.getType = function (person) {
  if (!Entity.isEntity(person)) return null;
  if (person.hasOwnProperty('firstName') && person.hasOwnProperty('lastName')) return 'realPerson';
  return 'legalPerson';
};

Entity.isEntity = function (person) {
  return (
    person &&
    typeof person === 'object' &&
    person.hasOwnProperty('id') &&
    person.hasOwnProperty('identificationNumber') &&
    person.hasOwnProperty('jurisdiction')
  );
};

Entity.getById = function (state, id, type) {
  return Entity.getBase(state, type).find((x) => x.id === id);
};

Entity.getByKeyObject = function (state, id, key, type) {
  if (!id || !key) return null;
  const base = Entity.getBase(state, type);
  const entity = base.find((item) => item.id === id);
  if (!entity) return null;

  return entity[key];
};

export { Entity };
