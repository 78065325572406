export const initSmartDefinitions = {
  id: 'initSmartDefinitions',
  dependencies: { onGenesis: true },
  handler: function ({ state, api }) {
    if (!this.getContractCreate().build || !this.getContractCreate().build.smartDefinitions) return;

    const definitions = this.getContractCreate().build.smartDefinitions.filter(
      (item) => item && item.xcontent && !(item.xcontent.data && item.xcontent.data.each_repeatable)
    );

    let definitionWasInserted = false;

    return (node, parents) => {
      if (node.type !== 'definitions' || !node.data || node.data.definitions_id !== 'mainDefinitions') return;

      for (const definition of definitions) {
        const content = JSON.parse(JSON.stringify(definition.xcontent));

        if (content && content.data && content.data.acp) {
          if (!this.applyLogic(definition.rule, { state })) content.data._inActive = true;
        }

        const def = api.utils.engine.uniqueItemIds(content);

        def.data.isSmartDefinition = true;
        def.data.isDefKey = true;

        definitionWasInserted = true;
        node.children.push(def);
      }
      if (definitionWasInserted) {
        this.sortDefinitions();
      }
    };
  },
};
