export const eachesHandleItemVariants = {
  id: 'eachesHandleItemVariants',
  dependencies: { repeatableChange: true },
  handler: function ({ state, handlerInvoked, paths, entries, api }) {
    // Collect general values for each path, assigned to an
    // object (generalValues) with paths as keys.
    const generalValues = entries.reduce((acc, { path, value }) => {
      acc[path] = this.getGeneralValues(path, value);
      return acc;
    }, {});

    return (node) => {
      if (node.variant !== 'item') return;
      for (const { path } of entries) {
        const nodeMatchesPath = node.data && node.data.each_item_path === path;
        const nodeMatchesExtraPath =
          Array.isArray(node.data.each_item_extra_paths) && node.data.each_item_extra_paths.includes(path);
        const nodeHasTransforms =
          Array.isArray(node.data.each && node.data.each.transforms) && node.data.each.transforms.length > 0;

        if (!nodeMatchesPath && !nodeMatchesExtraPath) continue;

        const values = generalValues[path];
        const nodeValues = this.getNodeValues(node, values);
        let value = this.getFinalValue(nodeValues);
        if (typeof window !== 'undefined' && window.debug)
          this.log('set each item value ', { node: JSON.parse(JSON.stringify(node)), value });

        if (nodeHasTransforms) {
          const parentData = api.utils.general.getByPath(state, api.interfaces.InputPaths.parentPath(path));
          if (parentData) {
            for (const transform of node.data.each.transforms) {
              value = transformValue(value, transform, state, parentData, this);
            }
          }
        }
        if (nodeMatchesExtraPath) {
          if (!nodeHasTransforms || !node.data.each_item_path) return this.log('Matching error.');
          value = api.utils.general.getByPath(state, node.data.each_item_path);
          const parentData = api.utils.general.getByPath(state, api.interfaces.InputPaths.parentPath(path));
          if (!parentData) return;
          for (const transform of node.data.each.transforms) {
            value = transformValue(value, transform, state, parentData, this);
          }
        }

        node.data.value = value;
        api.utils.engine.setFirstChild(node, value);
        this.markNodeUpdate(node);
      }
    };
  },
};

function transformValue(value, transform, state, parentData, engine) {
  const { key, where, op } = transform;
  let otherValue;
  if (where === '$_local_$') {
    otherValue = parentData[key];
  } else {
    otherValue = engine.api.utils.general.getByPath(state, key);
  }
  if (!otherValue) {
    // this.log('Could not find other value for transform.')
    return;
  }

  if (!isNaN(parseInt(value)) && !isNaN(parseInt(otherValue))) {
    value = parseInt(engine.combineNumber(value));
    otherValue = parseInt(engine.combineNumber(otherValue));

    switch (op) {
      case 'plus':
        return engine.splitNumber(value + otherValue);
      case 'minus':
        return engine.splitNumber(value - otherValue);
      case 'minusReverse':
        return engine.splitNumber(otherValue - value);
      case 'divide':
        return engine.splitNumber(value / otherValue);
      case 'divideReverse':
        return engine.splitNumber(otherValue / value);
      case 'multiply':
        return engine.splitNumber(value * otherValue);
      default:
        return engine.splitNumber(value);
    }
  } else if (op === 'plus') {
    return value + otherValue;
  } else if (op === 'plusReverse') {
    return otherValue + value;
  }

  // console.log('Transform with ', { value, transform, state, parentData });
}
