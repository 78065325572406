/*************************** IMPORTS ******************************/
/******************************************************************/
import enhanceEngine from './enhanceEngine';

enhanceEngine(Engine);

/*************************** INSTANCE ******************************/
/*******************************************************************/
function Engine(contract, options = {}) {
  if (!contract) return console.warn('No contract to engine', contract);

  this.setupApi();
  this.setupVariables(contract, options);
  this.setupHandlers(Engine);
}

/*************************** STATUS ******************************/
/*****************************************************************/
Engine.prototype.isInitialized = function () {
  const draftInfo = this.getDraftInfo()
  return draftInfo.status && draftInfo.status.initialized
};

export default Engine;
