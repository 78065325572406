import React from 'react';
import { Popconfirm, Button, Table } from 'antd';
import { Contract } from 'core/interfaces';
import AddEditInput from './AddEditInput';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { useModalContent } from 'components/ui/modals';

export default function Setup({ contract, setupState, setSetupState }) {
  const setup = setupState || Contract.getCreate(contract).setup;
  const contractLanguauge = contract.data.settings.language;
  const formatMessage = useIntlMessage();

  const onInputUpdate = () => {};

  const deleteField = (id) => {
    setSetupState(setupState.filter(s => s.id !== id));
  };

  const listInputs = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Label',
      dataIndex: 'label',
      render: (text, record) => text[contractLanguauge],
    },
    {
      title: 'Field type',
      dataIndex: 'type',
      render: (text, record) => {
        return formatMessage(`studio.template.inputs.types.${text}`);
      },
    },
    {
      title: 'Action',
      render: (text, record) => {
        console.log('record: ', record);
        return (
          <>
            <InputModal
              contract={contract}
              selected={{ input: record.id }}
              setupState={setupState}
              setSetupState={setSetupState}
              onInputUpdate={onInputUpdate}
            >
              Edit field
            </InputModal>
            <Popconfirm
              placement="top"
              title={<IntlMessages id="studio.sidebar.settings.generalSettings.setupFields.confirmDelete" />}
              onConfirm={() => {
                deleteField(record.id);
              }}
              okText={<IntlMessages id="desc.Yes" />}
              cancelText={<IntlMessages id="desc.No" />}
            >
              <Button type="danger">Delete field</Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        bordered={false}
        columns={listInputs}
        dataSource={setup}
        title={() => (
          <div className="d-flex justify-content-space-between">
            <div>Setup fields</div>
            <div className="d-flex">
              <InputModal
                contract={contract}
                selected={{ input: null }}
                setupState={setupState}
                setSetupState={setSetupState}
                onInputUpdate={onInputUpdate}
              >
                Add field
              </InputModal>
            </div>
          </div>
        )}
        rowKey="id"
      />
    </>
  );
}

const InputModal = ({ contract, selected, children, setupState, setSetupState, onInputUpdate }) => {
  const [Modal, toggleModal, , open] = useModalContent('Setup inputs', {
    defaultOpen: false,
  });

  const onFinish = () => {
    toggleModal();
    onInputUpdate();
  };

  return (
    <>
      {open && (
        <Modal size="lg" /* className="modal-lg" */ width={1000}>
          <AddEditInput
            contract={contract}
            selected={selected}
            onFinish={onFinish}
            setupState={setupState}
            setSetupState={setSetupState}
          ></AddEditInput>
        </Modal>
      )}
      <Button onClick={toggleModal} type="primary">
        {children}
      </Button>
    </>
  );
};

// setup: [
//   {
//     type: 'radio',
//     id: 'financingType',
//     label: {
//       sv: 'Finansieringstyp',
//       en: 'Financing type',
//     },
//     required: true,
//     options: [
//       {
//         id: 'propertyFinancing',
//         label: {
//           sv: 'Fastighetsfinansiering',
//           en: 'Property financing',
//         },
//       },
//       {
//         id: 'corporateFinancing',
//         label: {
//           sv: 'Corporate',
//           en: 'Corporate',
//         },
//       },
//     ],
//     value: 'propertyFinancing',
//   },
//   {
//     id: 'lender',
//     type: 'select',
//     label: {
//       sv: 'Långivare',
//       en: 'Lender',
//     },
//     required: false,
//     options: [
//       {
//         id: 'shb',
//         label: {
//           sv: 'Svenska Handelsbanken AB (publ)',
//           en: 'Svenska Handelsbanken AB (publ)',
//         },
//       },
//       {
//         id: 'hs',
//         label: {
//           sv: 'Stadshypotek AB (publ)',
//           en: 'Stadshypotek AB (publ)',
//         },
//       },
//     ],
//     value: 'shb',
//   },
//   {
//     type: 'radio',
//     id: 'language',
//     label: {
//       sv: 'Språk',
//       en: 'Language',
//     },
//     required: true,
//     options: [
//       {
//         id: 'sv',
//         label: {
//           sv: 'Svenska',
//           en: 'Swedish',
//         },
//       },
//       {
//         id: 'en',
//         label: {
//           sv: 'Engelska',
//           en: 'English',
//         },
//       },
//     ],
//     value: 'sv',
//   },
//   {
//     type: 'radio',
//     id: 'basecurrency',
//     label: {
//       sv: 'Basvaluta',
//       en: 'Base currency',
//     },
//     required: true,
//     options: [
//       {
//         id: 'SEK',
//         label: {
//           sv: 'SEK',
//           en: 'SEK',
//         },
//       },
//       {
//         id: 'EUR',
//         label: {
//           sv: 'EUR',
//           en: 'EUR',
//         },
//       },
//       {
//         id: 'USD',
//         label: {
//           sv: 'USD',
//           en: 'USD',
//         },
//       },
//     ],
//   },
// ],
// },
