import React, { useState, useMemo, memo } from "react";
import { Editor, Transforms, Path, Node } from "slate";
import { ReactEditor, useSelected, useEditor } from "slate-react";
import { Row, Menu, Tooltip, Dropdown, Input, Button, Select } from "antd";
import { useModalContent } from "components/ui/modals";
import {
  removeBlockByPath,
  duplicateBlockByPath,
  liftClauseByPath,
  insertBlockByPath,
  toggleConditionBlockByPath,
} from "../../../../helpers";

const { Option } = Select;

export const ClauseIndicator = ({ editor, element }) => {
  const selected = useSelected();
  const stringPath = selected ? ReactEditor.findPath(editor, element).join(".") : "";
  const { type } = element;
  const path = useMemo(() => {
    return stringPath.split(".").map((item) => parseInt(item));
  }, [stringPath]);

  if (!selected) return null;

  return (
    <>
      <XClauseIndicator editor={editor} path={path} elementData={element.data} type={type} />
    </>
  );
};

export const XClauseIndicator = memo(({ editor, elementData, path }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenuOpen = () => setMenuOpen(!menuOpen);
  const moveClauseUp = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    try {
      let toPath;
      for (const [, nextPath] of Editor.nodes(editor, {
        at: Path.parent(path),
        match: (n) => n.type === "clause",
        reverse: true,
      })) {
        if (Path.isBefore(nextPath, path) && nextPath.length >= path.length) {
          toPath = nextPath;
          break;
        }
      }
      if (!toPath) {
        return console.log("No target found");
      }
      Transforms.moveNodes(editor, { at: path, to: toPath });
      // selectAndScroll(editor, toPath, element);
    } catch (err) {}
  };
  const moveClauseDown = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    try {
      let toPath;
      for (const [, nextPath] of Editor.nodes(editor, {
        at: Path.parent(path),
        match: (n) => n.type === "clause",
      })) {
        if (Path.isAfter(nextPath, path)) {
          toPath = nextPath;
          break;
        }
      }
      if (!toPath) {
        return console.log("No target found");
      }
      Transforms.moveNodes(editor, { at: path, to: toPath });
      // selectAndScroll(editor, toPath, element);
    } catch (err) {
      console.log("Could not move", err);
    }
  };

  const [Modal, toggleModal, setModalOpen, open] = useModalContent("Name clause", {
    defaultOpen: false,
  });
  // console.log('Render clause indi')
  return (
    <>
      {open && (
        <Modal>
          <NameClause
            editor={editor}
            elementData={elementData}
            path={path}
            toggleModal={toggleModal}
          />
        </Modal>
      )}
      <div className="clause-name" onMouseDown={() => setModalOpen(true)}>
        {(elementData && elementData.name) || elementData.label || "Clause X"}
      </div>
      <div className="clause-menu" contentEditable={false}>
        <Tooltip title="Clause Menu">
          <Dropdown
            overlay={ClauseMenu(editor, elementData, path, setMenuOpen)}
            trigger={["click"]}
            onVisibleChange={toggleMenuOpen}
            visible={menuOpen}
            overlayClassName="editor-clause-menu-overlay"
            placement="bottomRight"
          >
            <i
              className={"mdi mdi-dots-vertical"}
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
              }}
            />
          </Dropdown>
        </Tooltip>
      </div>
      <div className="clause-mover" contentEditable={false}>
        <i className="mdi mdi-arrow-up d-block" onClick={moveClauseUp} />
        <i className="mdi mdi-arrow-down d-block" onClick={moveClauseDown} />
      </div>
    </>
  );
});

function NameClause({ editor, elementData, path, toggleModal }) {
  const [name, setName] = useState(elementData.name);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const submitName = () => {
    if (!path) return;
    Transforms.setNodes(
      editor,
      {
        data: {
          ...elementData,
          name,
        },
      },
      {
        at: path,
      }
    );
    toggleModal();
  };

  return (
    <div>
      <Input type="text" value={name} onChange={handleNameChange} />
      <Button block type="primary" className="mt-3" onClick={submitName}>
        Change name
      </Button>
    </div>
  );
}

function ClauseMenu(editor, elementData, path, setMenuOpen) {
  // console.log('Clause Menu editor ', {editor})
  const [Modal, toggleModal, setModalOpen, open] = useModalContent("Name clause", {
    defaultOpen: false,
  });

  const openClauseEditModal = () => {
    setMenuOpen(false);
    setModalOpen(true);
  };

  return (
    <>
      {open && (
        <Modal>
          <EditClause
            editor={editor}
            elementData={elementData}
            path={path}
            toggleModal={toggleModal}
          />
        </Modal>
      )}
      <Menu>
        <Menu.Item key="0" onClick={openClauseEditModal}>
          <span>Edit Clause</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="1" onClick={() => duplicateBlockByPath(editor, path)}>
          <span>Duplicate Clause</span>
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            removeBlockByPath(editor, path);
          }}
        >
          Remove Clause
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            liftClauseByPath(editor, path);
          }}
        >
          Move up one Level
        </Menu.Item>
        <Menu.Divider />
        <Menu.SubMenu title="Insert" key={"inserts"}>
          <Menu.SubMenu title="Clause" key={"insert_clause"}>
            <Menu.Item
              key={"clause_within_top"}
              onClick={() => {
                insertBlockByPath(editor, path, "clause", {
                  mode: "within",
                  direction: "above",
                });
              }}
            >
              <span>Within Clause (at top)</span>
            </Menu.Item>
            <Menu.Item
              key={"clause_within_bottom"}
              onClick={() => {
                insertBlockByPath(editor, path, "clause", {
                  mode: "within",
                  direction: "below",
                });
              }}
            >
              <span>Within Clause (at bottom)</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key={"insert_clause_before"}
              onClick={() => {
                insertBlockByPath(editor, path, "clause", {
                  mode: "sibling",
                  direction: "above",
                });
              }}
            >
              <span>Before this Clause</span>
            </Menu.Item>
            <Menu.Item
              key={"insert_clause_after"}
              onClick={() => {
                insertBlockByPath(editor, path, "clause", {
                  mode: "sibling",
                  direction: "below",
                });
              }}
            >
              <span>After this Clause</span>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu title="Paragraph" key={"insert_paragraph"}>
            <Menu.Item
              key={"paragraph_within_top"}
              onClick={() => {
                insertBlockByPath(editor, path, "paragraph", {
                  mode: "within",
                  direction: "above",
                });
              }}
            >
              <span>Within Clause (at top)</span>
            </Menu.Item>
            <Menu.Item
              key={"paragraph_within_bottom"}
              onClick={() => {
                insertBlockByPath(editor, path, "paragraph", {
                  mode: "within",
                  direction: "below",
                });
              }}
            >
              <span>Within Clause (at bottom)</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key={"insert_paragraph_before"}
              onClick={() => {
                insertBlockByPath(editor, path, "paragraph", {
                  mode: "sibling",
                  direction: "above",
                });
              }}
            >
              <span>Before this Clause</span>
            </Menu.Item>
            <Menu.Item
              key={"insert_paragraph_after"}
              onClick={() => {
                insertBlockByPath(editor, path, "paragraph", {
                  mode: "sibling",
                  direction: "below",
                });
              }}
            >
              <span>After this Clause</span>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu.SubMenu>
        <ClauseACP path={path} />
      </Menu>
    </>
  );
}

function ClauseACP(props) {
  const editor = useEditor();
  const { path, ...rest } = props;
  const node = Node.get(editor, path);
  const hasAcp = !!node.data?.acp;
  const actionDescription = hasAcp ? "unconditioned" : "conditioned";
  return (
    <Menu.Item
      key="blockACP"
      onMouseDown={() => {
        toggleConditionBlockByPath(editor, path);
      }}
      {...rest}
    >
      <span>
        <i className="mdi mdi-close-box-outline mr-2" />
        Make Clause {actionDescription}
      </span>
    </Menu.Item>
  );
}

function EditClause({ editor, elementData, path, toggleModal }) {
  const [tags, setTags] = useState(Array.isArray(elementData.tags) ? elementData.tags : []);

  const children = tags.map((tag, i) => <Option key={i.toString(36) + i}>{tag}</Option>);

  function handleChange(value) {
    console.log("value: ", value);
    setTags(value);
  }

  const submitTags = () => {
    if (!path) return;
    Transforms.setNodes(
      editor,
      {
        data: {
          ...elementData,
          tags,
        },
      },
      {
        at: path,
      }
    );
    toggleModal();
  };

  return (
    <div>
      <Row>
        <div>
          <h4>Clause Contents</h4>
        </div>
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Tags Mode"
          onChange={handleChange}
        >
          {children}
        </Select>
      </Row>
      <Row>
        <Button block type="primary" className="mt-3 w-50" onClick={submitTags}>
          Done
        </Button>
        <Button block type="danger" className="mt-3 w-50" onClick={toggleModal}>
          Abort
        </Button>
      </Row>
    </div>
  );
}
/* 
const selectAndScroll = (editor, path, element) => {
  setImmediate(() => {
    const firstItem = Editor.first(editor, path)[1];
    console.log("first Item ", firstItem);
    Transforms.setSelection(editor, {
      anchor: { path: firstItem, offset: 0 },
      focus: { path: firstItem, offset: 0 },
    });
    editor.scrollToNode(element);
  });
};
 */
