import React, { useState } from 'react';
import { Row, Col, Spin, Table, Popconfirm, Button, Dropdown, Menu, Tooltip, Modal } from 'antd';
import { Link } from 'react-router-dom';
import NewProjectButton from 'components/project/NewProject/NewProjectButton';
import { useMountFetch, useSearch } from 'hooks';
import WidgetHeader from 'components/WidgetHeader/index';
import { fixDate } from 'components/ui';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import ProjectWidget from 'components/Widgets/ProjectWidget';
import { RemoveModal } from 'components/ui';
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';

const { Column, ColumnGroup } = Table;

function sortPersonalFirst(a, b) {
  if (a.isPersonal) {
    return -1;
  }
  if (b.isPersonal) return 1;
  return 0;
}

export default function Projects() {
  const [projects, setProjects] = useState(null);
  const [projectsError, setProjectsError] = useState('');
  const [viewMode, setViewMode] = useState('list');

  const setFetchedProjects = (fetchedProjects) => {
    if (!Array.isArray(fetchedProjects)) return;
    if (projects) return;
    setProjects(fetchedProjects.sort(sortPersonalFirst));
  };

  const projectWasCreated = (project) => {
    setProjects([project, ...projects]);
  };
  const projectWasRemoved = (id) => {
    setProjects([...projects.filter((project) => project.id !== id)]);
  };

  useMountFetch(
    '/projects?includePersonal&fields=id,name,description,isSecret,isPersonal,isFolder,createdAt&order=desc&where=isFolder_->_NULL-or-FALSE',
    setFetchedProjects,
    setProjectsError
  );

  const showList = () => setViewMode('list');
  const showGrid = () => setViewMode('grid');

  const [filteredProjects, searchElement] = useSearch(projects, {
    element: true,
    fields: ['name', 'description'],
  });

  return (
    <>
      <WidgetHeader
        title={<IntlMessages id="app.projects.Projects" />}
        styleName="mb-3"
        extra={
          <>
            <span className="mr-3">
              <Tooltip title={'Show folders as list'} placement="bottom">
                <Button
                  size="middle"
                  className={'m-0 ' + (viewMode === 'list' ? 'active' : '')}
                  type="ghost"
                  onClick={showList}
                >
                  <UnorderedListOutlined />
                </Button>
              </Tooltip>
              <Tooltip title={'Show folders as grid'} placement="bottom">
                <Button
                  size="middle"
                  className={'m-0 ml-3 ' + (viewMode === 'grid' ? 'active' : '')}
                  type="ghost"
                  onClick={showGrid}
                >
                  <AppstoreOutlined />
                </Button>
              </Tooltip>
            </span>
            {searchElement}
            <NewProjectButton projectWasCreated={projectWasCreated} />
          </>
        }
      />

      {!projects && (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Spin size="large" />
        </div>
      )}

      {projectsError && (
        <div className="d-flex align-items-center justify-content-center h-100">{projectsError}</div>
      )}

      {/*       {projects && !projectsError && (
        <div className="full-table-view transparent-th">
          <ProjectsTable projects={filteredProjects} projectWasRemoved={projectWasRemoved} type='project' />
        </div>
      )} */}
      {projects && !projectsError && viewMode === 'list' && (
        <div className="full-table-view transparent-th">
          <ProjectsTable projects={filteredProjects} projectWasRemoved={projectWasRemoved} type="project" />
        </div>
      )}
      {projects && !projectsError && viewMode === 'grid' && (
        <Row>
          {filteredProjects.map((pr) => (
            <Col xs={12} lg={6} key={pr.id}>
              <ProjectWidget key={pr.id} project={pr} projectWasRemoved={projectWasRemoved} type="project" />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}

const config = {
  bordered: false,
  loading: false,
  pagination: { position: 'bottom' },
  size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  scroll: undefined,
};

export function ProjectsTable({ projects, projectWasRemoved, type = 'project' }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selection) => {
    console.log('selection changed: ', selection);
    setSelectedRowKeys(selection);
  };

  const intTypeCap = type === 'folder' ? 'Folder' : 'Project';

  const rowSelection = null; /* {
    selectedRowKeys,
    onChange: onSelectChange,
  }; */

  const removeProject = (id) => {
    api
      .delete('/projects/' + id)
      .then((result) => {
        projectWasRemoved(id);
      })
      .catch((err) => {
        console.log('Error removing project ', id, err.response);
      });
  };

  return (
    <Table
      rowKey="id"
      className="table-responsive ml-3"
      {...config}
      // columns={columns}
      rowSelection={rowSelection}
      dataSource={projects}
    >
      <ColumnGroup title={null}>
        <Column
          title={<IntlMessages id="app.general.Name" />}
          dataIndex="name"
          key="name"
          render={(text, record) => (
            <Link
              to={{
                pathname: '/' + type + '/' + (record && record.id ? record.id : '_null'),
              }}
            >
              {text}
            </Link>
          )}
        />
        <Column
          title={<IntlMessages id="app.general.Description" />}
          dataIndex="description"
          key="description"
        />
        <Column
          title={<IntlMessages id="app.general.Created" />}
          dataIndex="createdAt"
          key="createdAt"
          render={(text) => fixDate(text)}
        />
        <Column
          title={<IntlMessages id="app.general.Action" />}
          key="actions"
          render={(text, record) => (
            <Button className="m-0">
              <Link
                to={{
                  pathname: '/' + type + '/' + (record && record.id ? record.id : '_null'),
                }}
              >
                <IntlMessages id={'app.main.goTo' + intTypeCap} />
              </Link>
            </Button>
          )}
        />
        <Column
          title={<IntlMessages id="app.general.More" />}
          dataIndex={null}
          key="menu"
          render={(text, record) => (
            <Dropdown
              overlay={
                <Menu>
                  {/*  <Menu.Item>
                    <Popconfirm
                      placement="top"
                      title={
                        <>
                          <IntlMessages id="app.general.confirmRemoval" />{' '}
                          <IntlMessages id={'app.resources.' + intTypeCap} />?
                        </>
                      }
                      onConfirm={() => {
                        removeProject(record.id);
                      }}
                      okText={<IntlMessages id="button.Yes" />}
                      cancelText={<IntlMessages id="button.No" />}
                    >
                      <span className="">
                        <IntlMessages id="desc.Remove" /> <IntlMessages id={'app.resources.' + intTypeCap} />
                      </span>
                    </Popconfirm>
                  </Menu.Item> */}
                  <Menu.Item>
                    <RemoveModal
                      onConfirm={() => removeProject(record.id)}
                      confirmText={
                        <>
                          <IntlMessages id="app.general.confirmRemoval" />{' '}
                          <IntlMessages id={'app.resources.' + intTypeCap} />?
                        </>
                      }
                    >
                      <span className="">
                        <IntlMessages id="desc.Remove" /> <IntlMessages id={'app.resources.' + intTypeCap} />
                      </span>
                    </RemoveModal>
                  </Menu.Item>
                </Menu>
              }
            >
              <span className="link ant-dropdown-link">
                <i className="icon-btn icon icon-ellipse-v" />
              </span>
            </Dropdown>
          )}
        />
      </ColumnGroup>
    </Table>
  );
}
