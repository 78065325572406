import { Contract } from '../../../interfaces';

export const getAffectedACPkeys = function (invoked, entries) {
  let keys = [];

  // Get the relevant ACP keys depending on the dependency that invoked this flow

  const concepts = Contract.getConcepts(this.contract);

  for (const entry of entries) {
    // this.log('Get affected for entry ', {entry})
    const { cardId, fieldName, value } = entry;
    if (!cardId) continue;
    if (invoked.repeatableAdd || invoked.repeatableRemove || invoked.repeatableChange) {
      const repeatable = cardId;
      // Add the relevant repeatable and it's corresponding input fields
      const repeatableInputs = getRepeatableInputs(this.contract, repeatable, value);

      keys.push(repeatable);

      if (invoked.repeatableAdd || invoked.repeatableRemove) {
        keys = keys.concat(
          repeatableInputs.map((inputName) => [repeatable + '.' + inputName, '$_local_$' + inputName]).flat()
        );
      }

      // Add any concept which is dependent on the added/removed repeatable
      for (const concept of concepts) {
        if (
          concept.id &&
          concept.inheritance &&
          concept.inheritance.inherit &&
          concept.inheritance.inherit.includes(repeatable)
        ) {
          keys.push(concept.id);
          if (invoked.repeatableChange && fieldName)
            keys = keys.concat([concept.id + '.' + fieldName, concept.id + './.' + fieldName]);
          if (invoked.repeatableAdd || invoked.repeatableRemove)
            keys = keys.concat(repeatableInputs.map((inputName) => concept.id + '.' + inputName));
          /* let inheritedFields = [];
          for (const inheritedRepeatableName of concept.inheritance.inherit) {
            inheritedFields = inheritedFields.concat(
              getRepeatableInputs(this, inheritedRepeatableName, value)
            );
          }
          this.log({inheritedFields})
          keys = keys.concat(
            [...new Set(inheritedFields)].map((inputName) => concept.id + "." + inputName)
          ); */
        }
      }

      if (invoked.repeatableChange) {
        keys.push(cardId + '.' + fieldName);
        keys.push(cardId + './.' + fieldName, '$_local_$' + fieldName);
      }
    } else if (invoked.ordinary) {
      keys = [cardId];
      keys.push(cardId + '.' + fieldName);
    }
  }
  if (
    invoked.insertNode ||
    invoked.updateNode ||
    invoked.removeNode ||
    invoked.setNodes ||
    invoked.nodesAny
  ) {
    keys.push('nodes');
  }

  if (invoked.legalPersonAny) {
    keys.push('legalPersons');
  }

  return [...new Set(keys)];
};

function getRepeatableInputs(contract, repeatable, repeatableValues) {
  const inputCard = Contract.getUiCard(contract, repeatable);
  if (inputCard) return Object.keys(inputCard.values);
  if (!repeatableValues) return [];
  return Object.keys(repeatableValues).filter((field) => field.substr(0, 1) !== '_');
}
