
export const engineVariablesHandler = {
  id: "engineVariables",
  dependencies: { state: ['*'] },
  time: 1,
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    const draftInfo = this.getDraftInfo();
    if (!Array.isArray(this._tmpDraft._recentUpdatedVariables) || this._tmpDraft._recentUpdatedVariables.length === 0) {
      return
    }
    // console.log('Hmmmmm ', {tmpD: this._tmpDraft, vars: draftInfo.variables })
    return (node, parents) => {
      if (node.variant === "vari" && node.data && this._tmpDraft._recentUpdatedVariables.includes(node.data.name) && draftInfo.variables.hasOwnProperty(node.data.name)) {
        const value = draftInfo.variables[node.data.name]
        node.data.value = value
        api.utils.engine.setFirstChild(node, value)
        this.markNodeUpdate(node);
      }
    };
  },
};
