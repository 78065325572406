import React, { useState } from 'react';
import { Input, Table, Divider } from 'antd';
import { Contract } from 'core/interfaces';
import { imp_and } from 'core/utils/general';
import { updateContract } from 'hooks';
import EditAddConcepts from './EditAddConcepts';

export default function ListConcepts({ contract, conceptStates, setConceptStates }) {
  const concepts = (conceptStates && conceptStates.concepts) || Contract.getConcepts(contract);
  const [search, setSearch] = useState('');

  const onSearch = (evt) => {
    setSearch(evt.target.value);
  };

  const removeConcept = (id) => {
    if (conceptStates && setConceptStates) {
      const newConcepts = conceptStates.concepts.filter((c) => c.id !== id);
      const newConceptGrammar = { ...conceptStates.conceptGrammar };
      delete newConceptGrammar[id];
      setConceptStates({
        concepts: newConcepts,
        conceptGrammar: newConceptGrammar,
      });
    } else {
      updateContract(contract, (draft) => {
        const index = draft.data.create.build.concepts.findIndex((c) => c.id === id);
        if (index > -1) draft.data.create.build.concepts.splice(index, 1);
        delete draft.data.create.build.conceptGrammar[id];
      });
    }
  };

  const listConceptsColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: 'Contract Party',
      dataIndex: 'contractParty',
      render: (text, record) => (record.contractParty ? 'Yes' : 'No'),
    },
    {
      title: 'State',
      dataIndex: 'stateId',
      render: (text, record) => {
        if (record.inheritance && record.inheritance.inherit) {
          return (
            <span>
              <small>Inherit from: </small>
              <em>{imp_and(record.inheritance.inherit)}</em>
            </span>
          );
        }
        if (record.state) {
          return (
            <small>
              <em>Fixed state</em>
            </small>
          );
        }
        if (record.stateId) {
          return (
            <span>
              <small>Derived from card:</small> {record.stateId}
            </span>
          );
        } else return <span>?</span>;
      },
    },
    {
      title: 'State key',
      dataIndex: 'stateKey',
    },
    {
      title: 'Action',
      render: (text, record) => {
        return (
          <>
            <EditAddConcepts
              contract={contract}
              conceptStates={conceptStates}
              setConceptStates={setConceptStates}
              id={record.id}
              mode="edit"
              contentType="span"
            >
              Edit
            </EditAddConcepts>
            <Divider type="vertical" />
            <span className="link" onClick={() => removeConcept(record.id)}>
              Remove
            </span>
          </>
        );
      },
    },
  ];

  const lowerSearch = search.toLocaleLowerCase();
  const filteredConcepts = search
    ? concepts.filter((c) => {
        if (c.id.includes(lowerSearch)) return true;
        if (c.stateId && c.stateId.includes(lowerSearch)) return true;
        if (c.stateKey && c.stateKey.includes(lowerSearch)) return true;
        if (c.inheritance && c.inheritance.inherit) {
          if (c.inheritance.inherit.some((inherited) => inherited.includes(lowerSearch))) return true;
        }
        return false;
      })
    : concepts;

  return (
    <>
      <Table
        bordered={false}
        columns={listConceptsColumns}
        dataSource={filteredConcepts}
        title={() => (
          <div className="d-flex justify-content-space-between">
            <div>Contract concepts</div>
            <div className="d-flex">
              <Input placeholder="Search ..." allowClear onChange={onSearch} />
              <EditAddConcepts
                contract={contract}
                contentType="button"
                buttonProps={{ className: 'm-0 ml-2', type: 'primary' }}
                conceptStates={conceptStates}
                setConceptStates={setConceptStates}
              >
                Add Concept
              </EditAddConcepts>
            </div>
          </div>
        )}
        rowKey="id"
      />
    </>
  );
}
