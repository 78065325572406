
export const onShbGenesis = {
  id: 'onShbGenesis',
  dependencies: { onGenesis: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    const setupValues = this.api.interfaces.Contract.getSetup(this.contract)

    if (setupValues.lender === 'hs') {
      const lenderState = {
        _party: {
          _fixed: true,
          _settings: {
            identificationNumberInPartyList: false
          },
          id: 'da20bf8a-d65b-4ed0-8493-35638e5d2588',
          parentId: null,
          form: 'AB',
          name: 'Stadshypotek AB (publ)',
          jurisdiction: 'se',
          identificationNumber: '556677-1122',
          regno: '556677-1123',
          underTransfer: false,
          street: '',
          city: '',
          zipcode: '',
          emails: [],
          data: {},
        },
      }
      const lenderConcept = this.api.interfaces.Contract.getConcept(this.contract, 'lender')
      if (!lenderConcept) return
      lenderConcept.state = lenderState
    }
  },
};
