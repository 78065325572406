import React, { useState, useEffect } from 'react';

import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { useSelector } from 'react-redux';
import { find, getNodeAllText } from 'core/engine/utils';
import manager from 'core/engine/manager';
import { useContract, useRerender } from 'hooks';

import { ConditionActions, PrintCondition } from 'components/Rules/NodeConditions';

import { Button } from 'components/editor/legal/toolbar/components';
import { Contract } from 'core/interfaces';
import CreateInput from 'routes/studio/Views/Input/renderDraftUI/CreateInput';
import IntlMessages from 'util/IntlMessages';

export default function Fields({ selectedFields }) {
  const editor = useSlate();
  let hasFields = selectedFields.length > 0;

  console.log('Selected fields ', selectedFields)

  return (
    <div>
      <h5>
        <IntlMessages id="studio.sidebar.fields.fields" />
      </h5>
      <div className="btns-section mt-1">
        {hasFields && (
          <>
            {selectedFields.map((entry, index) => (
              <div
                key={entry[0].data.item_id + '' + index}
                onMouseEnter={() => editor.highlightNode(entry[0])}
                onMouseLeave={editor.clearHighlights}
                className="mb-1"
              >
                <ShowMagic
                  // key={entry[0].data.item_id + "" + index}
                  entry={entry}
                  allFields={selectedFields}
                  index={index}
                  editor={editor}
                />
              </div>
            ))}
          </>
        )}
        {!hasFields && (
          <span>
            <small>
              <IntlMessages id="studio.sidebar.fields.noSelected" />
            </small>
          </span>
        )}
      </div>
    </div>
  );
}

export const ShowMagic = ({ editor, entry }) => {
  const [item] = entry;
  const render = [];

  if (!!(item.data && item.data.acp) && item.variant !== 'opt') {
    render.push(<ShowACP key={'acp' + entry[1].join('.')} entry={entry} />);
  }
  if (!!(item.data && item.data.isSmartDefinition)) {
    render.push(
      <div key={'smartdef' + entry[1].join('.')} className="show-magic smartdefinition">
        <table>
          <tbody>
            <tr>
              <td className="label">
                <IntlMessages id="studio.sidebar.fields.type" />
              </td>
              <td className="value">
                <IntlMessages id="draft.fields.smartDefinition" />
              </td>
            </tr>
            <tr>
              <td className="label">
                <IntlMessages id="studio.sidebar.fields.each" />
              </td>
              <td className="value">{item.data._each_repeatable_name}</td>
            </tr>
            <tr>
              <td className="label"></td>
              <td className="value">{item.data.key}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (!!(item.data && item.data.each_repeatable)) {
    render.push(
      <div key={'each_rep' + entry[1].join('.')} className="show-magic each_repeatable">
        <table>
          <tbody>
            <tr>
              <td className="label">
                <IntlMessages id="studio.sidebar.fields.type" />
              </td>
              <td className="value">
                <IntlMessages id="draft.fields.each" />
              </td>
            </tr>
            <tr>
              <td className="label">
                <IntlMessages id="studio.sidebar.fields.produces" />
              </td>
              <td className="value">{item.data.each_repeatable.repeatable}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (item.variant === 'opt') {
    render.push(
      <div key={'opt' + entry[1].join('.')} className="show-magic opt">
        <table>
          <tbody>
            <tr>
              <td className="label">
                <IntlMessages id="studio.sidebar.fields.type" />
              </td>
              <td className="value">
                <IntlMessages id="draft.fields.opt" />
              </td>
            </tr>
            <tr>
              <td className="label">
                <IntlMessages id="studio.sidebar.fields.rule" />
              </td>
              <td className="value">[**]</td>
            </tr>
            <tr>
              <td className="label">
                <IntlMessages id="studio.sidebar.fields.textContent" />
              </td>
              <td className="value">{getNodeAllText(item)}</td>
            </tr>
            <tr>
              <td className="label">
                <IntlMessages id="studio.sidebar.fields.isActive" />
              </td>
              <td className="value">
                {item.data._inActive ? <IntlMessages id="desc.No" /> : <IntlMessages id="desc.Yes" />}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  // if (!item.variant) return null;

  if (item.variant === 'item') {
    render.push(
      <div key={'item' + entry[1].join('.')} className="show-magic item ml-3">
        <table>
          <tbody>
            <tr>
              <td className="label">
                <IntlMessages id="studio.sidebar.fields.type" />
              </td>
              <td className="value">
                <IntlMessages id="draft.fields.item" />
              </td>
            </tr>
            <tr>
              <td className="label">
                <IntlMessages id="draft.key" />
              </td>
              <td className="value">
                {!item.data || !item.data.each || !item.data.each.key ? 'n/a' : item.data.each.key}
              </td>
            </tr>
            <tr>
              <td className="label">
                <IntlMessages id="draft.key" /> 2
              </td>
              <td className="value">
                {!item.data || !item.data.each_item_id ? 'n/a' : item.data.each_item_id}
              </td>
            </tr>
            <tr>
              <td className="label">
                <IntlMessages id="draft.value" />
              </td>
              <td className="value">{item.children[0].text}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (item.variant === 'vari') {
    render.push(<ShowVari key={'vari' + entry[1].join('.')} entry={entry} editor={editor} />);
  }

  if (item.variant === 'smartreplacement') {
    render.push(<MagicSmartReplacement key={'smart' + entry[1].join('.')} entry={entry} />);
  }
  if (render.length === 0) return null;
  return render;
};

const ShowVari = ({ entry, editor }) => {
  const [node, path] = entry;
  const contract = useContract();
  const [inputTemplate, setInputTemplate] = useState(null);
  const language = Contract.getLanguage(contract);
  const [cardId, name] = (node.data.name && node.data.name.split('.')) || [];
  // const legalPersons = useSelector((state) => state.legalPersons);

  console.log('Fix show vari..');
  return null;
  /* 
  useEffect(() => {

    const inputTemp = Contract.getUiInputFieldData(
      manager.getActiveDraft().contract,
      name
    )
    if (inputTemp) setInputTemplate(inputTemp)
    else console.log('No input template found.', {cardId, name})
  }, [cardId, name]);

  return (
    <div key={"vari" + path.join(".")} className="show-magic vari">
      <table>
        <tbody>
          <tr>
            <td className="label"><IntlMessages id="studio.sidebar.fields.type" /></td>
            <td className="value"><IntlMessages id="draft.fields.vari" /></td>
          </tr>
          <tr>
            <td className="label"><IntlMessages id="draft.key" /></td>
            <td className="value">{node.data.name}</td>
          </tr>
          <tr>
            <td className="label"><IntlMessages id="draft.value" /></td>
            <td className="value">{node.children[0].text}</td>
          </tr>
          <tr>
            <td className="label inputlabel"><IntlMessages id="draft.value" /></td>
            <td className="value inputvalue">
              {cardId && name && inputTemplate && (
                <CreateInput
                  cardId={cardId}
                  template={inputTemplate}
                  inputIndex={0}
                  label={null}
                  itemValue={node.children[0].text}
                  cardRepeatable={false}
                  linkRepeatable={false}
                  isLinked={false}
                  sourceUid={undefined}
                  language={language}
                  nodes={nodes}
                  // siblingInputs={card.inputs}
                  createInputType="simple"
                />
              )}
            </td>
          </tr>
          <tr>
            <td className="value" colSpan={2}>
              <div
                className="clickable pt-2"
                onClick={() => {
                  ReactEditor.focus(editor);
                  Transforms.setSelection(editor, {
                    anchor: { path, offset: 0 },
                    focus: { path, offset: node.children[0].text.length },
                  });
                }}
              >
                <IntlMessages id="studio.sidebar.fields.changeValue" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ); */
};

const ShowACP = ({ entry }) => {
  const [node, path] = entry;
  const stringPath = JSON.stringify(path);
  const rerender = useRerender()

  const onChangeRule = (id) => {
    rerender()
  };

  return (
    <div className="show-magic acp">
      <table>
        <tbody>
          <tr>
            <td className="label">
              <IntlMessages id="studio.sidebar.fields.type" />
            </td>
            <td className="value">
              <IntlMessages id="draft.fields.acp" /> <IntlMessages id="draft.block" />
            </td>
          </tr>
          <tr>
            <td className="label">
              <IntlMessages id="studio.sidebar.fields.rule" />
            </td>
            <td className="value">
              <PrintCondition node={entry[0]} />
            </td>
          </tr>
        </tbody>
      </table>
      <ConditionActions node={node} stringPath={stringPath} onChange={onChangeRule} linksInline />
      {/* <div className="link mt-2" onClick={toggleModal} style={{ fontSize: "13px" }}>
      <IntlMessages id="studio.sidebar.fields.editRule" />
      </div> */}
    </div>
  );
};

const MagicSmartReplacement = ({ entry }) => {
  const [item] = entry;
  const [updateOpen, setUpdateOpen] = useState(false);
  const editor = useSlate();
  const all = find(
    editor.children,
    (n) =>
      n.variant === 'smartreplacement' &&
      n.data.section === item.data.section &&
      n.data.name === item.data.name,
    { tuple: true }
  );
  return (
    <div className="show-magic smartreplacement">
      <div className="label">
        <IntlMessages id="studio.sidebar.fields.type" />
      </div>
      <div className="value">
        <IntlMessages id="draft.fields.smartReplacement" />
      </div>

      <div className="label">
        <IntlMessages id="draft.section" />
      </div>
      <div className="value">{item.data.section}</div>

      <div className="label">
        <IntlMessages id="draft.key" />
      </div>
      <div className="value">{item.data.name}</div>

      <div className="label">
        <IntlMessages id="studio.sidebar.fields.occurences" />
      </div>
      <div className="value">{all.length}</div>

      <div className="label">
        <IntlMessages id="draft.value" />
      </div>
      <div className="value">{item.children[0].text}</div>

      <Button
        type="secondary"
        size="small"
        onClick={() => {
          setUpdateOpen(true);
        }}
      >
        <IntlMessages id="studio.sidebar.fields.updateAllOccurences" />
      </Button>
      {updateOpen && <UpdateSmartreplacements entry={entry} setUpdateOpen={setUpdateOpen} all={all} />}
    </div>
  );
};

const UpdateSmartreplacements = ({ entry, setUpdateOpen, all }) => {
  console.log('Fix updateSmartReplacements');
  return null;
};
