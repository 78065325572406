import uuid from "uuid-random";

export function generateEachPlaceholderChild(node) {

  if (node.data && node.data.fill && node.data.fill.placeholder)
    return JSON.parse(node.data.fill.placeholder);
  switch (node.type) {
    case "numbered_list":
    case "bulleted_list":
      return [
        {
          type: "list_item",
          data: {
            is_each_placeholder: true,
            template_id: "placeholder-ol",
            item_id: uuid(),
          },
          children: [{ text: "" }],
        },
      ];
    case "each":
    case "paragraph":
      return [
        {
          type: "paragraph",
          data: {
            is_each_placeholder: true,
            template_id: "placeholder-pr",
            item_id: uuid(),
          },
          children: [{ text: "" }],
        },
      ];
    default:
      return [
        {
          type: "paragraph",
          data: {
            is_each_placeholder: true,
            template_id: "placeholder-default",
            item_id: uuid(),
          },
          children: [{ text: "" }],
        },
      ];
  }
}
