import { useContext } from 'react';
import {
  CustomerContext,
  ProjectContext,
  ContractContext,
  DraftContext,
  ContractUpdateContext,
  EditorCommentContext,
  StudioEditorInputContent,
  ModalContext,
  AlertContext,
  WarningsContext,
  PermissionsTableContext,
} from 'contexts/contexts';
import { createDraft, finishDraft } from 'immer';
import { Entity } from 'core/interfaces';
import api from 'utils/api';

const isFetching = {
  project: false,
  customer: false,
};

export function useProject(id, opts = {}) {
  const { force = false } = opts;
  const currentId = ProjectContext.get('id');
  const goFetch = !currentId || (id && force && currentId !== id);
  if (goFetch && id && !isFetching.project) {
    isFetching.project = true;

    const include = [
      { model: 'Checklist', as: 'checklists' },
      { model: 'ExternalDocument', as: 'externalDocuments' },
    ];
    const includeQuery = encodeURI(JSON.stringify(include));

    api
      .get(`/projects/${id}?include=${includeQuery}`)
      .then((res) => {
        if (!res || !res.data) return;
        setProject(res.data);
      })
      .catch((err) => {})
      .finally(() => (isFetching.project = false));

    /* const proms = [];
    proms.push(api.get('/projects/' + id, { resource: 'Project'}));
    proms.push(api.get('/checklists?resourceType=Project&resourceId=' + id, { resource: 'Checklist'}));
    Promise.all(proms)
      .then((items) => {

        const checklistsItem = items.filter(item => item.config.resource === 'Checklist')
        const projectItem = items.find(item => item.config.resource === 'Project')
        const checklists = (checklistsItem && checklistsItem.data) || []
        const project = (projectItem && projectItem.data)
        if (project) {
          project.checklists = checklists
          setProject(project)
        }
      })
      .catch((promErr) => {
        console.log('Prom eerr ', promErr);
      }); */
  }
  return ProjectContext.useContext();
}
export function setProject(...args) {
  return ProjectContext.setContext(...args);
}
export function getProjectValue(arg) {
  return ProjectContext.get(arg);
}
export function getProjectValues() {
  return ProjectContext.getGlobal();
}

export function useCustomer(id, opts = {}) {
  const { force = false } = opts;
  const customer = CustomerContext.get('customer');
  const currentId = customer && customer.id;
  const goFetch = !currentId || (force && currentId !== id);

  if (goFetch && id && !isFetching.customer) {
    isFetching.customer = true;
    console.log('Shall set id ', id);
    setCustomer(id);
  }
  return CustomerContext.useContext();
}
export function setCustomer(id) {
  const include = [
    { model: 'LegalPerson', as: 'LegalPersons' },
    { model: 'RealPerson', as: 'RealPersons' },
  ];
  const includeQuery = encodeURI(JSON.stringify(include));
  api
    .get(`/customers/${id}?include=${includeQuery}`)
    .then((res) => {
      if (res.data) {
        const { person, type } = Entity.getRelevantPersonFromCustomer(res.data);
        // const topcoLegalPerson = res.data.LegalPersons.find((lp) => lp.isTopCo);
        setCustomerManually({
          customer: res.data,
          legalPerson: type === 'legalPerson' ? person : null,
          realPerson: type === 'realPerson' ? person : null,
          person,
          personType: type,
        });
      }
    })
    .catch((err) => {
      console.log('Fetch legal pers err', err);
    });
}
export function setCustomerManually(...args) {
  return CustomerContext.setContext(...args);
}
export function getCustomerValue(arg) {
  return CustomerContext.get(arg);
}
export function getCustomerValues() {
  return CustomerContext.getGlobal();
}

export function useDraft() {
  return DraftContext.useContext();
}
export function setDraft(...args) {
  return DraftContext.setContext(...args);
}
export function getDraftValue(arg) {
  return DraftContext.get(arg);
}
export function getDraftValues() {
  return DraftContext.getGlobal();
}

export function useEngine() {
  const draft = useDraft();
  return (draft && draft.instance) || null;
}

export function useContract() {
  return ContractContext.useContext();
}
export function useContractData() {
  const contract = useContract();
  if (!contract) return null;
  return contract.data || null;
}
export function setContract(...args) {
  return ContractContext.setContext(...args);
}
export function setContractContent(value) {
  // Get the existing contract
  const contract = getContractValues();
  if (!contract) {
    console.log('Has no contract...');
    return;
  }

  // Create a new contract
  const newContract = {
    ...contract,
    data: { ...contract.data, content: value },
  };

  // Also update the content in the current draft
  const draft = getDraftValues();
  draft.contract = newContract;

  // Set the new contract context
  ContractContext.setContext(newContract);
  return;
}
export function getContractUi() {
  return ContractContext.getGlobal()?.data?.ui;
}
export function getContractValue(arg) {
  return ContractContext.get(arg);
}
export function getContractValues() {
  return ContractContext.getGlobal();
}
export function updateContract(contract, fn) {
  if (!contract || !contract.id || typeof fn !== 'function') {
    throw new Error('updateContract expects a contract and a function as arguments');
  }
  const draft = createDraft(contract);
  fn(draft);
  setContract(finishDraft(draft));
}
// Usage
// `contract` variable from before..
/* updateContract(contract, (draft) => {
  draft.id = 'nytt-id'
  draft.data.create.build.concepts.splice(conceptIndex, 1);
  draft.data.ui.inputs['facility/fhea'].header.sv = 'hejsan'
}) */

export function useContractUpdates() {
  return ContractUpdateContext.useContext();
}
export function setContractUpdates(...args) {
  return ContractUpdateContext.setContext(...args);
}
export function addContractUpdates(...args) {
  return ContractUpdateContext.addContext(...args);
}
export function getContractUpdatesValue(arg) {
  return ContractUpdateContext.get(arg);
}
export function getContractUpdatesValues() {
  return ContractUpdateContext.getGlobal();
}

export function useEditorComments() {
  return EditorCommentContext.useContext();
}
export function setEditorComments(...args) {
  return EditorCommentContext.setContext(...args);
}
export function addEditorComments(...args) {
  return EditorCommentContext.addContext(...args);
}
export function getEditorCommentsValue(arg) {
  return EditorCommentContext.get(arg);
}
export function getEditorCommentsValues() {
  return EditorCommentContext.getGlobal();
}

export function useStudioEditorInputContent() {
  return StudioEditorInputContent.useContext();
}
export function setStudioEditorInputContent(...args) {
  return StudioEditorInputContent.setContext(...args);
}
export function addStudioEditorInputContent(...args) {
  return StudioEditorInputContent.addContext(...args);
}
export function getStudioEditorInputContentValue(arg) {
  return StudioEditorInputContent.get(arg);
}
export function getStudioEditorInputContentValues() {
  return StudioEditorInputContent.getGlobal();
}

export function useModalContext() {
  return ModalContext.useContext();
}
export function setModalContext(...args) {
  return ModalContext.setContext(...args);
}
export function addModalContext(...args) {
  return ModalContext.addContext(...args);
}
export function getModalContextValue(arg) {
  return ModalContext.get(arg);
}
export function getModalContextValues() {
  return ModalContext.getGlobal();
}

export function useAlertContext() {
  return AlertContext.useContext();
}
export function setAlertContext(...args) {
  return AlertContext.setContext(...args);
}
export function addAlertContext(...args) {
  return AlertContext.addContext(...args);
}
export function getAlertContextValue(arg) {
  return AlertContext.get(arg);
}
export function getAlertContextValues() {
  return ModalContext.getGlobal();
}

export function useWarnings() {
  return WarningsContext.useContext();
}
export function setWarnings(...args) {
  return WarningsContext.setContext(...args);
}
export function addWarnings(...args) {
  return WarningsContext.addContext(...args);
}
export function getWarningsValue(arg) {
  return WarningsContext.get(arg);
}
export function getWarningsValues() {
  return WarningsContext.getGlobal();
}

// Regular React contexts
export function usePermissionsTable() {
  return useContext(PermissionsTableContext);
}
