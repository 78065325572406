import React, { memo } from 'react';
import CreateInput from '../../CreateInput';
import { useSelector } from 'react-redux';
import { useContract, getContractUi } from 'hooks';
import { Contract, InputPaths } from 'core/interfaces';
import { getByPath } from 'core/utils/general';
import { Row, Col } from 'antd';
import { ACard } from '../../CardsContainer';

import { useRulePassed, useLocalStateUid } from '../../utils/hooks';
import { useMemo } from 'react';

export default memo((props) => {
  const { card, isRepeatable, path } = props;
  const cardUid = useLocalStateUid(path);
  const passed = useRulePassed(card.acp, path);
  if (!passed) return null;

  if (isRepeatable && !cardUid) {
    console.log('No rep', { card, path });
    return <div>Errornous contract state. Contract input fields and contract state are out of sync.</div>;
  }
  if (!card.inputs_order || card.inputs_order.length === 0) return null;

  return <RenderInputsByOrder card={card} cardUid={cardUid} path={path} />;
});

const RenderInputsByOrder = memo((props) => {
  const ui = getContractUi();
  let { inputs, inputs_order, card, path, acp } = props;
  if (!inputs) inputs = ui.inputs || {};
  if (!inputs_order) inputs_order = card.inputs_order;
  const passed = useRulePassed(acp, path);
  if (!passed) {
    console.log('RenderInputsByOrder did not pass', { acp, path });
    return null;
  }

  if (!inputs || !inputs_order || inputs_order.length === 0) {
    console.warn('No inputs nor inputs_order for card');
    return null;
  }
  return inputs_order.map((fieldName, index) => {
    let field = inputs[fieldName];

    if (!field) {
      console.log('cannot find fieldName', fieldName);
      return null;
    }

    return (
      <InputFieldContainer key={fieldName + '-' + index} {...props} field={field} fieldName={fieldName} />
    );
  });
});

const InputFieldContainer = memo(({ linkMade, ...props }) => {
  const { field } = props;
  if (field.type === 'card') {
    return <ReplicateCard field={field} path={props.path} />;
  } else if (field.type === 'block') {
    return <InputBlock {...props} />;
  } else if (field.type === 'linkBlock') {
    return <LinkBlockContainer {...props} level={props.level || props.level === 0 ? props.level + 1 : 0} />;
  } else if (field.linked && !linkMade) {
    // Check `linkMade`. If we come here due to a LinkBlock, then
    // the item would already be linked, and we should not link again
    // as that would cause double inputs.
    return <LinkedItem {...props} />;
  } else {
    return <InputItem {...props} />;
  }
});

/**
 * Replicate a card by instruction from an input field.
 *
 * An input field may have its `type` set to "card" and a property `cardName`.
 *
 * If so, we include that entire card on the position of that input field, resulting
 * in a card within a card.
 *
 *
 * @param {object} *  field: The input field (origin: DocumentTemplate) which is instructing the card to be replicated.
 *                    path:  The path of the card in which the input field resides, e.g. "input.facility.1" or "input.pricing"
 *                           whereas the relevant card later on will itself have the path "input.facility.1.replicatedCardId"
 *
 */
const ReplicateCard = memo((props) => {
  const { field, path } = props;
  const { cardName } = field;
  const contract = useContract();
  const card = Contract.getUiCard(contract, cardName);
  const language = Contract.getLanguage(contract);

  const passed = useRulePassed(field.acp, path);
  if (!passed) return null;

  if (!card) return null;

  return (
    <Col span={24}>
      <ACard card={card} cardName={cardName} language={language} path={path} cardWithinCard />
    </Col>
  );
});

/**
 *
 * An InputBlock is merely a container (origin: DocumentTemplate), by which an
 * input field may - instead of being a field - contain a list of other inputs
 * to be included, in which case it shall have its own `inputs_order` property
 * being an array referencing other input fields with the same card.
 *
 * @param {object} *  A variety of data. See closer description below at the `InputItem` component.
 */

const InputBlock = memo((props) => {
  const { field, path } = props;
  const ui = getContractUi();
  const passed = useRulePassed(field.acp, path);
  // console.log('Passed ', passed)
  if (!passed) return null;
  if (!field.inputs_order || field.inputs_order.length === 0) return null;

  return field.inputs_order.map((subFieldName, index) => {
    // Find the card with our input field.

    const subField = ui.inputs[subFieldName];
    if (!subField) {
      console.log('Cannot find subFieldName', { subFieldName, field, path });
      return null;
    }

    return <InputFieldContainer key={subFieldName} {...props} field={subField} fieldName={subFieldName} />;
  });
});

/**
 * LinkedItem.
 *
 * If an item links to a repeatable, use that field but in the context of each repeatable.
 *
 * E.g. a field within `cardA` may include a property `linked` with the value `cardX`.
 *
 * @param {object} *  The input field (origin: DocumentTemplate) which is linking elsewhere.
 *
 */

function LinkedItem(props) {
  const { field, fieldName, card, cardUid, path } = props;
  const { linked } = field;

  const origin = useMemo(() => ({ card, cardUid }), [card, cardUid]);

  let linkedPath;
  if (field.linkType === 'nested') {
    linkedPath = InputPaths.pathAdd(path, linked);
  } else {
    linkedPath = InputPaths.construct(null, linked);
  }

  const targetStates = useSelector((state) => getByPath(state, 'input.' + linked));
  const contract = useContract();

  if (!targetStates) return null;
  const linkedCard = Contract.getUiCard(contract, linked);
  if (!linkedCard) return null;

  const targetIsRepeatable = Contract.getUiIsCardRepeatable(contract, linked);

  if (!targetIsRepeatable) {
    return (
      <InputItem
        key={'link_' + linked + fieldName}
        origin={origin}
        card={linkedCard}
        cardUid={undefined}
        field={field}
        fieldName={fieldName}
        path={linkedPath}
      />
    );
  }

  return Object.keys(targetStates).map((uid, index) => (
    <InputItem
      key={'link_' + linked + fieldName + uid}
      origin={origin}
      card={linkedCard}
      cardUid={uid}
      field={field}
      fieldName={fieldName}
      path={InputPaths.construct(null, linked, uid)}
    />
  ));
}

/**
 * LinkBlock.
 *
 * Conceptually a combination of `linked` and `input block`.
 *
 * a LinkBlock has its own inputs_order of input fields to be rendered, but in the context
 * of the linked card.
 *
 * @param {object} *  The input field (origin: DocumentTemplate) which is linking elsewhere.
 *
 */

const LinkBlockContainer = memo((props) => {
  const { field, level, path } = props;
  const { linked } = field;
  let acp;
  if (Array.isArray(field.acps) && field.acps[level]) acp = field.acps[level];
  else if (field.acp) acp = field.acp;
  else acp = null;

  const linkTarget = linked;

  let linkedPath;
  if (field.linkType === 'nested') {
    linkedPath = InputPaths.pathAdd(path, linkTarget);
  } else {
    linkedPath = InputPaths.construct(null, linkTarget);
  }

  const targetStates = useSelector((state) => getByPath(state, linkedPath));
  const contract = useContract();

  if (!targetStates) return null;
  const linkedCard = Contract.getUiCard(contract, linkTarget);
  if (!linkedCard) return null;
  const targetIsRepeatable = Contract.getUiIsCardRepeatable(contract, linkTarget);

  if (!targetIsRepeatable) {
    return (
      <LinkBlock key={'lnk_blockx_' + linkTarget} {...props} card={linkedCard} path={linkedPath} acp={acp} />
    );
  }

  return Object.keys(targetStates).map((uid, index) => (
    <LinkBlock
      key={'lnk_blockx_' + linkTarget + index}
      {...props}
      card={linkedCard}
      cardUid={uid}
      path={InputPaths.pathAdd(linkedPath, uid)}
      acp={acp}
    />
  ));
});

const LinkBlock = memo((props) => {
  const { acp, card, cardUid, field, level, path } = props;

  const passed = useRulePassed(acp, path);
  if (!passed) return null;

  if (field.settings?.inline) {
    return (
      <RenderInputsByOrder
        key={'lnk_block_' + card.id + field.name}
        inputs_order={field.inputs_order}
        card={card}
        cardUid={cardUid}
        path={path}
        linkMade={true}
        debug={1}
        level={level}
      />
    );
  }

  return (
    <Col span={24}>
      <Row>
        <RenderInputsByOrder
          key={'lnk_block_' + card.id + field.name}
          inputs_order={field.inputs_order}
          card={card}
          cardUid={cardUid}
          path={path}
          linkMade={true}
          debug={1}
          level={level}
        />
      </Row>
    </Col>
  );
});

/**
 *  Render a specific input field.
 *
 *  The contents, onChange etc. is handled by the `CreateInput` component.
 *  Here, we merely complete the input field's `path` and check if there's
 *  a condition (acp) to validate.
 *
 * @param {object} *  An object of the following items:
 *                      Item          Description                                                   Origin
 *                      ¨¨¨¨          ¨¨¨¨¨¨¨¨¨¨¨                                                   ¨¨¨¨¨¨
 *                      card:         the relevant input card for the input field                   Document Template
 *                      cardUid:      for repeatables, the unique id (_uid) for that repeatable     State
 *                      field:        the input field data ()                                       DocumentTemplate
 *                      path:         the path (up until the input field), e.g.:                    <Generated based on state>
 *                                    `input.facility.1` to which we add `.name` to complete
 */

const InputItem = memo((props) => {
  const { field } = props;
  let { path } = props;
  // Make this check BEFORE the fieldName is added to the path.
  // The reason being that the path used by useRulePassed
  // shall be the path to the card state (i.e. being
  // the state of this input field AND all its siblings).
  // This enables the activeCondition check to consider
  // the `localState` one level up (i.e. including siblings)
  const passed = useRulePassed(field.acp, path);
  if (!passed) {
    // console.log(props.fieldName + " didnt pass", { field, path });
    return null;
  }

  // Ensure that the fieldName is does not include a reference to
  // its context, i.e. transform any `cardId.fieldName` to just `fieldName`
  let fieldName = props.fieldName;

  /* const fieldNameParts = props.fieldName.split("/");
  if (fieldNameParts.length === 1) fieldName = props.fieldName;
  else if (fieldNameParts.length === 2) {
    fieldName = fieldNameParts[1];
  } */

  // Add `.fieldName` AFTER the check above.
  path += '.' + fieldName;

  return <CreateInput {...props} fieldName={fieldName} path={path} />;
});

/*
 * Connecting a card with another... DocumentTemplate / Contract structure:
 *
 * 
state.input.__connectedCards = [
  {
    cards: {
      facility: "201fb670-256a-47cf-acee-1f8c399fea4b",
      borrower: "b1301600288567173" 
    },
    key: 'facilityAvailableToBorrower'
  }
]

*/

const x = {
  facilityAvailableToBorrower: {
    type: 'checkbox',
    function: 'connect',
    label: {
      type: 'ref',
      card: 'facility',
      target: 'name',
      default: '[Faciliteten]',
    },
    name: 'facilityAvailableToBorrower',
    linked: 'facility',
    cols: {
      lg: '2',
      md: '3',
      sm: '6',
    },
    checkLabel: {
      sv: 'Tillgänglig',
      en: 'Available',
    },
  },
};
