import { Editor, Path } from 'slate';

export default function applySavedOperations(editor, operationsSet) {
  let timeout = 0;
  const interval = 3000;

  const set = JSON.parse(JSON.stringify(operationsSet));

  /* for (const ops of set) {
    for (const op of ops.operations) {
      op.pointRef = Editor.pointRef(editor, {path: op.path, offset: op.offset})
    }
  } */

  for (const ops of set) {
    timeout = timeout + interval;
    if (!ops.operations || !ops.operations[0]) continue;
    setTimeout(() => {
      Editor.withoutNormalizing(editor, () => {
        for (const op of ops.operations) {
          console.log('Do op ', op);
          editor.apply(op);
          /* editor.apply({
            ...op,
            path: op.pointRef.current.path,
            offset: op.pointRef.current.offset
          }); */
        }
      });
    }, [interval]);
  }
}

export function applyOperationSets(editor, set) {
  if (!Array.isArray(set) || !set[0]) return false;

  /* for (const ops of set) {
    for (const op of ops) {
      op.pointRef = Editor.pointRef(editor, { path: op.path, offset: op.offset });
    }
  } */

  Editor.withoutNormalizing(editor, () => {
    for (const ops of set) {
      for (const op of ops) {
        // console.log('Dox op ', op);
        editor.apply(op);
        /* editor.apply({
          ...op,
          path: op.pointRef.current.path,
          offset: op.pointRef.current.offset,
        }); */
      }
    }
  });
  return true;
}


/* Organise Sets of Operations */

function isTextOp(op) {
  return op.type === 'insert_text' || op.type === 'remove_text';
}
function bothOpsText(op1, op2) {
  return isTextOp(op1) && isTextOp(op2);
}
function opsArePaste(operations) {
  const insertOpIndex = operations.findIndex((op) => op.type === 'insert_node');
  if (insertOpIndex === -1) return false;

  let insertOp;
  if (insertOpIndex === 0) {
    insertOp = operations[0];
  } else if (insertOpIndex === 1 && operations[0].type === 'split_node') {
    insertOp = operations[1];
  } else return false;

  if (insertOp.type !== 'insert_node') return false;
  const mergeOp = operations.find((op) => op.type === 'merge_node');
  if (!mergeOp) return false;
  return Path.equals(insertOp.path, mergeOp.path);
}

function getOperationsType(operations) {
  if (operations.every(isTextOp)) return 'text';
  if (opsArePaste(operations)) return 'paste';

  return 'other';
}

export function organiseSets(operationsSet) {
  let total = operationsSet;
  total = compressTextOperations(total);
  total = dataOrdering(total);
  return total;
}

function compressTextOperations(operationsSet) {
  const batch = [];
  let prevSet = null;
  for (const set of operationsSet) {
    if (
      !prevSet ||
      set.length !== 1 ||
      prevSet.length !== 1 ||
      !bothOpsText(set[0], prevSet[0]) ||
      !Path.equals(set[0].path, prevSet[0].path)
    ) {
      batch.push(set);
      prevSet = set;
      continue;
    }

    const lastIndex = batch.length - 1;
    batch[lastIndex] = [...batch[lastIndex], ...set];
  }
  return batch;
}

function dataOrdering(operationsSet) {
  return operationsSet.map((operations) => {
    return {
      operations: operations,
      type: getOperationsType(operations),
      madeAt: new Date(operations[operations.length - 1].madeAt).toISOString(),
    };
  });
}
