import { setByPath } from '../../../utils/general';

export const set = function (predicate, path, value) {
  if (this.draftValue) {
    this.warn('The `set` method is intended to be used outside drafting phase.');
    return null;
  }

  this.withDraft(() => {
    function loopContent(children, predicate) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (predicate(child)) {
          if (!path) {
            if (typeof value !== 'object') {
              this.log('Cannot set a non-object value to a node');
              continue;
            }
            this.log('Shall replace node', { orig: JSON.parse(JSON.stringify(child)), new: value });
            children[i] = value;
          } else {
            setByPath(child, path, value);
          }
        }
        if (Array.isArray(child.children)) {
          loopContent(child.children, predicate);
        }
      }
    }

    loopContent(this.draftValue, predicate);
  });
};
