import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import 'assets/vendors/style';
import configureStore, { history } from './appRedux/store';
import App from './containers/App/index';

import ExternalView from './routes/externalView/';
import ExternalComplete from './routes/externalView/components/ResponseCompleted';
import ExternalNoMatch from './routes/externalView/components/NoMatch';

// import asyncComponent from 'util/asyncComponent';
import { IS_EXTERNAL } from 'config';

const store = configureStore();

const NextApp = () => {
  return (
    <Provider store={store}>
      <Router basename="/" history={history}>
        <Switch>
          {IS_EXTERNAL ? <ExternalFrontendRoutes /> : <InternalFrontendRoutes />}
        </Switch>
      </Router>
    </Provider>
  );
};

export default NextApp;

function InternalFrontendRoutes() {
  if (IS_EXTERNAL) return null;
  return (
    <Route
      path="/"
      // component={asyncComponent(() => import('./containers/App/index'))}
      component={App}
    />
  );
}

function ExternalFrontendRoutes() {
  if (!IS_EXTERNAL) return null;
  return (
    <>
      <Route path="/s" component={ExternalView} />
      <Route path="/sc" component={ExternalComplete} />
      <Route component={ExternalNoMatch} />
    </>
  );
}

/*
// With only async.
import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import 'assets/vendors/style';
import configureStore, { history } from './appRedux/store';

// import App from './containers/App/index';
import asyncComponent from 'util/asyncComponent';
import { IS_EXTERNAL } from 'config';

const store = configureStore();

const NextApp = () => {
  return (
    <Provider store={store}>
      <Router basename="/" history={history}>
        <Switch>
          {IS_EXTERNAL ? <ExternalFrontendRoutes /> : <InternalFrontendRoutes />}
          </Switch>
          </Router>
        </Provider>
      );
    };
    
    export default NextApp;
    
    function InternalFrontendRoutes() {
      if (IS_EXTERNAL) return null;
      return (
        <Route
          path="/"
          component={asyncComponent(() => import('./containers/App/index'))}
          // component={App}
        />
      );
    }
    
    function ExternalFrontendRoutes() {
      if (!IS_EXTERNAL) return null;
      return (
        <>
          <Route path="/s" component={asyncComponent(() => import('./routes/externalView/'))} />
          <Route path="/sc" component={asyncComponent(() => import('./routes/externalView/ResponseCompleted'))} />
          <Route component={asyncComponent(() => import('./routes/externalView/NoMatch'))} />
        </>
      );
    }
    
*/
