import React, { useEffect } from "react";
import { useEditor, ReactEditor } from "slate-react";
import { ShowMagic } from "./Fields"
import IntlMessages from "util/IntlMessages";

export default function ({ selectedFields }) {
  const editor = useEditor();

  let hasFields = selectedFields.length > 0;

  useEffect(() => {
    editor.meta.isTabbingFields = true;
    ReactEditor.focus(editor);
    return () => {
      editor.meta.isTabbingFields = false;
    };
  }, [editor]);

  return (
    <div>
      <h5><IntlMessages id="studio.sidebar.editFlow" /></h5>
      <div className="btns-section">
        {hasFields && (
          <>
            <div className="label"><IntlMessages id="studio.sidebar.editFlow.currentFields" /></div>
            {selectedFields.map((entry, index) => (
              <div
                key={entry[0].data.item_id + "" + index}
                onMouseEnter={() => editor.highlightNode(entry[0])}
                onMouseLeave={editor.clearHighlights}
                className="mb-1"
              >
                <ShowMagic
                  entry={entry}
                  allFields={selectedFields}
                  index={index}
                  editor={editor}
                />
              </div>
            ))}
          </>
        )}
        {!hasFields && (
          <span>
            <small>
            <IntlMessages id="studio.sidebar.editFlow.instruction" />
            </small>
          </span>
        )}
      </div>
    </div>
  );
}
