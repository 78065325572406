import React, { useCallback, useRef, useEffect } from 'react';
import { Button, Tooltip, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addRepeatable, nodeWasInserted } from 'appRedux/actions';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

import readXlsxFile from 'read-excel-file';
import { handleExcel, filterRows } from './readExcel';

export default function (props) {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.nodes);
  const uploadExcelInput = useRef();
  const formatMessage = useIntlMessage();

  const newProperty = useCallback(
    (data = {}) => {
      // console.log("Data for new property ", data);
      dispatch(addRepeatable('input.property', data));
    },
    [dispatch]
  );

  const uploadExcel = () => {
    uploadExcelInput.current.click();
  };

  const onUploadExcel = useCallback(() => {
    readXlsxFile(uploadExcelInput.current.files[0], {
      transformData: filterRows,
    })
      .then((rows) => {
        const results = handleExcel(rows);

        for (const property of results) {
          newProperty(
            Object.keys(property).reduce((acc, curr) => {
              acc['property/' + curr] = property[curr];
              return acc;
            }, {})
          );
        }

        const messagePropertiesAdded = `${formatMessage('desc.Added')} ${results.length} ${formatMessage(
          'app.general.concepts.realProperties'
        )}`;
        setTimeout(() => {
          notification.info({
            message: messagePropertiesAdded,
            duration: 5,
          });
        }, 10);

        uploadExcelInput.current.value = '';

        // Add any new companies
        const companies = [
          ...new Set(results.filter((prop) => prop && prop.owner).map((prop) => prop.owner)),
        ];
        const newCompanies = companies.filter((company) => !nodes.find((node) => node.name === company));
        if (newCompanies.length > 0) {
          let highestId = 0;
          nodes.forEach((node) => {
            if (node.id > highestId) highestId = node.id;
          });
          for (const newCompany of newCompanies) {
            highestId++;
            dispatch(nodeWasInserted(highestId, 2, { name: newCompany }));
          }

          const messageCompaniesAdded = `${formatMessage('desc.Added')} ${
            newCompanies.length
          } ${formatMessage('app.general.concepts.companies')}`;
          const descriptionCompaniesAdded = formatMessage('studio.inputs.companiesAddedMessage');

          setTimeout(() => {
            notification.warn({
              message: messageCompaniesAdded,
              description: descriptionCompaniesAdded,
              duration: 7,
            });
          }, 10);
        }
      })
      .catch((e) => {
        console.log('onuploaderror ', e);
      });
  }, [nodes, dispatch, newProperty, uploadExcelInput, formatMessage]);

  useEffect(() => {
    const inputUploadRef = uploadExcelInput.current;
    inputUploadRef.addEventListener('change', onUploadExcel);
    return () => inputUploadRef.removeEventListener('change', onUploadExcel);
  }, [uploadExcelInput, onUploadExcel]);

  return (
    <>
      <Tooltip title={<IntlMessages id="studio.inputs.uploadExcelInfo" />}>
        <Button onClick={uploadExcel}>
          <i className="mdi mdi-file-excel"></i>
          {/* <IntlMessages id="studio.inputs.uploadExcel" /> */}
        </Button>
      </Tooltip>
      <input type="file" hidden id="upload-excel-input" ref={uploadExcelInput} />
    </>
  );
}
