import React from 'react';
import { useContract, setContract } from 'hooks';
import { useLocation } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import { array_move } from 'core/utils/general';
import { Alert, Button } from 'antd';
import { Create, Contract } from 'core/interfaces';

export default function ArrangeCards() {
  const contract = useContract();
  const language = Contract.getLanguage(contract);
  const location = useLocation();

  const currentPage = Create.getPageByUrl(contract.data.ui, location.pathname);

  return (
    <div className="h-100">
      <CustomScrollbars autoHeight autoHeightMin={600}>
        <div className="h-100">
          <CardList contract={contract} page={currentPage} language={language} />
        </div>
      </CustomScrollbars>
    </div>
  );
}

function CardList({ contract, page, language }) {
  return (
    <div>
      {page.cards.map((cardName, index) => {
        return (
          <CardEntry
            key={`card_${cardName}`}
            contract={contract}
            page={page}
            card={contract.data.ui.cards[cardName]}
            language={language}
            index={index}
          />
        );
      })}
    </div>
  );
}

function CardEntry({ contract, page, card, language, index }) {
  const moveUp = () => {
    if (index === 0) return;
    array_move(page.cards, index, index - 1);
    setContract({ ...contract });
  };
  const moveDown = () => {
    if (index + 1 === page.cards.length) return;
    array_move(page.cards, index, index + 1);
    setContract({ ...contract });
  };

  return (
    <>
      <Alert
        message={
          <div className="flex-row" style={{ justifyContent: 'space-between' }}>
            <div>{card.header[language]}</div>
            <div className="mr-1">
              <Button type="link" className="m-0" size="small" onClick={moveUp}>
                <i className="mdi mdi-arrow-up" />
              </Button>
              <Button type="link" className="m-0" size="small" onClick={moveDown}>
                <i className="mdi mdi-arrow-down" />
              </Button>
            </div>
          </div>
        }
        type="info"
      />
    </>
  );
}
