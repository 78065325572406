import { compareWithTransformation } from '../../../../utils/general';
import { uniqueItemIds, getDefinitionName } from '../../../utils';

export const makeDefinition = function (name, text, options = {}) {
  return cleanNewDefinition(
    this.makeParagraph([
      this.makeTextNode('"'),
      this.makeTextNode(name, { bold: true }),
      this.makeTextNode('" ' + text),
    ])
  );
};

export const cleanNewDefinition = function (content, options = {}) {
  const { ownItemIds = false } = options;
  if (!ownItemIds) return uniqueItemIds(content);

  return content;
};

export const sortDefinitions = function () {
  this.withDraft(() => {
    const definitionsClauses = this.contentSetters((n) => n.type === 'definitions', {
      outsideDraft: true,
    });
    for (const definitionsClause of definitionsClauses) {
      const node = definitionsClause.get();

      const defs = [];
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        const definitionName = getDefinitionName(child, { lowercase: true });
        if (definitionName) {
          defs.push([definitionName, child]);
        } else if (i !== 0) {
          if (!Array.isArray(defs[defs.length - 1])) {
            this.log('Smart def Sort. Previous item is not an array?? ', {
              defs: JSON.parse(JSON.stringify(defs)),
              i,
            });
            continue;
          }
          defs[defs.length - 1].push(child);
        } else {
          this.log('Error, non-defined item is first.');
          defs.push(['', child]);
        }
      }

      // Sort the definitions on the name entry.
      defs.sort(
        compareWithTransformation((item) => {
          const [name] = item;
          return name;
        })
      );

      // Compile the new set of sorted definitions.
      let newDefs = [];
      for (const [, ...contents] of defs) {
        newDefs = newDefs.concat(contents);
      }

      definitionsClause.set({
        ...node,
        children: newDefs,
      });
      // console.log('DefinitionsClause became ', {definitionsClause, n: JSON.parse(JSON.stringify(definitionsClause.get())) })
    }
  });
};
