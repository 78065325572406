import React, { useState } from "react";
import {
  Form,
  Input,
} from "antd";

export default function InputTypeText ({ onChildUpdate, currentInput }) {
  const [defaultValue, setDefaultValue] = useState(currentInput ? currentInput.value : '');

  const onChangeDefaultValue = (evt) => {
    const { value } = evt.target;
    setDefaultValue(value);
    onChildUpdate({ defaultValue: value });
  };
  
  return (
    <>
      <Form layout="vertical">
        <Form.Item
          label={"Default value"}
        >
          <Input
            type="text"
            value={defaultValue}
            onChange={onChangeDefaultValue}
            placeholder="Enter default value (if any)"
          />
        </Form.Item>
      </Form>
    </>
  );
}
