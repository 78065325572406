
export const onLoadEaches = {
  id: "onLoadEaches",
  dependencies: { onLoad: true },
  handler: function ({ state, api }) {
    return (node, parents, index) => {
      const _path = node.data && node.data._path;
      if (!_path) return;

      const localStates = api.utils.general.getByPath(state, _path);

      if (!localStates) {
        this.log("Shall clean remove: ", { node: JSON.parse(JSON.stringify(node)), _path });

        const parent = parents[0];
        parent.children.splice(index, 1);

        // Insert a placeholder if otherwise empty
        if (index === 0 && parent.children.length === 0) {
          parent.children = this.generateEachPlaceholderChild(parent);
        }
      }
    };
  },
};
