import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Drawer, Spin, Tabs, Tooltip, Button, Badge, notification } from 'antd';
import {
  FilePdfOutlined,
  FileWordFilled,
  EditOutlined,
  EditFilled,
  QuestionCircleOutlined,
  QuestionOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import CustomScrollbars from 'util/CustomScrollbars';
import OneEditor, { EditorWrapper } from 'components/editor/OneEditor';
import TopToolbar from 'components/editor/legal/toolbar/TopToolbar';
import { ContractContext } from 'contexts/contexts';
import { useSelector } from 'react-redux';
import ViewPDF from 'components/pdf/View';
import { useDownloadPDF, useDownloadDOCX } from 'hooks';
import { Contract } from 'core/interfaces';
import { AddComment } from 'routes/studio/Views/Edit/index.js';
import { useEditor } from 'slate-react';
import { Node, Editor, Path, Transforms } from 'slate';
// import ExternalContext from './ExternalContext';
import { fixDate } from 'components/ui';
import Tour from 'reactour';
import allSteps from './data/tourSteps';
import CommentsNotesEditor, { defaultNotesValue, defaultCommentsValue } from './components/CommentsNotes';
import Response, { useResponsePopup } from './components/Response/index';
import InfoTable from './components/InfoTable';
import TableOfContents from 'routes/studio/components/SidebarComponents/TableOfContents.js';
import IntlMessages from 'util/IntlMessages';
import api from './utils/externalApi';

const { TabPane } = Tabs;

function filterCommentsNotesToValue(content) {
  return content.filter((item) => Node.string(item) !== '');
}
function filterCommentsChecked(content) {
  return content.filter((item) => item.checked);
}

export default function ViewEditor({ sharedData, externalId, storedValues }) {
  const responsePopup = useResponsePopup();

  const [viewMode, setViewMode] = useState('default');
  const [isEditing, setIsEditing] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [myNotesValue, setMyNotesValue] = useState(
    (storedValues && storedValues.myNotesValue) || defaultNotesValue
  );
  const [myCommentsValue, setMyCommentsValue] = useState(
    (storedValues && storedValues.myCommentsValue) || defaultCommentsValue
  );

  const user = useSelector((state) => state.auth.user);

  const { settings = {} } = sharedData;
  const contract = (sharedData && sharedData.data) || null;
  const contractSettings = Contract.getSettings(contract);

  const editors = useRef({});
  const eventLog = useRef([]);
  // const inlineComments = useRef(contract?.data?.create?.comments || {});
  const operationSets = useRef([]);

  const availableTourSteps = useMemo(
    () =>
      allSteps.filter((step) => {
        if (!Array.isArray(step.onlyIfSetting)) return true;
        for (const requiredSetting of step.onlyIfSetting) {
          if (!settings[requiredSetting]) return false;
        }
        return true;
      }),
    [settings]
  );

  const saveToLocalStorage = (action) => {
    console.log('Save.');
    const saveData = {
      myNotesValue,
      myCommentsValue,
      operationSets: operationSets.current,
      inlineComments: contract?.data?.create?.comments || {},
      eventLog: eventLog.current,
      action: action || null,
    };
    localStorage.setItem('ext_save_' + externalId, JSON.stringify(saveData));
  };

  const onNotesChange = (val) => {
    setMyNotesValue(val);
    saveToLocalStorage();
  };
  const onCommentsChange = (val) => {
    setMyCommentsValue(val);
    saveToLocalStorage();
  };

  const addGeneralComment = (node) => {
    // Comments editor is open.
    if (editors.current.comments) {
      const last = Editor.last(editors.current.comments, []);
      if (!last) return;
      const [lastNode, lastPath] = last;
      const topPath = [lastPath[0]];
      let target;
      if (lastNode.text === '') target = topPath;
      else target = Path.next(topPath);
      Transforms.insertNodes(editors.current.comments, node, { at: target });
      return;
    }
    const newNodes = [...myCommentsValue];
    const nl = newNodes.length;
    const lastNode = newNodes[nl - 1];
    if (Node.string(lastNode) === '') {
      newNodes.splice(nl - 1, 0, node);
    } else {
      newNodes.push(node);
    }
    setMyCommentsValue(newNodes);
  };

  const onInlineCommentChange = (id, type, value) => {
    saveToLocalStorage();
  };

  const dispatchResponse = (action, response) => {
    const sendResponse = {
      ...response,
      user,
      inlineComments: contract?.data?.create?.comments || {},
      eventLog: eventLog.current,
    };
    if (action === 'feedback') {
      if (!settings.allowFeedbackOnly) return console.log('Not allowed to submit feedback only.');
      const commentsWithContent = myCommentsValue.filter(cmnt => !!Node.string(cmnt))
      sendResponse.generalComments = settings.allowGeneralComments ? commentsWithContent : null;
      sendResponse.operationSets = operationSets.current;
      // organiseSets(operationSets.current);
    }
    console.log('sendResponse is ', sendResponse);
    saveToLocalStorage();

    api
      .post('/document/response', sendResponse)
      .then((res) => {
        if (!res || !res.data || res.data.status !== 'sent') {
          notification.error({
            message: 'Failed to send response',
            description: 'Please try again later',
          });
        } else {
          responsePopup(action, response);
          saveToLocalStorage(action);
        }
      })
      .catch((err) => {
        notification.error({
          message: 'Failed to send response',
          description: 'Server responded with `' + err.response.status + '`',
        });
        console.log('send response err ', { err, response: err.response });
      });
  };

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const toggleIsEditing = () => {
    if (!settings.allowFeedbackOnly) {
      if (isEditing) setIsEditing(false);
      return;
    }
    setIsEditing(!isEditing);
  };

  const onChangeOperations = (ops) => {
    const madeAt = Date.now();
    operationSets.current.push(
      ops.filter((op) => op.type !== 'set_selection').map((op) => ({ ...op, madeAt }))
    );
    saveToLocalStorage();
  };

  const onChangeMode = (val) => {
    if (val !== 'default') setIsEditing(false);
    setViewMode(val);
  };

  const closeTour = () => {
    setIsTourOpen(false);
  };

  const dragStore = useRef({});

  const onMouseDown = useCallback(
    (evt) => {
      const container = evt.target.closest('.notes-container');
      if (!container) return;

      // Focus it.
      const thisResizer = container.getElementsByClassName('resize')[0];
      container.style.zIndex = 100;
      thisResizer.style.zIndex = 101;

      // Ensure other container is below
      const otherContainerId = container.classList.contains('notes')
        ? 'notes-container-comments'
        : 'notes-container-notes';
      const otherContainer = document.getElementById(otherContainerId);
      if (otherContainer) {
        const otherResizer = container.getElementsByClassName('resize')[0];
        otherContainer.style.zIndex = 98;
        otherResizer.style.zIndex = 99;
      }

      if (evt.target.closest('.notes-dragger')) {
        dragStore.current.active = container;
        dragStore.current.mode = 'move';
        evt.preventDefault();
        evt.stopPropagation();
      }
      if (evt.target.closest('.resize')) {
        dragStore.current.active = container;
        dragStore.current.mode = 'resize';
        dragStore.current.startX = evt.clientX;
        dragStore.current.startY = evt.clientY;
        evt.preventDefault();
        evt.stopPropagation();
      }
    },
    [dragStore]
  );
  const onMouseUp = useCallback(
    (evt) => {
      dragStore.current.mode = null;
      dragStore.current.active = null;
      dragStore.current.startX = null;
      dragStore.current.startY = null;
    },
    [dragStore]
  );

  const minWidth = 200;
  const minHeight = 300;

  const onMouseMove = useCallback(
    (evt) => {
      if (!dragStore || !dragStore.current || !dragStore.current.active) return;
      const { clientX, clientY } = evt;

      if (dragStore.current.mode === 'move') {
        const left = clientX - 20;
        const top = clientY - 20;
        dragStore.current.active.style.position = 'fixed';
        dragStore.current.active.style.left = left + 'px';
        dragStore.current.active.style.top = top + 'px';
        dragStore.current.active.style.zIndex = 1000;
      }

      if (dragStore.current.mode === 'resize') {
        const { startX, startY } = dragStore.current;
        const rect = dragStore.current.active.getBoundingClientRect();
        if (!rect) return;
        const { width, height, x, y } = rect;
        if (dragStore.current.active.style.position !== 'fixed') {
          dragStore.current.active.style.position = 'fixed';
          dragStore.current.active.style.left = x + 'px';
          dragStore.current.active.style.top = y + 'px';
        }
        const widthDiff = startX - clientX;
        const newWidth = width - widthDiff;
        const newHeight = height - (startY - clientY);

        if (newWidth > minWidth) dragStore.current.active.style.width = newWidth + 'px';
        if (newHeight > minHeight) dragStore.current.active.style.height = newHeight + 'px';

        dragStore.current.active.style.zIndex = 100;
        // console.log({ newWidth, newHeight });

        dragStore.current.startX = evt.clientX;
        dragStore.current.startY = evt.clientY;
      }
    },
    [dragStore]
  );

  useEffect(() => {
    const section = document.getElementById('external-editor-view');
    if (!section) return console.log('no sectionRef');
    section.addEventListener('mousedown', onMouseDown);
    section.addEventListener('mouseup', onMouseUp);
    section.addEventListener('mousemove', onMouseMove);
    return () => {
      if (!section) console.log('No.');
      section.removeEventListener('mousedown', onMouseDown);
      section.removeEventListener('mouseup', onMouseUp);
      section.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseDown, onMouseUp, onMouseMove]);

  const editorContents =
    viewMode === 'pdf' ? (
      <ViewPDF />
    ) : (
      <>
        <OneEditor
          noHeaderCounting={
            contractSettings && contractSettings.format && contractSettings.format.noHeaderCounting
          }
          external
          // readOnly={!isEditing}
          onInlineCommentChange={onInlineCommentChange}
        />
      </>
    );

  const myNotesLength =
    filterCommentsChecked(myNotesValue).length + '/' + filterCommentsNotesToValue(myNotesValue).length;
  const myCommentsLength = filterCommentsNotesToValue(myCommentsValue).length;
  // console.log('share data ', sharedData);
  return (
    <>
      <ContractContext.Provider initialValue={contract}>
        <EditorWrapper onChangeOperations={onChangeOperations}>
          <Drawer
            title={<IntlMessages id="app.external.info.detailsActions" cap />}
            className="drawer-external"
            placement={'right'}
            closable={true}
            onClose={toggleDrawer}
            visible={drawerOpen}
            closeIcon={<i className="icon-btn icon icon-menu-unfold" />}
          >
            <div className="external-drawer-section">
              <InfoTable
                header={<IntlMessages id="app.general.Info" cap />}
                headerSize="5"
                className="mb-4"
                data={[
                  {
                    id: 'app.external.info.shared',
                    value: fixDate(sharedData.info.sharedAt),
                  },
                  {
                    id: 'app.external.info.expiry',
                    value: fixDate(sharedData.info.expireAt),
                  },
                ]}
              />

              <InfoTable
                header={<IntlMessages id="app.external.info.originating" cap />}
                headerSize="5"
                className="mb-4"
                data={[
                  {
                    id: 'app.external.info.originatingOrganisation',
                    value:
                      sharedData.info.originator &&
                      sharedData.info.originator.client &&
                      sharedData.info.originator.client.name ? (
                        sharedData.info.originator.client.name
                      ) : (
                        <em>
                          <IntlMessages id="app.external.info.unknown" cap />
                        </em>
                      ),
                  },
                  {
                    id: 'app.external.info.originatingPerson',
                    value:
                      sharedData.info.originator &&
                      sharedData.info.originator.user &&
                      sharedData.info.originator.user.fullName ? (
                        sharedData.info.originator.user.fullName
                      ) : (
                        <em>
                          <IntlMessages id="app.external.info.unknown" cap />
                        </em>
                      ),
                  },
                  {
                    id: 'app.external.info.originatingEmail',
                    value:
                      sharedData.info.originator &&
                      sharedData.info.originator.user &&
                      sharedData.info.originator.user.email ? (
                        sharedData.info.originator.user.email
                      ) : (
                        <em>
                          <IntlMessages id="app.external.info.unknown" cap />
                        </em>
                      ),
                  },
                ]}
              />
            </div>

            <div className="external-drawer-section">
              <h5>
                <IntlMessages id="app.general.Export" cap />
              </h5>
              <Exports contract={contract} />
            </div>

            <div className="external-drawer-section">
              <h5>
                <IntlMessages id="studio.actions.actions" cap />
              </h5>
              <Response dispatchResponse={dispatchResponse} action="reject">
                <IntlMessages id="app.external.action.reject" cap />
                <span>&nbsp;</span>
                <IntlMessages id="general.document" cap />
              </Response>
              {settings.allowFeedbackOnly && (
                <Response action="feedback" dispatchResponse={dispatchResponse}>
                  <IntlMessages id="app.messages.submit" cap />
                  <span>&nbsp;</span>
                  <IntlMessages id="app.external.action.feedback" cap />
                </Response>
              )}
              <Response dispatchResponse={dispatchResponse} action="approve">
                <IntlMessages id="app.external.action.approve" cap />
                <span>&nbsp;</span>
                <IntlMessages id="general.document" cap />
              </Response>
            </div>
          </Drawer>
          <Tour
            steps={availableTourSteps}
            isOpen={isTourOpen}
            onRequestClose={closeTour}
            className="external-tour app-tour"
            rounded={10}
            lastStepNextButton={<CloseCircleOutlined className="icon-btn icon" onClick={closeTour} />}
          />
          <div className={'external-editor-view mode-' + viewMode} id="external-editor-view">
            {!drawerOpen && (
              <div className="hamburger guide-ext-actions-menu">
                <Tooltip title={<IntlMessages id="app.external.view.openMenu" />} placement="left">
                  <i className="icon-btn icon icon-menu-fold" onClick={toggleDrawer} />
                </Tooltip>
              </div>
            )}

            <Topbar
              settings={settings}
              isEditing={isEditing}
              toggleIsEditing={toggleIsEditing}
              onChangeMode={onChangeMode}
              contract={contract}
              isTourOpen={isTourOpen}
              setIsTourOpen={setIsTourOpen}
            />

            <div id="sted" className="studio-edit-quick-actions">
              <div>
                {settings.allowGeneralComments && (
                  <Badge
                    count={myCommentsLength}
                    showZero
                    className="m-0 sted-btn"
                    style={{ backgroundColor: myCommentsLength === 0 ? 'rgb(185 185 185)' : '#457fff' }}
                  >
                    <UserCommentsNotes
                      type="comments"
                      contract={contract}
                      value={myCommentsValue}
                      onChange={onCommentsChange}
                      editors={editors}
                      setEditors={true}
                    />
                  </Badge>
                )}
                {settings.allowInlineComments && <AddComment />}
                {/* <Divider className="mb-3" /> */}
                <Badge
                  count={myNotesLength}
                  showZero
                  className="m-0 my-notes-btn"
                  style={{
                    backgroundColor:
                      myNotesLength === 0 || myNotesLength === '0/0' ? 'rgb(185 185 185)' : '#457fff',
                  }}
                >
                  <UserCommentsNotes
                    type="notes"
                    contract={contract}
                    value={myNotesValue}
                    onChange={onNotesChange}
                    addGeneralComment={addGeneralComment}
                    editors={editors}
                  />
                </Badge>
              </div>
              {/* <div
                style={{
                  marginTop: '30vh',
                }}
              ></div> */}
              <div>
                <HelpButton isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen} />
                <Response action="reject" dispatchResponse={dispatchResponse} />
                {settings.allowFeedbackOnly && (
                  <Response action="feedback" dispatchResponse={dispatchResponse} />
                )}
                <Response action="approve" dispatchResponse={dispatchResponse} />
              </div>
            </div>
            <div className="viewing-contract">
              <TOC contract={contract} />
              <div className="external-editor">{editorContents}</div>
            </div>
          </div>
        </EditorWrapper>
      </ContractContext.Provider>
    </>
  );
}

function TOC() {
  return (
    <div className="ext-toc">
      <CustomScrollbars autoHeight autoHeightMin={500} autoHeightMax={'calc(100vh - 170px)'}>
        <TableOfContents noHeader hideIfOne />
      </CustomScrollbars>
    </div>
  );
}

function UserCommentsNotes({ contract, type, value, onChange, addGeneralComment, editors, setEditors }) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  let tooltip;

  const icon = type === 'notes' ? 'mdi-playlist-check' : 'mdi-comment-account-outline';

  if (open) {
    tooltip = (
      <>
        <IntlMessages id="desc.Close" cap /> <IntlMessages id={'app.external.' + type + '.label'} />
      </>
    );
  } else {
    tooltip = <IntlMessages id={'app.external.' + type + '.label'} />;
  }
  return (
    <div className="position-relative">
      {open && (
        <div id={'notes-container-' + type} className={'notes-container ' + type}>
          <CommentsNotesEditor
            type={type}
            content={value}
            onChange={onChange}
            addGeneralComment={addGeneralComment}
            editors={editors}
            setEditors={setEditors}
            close={toggle}
          />
          <i id={'notes-container-resize-' + type} className="resize mdi mdi-resize-bottom-right" />
        </div>
      )}
      <Tooltip title={tooltip} placement="left">
        <Button
          size="large"
          shape="circle"
          onMouseDown={toggle}
          className={'sted-btn guide-ext-user-editor-' + type + ' ' + (open ? 'active ant-btn-primary' : '')}
        >
          <i className={'mdi ' + (open ? 'mdi-close' : icon)} />
        </Button>
      </Tooltip>
    </div>
  );
}

function Topbar({ settings, isEditing, toggleIsEditing, onChangeMode }) {
  let topContent;
  if (isEditing) {
    topContent = (
      <div className="external-toolbar">
        <TopToolbar />
      </div>
    );
  } else {
    topContent = (
      <div className="external-tabs-holder">
        <Tabs defaultActiveKey="1" onChange={onChangeMode} className="external-tabs guide-ext-view-options">
          <TabPane tab={<IntlMessages id="app.external.view.standard" cap />} key={'default'}></TabPane>
          {/* <TabPane tab={<IntlMessages id="app.external.view.standard" cap />} key={'read'}></TabPane> */}
          <TabPane tab={<IntlMessages id="app.external.view.pdf" cap />} key={'pdf'}></TabPane>
        </Tabs>
      </div>
    );
  }

  return (
    <div className="external-top">
      <div className="external-top-left">
        {settings.allowFeedbackOnly && (
          <ToggleToolbarButton isEditing={isEditing} toggleIsEditing={toggleIsEditing} />
        )}
      </div>
      {topContent}
    </div>
  );
}

function ToggleToolbarButton({ isEditing, toggleIsEditing }) {
  const editor = useEditor();
  const icon = isEditing ? <EditFilled /> : <EditOutlined />;
  const tooltip = isEditing ? (
    <div className="text-center">
      <IntlMessages id="app.external.view.disableEdit" cap />
    </div>
  ) : (
    <IntlMessages id="app.external.view.enableEdit" cap />
  );

  const clickToggle = () => {
    const allowEditing = !isEditing;
    editor.meta.allowEditing = allowEditing;
    toggleIsEditing();
  };

  return (
    <div
      className={
        'external-toolbar-toggle guide-ext-toggle-topbar clickable ' + (isEditing ? 'text-primary' : '')
      }
      onClick={clickToggle}
    >
      <Tooltip title={tooltip} placement="right">
        {icon}
      </Tooltip>
    </div>
  );
}

function HelpButton({ isTourOpen, setIsTourOpen }) {
  const icon = isTourOpen ? <QuestionCircleOutlined /> : <QuestionOutlined />;
  const tooltip = isTourOpen ? undefined : <IntlMessages id="app.external.view.getHelp" cap />;

  return (
    <div>
      <Tooltip title={tooltip} placement="left">
        <Button
          icon={icon}
          size="large"
          shape="circle"
          className="sted-btn"
          onMouseDown={() => setIsTourOpen(!isTourOpen)}
        ></Button>
      </Tooltip>
    </div>
  );
}

function Exports({ contract }) {
  return (
    <div className="external-exports guide-ext-exports">
      <span className="link">
        <FilePdfOutlined className="mr-2" /> <IntlMessages id="app.external.info.exportPDF" />
      </span>
      <span className="link mt-1">
        <FileWordFilled className="mr-2" /> <IntlMessages id="app.external.info.exportWord" />
      </span>
    </div>
  );
  /* const { initDownloadPDF, downloadResultPDF, loadingPDF, errorLoadingPDF } = useDownloadPDF(contract);
  const { initDownloadDOCX, loadingDOCX } = useDownloadDOCX(contract);

  return (
    <div className="external-exports guide-ext-exports">
      {downloadResultPDF}
      {downloadResultPDF}
      {loadingPDF && <Spin className="loader-version-exports" />}
      {loadingDOCX && <Spin className="loader-version-exports" />}
      <span className="link" onClick={initDownloadPDF}>
        <FilePdfOutlined className="mr-2" /> <IntlMessages id="app.external.info.exportPDF" />
      </span>
      <span className="link mt-1" onClick={initDownloadDOCX}>
        <FileWordFilled className="mr-2" /> <IntlMessages id="app.external.info.exportWord" />
      </span>
    </div>
  ); */
}
