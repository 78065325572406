import { getByPath } from '../utils/general'

const State = {};

State.produceContractState = (state) => {
  return {
    input: state.input || {},
    legalPersons: state.legalPersons || {},
    realPersons: state.realPersons || {}
  }
}

State.get = (state, path) => {
  if (!state || !path) return null
  let result = getByPath(state, path)
  if (!result) {
    const inputPath = `input.${path}`
    result = getByPath(state, inputPath)
  }
  return result || null
}

export { State }