import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Card, Spin } from 'antd';
import { addUiModules } from 'appRedux/inputModule/';
import manager from 'core/engine/manager';
import { useProject, useDraftPreviousLocation } from 'hooks';
import { DraftContext, ContractContext, ContractUpdateContext } from 'contexts/contexts';
import useStudioLoad from 'utils/initDraft/1-studioLoad'; // Loads a template or an existing contract draft.
import { EditorWrapper } from 'components/editor/OneEditor';
import StudioWrapper from './containers/StudioWrapper/';
/* import StudioContainer from './containers/StudioContainer/'; */
import StudioContractInputEdit from './containers/ContractInputEdit/';
import { Contract } from 'core/interfaces';
import IntlMessages from 'util/IntlMessages';

import convertContractRules from 'core/utils/logic/convertContractRules.js'

export default function MainStudio() {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { id, action, subject } = useParams();
  const previousLocation = useDraftPreviousLocation();

  const [isLoaded, setIsLoaded] = useState({ status: null });
  const studioLoad = useStudioLoad(subject);

  const [loadedContract, setLoadedContract] = useState(null);

  const activeDraft = manager.drafts[0] && manager.drafts[0];
  const contract = (manager.drafts[0] && manager.drafts[0].contract) || loadedContract;
  const ui = Contract.getUi(contract);
  const project = useProject(contract?.projectId);

  const contractModules = contract?.data?.create?.contractModules?.input || contract?.data?.create?.modules;


  useEffect(() => {
    if (!contractModules || subject === 'template') return;
    addUiModules(contractModules);
  }, [contractModules, subject]);

  useEffect(() => {
    (async function () {
      // const load = await loader(id);
      const load = await studioLoad(id);
      if (load) {

        convertContractRules(load.contract)

        if (location.pathname.split('/').length === 5) {
          // If sent to /draft/:id/input we need to re-route to first path, e.g. /draft/:id/input/facilities
          if (action === 'input') {
            const initPath = load.firstPath;
            setIsLoaded({ status: true });
            if (subject === 'template') setLoadedContract(load.contract);
            return history.replace(initPath);
          } else if (action === 'contract') {
            setIsLoaded({ status: true });
            if (subject === 'template') setLoadedContract(load.contract);
            return history.replace(location.pathname + '/view');
          }
        }
        if (subject === 'template') setLoadedContract(load.contract);

        setIsLoaded({ status: true });
      } else {
        console.log('Failed to load: is ', load);
        setIsLoaded({ status: false, message: <IntlMessages id="errors.loadFail" /> });
      }
    })();
    // Only on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoaded.status === false) {
    return (
      <Card title="" className="card m-3">
        {isLoaded.message && <p>Load error: {isLoaded.message}</p>}
        <p>
          <IntlMessages id="studio.errors.load" />
        </p>
        {previousLocation && previousLocation.pathname && (
          <p>
            <span className="link" onClick={() => history.push(previousLocation.pathname)}>
              <IntlMessages id="desc.Back" />
            </span>
          </p>
        )}
        <p>
          <span className="link" onClick={() => history.push('/projects')}>
            <IntlMessages id="general.goto" /> <IntlMessages id="general.projects" />
          </span>
        </p>
      </Card>
    );
  }

  if (
    !checkLoadedStudio(project, subject === 'template' ? true : activeDraft, contract, ui, isLoaded.status)
  ) {
    /* console.log({
      project,
      activeDraft,
      contract,
      ui,
      status: isLoaded.status,
    }); */
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spin size="large" />
      </div>
    );
  }
  return (
    <>
      <DraftContext.Provider initialValue={activeDraft}>
        <ContractContext.Provider initialValue={contract}>
          <EditorWrapper>
            {/* <ContractUpdateContext.Provider initialValue={{}}> */}
              <StudioWrapper subject={subject} action={action} footer={null}>
                
              </StudioWrapper>
            {/* </ContractUpdateContext.Provider> */}
          </EditorWrapper>
          <StudioContractInputEdit />
        </ContractContext.Provider>
      </DraftContext.Provider>
    </>
  );
}

function checkLoadedStudio() {
  for (const arg of arguments) if (!arg) return false;
  return true;
}