import React from 'react';
import { Form, Checkbox, Tooltip, Button, Typography } from 'antd';
import uuid from 'uuid-random';
import { useDispatch } from 'react-redux';
import { addQA, removeQA, updateQA } from 'appRedux/actions';
import { getValue } from './utils/values';
const { Paragraph } = Typography;

export default function QandA(props) {
  const { label, path, isTemplate } = props;
  const dispatch = useDispatch();
  const value = getValue(props, 'as');
  const onAddQA = () => {
    dispatch(addQA(path, uuid()));
  };

  const onUpdateQA = (uid, type, val) => {
    dispatch(updateQA(path + '.' + uid + '.' + type, val));
  };

  const onRemoveQA = (uid) => {
    dispatch(removeQA(path, uid));
  };

  const keys = Object.keys(value);

  return (
    <div>
      <div className={"d-flex border-bottom mb-3 align-items-center"+(isTemplate ? ' mb-4' : '')}>
        <div>
          <h5>{label}</h5>
        </div>
        <div className="ml-auto position-relative">
          {isTemplate ? (
            <Button onClick={onAddQA} type="primary" className="m-0" size='small' style={{
              position: 'absolute',
              top: '13px',
              right: '0'
            }}>
              Add Question
            </Button>
          ) : (
            <Button onClick={onAddQA} type="primary" className="m-0 mb-1">
              Add Question
            </Button>
          )}
        </div>
      </div>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '10%' }}>
              <span className="mr-2">#</span>
            </td>
            <td style={{ width: '30%' }}>
              <span className="mr-3">Question</span>
            </td>
            <td style={{ width: '45%' }}>
              <span className="ml-3">Answer</span>
            </td>
            <td style={{ width: '15%' }}>
              <span className="ml-3">Action</span>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ height: '20px' }}></div>
            </td>
          </tr>
          {keys.map((key, index) => (
            <tr key={key}>
              <td className="">
                <span className="mr-2">{index + 1}</span>
              </td>
              <td className="">
                <div className="d-flex">
                  <Paragraph
                    onClick={(evt) => evt.stopPropagation()}
                    editable={{ onChange: (val) => onUpdateQA(key, 'q', val) }}
                    className="mb-0 mr-3"
                  >
                    {value[key].q || ''}
                  </Paragraph>
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <Paragraph
                    onClick={(evt) => evt.stopPropagation()}
                    editable={{ onChange: (val) => onUpdateQA(key, 'a', val) }}
                    className="mb-0 ml-3"
                  >
                    {value[key].a || ''}
                  </Paragraph>
                </div>
              </td>
              <td style={{ width: '20%' }}>
                <Button onClick={() => onRemoveQA(key)} className="m-0">
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
