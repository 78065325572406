import {
  SET_REALPERSONS,
  UPDATE_REALPERSON,
  INSERT_REALPERSON,
  REMOVE_REALPERSON,
} from 'constants/ActionTypes';
import uuid from 'uuid-random';

export const setRealPersons = (data) => {
  return {
    type: SET_REALPERSONS,
    payload: data,
  };
};

export const updateRealPerson = (id, data) => {
  return (dispatch, getState) => {
    const realPersons = [...getState().realPersons];
    const updatingIndex = realPersons.findIndex((data) => id === data.id);
    if (updatingIndex === -1) {
      console.log('Could not find realPerson with id ' + id, realPersons);
      return;
    }
    const realPerson = JSON.parse(JSON.stringify(realPersons[updatingIndex]));

    Object.assign(realPerson, data);

    dispatch({
      type: UPDATE_REALPERSON,
      payload: { index: updatingIndex, realPerson },
    });
  };
};

export const insertRealPerson = (data = {}) => {
  return (dispatch, getState) => {

    let { id } = data
    if (!id) id = uuid();

    dispatch({
      type: INSERT_REALPERSON,
      payload: Object.assign({ id }, data),
    });
  };
};

export const removeRealPerson = (id) => {
  return (dispatch, getState) => {
    return dispatch({
      type: REMOVE_REALPERSON,
      payload: [id],
    });
  };
};
