import { updateReferences } from "../../utils";

export const genesis = function (state, opts = {}) {

  if (!state || !state.input) {
    throw new Error("Invalid state to engine contract genesis.")
  };

  this.beginDraft();

  // We need to - here already - set the current state so that it
  // is available within the engine for the handlers running in the
  // 'onGenesis' phase. 
  this.setCurrentState(state)

  const api = this.api;

  const runGenesisFlows = () => {
    const isInitialized = this.isInitialized();
    this.handlers
      .filter((handler) => handler.dependencies && handler.dependencies.onGenesis)
      .forEach((handler) => {
        if (typeof handler.handler !== "function") return;

        const fn = handler.handler.call(this, {
          state,
          isInitialized,
          invoked: { onGenesis: true },
          api
        });

        this.runFlow(fn, this.getContent(), handler)
      });
  };

  runGenesisFlows();

  // Update references
  this._setContent(updateReferences(this.contract, {draftValue: this.draftValue}))


  // Sort definitions
  this.sortDefinitions()

  // Set engine variables.
  const draftInfo = this.getDraftInfo();
  draftInfo.status.loaded = true;
  draftInfo._firstTimeDraft = this._draftTime;
  
  // Return the result of the finishDraft method. 
  return this.finishDraft(state);

};
