import React from 'react';
import { Spin, Tooltip } from 'antd';
import IconCard from 'components/Widgets/IconCard';

export default function ButtonIcon({
  bgColor = 'primary',
  iconColor = 'white',
  icon,
  title = '',
  tooltip,
  loading = false,
  onClick,
}) {
  let card;
  if (loading) {
    card = (
      <IconCard color={`bg-${bgColor} icon-${iconColor}`} className="custom-icon-btn m-0">
        <Spin size="small" className="spinner-in-iconcard" />
      </IconCard>
    );
  } else {
    card = (
      <IconCard color={`bg-${bgColor} icon-${iconColor}`} className="custom-icon-btn m-0" mdi icon={icon} />
    );
  }

  let content = (
    <div
      onClick={onClick}
      className={
        'flex-column justify-content-center align-items-center clickable button-icon-holder ' +
        (tooltip ? 'tt' : '')
      }
    >
      {card}

      {title && <small className={`text-center`}>{title}</small>}
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip placement="top" title={tooltip}>
        {content}
      </Tooltip>
    );
  }

  return content
}
