import IntlMessages from 'util/IntlMessages';

export default function MenuHeader({ title = '', menu = false, intl }) {
  let label = intl ? <IntlMessages id={intl} /> : title;
  return (
    <div className={'ml-1' + (menu ? ' mt-3 mb-2' : '')}>
      <small>
        <strong className="ml-2 text-uppercase">{label}</strong>
      </small>
    </div>
  );
}
