import React from "react";
import { setModalContext, useContract } from "hooks";
import { useDispatch } from "react-redux";
import { setDraft } from "appRedux/actions";
import IntlMessages from "util/IntlMessages";

export default function ({ warnings }) {
  const contract = useContract();
  const dispatch = useDispatch();
  
  const clearWarning = (id) => {
    dispatch(
      setDraft('warnings', warnings.filter((warning) => warning.id !== id)),
    );
  }

  const showWarning = (warning) => {
    setModalContext({
      type: warning.type,
      title: warning.text,
      diffList: warning.data.diffList,
      latestStructure: warning.data.latestStructure,
      contract,
      clearWarning: () => clearWarning(warning.id),
    });
  }
  
  const menuItems = warnings.map((warning, idx) => (
    <li key={idx} className="draft-input-menu-item link" onClick={() => showWarning(warning)}>
      <span className="draft-input-menu-item-text">{warning.text}</span>
    </li>
  ));

  return (
    <div>
      <h4><IntlMessages id={"studio.sidebar.warnings"} /></h4>
      <ul style={{ listStyleType: 'disc' }} >
        {menuItems}
      </ul>
    </div>
  );
}
