import uuid from 'uuid-random';
import { v4 as isUuidV4 } from '../../import/is-uuid';

const RealPerson = {};

RealPerson.construct = function (basePerson, parentPerson = {}) {
  return {
    id: isUuidV4(basePerson.id) ? basePerson.id : uuid(),
    firstName: basePerson.firstName,
    lastName: basePerson.lastName,
    jurisdiction: basePerson.jurisdiction || basePerson.country || 'se',
    identificationNumber: basePerson.identificationNumber || basePerson.regno || '',
    street: basePerson.street || '',
    city: basePerson.city || '',
    zipcode: basePerson.zipcode || '',
    emails: basePerson.emails || [],
    data: basePerson.data || {},
  };
};

RealPerson.isRealPerson = function (obj) {
  return (
    obj.hasOwnProperty('id') &&
    !obj.hasOwnProperty('parentId') &&
    obj.hasOwnProperty('firstName') &&
    obj.hasOwnProperty('lastName') &&
    obj.hasOwnProperty('identificationNumber')
  );
};

export { RealPerson };
