
export const initSetupValues = {
  id: "initSetupValues",
  dependencies: { onGenesis: true },
  handler: function ({api}) {
    
    const setup  = api.interfaces.Contract.getSetup(this.contract);
    if(!setup) return

    return (node, parents) => {
    
      if(node.variant !== "setupValue") return

      let value;
      if (setup[node.data.name]) value = setup[node.data.name];
      else value = this.emptyContractString();
      
      api.utils.engine.setFirstChild(node, value)
      
    }
    
  },
};
