import React, { useState } from 'react';
import { Avatar, Dropdown, Menu, Input, Button, Badge } from 'antd';
import { useIsSidebarMini, useContract, useStudioAutoSave } from 'hooks';
// import { Prompt } from 'react-router'
import contractLabels from 'core/config/contractLabels';
import updateVersion from 'utils/saving/updateVersion';
import { Contract } from 'core/interfaces';
import IntlMessages from 'util/IntlMessages';

const ContractInfo = () => {
  const contract = useContract();
  const [dropdownVisisble, setDropdownVisible] = useState(false);
  const handleDropDownVisibleChange = (flag) => {
    if (flag === false) setIsChangingName(false);
    setDropdownVisible(flag);
  };
  const handleMenuItemClick = (e) => {
    if (e.key === 'nameChange') return;
    setIsChangingName(false);
    setDropdownVisible(false);
  };

  const [isChangingName, setIsChangingName] = useState(false);
  const toggleIsChangingName = () => setIsChangingName(!isChangingName);
  const cancelNameChange = () => {
    setNameChange('');
    setIsChangingName(false);
    setDropdownVisible(false);
  };
  const updateName = () => {
    console.log('Update name to ', contract, nameChange);
    const versionId = Contract.getId(contract);
    Contract.setName(contract, nameChange);
    updateVersion(versionId, { name: nameChange }, { notify: true });
    cancelNameChange();
  };

  const [nameChange, setNameChange] = useState('');
  const handleNameChangeUpdate = (e) => setNameChange(e.target.value);

  const name = Contract.getName(contract) || 'Untitled';

  // { color: "#f56a00", backgroundColor: "#fde3cf" }

  const isMini = useIsSidebarMini();

  const menu = (
    <Menu onClick={handleMenuItemClick}>
      <Menu.Item key="nameChange">
        {!isChangingName ? (
          <span onClick={toggleIsChangingName}>
            <IntlMessages id="studio.ChangeName" />
          </span>
        ) : (
          <div>
            <div>
              <Input value={nameChange} onChange={handleNameChangeUpdate} />
            </div>
            <div className="mt-2">
              <Button type="primary" size="small" onClick={updateName}>
                <IntlMessages id="studio.UpdateName" />
              </Button>
              <Button size="small" onClick={cancelNameChange}>
                <IntlMessages id="desc.Cancel" />
              </Button>
            </div>
          </div>
        )}
      </Menu.Item>
      {/* <Menu.Item>Invite collaborators *</Menu.Item> */}
    </Menu>
  );

  return (
    <div className="flex-column align-items-center justify-content-center py-2 contract-info-box">
      <AutoSaveIndicator contract={contract} />
      {!isMini && <span className="text-center text-white fs-sm">{name}</span>}
      {/* <Dropdown
        overlay={menu}
        trigger={['click']}
        onVisibleChange={handleDropDownVisibleChange}
        visible={dropdownVisisble}
      >
        <span className="fs-sm text-center">
          {!isMini ? <span className="mr-2 text-center text-white">{name}</span> : <span></span>}
          <i className="icon icon-chevron-down fs-xxs clickable" />
        </span>
      </Dropdown> */}
    </div>
  );
};

function AutoSaveIndicator({ contract }) {
  const short = Contract.getShortName(contract) || 'O';
  const labels = Contract.getLabels(contract) || [];
  const labelKey = labels.find((label) => contractLabels[label]) || '__unknown__';
  const labelStyle = contractLabels[labelKey];

  let pendingChangesStatus = useStudioAutoSave();
  const unsavedChanges = pendingChangesStatus === 'warning'; // Check in a cleaner way?

  return (
    <>
      {/* <Prompt
        when={unsavedChanges}
        message="There are unsaved changes. Are you sure you want to leave this page? (Work in progress. Need to be able to go to some other routes)"
      /> */}
      <Badge dot status={pendingChangesStatus} className="mx-0 document-badge">
        <Avatar className="mb-2" style={labelStyle}>
          {short}
        </Avatar>
      </Badge>
    </>
  );
}

export default ContractInfo;
