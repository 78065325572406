import React, { memo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { ConfigProvider, Modal, Button } from 'antd';
import { userSignOut } from 'appRedux/actions/Auth';

import { IntlProvider } from 'react-intl';
import OverlaySignIn from './OverlaySignIn';
import { CustomerContext, ProjectContext, ModalContext, PermissionsTableContext } from 'contexts/contexts';
import { AbilityContext } from 'permissions/Abilities';
import { ability } from 'permissions/permissions';

import AppLocale from 'lngProvider';
import MainApp from './MainApp';

import Studio from '../../routes/studio';

import SignIn from '../SignIn';
// import SignUp from "../SignUp";

import { setInitUrl } from 'appRedux/actions/Auth';

import VerifyUser from 'routes/users/VerifyUser';
import ForgotPassword from 'routes/users/ForgotPassword';
import ResetPassword from 'routes/users/ResetPassword';

import CustomModal from 'components/CustomModal/index';
import CustomAlert from 'components/CustomAlert/index';

import api from 'utils/api';

const DEBUG = process.env.REACT_APP_NODE_ENV === 'development';

const RestrictedRoute = ({ component: Component, location, user, ...rest }) => {
  /* useEffect(() => {
    dispatch(setInitUrl(location.pathname));
  }, [location.pathname]) */

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const App = (props) => {
  const dispatch = useDispatch();
  const { auth, settings } = useSelector((state) => state);
  const { locale } = settings;

  const { user, initURL } = useSelector(({ auth }) => auth);
  const [permissionTable, setPermissionTable] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    api
      .get('/permissions/table')
      .then((res) => res.data && setPermissionTable(res.data))
      .catch((err) => console.log('Cannot fetch permissions table', { err, repsonse: err.response }));
  }, []);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const goto = useCallback(
    (path, initURL, why, currentPath) => {
      if (DEBUG) console.log('Goto: ', { path, initURL, why, currentPath });
      history.push(path);
    },
    [history]
  );

  useEffect(() => {
    if (location.pathname === '/') {
      if (user === null) {
        goto('/signin', initURL, 1, location.pathname);
      } else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/verify') {
        // console.log('Shall change to main')
        goto('/main', initURL, 2, location.pathname);
      } else {
        goto(initURL, initURL, 3, location.pathname);
      }
    } else if (location.pathname === '/signin' && user) {
      if (initURL && initURL !== '/') {
        goto(initURL, initURL, 4, location.pathname);
        dispatch(setInitUrl('/main'));
      }
      else goto('/main', initURL, 5, location.pathname);
    }
  }, [user, initURL, location, history, goto, dispatch]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <PermissionsTableContext.Provider value={permissionTable}>
          <ModalContext.Provider initialValue={null}>
            <CustomModal />
            <CustomAlert />
            <CustomerContext.Provider initialValue={null}>
              <ProjectContext.Provider initialValue={null}>
                <AbilityContext.Provider value={ability}>
                  <Switch>
                    <Route exact path="/signin" component={SignIn} />
                    {/* <Route exact path="/signup" component={SignUp} /> */}
                    <Route exact path="/verify" component={VerifyUser} />
                    <Route exact path="/forgotpwd" component={ForgotPassword} />
                    <Route exact path="/resetpwd" component={ResetPassword} />
                    {auth.authOverlay && (
                      <Modal
                        title="Sign in"
                        visible={auth.authOverlay}
                        // onOk={this.handleOk}
                        onCancel={() => {
                          dispatch(userSignOut());
                          history.push('/signin');
                        }}
                        footer={[
                          <Button
                            key="back"
                            onClick={() => {
                              dispatch(userSignOut());
                              history.push('/signin');
                              // history.push("/");
                            }}
                          >
                            Cancel &amp; Logout
                          </Button>,
                        ]}
                      >
                        <OverlaySignIn />
                      </Modal>
                    )}
                    <RestrictedRoute
                      path="/studio/:subject/:id/:action"
                      user={user}
                      location={location}
                      component={Studio}
                    />
                    <Redirect exact from="/draft/:id" to="/studio/draft/:id/input" />
                    <Redirect exact from="/studio/draft/:id" to="/studio/draft/:id/input" />

                    {/* <RestrictedRoute
                      path="/ext/:type/:id"
                      user={user}
                      location={location}
                      component={ExternalView}
                    /> */}
                    <RestrictedRoute
                      path={`${match.url}`}
                      user={user}
                      location={location}
                      component={MainApp}
                    />
                  </Switch>
                </AbilityContext.Provider>
              </ProjectContext.Provider>
            </CustomerContext.Provider>
          </ModalContext.Provider>
        </PermissionsTableContext.Provider>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
