export const groupFieldModule = {
  id: 'groupFieldModule',
  dependencies: { legalPersonAny: true, repeatableChange: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    return (node, parents) => {
      if (!api.utils.engine.matchModule(node, 'facilityAgreement', 'groupField')) return;

      const topCo = api.utils.entities.findTopCo(state.legalPersons);
      if (topCo && topCo.name) {
        const args = (node.data && node.data.module && node.data.module.arguments) || {};
        const { useDefinition } = args;
        const { name } = topCo;
        let displayName = name;
        if (useDefinition) {
          displayName = this.companyDefinition(name);
        } else {
          const mapped = this.mapCompanies(name);
          if (mapped && mapped.perfectMatch && mapped.fullText) displayName = mapped.fullText;
        }
        api.utils.engine.setFirstChild(node, displayName);
        this.markNodeUpdate(node);
      }
    };
  },
};
