import React, { useState } from "react";
import {
  Form,
  Checkbox
} from "antd";

export default function ({ onChildUpdate, currentInput }) {
  const [defaultValue, setDefaultValue] = useState(currentInput ? currentInput.value : false);

  const onChangeDefaultValue = () => {
    const newValue = !defaultValue;
    setDefaultValue(newValue);
    onChildUpdate({ defaultValue: newValue });
  };
  
  return (
    <>
      <Form layout="vertical">
        <Form.Item
          label={"Default checked"}
          extra={
            <div>
              <small>
                Whether or not the checkbox should be default selected
              </small>
            </div>
          }
        >
          <Checkbox checked={defaultValue} onChange={onChangeDefaultValue} />
        </Form.Item>
      </Form>
    </>
  );
}
