import React, {useState} from "react"
import { Badge } from "antd"

export const ProvisionIndicator = ({ editor, element }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  let StatusBadge;
  if (!element.provision.state || !element.provision.state.status)
    StatusBadge = <Badge status="warning" />;
  else if (element.provision.state.status === "fulfilled")
    StatusBadge = <Badge status="success" />;
  else if (element.provision.state.status === "undetermined")
    StatusBadge = <Badge status="warning" />;
  else if (element.provision.state.status === "unfulfilled")
    StatusBadge = <Badge status="danger" />;
  else StatusBadge = <Badge status="warning" />;

  return (
    <>
      <div
        className="contract-provision clickable"
        onMouseDown={toggleOpen}
        contentEditable={false}
      >
        {open && (
          <div className="contract-provision-content">
            <table>
              <tbody>
                <tr>
                  <td>Provision</td>
                  <td>
                    {(element.provision.data &&
                      element.provision.data.info &&
                      element.provision.data.info.label) ||
                      "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{element.provision.type || "N/A"}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    {StatusBadge} (
                    {(element.provision.state &&
                      element.provision.state.status) ||
                      "Unknown"}
                    )
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};