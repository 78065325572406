import React, { useState, useCallback } from 'react';
import { Button, Modal } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useIntlMessage } from 'util/IntlMessages';

/* const ModalBody = ({children, ...rest}) => {
  return <div {...rest}>{children}</div>
} */

export function RemoveModal({ onConfirm, confirmText, children, onClick, onCancel }) {
  const formatMessage = useIntlMessage();
  const [visible, setVisible] = useState(false);

  const onClickItem = () => {
    setVisible(true);
  };

  const onConfirmClick = (evt) => {
    setVisible(false);
    if (typeof onConfirm === 'function') onConfirm(evt);
  };
  const onCancelClick = (evt) => {
    setVisible(false);
    if (typeof onCancel === 'function') onCancel(evt);
  };


  return (
    <>
      <Modal
        title={null}
        onOk={onConfirmClick}
        okText={formatMessage('desc.Remove')}
        okButtonProps={{ className: 'btn-danger' }}
        onCancel={onCancelClick}
        cancelText={formatMessage('desc.Cancel')}
        visible={visible}
        className="ant-modal ant-modal-confirm ant-modal-confirm-confirm"
      >
        <div className="d-flex">
          <div>
            <ExclamationCircleOutlined className="text-danger fs-xxxl mr-4" />
          </div>
          <div>
            <div>
              <h3>{formatMessage('desc.confirmRemoval')}</h3>
            </div>
            <div className="mt-3">{confirmText}</div>
          </div>
        </div>
      </Modal>
      {React.cloneElement(children, {
        onClick: (e) => {
          if (React.isValidElement(children)) {
            children?.props.onClick?.(e);
          }
          if (onClick) {
            onClick(e)
          }
          onClickItem(e);
        },
      })}
    </>
  );
}

export const useModal = function (title = '', body = <div></div>, opts = {}) {
  if (!opts.defaultOpen) opts.defaultOpen = false;
  if (!opts.size) opts.size = 'md';
  const [open, setOpen] = useState(opts.defaultOpen);
  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const close = useCallback(() => {
    if (typeof opts.setOpen === 'function') return opts.setOpen(false);
    return setOpen(false);
  }, [opts]);

  const element = (
    <div>
      <Modal
        title={title}
        visible={open}
        onCancel={toggleOpen}
        backdrop={'static'}
        size={opts.size}
        footer={[
          <Button key="closebtn" type="secondary" onClick={close}>
            <IntlMessages id="desc.Close" />
          </Button>,
        ]}
      >
        <div>
          <div className="mb-3">{body}</div>
        </div>
      </Modal>
    </div>
  );

  return [element, toggleOpen, setOpen];
};

export const useModalContent = function (title = '', opts = {}) {
  if (!opts.defaultOpen) opts.defaultOpen = false;
  const [open, setOpen] = useState(opts.defaultOpen);
  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const close = useCallback(() => {
    if (typeof opts.onClose === 'function') opts.onClose();
    if (typeof opts.onCancel === 'function') opts.onCancel();
    if (typeof opts.setOpen === 'function') return opts.setOpen(false);
    return setOpen(false);
  }, [opts]);

  const buttons = [
    <Button key="closebtn" type="secondary" onClick={close}>
      {opts.closeText || 'Close'}
    </Button>,
  ];

  if (opts.ButtonOK) buttons.push(opts.ButtonOK);

  const element = ({ children, className, bodyClassName, ...props }) => (
    <div>
      <Modal
        title={title}
        visible={open}
        toggle={toggleOpen}
        onCancel={close}
        backdrop={'static'}
        footer={buttons}
        wrapClassName={className || ''}
        {...props}
      >
        <div className={bodyClassName ? bodyClassName : ''}>
          <div className="mb-1">{children}</div>
        </div>
      </Modal>
    </div>
  );

  return [element, toggleOpen, setOpen, open];
};
