import uuid from 'uuid-random';
import { Editor, Text, Path } from '../../import/slate'

export const LIST_TYPES = {
  NUMBERED_LIST: 'numbered_list',
  BULLETED_LIST: 'bulleted_list',
};

export const BLOCK_TYPES = {
  SECTION: 'section',
  CLAUSE: 'clause',
  DEFINITIONS: 'definitions',
  SCHEDULE: 'schedule',
  PARAGRAPH: 'paragraph',
  HEADING_ONE: 'heading_one',
  HEADING_TWO: 'heading_two',
  HEADING_THREE: 'heading_three',
  SCHEDULE_ONE: 'schedule_one',
  SCHEDULE_TWO: 'schedule_two',
  LIST_ITEM: 'list_item',
  TABLE: 'table',
  TABLE_ROW: 'table_row',
  TABLE_CELL: 'table_cell',
  TABLE_CONTENT: 'table_content',
  IMAGE: 'img',
  TEXTBOX: 'textbox',
  NOTES_LIST_ITEM: 'notes_list_item',
  ...LIST_TYPES,
};

const ELEMENT_DESCRIPTIONS = {
  schedule_one: 'schedule heading',
  schedule_two: 'schedule heading',
  definitions: 'definitions holder',
  numbered_list: 'list',
  bulleted_list: 'list',
  list_item: 'list item',
  heading_one: 'heading',
  heading_two: 'heading',
  heading_three: 'heading',
  table_row: 'table row',
  table_cell: 'table cell',
  table_content: 'table content',
  img: 'image',
  NOTES_LIST_ITEM: 'general comment',
};

export const describe = (element) => ELEMENT_DESCRIPTIONS[element.type] || element.type;

export const INLINE_TYPES = {
  FIELD: 'field',
  USER_INPUT: 'user_input',
  tab: 'tab',
};

export const TYPES = {
  ...BLOCK_TYPES,
  ...INLINE_TYPES,
};

export const xac = (editor, element, path) => {
  const all = Array.from(Editor.nodes(editor, { at: path })).reverse();
  let lastPath;
  for (const [node, nodePath] of all) {
    if (Text.isText(node)) {
      lastPath = nodePath;
      continue;
    }
    let type = Editor.isEditor(node) ? '$root' : node.type;
    const accepts = accepting[type];
    if (!accepts) {
      lastPath = nodePath;
      continue;
    }
    for (const acc of accepts) {
      if (matchAccepting(acc, element)) {
        return {
          acceptedAt: nodePath,
          firstPos: lastPath,
          nextPos: Path.next(lastPath),
        };
      }
    }
    lastPath = nodePath;
  }
};

export const accepting = {
  $root: [{ type: 'section' }],
  section: [
    { type: 'clause' },
    { type: 'schedule' },
    { type: 'paragraph' },
    { type: 'heading_one' },
    { type: 'heading_two' },
    { type: 'heading_three' },
    { type: 'numbered_list' },
    { type: 'bulleted_list' },
    { type: 'table' },
    { type: 'img' },
    { type: 'textbox' },
  ],
  clause: [
    { type: 'paragraph' },
    { type: 'clause' },
    { type: 'definitions' },
    { type: 'container' },
    { type: 'heading_one' },
    { type: 'heading_two' },
    { type: 'heading_three' },
    { type: 'numbered_list' },
    { type: 'bulleted_list' },
    { type: 'table' },
    { type: 'img' },
    { type: 'textbox' },
  ],
  container: [
    { type: 'paragraph' },
    { type: 'clause' },
    { type: 'container' },
    { type: 'heading_one' },
    { type: 'heading_two' },
    { type: 'heading_three' },
    { type: 'schedule' },
    { type: 'schedule_one' },
    { type: 'schedule_two' },
    { type: 'numbered_list' },
    { type: 'bulleted_list' },
    { type: 'table' },
    { type: 'img' },
    { type: 'textbox' },
  ],
  schedule: [
    { type: 'schedule_one', default: true },
    { type: 'paragraph', default: true },
    { type: 'container' },
    { type: 'schedule_two' },
    { type: 'table' },
    { type: 'numbered_list' },
    { type: 'bulleted_list' },
  ],
  schedule_one: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }],
  schedule_two: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }],
  definitions: [
    { type: 'paragraph' },
    { type: 'numbered_list' },
    { type: 'bulleted_list' },
    { type: 'table' },
  ],
  numbered_list: [
    { type: 'list_item' },
    { type: 'numbered_list' },
    { type: 'bulleted_list' },
    { type: 'clause' },
  ],
  bulleted_list: [
    { type: 'list_item' },
    { type: 'numbered_list' },
    { type: 'bulleted_list' },
    { type: 'clause' },
  ],
  list_item: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }, { type: 'table' }],
  paragraph: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }, { type: 'container' }, { type: 'internallink' }],
  heading_one: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }],
  heading_two: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }],
  heading_three: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }],
  table: [{ type: 'table_row' }],
  table_row: [{ type: 'table_cell' }],
  table_cell: [{ type: 'table_content' }],
  table_content: [
    { type: 'text' },
    { type: 'paragraph', default: true },
    { type: 'numbered_list' },
    { type: 'bulleted_list' },
    { type: 'table' },
  ],
  field: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }, { type: 'inline' }],
  internallink: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }, { type: 'inline' }],
  tab: [{ type: 'text' }],
  img: [{ type: 'text' }],
  textbox: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }],
  notes_list_item: [{ type: 'text' }, { type: 'field' }, { type: 'tab' }],
};

export const matchAccepting = (acceptItem, element) => {
  if (!acceptItem || !element) return false
  if (acceptItem.type === 'text' && isText(element)) return true;
  return acceptItem.type === element.type;
};

export const lists = ['numbered_list', 'bulleted_list'];

export const blocks = [
  'section',
  'clause',
  'container',
  'definitions',
  'schedule',
  'paragraph',
  'textbox',
  ...lists,
  'list_item',
  'heading_one',
  'heading_two',
  'heading_three',
  'table',
  'table_row',
  'table_cell',
  'table_content',
  'section_start',
  'section_end',
  'definition_start',
  'definition_end',
  'schedule_one',
  'schedule_two',
  'img',
  'notes_list_item'
];

export const marks = ['bold', 'italic', 'underlined', 'code'];

export const headingToNumber = {
  heading_one: 1,
  heading_two: 2,
  heading_three: 3,
};
export const numberToHeading = {
  1: 'heading_one',
  2: 'heading_two',
  3: 'heading_three',
};

/**
 * Blocks which shall not be marked as `selected_block` which
 * would cause all its contents to be highlighted.
 */
const preventMarkAsSelectedBlock = [
  'section',
  'clause',
  'schedule',
  'definitions',
  'numbered_list',
  'bulleted_list',
];
export const preventMarkAsSelected = (element) => preventMarkAsSelectedBlock.includes(element.type);

/**
 * Fields which are selected when user hits tab
 * in the 'user edit flow' mode.
 */
const userInteractiveUpdateFields = [
  'vari',
  'item',
  'group',
  'setupValue',
  'initvalue',
  'smartreplacement',
  'concept',
  'enum',
];
export const isUserInteractiveUpdateField = (n) => userInteractiveUpdateFields.includes(n.variant);

/**
 * Fields for which selection is automatically
 * expanded upon entering into.
 */
export const autoSelectableInline = (node) => {
  return ['vari', 'item', 'setupValue', 'initvalue'].includes(node.variant);
};

export const magicFields = [
  'vari',
  'item',
  'group',
  'setupValue',
  'initvalue',
  'vari',
  'smartreplacement',
  'opt',
  'optelse',
  'ref',
  'engineContent',
  'smartDefinition',
  'concept',
  'enum',
];

export const inlines = [
  'field',
  'link',
  'tab',
  'internallink',
  'user_input', // For simple editors during creation page
];

export const TYPES_WITH_BLOCK_MENU = [
  // "clause",
  'paragraph',
  'numbered_list',
  'bulleted_list',
  'list_item',
  'img',
];
export const BLOCK_RENDER_OPTIONS = {
  customRender: ['table_row', 'table_cell', 'table_content', 'container'],
  withoutIndicator: [],
  withBlockMenu: [
    'section',
    'clause',
    'schedule',
    'paragraph',
    'numbered_list',
    'bulleted_list',
    'list_item',
    'img',
    'table',
    'heading_one',
    'heading_two',
    'heading_three',
  ],
  draggable: [
    'paragraph',
    'numbered_list',
    'bulleted_list',
    'list_item',
    'paragraph',
    'img',
    'clause',
    'heading_one',
    'heading_two',
    'heading_three',
  ],
};

export const blockMenuIcons = {
  section: 'mdi-vector-square',
  clause: 'mdi-format-section',
  schedule: 'mdi-file-document-box',
  definitions: 'mdi-tag-text-outline',
  paragraph: 'mdi-format-paragraph',
  numbered_list: 'mdi-format-list-numbers',
  bulleted_list: 'mdi-format-list-bulleted',
  list_item: 'mdi-adjust',
  img: 'mdi-image',
  table: 'mdi-table',
  heading_one: 'mdi-format-header-1',
  heading_two: 'mdi-format-header-2',
  heading_three: 'mdi-format-header-3',
  field: 'mdi-textbox',
};

export const nonSplittableElements = (node) => node.type === 'field';

export const filterCopyAttributes = (node) => {
  if (!node.data) return node;
  for (const key in node.data) {
    if (nonCopiedDataAttributes.includes(key)) delete node.data[key];
  }
};

export const nonCopiedDataAttributes = [
  '_each_uid',
  '_each_repeatable_id',
  '_each_repeatable_name',
  '_each_label_id',
  'key',
  'isSmartDefinition',
  'fill',
  '_updateTime',
  'acp',
  '_path',
  'itemJoined',
];

export const createElement = (type, children = []) => ({
  type,
  data: {
    item_id: uuid(),
    template_id: 'default_' + type,
  },
  children,
});

export const gotoBlocks = ['paragraph', 'heading_one', 'heading_two', 'heading_three', 'list_item'];

export const isMagicField = (n) => magicFields.includes(n.variant);

export const sidebarShowFields = (n) =>
  isMagicField(n) || (isBlock(n) && n.data && (n.data.acp || n.data.fill));

export const isBlock = (obj) => typeof obj === 'object' && blocks.includes(obj.type);

export const isElement = (obj) => typeof obj === 'object' && !isText(obj) && obj.hasOwnProperty('children');

export const isHeading = (obj) => typeof obj === 'object' && !!headingToNumber[obj.type];

export const isImage = (obj) => typeof obj === 'object' && obj.type === 'img';

export const isList = (obj) =>
  (typeof obj === 'object' && lists.includes(obj.type)) || (typeof obj === 'string' && lists.includes(obj));

export const isListItem = (obj) => typeof obj === 'object' && obj.type === 'list_item';

export const isInline = (obj) =>
  typeof obj === 'object' && (inlines.includes(obj.type) || (obj.type === 'each' && !isBlock(obj)));
export const isText = (obj) =>
  typeof obj === 'object' && obj.hasOwnProperty('text') && typeof obj.text === 'string' && !obj.children;

export const isTextPlain = (obj) => isText(obj) && Object.keys(obj).length === 1;

export const isInactive = (obj) => obj && obj.data && obj.data._inActive;

export const isActive = (obj) => !isInactive(obj);

export const emptyText = { text: '' };

export const normalizeNode = (node) => {
  if (typeof node !== 'object' || !Array.isArray(node.children)) return { text: '' };
};
export const normalizeChildren = (children) => {
  if (!Array.isArray(children)) return [{ text: '' }];
};

export const ensureProperChildren = (children) => {
  if (!Array.isArray(children) || children.length === 0)
    throw new Error('ensureProperChildren expects an array', { children });

  let types;
  if (isBlock(children[0])) types = 'block';
  else if (isInline(children[0]) || isText(children[0])) types = 'inline';
  else {
    throw new Error('Unknown type of children', { children });
  }
  if (types === 'inline') {
    let newChildren;
    if (!isText(children[0])) newChildren = [{ text: '' }, ...children];
    else newChildren = children;
    let previousIsInline = false;
    newChildren = newChildren.reduce((acc, curr) => {
      if (isInline(curr)) {
        if (previousIsInline) {
          acc.push({ text: '' });
        }
        previousIsInline = true;
      } else {
        previousIsInline = false;
      }
      acc.push(curr);
      return acc;
    }, []);
    if (isInline(newChildren[newChildren.length - 1])) newChildren = [...newChildren, { text: '' }];
    return newChildren;
  }
  return children;
};

export const assignNewIdsToElements = (element) => {
  if (!isElement(element)) return element;

  if (!element.data) element.data = {};
  element.data.item_id = uuid();

  assignNewIdsToChildren(element.children);
  return element;
};

export const assignNewIdsToChildren = (children) => {
  if (!Array.isArray(children)) return;
  if (children) {
    for (const child of children) assignNewIdsToElements(child);
  }
};
