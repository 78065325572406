import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useContract, setContract, setEventState } from 'hooks';
import { useLocation } from 'react-router-dom';
import { Dropdown, Menu, Button, Tooltip } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { DownOutlined, PlusSquareOutlined, EditOutlined } from '@ant-design/icons';
import { Create } from 'core/interfaces';
import RemoveModal from './components/templateEdit/RemoveModal';
import { removeCard } from '../../../containers/TemplateInputSections/utils';

export default function CardAction({ cardName }) {
  const dispatch = useDispatch();
  const contract = useContract();
  const location = useLocation();
  const [showRemove, setShowRemove] = useState(false);
  const currentPage = Create.getPageByUrl(contract.data.ui, location.pathname);

  const setTemplateEditCard = (type, method) => {
    setEventState('template_edit_input_sections', { type, method, selected: { card: cardName } });
  };

  const onRemove = (removeCompletely) => {
    const removeCardAction = removeCard(contract, currentPage, cardName, removeCompletely);

    dispatch(removeCardAction);
    setContract({ ...contract });
    setShowRemove(false);
  };

  const inputsLength = currentPage.cards.length;
  const cardIndex = currentPage.cards.findIndex((x) => x === cardName);
  const isFirst = cardIndex === 0;
  const isLast = cardIndex + 1 === inputsLength;
  const updatePage = (prop, value) => {
    contract.data.ui.pages[currentPage.id][prop] = value;
    setContract({
      ...contract,
    });
  };
  const moveUp = () => {
    if (isFirst) return;
    const newOrder = [...currentPage.cards];
    const otherValue = newOrder[cardIndex - 1];
    newOrder[cardIndex - 1] = newOrder[cardIndex];
    newOrder[cardIndex] = otherValue;
    updatePage('cards', newOrder);
  };
  const moveDown = () => {
    if (isLast) return;
    const newOrder = [...currentPage.cards];
    const otherValue = newOrder[cardIndex + 1];
    newOrder[cardIndex + 1] = newOrder[cardIndex];
    newOrder[cardIndex] = otherValue;
    updatePage('cards', newOrder);
  };

  return (
    <div className="template-card-toolbar">
      <Tooltip title={<IntlMessages id="studio.template.modal.input.add" />} placement="top">
        <Button
          shape="circle"
          icon={<PlusSquareOutlined />}
          onClick={() => setTemplateEditCard('input', 'add')}
        />
      </Tooltip>
      <Tooltip title={<IntlMessages id="studio.template.modal.card.edit" />} placement="top">
        <Button shape="circle" icon={<EditOutlined />} onClick={() => setTemplateEditCard('card', 'edit')} />
      </Tooltip>
      <div className="template-card-toolbar-arrange">
        {!isFirst ? (
          <Tooltip title={'Move up'} placement="left">
            <span className="link" onClick={moveUp}>
              <i className="mdi mdi-arrow-up" />
            </span>
          </Tooltip>
        ) : (
          <span style={{ height: '18px' }}>&nbsp;</span>
        )}
        {!isLast && (
          <Tooltip title={'Move down'} placement="left">
            <span className="link" onClick={moveDown}>
              <i className="mdi mdi-arrow-down" />
            </span>
          </Tooltip>
        )}
      </div>
      {/* <Button shape="circle" icon={<CloseOutlined />} /> */}
    </div>
  );

  return (
    <>
      <span>
        {showRemove && <RemoveModal type="card" onRemove={onRemove} />}
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => setTemplateEditCard('card', 'edit')}>
                {/* <span className=""><IntlMessages id={"app.file.disconnectFile"} /></span> */}
                <span className="">
                  <IntlMessages id="studio.template.modal.card.edit" />
                </span>
              </Menu.Item>
              <Menu.Item onClick={() => setShowRemove(true)}>
                {/* <span className=""><IntlMessages id={"app.file.removeFile"} /></span> */}
                <span className="">
                  <IntlMessages id="studio.template.modal.card.remove" />
                </span>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item onClick={() => setTemplateEditCard('input', 'add')}>
                {/* <span className=""><IntlMessages id={"app.file.disconnectFile"} /></span> */}
                <span className="">
                  <IntlMessages id="studio.template.modal.input.add" />
                </span>
              </Menu.Item>
              <Menu.Item onClick={() => setTemplateEditCard('input', 'arrange')}>
                {/* <span className=""><IntlMessages id={"app.file.disconnectFile"} /></span> */}
                <span className="">
                  <IntlMessages id="studio.template.modal.input.arrange" />
                </span>
              </Menu.Item>
            </Menu>
          }
        >
          <span className="link ant-dropdown-link">
            <IntlMessages id={'app.general.Actions'} /> <DownOutlined />
          </span>
        </Dropdown>
      </span>
    </>
  );
}
