import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Slider, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { useIsTemplateStudio, useEventState, setEventState } from 'hooks';
import StudioInputContainer from '../../Views/Input/';
import StudioEdit from '../../Views/Edit/';
import StudioEditor from '../../components/StudioEditor';
import { BuildOutlined } from '@ant-design/icons';
import config from 'config/config';

const { CONTRACT_PREVIEW_PLACEMENT = false } = config?.ui?.studio || {};

function reversedValue(value) {
  return -(value - 100);
}

export default function StudioContainer({ action, activePaths }) {
  const isInput = action === 'input';
  const isContract = action === 'contract';
  const contractPreviewContainer = useRef(null);
  const direction = useSelector(({ draft }) => draft.input_preview_direction);
  const isTemplate = useIsTemplateStudio();

  let defaultLeft;
  if (isTemplate) defaultLeft = 50;
  else {
    // Set opposite.
    if (direction === 'left') defaultLeft = 70;
    else defaultLeft = 30;
  }

  const [value, setValue] = useState(defaultLeft);

  const setNewContainersWidth = useCallback(
    (val) => {
      const previewElement = document.getElementById('preview-column');
      if (direction === 'right') val = reversedValue(val);
      previewElement.dispatchEvent(
        new CustomEvent('customResize', {
          detail: { percentage: val },
        })
      );
    },
    [direction]
  );
  const handleWidthChange = useCallback(
    (val) => {
      if (val > 80 || val < 20) return;
      setNewContainersWidth(val);
      setValue(val);
    },
    [setValue, setNewContainersWidth]
  );

  useEffect(() => {
    setValue(reversedValue(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  return (
    <>
      <div
        id="draft-input-container"
        className={`draft-input-container position-relative ${
          isTemplate ? ' template-studio' : ''
        } ${CONTRACT_PREVIEW_PLACEMENT}`}
      >
        <div
          id="preview-column"
          className={'studio-contract-live ' + (!isInput ? 'edit-mode' : 'input-mode')}
          ref={contractPreviewContainer}
        >
          {isTemplate && <ContractStructureModeSwitcher />}
          <StudioEditor action={action} />
        </div>

        {isInput && <StudioInputContainer activePaths={activePaths} contractPreviewContainer={contractPreviewContainer} />}

        {isContract && <StudioEdit />}
      </div>
      {isInput && (
        <Slider
          style={{
            marginLeft: '0px',
          }}
          id="studio-size-slider"
          className="studio-size-slider"
          step={5}
          included={false}
          value={value}
          tipFormatter={() => 'Slide to adjust size of contract preview'}
          onChange={handleWidthChange}
        />
      )}
    </>
  );
}

function ContractStructureModeSwitcher() {
  const showEditorStructure = useEventState('ContractStructureModeSwitch');

  const toggle = () => {
    setEventState('ContractStructureModeSwitch', !showEditorStructure);
  };

  return (
    <Tooltip title={(showEditorStructure ? 'Close' : 'Open') + ' Document Structure View'} placement="top">
      <div
        className={
          'contract-structure-mode-switcher clickable hoverable prevent-editor-blur' +
          (showEditorStructure ? ' active' : '')
        }
        tabIndex="-1"
        onClick={toggle}
      >
        <BuildOutlined />
      </div>
    </Tooltip>
  );
}
