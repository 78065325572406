import { Table } from 'antd';
import IntlMessages from 'util/IntlMessages';

export default function InfoTable({ data, header, headerSize = 'h5', ...rest }) {
  const keyedData = data.map((x) => ({ ...x, key: x.id }));
  let tableHeader = null
  if (header) {
    const CustomTag = `h${headerSize}`;
    tableHeader = <CustomTag>{header}</CustomTag>
  }
  return (
    <>
    {tableHeader}
    <Table
      showHeader={false}
      {...rest}
      columns={[
        {
          dataIndex: 'id',
          render: (text) => <small><IntlMessages id={text} cap /></small>,
        },
        {
          dataIndex: 'value',
        },
      ]}
      dataSource={keyedData}
      bordered={false}
      pagination={false}
    />
    </>
  );
}
