import React, { useState } from "react";
import { Col, Row, Card, Spin } from "antd";

import DocumentTemplateWidget from "components/Widgets/DocumentTemplateWidget";
import NewDocumentTemplateButton from 'components/project/NewDocumentTemplate/NewDocumentTemplateButton';

import WidgetHeader from "components/WidgetHeader/index";
import Auxiliary from "../../util/Auxiliary";

import { useMountFetch } from "hooks"
import IntlMessages, { useIntlMessage } from "util/IntlMessages";


const DocumentTemplates = (props) => {
  const [templates, setTemplates] = useState(null);
  const [templatesError, setTemplatesError] = useState("");
  const formatMessage = useIntlMessage();

  const templateWasCreated = (template) => {
    setTemplates([...templates, template]);
  };
  const templateWasRemoved = (id) => {
    setTemplates([...templates.filter((template) => template.id !== id)]);
  };
  

  useMountFetch(
    "/documenttemplates?fields=id,name,description,isCoreTemplate",
    setTemplates,
    setTemplatesError
  );

  if (!templates) {
    return (
      <Card title="" className="card">
        <Spin className="loader-container" />
      </Card>
    );
  }

  if (templatesError) {
    return (
      <Card title="" className="card">
        <IntlMessages id="app.templates.errorRetrieving" />
      </Card>
    );
  }

  const regularTemplates = templates.filter((template) => !template.isCoreTemplate);
  const coreTemplates = templates.filter((template) => template.isCoreTemplate);

  return (
    <Auxiliary>
      <div className="profile-content">
        <Row className="mb-4">
          <Col sm={24}>
            <WidgetHeader title={formatMessage('app.templates.templates')} extra={
              <>
                {/* <span className="mr-3">
                  <IntlMessages id="app.templates.editWithin" />
                </span> */}
                <NewDocumentTemplateButton coreTemplates={coreTemplates} templateWasCreated={templateWasCreated} />
              </>
              }
            />
            <div className="m-3">
              {regularTemplates.length === 0 ? (
                <div><IntlMessages id="app.templates.noTemplates" /></div>
              ) : (
                <Row>
                  {regularTemplates.map((template) => (
                    <Col key={template.id} xl={6} lg={12} md={12} sm={12} xs={24}>
                      <DocumentTemplateWidget
                        template={template}
                        templateWasCreated={templateWasCreated}
                        templateWasRemoved={templateWasRemoved}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Auxiliary>
  );
};

export default DocumentTemplates;
