import axios from "axios";
import { apiAddress } from "config/main";
import { setAuthToken, setLocale } from "utils/auth"
import { notification } from "antd"
import StackTrace from "stacktrace-js";

const isLoginError = (err) =>
  err.response &&
  err.response.status === 401 &&
  err.response.data &&
  err.response.data.type === "auth";

const handleError = (err, method, path, arg, resolve, reject) => {
  if (isLoginError(err)) {
    console.log("login err  is ", err.response);
  } else {
    console.log('Error: ', {err, response: err.response, method, path})
    if (err.response?.data?.errorMessage) {
      notification.error({
        message: err.response.data.errorHeader || null,
        description: err.response?.data?.errorMessage,
        duration: 10,
      });
    }
    reject(err);
  }
};

const apiDispatcher = (method, path, opts, settings = {}) => {

  const locale = localStorage['settings.locale'];
  if (locale) {
    const jsonLocale = JSON.parse(locale);
    if ('locale' in jsonLocale &&
      axios.defaults.headers.common.ClientLocale !== jsonLocale.locale) {
        setLocale(jsonLocale.locale);
    }
  }

  if (
    localStorage.extAccessToken &&
    axios.defaults.headers.common.Authorization !== localStorage.extAccessToken
  ) {
    setAuthToken(localStorage.extAccessToken)
    // axios.defaults.headers.common["Authorization"] = localStorage.extAccessToken;
  }

  // Return api call
  return new Promise((resolve, reject) => {
    if (method === "_custom") {
      axios(opts)
        .then((success) => {
          resolve(success);
        })
        .catch((err) => {
          return handleError(err, method, path, opts, resolve, reject);
        });
    } else if (typeof axios[method] === "function") {
      axios[method](apiAddress + path, opts)
        .then((success) => resolve(success))
        .catch((err) => {
          return handleError(err, method, path, opts, resolve, reject);
        });
    } else {
      throw new Error("Invalid method for api", method);
    }
  });
};


function api(opts) {
  if (opts.url) opts.url = apiAddress + opts.url;
  if (opts.fixUrl) opts.url = opts.fixUrl;
  return apiDispatcher("_custom", null, opts);
}
api.get = (path, opts, settings) => apiDispatcher("get", path, opts);
api.post = (path, opts, settings) => apiDispatcher("post", path, opts);
api.put = (path, opts, settings) => apiDispatcher("put", path, opts);
api.delete = (path, opts, settings) => apiDispatcher("delete", path, opts);
api.log = (logLevel, errorMessage, error, errorCode, extraInfo) => {
  StackTrace.fromError(error)
  .then((stack) => {
    const errorInfo = { level: logLevel, errorMessage, error: error.message, stacktrace: stack, errorCode, url: window.location?.href, extraInfo };
    console.log('Unhandled exception', errorInfo);
      apiDispatcher("post", "/log", errorInfo);
    });
}

export default api;

