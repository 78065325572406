export const initEaches = {
  id: 'initEaches',
  dependencies: { onGenesis: true },
  flowDirection: 'reverse',
  handler: function ({ api }) {
    return (node, parents) => {
      if (!node.data || !node.data.each_repeatable || !node.data.each_repeatable.repeatable) return;

      // Handle table rows separately, as they need to be inserted into a multiple
      // of other nodes. Remove it. Place it on the table node and handle it from there.
      if (node.type === 'table_row') {
        const tableNode = parents.find((item) => item.type === 'table');
        if (!tableNode) return this.log('No table node found for ', { node, parents });

        // Find the node's index within the tableNode. For usage below.
        const nodeIndex = api.utils.engine.findIndex(tableNode.children, (item) => item === node);
        if (nodeIndex === -1) {
          return this.log('Cannot find my nodeIndex (row within table)', { node, tableNode });
        }

        // Add row_repeatables to the parent table node's data.
        if (!tableNode.data.row_repeatables) tableNode.data.row_repeatables = [];
        tableNode.data.row_repeatables.push({
          repeatable: node.data.each_repeatable.repeatable,
          filter: node.data.each_repeatable.filter,
          filterComponents:
            node.data.each_repeatable.filter && api.logic.getComponents(node.data.each_repeatable.filter),
          content: JSON.stringify(nodeWithoutDataRepeatableEntry(node)),
        });

        // This relevant row (the `node`) was merely a template. It has
        // been stored to the table's data.row_repeatables and should
        // now be removed from the actual contract.
        tableNode.children.splice(nodeIndex, 1);
      }

      // Handle table cells differently, as they need to be inserted into a multiple
      // of other nodes. Remove it. Place it on the table node and handle it from there
      else if (node.type === 'table_cell') {
        const tableNode = parents.find((item) => item.type === 'table');
        if (!tableNode) return this.log('No table node found for ', { node, parents });

        const rowNode = parents.find((item) => item.type === 'table');
        if (!rowNode) return this.log('No table_row node found for ', { node, parents });

        // Find the node's index within the tableNode. For usage below.
        const nodeIndex = api.utils.engine.findIndex(rowNode.children, (item) => item === node);
        
        if (nodeIndex === -1) {
          return this.log('Cannot find my nodeIndex (row within table)', { node, tableNode });
        }

        // Add column_repeatables to the parent table node's data.
        if (!tableNode.data.column_repeatables) tableNode.data.column_repeatables = [];
        tableNode.data.column_repeatables.push({
          repeatable: node.data.each_repeatable.repeatable,
          filter: node.data.each_repeatable.filter,
          content: JSON.stringify(nodeWithoutDataRepeatableEntry(node)),
        });

        // This relevant cell (the `node`) was merely a template. It has
        // been stored to the table's data.column_repeatables and should
        // now be removed from the actual contract.
        rowNode.children.splice(nodeIndex, 1);
      } else {
        // Remove children and store them as a template
        node.data.template = JSON.stringify(node.children);

        node.children = this.generateEachPlaceholderChild(node);
      }
    };
  },
};

// The template may have a table_row or a table_cell with
// the data property `each_repeatable: { repeatable: 'borrower' }`
// to indicate it as a repeatable template. When initialising the contract,
// that node content is stringified into the table's data properties
// `row_repeatables` and/or `cell_repeatables` respectively. At the same
// time, the table_row or table_cell is stored within the table's data as
// a template. For that table_row/cell node, remove the `each_repeatable`
// property, as it is no longer needed.
function nodeWithoutDataRepeatableEntry(node) {
  const newNode = {
    ...node,
    data: {
      ...(node.data || {}),
    },
  };
  delete newNode.data.each_repeatable;
  return newNode;
}
