import React, { useState, useEffect, useRef } from 'react';
import { Menu, Dropdown, Button, message, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import manager from 'core/engine/manager';
import MainActions from 'containers/App/MainActions';
import updateStudioDrafts from 'utils/saving/updateStudioDrafts';
import { default as newVersionApi } from 'utils/saving/newVersion';
import {
  useProject,
  useContract,
  useIsTemplateStudio,
  useIsSidebarMini,
  useDraftPreviousLocation,
} from 'hooks';
import api from 'utils/api';
import { useModalContent } from 'components/ui/modals';
import { setContractWasUpdated } from 'hooks';
import IntlMessages from 'util/IntlMessages';

export default function ActionButtons({ isMini: propIsMini, dark }) {
  const [isLastVersion, setIsLastVersion] = useState(null);
  const history = useHistory();
  const contract = useContract();
  const project = useProject(contract?.projectId);
  const isTemplate = useIsTemplateStudio();
  const mini = useIsSidebarMini();
  const signOutFunction = useRef(null);
  const previousLocation = useDraftPreviousLocation();

  const isMini = propIsMini !== undefined ? propIsMini : mini;

  let goBackPath;
  if (previousLocation && previousLocation.pathname) {
    goBackPath = previousLocation.pathname;
  } else {
    if (isTemplate) {
      goBackPath = '/templates';
    } else {
      goBackPath = project ? '/project/' + project.id + '/documents' : '/projects';
    }
  }

  const documentId = contract?.documentId,
    versionNumber = contract?.info?.versionNumber;

  useEffect(() => {
    if (!documentId || isTemplate) return;
    api
      .get('/documents/' + documentId + '/versionNumbers')
      .then((res) =>
        Array.isArray(res.data) && res.data.slice(-1)[0] === versionNumber
          ? setIsLastVersion(true)
          : setIsLastVersion(false)
      )
      .catch((err) => console.log(err.response));
  }, [documentId, versionNumber, isTemplate]);

  const confirmSignOut = () => {
    if (typeof signOutFunction.current === 'function') {
      signOutFunction.current();
      setTimeout(() => {
        history.push('/signin');
      }, [200]);
    }
    toggleModal();
  };

  const [Modal, toggleModal, , open] = useModalContent(<IntlMessages id="app.userAuth.signOut" />, {
    defaultOpen: false,
    closeText: <IntlMessages id="desc.Abort" />,
    ButtonOK: (
      <Button key="signout" type="danger" onClick={confirmSignOut}>
        <IntlMessages id="app.userAuth.signOut" />
      </Button>
    ),
  });

  if (!project) return <div>...</div>;

  // Called by the manager to update the db with the initialized
  // and loaded contract data
  const updateCreation = (opts = {}) => {
    const { notify = false } = opts;

    let hideLoad;
    if (notify) hideLoad = message.loading('Saving ...', 7);
    updateStudioDrafts()
      .then((success) => {
        // console.log("Updated: ", success);
        if (notify) {
          hideLoad();
          message.success('Saved', 2);
        }

        if (setContractWasUpdated && typeof setContractWasUpdated.reset === 'function')
          setContractWasUpdated.reset();
        manager.loadedChangesMade = true;
      })
      .catch((err) => {
        if (notify) {
          hideLoad();
          message.error('Save failed', err.response, 2);
        }
        console.log('updateStudioDrafts failed ', err, err.response);
      });
  };

  const publishDocument = () => {
    api
      .put('/versions/' + contract.id, { status: 'published' })
      .then((res) => {
        api.put('/documents/' + contract.documentId, { status: 'published' }).then((res) => {
          console.log('Publish: ', res.data);
          history.push('/project/' + project.id + '/documents?hl=' + contract.documentId);
        });
      })
      .catch((err) => console.log('Could not update version status ', err.response));
  };

  const onSaveAsNewVersion = (opts = {}) => {
    let hideLoad = message.loading('Creating new Version ...', 7);
    newVersionApi()
      .then((success) => {
        hideLoad();
        message.success('Created new version', 2);
        setContractWasUpdated.reset();
        manager.loadedChangesMade = true;
        setIsLastVersion(true);
      })
      .catch((err) => {
        hideLoad();
        console.log('Err is ', err);
        message.error('Failed to create new version', 2);
      });
  };

  const onSaveAndClose = () => {
    updateCreation({ notify: false });
    history.replace(goBackPath);
  };

  const onSave = () => updateCreation({ notify: true, type: 'onlySave' });
  const onSaveAndPublish = () => {
    updateCreation();
    publishDocument();
  };

  const onSignOut = (signOut) => {
    signOutFunction.current = signOut;
    toggleModal();
  };

  if (isMini) {
    const closeBtnType = dark ? 'ghost' : 'danger';
    const saveBtnType = dark ? 'ghost' : 'success';
    const textClass = dark ? 'text-white' : '';
    const btnClass = 'm-0 mb-1 ' + textClass;
    return (
      <>
        {open && (
          <Modal>
            <IntlMessages id="studio.warnings.signOut" />
          </Modal>
        )}
        <MainActions
          onSignOut={onSignOut}
          dark={dark}
          extra={
            <>
              <Button type={closeBtnType} className={btnClass} onClick={onSaveAndClose}>
                {/* <i className="mdi mdi-close-circle-outline" /> */}
                <IntlMessages id="desc.Close" />
              </Button>

              <Button type={saveBtnType} className={btnClass} color="green" onMouseDown={onSave}>
                {/* <i className="mdi mdi-content-save" /> */}
                <IntlMessages id="desc.Save" />
              </Button>
              {/*  <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <span onClick={onSaveAndClose}>
                      <IntlMessages id="studio.actions.saveAndClose" />
                    </span>
                  </Menu.Item>
                  <Menu.Item>
                    <span onClick={onSave}>
                      <IntlMessages id="desc.Save" />
                    </span>
                  </Menu.Item>
                </Menu>
              }
              placement="topRight"
            >
              <Button style={{ margin: '0 0 5px 0' }}>
                <Tooltip title={<IntlMessages id="studio.actions.actions" />}>
                  <i className="mdi mdi-content-save-all" />
                </Tooltip>
              </Button>
            </Dropdown> */}
            </>
          }
        />
      </>
    );
  }

  return (
    <>
      {open && (
        <Modal>
          <IntlMessages id="studio.warnings.signOut" />
        </Modal>
      )}
      <MainActions
        onSignOut={onSignOut}
        extra={
          <>
            <Button type="danger" onClick={onSaveAndClose}>
              {/* <i className="mdi mdi-close-circle-outline" /> */}
              <IntlMessages id="desc.Close" />
            </Button>

            <Button type="success" color="green" onMouseDown={onSave}>
              {/* <i className="mdi mdi-content-save" /> */}
              <IntlMessages id="desc.Save" />
            </Button>

            {/* {contract.status === 'draft' && (
              <Tooltip title={<IntlMessages id="studio.actions.saveAndPublish" />}>
                <Button type="secondary" onMouseDown={onSaveAndPublish}>
                  <i className="mdi mdi-content-save-settings" />
                </Button>
              </Tooltip>
            )} */}
            {/* {!isLastVersion && !isTemplate && (
              <Tooltip title={<IntlMessages id="studio.actions.saveNewVersion" />}>
                <Button type="secondary" onMouseDown={onSaveAsNewVersion}>
                  <i className="mdi mdi-content-save-settings" />
                </Button>
              </Tooltip>
            )} */}
          </>
        }
      />
    </>
  );
}

// Old, mini.
/*
<Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <span onClick={onSaveAndClose}>
                      <IntlMessages id="studio.actions.saveAndClose" />
                    </span>
                  </Menu.Item>
                  <Menu.Item>
                    <span onClick={onSave}>
                      <IntlMessages id="desc.Save" />
                    </span>
                  </Menu.Item>
                   <Menu.Item>
                    <span onClick={onSaveAndPublish}>
                      <IntlMessages id="studio.actions.saveAndPublish" />
                    </span>
                  </Menu.Item>
                  {!isLastVersion && !isTemplate && (
                    <Menu.Item>
                      <span onClick={onSaveAsNewVersion}>
                        <IntlMessages id="studio.actions.saveNewVersion" />
                      </span>
                    </Menu.Item>
                  )} 
                  </Menu>
                }
                placement="topRight"
              >
                <Button style={{ margin: '0 0 5px 0' }}>
                  <Tooltip title={<IntlMessages id="studio.actions.actions" />}>
                    <i className="mdi mdi-content-save-all" />
                  </Tooltip>
                </Button>
              </Dropdown>

*/

// Old, ordinary.
/*
<Tooltip title={<IntlMessages id="studio.actions.saveAndClose" />}>
              <Button type="danger" onClick={onSaveAndClose}>
                <i className="mdi mdi-close-circle-outline" />
                <IntlMessages id="desc.Close" />
              </Button>
            </Tooltip>
            <Tooltip title={<IntlMessages id="desc.Save" />}>
              <Button type="success" color="green" onMouseDown={onSave}>
                <i className="mdi mdi-content-save" />
                <IntlMessages id="desc.Save" />
              </Button>
            </Tooltip>
            */
