import { Editor, Transforms, Path } from 'slate';
import uuid from "uuid-random"

export const cmd_indent_block = editor => (() => {
    const lowestListItems = Editor.nodes(editor, { at: editor.selection, match: n => n.type === 'list_item', mode: 'lowest' });
    const lowestLists = Editor.nodes(editor, { at: editor.selection, match: n => n.type === 'numbered_list', mode: 'lowest' });
    let lowestListItem, lowestList;
  
    for(let listItem of lowestListItems)
      lowestListItem = listItem;
    for(let list of lowestLists)
      lowestList = list;
  
    if(!lowestList || !lowestListItem || !Path.isParent(lowestList[1], lowestListItem[1])) return;
  
    const listItemIndex = lowestListItem[1][lowestListItem[1].length-1];
    // console.log('List item index', listItemIndex)
    
    if(listItemIndex === 0) {
    console.log('Not wrap top level');
    return;
    }

    if(lowestList[0].children[listItemIndex-1].type === 'numbered_list') {
    const targetPath = [...lowestList[1], listItemIndex-1, lowestList[0].children[listItemIndex-1].children.length]
    // console.log('Should move into above sibl list', lowestListItem[1], targetPath);
    Transforms.moveNodes(editor, { at: lowestListItem[1], to: targetPath })
    if(lowestList[0].children[listItemIndex+1] && lowestList[0].children[listItemIndex+1].type === 'numbered_list') {
        const newTargetPath = [...targetPath];
        newTargetPath[newTargetPath.length-1] = newTargetPath[newTargetPath.length-1]+1;
        Transforms.moveNodes(editor, { at: lowestListItem[1], to: newTargetPath })
    }
    }

    else if(lowestList[0].children.length === 1) {
    // console.log('Only child')
    return;
    }
    else {
    Transforms.wrapNodes(editor, {type: 'numbered_list', data: { item_join: false, item_id: uuid(), template_id: 'default_list_'}, children: []})
    // console.log('Wrapped list item ', listItemIndex, lowestList[0])
    if(lowestList[0].children && lowestList[0].children[listItemIndex+1] && lowestList[0].children[listItemIndex+1].type === 'numbered_list') {
        const subListPath = [...lowestList[1], listItemIndex+1]
        const newTargetPath = [...lowestListItem[1], 1]
        
        Transforms.moveNodes(editor, { at: subListPath, to: newTargetPath })
    }
    }
}
)


export const cmd_dedent_block = editor => (() => {

    Transforms.unwrapNodes(editor, {
    match: n => n.type === 'numbered_list',
    split: false,
    })
}
)

export const cmd_align = editor => ((arg) => {

    if(!editor.selection || !arg) return;
    const nodes = Editor.nodes(editor, { match: n => editor.isBlock(n), mode: 'lowest' }); // Selected nodes
    for(let node of nodes) {
      if(node[0] === editor) continue;
      if(!editor.isBlock(node[0])) continue;
      editor.addData({_format: { textAlign: arg }}, node[1]);
    }
}
)

export const cmd_color = editor => ((arg) => {

    if(!editor.selection || !arg) return;
    const nodes = Editor.nodes(editor, { match: n => editor.isBlock(n), mode: 'lowest' }); // Selected nodes
    for(let node of nodes) {
      if(node[0] === editor) continue;
      if(!editor.isBlock(node[0])) continue;
      editor.addData({_format: { color: arg }}, node[1]);
    }
}
)

export const cmd_undo = editor => (() => {

    editor.undo();
}
)

export const cmd_redo = editor => (() => {

    editor.redo();
}
)
