import React from "react";
import { Badge, Tooltip } from "antd";
import Widget from "components/Widget/index";

const IconCard = ({
  color,
  icon,
  mdi,
  badge,
  bottomText,
  topAction = {},
  bottomAction = {},
  afterText,
}) => {
  return (
    <>
      <Widget styleName={`card-full p-2 text-center position-relative text-primary ${color}`}>
        {badge && (
          <Badge
            status={badge}
            style={{ position: "absolute", right: "0", marginRight: "0", top: "4px" }}
          ></Badge>
        )}
        <div>
          {mdi ? (
            <i className={`mdi mdi-${icon} fs-iconcard`} />
          ) : (
            <i className={`icon icon-${icon} fs-iconcard`} />
          )}
        </div>
        <div>
          {bottomText ? bottomText : ""}
          <div className="widget-item-overlay">
            <div className="action action-top" onClick={topAction.onClick || null}>
              <div className="action-content">
                {topAction.tooltip ? (
                  <Tooltip
                    title={topAction.tooltip}
                    placement={topAction.tooltipPlacement || "top"}
                  >
                    {topAction.text}
                  </Tooltip>
                ) : (
                  topAction.text
                )}
              </div>
            </div>
            <div className="action action-bottom" onClick={bottomAction.onClick}>
              <div className="action-content">
                {bottomAction.tooltip ? (
                  <Tooltip
                    title={bottomAction.tooltip}
                    placement={bottomAction.tooltipPlacement || "top"}
                  >
                    {bottomAction.text}
                  </Tooltip>
                ) : (
                  bottomAction.text
                )}
              </div>
            </div>
          </div>
        </div>
      </Widget>
      {afterText && (
        <div className="d-flex align-items-center justify-content-center">
          <small className="text-center break-word">{afterText}</small>
        </div>
      )}
    </>
  );
};

export default IconCard;
