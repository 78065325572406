import React, { useState } from 'react';
import { Transforms } from 'slate';
import { useEditor, ReactEditor } from 'slate-react';
import { useContract } from 'hooks';
import { Contract } from 'core/interfaces';
import { RuleModal, ContractRulesEditor, PrintRule } from 'components/RuleEditor/';

export function ConditionActions({ onChange, linksInline, acp: inputAcp, ...rest }) {
  const [acp, setAcp] = useState(inputAcp || rest.node?.data?.acp || null);

  const onChangeRule = (id) => {
    setAcp(id);
    if (typeof onChange === 'function') onChange(id);
  };

  const style = {
    display: 'flex',
    flexDirection: linksInline ? 'row' : 'column',
    justifyContent: 'space-between',
  };

  return (
    <div style={style}>
      <NodeRuleEdit {...rest} acp={acp} onChange={onChangeRule} />
      <NodeRuleChanger {...rest} acp={acp} onChange={onChangeRule} />
    </div>
  );
}

function NodeRuleEdit({ title, ...rest }) {
  if (!rest.acp) return null;
  let title2 = !title ? 'Edit Rule' : title;
  return <NodeRule type="edit" {...rest} title={title2} />;
}

function NodeRuleChanger({ title, ...rest }) {
  const action = rest.acp ? 'Change' : 'Set';
  let title2 = !title ? `${action} Rule` : title;
  return <NodeRule type="change" {...rest} title={title2} />;
}

function NodeRule({
  type,
  title,
  acp: inputAcp,
  node,
  stringPath: inputStringPath,
  open: defaultOpen = false,
  onChange,
  preventUpdate,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const contract = useContract()
  let editor
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    editor = useEditor();
  } catch(err) {

  }
  const [acp, setAcp] = useState(inputAcp || node.data.acp || null);

  let stringPath;
  if (!inputStringPath) {
    try {
      if (editor) {
        const path = ReactEditor.findPath(editor, node);
        stringPath = JSON.stringify(path);
      }
    } catch (err) {
      console.log('Cannot get path for node ', node);
    }
  } else {
    stringPath = inputStringPath;
  }

  const updateRuleid = (id) => {
    if (!preventUpdate) {
      const path = JSON.parse(stringPath);
      Transforms.setNodes(
        editor,
        (node) => {
          return {
            data: {
              ...node.data,
              acp: id,
            },
          };
        },
        { at: path }
      );
    }
    if (typeof onChange === 'function') onChange(id);
    setAcp(id);
  };

  const onSave = (rule, ruleId) => {
    if (typeof onChange === 'function') onChange(acp);
  };

  const onSaveAs = (rule, ruleId) => {
    updateRuleid(ruleId);
  };
  const onSelect = (ruleId) => {
    updateRuleid(ruleId);
  };

  const close = () => {
    setIsOpen(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <span className="link" onClick={toggle}>
        {title || type.toUpperCase()}
      </span>
      {isOpen && type === 'edit' && (
        <RuleModal
          contract={contract}
          id={acp}
          onSave={onSave}
          onSaveAs={onSaveAs}
          onOk={close}
          onCancel={close}
        />
      )}
      {isOpen && type === 'change' && (
        <ContractRulesEditor
          contract={contract}
          onSelect={onSelect}
          onClose={close}
          currentRuleId={acp}
          onSave={onSave}
          onSaveAs={onSaveAs}
        />
      )}
    </>
  );
}

export function PrintCondition({ node, acp: inputAcp }) {
  const contract = useContract()
  const theAcp = inputAcp || node?.data?.acp
  if (!theAcp) return <div>No condition</div>;
  const rule = Contract.getRule(contract, theAcp);
  return <PrintRule rule={rule.data} />;
}
