import { useState, useEffect } from "react";
import api from "utils/api";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";

export function useDraftBaseRoute() {
  const match = useRouteMatch();
  if (match.path !== "/draft/:id/:action" || match.path !== "/studio/:subject/:id/:action")
    return null;
  if (!match.params.id) return null;
  const { subject, id } = match.params;
  if (subject) return `/studio/${subject}/${id}`;
  else return `/${subject}/${id}`;
  // return "/draft/" + match.params.id;
}

export function useTemplateEditBaseRoute() {
  const match = useRouteMatch();
  if (match.path !== "/tedit/:id/:action") return null;
  if (!match.params.id) return null;
  return "/tedit/" + match.params.id;
}

export function useStudioBaseRoute() {
  const match = useRouteMatch();
  if (match.path !== "/studio/:subject/:id/:action") {
    const parts = match.url.split("/");
    if (parts[1] === "studio" && (parts[2] === "draft" || parts[2] === "template"))
      return parts.slice(0, 4).join("/");
    return null;
  }
  if (!match.params.id) return null;
  const { subject, id } = match.params;
  return `/studio/${subject}/${id}`;
}

export function useIsTemplateStudio() {
  const { url } = useRouteMatch();
  return url.startsWith("/studio/template/");
}
export function getIsTemplateStudio() {
  return window.location.pathname.startsWith("/studio/template/");
}

/* Documents, go to etc */

export const useGotoTemplateEdit = (id, opts = {}) => {
  const history = useHistory();
  const { target = "document" } = opts;

  if (target === "document")
    return () => {
      api
        .get("/documents/" + id + "/versions?last&fields=id")
        .then((result) => {
          history.push("/studio/template/" + result.data.id + "/contract/view");
        })
        .catch((err) => {
          console.log("Cannot fetch last version", err.response);
        });
    };

  if (target === "version")
    return () => {
      history.push("/studio/template/" + id + "/contract/view");
    };

  return () => {};
};

const STATIC_DRAFT_LOCATIONS = {
  previousBeforeLoad: null
}

export const useDraftPreviousLocation = () => {
  return STATIC_DRAFT_LOCATIONS.previousBeforeLoad
}

export const useGotoDraft = (id, opts = {}) => {
  const history = useHistory();
  const location = useLocation();
  const { target = "document" } = opts;

  STATIC_DRAFT_LOCATIONS.previousBeforeLoad = location

  if (target === "document")
    return () => {
      api
        .get("/documents/" + id + "/versions?last&fields=id")
        .then((result) => {
          history.push("/studio/draft/" + result.data.id + "/input");
        })
        .catch((err) => {
          console.log("Cannot fetch last version", err.response);
        });
    };

  if (target === "version")
    return () => {
      history.push("/studio/draft/" + id + "/input");
    };

  return () => {};
};

export const useGotoDocumentView = ({ documentId, projectId }) => {
  const history = useHistory();

  if (!documentId || !projectId) {
    console.warn("useGotoDocumentView requires documentId and projectId");
    return;
  }

  return () => history.push("/project/" + projectId + "/document/" + documentId + "/overview");
};

export const useGotoVersionView = ({ versionId, documentId, projectId }) => {
  const history = useHistory();

  if (!documentId || !projectId) {
    console.warn("useGotoVersionView requires documentId and projectId");
    return;
  }

  if (!versionId) {
    return () => {
      api
        .get("/documents/" + documentId + "/versions?last&fields=id")
        .then((result) => {
          history.push(
            "/project/" +
              projectId +
              "/document/" +
              documentId +
              "/version/" +
              result.data.id +
              "/overview"
          );
        })
        .catch((err) => {
          console.log("Cannot fetch last version", err.response);
        });
    };
  }
  return () => {
    history.push(
      "/project/" + projectId + "/document/" + documentId + "/version/" + versionId + "/overview"
    );
  };
};

export const useLatestDocumentVersion = (documentId, options = {}) => {
  const { fields } = options;
  const [latestVersion, setLatestVersion] = useState(null);

  useEffect(() => {
    if (!documentId) return
    let url = "/documents/" + documentId + "/versions?last";
    if (fields) url += "&fields=" + fields;
    api
      .get(url)
      .then((result) => {
        setLatestVersion(result.data);
      })
      .catch((err) => {
        console.log("Cannot fetch last version", err.response);
      });
  }, [documentId, fields]);
  return latestVersion;
};
