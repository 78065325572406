import React, { useRef, useEffect, useState, memo, useMemo } from 'react';
import { Path } from 'slate';
import { ReactEditor } from 'slate-react';
import { Tooltip } from 'antd';
import { useContract, setEditorComments } from 'hooks';
import { formatting } from 'core/config/contractDefaults';
import { ptToEm } from 'core/utils/general';
import { fixDate } from 'components/ui';

const { fontSize } = formatting;

export const RenderLeaf = ({ attributes, children, leaf, editor }) => {
  if (!attributes.style) attributes.style = {};

  if (editor.meta /* && editor.meta.markup */) {
    if (leaf._markup_add)
      return (
        <span {...attributes} className="markup_mark_inserted">
          {children}
        </span>
      );
    if (leaf._markup_del)
      return (
        <span {...attributes} className="markup_mark_deleted">
          {children}
        </span>
      );
  }

  if (leaf.color) {
    attributes.style.color = leaf.color;
  }

  if (leaf.size) {
    attributes.style.fontSize = leaf.size + 'em';
  } else {
    attributes.style.fontSize = ptToEm(fontSize) + 'em';
  }

  if (leaf.bold) {
    attributes.style.fontWeight = '600';
    // children = <strong {...attributes}>{children}</strong>
  }

  if (leaf.code) {
    children = <code {...attributes}>{children}</code>;
  }

  if (leaf.italic) {
    attributes.style.fontStyle = 'italic';
  }

  if (leaf.underlined) {
    attributes.style.textDecoration = 'underline';
  }

  if (leaf.highlight) {
    attributes.style.backgroundColor = '#ffeeba';
  }

  if (leaf.tab) {
    attributes.tab = 'true';
  }

  if (leaf.comments && leaf.comments.length > 0) {
    return (
      <Comments leaf={leaf} attributes={attributes} editor={editor}>
        {children}
      </Comments>
    );
  }

  /* if(leaf.text === "") {
      attributes['data-empty'] = true
    } */

  return <span {...attributes}>{children}</span>;
};

const Comments = memo(({ leaf, children, attributes, editor }) => {
  const ref = useRef();
  const [currentPath, setCurrentPath] = useState(null);

  // Ensure that we receive the proper slate path of the leaf.
  useEffect(() => {
    if (ref.current !== undefined) {
      try {
        const slateNode = ReactEditor.toSlateNode(editor, ref.current);
        const path = ReactEditor.findPath(editor, slateNode);
        if ((currentPath === null && path !== null) || !Path.equals(currentPath, path)) {
          setCurrentPath(path);
        }
      } catch (err) {}
    }
  }, [editor, setCurrentPath, currentPath]);
  const RenderComments = useMemo(
    () =>
      leaf.comments.map((id) => {
        return (
          <Comment key={id} id={id} fontSize={attributes.style.fontSize} path={currentPath} editor={editor} />
        );
      }),
    [attributes.style.fontSize, currentPath, editor, leaf.comments]
  );

  const classNames = useMemo(() => {
    return leaf.comments.map((id) => 'cmnt_' + id).join(' ');
  }, [leaf.comments]);

  const RenderAll = useMemo(() => {
    return (
      <span className="editor-comment-text position-relative">
        {RenderComments}
        <span {...attributes} ref={ref} className={classNames}>
          {children}
        </span>
      </span>
    );
  }, [RenderComments, attributes, ref, classNames, children]);

  return RenderAll;
});

const Comment = memo(({ id, fontSize, path, editor }) => {
  const contract = useContract();
  if (!path) {
    return null;
  }

  // We only want to display the comment indicator
  // for the first leaf that has the relevant comment
  // (in case of nested comments).
  if (!editor.commentFirstLeafs[id]) {
    editor.commentFirstLeafs[id] = path;
  } else {
    const otherPath = editor.commentFirstLeafs[id];
    if (Path.isBefore(path, otherPath)) {
      editor.commentFirstLeafs[id] = path;
    } else {
      return null;
    }
  }

  const posts =
    (contract?.data?.create?.comments &&
      contract.data.create.comments[id] &&
      contract.data.create.comments[id].posts) ||
    null;

  if (!posts) return null;

  const dates = posts.map((post, index) => (
    <div key={index}>
      {fixDate(new Date(post.time).toISOString())}: {getPostUserName(post)}
    </div>
  ));

  return (
    <Tooltip
      title={dates}
      placement="left"
      className="editor-comment-tooltip"
      contentEditable={false}
      onClick={() => {
        setEditorComments({ id });
      }}
      onMouseEnter={() => {
        for (const elem of document.getElementsByClassName('cmnt_' + id))
          elem.style.borderBottom = '2px solid rgb(255, 170, 68)';
      }}
      onMouseLeave={() => {
        for (const elem of document.getElementsByClassName('cmnt_' + id)) elem.style.borderBottom = 'none';
      }}
    >
      <i
        className="mdi mdi-comment-alert editor-comment-icon"
        contentEditable={false}
        style={{
          marginTop: '-' + fontSize,
          userSelect: 'none',
        }}
      />
    </Tooltip>
  );
});

function getPostUserName(post) {
  // console.log('Post user ? ', post)
  if (!post || !post.user || !post.user.firstName) return 'Unknown';
  return post.user.firstName + ' ' + post.user.lastName;
}
