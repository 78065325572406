import React, { memo, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, Card, notification } from 'antd';
import { setLegalPersons } from 'appRedux/actions';
import Chart from 'routes/studio/Views/Input/renderDraftUI/components/orgchart/views/edit';
import api from 'utils/api';
import { mapLegalPersons } from 'core/utils/initDraft/mapLegalPersons';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

export default memo(({ customer }) => {
  const dispatch = useDispatch();
  const structureFetched = useRef(false);
  const legalPersons = useSelector((state) => state.legalPersons);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const formatMessage = useIntlMessage();

  useEffect(() => {
    async function fetchData() {
      const structureResponse = await api.get(`/customers/${customer.id}/structure`);
      structureFetched.current = true;
      dispatch(setLegalPersons(mapLegalPersons(structureResponse.data)));
    }

    fetchData();
  }, [customer.id, dispatch]);

  const updateChart = () => {
    api
      .put(`/customers/${customer.id}/structure`, { legalPersons })
      .then((res) => {
        notification.success({
          message: formatMessage('app.orgchart.saved'),
        });
        setUnsavedChanges(false);
      })
      .catch((err) => {
        // Handled generically in api instead
      });
  };

  if (!legalPersons || !structureFetched.current) return <IntlMessages id="app.general.Loading" />;

  return (
    <Card
      title={
        <span>
          <IntlMessages id="app.orgchart.header" />
        </span>
      }
      extra={
        <>
          {unsavedChanges && (
            <Alert
              description={<IntlMessages id="app.orgchart.unsavedChanges" />}
              type="warning"
              showIcon
              style={{ display: 'inline-flex', marginRight: '1em' }}
            />
          )}
          <Button type="primary" onClick={updateChart}>
            <IntlMessages id="desc.Save" className="m-0" />
          </Button>
        </>
      }
    >
      <Chart modules={[]} onlyView unsavedChanges={unsavedChanges} setUnsaved={setUnsavedChanges} />
    </Card>
  );
});
