import { updateReferences as utilUpdateReferences } from "../../../utils";

export function updateReferences() {

  let draftValue = this.draftValue || null
  const newContent = utilUpdateReferences(this.contract, {draftValue})

  return newContent

}
