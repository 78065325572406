
export const listPropCosModule = {
  id: "listPropCosModule",
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    return (node, parents) => {
      if (!api.utils.engine.matchModule(node, "facilityAgreement", "listPropCos")) return;

      const affectedCardIds = entries.map((entry) => entry.cardId);

      if (affectedCardIds.includes("property")) {
        if (!state.input.property || api.utils.general.ocount(state.input.property) === 0) {
          node.children = [this.makeParagraph("[**]")];
          this.markNodeUpdate(node);
          return;
        }
      } else if (!affectedCardIds.includes("borrower")) {
        // Neither property or borrower was affected. Abort.
        return;
      }

      const properties = state.input.property && Object.keys(state.input.property).map(key => state.input.property[key])
      const borrowers = state.input.borrower && Object.keys(state.input.borrower).map(key => state.input.borrower[key])

      const propcos = [
        ...new Set(
          properties
            .filter((property) => {
              if (!borrowers) return true;
              return (
                api.utils.engine.findIndex(borrowers, (borrower) => borrower.name === property.owner) ===
                -1
              );
            })
            .map((prop) => prop.owner)
        ),
      ];

      if (propcos.length === 0) {
        node.children = [this.makeParagraph("[**]")];
        this.markNodeUpdate(node);
        return;
      }

      const paragraphs = propcos.map((propco) => this.makeParagraph(propco || "[**]"));
      node.children = paragraphs;

      this.markNodeUpdate(node);
    };
  },
};
