import {
  createDraft as immerCreateDraft,
  finishDraft as immerFinishDraft,
  isDraft as immerIsDraft,
} from '../../../../import/immer';
import { format_number, arrays_equal } from '../../../utils/general';
import { organiseBookmarks, find } from '../../../engine/utils';
import { Contract } from '../../../interfaces';
import uuid from 'uuid-random';

export function updateReferences(contract, options = {}) {
  let draftValue = options.draftValue;
  const validSpecifiedDraft = draftValue && immerIsDraft(draftValue);

  const settings = Contract.getSettings(contract);

  const content = Contract.getContent(contract);
  if (!validSpecifiedDraft) draftValue = immerCreateDraft(content);

  const listFormat = (settings && settings.format && settings.format.listFormat) || null;

  const { bookmarks, refBindings, headings, refMap } = organiseBookmarks(
    draftValue,
    {
      bookmarks: true,
      refs: true,
      headings: true,
      toc: true,
    },
    contract
  );
  if (refBindings.size === 0) {
    console.log('No refs... ', JSON.parse(JSON.stringify(content)));
  }

  // console.log('Bookmarks: ', { bookmarks, refBindings, refMap, headings });

  const refs = find(draftValue, (n) => n && n.variant === 'ref');

  for (const ref of refs) {
    setRef(ref, bookmarks, listFormat, refBindings, settings);
  }

  const tocs = find(draftValue, (n) => n && n.data && n.data.fill && n.data.fill.type === 'TOC');
  const tocContent = makeTOC(headings);
  for (const toc of tocs) {
    toc.children = tocContent;
  }

  // If we received the content already as an immer draft,
  // return it the same way.
  if (validSpecifiedDraft) return draftValue;

  // Otherwise, return a finished draft.
  return immerFinishDraft(draftValue);
}

function makeTOC(headings) {
  const tocContent = [];

  for (const id in headings) {
    if (headings[id].type !== 'heading_one') continue;
    tocContent.push({
      type: 'list_item',
      data: {
        item_id: uuid(),
        template_id: 'default_toc_item',
      },
      children: [
        /* {
          text: headings[id].clause.join('.'),
          size: 0.8,
        }, */
        {
          text: headings[id].headingText,
          // size: 0.8,
          // tab: true,
        },
      ],
    });
  }
  // return tocContent;

  return [
    {
      type: 'numbered_list',
      data: {
        _format: {
          keepLeft: 'true',
          listType: 'decimal',
          indicatorSuffix: '.',
        },
      },
      children: tocContent,
    },
  ];
}

function setRef(ref, bookmarkNames, listFormat, refBindings, settings) {
  if (
    !ref.data ||
    !ref.data.name ||
    !ref.children ||
    ref.children.length === 0 ||
    !bookmarkNames[ref.data.name]
  ) {
    return;
  }
  const refname = ref.data.name;
  let text = '';
  let listText = '';
  let clauseText = '';
  let scheduleText = '';
  let bookmarkList = Array.isArray(bookmarkNames[refname].list) && bookmarkNames[refname].list;
  let bookmarkListOriginalLength = bookmarkList ? bookmarkList.length : 0;
  const hadList = bookmarkList.length > 0;
  let refBinding = refBindings.get(ref);
  const headerLevelStartsAsText =
    settings && settings.format && settings.format.headers && settings.format.headers.headerLevelStartsAsText;

  if (!refBinding) {
    refBinding = { x_clause: [], x_list: [] };
    // console.log('Missing refBinding ', ref)
    // If missing, a parent of the relevant reference is inactive. No need to update?
    return null;
  }

  // Compare paths between the reference and the bookmark.
  // Strip out any which are the same, to get a clearer reference.
  // I.E: A ref in 5.2(b) to bookmark in 5.2(c) could refer to (c) instead of 5.2(c)
  const sameClause =
    refBinding.x_clause &&
    bookmarkNames[refname].clause &&
    arrays_equal(refBinding.x_clause, bookmarkNames[refname].clause);

  if (sameClause && bookmarkList && refBinding.x_list) {
    let tmp = remove_first_commons(bookmarkList, refBinding.x_list);
    // console.log('Trans: ', bookmarkList, refBinding.x_list, tmp)
    bookmarkList = tmp;
  }

  if (
    bookmarkNames[refname].clause &&
    bookmarkNames[refname].clause.length > 0 &&
    (!sameClause || !hadList)
  ) {
    clauseText = bookmarkNames[refname].clause.join('.');
  }
  if (bookmarkNames[refname].schedule && bookmarkNames[refname].schedule.length > 0) {
    scheduleText = bookmarkNames[refname].schedule[bookmarkNames[refname].schedule.length - 1];
  }

  if (sameClause) {
    if (hadList) {
      if (bookmarkList.length === 0) {
        // all have been stripped = same list item
        listText =
          ' this item ' +
          format_number(bookmarkNames[refname].list[bookmarkNames[refname].list.length], 0, listFormat);
      } else {
        const padding = bookmarkListOriginalLength - bookmarkList.length || 0;
        for (let i = 0; i < bookmarkList.length; i++)
          listText += format_number(bookmarkList[i], i + padding, listFormat);
      }
    }
    // else clauseText = 'this Clause '+clauseText;
  } else if (bookmarkList.length > 0) {
    // const padding = bookmarkListOriginalLength - bookmarkList.length || 0;
    for (let i = 0; i < bookmarkList.length; i++) listText += format_number(bookmarkList[i], i, listFormat);
  }

  text = (clauseText || scheduleText) + listText;

  // Add the name of the clause/header
  if (listText === '' && bookmarkNames[refname].headingText && !sameClause) {
    if (
      headerLevelStartsAsText &&
      bookmarkNames[refname].hIndex &&
      bookmarkNames[refname].hIndex >= headerLevelStartsAsText
    ) {
      // console.log('Dont add for me...')
    } else text += ' (' + bookmarkNames[refname].headingText + ')';
  }

  ref.children = [{ text }];
}

// Remove all the common values until non-match is found
// [1,4,6,8] and [1,4,7,8] -> [6,8] from the first arr
function remove_first_commons(a1, a2) {
  for (let i = 0; i < a1.length; i++) if (a1[i] !== a2[i]) return a1.slice(i);
  return a1;
}
