import React, { useState } from 'react';
import { Editor, Transforms, Range, Path } from 'slate';
import { useEditor } from 'slate-react';
import { standardConceptGrammar, standardConceptVariations } from 'core/data/concepts/built-in-data';
import { useModalContent } from 'components/ui/modals';
import { useContract } from 'hooks';
import { Contract, Concept } from 'core/interfaces';
import { Dropdown, notification, Checkbox, Menu, Select, Input, Button, Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { accepting, matchAccepting } from 'core/types/elements';
import uuid from 'uuid-random';

const { Option } = Select;

export function accepts(element, editor, options = {}) {
  let { path } = options;
  if (!path) path = editor.selection?.anchor?.path;
  if (!path) return null;

  const tree = Array.from(Editor.nodes(editor)).reverse();
  for (const [candidateNode, candidatePath] of tree) {
    if (!accepting[candidateNode?.type]) continue;
    const candidateAccepts = accepting[candidateNode.type];
    for (const candidateAcceptItem of candidateAccepts) {
      if (matchAccepting(candidateAcceptItem, element)) return resolveAcceptedPath(candidatePath, path);
    }
  }
  return path.slice(0, 1);
}
function resolveAcceptedPath(candidatePath, path) {
  console.log('resolve ', { candidatePath, path });
  if (path.length <= candidatePath.length) return null;
  return [...candidatePath, path[candidatePath.length]];
}

const defaultSection = () => ({
  type: 'section',
  data: {
    template_id: 'section_default',
    section_break: 'nextPage',
    item_id: uuid(),
  },
  children: [defaultClause()],
});

const defaultSectionClean = () => ({
  type: 'section',
  data: {
    template_id: 'section_default',
    section_break: 'nextPage',
    item_id: uuid(),
  },
  children: [defaultParagraph()],
});

const defaultParagraph = (text = '', options = {}) => {
  let textNode;

  if (options.marks) {
    textNode = { text, ...options.marks };
  } else {
    textNode = { text };
  }
  return {
    type: 'paragraph',
    data: {
      template_id: '_std_paragraph_1',
      item_id: uuid(),
    },
    children: [textNode],
  };
};

export const defaultClause = (text = '', options = {}) => ({
  type: 'clause',
  data: {
    template_id: 'clause_default',
    bookmarks: [],
    label: 'Untitled Clause',
    item_id: uuid(),
  },
  children: [defaultParagraph(text, options)],
});

const defaultDefinition = () => ({
  type: 'paragraph',
  data: {
    template_id: '_std_definitions_1',
    item_id: uuid(),
  },
  children: [
    {
      text: '"',
    },
    {
      text: 'name',
      bold: true,
    },
    {
      text: '" means ...',
    },
  ],
});

const templates = {
  sections: [
    {
      name: 'Section',
      action: 'add',
      content: defaultSectionClean(),
    },
    {
      name: 'Clause Section with 1 clause',
      action: 'add',
      content: defaultSection(),
    },
    {
      name: 'Section',
      action: 'add',
      content: defaultSectionClean(),
      options: {
        target: 'after',
      },
    },
    {
      name: 'Clause Section with 1 clause',
      action: 'add',
      content: defaultSection(),
      options: {
        target: 'after',
      },
    },
  ],
  clauses: [
    {
      name: 'Insert Standard Clause',
      action: 'add',
      content: defaultClause(),
    },
    {
      name: 'Definitions Clause',
      action: 'add',
      content: {
        type: 'clause',
        data: {
          template_id: '3FDE1AD0-EE38-557B-6FAF-625ACB789E5D',
          level: 2,
          bookmarks: [],
          label: 'Definitioner',
        },
        children: [
          {
            type: 'heading_one',
            children: [
              {
                text: 'Definitions',
              },
            ],
            data: {
              definitions_id: 'mainDefinitions',
              template_id: '10276cd3-441c-4b10-913e-5e793e6b1f04',
              item_id: '333b30a3-2664-4f59-ab90-44d726220310',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                text: 'I detta Avtal betyder:',
              },
            ],
            data: {
              template_id: 'ae855baa-6ac2-42b6-8ece-e9909f7a32b9',
              item_id: 'a4bb7cba-28f3-49f4-918d-d0ca805efa1a',
            },
          },
          {
            type: 'definitions',
            data: {
              template_id: 'default_definitions',
              definitions_id: 'mainDefinitions',
            },
            children: [
              defaultDefinition(),
              defaultDefinition(),
              defaultDefinition(),
              defaultDefinition(),
              defaultDefinition(),
            ],
          },
        ],
      },
    },
    {
      name: 'List Contract Parties Clause',
      content: {
        type: 'clause',
        data: {
          module: {
            id: 'facilityAgreement',
            method: 'listParties',
            fieldType: 'agreement',
          },
          template_id: 'list_contract_parties',
        },
        children: [
          {
            text: '[list of parties automatically inserted here]',
          },
        ],
      },
    },
    {
      name: 'Wrap in Standard Clause',
      action: 'wrap',
      content: defaultClause(),
    },
  ],
  schedule: [
    {
      name: 'Schedule with heading 1 + heading 2',
      content: {
        type: 'schedule',
        data: {
          template_id: 'schedule_default',
          level: 1,
          bookmarks: [],
        },
        children: [
          {
            type: 'schedule_one',
            children: [
              {
                text: '[Schedule name]',
              },
            ],
            data: {
              template_id: 'schedule_one_default',
              item_id: 'f6f866f1-a899-455f-bbe0-c26174363748',
            },
          },
          {
            type: 'schedule',
            data: {
              template_id: 'schedule_default_level_2',
              level: 2,
              bookmarks: [],
            },
            children: [
              {
                type: 'schedule_two',
                children: [
                  {
                    text: 'Sub name',
                  },
                ],
                data: {
                  template_id: 'schedule_two_default',
                  item_id: '0c5a0351-793f-463c-8036-f5615172444c',
                },
              },
            ],
          },
          defaultParagraph(),
        ],
      },
    },
  ],
  lists: [
    {
      name: 'Numbered list',
      content: {
        type: 'numbered_list',
        data: {
          template_id: 'default_numbered_list',
        },
        children: [
          {
            type: 'list_item',
            data: {
              template_id: 'default_list_item',
            },
            children: [{ text: 'list item' }],
          },
        ],
      },
    },
    {
      name: 'Bullet list',
      content: {
        type: 'bulleted_list',
        data: {
          template_id: 'default_bulleted_list',
        },
        children: [
          {
            type: 'list_item',
            data: {
              template_id: 'default_list_item',
            },
            children: [{ text: 'list item' }],
          },
        ],
      },
    },
  ],
  inlines: [
    {
      name: 'Concept',
      modalAdjust: ConceptKey,
      content: {
        type: 'field',
        variant: 'concept',
        data: {
          template_id: 'default_concept_field',
          key: null,
        },
        children: [{ text: '[concept value]' }],
      },
    },
    {
      name: 'Optional text',
      // modalAdjust: OptCondition,
      content: {
        type: 'field',
        children: [
          {
            text: 'optional text',
          },
        ],
        data: {
          template_id: 'default_opt_field',
          acp: {
            and: ['FIX!!!'],
          },
        },
        variant: 'opt',
      },
      options: {
        inline: true,
      },
    },
    {
      name: 'Enum',
      content: {
        type: 'field',
        children: [
          {
            text: 'enum text',
          },
        ],
        data: {
          template_id: 'default_enum_field',
          enum: 'X',
        },
        variant: 'enum',
      },
      options: { inline: true },
    },
    {
      name: 'Inline XX',
      content: {
        type: 'field',
        children: [
          {
            text: 'inline text',
          },
        ],
        data: {
          template_id: 'default_inline_field',
        },
        variant: 'XXX',
      },
      options: { inline: true },
    },
    {
      name: 'Variable text',
      modalAdjust: VariField,
      content: {
        type: 'field',
        children: [
          {
            text: '[**]',
          },
        ],
        data: {
          template_id: 'default_vari_field',
        },
        variant: 'vari',
      },
    },
  ],
};

export default function () {
  const editor = useEditor();

  return (
    <div>
      <ListBlocks editor={editor} />
    </div>
  );
}

function makeIds(node) {
  if (typeof node === 'object') {
    if (typeof node.text === 'string') return;
    if (!node.data) {
      node.data = {
        item_id: uuid(),
      };
    } else {
      node.data.item_id = uuid();
    }
  }
  if (node.children) {
    for (const child of node.children) makeIds(child);
  }
  return node;
}

function ListBlocks({ editor }) {
  const [modalAdjustmentElement, setModalAdjustmentElement] = useState(null);

  if (!editor.selection) {
    return <div>Place the cursor in the document.</div>;
  }

  const doInsertContent = (item) => (content, options = {}) => {
    const { action = 'add' } = item;
    const { target = 'before' } = options;

    console.log('Do insert action for selection ', editor.selection);

    // return;

    content = JSON.parse(JSON.stringify(content));
    const node = makeIds(content);

    console.log('Hmmmmm ', { inl: options.inline, isExp: Range.isExpanded(editor.selection) });

    if (options.inline && Range.isExpanded(editor.selection)) {
      content.children = [];
      Transforms.wrapNodes(editor, content, { at: editor.selection, split: true });
      setModalAdjustmentElement(null);
      return;
    } else if (options.inline && content.children.length === 0) {
      console.log('what is content', content);
      content.children = [{ text: '[**]' }];
    } else if (action === 'wrap') {
      content.children = [];
      Transforms.wrapNodes(editor, content, { at: editor.selection });
      console.log('Wrap in ', content);
      return;
    }

    // let targetPath = findTargetPath(content, editor);
    let targetPath = accepts(content, editor);
    if (!targetPath) return;
    if (target === 'after') targetPath = Path.next(targetPath);
    console.log('Shall insert ', { action, content, targetPath });

    // console.log("node ", { node, targetPath });
    // if (targetPath) editor.insertNode(node, { at: targetPath });

    if (targetPath) Transforms.insertNodes(editor, node, { at: targetPath });

    setModalAdjustmentElement(null);
  };

  const insertItem = (item) => {
    const { content, modalAdjust } = item;

    if (modalAdjust) {
      // const ItemModalAdjust = modalAdjust;
      setModalAdjustmentElement(
        <ModalValue
          editor={editor}
          content={JSON.parse(JSON.stringify(content))}
          insertContent={doInsertContent(item)}
          Component={modalAdjust}
          setModalAdjustmentElement={setModalAdjustmentElement}
        />
      );
      return;
    }

    if (!editor.selection || !editor.selection.anchor.path) {
      notification.error({
        message: 'Place cursor in document',
      });
      return console.log('No selection');
    }

    doInsertContent(item)(content, item.options);
  };

  const removeSection = () => {
    Transforms.removeNodes(editor, { match: (n) => n.type === 'section' });
  };

  const unwrapInline = () => {
    const link = Array.from(Editor.nodes(editor, { match: (n) => n.type === 'link' || n.type === 'field' }));
    if (!link || link.length < 1) return;
    Transforms.unwrapNodes(editor, { match: (n) => n.type === 'link' || n.type === 'field' });
  };

  const menuItems = [
    {
      header: 'Sections',
      content: <ListTemplateContent type="sections" insertItem={insertItem} removeSection={removeSection} />,
      icon: 'mdi-book-open-page-variant',
    },
    {
      header: 'Clauses',
      content: <ListTemplateContent type="clauses" insertItem={insertItem} />,
      icon: 'mdi-format-section',
    },
    {
      header: 'Schedule',
      content: <ListTemplateContent type="schedule" insertItem={insertItem} />,
      icon: 'mdi-view-agenda',
    },
    {
      header: 'Lists',
      content: <ListTemplateContent type="lists" insertItem={insertItem} />,
      icon: 'mdi-format-list-bulleted',
    },
    {
      header: 'Inlines',
      content: <ListTemplateContent type="inlines" insertItem={insertItem} unwrapInline={unwrapInline} />,
      icon: 'mdi-format-wrap-inline',
    },
  ];

  return (
    <>
      {modalAdjustmentElement ? modalAdjustmentElement : null}
      {menuItems.map((menuItem) => {
        const overlayContent = <Menu>{menuItem.content}</Menu>;
        return (
          <Dropdown
            className="menu-collapse"
            key={menuItem.header}
            overlay={overlayContent}
            onMouseDown={(event) => event.preventDefault()}
          >
            <Button>
              <i className={`mdi mr-1 ${menuItem.icon}`} />
              <small>{menuItem.header}</small>
              <DownOutlined style={{ color: 'rgba(0, 0, 0, 0.25)', fontSize: '14px' }} />
            </Button>
          </Dropdown>
        );
      })}
    </>
  );
}

function ListTemplateContent({ type, insertItem, removeSection, unwrapInline, ...rest }) {
  if (!templates[type]) return null;
  const panelItems = templates[type].map((item, index) => {
    const { action = 'add', options = {} } = item;
    const where = options.target === 'after' ? 'after' : 'before';
    return (
      <Menu.Item
        key={index}
        onMouseDown={(event) => {
          event.preventDefault();
          insertItem(item);
        }}
        {...rest}
      >
        <i className={'mdi mr-2 d-flex ' + (action === 'wrap' ? 'mdi-crop-free' : 'mdi-plus')} /> {item.name}{' '}
        ({where})
      </Menu.Item>
    );
  });
  if (type === 'sections') {
    // mdi-close
    panelItems.push(
      <Menu.Item
        key={'remove-section'}
        onMouseDown={(event) => {
          event.preventDefault();
          removeSection();
        }}
        {...rest}
      >
        <i className={'mdi mr-2 d-flex mdi-close'} /> Remove Current Section
      </Menu.Item>
    );
  }
  if (type === 'inlines') {
    // mdi-close
    panelItems.push(
      <Menu.Item
        key={'remove-link'}
        onMouseDown={(event) => {
          event.preventDefault();
          unwrapInline();
        }}
        {...rest}
      >
        <i className={'mdi mr-2 d-flex mdi-close'} /> Remove Current Field
      </Menu.Item>
    );
  }
  return panelItems;
}

function ModalValue({ content, insertContent, Component, setModalAdjustmentElement, editor }) {
  const closeModal = () => {
    setModalAdjustmentElement(null);
  };

  const [Modal, , , open] = useModalContent('Rulex', {
    defaultOpen: true,
    onClose: closeModal,
  });

  return (
    <div className="x">
      <Modal size="lg" className="x-modal">
        {open && (
          <div>
            {/* <pre>{JSON.stringify(content, null, 2)}</pre> */}
            <Component
              content={content}
              insertContent={insertContent}
              closeModal={closeModal}
              editor={editor}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}

function OptCondition({ content, insertContent, closeModal }) {
  return (
    <button
      onMouseDown={(event) => {
        event.preventDefault();
        insertContent(
          {
            ...content,
            data: {
              ...content.data,
              acp: { and: ['FIX!'] },
            },
          },
          { inline: true }
        );
      }}
    >
      add
    </button>
  );
  /* return (
    <QB
      rule={null}
      onSave={(logic) => {
        console.log("Save logic as ", logic);
        insertContent(
          {
            ...content,
            data: {
              ...content.data,
              acp: logic,
            },
          },
          { inline: true }
        );
      }}
    />
  ); */
}

function VariField({ content, insertContent, closeModal, editor }) {
  const [value, setValue] = useState('');
  const [cardId, setCardId] = useState(null);
  const [inputId, setInputId] = useState(null);

  const contract = useContract();
  const ui = Contract.getUi(contract);

  if (!ui) {
    console.log('We got no ui...', ui);
  }

  const cardIds = ui && ui.cards && Object.keys(ui.cards);
  const inputIds = cardId && ui.cards[cardId].inputs_order;
  // const inputIds = cardId && Object.keys(ui.cards[cardId].inputs);

  const handleCardIdChange = (val) => setCardId(val);
  const handleInputIdChange = (val) => setInputId(val);

  const handleChange = (e) => setValue(e.target.value);

  const insert = () => {
    const val = (cardId && inputId && cardId + '.' + inputId) || value || '_none_';
    /* const string = Range.isExpanded(editor.selection)
      ? Editor.string(editor, editor.selection)
      : "[**]"; */
    insertContent(
      {
        ...content,
        data: {
          ...content.data,
          name: val,
        },
        children: [
          // {text: string}
        ],
      },
      { inline: true }
    );
  };

  return (
    <div>
      <h5>Variable field</h5>
      <p>Enter the key code for the variable field.</p>
      <Input value={value} onChange={handleChange} />
      <p>-- or --</p>
      <div>
        <Select value={cardId} onChange={handleCardIdChange}>
          <Option value={null} disabled>
            -- None --
          </Option>
          {cardIds &&
            cardIds.map((anId) => (
              <Option value={anId} key={anId}>
                {anId}
              </Option>
            ))}
        </Select>
        {cardId && inputIds && (
          <Select value={inputId} onChange={handleInputIdChange}>
            <Option value={null} disabled>
              -- None --
            </Option>
            {inputIds.map((anId) => (
              <Option value={anId} key={anId}>
                {anId}
              </Option>
            ))}
          </Select>
        )}
      </div>
      <Button
        block
        className="mt-4"
        type="primary"
        onMouseDown={(event) => {
          event.preventDefault();
          insert();
        }}
      >
        Create
      </Button>
    </div>
  );
}

function ConceptKey({ content, insertContent, closeModal }) {
  const contract = useContract();
  const [describer, setDescriber] = useState('');
  const [concept, setConcept] = useState(Object.keys(standardConceptGrammar)[0]);
  const [genitive, setGenitive] = useState(false);

  const handleChangeGenitive = (e) => setGenitive(e.target.checked);

  const insert = () => {
    const language = Contract.getLanguage(contract);

    let key = describer ? describer + '_' : '';
    key += concept;
    key += genitive ? '_s' : '';
    const { value: singularValue } = Concept.grammar(concept, 'singularFixed', language, { contract });
    const { value: pluralValue } = Concept.grammar(concept, 'pluralFixed', language, { contract });
    insertContent(
      {
        ...content,
        data: { ...content.data, key },
        children: [
          {
            text: '[' + singularValue + ' / ' + pluralValue + ']',
          },
        ],
      },
      { inline: true }
    );
  };

  return (
    <div className="set-concept-key">
      <Row>
        <Col span={8}>
          <Select label="Modifier" value={describer} style={{ width: 120 }} onChange={setDescriber}>
            <Option value="">--none--</Option>
            {Object.keys(standardConceptVariations).map((variationKey) => (
              <Option key={variationKey} value={variationKey}>
                {standardConceptVariations[variationKey].label || variationKey}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Select value={concept} style={{ width: 120 }} onChange={setConcept}>
            {Object.keys(standardConceptGrammar).map((conceptName) => (
              <Option key={conceptName} value={conceptName}>
                {conceptName}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Checkbox checked={genitive} onChange={handleChangeGenitive}>
            's
          </Checkbox>
        </Col>
      </Row>
      <div>
        <Button
          className="mt-4"
          type="primary"
          onMouseDown={(event) => {
            event.preventDefault();
            insert();
            closeModal();
          }}
        >
          Insert Concept
        </Button>
      </div>
    </div>
  );
}
