import React from 'react'
import { DragLayer } from 'react-dnd'

const defaultStyle = (item, currentOffset) => (
  {
    left: currentOffset.x,
    top: currentOffset.y,
    position: 'fixed'
  }
)

class DragPreview extends React.Component {
    render() {
        const {
          isDragging,
          currentOffset,
          item
        } = this.props
        if(!isDragging || !currentOffset || !item.withDragPreview) {
            // console.log('ret null', isDragging, currentOffset, item);
            return null;
        }
        return (
          <svg style={defaultStyle(item, currentOffset)}>
            <circle cx={14} cy={14} r={12} fill={item.color} stroke="black"
              strokeWidth={2}/>
          </svg>);
    
      }
}


export default DragLayer((monitor, props) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  otherProps: props,
}))(DragPreview)