export const listPledgorsModule = {
  id: 'listPledgorsModule',
  time: 1,
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    if (!this.contract.data.outline || this.contract.data.outline.id_name !== 'multi_pledge_shb') return;
    if (!this._tmpDraft.affectedConcepts || !this._tmpDraft.affectedConcepts.includes('pledgor')) return;
    return (node, parents) => {
      if (!api.utils.engine.matchModule(node, 'securityAgreement', 'listPledgors')) return;

      const pledgors = getPledgors(this.contract, state, api);

      if (!pledgors || pledgors.length < 1) {
        // this.log('No pledgors found');
        return;
      }

      const newTable = this.makeTable(pledgors.map((item) => [item.name, item.identificationNumber || '[**]']));
      let newTableChildren;
      if (node.children[0]) {
        newTableChildren = [node.children[0], ...newTable.children];
      } else {
        newTableChildren = newTable.children;
      }

      node.children = newTableChildren;
      this.markNodeUpdate(node);
    };
  },
};

function getPledgors(contract, state, api) {
  const concepts = api.interfaces.Contract.getConcepts(contract);
  if (!concepts) return []
  const pledgorConcept = concepts.find((concept) => concept.id === 'pledgor');
  return api.interfaces.Concept.getConceptEntities(pledgorConcept, contract, state) || [];
}
