import React from "react";
// import { FormGroup, Label, Input, FormText } from "reactstrap";
import { Form, Select } from "antd";
import { getValue } from "./utils/values";
import { useSelector } from "react-redux"
const { Option } = Select;

function InputSelect(props) {
  const {
    label,
    handleChange,
    language,
    cardId,
    field,
    inputIndex,
    cardUid
  } = props;
  const nodes = useSelector((state) => {
    if (field.content !== "node-names") return []
    return state.legalPersons
  })
  let value = getValue(props, field.value ? field.value : []);

  const id = field.name + inputIndex + cardId + cardUid;

  return (
    <Form.Item
      label={label}
      extra={
        field.info ? (
          <small className="muted d-block">{field.info[language]}</small>
        ) : null
      }
    >
      <Select
        mode="tags"
        onChange={handleChange}
        name={field.name}
        id={id}
        value={value}
        placeholder=""
      >
        {renderOptions(props, nodes)}
      </Select>
    </Form.Item>
  );
}

function renderOptions(props, nodes) {
  const {
    language,
    cardId,
    field,
    inputIndex,
  } = props;
  if (field.content === "node-names") {
    return [].concat(
      nodes
        .filter(node => node.parentId !== null)
        .map((node, nodeIndex) => (
          <Option
            key={
              node.id +
              cardId +
              inputIndex +
              nodeIndex +
              field.name +
              "y"
            }
            value={node.name}
          >
            {node.name}
          </Option>
        ))
    );
  }
  return field.content.map((option, contentIndex) => (
    <Option
      key={
        option.id +
        cardId +
        inputIndex +
        contentIndex +
        field.name +
        "x"
      }
      disabled={option.disabled}
      value={
        option.id
        /* option.values && option.values.hasOwnProperty(language)
          ? option.values[language]
          : option.id */
      }
    >
      {option.label[language]}
    </Option>
  ));
}

export default InputSelect;
