import React from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import { sidebarShowFields } from 'core/types/elements';
import { Tooltip } from 'antd';
import { useIsTemplateStudio, setEditorComments } from 'hooks';
import General from '../../components/SidebarComponents/General';
import TableOfContents from '../../components/SidebarComponents/TableOfContents';
import Fields from '../../components/SidebarComponents/Fields';
import EditFlow from '../../components/SidebarComponents/EditFlow';
import IntlMessages from 'util/IntlMessages';

export default function useEditSidebarTabs({ activeKey }) {
  const tooltipPlacement = 'right';

  const isTemplate = useIsTemplateStudio();
  const editor = useSlate();

  const [...selectedFields] = Editor.nodes(editor, {
    match: sidebarShowFields,
  });
  let hasFields = selectedFields.length > 0;

  const tabs = [
    [
      <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.general" />}>
        <i className="mdi mdi-eye fs-lg" />
      </Tooltip>,
      <General />,
    ],
    [
      <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.contents" />}>
        <i className="mdi mdi-sort-descending fs-lg" />
      </Tooltip>,
      <TableOfContents />,
    ],
    [
      <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.comments" />}>
        <i className="mdi mdi-comment-outline fs-lg" />
      </Tooltip>,
      null,
      {
        onClick: () => setEditorComments({ open: true }),
      },
    ],
    [
      <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.fields" />}>
        <i
          className={'mdi mdi-developer-board fs-lg' + (hasFields && activeKey !== '2' ? ' highlight' : '')}
        />
      </Tooltip>,
      <Fields selectedFields={selectedFields} />,
    ],
  ];

  if (!isTemplate) {
    tabs.push([
      <Tooltip placement={tooltipPlacement} title={<IntlMessages id="studio.sidebar.editFlow" />}>
        <i className="mdi mdi-redo-variant fs-lg" />
      </Tooltip>,
      <EditFlow selectedFields={selectedFields} />,
    ]);
  }
  return tabs;
}
