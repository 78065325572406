import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

const SHOW_HAMBURGER = true;

const SidebarLogo = () => {
  const [isMini, setIsMini] = useState(false);

  const toggleMini = () => {
    setIsMini(!isMini);
    window.dispatchEvent(new CustomEvent('menu_mini', { detail: {} }));
  };

  return (
    <div className="layout-sider-header">
      {SHOW_HAMBURGER ? (
        <div className="linebar">
          <i className={`icon-btn icon icon-${isMini ? 'menu-unfold' : 'menu-fold'}`} onClick={toggleMini} />
        </div>
      ) : null}

      {/* {!isMini && (
        <Link to="/" className="site-logo" style={{ marginLeft: '60px' }}>
          <div className="sider-header-logo text-muted">
            <div>maintract</div>
          </div>
        </Link>
      )} */}
    </div>
  );
};

export default SidebarLogo;
