import React, { useState, useEffect } from 'react';
import { Avatar, Tabs, Breadcrumb, Badge } from 'antd';
import { ofindKey, ofindValues, ucfirst } from 'core/utils/general';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { useProject } from 'hooks';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import { userToName } from 'components/ui';

const { TabPane } = Tabs;

export default function TopHeader({ items, back, name, description, isFolder, icon, toPathMatch, ...rest }) {
  const history = useHistory();
  const location = useLocation();

  const match = items && ofindKey(items, (item) => location.pathname.includes(item.activeMatch));

  const onChangeMenu = (key) => {
    history.push(items[key].path(toPathMatch));
  };

  let renderIcon;

  if (icon.icon) renderIcon = icon.icon;
  else {
    renderIcon = <i className={(icon.className || 'mdi mdi-asterisk') + ' ico-md'} />;
  }

  let avatarClassName = 'size-60';
  let avatarStyle = {};
  if (icon.bgClassName) avatarClassName += ' ' + icon.bgClassName;
  else {
    avatarStyle.backgroundColor = icon.bg || '#1890ff';
  }
  if (isFolder) avatarStyle.borderRadius = '20%';

  return (
    <div className="project-top-header">
      <Breadcrumbs items={items} isFolder={isFolder} {...rest} />
      <div className="flex-column">
        <div className="flex-row align-items-center">
          <div
            className="profile-banner-top-left"
            style={{
              padding: '10px',
              borderRadius: '4px',
              margin: '20px 0px 15px',
            }}
          >
            <div className="profile-banner-avatar">
              <Avatar className={avatarClassName} alt="icon" icon={renderIcon} style={avatarStyle} />
            </div>
            <div className="profile-banner-avatar-info" style={{ maxWidth: '25vw' }}>
              <h2 className="fs-xxl font-weight-light">{name || ''}</h2>
              {description && <h5 className="mt-2">{description || ''}</h5>}
            </div>
          </div>
        </div>
        <div className="profile-banner-bottom ml-4">
          {items && (
            <Tabs activeKey={match} onChange={onChangeMenu}>
              {Object.keys(items).map((key) => {
                const item = items[key].hasOwnProperty('badge') ? (
                  <Badge
                    count={items[key].badge}
                    style={{
                      backgroundColor: items[key].badgeColor || '#1e91ff',
                      color: items[key].badgeTextColor || undefined,
                    }}
                    offset={[7, 0]}
                    size="small"
                    showZero={items[key].showZero}
                  >
                    {items[key].text}
                  </Badge>
                ) : (
                  items[key].text
                );
                return <TabPane tab={item} key={key}></TabPane>;
              })}
            </Tabs>
          )}

          {back && (
            <span className="profile-setting">
              <Link to={back.path}>
                <span className="link">
                  <i className="icon icon-arrow-left fs-lg mr-2 d-inline-flex vertical-align-middle" />
                  <span className="d-inline-flex vertical-align-middle ml-1 ml-sm-0">{back.name}</span>
                </span>
              </Link>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

const BREADCRUM_ICONS = {
  folder: 'mdi mdi-folder-outline',
  project: 'mdi mdi-cube-outline',
  document: 'mdi mdi-file-outline',
  version: 'mdi mdi-snowflake',
};

function Breadcrumbs(props) {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const parts = pathname.split('/');
  const lastPathPart = parts.slice(-1)[0];

  const { items, isFolder } = props;
  const project = useProject();
  const [doc, setDoc] = useState(props.doc);
  const contract = props.contract;
  const entity = props.entity;

  const documentId = contract?.documentId;

  useEffect(() => {
    if (!doc && documentId) {
      api
        .get('/documents/' + documentId + '?fields=name')
        .then((res) => {
          if (res && res.data) setDoc(res.data);
        })
        .catch((err) => {
          console.log('Fetch doc err', { err, er: err?.response });
        });
    }
  }, [setDoc, documentId, doc]);

  const collection = { project, document: doc, entities: props.person || {} };
  if (contract)
    collection.version = {
      name: 'Version ' + (contract?.info?.versionNumber || '1'),
    };

  let path = '';

  return (
    <Breadcrumb separator=">">
      <Breadcrumb.Item className="clickable hoverable" onClick={() => history.push('/main')}>
        <HomeOutlined />
      </Breadcrumb.Item>
      {parts.map((item, index) => {
        if (index === 0) return null;
        if (index === parts.length - 1) {
          const itemEntry =
            items && ofindValues(items, (matchItem) => matchItem.activeMatch === '/' + lastPathPart);
          const text = itemEntry && itemEntry.text ? itemEntry.text : ucfirst(item);
          return <Breadcrumb.Item key="last">{text}</Breadcrumb.Item>;
        }

        if (index % 2 !== 0) {
          const shortPath = path + '/' + item + (item[item.length - 1] !== 's' ? 's' : '');

          const dataKey = isFolder && item === 'folder' ? 'project' : item;

          path += '/' + item + '/' + parts[index + 1];
          const name = collection[dataKey] ? userToName(collection[dataKey]) : item;
          let iconContent = null;
          if (isFolder) iconContent = <i className={BREADCRUM_ICONS['folder'] + ' mr-2'} />;
          else if (BREADCRUM_ICONS[item]) iconContent = <i className={BREADCRUM_ICONS[item] + ' mr-2'} />;
          const thisPath = path;
          let itemKey = item;
          return (
            <React.Fragment key={name}>
              <Breadcrumb.Item
                className="clickable hoverable"
                onClick={() => history.push(shortPath)}
                key={name}
              >
                <IntlMessages id={'app.project.breadcrumb.' + itemKey} />
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="clickable hoverable"
                onClick={() => history.push(thisPath)}
                key={name}
              >
                {iconContent}
                {name}
              </Breadcrumb.Item>
            </React.Fragment>
          );
        }
        return null;
      })}
    </Breadcrumb>
  );
}
