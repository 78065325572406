import React from 'react';
import { Button, Card, Row, Col, Input, Select, Alert } from 'antd';
import CustomScrollbars from 'util/CustomScrollbars';
import { connect } from 'react-redux';
import Chart from 'components/orgchart/orgchart';
import { ocount, omap, ofilter, ofindValues } from 'core/utils/general';
import api from 'utils/api';

import { addRepeatable, updateInput } from 'appRedux/actions';

// import IntlMessages from "util/IntlMessages";

const CardBody = ({ children, ...rest }) => {
  return <div {...rest}>{children}</div>;
};
const Label = ({ children, ...rest }) => {
  return <div {...rest}>{children}</div>;
};

// const topLeftIcon = ["mdi mdi-arrow-down-bold-circle-outline", "color:#ff8227"];
const bottomRightIcon = ['mdi mdi-plus-circle', 'color:#3a9815'];

const mapStateToProps = (state) => ({
  input: state.input,
  legalPersons: state.legalPersons,
});
const mapDispatchToProps = (dispatch) => ({
  addRepeatable: (cardId, data) => dispatch(addRepeatable(cardId, data)),
  updateInput: (...args) => dispatch(updateInput(...args)),
});

let layout = [
  // each array item a row

  [
    // each item a column
    {
      class: 'nodename',
      align: 'center',
      dataField: 'name',
    },
  ],
  [
    {
      align: 'center',
      class: 'properties-field',
      metaDataField: 'numProps',
      custom: {
        x: '0',
        y: '145',
        width: '75',
        height: '75',
      },
    },
  ],
  /*
  [ 
    {
      class: '',
      align: 'center',
      metaDataField: 'classNames',
      id: 'orgchart_name'
    },
  ]
  */
];

class OrgChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNode: null,
      pendingNodeChanges: {},
      fetchingForCompanies: [],
      metaData: this.props.legalPersons.map((node) => ({
        ...node,
        metaData: {},
      })), // copy with empty object content
    };

    this.completedFetchNodes = 0;
    this.tmpProps = [];
    this.editNameRef = React.createRef();
  }

  newProperty = (data = {}) => {
    console.log('Data for new property ', data);
    this.props.addRepeatable('input.property', data);
  };

  componentDidMount() {
    /* let highestId = 0;
    this.props.nodes.forEach((node) => {
      if (node.id > highestId) highestId = node.id;
    }); */
    // console.log("Which are nodes ", this.props.nodes, highestId);
  }

  clickNodeEvent = (sender, args) => {
    this.setState({ selectedNode: args.node.data.name });
  };

  addToCompany = (nodeId) => {
    const node = this.props.legalPersons.find((item) => item.id === nodeId);
    if (!node) {
      return console.log('Cannot locate now with id ', nodeId);
    }
    const tmpId = '_x' + Date.now();
    const propertyData = { propertyOwner: { id: nodeId, type: 'legalPerson' }, _tmpId: tmpId };
    this.newProperty(propertyData);
    setTimeout(() => {
      this.editNodeAction(tmpId);
      if (this.editNameRef && this.editNameRef.current) this.editNameRef.current.focus();
    }, 240);
  };

  toggleShowProperties = (nodeId) => {};

  selectForFetch = (nodeId) => {
    if (isNaN(nodeId)) return;
    const fetchingForCompanies = [...this.state.fetchingForCompanies];
    let fetchNodeIndex = fetchingForCompanies.findIndex((item) => item === nodeId);
    if (fetchNodeIndex === -1) {
      fetchingForCompanies.push(nodeId);
    } else {
      fetchingForCompanies.splice(fetchNodeIndex, 1);
    }

    this.setState({
      fetchingForCompanies,
    });
  };

  shouldComponentUpdate() {
    // Do not update while we are fetching (and preventUpdate is set)
    return !this.preventUpdate;
  }

  updateChartCondition = () => {
    // Do not update while we are fetching (and preventUpdate is set)
    return !this.preventUpdate;
  };
  fetchIsComplete = (nodeId) => {
    this.completedFetchNodes++;
    console.log('Compl fetch / fcLen', this.completedFetchNodes, this.state.fetchingForCompanies.length);
    if (this.completedFetchNodes >= this.state.fetchingForCompanies.length) {
      this.completedFetchNodes = 0;
      this.preventUpdate = false;
      console.log('Fetch is complete...');
      this.setState({ fetchingForCompanies: [] });
    }
  };

  fetchPropInfo = (propertyIds, nodeName) => {
    console.log('Fetchi..', nodeName, propertyIds);
    api
      .post('/assets/sweden/propertiesinfo', {
        propertyIds,
        projectId: 99,
      })
      .then((result) => {
        if (!result || !result.data || !result.data.data) {
          console.log('Error result for prop info', result);
          return;
        }
        console.log('fetch res', nodeName, result.data);
        for (var i in result.data.data) {
          const prop = result.data.data[i];
          this.props.newCard(
            'property',
            Object.assign(
              {
                name: '',
                municipal: '',
                owner: nodeName,
                existingMN: '',
                newMN: '',
                ALA: '',
              },
              prop,
              {
                existingMN:
                  (prop.info &&
                    prop.info.totalMortagesAmounts &&
                    prop.info.totalMortagesAmounts[
                      Object.keys(prop.info.totalMortagesAmounts).find(
                        (currency) => prop.info.totalMortagesAmounts[currency] > 0
                      )
                    ]) ||
                  0,
              }
            )
          );
        }

        this.fetchIsComplete();
      })
      .catch((error) => {
        console.log('Error in fetch prop info', error.response);
      });
  };

  selectAllFetch = () => {
    if (this.state.fetchingForCompanies.length === this.props.legalPersons.length - 1) {
      this.setState({ fetchingForCompanies: [] });
      return;
    }
    const allComps = this.props.legalPersons
      .filter((legalPerson) => legalPerson && legalPerson.id && legalPerson.parentId)
      .map((legalPerson) => legalPerson.id);
    this.setState({ fetchingForCompanies: allComps });
  };

  fetch = () => {
    this.preventUpdate = true;
    console.log('fetching..', this.state.fetchingForCompanies);
    if (!Array.isArray(this.state.fetchingForCompanies) || this.state.fetchingForCompanies.length === 0)
      return;
    for (const nodeId of this.state.fetchingForCompanies) {
      const node = this.props.legalPersons.find((item) => item.id === nodeId);
      console.log('Fetching for ', nodeId, node);
      let nodeName = node.name;

      api
        .post('/assets/sweden/companyproperties', {
          regno: node.regno,
          projectId: 99,
        })
        .then((result) => {
          if (!result || !result.data || !result.data.data) {
            console.log('Errornous data returned...', result);
            return;
          }

          this.fetchPropInfo(result.data.data.ids, nodeName);
        })
        .catch((err) => {
          console.log('Error in fetch properties for ' + nodeName + ' (' + node.regno + ')', err);
        });
    }
    return;
  };

  editNodeAction = (id) => {
    if (!this.props.input || !this.props.input.property) return;

    const propertyValues = ofindValues(
      this.props.input.property,
      (property) => JSON.stringify(property) === id || property._tmpId === id
    );

    if (propertyValues) {
      const propertyData = JSON.parse(JSON.stringify(propertyValues));
      console.log('Found node', propertyData);
      this.setState({
        editingNode: propertyValues,
        editingIndex: propertyValues._uid,
        pendingNodeChanges: propertyData,
      });
    } else {
      console.log('Could not find node', id, this.props.input.property);
    }
  };
  onEditChangeNode = (name, evt) => {
    const value = evt.target.value;
    if (typeof value !== 'string' && typeof value !== 'boolean' && typeof value !== 'number') {
      return console.log('Can only update strings, numbers and booleans for nodes at the moment');
    }
    const updatedPendingNodeChanges = {
      ...this.state.pendingNodeChanges,
      [name]: evt.target.value,
    };
    this.setState({ pendingNodeChanges: updatedPendingNodeChanges });
  };
  saveNodeChange = () => {
    this.props.updateInput('input.property.' + this.state.editingIndex, this.state.pendingNodeChanges);
    // this.props.setInputData("property", this.state.editingIndex, this.state.pendingNodeChanges);
    this.setState({ editingNode: undefined, editingIndex: undefined, pendingNodeChanges: {} });
  };
  renderEditingNode = () => {
    return (
      <div className={'editnode ' + (this.state.editingNode ? ' editnode-show' : '')}>
        <div className="p-3" style={{ overflow: 'visible', width: '250px', minWidth: '250px' }}>
          <div className="d-flex no-block">
            <div className="card-title">Edit property</div>
            <div className="ml-auto">
              <div onClick={() => this.setState({ editingNode: undefined })}>
                <i className="mdi mdi-close-circle-outline"></i>
              </div>
            </div>
          </div>
          <div className="pb-25">
            <Label htmlFor="node_edit_name">Name</Label>
            <Input
              type="text"
              id="node_edit_name"
              ref={this.editNameRef}
              name="node_edit_name"
              onChange={(evt) => this.onEditChangeNode('property/name', evt)}
              value={this.state.pendingNodeChanges['property/name'] || ''}
            />
          </div>
          <div className="pb-25">
            <Label htmlFor="node_edit_parent" className="mt-2">
              Owner
            </Label>
            <Select
              onChange={(value) => {
                this.onEditChangeNode('property/owner', { target: { value } });
              }}
              value={this.state.pendingNodeChanges['property/owner'] || ''}
            >
              {this.state.editingNode &&
                this.props.legalPersons.map((node) => (
                  <Select.Option key={'rbtn-option-' + node.id} value={node.name}>
                    {node.name}
                  </Select.Option>
                ))}
            </Select>
            {/* <Input type="select" id="node_edit_parent" name="node_edit_parent" onChange={(evt) => this.onEditChangeNode('owner', evt)} value={this.state.pendingNodeChanges.owner || ''}>
				{
				  this.state.editingNode && this.props.nodes.map(node => (
					<option key={node.id} value={node.name}>{node.name}</option>
				  ))
				}
			  </Input> */}
          </div>
          <div className="pb-25">
            <Label htmlFor="node_edit_regno" className="mt-2">
              Municipal
            </Label>
            <Input
              type="text"
              id="node_edit_regno"
              name="node_edit_regno"
              onChange={(evt) => this.onEditChangeNode('property/municipal', evt)}
              value={this.state.pendingNodeChanges['property/municipal'] || ''}
            />
          </div>
          <div className="pb-25">
            <Label htmlFor="node_edit_corpform" className="mt-2">
              Ex. mortgages
            </Label>
            <Input
              type="text"
              id="node_edit_corpform"
              name="node_edit_corpform"
              onChange={(evt) => this.onEditChangeNode('property/existingMN', evt)}
              value={this.state.pendingNodeChanges['property/existingMN'] || ''}
            />
          </div>
          <div className="pb-25">
            <Label htmlFor="node_edit_country" className="mt-2">
              New mortgages
            </Label>
            <Input
              type="text"
              id="node_edit_country"
              name="node_edit_country"
              onChange={(evt) => this.onEditChangeNode('property/newMN', evt)}
              value={this.state.pendingNodeChanges['property/newMN'] || ''}
            />
          </div>
          <div className="">
            <Label htmlFor="node_edit_target" className="mt-2">
              ALA
            </Label>
            <Input
              type="text"
              id="node_edit_ala"
              name="node_edit_ala"
              onChange={(evt) => this.onEditChangeNode('property/ALA', evt)}
              value={this.state.pendingNodeChanges['property/ALA'] || ''}
            />
          </div>
          <div className="mt-4">
            <Button color="danger" onClick={() => this.setState({ editingNode: undefined })}>
              Cancel
            </Button>
            <Button className="float-right" color="green" onClick={this.saveNodeChange}>
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let meta;
    if (this.props.input.property && ocount(this.props.input.property) > 0) {
      let newMeta = this.state.metaData.map((node, index) => {
        let numProps = Object.keys(this.props.input.property).reduce((acc, uid) => {
          const item = this.props.input.property[uid];
          if (item.propertyOwner.id === node.id) acc++;
          return acc;
        }, 0);

        return {
          ...node,
          metaData: { ...node.metaData, numProps: numProps },
        };
      });
      meta = newMeta;
    } else {
      meta = this.state.metaData.map((node) => ({
        ...node,
        metaData: { ...node.metaData, numProps: 0 },
      }));
    }
    meta = meta.map((node, index) => {
      if (this.state.fetchingForCompanies.includes(node.id))
        return {
          ...node,
          metaData: { ...node.metaData, classNames: ['node-fetch-props'] },
        };
      return node;
    });

    return (
      <React.Fragment>
        {this.renderEditingNode()}
        <CardBody className="p-0">
          {this.props.legalPersons && this.props.legalPersons.length > 0 ? (
            <React.Fragment>
              <Row className="mx-0 mt-0 mb-2">
                <Col span={24}>
                  {/* <span>
                    Fastigheter samt detaljerad fastighetsinformation kan även läggas till i nästa steg (
                    <i>Förteckning</i>)
                  </span> */}
                  <Alert
                    message={
                      <span>
                        Fastigheter samt detaljerad fastighetsinformation kan även läggas till i föregående
                        steg (<i>Förteckning</i>)
                      </span>
                    }
                    type="info"
                  />
                </Col>
              </Row>

              <Row className="mx-0 mt-3">
                <Col span={20} className={'p-0'}>
                  <Chart
                    legalPersons={this.props.legalPersons}
                    layout={layout}
                    metaData={meta}
                    clickNodeEvent={this.clickNodeEvent}
                    updateChartCondition={this.updateChartCondition}
                    topRightAction={'none'}
                    topRightIcon={'none'}
                    // topLeftAction={this.selectForFetch}
                    topLeftAction="none"
                    // topLeftIcon={topLeftIcon}
                    topLeftIcon="none"
                    bottomRightAction={this.addToCompany}
                    bottomRightIcon={bottomRightIcon}
                    bottomLeftAction="none"
                    bottomLeftIcon="none"
                  />
                </Col>
                <Col span={4} style={{ maxHeight: '60vh', marginTop: '-13px', paddingRight: '0' }}>
                  {this.renderSideProperties()}
                </Col>
              </Row>
            </React.Fragment>
          ) : null}
        </CardBody>
      </React.Fragment>
    );
  }

  renderSideProperties() {
    if (this.state.selectedNode) {
      return (
        <React.Fragment>
          <h6>
            {this.state.selectedNode}{' '}
            <i
              onClick={() => {
                this.setState({ selectedNode: null });
              }}
              className="mdi mdi-close-circle float-right"
            ></i>
          </h6>
          <CustomScrollbars>
            {this.props.input && this.props.input.property && ocount(this.props.input.property) > 0 ? (
              omap(
                ofilter(this.props.input.property, (prop) => prop.owner === this.state.selectedNode),
                (node) => (
                  <Card key={node.name}>
                    <CardBody onClick={() => this.editNodeAction(JSON.stringify(node))}>
                      <b>{node.name}</b>
                      <br />
                      {node.owner}
                    </CardBody>
                  </Card>
                )
              )
            ) : (
              <span>No properties added yet</span>
            )}
          </CustomScrollbars>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h6 className="border-bottom">All Properties</h6>
        <CustomScrollbars>
          {this.props.input && this.props.input.property && ocount(this.props.input.property) > 0 ? (
            omap(this.props.input.property, (property) => {
              return (
                <Card key={property['property/name']} className="list-properties">
                  <CardBody onClick={() => this.editNodeAction(JSON.stringify(property))}>
                    <b>{property['property/name']}</b>
                    <br />
                    {property['property/owner']}
                  </CardBody>
                </Card>
              );
            })
          ) : (
            <span>No properties added yet</span>
          )}
        </CustomScrollbars>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgChart);
