/******* HELPER FUNCTIONS ******/
import { splitNumber, combineNumber } from 'core/utils/general';

export function getValue(props = {}, defaultValue) {
  if (props.field && props.value && props.language && props.field.format === 'amount')
    return splitNumber(combineNumber(props.value, props.language), props.language);
  else if (
    props.value &&
    props.field &&
    (props.field.type === 'numeric' || props.field.type === 'numeric-steps') &&
    typeof props.value.toString === 'function'
  )
    return props.value.toString();

  return props.value || defaultValue;
}
