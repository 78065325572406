import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useContract, setContract } from 'hooks';
import { updateInput } from 'appRedux/actions';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Button, Form, Input, Select, Checkbox, Col, Tooltip } from 'antd';
import InputSelect from './InputTypes/Select';
import InputCheckbox from './InputTypes/Checkbox';
import InputTextbox from './InputTypes/Text';
import InputNumeric from './InputTypes/Numeric';
import { getKeyByValue, generateUID } from 'core/utils/general';
const { Option } = Select;

export default function AddEditInput({ selected, onFinish }) {
  const contract = useContract();
  const dispatch = useDispatch();
  const formatMessage = useIntlMessage();

  const currentInput = selected.input ? contract.data.ui.inputs[selected.input] : null;
  const currentInputId = currentInput && selected.input;
  const contractLanguauge = contract.data.settings.language;

  const colWidths = {
    25: 6,
    33: 8,
    50: 12,
    66: 16,
    75: 18,
    100: 24,
  };

  const getColValueByWidth = () => {
    if (!currentInput?.cols?.lg) return '100';
    const width = getKeyByValue(colWidths, parseInt(currentInput.cols.lg, 10) * 2);
    return width ? width.toString() : '100';
  };

  const [inputType, setInputType] = useState(currentInput ? currentInput.type : '-1'); // Ugly to use -1, what else? 'choose' ?
  const [inputId, setInputId] = useState(currentInput ? currentInputId : '');
  const [inputIdError, setInputIdError] = useState(null);
  const [hasInputInfo, setHasInputInfo] = useState(
    currentInput ? currentInput.info[contractLanguauge] : false
  );
  const [hasInputHelper, setHasInputHelper] = useState(
    currentInput ? currentInput.helper[contractLanguauge] : false
  );
  const [inputInfo, setInputInfo] = useState('');
  const [inputHelper, setInputHelper] = useState('');
  const [inputLabel, setInputLabel] = useState(currentInput ? currentInput.label[contractLanguauge] : '');
  const [inputWidth, setInputWidth] = useState(getColValueByWidth());
  const [inputLabelError, setInputLabelError] = useState(null);
  const [childInvalidError, setChildInvalidError] = useState(null);
  const [childData, setChildData] = useState(null);
  const [useCustomId, setUseCustomId] = useState(false);

  const currentCard = contract.data.ui.cards[selected.card];
  let defaultValue = '';

  const handleInputIdChange = (value) => {
    setInputId(value);
    if (contract.data.ui.inputs[value]) {
      setInputIdError(formatMessage('studio.template.inputs.errors.idExists') + value);
    } else if (inputIdError) {
      setInputIdError('');
    }
  };

  const generateInputId = (label) => {
    while (true) {
      const id = `${currentCard.id}/${label
        .replace(' ', '_')
        .replace(/[^a-zA-Z_]/g, '')
        .toLowerCase()
        .slice(0, 6)}_${generateUID(4)}`;
      if (!contract.data.ui.inputs[id]) {
        return id;
      }
    }
  };

  const handleInputLabelChange = (evt) => {
    const { value } = evt.target;
    setInputLabel(value);
    if (!currentInput && !useCustomId) {
      handleInputIdChange(generateInputId(value));
    }
  };

  useEffect(() => {
    if (!currentInput && !useCustomId) {
      handleInputIdChange(generateInputId(inputLabel || 'input'));
    }
  }, []); // Medvetet utelämnat dependencies?

  if (!currentCard) return 'Error';

  const handleTypeChange = (value) => {
    setInputType(value);
    setChildInvalidError(null);
  };

  const handleInfoToggle = () => {
    setHasInputInfo(!hasInputInfo);
  };
  const handleHelperToggle = () => {
    setHasInputHelper(!hasInputHelper);
  };

  const handleInfoChange = (evt) => {
    setInputInfo(evt.target.value);
  };
  const handleHelperChange = (evt) => {
    setInputHelper(evt.target.value);
  };

  const onChildUpdate = (data) => {
    setChildData(data);
    setChildInvalidError(null);
  };

  // We go through the data from the child and validate it and set extra properties
  const getChildData = () => {
    if (['select', 'radio'].includes(inputType)) {
      if (!childData) return setChildInvalidError('Unexpected error');
      const { options, defaultValue } = childData;

      if (Object.keys(options).length === 0)
        return setChildInvalidError(formatMessage('studio.template.inputs.errors.selectOptionMissing'));
      // TODO: Should this be in the child component?
      const obj = {
        content: Object.entries(options).map(([id, { label, value }]) => {
          const tmp = {
            id,
            label: {
              [contractLanguauge]: label,
            },
          };
          if (value) {
            tmp.values = { [contractLanguauge]: value };
          }
          return tmp;
        }),
      };
      if (defaultValue) obj.value = defaultValue;

      return obj;
    } else if (['text', 'checkbox', 'numeric', 'numeric-steps'].includes(inputType)) {
      return {
        value: childData && childData.defaultValue,
      };
    }
    return {};
  };

  const addInput = () => {
    if (!inputId) return setInputIdError(formatMessage('studio.template.inputs.errors.idMissing'));
    if (!inputLabel) return setInputLabelError(formatMessage('studio.template.inputs.errors.labelMissing'));
    if (!currentCard) return setInputIdError(formatMessage('studio.template.inputs.errors.cardMissing'));

    // Treat the data depending on the child
    const extraProps = getChildData();

    // We remove the cardid when we set the name
    const name = inputId.replace(`${currentCard.id}/`, '');
    const fullPath = inputId;

    // Two different width systems. Have to divide with 2 for the contract data.
    const width = (colWidths[inputWidth] / 2).toString();
    const newInput = {
      inputs_order: [],
      type: inputType,
      label: {
        // en: inputLabel, // To be commented out?
        [contractLanguauge]: inputLabel,
      },
      name: name,
      cols: { lg: width, md: width, sm: width },
      ...extraProps,
    };

    if (hasInputInfo && inputInfo) {
      newInput.info = {
        [contractLanguauge]: inputInfo,
      };
    }

    if (hasInputHelper && inputHelper) {
      newInput.helper = {
        [contractLanguauge]: inputHelper,
      };
    }

    if (extraProps.value) defaultValue = extraProps.value;

    currentCard.values[fullPath] = defaultValue;
    // Only push to card if its a new input
    if (!currentInput) {
      currentCard.inputs_order.push(fullPath);
    }

    contract.data.ui.inputs[fullPath] = newInput;

    setContract({
      ...contract,
    });
    setInputId('');
    setInputLabel('');
    setInputIdError(null);
    setInputType('-1');
    setHasInputInfo(false);
    setHasInputHelper(false);
    setInputInfo('');
    setInputHelper('');
    setChildData(null);
    setChildInvalidError(null);

    // Add/update the input in the state
    let statePath = `input.${currentCard.id}`;
    if (currentCard.isRepeatable) {
      // Get the path to the card in the state input. Do this for the first occurence only?
      const cardStateKeys = Object.keys(contract.data.input[currentCard.id]);
      if (cardStateKeys.length > 0) {
        statePath = `${statePath}.${cardStateKeys[0]}`;
      } else {
        console.log('No input in state found for repeatable');
        statePath = null;
      }
    }

    if (statePath) {
      if (inputType === 'QA') {
        console.log('Add state for QA');
        dispatch(updateInput(`${statePath}.${inputId}`, {}));
      } else dispatch(updateInput(`${statePath}.${inputId}`, defaultValue));
    }

    onFinish();
  };

  const renderWidthOptions = () => {
    return Object.keys(colWidths).map((val) => {
      return <Option key={val} value={val}>{`${val} %`}</Option>;
    });
  };

  const getColProps = () => {
    const width = colWidths[inputWidth];
    return {
      xl: width,
      lg: width,
      md: width,
      sm: width,
      xs: width,
    };
  };

  const inputTypes = [
    'text',
    'party',
    'select',
    'checkbox',
    'radio',
    'numeric',
    'numeric-steps',
    'date',
    'QA',
  ];

  return (
    <div>
      <Form layout="vertical">
        <Form.Item
          label={<IntlMessages id="studio.template.inputs.label.label" />}
          extra={
            <div>
              <small>
                <IntlMessages id="studio.template.inputs.label.info" />
              </small>
              {inputLabelError && <div className="text-warning">{inputLabelError}</div>}
            </div>
          }
        >
          <Input
            type="text"
            value={inputLabel}
            onChange={handleInputLabelChange}
            placeholder={formatMessage('studio.template.inputs.label.placeholder')}
          />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="studio.template.inputs.type.label" />}
          extra={
            <div>
              <small>
                <IntlMessages id="studio.template.inputs.type.info" />
              </small>
            </div>
          }
        >
          <Select onChange={handleTypeChange} className="w-100" value={inputType}>
            <Option value="-1">{`-- ${formatMessage('studio.template.inputs.types.pickType')} --`}</Option>
            {inputTypes.map((inputType) => (
              <Option key={inputType} value={inputType}>
                <IntlMessages id={`studio.template.inputs.types.${inputType}`} />
              </Option>
            ))}
          </Select>
        </Form.Item>

        {inputType !== '-1' && (
          <>
            {/* <h4>
              <IntlMessages id="studio.template.inputs.additionalProps" />
            </h4> */}
            {['select', 'radio'].includes(inputType) && (
              <InputSelect
                onChildUpdate={onChildUpdate}
                currentInput={currentInput}
                contractLanguauge={contractLanguauge}
              />
            )}
            {inputType === 'checkbox' && (
              <InputCheckbox onChildUpdate={onChildUpdate} currentInput={currentInput} />
            )}
            {['text'].includes(inputType) && (
              <InputTextbox onChildUpdate={onChildUpdate} currentInput={currentInput} />
            )}
            {['numeric', 'numeric-steps'].includes(inputType) && (
              <InputNumeric onChildUpdate={onChildUpdate} currentInput={currentInput} />
            )}
          </>
        )}

        <Input.Group compact>
          <Form.Item
            label={<IntlMessages id="studio.template.inputs.hasInfo.label" />}
            style={{ width: '35%' }}
            extra={
              <div>
                <small>
                  <IntlMessages id="studio.template.inputs.hasInfo.info" />
                </small>
              </div>
            }
          >
            <Checkbox checked={hasInputInfo} onChange={handleInfoToggle} />
          </Form.Item>

          {hasInputInfo && (
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.infoText.label" />}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.infoText.info" />
                  </small>
                </div>
              }
            >
              <Input
                type="text"
                value={inputInfo}
                onChange={handleInfoChange}
                placeholder={formatMessage('studio.template.inputs.infoText.placeholder')}
              />
            </Form.Item>
          )}
        </Input.Group>
        <Input.Group compact>
          <Form.Item
            label={
              <>
                <IntlMessages id="studio.template.inputs.hasHelper.label" />
                <Tooltip title={<IntlMessages id="studio.template.inputs.hasHelper.info" />}>
                  <i className="ml-1 mdi mdi-help-circle" />
                </Tooltip>
              </>
            }
            style={{ width: '35%' }}
          >
            <Checkbox checked={hasInputHelper} onChange={handleHelperToggle} />
          </Form.Item>

          {hasInputHelper && (
            <Form.Item
              label={
                <>
                  <IntlMessages id="studio.template.inputs.helperText.label" />
                </>
              }
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.helperText.info" />
                  </small>
                </div>
              }
            >
              <Input
                type="text"
                value={inputHelper}
                onChange={handleHelperChange}
                placeholder={formatMessage('studio.template.inputs.helperText.placeholder')}
              />
            </Form.Item>
          )}
        </Input.Group>

        <Form.Item
          label={<IntlMessages id="studio.template.inputs.size.label" />}
          extra={
            <div>
              <small>
                <IntlMessages id="studio.template.inputs.size.info" />
              </small>
            </div>
          }
        >
          <Col {...getColProps()} className="audi-col" style={{ padding: 0 }}>
            <Select onChange={(value) => setInputWidth(value)} value={inputWidth} placeholder="">
              {renderWidthOptions()}
            </Select>
          </Col>
        </Form.Item>
        <Input.Group compact>
          {!currentInput && (
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.customId.label" />}
              style={{ width: '35%' }}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.customId.info" />
                  </small>
                </div>
              }
            >
              <Checkbox checked={useCustomId} onChange={() => setUseCustomId(!useCustomId)} />
            </Form.Item>
          )}
          <Form.Item
            label={<IntlMessages id="studio.template.inputs.id.label" />}
            style={{ width: '55%' }}
            className="ml-2"
            extra={
              <div>
                <small>
                  <IntlMessages id="studio.template.inputs.id.info" />
                </small>
                {inputIdError && <div className="text-warning">{inputIdError}</div>}
              </div>
            }
          >
            <Input
              type="text"
              value={inputId}
              onChange={(evt) => handleInputIdChange(evt.target.value)}
              disabled={!!currentInput || !useCustomId}
              placeholder={formatMessage('studio.template.inputs.id.placeholder')}
            />
          </Form.Item>
        </Input.Group>
      </Form>

      <Button
        type="primary"
        block
        onClick={addInput}
        disabled={
          inputType === '-1' || inputIdError || childInvalidError || inputId === '' || inputLabel === ''
        }
      >
        {currentInput
          ? formatMessage('studio.template.inputs.updateButton')
          : formatMessage('studio.template.inputs.addButton')}
      </Button>
      {childInvalidError && <div className="text-warning">{childInvalidError}</div>}
    </div>
  );
}
