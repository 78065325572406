import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fixInput, resetDraft } from 'appRedux/actions';
import manager from 'core/engine/manager';
import { clearUiModules } from 'appRedux/inputModule/';

import { Layout } from 'antd';
import { debounce } from 'lodash';
import ErrorBoundry from 'containers/Error/index';
import Sidebar from '../Sidebar/index';
import SidebarContent from '../Sidebar/SidebarContent';
import { footerText } from 'util/config';
import App from 'routes/index';
import { COLLAPSE_SIZE } from 'config';
import { useRouteMatch } from 'react-router-dom';

const { Content, Footer } = Layout;

const MainApp = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  // When MainApp is mounted, that means that
  // the Studio is not mounted (see in containers/App/index.js)
  // Then. Ensure that no studio drafting values are set.
  useEffect(() => {
    manager.clearDrafts();
    clearUiModules();
    // Clear input
    dispatch(fixInput({}));
    // Clear draft
    dispatch(resetDraft({}));
  }, [dispatch]);

  return (
    <Layout className="app-layout">
      <Sidebar>
        <SidebarContent />
      </Sidebar>
      <Layout>
        <DrawerHamburger />
        <Content className={`layout-content`}>
          <ErrorBoundry>
            <App match={match} />
          </ErrorBoundry>
          {/* <Footer>
            <div className="layout-footer-content">{footerText}</div>
          </Footer> */}
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainApp;

export function DrawerHamburger({ className = '', style = {} }) {
  const [width, setWidth] = useState(window.innerWidth);
  const widthRef = useRef(window.innerWidth);

  const narrowView = width < COLLAPSE_SIZE;

  const updateWindowW = useCallback(
    debounce(() => {
      const windowInnerWidth = window.innerWidth;
      if (windowInnerWidth === widthRef.current) return;
      widthRef.current = windowInnerWidth;
      setWidth(windowInnerWidth);
    }, 500),
    []
  );

  const toggleDrawer = () => {
    window.dispatchEvent(new CustomEvent('toggle_drawer', { detail: {} }));
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowW);
    return () => {
      window.removeEventListener('resize', updateWindowW);
    };
  }, [updateWindowW]);

  if (!narrowView) return null;

  return (
    <i
      className={`icon-btn icon icon-menu fs-xxl drawer-hamburger ${className}`}
      // style={{ top: '4px', ...style, position: 'absolute', zIndex: 15 }}
      onClick={toggleDrawer}
    />
  );
}
