import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { useStudioBaseRoute } from "hooks";

function GotoProvision(props) {
  const { field, language } = props;
  const history = useHistory();
  const baseRoute = useStudioBaseRoute();

  return (
    <Button
      type="primary"
      onClick={() => {
        if (!field?.target?.endpoint || !baseRoute) return;
        const { endpoint } = field.target;
        const redirect = baseRoute + "/input/" + endpoint;

        history.push(redirect);
      }}
    >
      {(field.label && field.label[language]) || "Go to input section"}
    </Button>
  );
}

export default GotoProvision;
