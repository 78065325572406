import { Contract } from 'core/interfaces';
import uuid from 'uuid-random';

export const generateTemplateInputState = (template) => {
  const { input } = template.data;

  const newInputState = JSON.parse(JSON.stringify(input));

  for (const cardId in newInputState) {
    const isRepeatable = Contract.getUiIsCardRepeatable(template, cardId);
    if (!isRepeatable) continue;

    const repeatableState = newInputState[cardId];
    const repeatableStateUids = Object.keys(repeatableState);

    const stateLength = repeatableStateUids.length;
    if (stateLength === 1) continue;
    if (stateLength > 1) {
      for (let i = 1; i < stateLength; i++) {
        delete repeatableState[repeatableStateUids[i]];
      }
      continue;
    }

    const id = uuid();
    newInputState[cardId] = {
      [id]: {
        ...JSON.parse(JSON.stringify(template.data.ui.cards[cardId].values)),
        _meta: {
          added: Date.now(),
          isDefault: true
        },
        _uid: id,
      },
    };
  }

  return newInputState;
};
