export const ordinaryUpdateHandler = {
  id: 'ordinaryUpdate',
  dependencies: { ordinary: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    const adjustedValues = {};
    for (const entry of entries) {
      let { path, value } = entry;
      // Special treatment if the inputField behind the update
      // is of type `multipleText` as that will only give the
      // updated index, whereas we need the entiry array value
      const inputField = api.interfaces.Contract.getUiInputFieldDataByPath(this.contract, path);
      if (inputField) {
        if (inputField.type === 'multipleText') {
          path = api.interfaces.InputPaths.removeLast(path);
          value = api.utils.general.getByPath(this.states.current, path);
        }
      }

      // If the current entry is referring to an entity, the state looks something like:
      // { id: 'uuid', type: 'legalPerson' }
      // and in such case typically the `id` would get updated. Check for this.
      const valueEntity = api.interfaces.Entity.getById(state, value);
      if (api.interfaces.InputPaths.getLast(path) === 'id' && valueEntity) {
        path = api.interfaces.InputPaths.removeLast(path);
        adjustedValues[path] = { isEntity: true, value: valueEntity };
      } else {
        adjustedValues[path] = this.getGeneralValues(path, value);
      }
    }
    // console.log('Adjusted value ', adjustedValues)
    // Flow function.
    return (node, parents) => {
      if (node.variant !== 'vari') return;

      let itemPath = node.data.name;

      if (itemPath.substr(0, 6) !== 'input.') itemPath = 'input.' + itemPath;

      // const entry = entries.find(e => e.path === itemPath)
      if (!adjustedValues[itemPath]) return;

      let value;

      if (adjustedValues[itemPath].isEntity) {
        let entityKey = node.data.entityKey || 'name';
        if (entityKey === 'name') {
          // an entity could have the property `name` (if legalPerson)
          // or `firstName` and `lastName` (if realPerson)
          value = api.utils.general.userToName(adjustedValues[itemPath].value);
        } else {
          value = adjustedValues[itemPath].value[entityKey];
        }
      } else {
        const nodeValues = this.getNodeValues(node, adjustedValues[itemPath]);
        value = this.getFinalValue(nodeValues);
      }

      const originalValue = adjustedValues[itemPath].originalValue
      // console.log('Update ', { itemPath, value })
      node.data.value = originalValue;
      node.data.text = value;
      if (value === undefined) {
        return;
      }

      api.utils.engine.setFirstChild(node, value);
      this.markNodeUpdate(node);
    };
  },
};
