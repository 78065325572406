
export const activeConditionPreparation = {
  id: "activeConditionPreparation",
  dependencies: {
    repeatableAdd: true,
    repeatableRemove: true,
    repeatableChange: true,
    ordinary: true,
    legalPersonAny: true,
  },
  time: -10,
  handler: function ({ state, handlerInvoked, entries, paths }) {
    // Do not run this if the engine is not loaded.
    const draftInfo = this.getDraftInfo();
    if (!draftInfo.status.loaded) return;

    if (typeof window !== 'undefined' && window.debug)
      this.log('State prep acp ', {handlerInvoked, entries, paths})

    let keys = []
    // let keys = this.getAffectedACPkeys(handlerInvoked, entries)


    // Update all shortcuts considering the keys setting out changed data.
    // We receive the relevant shortcuts which were updated.
    const updatedShortcutKeys = this.applyShortcutRules(keys, state);

    // Add the shortcut keys to all keys
    keys = keys.concat(updatedShortcutKeys);

    // this.log('keys', keys, paths)

    // Set key generated condition keys to the temporary draft object.
    // To be used by other activeCondition handlers (as well as enum's, and others)
    this._tmpDraft.updatedACPkeys = Array.isArray(this._tmpDraft.updatedACPkeys) ? this._tmpDraft.updatedACPkeys.concat(keys) : keys

  },
};



export const applyShortcutRules = function (keys, state, options = {}) {
  // this.log('Apply shortcut rules for ', keys)
  const repeatableActivations = this.api.interfaces.Contract.getRepeatableActivation(this.contract);
  if (!repeatableActivations || repeatableActivations.length === 0) return [];
  const updatedShortcuts = [];

  const { force = false } = options; // Force a check of all shortcut, regardless of keys.
  // Used during first initialization of a contract.

  const draftInfo = this.getDraftInfo();

  for (const { rule, activate, elseActivate } of repeatableActivations) {
    if (force || this.api.logic.matchRule(rule, keys)) {
      const passed = this.applyLogic(rule, { state });
      if (activate) {
        updatedShortcuts.push("$_shortcut_$" + activate);
        draftInfo.shortcutStates[activate] = passed;
      }
      if (elseActivate) {
        updatedShortcuts.push("$_shortcut_$" + elseActivate);
        draftInfo.shortcutStates[elseActivate] = !passed;
      }
    }
  }

  // Add any shortcut which has been set by any module
  if (Array.isArray(this._tmpDraft._recentUpdatedShortcuts)) {
    for (const shortcutName of this._tmpDraft._recentUpdatedShortcuts)
      updatedShortcuts.push("$_shortcut_$" + shortcutName);
  }

  // this.log('Updated Shortcuts ? ', updatedShortcuts)
  return updatedShortcuts;
};
