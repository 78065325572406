import findIndex from 'find-index/findIndex';

import {
  getConceptDescr,
  isParty,
  isPartyIndirectly,
  getFirstConcept,
  getExclusiveConcept,
} from 'core/utils/nodes';

import { ucfirst, ofindValues, ocount } from 'core/utils/general';
import { mapCompaniesToConcepts } from 'core/engine/utils';
import values from './values';

import { getContractValues } from 'hooks';
import { Contract, LegalPerson } from 'core/interfaces';

function makeAnalysis(data, state) {
  const contract = getContractValues();
  const build = Contract.getBuild(contract);
  const language = Contract.getLanguage(contract);

  const { input, legalPersons } = state;

  if (!build || !build.concepts) {
    return console.log('No build or build.concepts available for the current contract.', { contract, build });
  }
  const { concepts } = build;

  const { value: restrictedNodes } = data;

  const restricted = [...restrictedNodes].sort();
  let restrictedLength = restricted.length;

  // No restriction
  if (restrictedLength === 0) return { type: 'none' };

  // Find owner node id
  const ownerIndex = findIndex(legalPersons, (node) => node.parentId === null);
  if (ownerIndex === -1) {
    console.log('Could not find owner node.');
    return { type: 'none' };
  }
  const topCoNode = legalPersons.find((node) => node.parentId === legalPersons[ownerIndex].id);
  if (!topCoNode) {
    console.log('Could not top company node.');
    return { type: 'none' };
  }

  function mapCompanyName(name) {
    const mapped = mapCompaniesToConcepts({
      companies: [name],
      contract,
      state,
      /* concepts,
      state,
      language,
      languageAnd: translateText("and", language, true) || "and",
      languageOr: translateText("or", language, true) || "or", */
    });
    return (mapped && mapped.fullText_and) || name;
  }

  const personsWithoutOwner = legalPersons.filter((node) => node.parentId !== null);
  const topCoName = topCoNode.name;
  const nodesLength = personsWithoutOwner.length;
  const topCoDescr = mapCompanyName(topCoName);
  const isTopCoParty = isParty(topCoNode, contract, state);

  // console.log('restricted Len ', restrictedLength);

  // All are restricted
  if (restrictedLength === nodesLength) {
    // Only one node exists and is restricted
    if (restrictedLength === 1) {
      const singleNode = personsWithoutOwner.find((item) => item.id === restricted[0]);
      const nodeDescr = mapCompanyName(singleNode.name);
      return {
        type: 'single',
        info: 'one-only-party-only-one-restricted',
        target: 'single',
        obligee: nodeDescr,
      };
    }

    // TopCo is not a party, but all are restricted
    if (!isTopCoParty) {
      return {
        warnings: ['All are restricted but not parties, make at least ' + topCoName + ' a party'],
      };
    }

    // TopCo is a party and all are restricted
    let updTopCoDescr;
    let topCoBorrower = ofindValues(input.borrower, (item) => item.name === topCoDescr);
    if (topCoBorrower && ocount(input.borrower) > 1 && topCoBorrower.definition)
      updTopCoDescr = ucfirst(topCoBorrower.definition);
    return {
      type: 'all',
      info: 'all-restricted-all-ok',
      target: 'all',
      obligee: updTopCoDescr || topCoDescr,
    };
  }

  const obligees = {};
  const arrObligees = [];
  const warnings = [];

  // If there is a subGroup - check if it is targeted!
  if (state.input.subGroup && state.input.subGroup['subGroup/is_enabled'] === true && state.input.subGroup['subGroup/target']) {
    const subGroupParentPerson = legalPersons.find((person) => person.name === state.input.subGroup['subGroup/target']);
    console.log('Got subGroup ... ? ', subGroupParentPerson)
    if (subGroupParentPerson && subGroupParentPerson.id) {
      // console.log('sub group 2')
      const subChildren = LegalPerson.collectChildren(legalPersons, subGroupParentPerson.id, {mode: 'id'})
      
      const allChildrenRestricted = restricted.every(id => {
        return findIndex(subChildren, childId => childId === id) > -1
      })
      
      if (allChildrenRestricted && subChildren.length === restrictedLength) {
        const subGroupParentDescr = mapCompanyName(subGroupParentPerson.name);
        return {
          type: 'others',
          info: 'children-subGroup-restricted-all-ok',
          target: 'allSub',
          obligee: subGroupParentDescr,
        };
      } else {
        const subChildrenAndParent = [subGroupParentPerson.id, ...subChildren]
        const allSubGroupRestricted = restricted.every(id => {
          return findIndex(subChildrenAndParent, childId => childId === id) > -1
        })
        if (allSubGroupRestricted && subChildrenAndParent.length === restrictedLength) {
          const subGroupParentDescr = mapCompanyName(subGroupParentPerson.name);
          return {
            type: 'all',
            info: 'all-subGroup-restricted-all-ok',
            target: 'allSub',
            obligee: subGroupParentDescr,
          };
        }
      }
    }
  }

  // All except topCo are restricted
  if (restrictedLength === nodesLength - 1 && findIndex(restricted,(item) => item === topCoNode.id) === -1) {
    // TopCo does not seem to be a party, so check if others are directly or indirectly parties
    if (!isTopCoParty) {
      for (let node of personsWithoutOwner) {
        const closestParty = isPartyIndirectly(node, personsWithoutOwner, contract, state);
        if (!closestParty)
          warnings.push(
            node.name +
              ' nor any of its direct or indirect parent(s) is a contract party - suitable obligee not found.'
          );
        else {
          const closestPartyName = getConceptDescr(
            closestParty.firstConcept,
            contract,
            state,
            closestParty.node.name
          );
          if (arrObligees.indexOf(closestPartyName) === -1) arrObligees.push(closestPartyName);
        }
      }
      return {
        type: 'others',
        info: 'all-except-topco-topco-not-party',
        target: 'all',
        obligees: arrObligees,
        warnings,
      };
    }
    // TopCo is a party, not restricted, and all others are restricted
    else {
      let updTopCoDescr;
      let topCoBorrower = ofindValues(input.borrower, (item) => item.name === topCoDescr);
      if (topCoBorrower && ocount(input.borrower) > 1 && topCoBorrower.definition)
        updTopCoDescr = ucfirst(topCoBorrower.definition);
      return {
        type: 'others',
        info: 'all-except-topco',
        target: 'all',
        obligee: updTopCoDescr || topCoDescr,
        warnings,
      };
    }
  }

  // Only one company is restricted
  if (restrictedLength === 1) {
    const node = personsWithoutOwner.find((item) => item.id === restricted[0]);
    let nodeConcept = getExclusiveConcept(concepts, node, contract, state);
    let nodeName;
    // console.log('one only.');
    // Not exclusive - check if party and if so refer to it by name
    if (!nodeConcept) {
      nodeConcept = getFirstConcept(concepts, node, state);
      if (!nodeConcept) {
        if (isTopCoParty) {
          obligees[topCoDescr] = {
            self: topCoNode.id,
            govern: restricted,
          };
          return {
            type: 'others',
            target: 'others',
            info: 'one-only',
            obligees,
            warnings,
          };
        }
        return {
          info: 'one-only-warnings-only',
          warnings: [node.name + ' is not a party.'],
        };
      } else {
        nodeName = node.name;
      }
    } else nodeName = getConceptDescr(nodeConcept, contract, state, node.name);
    return {
      type: 'some',
      target: 'themselves',
      obligee: nodeName,
      info: 'one-only',
    };
  }

  // All restricted are parties themselves
  const parties = [],
    nodeConcepts = [],
    conceptCounter = {};
  for (let node of personsWithoutOwner) {
    const nodeConcept = getFirstConcept(concepts, node, state);
    if (nodeConcept && restricted.includes(node.id)) {
      console.log('+parties', node.id, restricted);
      parties.push(node.id);
      nodeConcepts.push(nodeConcept);
      if (conceptCounter[nodeConcept.id]) conceptCounter[nodeConcept.id]++;
      else conceptCounter[nodeConcept.id] = 1;
    }
  }
  if (parties.length === restrictedLength) {
    let conceptsCollection = [];
    console.log('cC', conceptCounter);
    for (let cc in conceptCounter) {
      const concept = concepts.find((item) => item.id === cc);
      conceptsCollection.push(getConceptDescr(concept, contract, state, 'x', conceptCounter[cc] > 1));
    }
    return {
      type: 'some',
      target: 'themselves',
      info: 'all-restricted-are-parties',
      obligees: conceptsCollection,
    };
  } else if (restrictedLength < parties.length) {
    let conceptsCollection = [];
    console.log('cC2', conceptCounter);
    for (let cc in conceptCounter) {
      const concept = concepts.find((item) => item.id === cc);
      conceptsCollection.push(getConceptDescr(concept, contract, state, 'x', conceptCounter[cc] > 1));
    }
    return {
      type: 'some',
      target: 'themselves',
      info: 'all parties are not restricted',
      obligees: conceptsCollection,
      warnings: ['Some companies are parties to the contract but not restricted'],
    };
  }

  // Some are restricted and topCo is party
  if (isTopCoParty) {
    obligees[topCoDescr] = { self: topCoNode.id, govern: restricted };
    return {
      type: 'others',
      target: 'others',
      info: 'some-restricted-topco-exists',
      obligees,
      warnings,
    };
  }

  // a mix of legalPersons are restricted
  else {
    for (let nodeId of restricted) {
      const node = personsWithoutOwner.find((item) => item.id === nodeId);
      const closestParty = isPartyIndirectly(node, personsWithoutOwner, contract, state);
      if (!closestParty)
        warnings.push(
          node.name +
            ' nor any of its direct or indirect parent(s) is a contract party - suitable obligee not found.'
        );
      else {
        const closestPartyName = getConceptDescr(
          closestParty.firstConcept,
          contract,
          state,
          closestParty.node.name
        );
        if (!obligees[closestPartyName])
          obligees[closestPartyName] = {
            self: closestParty.node.id,
            govern: [node.id],
          };
        else obligees[closestPartyName].govern.push(node.id);
      }
    }
    const returnArr = [];
    for (let obligee in obligees) {
      returnArr.push({
        type: obligees[obligee].govern.length > 1 ? 'all' : 'single',
        target: obligees[obligee].govern.length > 1 ? 'others' : 'themselves',
        obligees: { [obligee]: obligees[obligee] },
        info: 'custom',
        warnings,
      });
    }
    return returnArr;
  }
}

function undertakingText(analysis, settings, legalPersons, language) {
  let target = '';
  const provision = [];

  const templateTargetsData = settings?.provisionLayout?.targets;
  const templateTypes = settings?.provisionLayout?.types;

  if (analysis.target) {
    if (!templateTargetsData) {
      console.log('Cannot find templateTargetsData');
      return '[**]';
    }
    if (templateTargetsData[analysis.type + '_' + analysis.target])
      target = templateTargetsData[analysis.type + '_' + analysis.target];
    else if (templateTargetsData[analysis.target]) target = templateTargetsData[analysis.target];
  }

  if (analysis.obligee)
    provision.push(analysis.obligee.charAt(0).toLocaleUpperCase() + analysis.obligee.substr(1));
  else if (Array.isArray(analysis.obligees)) {
    for (const obligee of analysis.obligees)
      provision.push(obligee.charAt(0).toLocaleUpperCase() + obligee.substr(1));
  }

  // Custom.
  if (typeof analysis.obligees === 'object' && !Array.isArray(analysis.obligees)) {
    let provisionArr = handleObligees(analysis.obligees, legalPersons, templateTypes[analysis.type], target);
    provision.push(provisionArr.join(','));
  } else {
    if (analysis.type && templateTypes[analysis.type]) provision.push(templateTypes[analysis.type]);

    provision.push(target);
  }
  let string = provision
    .filter((item) => item)
    .join(' ')
    .trim();
  if (string === '') string = '[**]';

  return string;
}
/*
{
  type: 'all-subGroup',
  info: 'all-subGroup-restricted-all-ok',
  target: 'subGroup',
  obligee: subGroupParentDescr,
};
*/

function handleObligees(obligees, legalPersons, typeText = '-err-', targetText = '-err-') {
  /*
    console.log('obligees', obligees);
    console.log('typeText', typeText);
    console.log('targetText', targetText);
    */
  const provisionArr = [];
  for (let obligee in obligees) {
    if (Array.isArray(obligees[obligee].govern)) {
      const childNames = [];
      for (const child of obligees[obligee].govern) {
        const childNode = legalPersons.find((item) => item.id === child);
        if (childNode && childNode.id !== obligees[obligee].self) childNames.push(childNode.name);
      }
      provisionArr.push(obligee + ' ' + typeText + ' ' + childNames.join(',') + ' ' + targetText);
    }
  }
  return provisionArr;
}

function analyse(data, state) {
  const { input, legalPersons } = state;

  const contract = getContractValues();
  const language = Contract.getLanguage(contract);
  const analysis = makeAnalysis(data, state);
  const { fieldName: undertaking } = data;

  const settings = values[undertaking][language];

  console.log('Analysis is ', analysis);

  let provision = analysis
    ? Array.isArray(analysis)
      ? analysis.map((ana) => undertakingText(ana, settings, legalPersons, language)).join(' / ')
      : undertakingText(analysis, settings, legalPersons, language)
    : '';

  if (!provision) {
    if (!analysis.obligee && !analysis.obligees && !analysis.type) provision = '[**]';
    else {
      provision = '[**]';
    }
  }

  let obligationText = provision;
  console.log('ObligationText ', obligationText);

  if (settings.provisionText) provision += ' ' + settings.provisionText;

  if (settings.exceptText) provision += settings.exceptText;

  return {
    path: 'input.undertaking_' + undertaking,
    value: {
      ...analysis,
      provision,
      obligationText,
    },
  };
}

const inputs = [
  {
    trigger: '_undertakings/*',
    mode: 'single',
    function: analyse,
    info: {
      name: 'Analyses undertaking obligations',
      category: 'legal',
    },
  },
];

export default inputs;
