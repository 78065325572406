// import jsonLogic from "json-logic-js-enhanced";
import jsonLogic from 'json-logic-js';
import { Contract } from '../../interfaces';
import {
  varValues,
  setup,
  input,
  local,
  shortcut,
  numof_repeatable,
  count_repeatable,
  any_repeatable,
  all_repeatable,
  all_repeatables_same,
  any_item,
  all_items,
  numof_nodes,
  numof_legalPersons,
  numof_realPersons,
  objectValues,
  firstSet,
  firstSetItem,
  card,
  numof,
  numof_state,
  any_in_card,
  all_in_card,
  like,
  qAndA,
} from './jsonLogicOperations';

jsonLogic.add_operation('card', card);
jsonLogic.add_operation('numof', numof);
jsonLogic.add_operation('numof_state', numof_state);
jsonLogic.add_operation('any_in_card', any_in_card);
jsonLogic.add_operation('all_in_card', all_in_card);

jsonLogic.add_operation('varValues', varValues);
jsonLogic.add_operation('objVar', varValues);
jsonLogic.add_operation('setup', setup);
jsonLogic.add_operation('input', input);
jsonLogic.add_operation('local', local);
jsonLogic.add_operation('shortcut', shortcut);
// jsonLogic.add_operation('numof', numof_repeatable);
jsonLogic.add_operation('numof_repeatable', numof_repeatable);
jsonLogic.add_operation('count_repeatable', count_repeatable);
jsonLogic.add_operation('any_repeatable', any_repeatable);
jsonLogic.add_operation('all_repeatable', all_repeatable);
jsonLogic.add_operation('all_repeatables_same', all_repeatables_same);
jsonLogic.add_operation('any_item', any_item);
jsonLogic.add_operation('all_items', all_items);
// jsonLogic.add_operation('numof_nodes', numof_nodes);
jsonLogic.add_operation('numof_nodes', numof_legalPersons);
jsonLogic.add_operation('numof_legalPersons', numof_legalPersons);
jsonLogic.add_operation('numof_realPersons', numof_realPersons);
jsonLogic.add_operation('objectValues', objectValues);
jsonLogic.add_operation('firstSet', firstSet);
jsonLogic.add_operation('firstSetItem', firstSetItem);

jsonLogic.add_operation('like', like);
jsonLogic.add_operation('qAndA', qAndA);

const ruleOperators = [
  'setup',
  'input',
  'local',
  'shortcut',
  'numof_repeatable',
  'count_repeatable',
  'any_repeatable',
  'all_repeatable',
  'any_item',
  'all_items',
  'all_same',
  'numof_nodes',
  'numof',
  'numof_state'
];

export function getComponents(rule) {
  let operators = [];

  if (!rule) return;
  const ops = Object.keys(rule);
  if (ops.length !== 1) return; // Invalid json rule
  const operator = ops[0];
  if (ruleOperators.includes(operator)) {
    operators.push([operator, rule[operator]]);
  }

  if (Array.isArray(rule[operator])) {
    for (const ruleItem of rule[operator]) {
      operators = operators.concat(getComponents(ruleItem));
    }
  }
  return operators.filter((item) => !!item);
}

export function getConditionVariables(condition) {
  let variables = [];

  if (!condition) return;
  const ops = Object.keys(condition);
  if (ops.length !== 1) return; // Invalid json rule
  const operator = ops[0];

  if (operator === 'var') {
    variables.push(condition[operator])
  }

  if (Array.isArray(condition[operator])) {
    for (const conditionItem of condition[operator]) {
      variables = variables.concat(getConditionVariables(conditionItem));
    }
  }
  return variables.filter((item) => !!item);
}


var ruleTypeMatches = {
  numof_repeatable: (parameters, keys) => {
    let repeatable = Array.isArray(parameters) ? parameters[0] : parameters;
    return keys.includes(repeatable);
  },
  count_repeatable: (parameters, keys) => {
    const [repeatable, fieldName] = parameters;
    return keys.includes(repeatable + '.' + fieldName);
  },
  any_repeatable: (parameters, keys) => {
    const [repeatable, fieldName] = parameters;
    return keys.includes(repeatable + '.' + fieldName);
  },
  all_repeatable: (parameters, keys) => {
    const [repeatable, fieldName] = parameters;
    return keys.includes(repeatable + '.' + fieldName);
  },
  all_repeatables_same: (parameters, keys) => {
    const [repeatable, fieldName] = parameters;
    return keys.includes(repeatable + '.' + fieldName);
  },
  any_item: (parameters, keys) => {
    const [cardId] = parameters;
    return keys.includes(cardId);
  },
  all_items: (parameters, keys) => {
    const [cardId] = parameters;
    return keys.includes(cardId);
  },

  numof_nodes: (parameter, keys) => {
    return parameter === 'nodes' && keys.includes(parameter);
  },

  input: (parameter, keys) => {
    return keys.includes(parameter);
  },
  setup: (parameter, keys, options = {}) => {
    if (options.alwaysMatchSetup) {
      return true;
    }
    return keys.includes(parameter);
  },
  shortcut: (parameter, keys, options = {}) => {
    return keys.includes('$_shortcut_$' + parameter);
  },
  local: (parameter, keys) => {
    return keys.includes('$_local_$' + parameter); // to avoid uncessary matches.
  },
};

export function matchRule() {
  return true;
}
export function xmatchRule(rule, keys, options = {}) {
  if (!rule) return;
  const ops = Object.keys(rule);
  if (ops.length !== 1) return; // Invalid json rule
  const operator = ops[0];
  if (ruleOperators.includes(operator)) {
    // console.log('Shall check ', operator)
    return ruleTypeMatches[operator](rule[operator], keys, options);
  }

  if (Array.isArray(rule[operator])) {
    for (const ruleItem of rule[operator]) {
      const gotMatch = matchRule(ruleItem, keys, options);
      if (gotMatch) return true;
    }
  }
}
/* 
export default function applyJsonLogic(rule, data, options = {}) {
  const { _meta = {} } = options;
  if (!_meta.create) _meta.create = {};
  if (!_meta.contract) _meta.contract = {};

  if (Object.keys(_meta.contract).length === 0) {
    console.trace('NO contract for apply log?');
  }

  console.log('Apply json logic data ', {
    data1: JSON.parse(JSON.stringify(data)),
    data2: data
  })

  try {
    const result = jsonLogic.apply(rule, {
      ...data,
      _meta,
    });
    // console.log('Logic result ', result)
    return result;
  } catch (err) {
    console.log('JSON LOGIC BUG: ', { rule, data, _meta, err });
    return false;
  }
}
 */

const NEW_LOGIC = true;

export default function applyJsonLogic(rule, state, options = {}) {
  if (NEW_LOGIC && typeof rule === 'string') {
    return validateRule({
      id: rule,
      state,
      contract: options._meta.contract,
      options,
    });
  }

  const { _meta = {}, data = {} } = options;
  if (!_meta.create) _meta.create = {};
  if (!_meta.contract) _meta.contract = {};
  if (!_meta.data) _meta.data = data;

  if (Object.keys(_meta.contract).length === 0) {
    console.log('NO contract for apply log?', { options });
  }

  try {
    const result = jsonLogic.apply(rule, {
      ...state,
      _meta,
    });
    // console.log('Logic result ', result)
    return result;
  } catch (err) {
    console.log('JSON LOGIC BUG: ', { rule, data, _meta, err });
    /* console.log(err)
    console.trace('json logic bug trace') */
    return false;
  }
}

export function validateRule({ id, state, contract, options = {} }) {
  if (!id || !state || !contract) {
    console.warn('Expected arguments id, state and contract', { id, state, contract });
    return;
  }
  const rule = Contract.getRule(contract, id);
  if (!rule) {
    console.warn('No rule for rule with id ', id);
    return false;
  }

  if (rule.code) {
    // console.log('Try code ', {id, value: (rule.value || false)})
    return rule.value || false;
  }

  try {
    const result = jsonLogic.apply(rule.data, {
      ...state,
      _meta: {
        contract,
        data: options.data || {},
      },
    });
    return result;
  } catch (err) {
    console.log('LOGIC BUG: ', { ruleData: rule.data, state, contract, options, err });
    return;
  }
}
