import React, { useState } from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import Auxiliary from 'util/Auxiliary';
import { setProject, useAbility } from 'hooks';
import api from 'utils/api';
const { Paragraph } = Typography;

const AboutItem = ({ data }) => {
  const {
    title,
    icon,
    iconSize = 'xl',
    itemKey,
    desc,
    color = 'orange',
    resource,
    resourceRoute,
    updateProjectContext,
    linkTo,
  } = data;
  const [descValue, setDescValue] = useState(desc || '');

  const ability = useAbility();
  const mayEdit = ability.can('edit', 'Project');

  const onEditableChange = (value) => {
    if (!mayEdit) return;
    if (!value) return console.log('No value.');
    if (!itemKey) return console.log('No item key');
    if (!resource || !resourceRoute) return console.log('No resource or resourceRoute');

    api
      .put('/' + resourceRoute + '/' + resource.id, { [itemKey]: value })
      .then((res) => {
        console.log('Update is ', res);
      })
      .catch((err) => {
        console.log('Put err ', { err, response: err.response });
      });

    if (updateProjectContext) {
      setProject({
        ...resource,
        [itemKey]: value,
      });
    } else {
      setDescValue(value);
    }
  };

  let item = '';
  if (mayEdit && itemKey && resource && resourceRoute) {
    item = (
      <Paragraph
        onClick={(evt) => evt.stopPropagation()}
        editable={{ onChange: onEditableChange, tooltip: 'Click to edit' }}
        className="mb-0"
      >
        {descValue}
      </Paragraph>
    );
  } else if (desc && linkTo) {
    item = (
      <Link to={linkTo} className="mb-0">
        {desc}
      </Link>
    );
  } else {
    item = <div className="mb-0">{desc || ''}</div>;
  }

  return (
    <Auxiliary>
      <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
        <div className="mr-3">
          <i className={`icon icon-${icon} fs-${iconSize} text-${color}`} />
        </div>
        <div className="media-body">
          <h6 className="mb-1 text-grey">{title}</h6>
          {item}
        </div>
      </div>
    </Auxiliary>
  );
};

export default AboutItem;
