const colors = [
  '#F9690E', // yellow
  '#7A942E', // green
  '#19B5FE', // blue
  '#F47983', // pink
  '#875F9A', // purple
  '#757D75', // gray
  '#C91F37', // red
  '#FFA400', // yellow
  '#26A65B', // green
  '#003171', // blue
  '#F58F84', // pink
  '#BF55EC', // purple
  '#95A5A6', // gray
  '#F22613', // red
  '#E68364',
  '#6B9362',
  '#4D8FAC',
  '#D24D57',
  '#A17917',
  '#48929B',
  '#875F9A',
  '#CA6924',
  '#6C7A89',
  '#03A678',
  '#264348',
  '#BE90D4',
  '#F58F84',
  '#317589',
  '#CF3A24',
  '#757D75',
  '#E29C45',
];
export default colors
