import {
  standardConceptGrammar,
  standardConceptVariations,
  genitiveRules,
  languages,
} from '../../data/concepts/built-in-data';
import { Concept, Contract } from '../index';
import applyLogic from '../../utils/logic/applyLogic';
import { ocount, isObject } from '../../utils/general';

var STATUSES = {
  DISABLED: 'DISABLED',
  NO_DATA: 'NO_DATA',
  NO_DATA_ENTRIES: 'NO_DATA_ENTRIES',
  SUCCESS: 'SUCCESS',
  NO_CONCEPT: 'NO_CONCEPT',
  NO_CONCEPT_DESCRIPTION: 'NO_CONCEPT_DESCRIPTION',
};
var STATUS_DATA = {
  DISABLED: { status: '_error_', code: STATUSES.DISABLED, text: '' },
  NO_DATA: { status: '_error_', code: STATUSES.NO_DATA, text: '' },
  NO_DATA_ENTRIES: { status: '_error_', code: STATUSES.NO_DATA_ENTRIES, text: '' },
  NO_CONCEPT: { status: '_error_', code: STATUSES.NO_CONCEPT, text: '' },
  NO_CONCEPT_DESCRIPTION: { status: '_error_', code: STATUSES.NO_CONCEPT_DESCRIPTION, text: '' },
  SUCCESS: { status: '_success_', code: STATUSES.SUCCESS, text: '' },
};

function generateStatus(status, data) {
  return {
    ...STATUS_DATA[status],
    data,
  };
}

// Supports:
// 'a_borrower' => ['a', 'borrower']
// or
// 'a_borrower_s' => ['a', 'borrower', 's'] (indicates genitive)
export function handleKey(key) {
  if (!key) return [null, null, null];
  const [a, b, c] = key.split('_');
  if (a && b && c) return [a, b, c];

  const genitive = key.substr(key.length - 2, key.length) === '_s';

  if (a && b) {
    if (genitive) return [null, a, b];
    else return [a, b, null];
  }

  return [null, a, null];
}

/*********************/
/* Listing functions */
export function describeAll() {
  const all = [];
  for (const concept in standardConceptGrammar) {
    if (!standardConceptGrammar[concept].values) continue;
    const conceptResult = { concept, values: describeAllOfConcept(concept) };

    all.push(conceptResult);
  }

  return all;
}

export function describeAllOfConcept(conceptName, options = {}) {
  const result = [];
  const relevantVariationNames = options.relevantVariationNames || Object.keys(standardConceptVariations)
  for (const variationName of relevantVariationNames) {
    const variation = standardConceptVariations[variationName];
    const variationResult = {
      key: variation.key,
      fullKey: variation.key + '_' + conceptName,
      languages: describeAllOfVariation(conceptName, variation, options),
    };

    result.push(variationResult);
  }
  return result;
}

export function describeAllOfVariation(conceptName, variation, options = {}) {
  const result = {};
  const parseLanguages = options.language ? [options.language] : languages;
  for (const language of parseLanguages) {
    result[language] = {
      one: describeConcept(variation.key + '_' + conceptName, 1, language, { ...options, mock: true }),
      many: describeConcept(variation.key + '_' + conceptName, 2, language, { ...options, mock: true }),
      one_genitive: describeConcept(variation.key + '_' + conceptName + '_s', 1, language, {
        ...options,
        mock: true,
      }),
      many_genitive: describeConcept(variation.key + '_' + conceptName + '_s', 2, language, {
        ...options,
        mock: true,
      }),
    };
  }
  return result;
}
/* End of listing functions */
/****************************/

/* Helpers */
export function _getNumerus(id, input, options = {}) {
  const { completeConceptGrammar } = _ensureConceptGrammar(options);

  let concept;
  if (options.contract) {
    const concepts = Contract.getConcepts(options.contract);
    concept = concepts.find((c) => c.id === id);
    // if(!concept) return null
  }

  if (options.rule) {
    return _lengthToNumerus(applyLogic(options.rule, { input }, { _meta: { contract: options.contract } }));
  }

  if (completeConceptGrammar[id] && completeConceptGrammar[id].countRule) {
    return _lengthToNumerus(
      applyLogic(
        completeConceptGrammar[id].countRule,
        { input },
        { _meta: { contract: options.contract } }
      )
    );
  }

  // Use locally set stateId, if set. For instance, the concept `termFacility` which does not
  // have its own position within the state.input, may instead refer to state.input.facility
  // and have included a countRule (see above) only includeing state.input.facility[x].type === "term"
  if (completeConceptGrammar[id] && completeConceptGrammar[id].stateId)
    id = completeConceptGrammar[id].stateId;

  // A reference concept (requires us to have received the `contract` options in order to
  // locate such concept data), may refer to many state items. Combine them!
  if (concept && concept.type === 'reference') {
    const combinedState = Concept.getConceptState(concept, options.contract, { input });
    return _lengthToNumerus(ocount(combinedState));
  }

  if (concept) {
    const conceptState = Concept.getConceptState(concept, options.contract, { input });
    if (!conceptState) {
      console.warn('We got no conceptState: ', { concept, input, conceptState });
      return _lengthToNumerus(0);
    }
    return _lengthToNumerus(ocount(conceptState));
  }

  if (input[id] && isObject(input[id])) return _lengthToNumerus(ocount(input[id]));

  // Find the numerus (singluar or plural) using underlying input data

  return null;
}

function _lengthToNumerus(length) {
  if (length === 1) return 'singular';
  else if (length > 1) return 'plural';
  return null;
}

function _returnNoId(id, key, input, language, options) {
  let numerus;
  // Transform the id - now key - (if relevant) if the relevant concept is a transform concept.
  if (!options.mock) {
    const { newId, change } = _checkTransformConcept(key, input, options.completeConceptGrammar);
    if (change) key = newId;

    numerus = _getNumerus(key, input, options);
  } else {
    numerus = _lengthToNumerus(ocount(input));
  }

  const { value, match } = getConceptValue(key, numerus || 'singular', language, options);

  if (numerus && match) {
    return {
      status: generateStatus(STATUSES.SUCCESS, { id, concept: key, base: true }),
      value,
      fallbackValue: null,
      text: value,
    };
  } else {
    return {
      status: generateStatus(STATUSES.NO_CONCEPT, { id, concept: key, base: true }),
      value: null,
      fallbackValue: value,
      text: value,
    };
  }
}
function _returnDisabled(id) {
  // If concept disabled, return standard brackets
  return {
    status: generateStatus(STATUSES.DISABLED, { id }),
    value: null,
    fallbackValue: brackify('**'),
    text: brackify('**'),
  };
}
/* function _returnNoData(id, key, language, options) {
  // If no input, return bracketed general, singular word
  const { value: fallbackValue } = getConceptValue(id || key, "singular", language, options);
  return {
    status: generateStatus(STATUSES.NO_DATA, { id }),
    value: null,
    fallbackValue,
    text: brackify(fallbackValue),
  };
} */
function _returnNoEntries(id, key, language, options) {
  const { value: fallbackValue } = getConceptValue(id, 'singular', language, options);
  return {
    status: generateStatus(STATUSES.NO_DATA_ENTRIES, { id, options }),
    value: null,
    fallbackValue,
    text: brackify(fallbackValue),
  };
}
function _returnNoConceptDescription(id, key, language, options) {
  const { value: fallbackValue } = getConceptValue(id || key, 'singular', language, options);
  return {
    status: generateStatus(STATUSES.NO_CONCEPT_DESCRIPTION, { id, language }),
    value: null,
    fallbackValue,
    text: brackify(fallbackValue),
  };
}

function _checkRule(id, input, conceptGrammar, options) {
  if (
    conceptGrammar[id] &&
    conceptGrammar[id].rule &&
    !applyLogic(conceptGrammar[id].rule, { input }, { _meta: { contract: options.contract } })
  ) {
    return false;
  }
  return true;
}

/**
 * _checkTransformConcept
 * 
 * Certain conceptGrammars merely points to other concepts, using
 * json logic rules to determine which is applicable.
 * 
 * Such conceptGrammar might have a transforms array looking like:
   [
     {
        rule: { json-logic-rule }
        "concept": "termFacility"
      }, 
    ]
 * 
 * The transforms are applied in order. First valid rule determines
 * which concept to actually use.
 *
 * @param {string} id 
 * @param {object} input 
 */
function _checkTransformConcept(id, input, conceptGrammar, options) {
  if (!conceptGrammar[id]) {
    return { change: false, id };
  }
  if (!Array.isArray(conceptGrammar[id].transforms)) {
    return { change: false, id };
  }

  // When mocking the value, choose the first transform.
  if (options.mock) {
    return { change: true, newId: conceptGrammar[id].transforms[0].concept, oldId: id };
  }

  if (typeof input !== 'object' || input === null) {
    return { change: false, id };
  }

  for (const transform of conceptGrammar[id].transforms) {
    if (transform.rule && !applyLogic(transform.rule, { input }, { _meta: { contract: options.contract } }))
      continue;
    if (transform.concept) return { change: true, newId: transform.concept, oldId: id };
  }

  // TBD.
  // if (conceptGrammar.stateId) return { change: true, id: conceptGrammar.stateId }

  return { change: false, id };
}

export function _ensureConceptGrammar(options = {}) {
  if (options.completeConceptGrammar && options.completeConceptVariations) return options;

  let value = { ...standardConceptGrammar };

  if (options.contract) {
    const contractConceptGrammar = Contract.getConceptGrammar(options.contract);
    if (contractConceptGrammar) value = { ...value, ...contractConceptGrammar };
  }

  if (options.conceptGrammar) {
    value = { ...value, ...options.conceptGrammar };
  }

  options.completeConceptGrammar = value;
  return options;
}
function _ensureConceptVariations(options = {}) {
  if (options.completeConceptVariations && options.completeConceptVariations) return options;

  let value = { ...standardConceptVariations };

  if (options.contract) {
    const contractConceptVariations = Contract.getConceptVariations(options.contract);
    if (contractConceptVariations) value = { ...value, ...contractConceptVariations };
  }

  if (options.conceptVariations) {
    value = { ...value, ...options.conceptVariations };
  }

  options.completeConceptVariations = value;
  return options;
}

export function getDescribeData(conceptId, language, options = {}) {
  const { completeConceptGrammar } = _ensureConceptGrammar(options);
  if (
    !completeConceptGrammar[conceptId] ||
    !completeConceptGrammar[conceptId].values ||
    !completeConceptGrammar[conceptId].values[language]
  )
    return null;
  return completeConceptGrammar[conceptId].values[language];
}

/**
 *
 * @param {string} key description key, e.g. 'the_borrower'
 * @param {object} input state input - e.g. `state.input`
 * @param {string} language relevant language
 * @param {object} options { contract, conceptGrammar, conceptVariations }
 */
// describeConcept(variation.key + '_' + conceptName, 2, language, { ...options, mock: true }),
export function describeConcept(key, input, language, options = {}) {
  let numerus;
  let [describer, id, genitive] = handleKey(key);

  const { completeConceptGrammar } = _ensureConceptGrammar(options);
  _ensureConceptVariations(options);

  if (options.useConcept && options.useConcept.id && options.useConcept.values) {
    completeConceptGrammar[options.useConcept.id] = { values: options.useConcept.values };
  }

  // No id => return standard
  if (!id) return _returnNoId(id, key, input, language, options);

  // Rule not passing => return disabled
  if (!options.mock && !_checkRule(id, input, completeConceptGrammar, options)) return _returnDisabled(id);

  // Transform the id (if relevant) if the relevant concept is a transform concept.
  const { newId, change } = _checkTransformConcept(id || key, input, completeConceptGrammar, options);
  if (change) id = newId;

  if (!options.mock) {
    numerus = _getNumerus(id, input, options);
  } else {
    numerus = typeof input === 'number' ? _lengthToNumerus(input) : _lengthToNumerus(ocount(input));
  }

  // No numerus => no entries. Return early.
  if (!numerus) {
    return _returnNoEntries(id, key, language, options);
  }

  // Find genitive and retrieve value
  const getGenitiv = genitive === 's';

  let value;
  if (describer) {
    value = getDescribedConcept(describer, id, numerus, language, {
      ...options,
      getGenitiv,
    });
  } else {
    value = getConceptValue(id, numerus || 'singular', language, options).value;
  }
  // console.log('Describer and val ? ', {describer, value})
  // No value => No concept matching. Return early.
  if (!value) return _returnNoConceptDescription(id, key, language, options);

  // Possibly return value based on concept `definitionKey` (if any)
  if (options.allowDefinition && options.contract) {
    const concept = Contract.getConcept(options.contract, id);
    if (concept && concept.definitionKey) {
      const conceptState = Concept.getConceptState(concept, options.contract, { input });
      if (ocount(conceptState) === 1 && conceptState[0] && conceptState[0][concept.definitionKey]) {
        return {
          status: generateStatus(STATUSES.SUCCESS, { id }),
          value: conceptState[0][concept.definitionKey],
          fallbackValue: value,
          text: conceptState[0][concept.definitionKey],
        };
      }
    }
  }

  // Success
  return {
    status: generateStatus(STATUSES.SUCCESS, { id }),
    value,
    fallbackValue: null,
    text: value,
  };
}

function brackify(str) {
  return '[' + str + ']';
}

function getDescribedConcept(describer, id, numerus, language, options = {}) {
  const { getGenitiv = false } = options;

  const { completeConceptGrammar } = _ensureConceptGrammar(options);
  const { completeConceptVariations } = _ensureConceptVariations(options);

  let conceptVariation = completeConceptVariations[describer];
  if (!conceptVariation) {
    return null;
  }
  const variationRule =
    conceptVariation.rules && conceptVariation.rules[language] && conceptVariation.rules[language][numerus];
  if (!variationRule) {
    return null;
  }

  const targetMode = variationRule.targetMode || numerus;

  let { value } = getConceptValue(id, targetMode, language, options);

  const isPlural = targetMode.includes('plural');

  value = variationModifyValue(
    value,
    variationRule,
    getConceptGrammarRules(id, language, completeConceptGrammar)
  );

  if (getGenitiv) value = genitiveRules[language](value, isPlural);

  return value;
}

function getConceptGrammarRules(id, language, conceptGrammar) {
  if (!conceptGrammar) return false;
  if (!id) return false;
  if (!conceptGrammar[id] || !conceptGrammar[id].values || !conceptGrammar[id].values[language]) {
    return false;
  }
  return conceptGrammar[id].values[language];
}

export function getConceptValue(id, type, language, options = {}) {
  const { completeConceptGrammar } = _ensureConceptGrammar(options);

  const rules = getConceptGrammarRules(id, language, completeConceptGrammar);

  if (!rules)
    return {
      match: false,
      value: brackify(id),
    };
  let value = rules.base;
  return {
    match: true,
    value: grammarModifyValue(value, rules[type]),
  };
}

// Common function for modifying string due to
// grammar rules. Rules may derive from either
// 'conceptGrammar' or 'conceptVariations'

function grammarModifyValue(value, rule) {
  if (rule.prefix) value = rule.prefix + (rule.prefixSpace ? ' ' : '') + value;
  if (rule.suffix) value = value + (rule.suffixSpace ? ' ' : '') + rule.suffix;

  return value;
}
function variationModifyValue(value, rule, conceptGrammarRule) {
  const { gender } = conceptGrammarRule;
  if (rule.prefixes) {
    let allPrefix = '';
    for (const prefixRule of rule.prefixes) {
      let [prefix, space] = prefixRule;
      if (typeof prefix === 'object' && prefix !== null) {
        if (prefix.text) prefix = prefix.text;
        else if (!gender || !prefix[gender]) continue;
        else prefix = prefix[gender];
      }
      allPrefix += prefix + (space ? ' ' : '');
    }
    value = allPrefix + value;
  }
  if (rule.suffixes) {
    let allSuffix = '';
    for (const suffixRule of rule.suffixes) {
      let [suffix, space] = suffixRule;
      if (typeof suffix === 'object' && suffix !== null) {
        if (suffix.text) suffix = suffix.text;
        else if (!gender || !suffix[gender]) continue;
        else suffix = suffix[gender];
      }
      allSuffix += (space ? ' ' : '') + suffix;
    }
    value = value + allSuffix;
  }
  if (rule.suffix) value = value + (rule.suffixSpace ? ' ' : '') + rule.suffix;

  return value;
}
