import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar, Popover } from 'antd';
import { userSignOut } from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { UserAvatar } from 'components/ui';

const UserProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const userMenuOptions = (
    <ul className="user-popover">
      {/* <li>My Account</li>
      <li>Connections</li> */}
      <li
        onClick={() => {
          dispatch(userSignOut());
          setTimeout(() => {
            history.push('/signin');
          }, [200]);
        }}
      >
        <IntlMessages id="app.userAuth.signOut" />
      </li>
    </ul>
  );

  return (
    <div className="flex-row align-items-center mb-4 avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        {/* <Avatar
          style={{ verticalAlign: "middle" }}
          className="size-40 bg-light pointer mr-3"
          alt=""
        >
          <i className="icon icon-user ico-sm" />
        </Avatar> */}
        <UserAvatar
          user={user}
          style={{ verticalAlign: 'middle' }}
          className="size-40 pointer mr-3"
        />
        <div className="avatar-name user-name">
          {user && user.firstName} {user && user.lastName}
          <i className="icon icon-chevron-down fs-xxs ml-2" />
        </div>
      </Popover>
    </div>
  );
};

export default UserProfile;
