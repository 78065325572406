import { SET_NODES, UPDATE_NODE, INSERT_NODE, REMOVE_NODE } from "constants/ActionTypes";

/*
const INIT_STATE = [
    { id: 1, parentId: null, Name: "Amber McKenzie"},
    { id: 2, parentId: 1, Name: "Ava Field", Title: 'mjauer'},
    { id: 3, parentId: 1, Name: "Evie Johnson"}
  ];
  */

export const INIT_STATE =
  process.env.REACT_APP_NODE_ENV === "development"
    ? [
        {
          id: 1,
          name: "Owner",
          parentId: null,
          addInfo: "Owner",
          properties: [],
        },
        {
          id: 2,
          name: "Boxen Holding AB",
          parentId: 1,
          regno: "556677-1122",
          country: "se",
          addInfo: "TopCo",
          corpForm: "AB",
          properties: [],
        },
        {
          id: 3,
          name: "Fastighets AB Skrået",
          parentId: 2,
          regno: "556677-1123",
          country: "se",
          addInfo: "A subsidiary",
          corpForm: "AB",
          properties: [],
        },
        {
          id: 4,
          name: "Stugan AB",
          parentId: 2,
          regno: "5566771124",
          country: "se",
          addInfo: "A subsidiary",
          corpForm: "AB",
          properties: [],
          isTarget: false,
          secondParentId: 0,
        },
        {
          id: 5,
          name: "Boxen i Stockholm AB",
          parentId: 2,
          regno: "5566771125",
          country: "se",
          addInfo: "A subsidiary",
          corpForm: "AB",
          properties: [],
        },
        {
          id: 6,
          name: "Stallet AB",
          parentId: 2,
          regno: "556677-1126",
          country: "se",
          addInfo: "A subsidiary",
          corpForm: "AB",
          properties: [],
        },
        {
          id: 7,
          name: "Katten AB",
          parentId: 2,
          regno: "556677-1127",
          country: "se",
          addInfo: "A subsidiary",
          corpForm: "AB",
          properties: [],
        },
        {
          id: 8,
          name: "Humlan Förvaltning KB",
          parentId: 4,
          secondParentId: 5,
          regno: "556677-1128",
          country: "se",
          addInfo: "A subsidiary",
          corpForm: "KB",
          properties: [],
        },
      ]
    : [
        {
          id: 1,
          name: "Owner",
          parentId: null,
          addInfo: "Owner",
          properties: [],
        },
        {
          id: 2,
          name: "TopCo",
          parentId: 1,
          regno: "",
          country: "se",
          addInfo: "TopCo",
          corpForm: "AB",
          properties: [],
        },
      ];

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_NODES:
      return action.payload;
    case UPDATE_NODE:
      const newState = [...state];
      newState[action.payload.index] = action.payload.node;
      return newState;
    case INSERT_NODE:
      return [...state, action.payload];

    case REMOVE_NODE:
      const payloadType = typeof action.payload;
      let removeIds;
      if (payloadType === "string") {
        if (isNaN(parseInt(action.payload))) return state;
        removeIds = [parseInt(action.payload)];
      } else if (payloadType === "number") removeIds = [action.payload];
      else if (Array.isArray(action.payload)) removeIds = action.payload;
      else return state;
      return state.filter((node) => !removeIds.includes(node.id));

    // return state.filter(node => node.id !== action.payload);
    /*
            const newStateRemove = [ ...state ];
            const removingNodeIndex = newStateRemove.findIndex(data => action.payload == data.id);
            if(removingNodeIndex === -1)
                return state;
            newStateRemove.splice(removingNodeIndex, 1);
            return newStateRemove;
            */
    default:
      return state;
  }
};
