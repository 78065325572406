import React, { useMemo, useCallback, useEffect, useState } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useIsTemplateStudio, useHighlightContractUpdates, useContract, useEventState } from 'hooks/';
import CreatePage from './renderDraftUI/CreatePage';
import { Contract } from 'core/interfaces';
import EditorStructureView from './EditorStructureView';
import config from 'config/config';

const { MULTI_PAGE = false } = config?.ui?.studio || {};

function StudioInputContainer({ contractPreviewContainer, activePaths }) {
  const contract = useContract();
  const createRoutes = Contract.getUiRoutes(contract);
  const isTemplate = useIsTemplateStudio();

  const createRoutesJson = JSON.stringify(createRoutes); // We must stringify because useMemo will just do a shallow compare and won't find changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const RouteElements = useMemo(
    () => createRoutes && makeRouteComponents(createRoutes, isTemplate),
    [createRoutesJson]
  );

  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    if (!MULTI_PAGE) return;
    const inputPaths = pathname.split('/').slice(5);
    const targetId = 'creating-input-' + inputPaths.join('-');
    const element = document.getElementById(targetId);
    if (element && typeof element.scrollIntoView === 'function') {
      element.scrollIntoView();
      setTimeout(() => {
        const header = element.querySelector('.create-page-header');
        if (header) {
          header.classList.toggle('highlight-highlighted-page-header');
          setTimeout(() => {
            header.classList.toggle('highlight-highlighted-page-header');
          }, 1000);
        }
      }, 50);
    }
  }, [pathname]);

  return (
    <StudioInputForms
      contractPreviewContainer={contractPreviewContainer}
      RouteElements={RouteElements}
      activePaths={activePaths}
    />
  );
}
const makeRouteComponents = (routes, isTemplate) => {
  if (!routes || !Array.isArray(routes)) return null;
  let RouteElements = [];
  for (const route of routes) {
    if (isTemplate || !route.collapse) {
      RouteElements.push(
        <Route
          key={route.fullPath}
          path={route.fullPath}
          exact={true}
          render={(props) => <CreatePage route={route} {...route.props} {...props} />}
        />
      );
    }
    if (route.children) RouteElements.push(...makeRouteComponents(route.children, isTemplate));
  }
  return RouteElements;
};

const StudioInputForms = ({ RouteElements, contractPreviewContainer, activePaths }) => {
  const isTemplate = useIsTemplateStudio();
  const { highlight } = useHighlightContractUpdates({ isTemplate });
  useEffect(() => {
    if (!isTemplate) highlight();
  }, [isTemplate, highlight]);

  const resizeMade = useCallback(
    (evt) => {
      const { percentage } = evt.detail;
      const resizeElement = document.getElementById('preview-column');
      const editorElement = document.getElementById('editor-holder');
      if (!editorElement || !resizeElement) return;

      resizeElement.style.width = percentage + '%';
      resizeElement.style.flex = ' 0 0 ' + percentage + '%';

      const newSize = (percentage / 30).toFixed(2);
      editorElement.style.fontSize = newSize + 'em';
      editorElement.style.lineHeight = newSize * 1.5 - (percentage - 30) / 30 + 'em';

      if (!isTemplate) highlight();
    },
    [isTemplate, highlight]
  );

  useEffect(() => {
    if (!contractPreviewContainer.current) return;
    const previewContainer = contractPreviewContainer.current;
    previewContainer.addEventListener('customResize', resizeMade);
    return () => {
      previewContainer.removeEventListener('customResize', resizeMade);
    };
  }, [contractPreviewContainer, resizeMade]);

  let content;
  if (MULTI_PAGE) {
    content = <AllRoutes activePaths={activePaths} />;
  } else {
    content = isTemplate ? (
      <TemplateRightSide RouteElements={RouteElements} />
    ) : (
      <Switch>{RouteElements || null}</Switch>
    );
  }

  return (
    <div id="draft-input-forms" className="draft-input-forms">
      <CustomScrollbars>
        {content}
        {/* {isTemplate ? (
          <TemplateRightSide RouteElements={RouteElements} />
        ) : (
          <Switch>{RouteElements || null}</Switch>
        )} */}
      </CustomScrollbars>
    </div>
  );
};

function AllRoutes({ activePaths }) {
  const contract = useContract();
  const createRoutes = Contract.getUiRoutes(contract);
  return (
    <>
      {createRoutes.map((route) => (
        <>
          <CreatePage
            key={route.id}
            isActive={activePaths[route.paths.toString()]}
            route={route}
            {...route.props}
            fullId={route.id}
            parents={[]}
          />
          {route.children &&
            route.children.map((child) => (
              <>
                <CreatePage
                  key={route.id + '-' + child.id}
                  isActive={activePaths[child.paths.toString()]}
                  route={child}
                  {...child.props}
                  fullId={route.id + '-' + child.id}
                  parents={[route]}
                />
                {child.children &&
                  child.children.map((grandchild) => (
                    <>
                      <CreatePage
                        key={route.id + '-' + child.id + '-' + grandchild.id}
                        isActive={activePaths[grandchild.paths.toString()]}
                        route={grandchild}
                        {...grandchild.props}
                        fullId={route.id + '-' + child.id + '-' + grandchild.id}
                        parents={[route, child]}
                      />
                    </>
                  ))}
              </>
            ))}
        </>
      ))}
    </>
  );
}

function TemplateRightSide({ RouteElements }) {
  const showEditorStructure = useEventState('ContractStructureModeSwitch');
  const contractFocus = useEventState('contractFocus');

  if (showEditorStructure) return <EditorStructureView />;

  if (contractFocus) return null;

  return RouteElements || null;
}

export default StudioInputContainer;
