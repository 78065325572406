
import { Node, Path, Range } from "slate";
import { notification } from 'antd'

const isCollapsed = (selection) =>
  selection.anchor && selection.focus && Range.isCollapsed(selection);

function actionAllowed(editor) {
  if(editor.meta?.isEditingTemplate) return true
  if(editor.editingAllowed()) return true
  if(editor?.meta?.action === "input") return false
  const { selection } = editor
  if(!selection) return false
  const collapsedSelection = isCollapsed(selection);
  
  if (!collapsedSelection && !Path.equals(selection.anchor.path, selection.focus.path)) {
    console.log('Selection failed')
    return false
  }

  const currentInline = editor.currentInline(selection.anchor.path);
  if (!currentInline || !Node.isTextInline(editor, currentInline)) {
    return false;
  }
  return true
}

export const withPreventActions = editor => {
  let failedAttempts = 0
  const { addMark, removeMark, insertData, insertText, insertBreak, insertFragment, insertNode, deleteBackward, deleteForward, deleteFragment } = editor;

  if (!editor.meta) editor.meta = {};
  editor.meta._forceAllow = false

  editor.insertText = (...args) => actionAllowed(editor) && insertText(...args)
  editor.insertData = (...args) => actionAllowed(editor) && insertData(...args)
  editor.addMark = (...args) => actionAllowed(editor) && addMark(...args)
  editor.removeMark = (...args) => actionAllowed(editor) && removeMark(...args)
  editor.insertBreak = (...args) => actionAllowed(editor) && insertBreak(...args)
  editor.insertFragment = (...args) => actionAllowed(editor) && insertFragment(...args)
  editor.insertNode = (...args) => actionAllowed(editor) && insertNode(...args)
  editor.deleteBackward = (...args) => actionAllowed(editor) && deleteBackward(...args)
  editor.deleteForward = (...args) => actionAllowed(editor) && deleteForward(...args)
  editor.deleteFragment = (...args) => actionAllowed(editor) && deleteFragment(...args)
  
  editor.editingAllowed = (warnIfNotAllowed = true) => {
    const allowed = (editor.meta?.allowEditing) || editor.meta.isEditingTemplate || editor?.meta?._forceAllow /* || editor?.meta?.external */
    if (warnIfNotAllowed && !allowed) {
      failedAttempts++;
      if (failedAttempts > 3) {
        notification.warning({
          message: 'Enable editing.',
          description: 'It seems as if you are trying to edit the document. Please enable editing first.'
        })
        failedAttempts = 0
      }
      return false
    }
    return true
    // return (editor.meta?.interactive && editor.meta?.allowEditing) || editor.meta.isEditingTemplate || editor?.meta?._forceAllow
  }

  editor.withAllowedEditing = (fn) => {
    editor.meta._forceAllow = true
    fn()
    editor.meta._forceAllow = false
  }

  return editor
}
