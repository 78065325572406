import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import IntlMessages from 'util/IntlMessages';
import { Prompt } from 'react-router';
import HTML5Backend from 'react-dnd-html5-backend';
import CustomScrollbars from 'util/CustomScrollbars';
import DropZone from './DropZone';
import { combineNumber, makeRepeatableId, ocount } from 'core/utils/general';
import { useDrag } from 'react-dnd';
import { Card, Row, Col, Tooltip, notification, Button } from 'antd';
import { connect } from 'react-redux';
import {
  updateLegalPerson, // Updates should only be made here, or otherwise updating the redux state (not internally within the chart)
  updateInputs,
  addRepeatable,
  setConnectedInputCards,
  setLegalPersons,
} from 'appRedux/actions';
import Chart from 'components/orgchart/orgchart';
import { getContractValues } from 'hooks';
import { Contract } from 'core/interfaces';
import defaultLegalPersons from 'constants/state/LegalPersons';
import EditLegalPerson from 'components/entity/EditLegalPerson';

const CardBody = ({ children, ...rest }) => {
  return <div {...rest}>{children}</div>;
};

const topLeftIcon = ['mdi mdi-table-edit', 'color: #2873ff'];
const bottomLeftIcon = ['mdi mdi-close-circle', 'color: #f35555'];
const bottomRightIcon = ['mdi mdi-plus-circle', 'color: #399039'];

function findLegalPerson(repeatableData, key, id) {
  if (!repeatableData) return;
  for (const [, value] of Object.entries(repeatableData)) {
    if (value[key] && value[key].id === id) return value;
  }
}

const isBelowTopCo = (legalPerson, legalPersons) => {
  return legalPersons[legalPerson.pid] && legalPersons[legalPerson.pid].pid;
}; // Only show if parent has a parent (i.e. always two nodes)

const isNotOwner = (legalPerson, legalPersons) => !!legalPerson.pid;

const bottomLeftPredicate = (legalPerson, legalPersons) => {
  return legalPersons[legalPerson.pid] && legalPersons[legalPerson.pid].pid;
}; // Only show if parent has a parent (i.e. always two nodes)

const mapStateToProps = (state) => ({
  input: state.input,
  legalPersons: state.legalPersons,
});
const mapDispatchToProps = (dispatch) => ({
  updateInputs: (...args) => dispatch(updateInputs(...args)),
  addRepeatable: (...args) => dispatch(addRepeatable(...args)),
  setConnectedInputCards: (...args) => dispatch(setConnectedInputCards(...args)),

  // Actions to state and chart
  updateLegalPerson: (id, data) => dispatch(updateLegalPerson(id, data)),
  // Events from chart
  setLegalPersons: (data) => dispatch(setLegalPersons(data)),
});

class OrgChart extends React.Component {
  constructor(props) {
    super(props);
    this.dropFunc = this.dropFunc.bind(this);
    this.state = {
      chartElem: false,
      pendingChanges: {},
      updateCount: 0,
      editingLegalPersonId: null,
      setAutoSelectNameOnEdit: false,
    };

    this.editNameRef = createRef();
    const contract = getContractValues();
    this.ui = contract && Contract.getUi(contract);
    this.language = contract && Contract.getLanguage(contract);

    this.chartElem = false;
    this.hasMounted = false;
    this.layout = [
      // each array item a row

      [
        // each item a column
        {
          class: 'nodename',
          align: 'center',
          dataField: 'name',
          id: 'orgchart_name',
        },
      ],
      [
        {
          func: this.customLayout,
        },
      ],
      /*
      [ 
        {
          class: '',
          align: 'center',
          metaDataField: 'classNames',
          id: 'orgchart_name'
        },
      ]
      */
    ];

    this.setup(this.props.modules);

    this.updateRequiringRenderIndex = 0;
  }

  setup = (modules) => {
    if (modules.length === 0) return;
    for (let mod of modules) {
      if (mod.customLayout) {
        this.customModuleLayout = mod.customLayout;
      }
    }
  };

  setupDefaultLegalPersons = () => {
    try {
      const newLPs = JSON.parse(JSON.stringify(defaultLegalPersons));
      if (!Array.isArray(newLPs)) return console.log('Invalid DefaultLegalPersons');
      this.props.setLegalPersons(newLPs);
    } catch (err) {}
  };

  customLayout = (legalPerson) => {
    if (this.customModuleLayout) {
      return this.customModuleLayout(legalPerson);
    }

    let colorBlobs = '';

    const legalPersonBorrower = findLegalPerson(this.props.input.borrower, 'borrowerEntity', legalPerson.id);
    const facilities = this.props.input.facility;

    if (legalPersonBorrower && facilities && ocount(facilities) > 0) {
      const uid = legalPersonBorrower['_uid'];
      const availableFacs =
        this.props.input.__connectedCards &&
        this.props.input.__connectedCards.filter((cc) => {
          if (cc.key !== 'facilityAvailableToBorrower') return false;
          if (cc.cards?.borrower !== uid) return false;
          if (!cc.cards?.facility) return false;
          if (!cc.value) return false;
          return true;
        });

      if (availableFacs && availableFacs.length > 0) {
        colorBlobs += availableFacs
          .map((cc) => {
            const facility = facilities[cc.cards.facility];
            if (!facility?._meta?.color) return null;
            return (
              '<div class="drop-indicator" style="background-color:' + facility['_meta'].color + '"></div>'
            );
          })
          .join('');
      }
    }
    const legalPersonGuarantor = findLegalPerson(
      this.props.input.guarantor,
      'guarantorEntity',
      legalPerson.id
    );
    if (legalPersonGuarantor)
      colorBlobs += '<div class="drop-indicator guarantor"><i class="mdi mdi-account-key"></i></div>';

    return colorBlobs;
  };

  componentDidMount() {
    this.hasMounted = true;
    // this.ignoreUpdateChart = true;
    this.chartElem = document.getElementById('ihd_orgchart');
    this.onDrawCallback(); // Call this first time after chart and legalPersons have been set up
  }

  static getDerivedStateFromProps(props, state) {
    return {
      weForcedTheUpdate: false,
    };
  }

  onLegalPersonInsert = (legalPerson) => {
    this.setState({ setAutoSelectNameOnEdit: true });
    this.setEditLegalPerson(legalPerson.id);
  };

  setEditLegalPerson = (id) => {
    this.setState({ editingLegalPersonId: id });
  };
  cancelEditLegalPerson = () => {
    this.setState({ setAutoSelectNameOnEdit: false });
    this.setState({ editingLegalPersonId: null });
  };
  editLegalPersonCallback = () => {
    this.cancelEditLegalPerson(true);
    this.setUnsavedChanges(true);
  };
  setUnsavedChanges = (unsavedChanges) => {
    if (this.props.setUnsaved) {
      this.props.setUnsaved(unsavedChanges);
    }
  };

  clickLegalPersonEvent = (sender, args) => {
    /* if (args.node && args.node.id) {
      this.setEditLegalPerson(args.node.id);
    } */
  };

  editEvent = (orgchart, event) => {
    if (event.action === 'insert') {
      this.ignoreUpdateChart = true;
      orgchart.expandNode(event.parentId);
    }
    this.setUnsavedChanges(true);
  };

  updateChartCondition = () => {
    // No need to update if we have not mounted yet.
    if (!this.hasMounted) return false;

    return true
    // console.log('Update chart ? ', this.ignoreUpdateChart , this.state.weForcedTheUpdate)
    // weForcedTheUpdate        - if the update of the chart was caused by this component.
    // ignoreUpdateChart        - if we made minor UI changes to the chart only - no need to rerender.
    if (this.ignoreUpdateChart || this.state.weForcedTheUpdate) {
      // console.log("Chart may not update", this.ignoreUpdateChart, this.state.weForcedTheUpdate);
      this.ignoreUpdateChart = false; // Reset ignoreUpdateChart.
      return false;
    }
    return true;
  };

  onDrawCallback = (orgchart, weForcedTheUpdate) => {
    if (!this.hasMounted) {
      return;
    }

    if (this.chartElem) {
      // Set new dom nodes to the state. Will cause a re-render so that we can add new dropZones to the nodes.
      // This will not cause the underlying chart itself to re-render, as it is a PureComponent and we do not
      // change any of its props.
      // Use set timeout as it will otherwise update the state during render (as the callback function in orgchart(chart2.js) was caused by our previous render)
      setTimeout(() => {
        this.setState({
          domNodes: Array.from(this.chartElem.getElementsByClassName('achartnode')),
          weForcedTheUpdate,
        });
      }, 400);
    }

    return;
  };

  dropFunc(targetId, droppedItem) {
    const legalPerson = this.props.legalPersons.find((item) => item.id === targetId);
    if (!legalPerson) {
      console.log('No legalPerson found for id ' + targetId);
      return;
    }

    if (!legalPerson.parentId) {
      return notification.warn({
        message: 'The Owner shall not be a borrower or guarantor',
      });
    }

    const cardId = droppedItem.data.targetCard; // Added card, e.g. 'borrower'
    const targetCardId = droppedItem.data.type; // Dropped item card, e.g. 'facility'
    const targetCardUid = droppedItem.data.targetUid; // Dropped item index, e.g. 0

    let legalPersonHasCapacity =
      cardId === 'borrower'
        ? findLegalPerson(this.props.input.borrower, 'borrowerEntity', legalPerson.id)
        : findLegalPerson(this.props.input.guarantor, 'guarantorEntity', legalPerson.id);

    // Off case for borrowers. If the legalPerson is already a borrower - do not add it as borrower,
    // just make the dropped facility available.
    if (typeof legalPersonHasCapacity !== 'undefined') {
      // If already a guarantor - nothing more to do.
      if (cardId === 'guarantor') return;

      // The legalPerson is already a borrower, ensure it has access to the dropped facility.
      const companyUid = legalPersonHasCapacity['_uid'];

      this.props.setConnectedInputCards({
        key: 'facilityAvailableToBorrower',
        sourceCardId: cardId,
        sourceUid: companyUid,
        targetCardId: targetCardId,
        targetUid: targetCardUid,
        value: true,
      });
    } else {
      // The legalPerson on which the facility was dropped was not previously a Borrower - add it.

      const newBorrowerUid = makeRepeatableId(cardId);
      if (cardId === 'borrower') {
        this.props.addRepeatable(
          'input.' + cardId,
          {
            borrowerEntity: {
              id: targetId,
              type: 'legalPerson',
            },
          },
          newBorrowerUid,
          true
        );
        this.props.setConnectedInputCards({
          key: 'facilityAvailableToBorrower',
          sourceCardId: cardId,
          sourceUid: newBorrowerUid,
          targetCardId: 'facility',
          targetUid: targetCardUid,
          value: true,
        });
      } else if (cardId === 'guarantor') {
        this.props.addRepeatable(
          'input.' + cardId,
          {
            guarantorEntity: {
              id: targetId,
              type: 'legalPerson',
            },
          },
          newBorrowerUid
        );
      }
    }
    this.updateRequiringRenderIndex++;
    this.ignoreUpdateChart = false
    setTimeout(() => {
      this.ignoreUpdateChart = true
      this.setState({ updateCount: this.state.updateCount + 1 });
    }, 100);
  }

  render() {
    const { editingLegalPersonId, setAutoSelectNameOnEdit } = this.state;
    const language = this.language || 'en';

    if (!this.props.legalPersons || this.props.legalPersons.length === 0) {
      return (
        <div className="p-4 text-center">
          <div>
            <IntlMessages id="app.orgchart.noEntities" />
          </div>
          <div>
            <Button className="mt-4" onClick={this.setupDefaultLegalPersons}>
              <IntlMessages id="app.orgchart.createBasic" />
            </Button>
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <Prompt
          when={!!this.props.unsavedChanges}
          message={<IntlMessages id="app.orgchart.unsavedChangesConfirm" />}
        />
        {editingLegalPersonId && (
          <EditLegalPerson
            container={'drawer'}
            autoSelectName={setAutoSelectNameOnEdit}
            id={editingLegalPersonId}
            onCancel={this.cancelEditLegalPerson}
            onEditCallback={this.editLegalPersonCallback}
          />
        )}
        <CardBody>
          {this.props.legalPersons && this.props.legalPersons.length > 0 ? (
            <DndProvider backend={HTML5Backend}>
              {
                // portals
                this.state.domNodes
                  ? this.state.domNodes.map((node) =>
                      ReactDOM.createPortal(
                        <DropZone
                          targetNode={<div className="drop-zone"></div>}
                          id={node.getAttribute('node-id')}
                          dropFunc={this.dropFunc}
                        />,
                        node
                      )
                    )
                  : null
              }
              <Row>
                <Col span={20} className={'mt-5'}>
                  {this.props.onlyView ? null : <DragBoxes {...this.props} language={language} />}

                  <Chart
                    legalPersons={this.props.legalPersons}
                    editable={true}
                    layout={this.layout}
                    editCallback={this.editEvent}
                    onLegalPersonInsert={this.onLegalPersonInsert}
                    clickLegalPersonEvent={this.clickLegalPersonEvent}
                    updateChartCondition={this.updateChartCondition}
                    onDrawCallback={this.onDrawCallback}
                    updateRequiringRenderIndex={this.updateRequiringRenderIndex}
                    // updateCount={this.state.updateCount}
                    topLeftAction={this.setEditLegalPerson}
                    topLeftIcon={topLeftIcon}
                    topRightAction={'none'}
                    topRightIcon={'none'}
                    bottomLeftPredicate={bottomLeftPredicate}
                    bottomLeftIcon={bottomLeftIcon}
                    bottomRightIcon={bottomRightIcon}
                  />
                </Col>

                <Col span={4} className="companies-list-holder">
                  <h6><IntlMessages id="app.orgchart.Entities" /></h6>
                  <CustomScrollbars className="companies-list">
                    {this.props.legalPersons.map((legalPerson) => {
                      if (legalPerson.parentId === null) return null;
                      return (
                        <Card key={legalPerson.id}>
                          <CardBody onClick={() => this.setEditLegalPerson(legalPerson.id)}>
                            <b>{legalPerson.name}</b>
                            <br />
                            {legalPerson.identificationNumber}
                          </CardBody>
                        </Card>
                      );
                    })}
                  </CustomScrollbars>
                </Col>
              </Row>
            </DndProvider>
          ) : null}
        </CardBody>
      </React.Fragment>
    );
  }
}

function DragBoxes(props) {
  const { input, language } = props;
  const dragFacilityTooltip =
    language === 'sv'
      ? 'Lägg till facilitet till bolag genom att dra till rätt entitet'
      : 'Add facilty to company by dragging it onto the relevant company';

  const dragGuarantorTooltip =
    language === 'sv'
      ? 'Lägg till Borgensman genom att dra till rätt entitet'
      : 'Add a company as guarantor by dragging this icon onto the relevant company';

  let facilities;
  if (input.facility && ocount(input.facility) > 0) {
    facilities = Object.keys(input.facility).map((uid, index) => {
      const facility = input.facility[uid];
      let type =
        facility['facility/type'] === 'choose' || !facility['facility/type']
          ? 'X'
          : facility['facility/type'].toUpperCase();
      let amount =
        facility['facility/currency'] +
        ' ' +
        Math.round((combineNumber(facility['facility/commitment'], language) / 1000000) * 10) / 10;
      return (
        <DragBox
          key={index}
          type={'facility'}
          data={{
            type: 'facility',
            targetCard: 'borrower',
            targetUid: uid,
          }}
          style={{
            borderColor: facility['_meta'].color,
          }}
        >
          <Tooltip title={dragFacilityTooltip} key={index}>
            <p>{type}</p>
            <p>{amount}</p>
          </Tooltip>
        </DragBox>
      );
    });
  }

  return (
    <Row className="drag-row">
      {!!facilities && facilities}
      <DragBox
        type={'guarantor'}
        data={{
          type: 'guarantor',
          targetCard: 'guarantor',
        }}
      >
        <Tooltip title={dragGuarantorTooltip}>
          <i className="mdi mdi-account-star" />
        </Tooltip>
      </DragBox>
    </Row>
  );
}

const DragBox = ({ type, data, children, style = {} }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type, data },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <div ref={drag} className={'drop-box drop-box-' + type} style={{ ...style, opacity }}>
      {children}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgChart);

/* let layoutxx = [
	// each array item a row

	[
		// each item a column
		{
			class: "nodename",
			align: "center",
			dataField: "name"
		}
	],
	[
		{
			class: "hej"
		}
	],
	
  // [ 
  //   {
  //     class: '',
  //     align: 'center',
  //     metaDataField: 'classNames',
  //     id: 'orgchart_name'
  //   },
  // ]
  
]; */
/* let layoutx = [ // each array item a row
  
  [ // each item a column
    {
      class: '',
      align: 'center',
      dataField: name,
      id: 'text'
    },
    {
      class: '',
      col: 3,
      align: 'center',
      dataField: name,
      text: '',
    }
  ],
  [ // each item a column
    {
      class: '',
      align: 'center',
      dataField: name,
      text: '',
    }
  ]
] */
