
export const conceptOnAny = {
  id: "conceptOnAny",
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    const concepts = api.interfaces.Contract.getConcepts(this.contract);
    if (!concepts || concepts.length === 0) return;
    const { repeatableAdd, repeatableRemove, repeatableChange } = handlerInvoked;
    let affectedConcepts = [];

    for (const { cardId, fieldName, value } of entries) {
      let dataset;
      if (repeatableAdd) dataset = value;
      if (repeatableRemove) dataset = api.interfaces.Contract.getUiInputs(this.contract, cardId);

      // Get affected concept ids.
      affectedConcepts = affectedConcepts.concat(
        api.interfaces.Concept.affectedConcepts(concepts, cardId, {
          contract: this.contract,
          key: repeatableChange && fieldName,
          dataset: dataset,
          changedConceptNumber: repeatableAdd || repeatableRemove,
          allowDefinition: true,
        })
      );
    }

    affectedConcepts = [...new Set(affectedConcepts)]
    // this.log('Affected Concepts', {affectedConcepts})
    
    if (!this._tmpDraft.affectedConcepts) this._tmpDraft.affectedConcepts = affectedConcepts;
    else this._tmpDraft.affectedConcepts = this._tmpDraft.affectedConcepts.concat(affectedConcepts);
    // console.log('Affected concepts are ', affectedConcepts)
    if (affectedConcepts.length === 0) return;

    return (node, parents) => {
      if (!isValidFieldConcept(node)) return;
      const { key } = node.data;

      const [, id] = api.interfaces.Concept.handleKey(key);

      // Only apply if concept is affected by the change
      if (!affectedConcepts.includes(id)) return;

      const { text } = api.interfaces.Concept.describe(key, state.input, this.language, {
        contract: this.contract,
        allowDefinition: true,
      });
      let value = api.utils.engine.isTextFirst(node, parents[0]) ? api.utils.general.ucfirst(text) : text;

      if (value === node.data.value) return;

      node.data.value = value
      api.utils.engine.setFirstChild(node, value);
      this.markNodeUpdate(node);
    };
  },
};

function isValidFieldConcept(node) {
  if (node.type !== "field" || node.variant !== "concept" || !node.data || !node.data.key)
    return false;
  return true;
}
