import { formatting, display } from 'core/config/contractDefaults';
import { ptToEm } from 'core/utils/general';
import { cx, css } from 'emotion';

const { textColor } = formatting;
const { classes: defaultClasses } = display;

export default function customClass(editor, props, settings) {
  const classes = [
    standardListClasses(settings),
    scheduleListClasses(settings),
    headers(settings),
    fontSizeClass(settings, formatting),
    contractStyle(settings),
  ];
  // classes.join("")

  const initialClasses = Object.keys(defaultClasses)
    .filter((viewClass) => defaultClasses[viewClass])
    .join(' ');

  return cx(
    initialClasses,
    'language-' + settings.language,
    props.templateEdit ? 'template-edit' : '',
    props.markup ? 'markup' : 'no-markup',
    props.noHeaderCounting ? 'no-header-counting' : 'header-counting',
    settings.format && settings.format.headers && settings.format.headers.headerLevelStartsAsText
      ? 'has-heading-clause-text'
      : '',
    'editor interactive-editor full-editor preview-editor',
    props.view ? 'only-view' : '',
    css`
      ${classes}
    `
  );
}

function contractStyle(settings) {
  return `
    color: ${(settings && settings.format && settings.format.textColor) || textColor}
  `;
}

function fontSizeClass(settings, formatting) {
  const defaultFontSize = formatting.fontSize || 12;
  const fontSize = settings?.format?.fontSize || defaultFontSize;

  const fontSizeDownscale = ptToEm(fontSize, defaultFontSize);
  const fontSizeUpscale = ptToEm(defaultFontSize, fontSize);

  // console.log('Font size is ', {fontSize, sfs: settings?.format?.fontSize, fmfs: formatting.fontSize, defaultFontSize, result: ptToEm(fontSize, defaultFontSize)})
  const sectionPadding = (formatting.sectionPadding || 4) * fontSizeUpscale;
  const sectionMinHeight = (formatting.sectionMinHeight || 42) * fontSizeUpscale;
  return `
    font-size: ${fontSizeDownscale}em;
    .editor-block.editor-section {
      min-height: ${sectionMinHeight}em;
      padding: 3em ${sectionPadding}em;
    }
  `;
}

function headers(settings) {
  if (!settings.format || !settings.format.headers) return '';
  let style = '';
  if (typeof settings.format.headers.headerLevelStartsAsText === 'number') {
    for (let i = settings.format.headers.headerLevelStartsAsText; i < 7; i++)
      style += `h${i} { 
        font-size: 1em;
        font-weight: normal;
      }`;
  }
  if (typeof settings.format.headers.sizes === 'object') {
    for (const level in settings.format.headers.sizes)
      style += `h${level} { 
        font-size: ${settings.format.headers.sizes[level] / 100}em;
      }`;
  }
  return style;
}

function standardListClasses(settings) {
  if (!settings || !settings.format || !settings.format.listFormat) return '';
  let stdPrefix = settings.format.listFormat.prefix || '';
  let stdSuffix = settings.format.listFormat.suffix || '';
  let stdContents;
  if (!Array.isArray(settings.format.listFormat.formats)) {
    stdContents = [
      { format: 'lower-alpha' },
      { format: 'lower-roman' },
      { format: 'upper-alpha' },
      { format: false },
    ];
  } else {
    stdContents = settings.format.listFormat.formats;
    for (let i = stdContents.length; i < 5; i++) stdContents.push({ format: false });
  }
  const contents = stdContents.map((item, index) => {
    const prefix = item.prefix || stdPrefix;
    const suffix = item.suffix || stdSuffix;
    let ols = '';
    for (let i = 0; i < index; i++) ols += ' div.block-list:not(.block-inactive) > ol';
    return `
      div.block-list:not(.block-inactive) > ol${ols} {
        counter-reset: list-level-${index + 1};
        list-style-type: none;
      }
      div.block-list:not(.block-inactive) > ol${ols} > div > div > div:not(.block-inactive):not(.list-item-hanging) > li:before {
        counter-increment: list-level-${index + 1};
        content: "${prefix}" counter(list-level-${index + 1}${
      item.format ? ', ' + item.format : ''
    }) "${suffix} ";
        margin-left: -2em;
        position: absolute;
      }
    `;
  });
  return contents.join('');
}

function scheduleListClasses(settings) {
  if (!settings || !settings.format || !settings.format.scheduleListFormat) return '';
  let stdPrefix = settings.format.scheduleListFormat.prefix || '';
  let stdSuffix = settings.format.scheduleListFormat.suffix || '';
  let stdContents;
  if (!Array.isArray(settings.format.scheduleListFormat.formats)) {
    stdContents = [
      { format: 'lower-alpha' },
      { format: 'lower-roman' },
      { format: 'upper-alpha' },
      { format: false },
    ];
  } else {
    stdContents = settings.format.scheduleListFormat.formats;
    for (let i = stdContents.length; i < 5; i++) stdContents.push({ format: false });
  }
  const contents = stdContents.map((item, index) => {
    const prefix = item.prefix !== undefined ? item.prefix : stdPrefix;
    const suffix = item.suffix !== undefined ? item.suffix : stdSuffix;
    let ols = '';
    for (let i = 0; i < index; i++) ols += ' div.block-list:not(.block-inactive) > ol';
    return `
      .editor-schedule div.block-list:not(.block-inactive) > ol${ols} > div > div > div:not(.block-inactive):not(.list-item-hanging) > li:before {
        counter-increment: list-level-${index + 1};
        content: "${prefix}" counter(list-level-${index + 1}${
      item.format ? ', ' + item.format : ''
    }) "${suffix} ";
        margin-left: -2em;
        position: absolute;
      }
    `;
  });
  return contents.join('');
}
