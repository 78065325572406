import {useEffect} from "react"
import api from "utils/api"

export function useMountFetch(url, setFunction, setErrorFunction) {
  const hasErrorFunction = typeof setErrorFunction === "function";
 
  useEffect(() => {
    if (!url || typeof setFunction !== "function") return;
    api
      .get(url)
      .then(function (res) {
        if (res.data) {
          setFunction(res.data);
        }
        else if (hasErrorFunction) {
          setErrorFunction("No projects available");
        }
      })
      .catch((err) => {
        if (hasErrorFunction) {
          if (err.response && err.response.status === 401) {
            setErrorFunction("Sign in to fetch resources");
          } else {
            setErrorFunction("No projects available at this time");
          }
        }
      });
  }, [url, setFunction, setErrorFunction, hasErrorFunction]);
}

export function useFetch(url, dependencies = [], setFunction, setErrorFunction) {
  const hasErrorFunction = typeof setErrorFunction === "function";
  useEffect(() => {
    if (typeof setFunction !== "function") return;
    if(!Array.isArray(dependencies)) return
    api
      .get(url)
      .then(function (res) {
        // console.log("fetch proj res ", res);
        if (res.data && res.data.length > 0) setFunction(res.data);
        else if (hasErrorFunction) {
          setErrorFunction("No projects available");
        }
      })
      .catch((err) => {
        if (hasErrorFunction) {
          if (err.response && err.response.status === 401) {
            setErrorFunction("Sign in to fetch resources");
          } else {
            setErrorFunction("No projects available at this time");
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}