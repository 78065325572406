import React from 'react';
import { Button, Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';
import { useContract, useEventState, setEventState } from 'hooks';
import { Create } from 'core/interfaces';
import IntlMessages from 'util/IntlMessages';
import { PlusOutlined } from '@ant-design/icons';

export const TemplateStudioSectionMenu = () => {
  const contract = useContract();
  const location = useLocation();
  const showEditorStructure = useEventState('ContractStructureModeSwitch');

  const currentPage = Create.getPageByUrl(contract.data.ui, location.pathname);

  const openInputSectionMenu = (type, method, selected = null, disableModal = false) => {
    setEventState('template_edit_input_sections', { type, method, selected, disableModal });
  };

  if (showEditorStructure) return null;

  return (
    <>
      <div className="template-topbar-add-section">
        <Tooltip title={<IntlMessages id="studio.template.modal.page.add" />} placement="bottom">
          <Button
            shape="circle"
            className="m-0"
            icon={<PlusOutlined />}
            onClick={() => openInputSectionMenu('page', 'add')}
          />
        </Tooltip>

        {currentPage && currentPage.collapse && (
          <Tooltip title={<IntlMessages id="studio.template.modal.page.addChild" />} placement="bottom">
            <Button
              shape="circle"
              size="small"
              className="m-0"
              icon={<i className="mdi mdi-library-plus" />}
              onClick={() => openInputSectionMenu('page', 'add', { addChildPage: true })}
            />
          </Tooltip>
        )}
      </div>

      {/*  <span>
          <Dropdown
            overlayStyle={{
              minWidth: '160px',
            }}
            overlay={
              <Menu>
                <MenuHeader intl="studio.template.allPages" />
                <Menu.Item onClick={() => openInputSectionMenu('page', 'add')}>
                  <span>
                    <IntlMessages id="studio.template.modal.page.add" />
                  </span>
                </Menu.Item>
                <Menu.Item onClick={() => openInputSectionMenu('page', 'arrange')}>
                  <span>
                    <IntlMessages id="studio.template.modal.page.arrange" />
                  </span>
                </Menu.Item>
                <Menu.Divider />
                <MenuHeader intl="studio.template.thisPage" />
                {currentPage && currentPage.collapse && (
                  <Menu.Item onClick={() => openInputSectionMenu('page', 'add', { addChildPage: true })}>
                    <span>
                      <IntlMessages id="studio.template.modal.page.addChild" />
                    </span>
                  </Menu.Item>
                )}
                <Menu.Item onClick={() => openInputSectionMenu('page', 'edit', { editPage: true })}>
                  <span>
                    <IntlMessages id="studio.template.modal.page.edit" />
                  </span>
                </Menu.Item>
                <Menu.Item onClick={() => openInputSectionMenu('page', 'remove', null, true)}>
                  <span>
                    <IntlMessages id="studio.template.modal.page.remove" />
                  </span>
                </Menu.Item>
                <Menu.Divider />
                <MenuHeader intl="studio.template.pageBoxes" />
                <Menu.Item onClick={() => openInputSectionMenu('card', 'add')}>
                  <span>
                    <IntlMessages id="studio.template.modal.card.add" />
                  </span>
                </Menu.Item>
                <Menu.Item onClick={() => openInputSectionMenu('card', 'arrange')}>
                  <span>
                    <IntlMessages id="studio.template.modal.card.arrange" />
                  </span>
                </Menu.Item>
              </Menu>
            }
          >
            <Button shape="circle" icon={<EditOutlined />} />
            <span className="link ant-dropdown-link">
              <IntlMessages id="studio.template.pageActions" /> <DownOutlined />
            </span>
          </Dropdown>
        </span> */}
    </>
  );
};
