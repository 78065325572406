import React, { useState } from "react";
import { useIsSidebarMini } from "hooks";
import CustomScrollbars from "util/CustomScrollbars";

import { Tabs, Menu, Dropdown } from "antd";
const TabPane = Tabs.TabPane;

export default function SidebarContainer({ tabFunction, tabArguments }) {
  const [activeKey, setActiveKey] = useState("0");
  const isMini = useIsSidebarMini()

  const tabs = tabFunction({ ...tabArguments, activeKey });

  const onChangeTab = (key) => {
    const [, tabContent, tabOptions] = tabs[key]
    if (!tabContent) {
      if (tabOptions && typeof tabOptions.onClick === "function")
        tabOptions.onClick()
      return
    }
    setActiveKey(key);
  };

  const dropdownMenu = isMini && (
    <Menu>
      {tabs.map(([title], index) => (
        <Menu.Item key={index} onClick={() => setActiveKey(index.toString())}>
          {title}
        </Menu.Item>
      ))}
    </Menu>
  );

  const MenuIcon = () => {
    return isMini ? (
      <div className="flex-column align-items-center border-bottom">
        <Dropdown overlay={dropdownMenu} trigger={["click"]}>
          <i className="icon-btn icon icon-menu"></i>
        </Dropdown>
      </div>
    ) : null;
  };

  

  return (
    <div
      className={
        "draft-menu-tab-container size" +
        (isMini?'mini':'full') +
        " contract-mode editor-mode-input"
      }
    >
      <MenuIcon />
      {isMini ? (
        <CustomScrollbars className="layout-sider-scrollbar">
          {tabs[activeKey][1]}
        </CustomScrollbars>
      ) : (
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          className="draft-menu-tabs"
          onChange={onChangeTab}
          // tabPosition="left"
        >
          {tabs.map(([title, content, options = {}], index) => (
            <TabPane tab={title} key={index.toString()}>
              <CustomScrollbars className="layout-sider-scrollbar">
                <div
                  className={
                    options.hasOwnProperty("holderClassName")
                      ? options.holderClassName
                      : "p-2"
                  }
                >
                  {content}
                </div>
              </CustomScrollbars>
            </TabPane>
          ))}
        </Tabs>
      )}
    </div>
  );
}
