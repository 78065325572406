import React, { useState, useCallback, useEffect, useMemo, createRef } from 'react';
import { Editor, Transforms, Text, Node, Path } from 'slate';
import { useSlate, useEditor } from 'slate-react';
import {
  Row,
  Col,
  Badge,
  Dropdown,
  Select,
  Menu,
  Input,
  Tooltip,
  Divider,
  Modal,
  Upload,
  notification,
} from 'antd';
import { IS_EXTERNAL } from 'config';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';

import { ptToEm, emToPt, lightOrDark } from 'core/utils/general';
import { formatting } from 'core/config/contractDefaults';

import { isList, isImage } from 'core/types/elements';

import { getNodeAllText, find } from 'core/engine/utils';
import uuid from 'uuid-random';

import { Button, Icon } from './components';
import ZoomComponent from './ZoomComponent';
import { isBlockActive, isMarkActive, hasFontSizes, emptyBlock } from '../helpers';

import { useSelector } from 'react-redux';
import { useDraft, setContract, useContract, useIsTemplateStudio } from 'hooks';
import { Contract } from 'core/interfaces';

import { RemoveModal } from 'components/ui';
import { useModalContent } from 'components/ui/modals';

import { insertTable } from '../plugins/slate-table/elements/table';
import images from 'core/data/images/';
import IntlMessages from 'util/IntlMessages';
import BlocksInlines from './BlocksInlines';
import api from 'utils/api';

const { Option } = Select;
const { TextArea } = Input;

const { textColor, fontFamilies, defaultFont, fontSize: defaultFontSize, fontSizes } = formatting;

export default function TopToolbar({ isTemplate }) {
  const [menu, setTheMenu] = useState('format');

  const setMenu = useCallback(
    (newMenu) => {
      if (newMenu === menu) return setTheMenu(null);
      setTheMenu(newMenu);
    },
    [menu, setTheMenu]
  );

  return (
    <div className={'editor-toolbar horizontal toolbar-menu' + (isTemplate ? ' template' : '')}>
      <MainMenu menu={menu} setMenu={setMenu} />
      <SubMenu menu={menu} setMenu={setMenu} />
    </div>
  );
}

const MainMenu = ({ menu, setMenu }) => {
  const isTemplate = useIsTemplateStudio();

  return (
    <div className="editor-toolbar-top">
      <div className="editor-toolbar-top-item">
        <MenuButton
          icon="mdi-format-line-style"
          label={<IntlMessages id="studio.toptoolbar.format" />}
          name="format"
          setMenu={setMenu}
          menu={menu}
        />
        <MenuButton
          icon="mdi-plus"
          label={<IntlMessages id="studio.toptoolbar.insert" />}
          name="insert"
          setMenu={setMenu}
          menu={menu}
        />
        <MenuButton
          icon="mdi-bookmark-plus-outline"
          label={<IntlMessages id="studio.toptoolbar.references" />}
          name="references"
          setMenu={setMenu}
          menu={menu}
        />
        <MenuButton
          icon="mdi-comment-outline"
          label={<IntlMessages id="studio.toptoolbar.comments" />}
          name="comments"
          setMenu={setMenu}
          menu={menu}
        />
        {!IS_EXTERNAL && (
          <MenuButton
            icon="mdi-comment-outline"
            label={<IntlMessages id="studio.toptoolbar.blocksInlines" />}
            name="blocks"
            setMenu={setMenu}
            menu={menu}
          />
        )}
      </div>
      <div className="editor-toolbar-top-item">
        <div className="btns-section">
          <CmdButton type="undo" icon="mdi-undo" tooltip="Undo" />
          <CmdButton type="redo" icon="mdi-redo" tooltip="Redo" />
        </div>
        {!isTemplate && <ZoomComponent />}
      </div>
    </div>
  );
};

const MenuButton = ({ setMenu, menu, name, label, icon }) => {
  return (
    <span
      onMouseDown={(event) => {
        event.preventDefault();
        setMenu(name);
      }}
      className={'toptoolbar-section clickable ' + (menu === name ? 'toptoolbar-active' : '')}
    >
      <div className="toolbar-menu-label">{label}</div>
    </span>
  );
};

const SubMenu = ({ menu, setMenu }) => {
  if (!menu) return null;
  return (
    <div className="editor-toolbar-horizontal-sub">
      {menu === 'insert' && <MenuInsert />}
      {menu === 'format' && <MenuFormat />}
      {menu === 'references' && <MenuReferences />}
      {menu === 'comments' && <MenuComments />}
      {menu === 'blocks' && <MenuBlocks />}
    </div>
  );
};

const MenuFormat = () => {
  return (
    <div className="d-flex flex-direction-row">
      <div className="btns-section">
        <FontFamily />
      </div>
      <div className="btns-section">
        <MarkButton format="bold" icon="mdi-format-bold" tooltip="Bold" />
        <MarkButton format="italic" icon="mdi-format-italic" tooltip="Italic" />
        <MarkButton format="underlined" icon="mdi-format-underline" tooltip="Underlined" />
      </div>
      <div className="btns-section">
        <FontSizeButton />
      </div>
      <div className="btns-section">
        <ColorButton />
      </div>

      <div className="btns-section">
        <BlockButton format="paragraph" icon="mdi-format-paragraph" tooltip="Paragraph" />
        <BlockButton format="heading_one" icon="mdi-format-header-1" tooltip="Heading Level 1" />
        <BlockButton format="heading_two" icon="mdi-format-header-2" tooltip="Heading Level 2" />
        <BlockButton format="heading_three" icon="mdi-format-header-3" tooltip="Heading Level 3" />
        <BlockButton format="numbered_list" icon="mdi-format-list-numbers" tooltip="Numbered List" />
        <BlockButton format="bulleted_list" icon="mdi-format-list-bulleted" tooltip="Bulleted List" />
      </div>
      <div className="btns-section">
        <CmdButton
          type="dedent_block"
          icon="mdi-format-indent-decrease"
          tooltip="Decrease indentation"
          disableChecks={[isSelectedElementImage]}
        />
        <CmdButton
          type="indent_block"
          icon="mdi-format-indent-increase"
          tooltip="Increase indentation"
          disableChecks={[isSelectedElementImage]}
        />
      </div>

      <div className="btns-section">
        <CmdButton type="align" arg="left" icon="mdi-format-align-left" tooltip="Text Left" />
        <CmdButton type="align" arg="center" icon="mdi-format-align-center" tooltip="Text Centered" />
        <CmdButton type="align" arg="right" icon="mdi-format-align-right" tooltip="Text Right" />
        <CmdButton type="align" arg="justify" icon="mdi-format-align-justify" tooltip="Text Justified" />
      </div>
    </div>
  );
};

const FontFamily = () => {
  const contract = useContract();
  const settings = Contract.getSettings(contract);
  const editor = useSlate();

  const [font, setFont] = useState((settings && settings.format && settings.format.fontFace) || defaultFont);

  const changeFont = useCallback(
    (value) => {
      settings.format.fontFace = value;
      const editorElement = document.getElementById('editor-holder');
      if (editorElement) editorElement.style.fontFamily = fontFamilies[value].css;
      setFont(value);
    },
    [settings]
  );

  return (
    <Select
      type="select"
      onChange={changeFont}
      disabled={isFontDisabled(editor)}
      value={font}
      style={{ fontSize: '12px', width: '90px' }}
      className="select-border-0"
    >
      {Object.keys(fontFamilies).map((ff, index) => (
        <Option key={'f' + index} value={ff}>
          {ff}
        </Option>
      ))}
    </Select>
  );
};

const FontSizeButton = () => {
  const editor = useSlate();
  const contract = useContract();
  const { fontSize: contractFontSize } = Contract.getFormat(contract);

  const fontSize = contractFontSize || defaultFontSize || '12';

  const setSize = useCallback(
    (size) => {
      const em = ptToEm(size, fontSize);
      setMark(editor, 'size', em);
    },
    [editor, fontSize]
  );

  let currentSize;
  let sizeInfo = hasFontSizes(editor);
  if (sizeInfo === 'default') currentSize = fontSize;
  else if (sizeInfo === 'multiple') currentSize = 'Mixed Sizes';
  else if (!isNaN(sizeInfo)) currentSize = emToPt(sizeInfo, fontSize);

  return (
    <Select
      type="select"
      onChange={setSize}
      disabled={isFontDisabled(editor)}
      value={currentSize}
      style={{ fontSize: '12px', width: '75px' }}
      className="select-border-0"
    >
      {fontSizes.map((size, index) => (
        <Option key={'f' + index} value={size}>
          {size}
        </Option>
      ))}
    </Select>
  );
};

const colors = ['#000', '#67757c', '#043B62', '#0080BB', '#FFFFFF', '#1F497D'];

const ColorButton = () => {
  const editor = useSlate();
  const contract = useContract();
  const settings = Contract.getSettings(contract);

  let hasColor;
  const [...activeColors] = Editor.nodes(editor, {
    match: (node) => Text.isText(node),
    mode: 'lowest',
  });

  if (activeColors.length === 1) hasColor = activeColors[0][0].color;
  else if (activeColors.length > 1) {
    hasColor = activeColors[0][0].color;
    for (let [tmpColorNode] of activeColors) {
      if (tmpColorNode.color !== hasColor) {
        hasColor = null;
        break;
      }
    }
  }

  if (!hasColor) {
    hasColor = (settings && settings.format && settings.format.textColor) || textColor || null;
  }
  if (hasColor && !colors.includes(hasColor)) colors.push(hasColor);

  const menu = (
    <div className="p-3 menu-div">
      {colors.map((color, index) => (
        <Button
          key={'k' + index}
          className={'font-color-btn border-0' + (hasColor === color ? ' active' : '')}
          onMouseDown={(event) => {
            event.preventDefault();
            setMark(editor, 'color', color);
          }}
          style={{ backgroundColor: color }}
        >
          {hasColor === color ? (
            <i className={'mdi mdi-check active-font-icon ' + lightOrDark(color)} />
          ) : (
            ' '
          )}
        </Button>
      ))}
    </div>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} disabled={isFontDisabled(editor)}>
      <Button className="border-0">
        <Icon className="editor-color-btn" style={{ color: hasColor || '#000' }}>
          mdi-format-color-text
        </Icon>
        {/* <AntIcon type="down" style={{ color: "rgba(0, 0, 0, 0.25)", fontSize: "14px" }} /> */}
        <DownOutlined style={{ color: 'rgba(0, 0, 0, 0.25)', fontSize: '14px' }} />
      </Button>
    </Dropdown>
  );
  /* 
  return (
    <>
      <ButtonDropdown isOpen={open} toggle={toggleOpen}>
        <DropdownToggle
          caret
          size="sm"
          color="secondary"
          outline
          style={{ backgroundColor: hasColor || "" }}
        >
          <Icon
            className="editor-color-btn"
            style={{ color: oppositeColor || "" }}
          >
            mdi-format-color-text
          </Icon>
        </DropdownToggle>
        <DropdownMenu className="editor-color-dropdown">
          {colors.map((color, index) => (
            <Button
              key={"k" + index}
              className={hasColor === color ? "active" : ""}
              onMouseDown={event => {
                event.preventDefault();
                setMark(editor, "color", color);
              }}
              outline
              color="secondary"
              style={{ backgroundColor: color }}
            ></Button>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
      
    </>
  );
   */
};

const MenuInsert = () => {
  const [visible, setVisible] = useState(false);
  const onVisibleChange = (value) => {
    setVisible(value);
  };
  return (
    <>
      <div className="btns-section">
        <Dropdown
          overlay={<InsertTable onVisibleChange={onVisibleChange} />}
          visible={visible}
          onVisibleChange={onVisibleChange}
        >
          <Button>
            <i className="mdi mdi-table mr-1" />
            <small>
              <IntlMessages id="studio.toptoolbar.insert.table" />
            </small>
            <DownOutlined style={{ color: 'rgba(0, 0, 0, 0.25)', fontSize: '14px' }} />
          </Button>
        </Dropdown>
        <InsertImageButton />
      </div>
    </>
  );
};

const MenuBlocks = () => {
  const [visible, setVisible] = useState(false);
  const onVisibleChange = (value) => {
    setVisible(value);
  };
  return <BlocksInlines />;
};

export const InsertImageButton = ({
  currentImageId,
  isChange = false,
  onSelectImage: parentOnSelectImage,
  className = '',
}) => {
  const editor = useEditor();
  const [Modal, toggleModal, , isOpen] = useModalContent(
    <IntlMessages id="studio.toptoolbar.insert.image" />
  );

  if (!editor.selection || !editor.selection.anchor) {
    notification.info({
      message: 'Make selection',
      description: 'Place the cursor where the image is to be inserted',
    });
    return null;
  }

  const onSelectImage = (selected) => {
    if (typeof parentOnSelectImage === 'function') {
      parentOnSelectImage(selected);
    } else {
      editor.insertImage(selected);
    }
    toggleModal();
  };

  return (
    <>
      <Button onClick={toggleModal} className={className}>
        <i className="mdi mdi-image mr-1" />
        <small>
          <IntlMessages id={`studio.toptoolbar.${isChange ? 'change' : 'insert'}.image`} />
        </small>
      </Button>
      {isOpen && (
        <Modal>
          <ImageSelectionContent
            currentImageId={currentImageId}
            onSelectImage={onSelectImage}
            isChange={isChange}
          />
        </Modal>
      )}
    </>
  );
};

export const ShowSelectedImage = ({ element, width, height }) => {
  if (!element.data || !element.data.imgData) {
    return <div>No image</div>;
  }

  return (
    <img
      src={element.data.imgData}
      alt={'ContractImage'}
      style={{
        width,
        height,
      }}
    />
  );
};

export const ImageSelectionContent = ({ currentImageId, onSelectImage, isChange, actionText = 'insert' }) => {
  const editor = useEditor();
  const user = useSelector((state) => state.auth.user);
  const [backendImages, setBackendImages] = useState([]);
  const [selected, setSelected] = useState(currentImageId || null);
  const [alignment, setAlignment] = useState('left');
  const toggleSelected = (id) => {
    if (selected === id) return setSelected(null);
    setSelected(id);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const files = await api.get(
          `/backendfiles?resourceType=Client&resourceId=${user.clientId}&where=type_->_image`
        );
        if (!files || !files.data) return;
        setBackendImages(
          files.data.filter((img) => {
            if (!img.data.base64 || !img.data.dataPrefix) return false;
            return true;
          })
        );
      } catch (err) {
        console.log('Cannot fetch client files');
      }
    }
    fetch();
  }, [user.clientId]);

  const insertImage = () => {
    if (!selected) {
      return notification.info({
        message: 'Select an image to be inserted',
      });
    }

    const backendImage = backendImages.find((img) => img.id === selected);

    if (!backendImage) {
      console.log('Cannot find selected amongst images... ', { selected, backendImages });
      return notification.info({
        message: 'Select an image to be inserted',
      });
    }

    onSelectImage({
      id: selected,
      alignment,
      imgData: backendImage && backendImage.data.dataPrefix + backendImage.data.base64,
      data: backendImage.data.extra,
    });
  };

  const removeImage = async (id) => {
    try {
      const deleted = await api.delete(`/backendfiles/${id}`);
      if (deleted.data.status === 'OK') {
        setBackendImages(backendImages.filter((image) => image.id !== id));
      }
    } catch (err) {
      console.log('Cannot remove image ', err);
    }
  };

  const addImageToLibrary = (file) => {
    if (file) setBackendImages([...backendImages, file]);
  };

  return (
    <>
      <Row className="editor-insert-image-select-row">
        <Divider orientation="left" className="mb-2">
          Image Library
        </Divider>
        {images.map((image) => (
          <Col className="p-0 m-2" key={image.id} span={7}>
            <ImageItem selected={selected === image.id}>
              <div
                className={'p-3 image-selector ' + (selected === image.id ? 'selected' : '')}
                onClick={() => toggleSelected(image.id)}
              >
                <img src={image.image} alt={image.description || image.id || 'Image'} />
              </div>
            </ImageItem>
            <small style={{ display: 'block', textAlign: 'center' }}>
              {image.description || 'Untitled image'}
            </small>
          </Col>
        ))}
        {backendImages &&
          backendImages.map((image) => (
            <ImageItem
              key={image.id}
              image={image}
              selected={selected}
              removeImage={removeImage}
              toggleSelected={toggleSelected}
            />
          ))}
        <Col className="p-0 pl-3 m-2" span={7}>
          <UploadImages addImageToLibrary={addImageToLibrary} />
        </Col>
      </Row>
      <Row className="editor-insert-image-alignment-row">
        <Divider orientation="left" className="mb-2">
          Image Alignment
        </Divider>
        {/* <Col span={24}>Alignment</Col> */}
        <Col className={alignment === 'left' ? 'selected' : ''} span={8} onClick={() => setAlignment('left')}>
          <div className="align-div">Left</div>
        </Col>
        <Col
          className={alignment === 'center' ? 'selected' : ''}
          span={8}
          onClick={() => setAlignment('center')}
        >
          <div className="align-div">Center</div>
        </Col>
        <Col
          className={alignment === 'right' ? 'selected' : ''}
          span={8}
          onClick={() => setAlignment('right')}
        >
          <div className="align-div">Right</div>
        </Col>
      </Row>
      <Row className="editor-insert-image-button-row">
        <Button
          type="primary"
          className="editor-insert-image-button"
          disabled={!selected}
          onClick={insertImage}
        >
          <IntlMessages id={`studio.toptoolbar.${isChange ? 'change' : 'insert'}.image`} />
        </Button>
      </Row>
    </>
  );
};

const ImageItem = ({ image, selected, removeImage, toggleSelected }) => {
  const imageSelected = selected === image.id;

  const imageView = (
    <div
      className={'p-3 image-selector ' + (imageSelected ? 'selected' : '')}
      onClick={() => toggleSelected(image.id)}
    >
      <img
        src={image.data.dataPrefix + image.data.base64}
        alt={image.description || image.name || image.id || 'Image'}
      />
    </div>
  );

  const withBadgeIfSelected = imageSelected ? (
    <Badge className="m-0" count={<i className="mdi mdi-check-circle" style={{ color: '#52c41a' }} />}>
      {imageView}
    </Badge>
  ) : (
    imageView
  );

  return (
    <Col className="p-0 m-2" span={7}>
      {withBadgeIfSelected}
      <div>
        <small style={{ display: 'block', textAlign: 'center' }}>{image.name || 'No name'}</small>
      </div>
      <div>
        <small style={{ display: 'block', textAlign: 'center' }}>
          {image.description || 'No description'}
        </small>
      </div>
      <div>
        <RemoveModal
          onConfirm={() => removeImage(image.id)}
          confirmText={
            <>
              <IntlMessages id="app.general.confirmRemoval" /> <IntlMessages id="app.resources.Image" />?
            </>
          }
        >
          <small style={{ display: 'block', textAlign: 'center' }} className="link">
            <IntlMessages id="desc.Remove" /> <IntlMessages id="app.resources.Image" />
          </small>
        </RemoveModal>
      </div>
    </Col>
  );
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const imageMimeTypes = ['image/png', 'image/jpeg', 'image/gif'];

const UploadImages = ({ addImageToLibrary }) => {
  const [uploadError, setUploadError] = useState(null);
  const uploadFn = (props) => {
    const { file, onSuccess, onError } = props;

    async function upload() {
      const base64 = await getBase64(file);
      console.log('file is ', file);
      let fileUpload;
      try {
        fileUpload = await api.post('/backendfiles', {
          resourceType: 'Client',
          resourceId: '51672ad1-97c1-428a-8b12-8bb9726c8503',
          name: file.name,
          description: '',
          format: 'base64',
          data: base64,
        });
        console.log('File up res', fileUpload);
        addImageToLibrary(fileUpload.data);
        onSuccess({ status: 200 }, file);
      } catch (err) {
        setUploadError('Could not upload file - please try again later.');
        onError('Upload failed', fileUpload, file);
      }
    }
    upload();
  };

  const beforeUpload = (file) => {
    const isImage = imageMimeTypes.includes(file.type);
    if (!isImage) {
      notification.warn({
        message: 'Only images are supported',
      });
    }
    return isImage;
  };

  return (
    <>
      <Upload
        listType="picture-card"
        customRequest={uploadFn}
        beforeUpload={beforeUpload}
        showUploadList={false}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
      {uploadError}
    </>
  );
};

const InsertTable = ({ onVisibleChange }) => {
  const editor = useSlate();

  const [size, setSize] = useState([8, 8]);
  const [selected, setSelected] = useState([0, 0]);

  const addTable = (rows, cols) => {
    insertTable(editor, [rows + 1, cols + 1]);
  };
  const createTable = () => {
    let table = [];

    for (let i = 0; i < size[0]; i++) {
      let children = [];
      for (let j = 0; j < size[1]; j++) {
        children.push(
          <div
            className={'insert-table-cell ' + (i <= selected[0] && j <= selected[1] ? 'active' : '')}
            key={'cell' + i + j}
            data-row={i}
            data-cell={j}
            onMouseEnter={() => {
              setSelected([i, j]);
              // Resize rows and cols if at max
              if (i + 1 >= size[0] && j + 1 >= size[1]) setSize([size[0] + 1, size[1] + 1]);
              else if (i + 1 >= size[0]) setSize([size[0] + 1, size[1]]);
              else if (j + 1 >= size[1]) setSize([size[0], size[1] + 1]);
            }}
            onMouseDown={() => {
              addTable(j, i);
              onVisibleChange(false);
            }}
          >
            <div>x</div>
          </div>
        );
      }
      table.push(
        <div key={'row' + i} className="insert-table-row">
          {children}
        </div>
      );
    }
    return table;
  };

  return (
    <Menu>
      <div
        className="insert-table-holder"
        onMouseLeave={() => {
          setSize([8, 8]);
          setSelected([0, 0]);
        }}
      >
        <div className="insert-table-selector">{createTable()}</div>
      </div>
    </Menu>
  );
};

const MenuReferences = () => {
  const draft = useDraft();
  return (
    <>
      <div className="btns-section">
        <CmdButton
          type="updateReferences"
          arg={draft}
          icon="mdi-bookmark-plus-outline"
          tooltip="Update cross references"
        />
        <InsertReferenceButton />
        <RemoveReferenceButton />
      </div>
    </>
  );
};

const RemoveReferenceButton = () => {
  const editor = useEditor();
  const removeLink = () => {
    const link = Array.from(Editor.nodes(editor, { match: (n) => n.variant === 'ref' }));
    if (!link || link.length < 1) return;
    Transforms.removeNodes(editor, { match: (n) => n.variant === 'ref' });
  };
  return (
    <TipBtn title={'Remove Reference'}>
      <Button
        id={'tt_del_ref_btn'}
        onMouseDown={(event) => {
          event.preventDefault();
          removeLink();
        }}
      >
        <Icon>mdi-close</Icon>
      </Button>
    </TipBtn>
  );
};

const InsertReferenceButton = () => {
  const editor = useEditor();

  const [Modal, toggleModal, setModal, isOpen] = useModalContent('Insert Reference');

  // Function for inserting a reference to a selected target
  // `node` and `path` arguments refer to target node
  const insertRef = useCallback(
    (node, path) => {
      if (!editor.selection) {
        return console.log('No editor selection to insert into');
      }

      // Determine if the target has a `clause` as direct parent,
      // if so, we want to add substitute the target to that clause
      const parentPath = Path.parent(path);
      try {
        const parentNode = Node.get(editor, parentPath);
        console.log('Parent Node is ', parentNode, parentPath, ' of ', node, path);
      } catch (err) {}

      const hasBookmarks = node.data && Array.isArray(node.data.bookmarks) && node.data.bookmarks.length > 0;

      const id = hasBookmarks ? node.data.bookmarks[0] : uuid();

      const ref = {
        type: 'field',
        children: [
          {
            text: '[**]',
          },
        ],
        data: {
          name: id,
          target_item_id: node.data?.item_id || 'none',
          template_id: '000_inserted_ref',
          item_id: uuid(),
        },
        variant: 'ref',
      };
      const data = node.data ? { ...node.data } : { template_id: '000_tmp' + node.type, item_id: uuid() };

      if (!hasBookmarks) data.bookmarks = [id];

      Transforms.setNodes(editor, { data }, { at: path });
      Transforms.insertNodes(editor, ref, { at: editor.selection.anchor });
      /* setTimeout(() => {
      editor.cmd_updateReferences(draft);
    }, 10); */
      setModal(false);
    },
    [editor, setModal]
  );

  const targets = useMemo(() => {
    if (!isOpen) return null;
    const content = [];
    let headCount = [0];
    let listCount = 0;
    let scheduleCount = [0];
    let lastPath;

    const idLessElements = Array.from(
      Editor.nodes(editor, {
        match: (n) => !n.hasOwnProperty('text') && !n.data?.item_id,
        at: [],
      })
    );
    if (idLessElements.length > 0) {
      Editor.withoutNormalizing(editor, () => {
        for (const [node, path] of idLessElements) {
          if (path.length === 0) continue;
          if (!node.data) Transforms.setNodes(editor, { data: { item_id: uuid() } }, { at: path });
          else Transforms.setNodes(editor, { data: { ...node.data, item_id: uuid() } }, { at: path });
        }
      });
    }

    for (const [node, path] of Editor.nodes(editor, {
      match: (n) =>
        (n.type === 'clause' ||
          n.type === 'list_item' ||
          n.type === 'schedule_one' ||
          n.type === 'schedule_two') &&
        (!n.data || !n.data._inActive),
      at: [],
    })) {
      if (node.type === 'list_item') {
        if (!node.data?.item_id) {
          console.log(node, path, 'missing item_id');
          continue;
        }

        if (!lastPath || lastPath.slice(0, -1).join('.') !== path.slice(0, -1).join('.')) listCount = 1;
        else listCount += 1;

        lastPath = path;

        content.push(
          <div
            className={'clickable insert_ref insert_ref_list_item' + (listCount === 1 ? ' border-top' : '')}
            key={node.data.item_id}
            onMouseDown={(evt) => {
              evt.preventDefault();
              insertRef(node, path);
            }}
          >
            {listCount + '. '}
            {getNodeAllText(node)}
          </div>
        );
      }
      if (node.type === 'clause') {
        const headings = find(node, (n) => ['heading_one', 'heading_two', 'heading_three'].includes(n.type));
        if (headings.length === 0) continue;
        const heading = headings[0];
        let headingText = getNodeAllText(heading);
        let headingNum = 'X.';

        if (heading.type === 'heading_one') {
          headCount[0] += 1;
          headCount = headCount.slice(0, 1);
        }
        if (heading.type === 'heading_two') {
          headCount[1] !== undefined ? (headCount[1] += 1) : (headCount[1] = 1);
          headCount = headCount.slice(0, 2);
        }
        if (heading.type === 'heading_three')
          headCount[2] !== undefined ? (headCount[2] += 1) : (headCount[2] = 1);

        headingNum = headCount.join('.') + '  ';

        content.push(
          <div
            className={'clickable insert_ref insert_ref_heading insert_ref_' + heading.type}
            onMouseDown={(evt) => {
              evt.preventDefault();
              insertRef(node, path);
            }}
            key={node.data.item_id || node.data.template_id}
          >
            {headingNum}
            {headingText}
          </div>
        );
      }
      if (node.type === 'schedule_one' || node.type === 'schedule_two') {
        let scheduleText = getNodeAllText(node);
        if (node.type === 'schedule_one') {
          scheduleCount[0] += 1;
          scheduleCount = scheduleCount.slice(0, 1);
        }
        if (node.type === 'schedule_two') {
          scheduleCount[1] !== undefined ? (scheduleCount[1] += 1) : (scheduleCount[1] = 1);
          scheduleCount = scheduleCount.slice(0, 2);
        }
        content.push(
          <div
            className={'clickable insert_ref insert_ref_heading insert_ref_' + node.type}
            onMouseDown={(evt) => {
              evt.preventDefault();
              insertRef(node, path);
            }}
            key={node.data.item_id || node.data.template_id}
          >
            {node.type === 'schedule_one' ? (
              <span>
                Schedule {scheduleCount.join('.')}: {scheduleText}
              </span>
            ) : (
              <small className="ml-2">
                Schedule {scheduleCount.join('.')}: {scheduleText}
              </small>
            )}
          </div>
        );
      }
      // console.log('Node BM is ', node)
    }
    // content.push(<div>hej</div>)
    return content;
  }, [isOpen, editor, insertRef]);

  return (
    <TipBtn title={'Insert Reference'}>
      <Button
        id={'tt_ins_ref_btn'}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
          toggleModal();
        }}
      >
        <Icon>mdi-arrow-top-right</Icon>
        <Modal bodyClassName="p-4">{targets}</Modal>
      </Button>
    </TipBtn>
  );
};

const MenuComments = () => {
  return (
    <>
      <div className="btns-section">
        <AddCommentButton />
        <RemoveAllCommentsButton />
      </div>
    </>
  );
};

const AddCommentButton = () => {
  const editor = useSlate();
  const contract = useContract();
  const { user } = useSelector((state) => state.auth);

  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const inputRef = createRef();

  const openComment = () => {
    if (open === false && !editor.selection)
      return notification.warn({
        message: 'Select contract text to comment',
      });
    setOpen(!open);
  };
  const onChange = (e) => setText(e.target.value);

  const saveComment = () => {
    if (!text)
      return notification.warn({
        message: 'Add a comment text',
      });
    if (!editor.selection)
      return notification.warn({
        message: 'Select contract text to comment',
      });
    if (!contract.data.create) contract.data.create = {};
    if (!contract.data.create.comments) contract.data.create.comments = {};
    const id = uuid();
    contract.data.create.comments[id] = {
      posts: [{ user, text, time: new Date(Date.now()).toISOString() }],
    };
    setContract({ ...contract });

    const existingMarks = Editor.marks(editor);
    if (existingMarks?.comments) {
      Transforms.setNodes(
        editor,
        (node) => {
          return {
            comments: (node.comments || []).concat(id),
          };
        },
        { match: Text.isText, split: true }
      );
    } else {
      Editor.addMark(editor, 'comments', [id]);
    }

    setText('');
    setOpen(false);
  };

  const closeComment = () => {
    setText('');
    setOpen(false);
  };

  useEffect(() => {
    if (!open || !inputRef || !inputRef.current) return;
    inputRef.current.focus();
  }, [open, inputRef]);

  return (
    <div className="position-relative d-inline-block">
      <TipBtn title={<IntlMessages id="studio.comments.addComment" />} ignore={open}>
        <Button
          onMouseDown={(event) => {
            event.preventDefault();
            openComment();
          }}
        >
          <Icon>mdi-comment-plus-outline</Icon>
        </Button>
      </TipBtn>
      {open && (
        <div className="editor-comment-box">
          <TextArea rows={4} value={text} allowClear onChange={onChange} ref={inputRef} />
          <div className="action-area">
            <Button onClick={closeComment} className="delete-comment">
              Close
            </Button>
            <Button onClick={saveComment} className="save-comment">
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const RemoveAllCommentsButton = () => {
  const editor = useSlate();
  const remove = () => {
    // Editor.removeMark(editor, "comments");
    Transforms.setNodes(
      editor,
      (node) => {
        console.log('remove comment for ', node);
        return {
          // comments: (node.comments || []).concat(id),
        };
      },
      { match: Text.isText, split: true }
    );
  };
  return (
    <TipBtn title={'Delete Selected'}>
      <Button
        onMouseDown={(event) => {
          event.preventDefault();
          remove();
        }}
      >
        <Icon>mdi-comment-remove-outline</Icon>
      </Button>
    </TipBtn>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const formatIsList = isList(format);
  const isImageSelected = isSelectedElementImage(editor);
  const { selection } = editor;
  if (isImageSelected) return console.log('Do not toggle image');

  Editor.withoutNormalizing(editor, () => {
    // Do not toggle clauses.
    const { node, path } = editor.getClosestBlock();
    if (format === 'paragraph' && node && node.type === 'clause' && selection?.anchor?.path) {
      console.log('Clause to paragraph?');
      // Edge case. If a text node is directly within a clause, then wrap the text node
      // in a paragraph. (Should also be handled by normalizing?)
      // FYI. `wrapNodes` without an 'at' argument will match on inlines (and if none, blocks).
      // Therefore we need to manually set the 'at' argument to the path of the selection (i.e. text/leaf node)
      if (Path.isParent(path, selection.anchor.path)) {
        return Transforms.wrapNodes(editor, emptyBlock(format, []), { at: selection.anchor.path });
      }
      // Otherwise, ignore the toggling?
      return;
    }

    if (node.type !== format) {
      Transforms.unwrapNodes(editor, {
        match: (n) => isList(n.type),
        split: true,
      });
    }

    const newBlockType = isActive ? 'paragraph' : formatIsList ? 'list_item' : format;

    Transforms.setNodes(editor, {
      type: newBlockType,
    });

    if (!isActive && formatIsList) {
      // Not currently a list (not isActive), which shall become a list.
      // Wrap the list_item in a numbered_list/bulleted_list (i.e. the format)
      const block = emptyBlock(format, []);
      Transforms.wrapNodes(editor, block);
    }
  });
};

const isMarkDisabled = (editor) => {
  return isSelectedElementImage(editor);
};
const isBlockDisabled = (editor) => {
  return isSelectedElementImage(editor);
};
const isFontDisabled = (editor) => {
  return isSelectedElementImage(editor);
};

const isSelectedElementImage = (editor) => {
  return isImage(editor.getClosestBlockNode());
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  const isImageSelected = isSelectedElementImage(editor);
  if (isImageSelected) return console.log('Do not toggle image');

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const setMark = (editor, key, value) => {
  Editor.addMark(editor, key, value);
};

export const BlockButton = ({ format, icon, tooltip, className }) => {
  const editor = useSlate();
  return (
    <TipBtn title={tooltip}>
      <Button
        className={className}
        id={'tt_' + format + '_' + icon}
        active={isBlockActive(editor, format)}
        disabled={isBlockDisabled(editor)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        <Icon>{icon}</Icon>
      </Button>
    </TipBtn>
  );
};

function TipBtn({ children, title, ignore = 'false' }) {
  if (!title || ignore) return children;
  return <Tooltip title={title}>{children}</Tooltip>;
}

export const MarkButton = ({ format, icon, tooltip, className }) => {
  const editor = useSlate();
  return (
    <TipBtn title={tooltip}>
      <Button
        className={className}
        id={'tt_' + format + '_' + icon}
        active={isMarkActive(editor, format)}
        disabled={isMarkDisabled(editor)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <Icon>{icon}</Icon>
      </Button>
    </TipBtn>
  );
};

const CmdButton = ({ type, arg, icon, iconColor, tooltip, disableChecks = [], className }) => {
  const editor = useSlate();

  const style = {};
  if (iconColor) style.color = iconColor;

  let disabled = false;
  disableChecks.forEach((disableCheck) => {
    if (disableCheck(editor)) disabled = true;
  });

  return (
    <TipBtn title={tooltip}>
      <Button
        className={className}
        id={'tt_' + type + '_' + icon}
        active={isCmdActive(editor, type, arg)}
        disabled={disabled || isCmdDisabled(editor, type, arg)}
        onMouseDown={(event) => {
          event.preventDefault();
          if (typeof editor['cmd_' + type] === 'function') editor['cmd_' + type](arg);
        }}
      >
        <Icon style={style}>{icon}</Icon>
      </Button>
    </TipBtn>
  );
};

const isCmdActive = (editor, type, arg) => {
  if (type === 'align') {
    if (!editor.selection) return false;
    const nodes = Editor.nodes(editor);
    for (let node of nodes) {
      if (node[0] === editor) continue;
      if (!editor.isBlock(node[0])) continue;
      if (node[0].data && node[0].data._format && node[0].data._format.textAlign === arg) return true;
      // if(!node[0].data || !node[0].data._format || node[0].data._format.textAlign !== arg) return false;
    }
    return false;
  }
  return false;
};

const isCmdDisabled = (editor, type, arg) => {
  if (type === 'undo') {
    if (!editor.history || !editor.history.undos || editor.history.undos.length === 0) return true;
    if (
      editor.history.undos.length === 1 &&
      editor.history.undos[0].length === 1 &&
      editor.history.undos[0][0].type === 'set_selection'
    )
      return true;
    return false;
  } else if (type === 'redo') {
    if (!editor.history || !editor.history.redos || editor.history.redos.length === 0) return true;
    return false;
  }

  return false;
};
