import React, { useCallback } from "react";
import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { SlateReactPresentation } from "slate-react-presentation";
import { RenderElements } from "../../../../elements";
import { RenderLeaf } from "../../../../marks";

export const InActiveClause = ({ element, editor }) => {
  const activateNode = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      try {
        const path = ReactEditor.findPath(editor, element);
        const data = { ...element.data, _inActive: false };
        Transforms.setNodes(editor, { data }, { at: path });
        console.log("activate for path ", path);
      } catch (err) {
        console.log("Err finding inactive node path");
      }
    },
    [editor, element]
  );

  const renderElement = useCallback(
    (props) => <RenderElements {...props} editor={editor} isInActiveRepresentation />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = useCallback((props) => <RenderLeaf {...props} editor={editor} />, []);

  let renderedBlock = makeActive(JSON.parse(JSON.stringify(element)));
  // console.log("rend block ", renderedBlock);

  return (
    <div className="inactive-block-view">
      <div
        className="inactive-block-activate-btn"
        onMouseDown={activateNode}
        style={{ zIndex: "1000" }}
        contentEditable={false}
      >
        Activate
      </div>
      <SlateReactPresentation
        value={[renderedBlock]} // [{ type: 'h1' children: [ ... ]}, ...]
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
    </div>
  );
};

function makeActive(elem) {
  if (elem.data && elem.data._inActive) elem.data._inActive = false;
  elem._isInActiveRepresentation = true;
  if (elem.children) elem.children.forEach((child) => makeActive(child));
  return elem;
}
