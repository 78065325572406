import { isBlock, isInline, isText, ensureProperChildren } from '../../../../types/elements';

export const eachesOnRepeatableAdd = {
  id: 'eachesOnRepeatableAdd',
  mode: 'single',
  time: -3,
  dependencies: { repeatableAdd: true },
  handler: function ({ state, handlerInvoked, entry, path, api }) {
    let repeatablesToUpdate = [];

    const { cardId: repeatable } = entry;

    const repeatables = api.interfaces.InputPaths.extractRepeatables(this.contract, path);
    if (!repeatable) return;
    repeatablesToUpdate.push(repeatable);

    /* if (window.debug)
      this.log('Add repeatable with id ', repeatable) */

    const dependingConceptIds = [];
    for (const concept of api.interfaces.Contract.getConcepts(this.contract)) {
      if (
        concept.id &&
        concept.inheritance &&
        concept.inheritance.inherit &&
        concept.inheritance.inherit.includes(repeatable)
      ) {
        dependingConceptIds.push(concept.stateId || concept.id);
      }
    }

    repeatablesToUpdate = repeatablesToUpdate.concat(dependingConceptIds);

    // Clear out duplicates.
    repeatablesToUpdate = [...new Set(repeatablesToUpdate)];
    this.log('Repeatables to update ', { repeatablesToUpdate });
    return (node, parents) => {
      if (node.data && node.data.each_repeatable && node.data.each_repeatable.repeatable && node.data.template) {
        if (repeatable === node.data.each_repeatable.repeatable) {
          const parentPaths = getRepeatableParents(parents);
          for (const parentPath of parentPaths) {
            if (!path.includes(parentPath)) {
              // We shall not add this repeatable to another parent repeatable...
              return;
            }
          }
          /* if (window.debug) {
            this.log('Add rep: ', {repeatable, entry})
          } */
          this.addRepeatableForNode({ node, state, repeatables, entry });
        }
      }
    };
  },
};

/**
 *
 * Parents is an array ordered backwards, i.e. with the closest the the node at index 0.
 *
 * Figure out which of a node's parent are repeatable.
 * Start by identifying the parents stipulating `data.each_repeatable` (being a repeatable container).
 * Store the indices of such containers in the `repeatableParents` object, e.g.
 * { 3: "facility" }
 *
 * Then check any occurences of parents with a `node.data._path` which has an index lower
 * than a container (to ensure that such node is the (grand)child of a repeatable container).
 *
 * If so, that parent (with node.data._path) is a valid repeatable, and we push that _path
 * to an array which eventually gets returned.
 *
 * @param {array}   parents All parents of a specific node.
 * @returns {array} An array of paths of parent repeatables.
 */
function getRepeatableParents(parents) {
  const repeatableParents = {};
  const repeatablePaths = [];
  function higherIndexIsRepeatable(testIndex) {
    for (let i = testIndex + 1; i < parents.length; i++) {
      if (repeatableParents[i]) return true;
    }
    return false;
  }

  for (let i = 0; i < parents.length; i++) {
    const parent = parents[i];
    if (parent.data && parent.data.each_repeatable && parent.data.each_repeatable.repeatable) {
      repeatableParents[i] = parent.data.each_repeatable.repeatable;
    }
  }
  for (let i = 0; i < parents.length; i++) {
    const parent = parents[i];
    if (parent.data && parent.data._path && higherIndexIsRepeatable(i)) {
      repeatablePaths.push(parent.data._path);
    }
  }
  return repeatablePaths;
}

export const addRepeatableForNode = function (props) {
  const { node, ...rest } = props;

  clearPotentialPlaceholder(node);

  const toInsert = this.getEachInsert({
    node,
    children: JSON.parse(node.data.template),
    ...rest,
  });

  if (!toInsert || !Array.isArray(toInsert)) return;

  node.children = ensureProperChildren(node.children.concat(toInsert));
  this.api.utils.engine.itemJoiner(this, node);
};

export const getEachInsert = function (props) {
  const { node, children, state, entry } = props;
  const { repeatable, filter } = node.data.each_repeatable;
  const { value: values } = entry;
  let { _uid } = values;
  let newChildren = [];

  if (filter) {
    const passed = this.applyLogic(filter, {
      local: values,
    });
    this.log('Passed is: ', passed);
    if (!passed) return;
  }

  // Ensure that children is wrapped (if necessary), so that the each content
  // is contained in a single block or inline.
  if (isText(children[0]) || (children.length > 0 && isInline(children[0]))) {
    newChildren = [
      {
        type: 'field',
        variant: 'container',
        data: {
          template_id: 'std_inline_container',
          item_id: this.api.utils.general.uuid(),
        },
        children,
      },
    ];
  } else if (children.length > 1 && isBlock(children[0])) {
    newChildren = [
      {
        type: 'clause',
        data: {
          template_id: 'std_clause_container',
          item_id: this.api.utils.general.uuid(),
        },
        children,
      },
    ];
  } else {
    newChildren = children;
  }

  this.api.utils.engine.uniqueItemIds(newChildren);

  const childLabelId = node.data.each_repeatable.eachLabel
    ? 'el_' + node.data.each_repeatable.eachLabel + '_' + _uid
    : false;

  newChildren.forEach((child) => {
    if (isText(child)) return;
    if (!child.data) child.data = {};
    child.data._path = entry.path;
    child.data._each_repeatable_name = repeatable;
    child.data._each_uid = _uid;

    if (childLabelId) child.data._each_label_id = childLabelId;

    if (node.data.each_repeatable.makeBookmark) {
      if (!child.data.bookmarks) child.data.bookmarks = [];
      child.data.bookmarks.push((node.data.each_repeatable.bookmarkPrefix || '') + _uid);
    }
  });

  this.populateEachContent(repeatable, newChildren, values, state, entry);

  return newChildren;
};

function clearPotentialPlaceholder(node) {
  if (
    !node ||
    !Array.isArray(node.children) ||
    node.children.length !== 1 ||
    !node.children[0].data ||
    !node.children[0].data.is_each_placeholder
  )
    return;
  node.children = [];
  return;
}
