import api from 'utils/api';
import { sha256 } from 'js-sha256';
import manager from 'core/engine/manager';
import { getStore } from 'appRedux/store';
import { getContractValues, getIsTemplateStudio } from 'hooks';
import { Contract } from 'core/interfaces';

function finalTemplateInputState(input, contract) {
  const newInputState = JSON.parse(JSON.stringify(input));

  for (const cardId in newInputState) {
    const repeatableInfo = Contract.getRepeatableInfo(contract, cardId);

    let cardDefaultState;
    if (repeatableInfo) {
      cardDefaultState = newInputState[cardId][Object.keys(newInputState[cardId])[0]];
    } else {
      cardDefaultState = newInputState[cardId];
    }
    if (!contract.data.ui.cards[cardId]) continue;

    contract.data.ui.cards[cardId].values = cardDefaultState;

    if (!repeatableInfo) continue;
    const { defaultNumber } = repeatableInfo;
    const repeatableState = newInputState[cardId];
    const repeatableStateUid = Object.keys(repeatableState);
    const stateLength = repeatableStateUid.length;

    if (stateLength > defaultNumber) {
      for (let i = stateLength - 1; i > defaultNumber - 1; i--) {
        delete repeatableState[repeatableStateUid[i]];
      }
    }
  }

  return newInputState;
}

const save = function (draft, opts = {}) {
  return new Promise(function (resolve, reject) {
    const { input, nodes, legalPersons, realPersons } = getStore().getState();
    const contract = getContractValues();
    const isTemplate = getIsTemplateStudio();

    // This would fix a bug caused in dev mode where
    // the state is sometimes empty at save. But
    // TBD; what it
    // if (Object.keys(input).length === 0) return;

    if (isTemplate) {
      // In template mode, the input state has been setup to cater for
      // the documentTemplate being in template mode (set in loadDraft).
      // Now, ensure that repeatable cards are dealt with properly.
      const templateInputState = finalTemplateInputState(input, contract);

      const updatedData = Contract.getData(contract);

      updatedData.input = templateInputState;

      api
        .put('/documenttemplates/' + contract.id, { data: updatedData })
        .then(function (res) {
          resolve(true);
        })
        .catch((err) => {
          console.log('Cannot update version: ', err, err.response);
          reject(err);
        });
      console.log('Updating creation....', updatedData);

      return;
    }

    const defaultSaveState = { input, nodes, legalPersons, realPersons };

    if (draft) {
      saveDraft(draft, defaultSaveState)
        .then((saveResult) => resolve(true))
        .catch((saveError) => {
          console.log('Save error is ', saveError);
          reject(saveError);
        });
      return;
    } else {
      const savingDrafts = [];
      for (draft of manager.drafts) {
        savingDrafts.push(saveDraft(draft, defaultSaveState));
      }
      Promise.all(savingDrafts)
        .then((allSaved) => {
          resolve(true);
        })
        .catch((anySaveFailed) => {
          console.log('Failed to save all ', anySaveFailed);
        });
    }

    return;
  });
};

function saveDraft(draft, defaultSaveState) {
  const { contract, instance } = draft;

  // Update cross references before saving, ensuring
  // they are up to date.
  draft.withDraftMethod('updateReferences');

  // Get update data
  const updatedData = Contract.dbStoreData(contract, instance.states.current || defaultSaveState);

  return new Promise(function (resolve, reject) {
    // console.log('Saving ... ', contract.id)
    api
      .put('/versions/' + contract.id, { data: updatedData })
      .then(function (res) {
        const hashObj = sha256.create();
        hashObj.update(JSON.stringify(updatedData));
        const hash = hashObj.hex();

        api
          .put('/documents/' + contract.documentId + '/touch', {
            event: 'version-update',
            hash,
            meta: contract.info,
          })
          .then(() => {})
          .catch((err) => {
            console.log('could not touch doc ', err.response);
          });
        resolve(res.data);
      })
      .catch((err) => {
        console.log('Cannot update version: ', err, err.response);
        reject(err);
      });
    console.log('Updating creation....', updatedData);
  });
}

export default save;
