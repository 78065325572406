import { Node } from '../../../../../import/slate';

export const smartReplacementsOnAddRemove = {
  id: 'smartReplacementsOnAddRemove',
  dependencies: { repeatableAdd: true, repeatableRemove: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    let mappedValues = {};

    for (const { cardId } of entries) {
      const aSetOfMappedValues = this.getSmartReplacementValues('numof_repeatable', cardId, state);
      if (aSetOfMappedValues) mappedValues = api.utils.general.mergeObj(mappedValues, aSetOfMappedValues);
    }

    if (!mappedValues || Object.keys(mappedValues).length === 0) return;

    // this.log('mapped values are ', mappedValues)

    return (node, parents) => {
      if (!isFieldSmartReplacement(node)) return;
      const { section, name } = node.data;
      if (mappedValues[section] && mappedValues[section][name]) {
        let value = isTextFirst(node, parents[0])
          ? api.utils.general.ucfirst(mappedValues[section][name])
          : mappedValues[section][name];
        api.utils.engine.setFirstChild(node, value);
        this.markNodeUpdate(node);
      }
    };
  },
};

function isFieldSmartReplacement(node) {
  if (node.type !== 'field' || node.variant !== 'smartreplacement' || !node.data) return false;
  return true;
}

function isTextFirst(node, parent) {
  if (!node || !parent || !Array.isArray(parent.children)) return false;
  let previousString,
    previousStrings = '';
  for (let i = 0; i < parent.children.length; i++) {
    const child = parent.children[i];
    const childIsNode = child === node;

    if (childIsNode) {
      if (previousString.trim().endsWith('.')) return true;
      if (i === 0) {
        return true;
      }
      if (previousStrings.trim() === '') {
        return true;
      }
    }

    previousString = Node.string(child);
    previousStrings += previousString;
  }
}
