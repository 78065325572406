
export * from "./repaymentClause/facilitiesAgreement"
export * from "./repaymentClause/offer"

const AFFECTED_FIELDS = [
  "type",
  "name",
  "commitment",
  "repayment",
  "repaymentinterval",
  "repaymentamount",
  "deferredrepayment",
  "compensaterepaymentdeferral",
  "tenor",
  "tenorformat",
  "currency",
  "termdate",
  "offer_multiple_tenors"
];

function multiIncludes(array1, array2) {
  for (const item of array1) {
    if(array2.includes(item)) return true
  }
  return false;
}

export const repaymentClauseModule = {
  id: "repaymentClauseModule",
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  handler: function ({ state, handlerInvoked, entries, paths, api }) {

    // Do not run unless necessary.
    const cardIds = entries.map(entry => entry.cardId)
    const fieldNames = entries.map(entry => entry.fieldName)
    if (!cardIds.includes("facility")) return;
    if (!multiIncludes(AFFECTED_FIELDS, fieldNames)) return;

    
    return (node, parents) => {
      if (!api.utils.engine.matchModule(node, "facilityAgreement", "repaymentClause")) return;

      let newChildren

      if (this.contract.data.outline.id_name === "offer_bank_loan")
        newChildren = this.buildRepaymentClauseOffer(node, state);
      else
        newChildren = this.buildRepaymentClauseSFA(node, state);

      // this.log('repayment... ', {node: JSON.parse(JSON.stringify(node)), nc: newChildren})

      if (!newChildren) return;
      node.children = newChildren;

      if(node.data.module.type !== "inline") 
        api.utils.engine.itemJoiner(this, node)
      
      /* this.log('Node is ', JSON.parse(JSON.stringify(node)))
      this.log('New children: ', this.buildRepaymentClause(node, state)) */
      this.markNodeUpdate(node);
    };
  },
};
