import findIndex from 'find-index/findIndex';
import {
  createDraft as immerCreateDraft,
  finishDraft as immerFinishDraft,
  setAutoFreeze,
} from '../../../../import/immer';

import { generateHandlersActions, checkElementValid } from './draftSessionHelpers';

// Set immer auto-freeze to false, in order to allow continous updates
setAutoFreeze(false);

/**
 * Begin draft. Called by redux-saga upon user input.
 * Initialises the engine to setup itself for a 'draft session'.
 *
 * @param {object}
 *                  contract: the relevant contract on which we are working (cf. database version)
 *                  state:    the new redux state
 */
export const beginDraft = function (options = {}) {
  if (this.draftValue) {
    return;
  }
  if (options.contract) this.contract = options.contract;

  // Temporary object for holding drafting values.
  // Used by certain core/advanced handlers.
  this._tmpDraft = {};

  // draft time is used to keep track of the exact
  // point in this the various drafting sessions
  // took place. Used in `markNodeUpdate` (see below).
  this._draftTime = Date.now();

  // Performance measurement variable.
  this.t0 = typeof performance === 'object' && typeof performance.now === 'object' ? performance.now() : null;

  // The immutable working copy of the contract content.
  this.draftValue = immerCreateDraft(this.getContent());
};

export const averageSessionTime = function () {
  const total = this.sessionTimes.reduce((acc, curr) => (acc += curr), 0);
  return total / this.sessionTimes.length;
};

/**
 * Finish Draft.
 * Called by redux-saga (via the manager) after a debouncer therein
 * has concluded that it is time for the draft session to come to an
 * end, and for the new contract data to be visually updated (and
 * displayed by Slate).
 *
 * @param {object} state Main redux state.
 */
export const finishDraft = function (state) {
  // console.log('Do Finish Draft.', Date.now())
  if (!this.draftValue) {
    return { content: null };
  }
  if (!state) {
    // console.log('Finish draft received no state', state)
    state = this.states.current;
  }
  // console.log('Finish Draft ', JSON.parse(JSON.stringify(state)))
  this.updateContractContent(state);

  const finishedContent = immerFinishDraft(this.draftValue);

  const t1 =
    typeof performance === 'object' && typeof performance.now === 'object' ? performance.now() : null;
  if (t1) {
    const sessionTime = t1 - this.t0;
    this.sessionTimes.push(sessionTime);
    // this.log('Building new contract took ' + sessionTime + ' milliseconds.');
  }
  this.draftValue = null;
  this._tmpDraft = {};

  this.setPreviousState(this.states.current);
  return {
    content: finishedContent,
    draftInfo: this.getDraftInfo(),
    draftTime: this._draftTime,
  };
};

/**
 * Update Contract Content.
 *
 * Update content by invoking all engine handlers.
 * We're calculating which redux state items have changed
 * since the previous draft session.
 *
 * Called by finishDraft.
 *
 * We do not use the redux action itself to instruct the handlers what to do,
 * but instead we analyse the entire state to conclude which changes
 * have occurred compared to the previous state.
 *
 * @param {object} action The redux action causing the update.
 * @param {object} state  The new state.
 */
export const updateContractContent = function (state, options = {}) {
  // Safe guard. No updates shall be made unless there
  // is an immer `draftValue`
  if (!this.draftValue) return;

  const stateChanges = this.handleNewState(state);
  if (typeof window !== 'undefined' && window.debug) this.log('State changes are ', { stateChanges });
  if (!stateChanges) {
    return this.warn('Invalid state.');
  }

  this.invokeHandlers(state, stateChanges, options);
};

export const invokeHandlers = function (state, stateChanges) {
  this._measureFlows = [];
  const actions = generateHandlersActions(this.handlers, stateChanges, this.contract, {
    importContract: this.importContract,
  });
  if (typeof window !== 'undefined' && window.debug)
    this.log('Actions are ', { actions, importContract: this.importContract });
  const api = this.api;
  const engine = this;
  actions.forEach((action) => {
    const { handler, handlerInvoked, entries, paths, path, entry } = action;

    const flowFunction = handler.handler.call(this, {
      state,
      handlerInvoked,
      entries,
      paths,
      path,
      entry,
      api,
    });
    if (typeof flowFunction !== 'function') return;
    this.runFlow(flowFunction.bind(engine), this.draftValue, handler);
  });
};

export const runFlow = function (flowFunction, content, handler) {
  if (typeof flowFunction !== 'function') return;
  if (!Array.isArray(content)) return this.trace('runFlow content is no array', content);

  for (let i = 0; i < content.length; i++) {
    const node = content[i];
    this.passNodeToFlow(flowFunction, node, [], i, handler);

    // Check if it was removed...
    if (content[i] !== node) {
      i--;
    }
  }
};

export const passNodeToFlow = function (flowFunction, node, parents, index, handler) {
  if (this._preventFlow) return;
  const { flowDirection = 'normal' } = handler;

  let cleanUp;

  if (flowDirection !== 'reverse') cleanUp = flowFunction(node, parents, index);

  if (node.type === 'field' && node.variant === 'enum' && Array.isArray(node.data && node.data.enums)) {
    for (const enumItem of node.data.enums) {
      if (!Array.isArray(enumItem.content)) continue;
      const contentLength = enumItem.content.length;
      for (let i = 0; i < contentLength; i++) {
        const child = enumItem.content[i];
        this.passNodeToFlow(flowFunction, child, [node, ...parents], i, handler);
      }
    }
  }

  if (node.children) {
    let childrenLength = node.children.length;
    for (let i = 0; i < childrenLength; i++) {
      const child = node.children[i];
      if (!child) break;
      this.passNodeToFlow(flowFunction, child, [node, ...parents], i, handler);

      if (!checkElementValid(child, handler, node)) {
        node.children.splice(i, 1);
        i--;
        childrenLength--;
        continue;
      }

      // Check if node have been removed or moved.
      const nodeIndex = findIndex(node.children, (n) => n === child);
      if (nodeIndex === i) continue; // Same position, continue.
      if (nodeIndex === -1 && childrenLength - node.children.length === 1) {
        // 49 - 50
        // Node was simply removed. Decrease counter to catch next node.
        // this.log('Simple removal: ', {node: JSON.parse(JSON.stringify(node)), child: JSON.parse(JSON.stringify(child)), i, nodeIndex, ncl: node.children.length, childrenLength })
        i--;
        childrenLength--;
        continue;
      } else if (nodeIndex > i) {
        // Another node was inserted prior to this one. Adjust accordingly.
        i = nodeIndex; // Will then be increased just after this assignment, (for...i++ above)
        childrenLength = node.children.length;
        continue;
      } else {
        // this.log('Something else happended with this node...', {node: JSON.parse(JSON.stringify(node)), child: JSON.parse(JSON.stringify(child)), i, nodeIndex, ncl: node.children.length, childrenLength })
      }
    }
  }

  if (flowDirection === 'reverse') cleanUp = flowFunction(node, parents, index);

  if (typeof cleanUp === 'function') {
    cleanUp();
  }
};

/*****************************************************/

/****      SYNTHETIC DRAFT SESSION      ****/
/****    AND HELPERS FOR OUTSIDE USE    ****/

export const withDraft = function (fn) {
  if (typeof fn !== 'function') return { content: this.getContent() };
  const alreadyDrafting = !!this.draftValue;
  if (!alreadyDrafting) {
    this.beginDraft();
  }
  fn();
  if (!alreadyDrafting) return this.finishDraft();
};

export const invokeEngineMethod = function (method, contract, ...args) {
  if (typeof this[method] !== 'function') {
    this.log('Engine has no method ', method);
  }
  return this[method](...args);
};

export const forwardDraftMethod = function (method, contract = undefined, ...args) {
  if (typeof this[method] !== 'function') {
    this.log('Engine has no method ', method);
  }
  this.beginDraft({ contract });
  const result = this[method](...args);
  const draftResult = this.finishDraft();
  return {
    ...draftResult,
    result,
  };
};

export const contentSetters = function (predicate, options = {}) {
  const { outsideDraft = false } = options;
  let target;
  if (!this.draftValue) {
    if (outsideDraft) target = this.contract.data.content;
    else {
      this.warn('Cannot get contentSetter outside of draft phase.');
      return [];
    }
  } else {
    target = this.draftValue;
  }
  const setters = [];
  function loopContent(children, predicate) {
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (predicate(child)) {
        setters.push({
          set: (value) => {
            children[i] = value;
          },
          get: () => child,
        });
      }
      if (Array.isArray(child.children)) {
        loopContent(child.children, predicate);
      }
    }
  }

  loopContent(target, predicate);
  return setters;
};

/****        DRAFTING GUIDANCE       ****/
/****  VARIABLES' SETTING FUNCTIONS  ****/

/**
 * Mark Node Update.
 *
 * Mark a contract element (slate node) as having been updated
 * during the relevant drafting session. Helps other parts of the
 * system to keep track of which contract elements were updated
 * at which time.
 *
 * @param {object} node Slate node.
 */
export const markNodeUpdate = function (node) {
  if (!node.data) return (node.data = { _updateTime: this._draftTime });
  node.data._updateTime = this._draftTime;
};

export const setRuleValue = function (name, value) {
  if (!name || value === undefined) return;

  if (!this.contract.data.create.savedRules) this.contract.data.create.savedRules = {};

  if (!this.contract.data.create.savedRules[name]) {
    const madeAt = new Date().toISOString();
    this.contract.data.create.savedRules[name] = {
      label: name,
      description: null,
      code: name,
      createdAt: madeAt,
      updatedAt: madeAt,
      value,
    };
  } else {
    // this.log('Set rule value ', name, value)
    this.contract.data.create.savedRules[name].value = value;
  }
};

export const setShortcut = function (name, value) {
  if (!name || value === undefined) return;

  this.setRuleValue(name, value);
  const draftInfo = this.getDraftInfo();

  if (draftInfo.shortcutStates[name] === value) {
    // Don't set if same value already applies
    return;
  }

  draftInfo.shortcutStates[name] = value;

  if (!Array.isArray(this._tmpDraft._recentUpdatedShortcuts)) this._tmpDraft._recentUpdatedShortcuts = [name];
  else if (!this._tmpDraft._recentUpdatedShortcuts.includes(name))
    this._tmpDraft._recentUpdatedShortcuts.push(name);
};

export const setVariable = function (name, value) {
  if (!name) return;
  const draftInfo = this.getDraftInfo();

  if (value === undefined) delete draftInfo.variables[name];
  else draftInfo.variables[name] = value;

  // Maintain a record of which variables were updated
  // during the current draft session
  if (!Array.isArray(this._tmpDraft._recentUpdatedVariables)) this._tmpDraft._recentUpdatedVariables = [name];
  else if (!this._tmpDraft._recentUpdatedVariables.includes(name))
    this._tmpDraft._recentUpdatedVariables.push(name);
};
export const getVariable = function (name) {
  const draftInfo = this.getDraftInfo();
  return draftInfo && draftInfo.variables && draftInfo.variables[name];
};

export const setInActive = function (node, value = true) {
  if (!node.data) node.data = { _inActive: value };
  else node.data._inActive = value;
  this.markNodeUpdate(node);
};
