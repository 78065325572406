import React from "react";

import { Form, DatePicker } from "antd";
import { getValue } from "./utils/values";


import moment from "moment";
import 'moment/locale/sv'
import svSE from 'antd/lib/locale-provider/sv_SE';
import enGB from 'antd/lib/locale-provider/en_GB';

const datePickerSettings = {
  locales: {
    sv: svSE,
    en: enGB
  },
  formats: {
    sv: 'D MMMM YYYY',
    en: 'll'
  }
}

function InputDate(props) {
  const {
    label,
    handleChange,
    cardId,
    language,
    field,
    inputIndex,
    cardUid
  } = props;

  const value = getValue(props, "");

  

  const dateFormat = datePickerSettings.formats[language || 'en']
  const datePickerLocale = datePickerSettings.locales[language || 'en'] && datePickerSettings.locales[language || 'en']

  moment.locale(language || 'en')
  

  // console.log('language and stns for date: ', {language, dateFormat, datePickerLocale })

  let dateValue
  if(value) {
    dateValue = moment(value, dateFormat)
    dateValue.locale(language || 'en')
  }

  return (
    <Form.Item
      label={label}
      extra={
        field.info ? (
          <small className="muted">{field.info[language]}</small>
        ) : null
      }
    >
      {field.introText && field.introText[language] && (
        <div>{field.introText[language]}</div>
      )}
      <DatePicker
        name={field.name}
        locale={datePickerLocale}
        format={dateFormat}
        onChange={val => {
          if (val) {
            val.locale(language || 'en');
            handleChange(val.format(dateFormat))
          }
        }
        }
        id={field.name + inputIndex + cardId + cardUid}
        value={ dateValue }
      />
    </Form.Item>
  );
}

export default InputDate;
